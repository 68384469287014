import {
  dLCategories,
  genericFormFieldNames,
  vehicleDataList,
  vehicleInformationList
} from "@mb-react/krg-getid-form-data-common";
import {EDocumentType, EUseCases, formFieldNames, getCookie, identificationNumberTypes} from "@mb-react/svarog-ui";
import moment from 'moment';
import { I18n } from "react-redux-i18n";
import {
  NATURAL_PERSON,
  LEGAL_ENTITY,
  vehicleTypes,
  changeOwnershipSubUseCases, IDENTITY_STATE
} from "../constants/application";
import { dictionaryReference, projectDictionaries } from "../constants/dictionaries";
import {
  mandatoryFieldListEXPIRED_DL,
  mandatoryFieldListEXTEND_DL,
  mandatoryFieldListFOREIGN_DL,
  mandatoryFieldListNEW_DL,
  mandatoryFieldListPARTLY_DAMAGED_DL,
  mandatoryFieldListREPLACE_DL,
  mandatoryFieldListREREGISTER_DL, mandatoryTpIdList,
  readOnlyFieldListEXPIRED,
  readOnlyFieldListEXTEND_DL,
  readOnlyFieldListFOREIGN_DL,
  readOnlyFieldListNEW_DL,
  readOnlyFieldListPARTLY_DAMAGED_DL,
  readOnlyFieldListREPLACE_DL,
  readOnlyFieldListREREGISTER_DL
} from "../constants/fieldState/fieldsStateDl";
import { mandatoryFieldListCANCEL_IM, mandatoryFieldListMODIFY_IM, mandatoryFieldListNEW_IM, readOnlyFieldListIM } from "../constants/fieldState/fieldStateIM";
import {
  specificMandatoryBehaviorFieldList,
  specificReadOnlyBehaviorFieldList
} from "../constants/fieldState/specificFieldsList";
import {formFieldsMeta, forms} from "../constants/form";
import {
  ENROLLMENT,
  extendDLCategories,
  foreignerSelectedDLCategories,
  ACCOUNT_TYPE, PROTECTED
} from "../constants/general";
import {
  plateCategories,
  COMPANY, GOVERNMENT, EMBASSY, NO, IO
} from "../constants/application";
import { getPath, i18nl, TRG } from "../constants/i18n";
import {
  addressDataSteps,
  defaultPersonalDataSteps,
  defaultVisaDataSteps,
  dlWorkflowTasks,
  vehicleDataSteps
} from "../constants/workflow";
import { specificMandatoryBehavior, specificReadOnlyBehavior } from "./specificBusinessLogicBehaviorUtil";
import {
  mandatoryFieldListCHANGE_OWNERSHIP_SALE_AND_BUY,
  mandatoryFieldListEXCHANGE_REGISTRATION,
  mandatoryFieldListNEW_VR,
  mandatoryFieldListPARTLY_DAMAGED_VR,
  mandatoryFieldListREPLACE_VR,
  mandatoryFieldListREREGISTER_VR,
  mandatoryFieldListRESERVE_PLATE_NUMBER,
  readOnlyFieldListCHANGE_OWNERSHIP,
  readOnlyFieldListNEW_VR,
  readOnlyFieldListPARTLY_DAMAGED_VR,
  readOnlyFieldListREPLACE_VR,
  readOnlyFieldListREREGISTER_VEHICLE
} from "../constants/fieldState/fieldsStateVR";
import {extractValueFromGenericField, extractValueFromLinkedPerson} from "./environment";
import {checkUserRights} from "./util";
import {MODIFY_PROVINCE_RIGHTS} from "../constants/admin/rights";
import {extractObjectBasedOnKeysList} from "./dataExtractorUtil";
import {
  addInitialValueToForm,
  addMultipleInitialValuesToForm,
  addToAppStore, hideSpinner,
  removeAllValuesFromForm, removeFromAppStore, showSpinner
} from "@mb-react/mb-react-core";
import {plateDataList} from "../constants/wpFormFieldList";
import {applicationStore} from "../constants/store";

export const APPOINTMENT_WF_EDIT = "edit";
export const APPOINTMENT_WF_NEW = "new";

export const GENERIC_TYPES = Object.freeze({ STRING: "STRING", BOOLEAN: "BOOLEAN", INTEGER: "INTEGER" });

export const DEFAULT_ESTIMATED_DAY = '-01';
export const DEFAULT_ESTIMATED_MONTH = '-01';

export const MALE = "M";
export const FEMALE = "F";
export const OTHER = "OTHER";
export const GENDER_TYPES = [MALE, FEMALE];
export const USE_CASE_NEW = "NEW";
export const USE_CASE_NATURALIZATION = "NATURALIZATION";
export const USE_CASE_RENEW = "RENEW";
export const USE_CASE_REPLACE = "REPLACE";
export const USE_CASE_MODIFICATION = "MODIFICATION";
export const USE_CASE_FOREIGN = "FOREIGN";

export function getGenderDropdown() {
  return GENDER_TYPES.map((type) => {
    return {
      label: I18n.t(getPath(type)),
      value: type
    }
  });
}


export const PUBLIC_PERSON = "PUBLIC_PERSON";
export const HOTLINE_OPERATOR = "HOTLINE_OPERATOR";
export const REPRESENTATIVE_TYPES = [PUBLIC_PERSON, HOTLINE_OPERATOR];
export const disableAddIdentitiesButtonListOfUC = [EUseCases.REPLACE, EUseCases.PARTLY_DAMAGED, EUseCases.EXCHANGE_REGISTRATION, EUseCases.MODIFY_VEHICLE_DATA, EUseCases.EXPIRED, EUseCases.CANCEL]

export const plateHandlingFurtherUseUseCaseList = [EUseCases.REPLACE, EUseCases.PARTLY_DAMAGED, EUseCases.CHANGE_OWNERSHIP, EUseCases.MODIFY_VEHICLE_DATA, EUseCases.EXCHANGE_REGISTRATION, EUseCases.CANCEL, EUseCases.EXPIRED]


export function getRepresentativeTypesDropdown() {
  return REPRESENTATIVE_TYPES.map((type) => {
    return {
      label: I18n.t(getPath(type)),
      value: type
    }
  });
}

export function isAccompaniedWithRepresentative(formValues) {
  return formValues && formValues[formFieldNames.CREATED_BY_REPRESENTATIVE] && formValues[formFieldNames.CREATED_BY_REPRESENTATIVE] === true;
}

export function isPublicPerson(formValues) {
  return isAccompaniedWithRepresentative(formValues) && formValues[formFieldNames.REPRESENTATIVE_TYPE] && formValues[formFieldNames.REPRESENTATIVE_TYPE] === PUBLIC_PERSON;
}

export function getVehicleTypes() {
  return [
    {
      label: I18n.t("label." + vehicleTypes.VEHICLE),
      value: vehicleTypes.VEHICLE
    },
    {
      label: I18n.t("label." + vehicleTypes.TRAILER),
      value: vehicleTypes.TRAILER
    }
  ];
}

export const LEGAL_ENTITY_TYPE = "legalEntity";
export const NATURAL_PERSON_TYPE = "naturalPerson";

export function isLegalEntity(formValues) {
  return formValues && formValues[formFieldNames.ACCOUNT_TYPE] && formValues[formFieldNames.ACCOUNT_TYPE] === LEGAL_ENTITY_TYPE;
}

export function isEstimatedDateOfBirth(formValues) {
  return formValues && formValues[formFieldNames.ESTIMATED_DATE_OF_BIRTH] && formValues[formFieldNames.ESTIMATED_DATE_OF_BIRTH] === true;
}

export function prepareEstimatedDateOfBirth(formValues) {
  return formValues[formFieldNames.ESTIMATED_YEAR] + DEFAULT_ESTIMATED_MONTH + DEFAULT_ESTIMATED_DAY;
}

// export function isEstimatedDateOfBirthAndNaturalPerson(formValues) {
//     return isNaturalPerson(formValues) && formValues && formValues[formFieldNames.ESTIMATED_DATE_OF_BIRTH] && formValues[formFieldNames.ESTIMATED_DATE_OF_BIRTH] === true;
// }

export function isEstimatedDateOfBirthAndNaturalPerson(formValues) {
  return formValues && formValues[formFieldNames.ESTIMATED_DATE_OF_BIRTH] && formValues[formFieldNames.ESTIMATED_DATE_OF_BIRTH] === true;
}

export function isNaturalPerson(formValues) {
  return formValues && formValues[formFieldNames.ACCOUNT_TYPE] && formValues[formFieldNames.ACCOUNT_TYPE] === NATURAL_PERSON_TYPE;
}

export function isBirthSurname(formValues) {
  if (formValues && formValues[formFieldNames.BIRTH_SURNAME]) {
    return true;
  }
}

export function isMale(formValues) {
  if (formValues && formValues[formFieldNames.SEX] === "M") {
    return true;
  }
}

export function isForeignUseCase(formValues) {
  return formValues && formValues[formFieldNames.USE_CASE] === USE_CASE_FOREIGN;
}

export function isReplaceUseCase(formValues) {
  return formValues && formValues[formFieldNames.USE_CASE] === USE_CASE_REPLACE;
}


export function isRenewUseCase(formValues) {
  return formValues && formValues[formFieldNames.USE_CASE] === USE_CASE_RENEW;
}

export function isNaturalizationUseCase(formValues) {
  return formValues && formValues[formFieldNames.USE_CASE] === USE_CASE_NATURALIZATION;
}


export function isModificationUseCase(formValues) {
  return formValues && formValues[formFieldNames.USE_CASE] === USE_CASE_MODIFICATION;
}
export function isModificationVRUseCase(formValues) {
  return formValues && formValues[formFieldNames.USE_CASE] === EUseCases.MODIFY_VEHICLE_DATA;
}

export function isNewUseCase(formValues) {
  return formValues && formValues[formFieldNames.USE_CASE] === USE_CASE_NEW;
}

export const REPRESENTATIVE_FIELDS = [formFieldNames.REPRESENTATIVE_DATE_OF_BIRTH, formFieldNames.REPRESENTATIVE_SEX, formFieldNames.REPRESENTATIVE_TYPE, formFieldNames.REPRESENTATIVE_PERSONAL_NUMBER,
formFieldNames.REPRESENTATIVE_NAME, formFieldNames.REPRESENTATIVE_SURNAME];

export const REGISTER_ACCOUNT_GENERIC_FIELDS = [...REPRESENTATIVE_FIELDS, formFieldNames.ESTIMATED_DATE_OF_BIRTH, formFieldNames.ESTIMATED_YEAR, formFieldNames.LE_SUBTYPE];

export const EDIT_ACCOUNT_GENERIC_FIELDS = [formFieldNames.RECEIVE_NOTIFICATIONS];

export const LEGAL_ENTITY_GENERIC_FIELDS = [formFieldNames.LE_TPID, formFieldNames.TPID_PARENT, formFieldNames.LE_STATE, formFieldNames.LE_SECTOR_NUMBER, formFieldNames.LE_QUARTER_NAME, formFieldNames.LE_ADDRESS_ZIP_CODE, genericFormFieldNames.LE_ADDRESS_VILLAGE, formFieldNames.LE_ADDRESS_REGION, formFieldNames.LE_SUBTYPE];

export function resolveGenericType(generic) {
  switch (generic) {
    case formFieldNames.ESTIMATED_DATE_OF_BIRTH:
      return GENERIC_TYPES.BOOLEAN;
    case formFieldNames.ESTIMATED_YEAR:
      return GENERIC_TYPES.INTEGER;
    default:
      return GENERIC_TYPES.STRING;
  }
}

export function prepareGenericsForSerialization(formValues, genericFields) {
  let generics = [];
  if (formValues) {
    genericFields.forEach(generic => {
      if (formValues[generic] !== undefined) {
        generics.push({
          key: generic,
          value: formValues[generic],
          genericFieldDataType: resolveGenericType(generic)
        });
      }
    })
  }
  return generics;
}

export function isRoN(fieldName, fieldsArray) {
  return fieldsArray && fieldsArray.includes(fieldName);
}

export function returnMessageForUsecase(value) {
  switch (value) {
    case USE_CASE_NEW:
      return getPath(i18nl.NEW_DESC, TRG.MESSAGE);
    case USE_CASE_RENEW:
      return getPath(i18nl.RENEW_DESC, TRG.MESSAGE);
    case USE_CASE_REPLACE:
      return getPath(i18nl.REPLACE_DESC, TRG.MESSAGE);
    case USE_CASE_MODIFICATION:
      return getPath(i18nl.MODIFY_DESC, TRG.MESSAGE);
    case USE_CASE_NATURALIZATION:
      return getPath(i18nl.NATURALIZATION_DESC, TRG.MESSAGE);
    default:
      return getPath(i18nl.NEW_DESC, TRG.MESSAGE);
  }
}

export function returnMessageForUsecasePassport(value) {
  switch (value) {
    case USE_CASE_NEW:
      return getPath(i18nl.NEW_DESC_PASSPORT, TRG.MESSAGE);
    case USE_CASE_RENEW:
      return getPath(i18nl.RENEW_DESC_PASSPORT, TRG.MESSAGE);
    case USE_CASE_REPLACE:
      return getPath(i18nl.REPLACE_DESC_PASSPORT, TRG.MESSAGE);
    case USE_CASE_MODIFICATION:
      return getPath(i18nl.MODIFY_DESC_PASSPORT, TRG.MESSAGE);
    case USE_CASE_NATURALIZATION:
      return getPath(i18nl.NATURALIZATION_DESC_PASSPORT, TRG.MESSAGE);
    default:
      return getPath(i18nl.NEW_DESC_PASSPORT, TRG.MESSAGE);
  }
}

export function returnMessageForUsecaseDriverLicense(value) {
  switch (value) {
    case USE_CASE_NEW:
      return getPath(i18nl.NEW_DESC_DL, TRG.MESSAGE);
    case USE_CASE_RENEW:
      return getPath(i18nl.RENEW_DESC_DL, TRG.MESSAGE);
    case USE_CASE_REPLACE:
      return getPath(i18nl.REPLACE_DESC_DL, TRG.MESSAGE);
    case USE_CASE_MODIFICATION:
      return getPath(i18nl.MODIFY_DESC_DL, TRG.MESSAGE);
    case USE_CASE_NATURALIZATION:
      return getPath(i18nl.NATURALIZATION_DESC_DL, TRG.MESSAGE);
    default:
      return getPath(i18nl.NEW_DESC_DL, TRG.MESSAGE);
  }
}

export const isOlderThen18 = (date) => {
  return moment(date).isBefore(moment().subtract(18, "years"))
}

export const isAfter = (date) => {
  return moment(date).isAfter()
}

const TOKEN_NAME = configurationData && configurationData.accessTokenName ? configurationData.accessTokenName : "jwt"

export function isLoggedIn() {
  return getCookie(TOKEN_NAME)
}

export function isNotRegisteredUserRole(userRights) {
  return userRights && userRights.includes('notRegisteredUser')
}

export function isUser(userRights) {
  return userRights && userRights.includes('user')
}

export function getApplicationTasksToRender(formValues) {
  let tasksToRender = []

  if (formValues && formValues[formFieldNames.DOCUMENT_TYPE]) {
    if (formValues[formFieldNames.DOCUMENT_TYPE] == EDocumentType.DL) {
      tasksToRender = dlWorkflowTasks
    }
  }
  return tasksToRender;
}

export function getPersonalDataSectionsToRender(formValues) {
  let stepsToRender = defaultPersonalDataSteps
  //logic for rendering personal steps
  return stepsToRender
}

export function getVisaDataDataSectionsToRender(formValues) {
  let stepsToRender = defaultVisaDataSteps
  return stepsToRender
}

export function getAddressDataSectionsToRender(formValues) {
  let stepsToRender = addressDataSteps
  return stepsToRender
}

export function getVehicleDataStepsToRender(formValues) {
  let stepsToRender = vehicleDataSteps
  return stepsToRender
}

export function filterDlCategoriesPerUseCase(useCase) {
  let filteredCategories = dLCategories
  if (useCase == EUseCases.NEW) {
    filteredCategories = dLCategories.filter(x => !extendDLCategories.includes(x.value))
  }

  return filteredCategories
}

export function driversLicenseDataReadOnly (useCase){
  if(isOperationModeProtected()){
    if([EUseCases.NEW, EUseCases.FOREIGN, EUseCases.MODIFICATION, EUseCases.EXTEND, EUseCases.RE_REGISTER].includes(useCase)){
      return false;
    }
    return true
  }
  return false
}

export function isMandatory(formValues, fieldName, useCase, documentType, workflow, accountIdentity) {
  if (isReadOnly(formValues, fieldName, useCase, documentType, workflow)) {
    return false
  } else {
    let defaultList = [];
    if (!accountIdentity && (formValues[formFieldNames.TPID] && formValues[formFieldNames.TPID] !== "")) {
      defaultList = mandatoryTpIdList
    } else if (documentType && documentType == EDocumentType.DL) {
      if (workflow && workflow == ENROLLMENT) {
        if (useCase && useCase == EUseCases.NEW) {
          defaultList = mandatoryFieldListNEW_DL
        } else if (useCase && useCase == EUseCases.RE_REGISTER) {
          defaultList = mandatoryFieldListREREGISTER_DL
        } else if (useCase && useCase == EUseCases.REPLACE) {
          defaultList = mandatoryFieldListREPLACE_DL
        } else if (useCase && useCase == EUseCases.EXTEND) {
          defaultList = mandatoryFieldListEXTEND_DL
        } else if (useCase && useCase == EUseCases.EXPIRED) {
          defaultList = mandatoryFieldListEXPIRED_DL
        } else if (useCase && useCase == EUseCases.FOREIGN) {
          defaultList = mandatoryFieldListFOREIGN_DL
        } else if (useCase && useCase == EUseCases.PARTLY_DAMAGED) {
          defaultList = mandatoryFieldListPARTLY_DAMAGED_DL
        }
      }
    } else if (documentType && documentType == EDocumentType.VR){
      if(workflow && workflow == ENROLLMENT){
        if (useCase && useCase == EUseCases.NEW) {
          defaultList = mandatoryFieldListNEW_VR
        } else if (useCase && useCase == EUseCases.RE_REGISTER) {
          defaultList = mandatoryFieldListREREGISTER_VR
        } else if (useCase && useCase == EUseCases.REPLACE) {
          defaultList = mandatoryFieldListREPLACE_VR
        } else if (useCase && useCase == EUseCases.PARTLY_DAMAGED) {
          defaultList = mandatoryFieldListPARTLY_DAMAGED_VR
        } else if (useCase && useCase == EUseCases.RESERVE_PLATE_NUMBER) {
          defaultList = mandatoryFieldListRESERVE_PLATE_NUMBER
        } else if (useCase && useCase == EUseCases.EXCHANGE_REGISTRATION) {
          defaultList = mandatoryFieldListEXCHANGE_REGISTRATION
        } else if (useCase && useCase == EUseCases.CHANGE_OWNERSHIP) {
          defaultList = mandatoryFieldListCHANGE_OWNERSHIP_SALE_AND_BUY
        }else{
          defaultList = mandatoryFieldListNEW_VR
        }
      }
    } else if (documentType && documentType == "IDENTITY") {
      if (workflow && workflow == ENROLLMENT) {
        if (useCase && useCase == "NEW") {
          defaultList = mandatoryFieldListNEW_IM
        } else if (useCase && useCase == "MODIFICATION") {
          defaultList = mandatoryFieldListMODIFY_IM
        } else if (useCase && useCase == "CANCEL_PERSON") {
          defaultList = mandatoryFieldListCANCEL_IM
        }
      }
    }
    if (specificMandatoryBehaviorFieldList.includes(fieldName)) {
      return specificMandatoryBehavior(formValues, fieldName, useCase, documentType, workflow, defaultList.includes(fieldName))
    } else {
      return defaultList.includes(fieldName)
    }
  }
}

export function isReadOnly(formValues, fieldName, useCase, documentType, workflow) {
  let defaultList = [];
  if (documentType && documentType == EDocumentType.DL) {
    if (workflow && workflow == ENROLLMENT) {
      if (useCase && useCase == EUseCases.NEW) {
        defaultList = readOnlyFieldListNEW_DL
      } else if (useCase && useCase == EUseCases.RE_REGISTER) {
        defaultList = readOnlyFieldListREREGISTER_DL
      } else if (useCase && useCase == EUseCases.REPLACE) {
        defaultList = readOnlyFieldListREPLACE_DL
      } else if (useCase && useCase == EUseCases.EXTEND) {
        defaultList = readOnlyFieldListEXTEND_DL
      } else if (useCase && useCase == EUseCases.EXPIRED) {
        defaultList = readOnlyFieldListEXPIRED
      } else if (useCase && useCase == EUseCases.FOREIGN) {
        defaultList = readOnlyFieldListFOREIGN_DL
      } else if (useCase && useCase == EUseCases.PARTLY_DAMAGED) {
        defaultList = readOnlyFieldListPARTLY_DAMAGED_DL
      }
    }
  } else if (documentType && documentType == EDocumentType.VR){
    if(workflow && workflow == ENROLLMENT){
      if (useCase && useCase == EUseCases.NEW) {
        defaultList = readOnlyFieldListNEW_VR
      } else if (useCase && useCase == EUseCases.RE_REGISTER) {
        defaultList = readOnlyFieldListREREGISTER_VEHICLE
      } else if (useCase && useCase == EUseCases.REPLACE) {
        defaultList = readOnlyFieldListREPLACE_VR
      } else if (useCase && useCase == EUseCases.PARTLY_DAMAGED) {
        defaultList = readOnlyFieldListPARTLY_DAMAGED_VR
      } else if (useCase && useCase == EUseCases.RESERVE_PLATE_NUMBER) {
        defaultList = readOnlyFieldListNEW_VR
      } else if (useCase && useCase == EUseCases.EXCHANGE_REGISTRATION) {
        defaultList = readOnlyFieldListNEW_VR
      } else if (useCase && useCase == EUseCases.CHANGE_OWNERSHIP) {
        defaultList = readOnlyFieldListCHANGE_OWNERSHIP
      }else{
        defaultList = readOnlyFieldListNEW_VR
      }
    }
  }else if (documentType && documentType == "IDENTITY") {
    if (useCase && useCase == "CANCEL_PERSON") {
      defaultList = readOnlyFieldListIM;
    }
  }
  if (specificReadOnlyBehaviorFieldList.includes(fieldName)) {
    return specificReadOnlyBehavior(formValues, fieldName, useCase, documentType, workflow, defaultList.includes(fieldName));
  } else {
    return defaultList.includes(fieldName);
  }
}

export function isExtendUseCase(formValues) {
  return isEnrollment(formValues) && EUseCases.EXTEND === formValues[formFieldNames.USE_CASE];
}

export function isEnrollment(formValues) {
  return formValues && formValues[formFieldsMeta.WORKFLOW] == ENROLLMENT
}


export function isUseCaseNEW(formValuesHome) {
  return formValuesHome[formFieldNames.USE_CASE] == EUseCases.NEW;
}

export function isUseCaseForeign(formValuesHome) {
  return formValuesHome[formFieldNames.USE_CASE] == EUseCases.FOREIGN;
}

export function isUseCaseReregister(formValuesHome) {
  return formValuesHome[formFieldNames.USE_CASE] == EUseCases.RE_REGISTER;
}

export function isUseCaseReRegister(formValues) {
  if (formValues[formFieldNames.USE_CASE] == EUseCases.RE_REGISTER) {
    return true
  }
  return false
}

export function isUseCaseExchangeRegistration(formValuesHome) {
  return formValuesHome[formFieldNames.USE_CASE] == EUseCases.EXCHANGE_REGISTRATION;
}

export function isUseCaseExpired(formValuesHome) {
  return formValuesHome[formFieldNames.USE_CASE] == EUseCases.EXPIRED;
}

export function isUseCaseReplace(formValuesHome) {
  return formValuesHome?.[formFieldNames.USE_CASE] === EUseCases.REPLACE;
}

export function isUseCaseUncancelDocument(formValuesHome) {
  return formValuesHome?.[formFieldNames.USE_CASE] === EUseCases.UNCANCEL_DOCUMENT;
}

export function isUseCaseChangeOwnership(formValuesHome) {
  return formValuesHome?.[formFieldNames.USE_CASE] === EUseCases.CHANGE_OWNERSHIP;
}

export function renderFurtherUseSection(formValuesHome){
  let subUseCase = formValuesHome[genericFormFieldNames.CHANGE_OWNERSHIP_SUB_USE_CASE];
  let listOfSubUseCases = [changeOwnershipSubUseCases.BY_AUCTION, changeOwnershipSubUseCases.BY_COURT, changeOwnershipSubUseCases.SALE_AND_BUY];
  return isUseCaseChangeOwnership(formValuesHome) && listOfSubUseCases.includes(subUseCase);
}

export function isUseCaseCancel(formValues) {
  if (formValues[formFieldNames.USE_CASE] == EUseCases.CANCEL_DOCUMENT) {
    return true
  }
  return false
}
export function isUseCaseReRegisterVehicle(formValuesHome) {
  return formValuesHome?.[formFieldNames.USE_CASE] === EUseCases.RE_REGISTER;
}

export function isUseCaseReservePlateNumber(formValues){
  return formValues?.[formFieldNames.USE_CASE] === EUseCases.RESERVE_PLATE_NUMBER;
}

export function isSubUseCaseSaleAndBuy(formValuesHome) {
  return formValuesHome?.[genericFormFieldNames.CHANGE_OWNERSHIP_SUB_USE_CASE] === changeOwnershipSubUseCases.SALE_AND_BUY;
}

export function isSubUseCaseDeleteOwner(formValuesHome) {
  return formValuesHome?.[genericFormFieldNames.CHANGE_OWNERSHIP_SUB_USE_CASE] === changeOwnershipSubUseCases.DELETE_OWNER;
}

export function isUseCase(formValuesHome, useCase) {
  return formValuesHome?.[formFieldNames.USE_CASE] === useCase;
}

export function preselectMedicalExam(formValues) {
  if (formValues[formFieldNames.USE_CASE]) {
    if (formValues[formFieldNames.USE_CASE] == EUseCases.NEW
      || formValues[formFieldNames.USE_CASE] == EUseCases.RE_REGISTER
    ) {
      return true
    }
  }
  return false
}

export function isUseCaseMODIFICATION(formValuesHome) {
  return formValuesHome[formFieldNames.USE_CASE] == EUseCases.MODIFICATION;
}

export function isMarried(maritalStatusValue) {
  return maritalStatusValue && maritalStatusValue == "Married";
}

export function isFoodCenter(formValues) {
  return formValues && formValues[genericFormFieldNames.FOOD_CENTER_INDICATOR];
}

export function isProxy(formValues) {
  return formValues && formValues[formFieldNames.PROXY];
}

export function isPreviousDL(formValues) {
  return formValues && formValues[genericFormFieldNames.OWNS_PREVIOUS_DL];
}

export function isIRQ(value) {
  return value && value == "IRQ";
}

export function isUnemployed(formValues) {
  if (formValues && !formValues[formFieldNames.POSITION]) {
    return true
  } else {
    return formValues && formValues[formFieldNames.POSITION] && formValues[formFieldNames.POSITION].includes("12");
  }
}

export function isInsideCity(formValues) {
  return formValues && formValues[genericFormFieldNames.ADDRESS_BUILDING_CLASS] && formValues[genericFormFieldNames.ADDRESS_BUILDING_CLASS] == "Within the city";
}

export function isOutsideCity(formValues) {
  return formValues && formValues[genericFormFieldNames.ADDRESS_BUILDING_CLASS] && formValues[genericFormFieldNames.ADDRESS_BUILDING_CLASS] == "Outside the city";
}

export function isOperationModeProtected() {
  return configurationData.operationMode === PROTECTED;
}

export function isOperationModeNotProtected() {
  return configurationData.operationMode !== PROTECTED;
}

export const extractIdentificationNumberByType = (identificationNumbers, type) => {
  if (identificationNumbers?.length > 0) {
    return identificationNumbers?.find(identificationNumber => identificationNumber && identificationNumber.personalNumberType === type)?.personalNumber ?? null;
  }
};

export const extractGenericField = (genericFields, field) => {
  if (genericFields?.length > 0) {
    return genericFields?.find(genericField => genericField?.key === field)?.value ?? null;
  }
};

export function isReRegisterOrForeignUseCase(formValues) {
  if (isEnrollment(formValues)) {
    switch (formValues[formFieldNames.USE_CASE]) {
      case EUseCases.RE_REGISTER:
      case EUseCases.FOREIGN:
        return true;
      case EUseCases.NEW:
      case EUseCases.EXTEND:
      case EUseCases.REPLACE:
      case EUseCases.PARTLY_DAMAGED:
      case EUseCases.EXPIRED:
      case EUseCases.CANCEL_DOCUMENT:
        return false;
      default:
        return false;
    }
  }
  return false
}
export function handleBrandDictionaries(formValues, brand, handleCommonServices) {
  if (isTrailerType(formValues)) {
    handleCommonServices.handleGetSingleDictionary(projectDictionaries.TRAILER_MODELS, brand, dictionaryReference.TRAILER_MODELS)
  } else {
    handleCommonServices.handleGetSingleDictionary(projectDictionaries.VEHICLE_MODELS, brand, dictionaryReference.VEHICLE_MODELS)
  }
}

export function handleCategoryDictionaries(formValues, brand, handleCommonServices) {
  if (isTrailerType(formValues)) {
    handleCommonServices.handleGetSingleDictionary(projectDictionaries.TRAILER_SUBCATEGORIES, brand, dictionaryReference.TRAILER_SUBCATEGORIES)
    // } else if (isVehicleType(formValues)) {
  } else {
    handleCommonServices.handleGetSingleDictionary(projectDictionaries.VEHICLE_SUBCATEGORIES, brand, dictionaryReference.VEHICLE_SUBCATEGORIES)
  }
}

export function isTrailerType(formValues) {
  return formValues?.[genericFormFieldNames.VEHICLE_TYPE] === vehicleTypes.TRAILER;
}

export function isVehicleType(formValues) {
  return formValues?.[genericFormFieldNames.VEHICLE_TYPE] === vehicleTypes.VEHICLE;
}

export function isUseCaseReplaceVR(formValuesHome) {
  return formValuesHome?.[formFieldNames.USE_CASE] === EUseCases.REPLACE &&
    formValuesHome?.[formFieldNames.DOCUMENT_TYPE] === EDocumentType.VR;
}

export function isUseCasePartlyDamagedVR(formValuesHome) {
  return formValuesHome?.[formFieldNames.USE_CASE] === EUseCases.PARTLY_DAMAGED &&
    formValuesHome?.[formFieldNames.DOCUMENT_TYPE] === EDocumentType.VR;
}

export function isComponentApplicableForUC(useCase) {
  return useCase === EUseCases.NEW || useCase === EUseCases.RE_REGISTER || useCase === EUseCases.CHANGE_OWNERSHIP
}

export function isComponentApplicableOnVehicleTaskForUC(useCase) {
  return useCase === EUseCases.NEW || useCase === EUseCases.RE_REGISTER
}

export function isSourcePlateChosen(formValuesPlateData, plateDataHelper) {
  if(formValuesPlateData && formValuesPlateData.sourcePlate) {
    if(["FOR_SELLER", "FOR_BUYER", "FREE_PLATE"].includes(formValuesPlateData.sourcePlate)){
      return true;
    }
    if(["FURTHER_USE"].includes(formValuesPlateData.sourcePlate) && !plateDataHelper){
      return true
    }
  }
  return false;
}

export function isLoggedAccountLegalEntity() {
  return (getCookie(ACCOUNT_TYPE) && getCookie(ACCOUNT_TYPE) === LEGAL_ENTITY) ? true : false
}

export function getAccountLegalEntityType(){
  return getCookie(formFieldNames.LE_TYPE)
}

export function getAccountLegalEntitySubType(){
  return getCookie(formFieldNames.LE_SUBTYPE)
}

export function getAccountType(){
  return getCookie(ACCOUNT_TYPE);
}

export function legalEntityTypeMapper(leDropDownValue){
  switch (leDropDownValue) {
    case "no":
      return "NATIONAL_ORGANIZATION"
    case "io":
      return "INTERNATIONAL_ORGANIZATION"
    case "embassy":
      return "EMBASSY"
    case "company":
      return "COMPANY"
    case "government":
      return "GOVERNMENT"
    case "bank":
      return "BANK"
    default:
      return leDropDownValue
  }
}

export function prepareLETypeForDropDownList(leType, category){
  switch (leType) {
    case "NATIONAL_ORGANIZATION":
      return "no"
    case "INTERNATIONAL_ORGANIZATION":
      return "io"
    case "EMBASSY":
      return "embassy"
    case "COMPANY":
      return (category && category === plateCategories.MOTORCYCLE) ? "com" : "company"
    case "GOVERNMENT":
      return (category && category === plateCategories.MOTORCYCLE) ? "gov" : "government"
    case "BANK":
      return "bank"
    default:
      return leType
  }
}

export const getPlateCategoryValues = () => {
  let naturalPersonOptionList = [plateCategories.STANDARD, plateCategories.MOTORCYCLE]
  let embassyAndInternationalOrgOptionList = [plateCategories.SPECIAL]
  let nationalOrgOptionList = [plateCategories.OTHER, plateCategories.MOTORCYCLE]
  let govAndComOptionList = [plateCategories.OTHER, plateCategories.STANDARD, plateCategories.MOTORCYCLE]

  let plateCategoryOptions = [
    { name: I18n.t('label.standard'), value: "standard" },
    { name: I18n.t('label.special'), value: "special" },
    { name: I18n.t('label.motorcycle'), value: "motorcycle" },
    { name: I18n.t('label.other'), value: "other" },
  ]

  let preparedOptions = plateCategoryOptions;
  if(isLoggedAccountLegalEntity()){
    switch (prepareLETypeForDropDownList(getAccountLegalEntityType())) {
      case COMPANY:
      case GOVERNMENT:
        preparedOptions = plateCategoryOptions.filter(e => govAndComOptionList.includes(e.value));
        break;
      case EMBASSY:
      case IO:
        preparedOptions = plateCategoryOptions.filter(e => embassyAndInternationalOrgOptionList.includes(e.value));
        break;
      case NO:
        preparedOptions = plateCategoryOptions.filter(e => nationalOrgOptionList.includes(e.value));
        break;
    }
  } else{
    preparedOptions = plateCategoryOptions.filter(e => naturalPersonOptionList.includes(e.value))
  }
  return preparedOptions;
}

export function disableLeButtonForSubUC(subUC){
  if([changeOwnershipSubUseCases.ADD_OWNER, changeOwnershipSubUseCases.REMOVE_OWNER, changeOwnershipSubUseCases.CHANGE_SHARE, changeOwnershipSubUseCases.CHANGE_LEAD].includes(subUC)){
    return false;
  }else{
    return true;
  }
}

export function isIdentityActivated() {
  return (getCookie(IDENTITY_STATE) && getCookie(IDENTITY_STATE) == "true") ? true : false
}

export const prepareApplicantDTOforStore = (applicant) => {
  return {
    enrollmentStatus: "COMPLETE",
    entityType: applicant?.applicantType,
    applicantData: applicant
  }
}

export function checkIfEntityIsAlreadyEnrolled(listOfEntities, formValues, isEnrolledEntityLE){
  let tpid = isEnrolledEntityLE ? formValues["leTpId"] : formValues[formFieldNames.TPID]
  let entityType = isEnrolledEntityLE ? LEGAL_ENTITY : NATURAL_PERSON

  if(listOfEntities && listOfEntities.length > 0){
    if(tpid && checkIfEntityWithTPIDIsAlreadyEnrolled(listOfEntities, tpid, entityType)){
      return true
    }

    if(checkIfEntityWithSameDemographicDataIsAlreadyEnrolled(listOfEntities, formValues, entityType)){
      return true
    }

  }

  return false;
}

function checkIfEntityWithSameDemographicDataIsAlreadyEnrolled(listOfEntities, formValues, entityType){
  if(listOfEntities.find((obj) => {
    if(obj?.entityType === entityType){
      if(entityType == NATURAL_PERSON){
        let personDataDTO = obj?.applicantData?.demographicDataDTO?.personDataDTO;
        let name = personDataDTO?.givenName
        let surname = personDataDTO?.surname
        let dob = personDataDTO?.dateOfBirth ? moment(personDataDTO.dateOfBirth, 'YYYY-MM-DD') : null
        let gender = personDataDTO?.gender
        let grandfatherName = extractValueFromGenericField(obj?.applicantData?.genericFieldsDTO?.genericFieldDTOList, "grandfatherName");
        let fatherName = extractValueFromLinkedPerson(obj?.applicantData?.demographicDataDTO?.linkedPersons, "FATHER")

        let formValuesDoB = formValues?.dateOfBirth ? moment(formValues.dateOfBirth) : null

        if(name == formValues?.givenName && surname == formValues?.surname &&
            (dob && formValuesDoB && formValuesDoB.isSame(dob, 'day')) &&
            gender == formValues?.gender  && grandfatherName == formValues?.grandfatherName && fatherName == formValues?.fatherName){
          return true
        }
      }
      if(entityType == LEGAL_ENTITY){
        let leDTO = obj?.applicantData?.legalEntityDTO;
        let leName = leDTO?.leName
        let leType = leDTO?.leType

        console.log(formValues)

        if(leName == formValues?.leName && leType == formValues?.leType){
          return true
        }
      }
    }
  })){
    return true
  }
  return false
}

function checkIfEntityWithTPIDIsAlreadyEnrolled(listOfEntities, tpid, entityType){

  if(listOfEntities.find((obj) => {
    let upn = obj?.entityType == NATURAL_PERSON ?
        extractIdentificationNumberByType(obj?.applicantData?.demographicDataDTO?.personDataDTO?.identificationNumbers, identificationNumberTypes.TPID) :
        extractValueFromGenericField(obj?.applicantData?.genericFieldsDTO?.genericFieldDTOList, "leTpId");
    if (obj?.entityType === entityType && upn === tpid) {
      return true;
    }
  })){
    return true
  }
  return false
}

export const getDropdownLabelFromKey = (options, key) => {
  return options?.find(option => option.value === key)?.label;
};

export const disableDraft = (userRights, accountIdentity) => {
  if(accountIdentity){
    return true;
  }
  if(isNotRegisteredUserRole(userRights) || isOperationModeProtected()){
    return true
  }
  return false
}

export const useSkip = (userRights, errors, accountIdentity) => {
  if(disableDraft(userRights, accountIdentity)){
    return false;
  }else{
    return errors;
  }
}

export const disableNextButton = (isFormValid, userRights, accountIdentity) => {
  if(accountIdentity && !isFormValid){
    return true
  }

  if(isFormValid || (isOperationModeNotProtected() && !isNotRegisteredUserRole(userRights))){
    return false
  }
  return true
}

export const disablePlateHandlingTask = (formValues, isVehicleFetched, userRights) => {

  if(isOperationModeProtected()){
    if(isModificationVRUseCase(formValues) && userRights && !checkUserRights(MODIFY_PROVINCE_RIGHTS, userRights)){
      return true;
    }
    return false;
  }


  if(isModificationVRUseCase(formValues) && isVehicleFetched && formValues[formFieldNames.PLATE_CATEGORY]){
    return true
  }

  return false
}

export function usePlateDataFromSystemForUCs(useCase){

  if(useCase && plateHandlingFurtherUseUseCaseList.includes(useCase)){
    return true
  }

  return false;
}

export const mapVehicleToFormValues = (dispatch, handleCommonServices, vehicle, formValues) => {

  dispatch(removeAllValuesFromForm(forms.VEHICLE_IDENTIFICATION))
  dispatch(removeAllValuesFromForm(forms.VEHICLE_DATA))
  dispatch(removeFromAppStore(applicationStore.INITIAL_MODIFY_VALUES))

  let vehicleInformationData = extractObjectBasedOnKeysList(vehicle, [...vehicleInformationList, "transactionId"])
  if(vehicle?.genericFieldsDTO?.genericFieldDTOList.length > 0){
    let gf = vehicle.genericFieldsDTO.genericFieldDTOList;
    let gfData = extractGenericField(gf, genericFormFieldNames.VEHICLE_TYPE)
    vehicleInformationData = {...vehicleInformationData, [genericFormFieldNames.VEHICLE_TYPE]: gfData};
  }
  if(vehicleInformationData[formFieldNames.VEHICLE_BRAND]){
    handleBrandDictionaries(vehicleInformationData, vehicleInformationData[formFieldNames.VEHICLE_BRAND], handleCommonServices)
  }
  dispatch(addMultipleInitialValuesToForm(forms.VEHICLE_IDENTIFICATION, vehicleInformationData))
  let vehicleData = extractObjectBasedOnKeysList(vehicle, vehicleDataList)

  if(vehicleInformationData[formFieldNames.VEHICLE_CATEGORY]){
    if(isTrailerType(vehicleInformationData)){
      handleCommonServices.handleGetSingleDictionary(projectDictionaries.TRAILER_SUBCATEGORIES, vehicleInformationData[formFieldNames.VEHICLE_CATEGORY], dictionaryReference.TRAILER_SUBCATEGORIES);
    }else{
      handleCommonServices.handleGetSingleDictionary(projectDictionaries.VEHICLE_SUBCATEGORIES, vehicleInformationData[formFieldNames.VEHICLE_CATEGORY], dictionaryReference.VEHICLE_SUBCATEGORIES);
    }
  }

  dispatch(addMultipleInitialValuesToForm(forms.VEHICLE_DATA, vehicleData))
  if(vehicleData[formFieldNames.FRONT_TINT_PERCENTAGE]){
    dispatch(addInitialValueToForm(forms.VEHICLE_DATA, formFieldNames.FRONT_TINT_PERCENTAGE, vehicleData[formFieldNames.FRONT_TINT_PERCENTAGE].toString()))
  }
  if(vehicleData[formFieldNames.REAR_TINT_PERCENTAGE]){
    dispatch(addInitialValueToForm(forms.VEHICLE_DATA, formFieldNames.REAR_TINT_PERCENTAGE, vehicleData[formFieldNames.REAR_TINT_PERCENTAGE].toString()))
  }

  let plateData = {};

  if(vehicle?.plateDTOList.length > 0){
    plateData = extractObjectBasedOnKeysList(vehicle.plateDTOList[0], plateDataList)
    dispatch(addMultipleInitialValuesToForm(forms.PLATE_DATA, plateData))
    if(configurationData.enablePlateHandling && usePlateDataFromSystemForUCs(formValues[formFieldNames.USE_CASE])){
      dispatch(addToAppStore(applicationStore.PLATE_DATA_HELPER, plateData))
    }else if(isUseCaseChangeOwnership(formValues) && formValues?.["changeOwnershipSubUseCase"] === changeOwnershipSubUseCases.SALE_AND_BUY){
      dispatch(addToAppStore(applicationStore.PLATE_DATA_HELPER, plateData))
    }
  }

  if(isModificationVRUseCase(formValues)){
    let extractedValues = {...formValues, ...vehicleInformationData, ...vehicleData, ...plateData}
    prepareModificationFieldFlags(dispatch, extractedValues)
  }

}

export const prepareModificationFieldFlags = (dispatch, formValues) => {
  if (!isModificationVRUseCase(formValues)) return;

  let fields = {
    [formFieldNames.VEHICLE_SUBCATEGORY]: formValues[formFieldNames.VEHICLE_SUBCATEGORY],
    [formFieldNames.PLATE_PROVINCE]: formValues[formFieldNames.PLATE_PROVINCE],
    [formFieldNames.PLATE_USAGE]: formValues[formFieldNames.PLATE_USAGE],
    [formFieldNames.PLATE_NUMBER_LATIN]: formValues[formFieldNames.PLATE_NUMBER_LATIN],
    [formFieldNames.VEHICLE_COLOR]: formValues[formFieldNames.VEHICLE_COLOR]
  }
  dispatch(addToAppStore(applicationStore.INITIAL_MODIFY_VALUES, fields))
}

export const VRcheckStateHandling = (InterfaceAdapterService, dispatch, entityType, useCase, states) => {
  dispatch(showSpinner(I18n.t('message.checkStateHandling')))
  return new Promise((resolve, reject) => {
    let blockingState = null;
    const requestData = {
      entityType: entityType,
      useCase: useCase,
      states: states
    };

    InterfaceAdapterService.getVRBlockingState(requestData)
        .then(response => {
          if (response?.data?.data) {
            blockingState = `label.stateHandling${response.data.data}`;
            reject(blockingState)
          }
          dispatch(hideSpinner())
          resolve(true)
        })
        .catch(() => {
          blockingState = "err.blockingStateCheckFailed";
          reject(blockingState)
        });
  });
}

export const checkIfVehicleExistsInVehicleService = (dispatch, VehicleService, chassisNumber, status) => {
  dispatch(showSpinner(I18n.t('message.checkingVehicle')))
  return new Promise((resolve, reject) => {
    const requestData = {
      chassisNumber: chassisNumber,
      status: status ? status : null
    };
    VehicleService.isVehicleExists(requestData)
        .then(
            response => {
              if(response?.data?.length > 0){
                dispatch(hideSpinner())
                resolve(true)
              }
              dispatch(hideSpinner())
              resolve(false)
            }
        )
        .catch(() => {
              let errorMessage = "err.isVehicleExistsFailed";
              dispatch(hideSpinner())
              reject(errorMessage)
            }
        )
  });
}

export const saveVehicleInVehicleService = (dispatch, VehicleService, vehicle) => {
  dispatch(showSpinner(I18n.t('message.saveVehicle')))
  return new Promise((resolve, reject) => {
    VehicleService.saveVehiclePrivate(vehicle)
        .then(
            () => {
              dispatch(hideSpinner())
              resolve(true)
            }
        )
        .catch(() => {
              let errorMessage = "err.saveVehicleFailed";
              dispatch(hideSpinner())
              reject(errorMessage)
            }
        )
  });
}

export const updateVehicleByTxId = (dispatch, VehicleService, vehicle) => {
  dispatch(showSpinner(I18n.t('message.updateVehicle')))
  return new Promise((resolve, reject) => {
    VehicleService.updateVehicleByTransactionId(vehicle)
        .then(
            () => {
              dispatch(hideSpinner())
              resolve(true)
            }
        )
        .catch(() => {
              let errorMessage = "err.updateVehicleFailed";
              dispatch(hideSpinner())
              reject(errorMessage)
            }
        )
  });
}