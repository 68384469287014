import {extractGenericField, extractIdentificationNumberByType} from "./businesslogicUtil";
import {formFieldNames, identificationNumberTypes} from "@mb-react/svarog-ui";
import {genericFormFieldNames} from "@mb-react/krg-getid-form-data-common";
import {extractValueFromGenericField} from "./environment";
import {LEGAL_ENTITY} from "../constants/application";

export function returnApplicantMetaDataFromDTOForTable(applicantDataArray) {
  let tableDataArray = [];
  if (applicantDataArray != null) {
    tableDataArray = applicantDataArray.map((applicant, index) => {
      if (applicant?.demographicDataDTO?.personDataDTO) {
        let personDataDTO = applicant.demographicDataDTO.personDataDTO;
        return {
          id: index,
          givenName: personDataDTO.givenName,
          surname: personDataDTO.surname,
          personalNumber: extractIdentificationNumberByType(personDataDTO.identificationNumbers, identificationNumberTypes.NATIONAL_ID),
          tpId: extractIdentificationNumberByType(personDataDTO.identificationNumbers, identificationNumberTypes.TPID),
          applicantUniqueIdentifier: applicant?.applicantUniqueIdentifier,
          [formFieldNames.OWNER_ROLE]: applicant?.ownershipDTO?.[formFieldNames.OWNER_ROLE],
          ownershipStatus: applicant?.ownershipStatus,
          isPresent: applicant?.[genericFormFieldNames.IS_PRESENT],
        };
      }
    });
  }
  return tableDataArray
}

export function returnLegalEntityMetaDataFromDTOForTable(legalEntityDataArray) {
  let tableDataArray = [];
  if (legalEntityDataArray != null) {
    tableDataArray = legalEntityDataArray.map((legalEntity, i) => {
      let genericFieldsDTO = legalEntity?.genericFieldsDTO?.genericFieldDTOList;
      return {
        id: i,
        [formFieldNames.LE_NAME]: legalEntity?.legalEntityDTO?.leName,
        [formFieldNames.LE_TYPE]: legalEntity?.legalEntityDTO?.leType,
        [formFieldNames.TPID]: extractGenericField(genericFieldsDTO, formFieldNames.LE_TPID),
        [formFieldNames.TPID_PARENT]: extractGenericField(genericFieldsDTO, formFieldNames.TPID_PARENT),
        applicantUniqueIdentifier: legalEntity?.applicantUniqueIdentifier,
      }
    });
  }
  return tableDataArray
}

export function isEntityAlreadyAddedInTable (entity, list) {
  if(list.length === 0) return false;

  let entityTpid;
  if(entity?.applicantType === LEGAL_ENTITY){
    entityTpid = extractValueFromGenericField(entity?.genericFieldsDTO?.genericFieldDTOList, "leTpId");
  } else {
    entityTpid = extractIdentificationNumberByType(entity?.demographicDataDTO?.personDataDTO?.identificationNumbers, identificationNumberTypes.TPID);
  }
  return list.some(e => extractIdentificationNumberByType(e?.applicantData?.demographicDataDTO?.personDataDTO?.identificationNumbers, identificationNumberTypes.TPID) === entityTpid)
}

export function returnVehicleMetaDataFromDTOForTable(vehicleDataArray) {
  let tableDataArray = [];
  if (vehicleDataArray != null) {
    tableDataArray = vehicleDataArray.map((vehicle, vehicleIndex) => {
      if (vehicle) {
        return {
          id: vehicleIndex,
          [formFieldNames.VEHICLE_ID]: vehicle[formFieldNames.VEHICLE_ID],
          [formFieldNames.CHASSIS_NUMBER]: vehicle[formFieldNames.CHASSIS_NUMBER],
          [formFieldNames.PLATE_NUMBER_LATIN]: vehicle.plateDTOList ? vehicle.plateDTOList[0]?.[formFieldNames.PLATE_NUMBER_LATIN] : null
        }
      }
    });
  }
  return tableDataArray;
}