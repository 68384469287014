import {
    getFormValues, getFromAppStore
} from "@mb-react/mb-react-core";
import { formFieldNames } from "@mb-react/svarog-ui";
import { useMemo } from "react";
import { useForm } from "react-final-form";
import { useSelector } from "react-redux";
import { formFieldsMeta, forms } from "../../../../../../constants/form";
import { projectDictionaries } from "../../../../../../constants/general";
import { isMandatory, isReadOnly } from "../../../../../../util/businesslogicUtil";
import {ACCOUNT_IDENTITY} from "../../../../../../constants/application";

export const useEmploymentInformationDataSettings = () => {
    const form = useForm();
    const useCase = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.USE_CASE])
    const documentType = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.DOCUMENT_TYPE])
    const workflow = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldsMeta.WORKFLOW])
    const accountIdentity = useSelector(state => getFromAppStore(state, ACCOUNT_IDENTITY));

    let formValues = form?.getState()?.values ?? {}

    let years16Before = new Date();
    years16Before.setFullYear(years16Before.getFullYear() - 16);
    years16Before.setDate(years16Before.getDate() + 2)
    years16Before.setMonth(years16Before.getMonth() + 2)

    let years125Before = new Date();
    years125Before.setFullYear(years125Before.getFullYear() - 125);

    const handlePositionSettings = useMemo(() => {
        let employmentInformationDataProps = {
            [formFieldNames.POSITION]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(formValues, formFieldNames.POSITION, useCase, documentType, workflow),
                    reference: projectDictionaries.PROFESSION,
                },
                required: isMandatory(formValues, formFieldNames.POSITION, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return employmentInformationDataProps
    }, [formValues[formFieldNames.TPID]])

    const handleEmployerOrSchoolSettings = useMemo(() => {
        let employmentInformationDataProps = {
            [formFieldNames.EMPLOYER_OR_SCHOOL]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(formValues, formFieldNames.EMPLOYER_OR_SCHOOL, useCase, documentType, workflow),
                },
                required: isMandatory(formValues, formFieldNames.EMPLOYER_OR_SCHOOL, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return employmentInformationDataProps
    }, [formValues[formFieldNames.POSITION], formValues[formFieldNames.TPID]])

    return { ...handlePositionSettings, ...handleEmployerOrSchoolSettings }
};