import {
    NavigationWizardWrapper, getFromAppStore, addMultipleInitialValuesToForm, addToAppStore
} from "@mb-react/mb-react-core";
import { addressDataFormFieldList, personalInformationFormFieldList } from "@mb-react/krg-getid-form-data-common";
import { extractPersonDataToFormValues, PagedRequest } from '@mb-react/svarog-ui';
import React, { useEffect } from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import { Translate } from "react-redux-i18n";
import {forms, navigationName} from '../../../constants/form';
import { i18nl } from '../../../constants/i18n';
import {APPLICATION_STEPS, applicationStore} from '../../../constants/store';
import {
    disableDraft,
    isIdentityActivated,
    isLoggedIn,
    isNotRegisteredUserRole,
    isOperationModeNotProtected,
    isOperationModeProtected
} from "../../../util/businesslogicUtil";
import { isMobile } from '../../../util/environment';
import SummaryTaskWrapper from '../../tasks/workflowTasks/common/summary/SummaryTaskWrapper';
import AddressDataForm from "../../tasks/workflowTasks/document/addressData/AddressDataForm";
import DriverLicenseForm from "../../tasks/workflowTasks/document/driverLicense/DriverLicenseForm";
import QuestionnaireData from "../../tasks/workflowTasks/document/driverLicense/QuestionnaireData";
import PersonalInformationForm from "../../tasks/workflowTasks/document/personalData/PersonalInformationForm";
import { useHandleWorkflowSettings } from './handleWorkflowSettings';
import {useAddressEnrollmentFormDataValidation} from "../../tasks/workflowTasks/document/addressData/AddressDataValidation";
import {useDriversLicenceEnrollmentFormValidation} from "../../tasks/workflowTasks/document/driverLicense/DriversLicenseDataValidation";
import {usePersonalInformationValidation} from "../../tasks/workflowTasks/document/personalData/personalInformationValidation";
import PersonSearch from "../../tasks/workflowTasks/vehicle/searchData/PersonSearch";
import SearchPersonTableResult from "../../tasks/workflowTasks/vehicle/searchData/SearchPersonTableResult";
import PreSummaryForm from "../../tasks/workflowTasks/document/personalData/PreSummaryForm";
import {useHandleCommonServices} from "../../../services/commonServiceHandler";
import {extractObjectBasedOnKeysList} from "../../../util/dataExtractorUtil";

const CreateApplicationWorkflow = () => {
    const handleWorkflowSettings = useHandleWorkflowSettings();
    const questionnariesCompleted = useSelector((state) => getFromAppStore(state, "questionnariesCompletedDL"));
    const selectedPersonForDL = useSelector((state) => getFromAppStore(state, "selectedPersonForDL"));
    const enrollPersonFromScratch = useSelector((state) => getFromAppStore(state, "enrollPersonFromScratch"));
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))
    let isIdentityActive = isIdentityActivated()
    const handleCommonServices = useHandleCommonServices();
    const dispatch = useDispatch();

    useSelector(state => state.i18n.locale)

    const getMyIdentityOnSuccess = (response) => {
        let newObj = extractPersonDataToFormValues(response.data, {});
        let personalInformation = extractObjectBasedOnKeysList(newObj, personalInformationFormFieldList);
        let addressData = extractObjectBasedOnKeysList(newObj, addressDataFormFieldList);

        dispatch(addMultipleInitialValuesToForm(forms.PERSONAL_INFORMATION, personalInformation))
        dispatch(addMultipleInitialValuesToForm(forms.ADDRESS_DATA, addressData))
        dispatch(addToAppStore('fetchedApplication', {...addressData, ...personalInformation}))

    }

    useEffect(() => {
        handleWorkflowSettings.initWorkflowSettings();
        if(configurationData.useDDL){
            let pagedRequest = new PagedRequest();
            pagedRequest.requestObject = {}
            pagedRequest.page = 1
            pagedRequest.sizePerPage = 10

            handleCommonServices.handleCallGetMyIdentity(pagedRequest, getMyIdentityOnSuccess)
        }

    }, []);


    const personItems = [
        {
            id: APPLICATION_STEPS.PERSONAL_DATA,
            label: <Translate value={"label." + i18nl.PERSONAL_INFORMATION} />,
            icon: 'pi pi-user',
            component: <PersonalInformationForm navigationName={navigationName.MAIN_NAVIGATION} formName={forms.PERSONAL_INFORMATION} />,
            validateFn: usePersonalInformationValidation
        },
        {
            id: APPLICATION_STEPS.ADDRESS_DATA,
            label: <Translate value={"label." + i18nl.ADDRESS_DATA} />,
            icon: 'pi pi-id-card',
            component: <AddressDataForm navigationName={navigationName.MAIN_NAVIGATION} formName={forms.ADDRESS_DATA} />,
            validateFn: useAddressEnrollmentFormDataValidation
        },
    ]

    const preSummaryItems = [
        {
            id: APPLICATION_STEPS.PERSONAL_DATA,
            label: <Translate value={"label." + i18nl.PERSONAL_INFORMATION} />,
            icon: 'pi pi-user',
            component: <PreSummaryForm navigationName={navigationName.MAIN_NAVIGATION} formName={forms.PERSONAL_INFORMATION} />,
            validateFn: usePersonalInformationValidation
        },
    ]

    const items = [
        {
            id: APPLICATION_STEPS.DL_TASK,
            label: <Translate value={"label." + i18nl.DL_DATA} />,
            icon: 'pi pi-car',
            component: <DriverLicenseForm navigationName={navigationName.MAIN_NAVIGATION}/>,
            validateFn: useDriversLicenceEnrollmentFormValidation
        },
        {
            id: APPLICATION_STEPS.CONFIRMATION_TASK,
            label: <Translate value={"label." + i18nl.SUMMARY} />,
            icon: 'pi pi-file',
            component: <SummaryTaskWrapper navigationName={navigationName.MAIN_NAVIGATION} />
        },
    ]

    if(isOperationModeNotProtected()) {
        if (isIdentityActive && isLoggedIn() && !isNotRegisteredUserRole()) {
            items.unshift(...preSummaryItems)
        } else {
            items.unshift(...personItems)
        }
    }

    if (isOperationModeProtected()) {
        if(selectedPersonForDL){
            items.unshift(...preSummaryItems)
        }else if(enrollPersonFromScratch){
            items.unshift(...personItems)
        }
    }

    const renderNavStructure = isMobile() ?
      (<div className="card navigation">
          {(!questionnariesCompleted && !isOperationModeProtected() && !configurationData.useDDL) && <QuestionnaireData />}
          {(questionnariesCompleted || isOperationModeProtected() || configurationData.useDDL) && (
            <div className="mobileWizzardWrapper">
                <NavigationWizardWrapper navigationId={navigationName.MAIN_NAVIGATION} items={items} progressBar={!isMobile()} navigationType={isMobile() ? "CIRCULAR" : "CIRCLE"} readOnly={disableDraft(userRights)}
                                         formName={forms.APPLICATION} /></div>)}
      </div>)
      :
      (<div className="card navigation">
          {(!questionnariesCompleted && !isOperationModeProtected() && !configurationData.useDDL) && <QuestionnaireData />}
          {(questionnariesCompleted || isOperationModeProtected() || configurationData.useDDL) && (
            <NavigationWizardWrapper navigationId={navigationName.MAIN_NAVIGATION} items={items} progressBar={!isMobile()} navigationType={isMobile() ? "CIRCULAR" : "CIRCLE"} readOnly={disableDraft(userRights)}
                                     formName={forms.APPLICATION} />)}
      </div>)


    return (
        <>
            {isOperationModeProtected() && !selectedPersonForDL && !enrollPersonFromScratch ?
              <div className="card navigation"><PersonSearch/><SearchPersonTableResult/></div> :
              renderNavStructure
            }
        </>
    );
}

CreateApplicationWorkflow.propTypes = {};

function mapStateToProps(state) {
    return {
        activeLocale: state.i18n.locale
    }
}

export default connect(mapStateToProps)(CreateApplicationWorkflow)

