import React from "react";

import ContainerHOC from "../../../ContainerHOC";
import AddDocumentsTask from "./AddDocumentsTask";

const ContentManagementHome = () => {
    return (
        <ContainerHOC fullPage>
            <AddDocumentsTask />
        </ContainerHOC>
    );
}
export default ContentManagementHome
