import {setCookie} from "@mb-react/mb-bootstrap-core";
import {addToAppStore, Button, Column, DataTable, getFromAppStore, removeFromAppStore} from '@mb-react/mb-react-core';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {SELECTED_ACCOUNT_EMAIL, SELECTED_ACCOUNT_ID, SELECTED_EXISTING_ACCOUNT} from "../../../../constants/general";
import {getPath, i18nl} from '../../../../constants/i18n';
import {accountStore, applicationStore} from '../../../../constants/store';
import {delete_cookie} from "../../../../util/util";
import {useHandleCommonServices} from "../../../../services/commonServiceHandler";

const AccountsTable = (props) => {
    const accountsFromStore = useSelector((state) => getFromAppStore(state, accountStore.ACCOUNTS_DATA));
    const [selectedRow, setSelectedRow] = useState();
    const dispatch = useDispatch();
    const [report, setReport] = useState();
    const handleCommonServices = useHandleCommonServices()

    const printReportButton = (row) => {
        return (
            <div className="actionColumn" style={{display: "flex"}}>
                <Button icon="pi pi-print" className="p-button-rounded p-button-info"
                        onClick={() => printReport(row)}/>
            </div>
        )
    }

    function printReport(row){

        let fullName = row.givenName;
        if(row.surname){
            fullName = fullName + " " + row.surname;
        }

        handleCommonServices.handleCreateAccountReport(
            { username: row.username,
                        entityName: fullName,
                        entityId: row.personId,
                        entityDateOfBirth: row.dateOfBirth,
                        email: row.email,
                        genericFieldsDTO: {
                            genericFieldDTOList: [
                                {
                                    key: "reportType",
                                    value: "AccountReport",
                                    genericFieldDataType: "STRING"
                                }
                            ]
                        }},
            onPrintCredentialsFunction
        );
    }

    const onPrintCredentialsFunction = (response) => {
        if (response?.data) {
            setReport(response.data)
        }
    }

    useEffect(() => {
        if (report) {
            let pdf_newTab = window.open("");
            pdf_newTab.document.write(
                "<html><head><title>PRINT CREDENTIALS</title></head><body><iframe title='PRINT CREDENTIALS'  width='100%' height='100%' src='data:application/pdf;base64, " + report + "'></iframe></body></html>"
            );
        }
    }, [report]);

    const columns = [
        {
            field: 'accountId',
            header: I18n.t(getPath(i18nl.ACCOUNT_ID)),
        },
        {
            field: 'username',
            header: I18n.t(getPath(i18nl.USERNAME)),
        },
        {
            field: 'personId',
            header: I18n.t(getPath(i18nl.PERSON_ID)),
        },
        {
            field: 'givenName',
            header: I18n.t(getPath(i18nl.GIVEN_NAME)),
        },
        {
            field: 'surname',
            header: I18n.t(getPath(i18nl.SURNAME)),
        },
        {
            field: 'email',
            header: I18n.t(getPath(i18nl.EMAIL)),
        },
        {
            field: 'dateOfBirth',
            header: I18n.t(getPath(i18nl.DATE_OF_BIRTH)),
        },
        {
            field: 'state',
            header: I18n.t(getPath(i18nl.STATE)),
        },
        {
            field: 'actions',
            header: I18n.t(getPath(i18nl.PRINT_REPORTS)),
            hidden: !props.printAccountReport
        },
    ];

    const handleSelectionChange = (e) => {
        setSelectedRow(e.value);

        if(!props.printAccountReport){
            if (e?.value?.accountId && e?.value?.email) {
                setCookie(SELECTED_ACCOUNT_ID, e?.value?.accountId);
                setCookie(SELECTED_ACCOUNT_EMAIL, btoa(e?.value?.email));
                setCookie(SELECTED_ACCOUNT_EMAIL, btoa(e?.value?.email));
                setCookie(SELECTED_EXISTING_ACCOUNT, true);
                dispatch(addToAppStore(applicationStore.REGISTERED_ACCOUNT_ID, e?.value?.accountId));
                dispatch(addToAppStore(applicationStore.REGISTERED_ACCOUNT_EMAIL, e?.value?.email));
            } else {
                delete_cookie(SELECTED_ACCOUNT_ID);
                delete_cookie(SELECTED_ACCOUNT_EMAIL);
                delete_cookie(SELECTED_EXISTING_ACCOUNT);
                dispatch(removeFromAppStore(applicationStore.REGISTERED_ACCOUNT_ID));
                dispatch(removeFromAppStore(applicationStore.REGISTERED_ACCOUNT_EMAIL));
            }
        }
    };

    useEffect(() => {
        // Call the callback to scroll to the target section after the state has been updated
        props.onAccountSelect();
    }, [selectedRow, props.onAccountSelect]);


    let dynamicColumns = [];

    if (columns.length > 0) {
        dynamicColumns = columns.map((col) => {
            if (col.field == "actions") {
                return <Column key={col.field} field={col.field} header={col.header} body={printReportButton} style={{ width: '10%' }} />
            }
            else {
                return <Column {...col} key={col.field} field={col.field} header={col.header} style={{ width: col.width ? col.width : "auto" }} />;

            }
        });
    }

    return (
        <div className="card">
            <DataTable
                value={accountsFromStore}
                actionTitle={I18n.t('label.viewAccount')}
                count={accountsFromStore != null ? accountsFromStore.length : 0}
                onViewIcon="pi pi-calendar-plus"
                paginator={true}
                rows={5}
                rowsPerPageOptions={[5, 10, 25, 50]}
                dataKey="accountId"
                emptyMessage={I18n.t('message.noResultsFound')}
                metaKeySelection={false}
                selectionMode="single"
                selection={selectedRow}
                onSelectionChange={(e) => {
                    handleSelectionChange(e);
                }}
            >
                {dynamicColumns}
            </DataTable>
        </div>
    );
};

export default AccountsTable;
