import React, { Fragment, useEffect, useState, forwardRef } from 'react'
import { I18n } from 'react-redux-i18n';
import { getPath, i18nl, TRG } from '../../../../../constants/i18n';
import {Dialog, Button, NavButtons, MBForm, completeStep, getFormValues, getFromAppStore, showToast, toastIDs} from '@mb-react/mb-react-core';
import {useDispatch, useSelector} from 'react-redux';
import {applicationStore, appStoreFields, wizardSteps} from '../../../../../constants/store';
import moment from 'moment';
import { preparePrefferedChannelsForDropDown } from '../../../../../util/environment';
import { b64toBlob, loadFile, prepareServerStringDate } from '../../../../../sagas/generators/sagaUtil';
import { ACCOUNT_ID, accountMode } from '../../../../../constants/general';
import { APPOINTMENT_WF_EDIT } from '../../../../../util/businesslogicUtil';
import SummaryContactForm from './SummaryContactForm';
import {
    AppointmentService,
    useCreateServiceWrapper,
    ServiceRequestData,
    CreateAppointmentRequest,
    GetGenerateAppointmentReportRequest,
    useHandleCreateAppointmentErrorResponse,
    formFieldNames,
    getCookie, isRequired, restErrors
} from '@mb-react/svarog-ui'
import {EMAIL_REGEX, forms, PHONE_REGEX} from "../../../../../constants/form";
import {ROOT} from "../../../../../constants/environment";
import NavButtonsLocal from "../../../../reactCoreComponents/NavButtonsLocal";

const SummaryAppointmentTask = forwardRef((props, ref) => {
    const selectedService = useSelector((state) => getFromAppStore(state, appStoreFields.selectedService))
    const selectedLocation = useSelector((state) => getFromAppStore(state, appStoreFields.selectedLocation))
    const selectedProviderKey = useSelector((state) => getFromAppStore(state, appStoreFields.selectedProvider))
    const selectedProviderName = useSelector((state) => getFromAppStore(state, appStoreFields.selectedProviderName))
    const selectedDay = useSelector((state) => getFromAppStore(state, appStoreFields.selectedDay))
    const selectedTimeSlot = useSelector((state) => getFromAppStore(state, appStoreFields.selectedTimeSlot))
    const applicationType = useSelector((state) => getFromAppStore(state, applicationStore.WORKFLOW_TYPE))
    const appointmentId = useSelector((state) => getFromAppStore(state, appStoreFields.appointmentId))
    const createdAppointmentId = useSelector((state) => getFromAppStore(state, appStoreFields.createdAppointmentId))
    const formValues = useSelector((state) => getFormValues(state, forms.BOOK_APPOINTMENT_FORM));

    const [preferredNotification, setPreferredNotification] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [appointmentReport, setAppointmentReport] = useState();
    const serviceWrapper = useCreateServiceWrapper();
    const handleCreateAppointmentError = useHandleCreateAppointmentErrorResponse();

    let dispatch = useDispatch();

    const getAllPreferredChannels = () => {
        serviceWrapper(new ServiceRequestData(
            AppointmentService.fetchAllPreferredChannels,
            null,
            null,
            null,
            (response) => {
                let data = response.data
                if (data && data.channels) {
                    setPreferredNotification(preparePrefferedChannelsForDropDown(data.channels))
                }
            },
            null
        ))
    }

    const maxNumberOfAppointmetsMessage = () => {
        dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {messageId: I18n.t("err." + restErrors.ERROR_MAX_NUMBER_OF_APPOINTMENTS_REACHED)}))
    };

    const generateAppointmentReport = (appointmentId) => {
        let appointmentRequest = new GetGenerateAppointmentReportRequest()
        appointmentRequest.accountId = getCookie("accountId")
        appointmentRequest.appointmentId = appointmentId
        serviceWrapper(new ServiceRequestData(
            AppointmentService.generateAppointmentReport,
            appointmentRequest,
            null,
            handleCreateAppointmentError,
            (response) => {
                let data = response.data
                setAppointmentReport(data)
            },
            null
        ))
    }
    const createAppointmentDTO = () => {
        let appointmentDTO = new CreateAppointmentRequest()
        appointmentDTO.id = createdAppointmentId
        appointmentDTO.appointmentId = appointmentId
        appointmentDTO.providerID = selectedProviderKey.value
        appointmentDTO.startTime = selectedTimeSlot
        appointmentDTO.date = prepareServerStringDate(selectedDay, "YYYY-MM-DD")
        appointmentDTO.applicationID = 'AppNameRandom' + Math.random().toString(36).substring(7)
        appointmentDTO.serviceID = selectedService.code
        appointmentDTO.phone = formValues[formFieldNames.USER_PHONE]
        appointmentDTO.email = formValues[formFieldNames.USER_EMAIL]
        appointmentDTO.applicantName = formValues[formFieldNames.USERNAME]
        appointmentDTO.serviceName = selectedService.name
        appointmentDTO.providerName = selectedProviderName
        appointmentDTO.preferredNotificationChannel = formValues[formFieldNames.PREFERRED_NOTIFICATION_CHANNEL]
        appointmentDTO.accountID = getCookie(ACCOUNT_ID)
        appointmentDTO.accountMode = accountMode.PUBLIC
        appointmentDTO.locationID = selectedLocation && selectedLocation.data ? selectedLocation.data.split("-")[0] : null
        return appointmentDTO
    }
    const createAppointmentFunc = () => {
        let serviceToCall = AppointmentService.createAppointment
        if (applicationType && applicationType == APPOINTMENT_WF_EDIT) {
            serviceToCall = AppointmentService.rescheduleAppointment

        }
        dispatch(completeStep(wizardSteps.STEP4))
        serviceWrapper(new ServiceRequestData(
            serviceToCall,
            createAppointmentDTO(),
            null,
            null,
            (response) => {
                let id = response.id
                if(applicationType && applicationType == APPOINTMENT_WF_EDIT){
                    id = createdAppointmentId
                }
                if (id) {
                    setShowDialog(true)
                    generateAppointmentReport(id)
                }
            },
            null,
            null,
            null,
            null,
            maxNumberOfAppointmetsMessage
        ))
    }
    useEffect(() => {
        getAllPreferredChannels()
    }, [])
    useEffect(() => {
        if (appointmentReport) {
            let blob = b64toBlob(appointmentReport, 'application/pdf');
            let url = window.URL.createObjectURL(blob);

            document.getElementById("appSummaryFrame").src = url + "#toolbar=0";
        }
    }, [appointmentReport])

    const downloadReport = () => {
        loadFile(appointmentReport, "My_Appointment_Report.pdf");

    }
    let url = APPOINTMENT_WF_EDIT === applicationType ? ROOT + `/myAppointments` : ROOT + "/";

    const renderFooter = () => {
        return (
            <div>
                <Button label={I18n.t(getPath(i18nl.FINISH, TRG.ACTION))} icon="pi pi-check" onClick={() => window.location = url} className="p-button-text" />
                <Button label={I18n.t(getPath(i18nl.DOWNLOAD, TRG.ACTION))} icon="pi pi-file-pdf" onClick={() => downloadReport()} autoFocus />
            </div>
        );

    }

    const summaryData = [
        { name: I18n.t(getPath(i18nl.SERVICE)), value: selectedService.name, icon: 'pi pi-cog', color: 'rgb(85, 73, 148)' },
        { name: I18n.t(getPath(i18nl.LOCATION)), value: selectedLocation.label, icon: 'pi pi-map-marker', color: '#ea842c' },
        { name: I18n.t(getPath(i18nl.PROVIDER)), value: selectedProviderName, icon: 'pi pi-flag', color: 'rgb(85, 73, 148)' },
        { name: I18n.t(getPath(i18nl.DATE)), value: moment(selectedDay).format('DD/MM/YYYY'), icon: 'pi pi-calendar', color: '#ea842c' },
        { name: I18n.t(getPath(i18nl.TIME)), value: selectedTimeSlot, icon: 'pi pi-clock', color: 'rgb(85, 73, 148)' }

    ];
    const customizedMarker = (item) => {
        return (
            <span className="custom-marker p-shadow-2">
        <i className={item.icon} style={{ color: 'rgb(85, 73, 148)' }}></i>
      </span>
        );
    };
    const summaryContent = () => {
        let content = summaryData.map(x =>
            <div className="grid summaryCol">
                <div className="col-12 xl:col-6 summary">
                    {customizedMarker(x)} {x.name}
                </div>
                <div className="col-12 xl:col-6 summaryValue">
                    {x.value}
                </div>
            </div>
        );


        return content
    }

    const validationFunc = (values) => {
        let errors = {}
        let validatePhone = false;
        let validateMail = false;

        if(values && values[formFieldNames.PREFERRED_NOTIFICATION_CHANNEL]){
            switch (values[formFieldNames.PREFERRED_NOTIFICATION_CHANNEL]) {
                case "EMAIL":
                    validateMail = true
                    break;
                case "PHONE":
                    validatePhone = true
                    break;
                case "PHONE_EMAIL":
                    validateMail = true
                    validatePhone = true
                    break;
            }
        }

        isRequired(values, formFieldNames.USERNAME, errors)
        isRequired(values, formFieldNames.PREFERRED_NOTIFICATION_CHANNEL, errors)
        if(validateMail){
            isRequired(values, formFieldNames.USER_EMAIL, errors)
            if(values[formFieldNames.EMAIL] && !values[formFieldNames.EMAIL].match(EMAIL_REGEX)){
                errors[formFieldNames.EMAIL] = I18n.t('label.wrongEmailFormat')
            }
        }
        if(validatePhone){
            isRequired(values, formFieldNames.USER_PHONE, errors)
            if(values[formFieldNames.PHONE] && !values[formFieldNames.PHONE].match(PHONE_REGEX)){
                errors[formFieldNames.PHONE] = I18n.t('label.wrongPhoneFormat')
            }
        }

        return errors
    }

    return (
        <Fragment>
            <Dialog header={I18n.t(getPath(i18nl.SUCCESSFULLY_CREATED_REPORT, TRG.MESSAGE))} visible={showDialog} style={{ width: '54vw' }} footer={renderFooter} onHide={() => setShowDialog(false)}>
                <div className="card">
                    <div className="card jt-center">
                        <div className="panel-body">
                            <div className="center-content">
                                <iframe style={{ height: '700px', width: '1000px' }} id="appSummaryFrame" />
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <MBForm formName={forms.BOOK_APPOINTMENT_FORM} validate={validationFunc} submitFunction={() => createAppointmentFunc()}>
                <div className="grid summaryWrapper">
                    <div className="col-12 xl:col-4">
                        <SummaryContactForm chanelsOptions={preferredNotification} />
                    </div>
                    <div className="xl:col-offset-2 col-12 xl:col-6">
                        <div className="card bookSummary">
                            {summaryContent()}
                        </div>
                    </div>
                    <div className="col-12">
                        <NavButtonsLocal lastStep />
                    </div>
                </div >
            </MBForm>

        </Fragment >

    )
});

export default SummaryAppointmentTask;