import { genericFormFieldNames } from "@mb-react/krg-getid-form-data-common"
import {getFormValues, removeFromDictionaryStore, change, getFromAppStore} from "@mb-react/mb-react-core"
import { formFieldNames } from "@mb-react/svarog-ui"
import { useDispatch, useSelector } from "react-redux"
import { formFieldsMeta, forms } from "../../../../../../constants/form"
import { dictionaryReference, projectDictionaries } from "../../../../../../constants/general"
import { useHandleCommonServices } from "../../../../../../services/commonServiceHandler"
import { isMandatory, isReadOnly } from "../../../../../../util/businesslogicUtil"
import { formatDate } from "../../../../../../util/dateUtil"
import {useMemo} from "react";
import {useForm} from "react-final-form";
import {ACCOUNT_IDENTITY} from "../../../../../../constants/application";

export const useGetFoodCenterDetailsFieldsSettings = (props) => {
    const dispatch = useDispatch()
    const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION))
    let addressDataFormValues = useSelector((state) => getFormValues(state, props.formName))
    let personalInformationFormValues = useSelector((state) => getFormValues(state, forms.PERSONAL_INFORMATION + props.formNameSufix))
    const accountIdentity = useSelector(state => getFromAppStore(state, ACCOUNT_IDENTITY));

    addressDataFormValues = {...addressDataFormValues, ...personalInformationFormValues, ...formValues}
    let form = useForm();

    const handleCommonServices = useHandleCommonServices()

    let today = new Date();

    let years125Before = new Date();
    years125Before.setFullYear(years125Before.getFullYear() - 125);

    const onCateringCenterProvinceChange = (event) => {
        form.change(genericFormFieldNames.CATERING_CENTER_NUMBER_NAME, "")
        dispatch(removeFromDictionaryStore(dictionaryReference.CATERING_CENTER_NUMBER))
        handleCommonServices.handleGetSingleDictionary(projectDictionaries.FOOD, event.target.value)
    }

    const onFoodCenterIndicatorChange = () => {
        form.change(genericFormFieldNames.CATERING_CENTER_PROVINCE, "")
        form.change(genericFormFieldNames.CATERING_CENTER_NUMBER_NAME, "")
        form.change(genericFormFieldNames.FOOD_RATION_CARD_ISSUE_DATE, "")
        form.change(genericFormFieldNames.FOOD_RATION_CARD_NUMBER, "")
    }

    const handleFoodCenterIndicatorSettings = useMemo(() => {
        let registrationDataProps = {
            [genericFormFieldNames.FOOD_CENTER_INDICATOR]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(addressDataFormValues, genericFormFieldNames.FOOD_CENTER_INDICATOR, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                    [formFieldNames.ON_CHANGE]: onFoodCenterIndicatorChange
                },
                required: isMandatory(addressDataFormValues, genericFormFieldNames.FOOD_CENTER_INDICATOR, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return registrationDataProps
    }, [])

    const handleCateringCenterProvinceSettings = useMemo(() => {
        let registrationDataProps = {
            [genericFormFieldNames.CATERING_CENTER_PROVINCE]: {
                [formFieldNames.FIELD_PROPS]: {
                    reference: projectDictionaries.PROVINCES,
                    [formFieldNames.ON_CHANGE]: onCateringCenterProvinceChange,
                    readOnly: isReadOnly(addressDataFormValues, genericFormFieldNames.CATERING_CENTER_PROVINCE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                required: isMandatory(addressDataFormValues, genericFormFieldNames.CATERING_CENTER_PROVINCE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return registrationDataProps
    }, [addressDataFormValues[genericFormFieldNames.FOOD_CENTER_INDICATOR]])

    const handleCateringCenterNumberNameSettings = useMemo(() => {
        let registrationDataProps = {
            [genericFormFieldNames.CATERING_CENTER_NUMBER_NAME]: {
                [formFieldNames.FIELD_PROPS]: {
                    reference: projectDictionaries.FOOD,
                    readOnly: isReadOnly(addressDataFormValues, genericFormFieldNames.CATERING_CENTER_NUMBER_NAME, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                required: isMandatory(addressDataFormValues, genericFormFieldNames.CATERING_CENTER_NUMBER_NAME, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return registrationDataProps
    }, [addressDataFormValues[genericFormFieldNames.FOOD_CENTER_INDICATOR], addressDataFormValues[genericFormFieldNames.CATERING_CENTER_PROVINCE]])

    const handleFoodRationCardNumberSettings = useMemo(() => {
        let registrationDataProps = {
            [genericFormFieldNames.FOOD_RATION_CARD_NUMBER]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(addressDataFormValues, genericFormFieldNames.FOOD_RATION_CARD_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                required: isMandatory(addressDataFormValues, genericFormFieldNames.FOOD_RATION_CARD_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return registrationDataProps
    }, [addressDataFormValues[genericFormFieldNames.FOOD_CENTER_INDICATOR]])

    const handleFoodRationCardDoISettings = useMemo(() => {
        let registrationDataProps = {
            [genericFormFieldNames.FOOD_RATION_CARD_ISSUE_DATE]: {
                [formFieldNames.FIELD_PROPS]: {
                    minDate: years125Before, maxDate: today,
                    dateFormat: formatDate(addressDataFormValues[genericFormFieldNames.FOOD_RATION_CARD_ISSUE_DATE]),
                    readOnly: isReadOnly(addressDataFormValues, genericFormFieldNames.FOOD_RATION_CARD_ISSUE_DATE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                required: isMandatory(addressDataFormValues, genericFormFieldNames.FOOD_RATION_CARD_ISSUE_DATE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return registrationDataProps
    }, [addressDataFormValues[genericFormFieldNames.FOOD_CENTER_INDICATOR]])

    return { ...handleFoodCenterIndicatorSettings, ...handleCateringCenterProvinceSettings, ...handleCateringCenterNumberNameSettings,
    ...handleFoodRationCardNumberSettings, ...handleFoodRationCardDoISettings}
}