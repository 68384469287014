import React from 'react';
import {addToAppStore, Button} from "@mb-react/mb-react-core/dist";
import {I18n} from "react-redux-i18n";
import {formFieldNames} from '@mb-react/svarog-ui';
import {forms} from "../../../../../constants/form";
import {MBForm, FormElement, getFormValues, fieldType, showSpinner, hideSpinner} from "@mb-react/mb-react-core";
import {useHandleCommonServices} from "../../../../../services/commonServiceHandler";
import {applicationStore} from "../../../../../constants/store";
import {useDispatch, useSelector} from "react-redux";
import {projectDictionaries} from "../../../../../constants/general";
import {prepareSpecificDictionaryElementForDropdown} from "../../../../../util/dataPreparationUtil";
import {getDropdownLabelFromKey} from "../../../../../util/businesslogicUtil";

function LegalEntitySearch(props) {
    const handleCommonServices = useHandleCommonServices();
    const dispatch = useDispatch();
    const formValues = useSelector((state) => getFormValues(state, forms.LE_SEARCH_FORM))
    const leType = useSelector((state) => prepareSpecificDictionaryElementForDropdown(state, projectDictionaries.LEGAL_ENTITY_TYPE))


    const disabledSearchLegalEntities = !(formValues
        && (formValues[formFieldNames.TPID]
            || formValues[formFieldNames.TPID_PARENT]
            || formValues[formFieldNames.LE_NAME]
            || formValues[formFieldNames.LE_TYPE])
    );

    const fetchLegalEntityHandler = () => {
        const requestData = {
            name: formValues[formFieldNames.LE_NAME] ? formValues[formFieldNames.LE_NAME] : null,
            tpid: formValues[formFieldNames.TPID] ? formValues[formFieldNames.TPID] : null,
            parentTPID: formValues[formFieldNames.TPID_PARENT] ? formValues[formFieldNames.TPID_PARENT] : null,
            type: formValues[formFieldNames.LE_TYPE] ? getDropdownLabelFromKey(leType, formValues[formFieldNames.LE_TYPE]) : null
        };
        dispatch(showSpinner(I18n.t('message.searchLE')))
        handleCommonServices.handleSearchLegalEntities(requestData, response => {
            dispatch(hideSpinner())
            dispatch(addToAppStore(applicationStore.LEGAL_ENTITY_DATA, response));
        }, () => {
            dispatch(hideSpinner())
            dispatch(addToAppStore(applicationStore.LEGAL_ENTITY_DATA, null));
        })
    };

    return (
        <MBForm formName={forms.LE_SEARCH_FORM} submitFunction={() => fetchLegalEntityHandler()}>
            <div className='grid appSearchForm'>
                <FormElement
                    additionalClass={"col-3"}
                    label={"label.uen"}
                    fieldType={fieldType.INPUT_TEXT}
                    fieldProps={{
                        maxLength: 13,
                        name: formFieldNames.TPID,
                    }}
                />
                <FormElement
                    additionalClass={"col-3"}
                    label={"label." + formFieldNames.LE_TYPE}
                    fieldType={fieldType.INPUT_DROPDOWN}
                    fieldProps={{
                        name: formFieldNames.LE_TYPE,
                        selectOptions: leType,
                        showClear: true
                    }}
                />
                <FormElement
                    additionalClass={"col-3"}
                    label={"label." + formFieldNames.LE_NAME}
                    fieldType={fieldType.INPUT_TEXT}
                    fieldProps={{
                        name: formFieldNames.LE_NAME,
                    }}
                />
                <FormElement
                    additionalClass={"col-3"}
                    label={"label." + formFieldNames.TPID_PARENT}
                    fieldType={fieldType.INPUT_TEXT}
                    fieldProps={{
                        name: formFieldNames.TPID_PARENT,
                    }}
                />
            </div>
            <div className='grid appSearchForm'>
                <Button
                    label={I18n.t("action.search")}
                    icon="pi pi-search"
                    onClick={fetchLegalEntityHandler}
                    type={"button"}
                    className="p-button-primary mr-2"
                    disabled={disabledSearchLegalEntities}
                />
            </div>
        </MBForm>
    );
}

export default LegalEntitySearch;