import React from 'react';
import VehicleSearchForm from "./VehicleSearchForm";
import VehicleTableResult from "./VehicleTableResult";

function VehicleSearchWrapper(props) {
  return (
    <>
      <VehicleSearchForm/>
      <VehicleTableResult additionalVehicle={props.isAdditionalVehicle}/>
    </>
  );
}

export default VehicleSearchWrapper;