import {
  getFormValues, getFromAppStore, prepareDictionaryElementForDropdown,
} from "@mb-react/mb-react-core"
import { formFieldNames,  } from "@mb-react/svarog-ui"
import { useSelector} from "react-redux"
import { formFieldsMeta, forms } from "../../../../../../constants/form"
import {
  disablePlateHandlingTask,
  getAccountLegalEntityType,
  isLoggedAccountLegalEntity,
  isMandatory,
  isReadOnly,
  prepareLETypeForDropDownList
} from "../../../../../../util/businesslogicUtil"
import {useMemo} from "react";
import {projectDictionaries} from "../../../../../../constants/dictionaries";
import { genericFormFieldNames } from "@mb-react/krg-getid-form-data-common"
import {useForm} from "react-final-form";
import {prepareSpecificDictionaryElementForDropdown} from "../../../../../../util/dataPreparationUtil";
import {accountSubType, plateCategories} from "../../../../../../constants/application";
import {applicationStore} from "../../../../../../constants/store";

export const usePlateDataSettings = () => {
  const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION))
  const form = useForm();
  let plateDataFormValues = form?.getState()?.values ?? {}
  const vehicleFromSystem = useSelector((state) => getFromAppStore(state, applicationStore.VEHICLE_FROM_SYSTEM));
  const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))
  const plateDataHelper = useSelector((state) => getFromAppStore(state, applicationStore.PLATE_DATA_HELPER));

  const plateUsageOptions = useSelector((state) => prepareSpecificDictionaryElementForDropdown(state, projectDictionaries.PLATE_USAGE));

  const desiredPlateNumberReserved = useSelector((state) =>
        getFromAppStore(state, "desiredPlateNumberReserved")
    )

  //const excludeLostOrStolen = !isUseCaseReplaceVR(formValues);
  //plate handling task is returned to several UCs with different purpose RM ticket #228066
  const excludeLostOrStolen = true;
  const excludeAdditionalPlates = true //looks like getID logic !isUseCaseChangeOwnership(formValues);

  const onUsageChange = () => {
    form.change(formFieldNames.PLATE_NUMBER, null);
    form.change(formFieldNames.CAPTCHA, null);
    form.change(formFieldNames.PLATE_PROVINCE, null);
    form.change(genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN, null);
    form.change(formFieldNames.PLATE_COUNTRY_CODE, null)
  }

  const plateLostOrStolenSettings = useMemo(() => {
    let plateDataProps = {
      [genericFormFieldNames.LOST_OR_STOLEN]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(plateDataFormValues, genericFormFieldNames.LOST_OR_STOLEN, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        },
        required: isMandatory(plateDataFormValues, genericFormFieldNames.LOST_OR_STOLEN, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        exclude: excludeLostOrStolen,
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return plateDataProps
  }, [])

  const plateAdditionalPlatesSettings = useMemo(() => {
    let plateDataProps = {
      [genericFormFieldNames.ADDITIONAL_PLATES]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(plateDataFormValues, genericFormFieldNames.ADDITIONAL_PLATES, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        },
        required: isMandatory(plateDataFormValues, genericFormFieldNames.ADDITIONAL_PLATES, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        exclude: excludeAdditionalPlates,
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return plateDataProps
  }, [])

  const plateTypeSettings = useMemo(() => {
    let plateDataProps = {
      [formFieldNames.PLATE_TYPE]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(plateDataFormValues, formFieldNames.PLATE_TYPE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: projectDictionaries.PLATE_TYPES,
        },
        exclude: true,
        required: isMandatory(plateDataFormValues, formFieldNames.PLATE_TYPE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return plateDataProps
  }, [])

  {/* plate category is plate class */}
  {/*Excluded based on PlateHandling.jpg provided by dr P. Jovanovic */}
  {/*Plate category comes from dictionary and has vales standard, other, special and motorcycle */}
  {/*field handled on PlateDataTask.js component out of FDC */}
  const plateCategorySettings = useMemo(() => {
    let excludePlateCategory = true;
    if(plateDataFormValues && plateDataFormValues[formFieldNames.PLATE_TYPE]){
      switch (plateDataFormValues[formFieldNames.PLATE_TYPE]) {
        case "Standard":
          excludePlateCategory = true;
          break;
        case "Special":
          excludePlateCategory =  false;
          break;
        case "MotorCycle":
          excludePlateCategory =  true;
          break;
        case "Other":
          excludePlateCategory =  true;
          break;
      }
    }

    let plateDataProps = {
      [formFieldNames.PLATE_CATEGORY]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(plateDataFormValues, formFieldNames.PLATE_CATEGORY, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: projectDictionaries.PLATE_USAGE_SPECIAL,
        },
        exclude: true,
        required: isMandatory(plateDataFormValues, formFieldNames.PLATE_CATEGORY, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return plateDataProps
  }, [])


  const plateCountryCodeSettings = useMemo(() => {
    let excludePlateCountryCode = true;
    if(plateDataFormValues && plateDataFormValues[formFieldNames.PLATE_CATEGORY]){
      switch (plateDataFormValues[formFieldNames.PLATE_CATEGORY]) {
        case "standard":
          excludePlateCountryCode = true;
          break;
        case "special":
          excludePlateCountryCode =  false;
          break;
        case "motorcycle":
          excludePlateCountryCode =  true;
          break;
        case "other":
          excludePlateCountryCode =  true;
          break;
      }
    }

    let plateDataProps = {
      [formFieldNames.PLATE_COUNTRY_CODE]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(plateDataFormValues, formFieldNames.PLATE_COUNTRY_CODE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]) || plateDataHelper,
          reference: projectDictionaries.COUNTRY_CODES,
          showClear: true
        },
        exclude: excludePlateCountryCode,
        required: isMandatory(plateDataFormValues, formFieldNames.PLATE_COUNTRY_CODE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return plateDataProps
  }, [plateDataFormValues[formFieldNames.PLATE_CATEGORY]])

  const plateProvinceSettings = useMemo(() => {

    let plateUsage = plateDataFormValues[formFieldNames.PLATE_USAGE];

    let excludeProvince = true;
    if(plateDataFormValues && plateDataFormValues[formFieldNames.PLATE_CATEGORY]){
      switch (plateDataFormValues[formFieldNames.PLATE_CATEGORY]) {
        case "standard":
          excludeProvince = false;
          break;
        case "special":
          excludeProvince =  true;
          break;
        case "motorcycle":        // todo we have it for natural person and LE when usage Private
            if(isLoggedAccountLegalEntity()){
              if(plateUsage === "private" && ![accountSubType.GOVERNMENT, accountSubType.COMPANY, accountSubType.NATIONAL_ORGANIZATION].includes(getAccountLegalEntityType())){
                excludeProvince =  true;
              }else if (plateUsage !== "private" && [accountSubType.GOVERNMENT, accountSubType.COMPANY, accountSubType.NATIONAL_ORGANIZATION].includes(getAccountLegalEntityType())){
                excludeProvince = true;
              }else{
                excludeProvince =  false;
              }
            }else{
              excludeProvince =  false;
            }
          break;
        case "other":
          excludeProvince =  true;
          break;
      }
    }


    let plateDataProps = {
      [formFieldNames.PLATE_PROVINCE]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: disablePlateHandlingTask({...formValues, ...plateDataFormValues}, vehicleFromSystem, userRights) || desiredPlateNumberReserved || isReadOnly(plateDataFormValues, formFieldNames.PLATE_PROVINCE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]) || plateDataHelper,
          reference: projectDictionaries.GOVERNORATE,
          showClear: true,
        },
        exclude: excludeProvince,
        required: isMandatory(plateDataFormValues, formFieldNames.PLATE_PROVINCE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return plateDataProps
  }, [plateDataFormValues[formFieldNames.PLATE_CATEGORY], plateDataFormValues[formFieldNames.PLATE_USAGE], desiredPlateNumberReserved])

  const plateUsageSettings = useMemo(() => {

    let plateCategory = plateDataFormValues[formFieldNames.PLATE_CATEGORY];
    let usageOptions = plateUsageOptions;

    if(isLoggedAccountLegalEntity()){
      switch (plateCategory) {
        case plateCategories.MOTORCYCLE:
          usageOptions = plateUsageOptions.filter(e => ["private", prepareLETypeForDropDownList(getAccountLegalEntityType(), plateCategories.MOTORCYCLE)].includes(e.value))
          break;
        case plateCategories.OTHER:
          usageOptions = plateUsageOptions.filter(e => e.value === prepareLETypeForDropDownList(getAccountLegalEntityType(), plateCategories.OTHER))
          break;
        case plateCategories.STANDARD:
          if(getAccountLegalEntityType() === "GOVERNMENT"){
            usageOptions = plateUsageOptions.filter(e => e.value === "private")
          }
          break;
      }
    }else{
      if(plateDataFormValues[formFieldNames.PLATE_CATEGORY] === "motorcycle"){
        usageOptions = plateUsageOptions.filter(e => e.value === "private")
      }
    }

    let plateDataProps = {
      [formFieldNames.PLATE_USAGE]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: disablePlateHandlingTask({...formValues, ...plateDataFormValues}, vehicleFromSystem, userRights) || desiredPlateNumberReserved || isReadOnly(plateDataFormValues, formFieldNames.PLATE_USAGE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]) || plateDataHelper,
          selectOptions: usageOptions,
          showClear: true,
          [formFieldNames.ON_CHANGE]: onUsageChange
        },
        required: isMandatory(plateDataFormValues, formFieldNames.PLATE_USAGE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return plateDataProps
  }, [plateUsageOptions])

  return {...plateTypeSettings, ...plateCategorySettings, ...plateCountryCodeSettings, ...plateProvinceSettings, ...plateUsageSettings,
        ...plateLostOrStolenSettings, ...plateAdditionalPlatesSettings}
}