import axios from "axios";
import {getCookie} from "@mb-react/svarog-ui";
import {REQUEST_OBJECT} from "../../constants/services";


const ACCESS_TOKEN_NAME = configurationData && configurationData.accessTokenName ? configurationData.accessTokenName : "jwt"
const VEHICLE_SERVICE_ENDOPOINT = '/vehicle-service/public/vehicles'
const VEHICLE_SERVICE_INTERNAL_ENDOPOINT = '/vehicle-service/vehicles'
const UPDATE_VEHICLE_BY_TRANSACTION_ID = '/vehicle-service/vehicles/update-vehicle-by-transaction-id'

let GATEWAY_URL = configurationData?.gatewayURL ? configurationData.gatewayURL : "http://localhost:10300";


export default class VehicleService {

    static saveVehicle(data, requestHandler) {
        return axios.post(GATEWAY_URL + "/vehicle-service/public/vehicles", data, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    static saveVehiclePrivate(data, requestHandler) {
        return axios.post(GATEWAY_URL + "/vehicle-service/vehicles", data, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    static updateVehicleByTransactionId(data, requestHandler) {
        return axios.post(GATEWAY_URL + UPDATE_VEHICLE_BY_TRANSACTION_ID, data, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    static getMyVehicles(requestData, requestHandler) {

        let data = requestData[REQUEST_OBJECT]
        let GET_MY_VEHICLES = '/get-my-vehicles?'
        if (requestData.page) {
            GET_MY_VEHICLES = GET_MY_VEHICLES + '&page=' + requestData.page;
        }

        if (requestData.sizePerPage) {
            GET_MY_VEHICLES = GET_MY_VEHICLES + '&sizePerPage=' + requestData.sizePerPage;
        }
        return axios.get(GATEWAY_URL + "/vehicle-service/public/vehicles" + GET_MY_VEHICLES, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    static getVehicleByTransactionId(requestData, requestHandler){
        return axios.get(GATEWAY_URL + "/vehicle-service/vehicles/transaction/" + requestData.transactionId, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    static getVehicleByVid(requestData){
        return axios.get(GATEWAY_URL + "/vehicle-service/public/vehicles/" + requestData.vid, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    static isVehicleExists(requestData){
        return axios.post(GATEWAY_URL + "/vehicle-service/public/vehicles/is-vehicle-exists", requestData, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

}