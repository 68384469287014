import {all, fork, takeEvery} from 'redux-saga/effects';
import {sagaActions} from '../constants/action';
import { testSaga } from './generators/applicationSaga';

export function* sagas() {
  yield all([
    fork(getTestSaga),
  ]);
}

function* getTestSaga() {
  yield takeEvery(sagaActions.TEST_ACTION, testSaga)
}
