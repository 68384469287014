import {
    NavigationWizardWrapper,
    getFromAppStore,
} from "@mb-react/mb-react-core";
import React, {useEffect} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {forms, navigationName} from "../../../../constants/form";
import {getListOfNavItemsByUC} from "../../../../constants/navigationItems";
import {disableDraft, isOperationModeProtected} from "../../../../util/businesslogicUtil";
import {isMobile} from "../../../../util/environment";
import {useHandleWorkflowSettings} from "../../../entries/application/handleWorkflowSettings";
import {handleWorkflowTasks} from "../../../entries/application/handleWorkflowTasks";
import VehicleQuestionnaireData from "./VehicleQuestionnaireData";
import {applicationStore} from "../../../../constants/store";
import {addToAppStore} from "@mb-react/mb-react-core/dist";

const VehicleApplicationWorkflow = () => {
    const handleWorkflowSettings = useHandleWorkflowSettings();
    useSelector(state => state.i18n.locale)
    const dispatch = useDispatch();
    const questionnariesCompleted = useSelector((state) =>
        getFromAppStore(state, "questionnariesCompletedVR")
    );
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))
    const workflowTasks = handleWorkflowTasks()
    useEffect(() => {
        handleWorkflowSettings.initWorkflowSettings();
        let year = new Date();
        year = year.getFullYear();
        let validationDigit = year % 10;
        dispatch(addToAppStore(applicationStore.TEMPORARY_PLATE_NUMBER_VALIDATION_DIGIT, validationDigit))
    }, []);

    let listOfNavItems = getListOfNavItemsByUC();

    let items = listOfNavItems ? listOfNavItems.map(item => workflowTasks.navigationItems()[item]) : null;

    return (
        <div className="card navigation">
            {(!questionnariesCompleted && !isOperationModeProtected() && !configurationData.useDDL) && <VehicleQuestionnaireData/>}
            {((questionnariesCompleted || configurationData.useDDL) || (isOperationModeProtected() && userRights)) && items &&
                <NavigationWizardWrapper items={items}
                  progressBar={!isMobile()}
                  readOnly={disableDraft(userRights)}
                  navigationId={navigationName.MAIN_NAVIGATION}
                  navigationType={isMobile() ? "CIRCULAR" : "CIRCLE"}
                  formName={forms.APPLICATION}/>}
        </div>
    );
};

VehicleApplicationWorkflow.propTypes = {};

function mapStateToProps(state) {
    return {
        activeLocale: state.i18n.locale,
    };
}

export default connect(mapStateToProps)(VehicleApplicationWorkflow);
