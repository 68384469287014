import {
  extractFormValuesToApplicationDataWithMultiData,
  extractFormValuesToApplicationData,
  InterfaceAdapter,
  ServiceRequestData,
  useCreateServiceWrapper,
  ProcessStateController
} from "@mb-react/svarog-ui";
import { useHandleGetSingleDictionarySuccessResult } from "./application/successGetDictionaryHandler";
import VehicleService from "./vehicle/VehicleService"
import { IdentityManagementService } from "./identityManagement/IdentityManagementService";
import InterfaceAdapterService from "./interfaceAdapter/InterfaceAdapterService";
import {useHandleGetAvailableLicenseNumbersSuccessResult, useHandleGetAvailableLicenseNumbersErrorResult} from "./vehicle/handleVehicleResponse";
import {
  applicantGenericFieldsList,
  applicationGenericFieldsList,
  proxyGenericFieldsList
} from "@mb-react/krg-getid-form-data-common";
import {handleOnCountApplicationsError} from "./application/handleApplicationServiceResponse";
import {useHandleSearchVehiclesSuccessResult} from "./customServiceHandlers/handleVehicleResponse";

export function useHandleCommonServices() {
  const callService = useCreateServiceWrapper();
  const handleGetSingleDictionarySuccessResult = useHandleGetSingleDictionarySuccessResult();
  const handleGetAvailableLicenseNumbersSuccessResult = useHandleGetAvailableLicenseNumbersSuccessResult();
  const handleGetAvailableLicenseNumbersErrorResult = useHandleGetAvailableLicenseNumbersErrorResult();
  const onCountApplicationsError = handleOnCountApplicationsError();
  const handleSearchVehicleOnSuccess = useHandleSearchVehiclesSuccessResult();

  const handleCallGetSingleDictionary = (code, parent, reference) => {
    let parentKey;
    if (parent) {
      if (parent.includes(";")) {
        parentKey = parent.split(";")[0]
      } else {
        parentKey = parent
      }
    }
    callService(new ServiceRequestData(
      InterfaceAdapter.getSingleDictionary,
      {
        code: code,
        parent: parentKey,
        reference: reference
      },
      handleGetSingleDictionarySuccessResult,
      null,
      null,
      null
    ))
  };

  const handleCallDownloadPreEnrollmentSummaryReport = (formValues, genericFormFieldNames, onLocalSuccess) => {
    callService(new ServiceRequestData(
      InterfaceAdapter.preEnrollmentReportPdf,
      extractFormValuesToApplicationData(formValues, Object.values(genericFormFieldNames)),
      null,
      null,
      onLocalSuccess,
      null
    ))
  };
  const handleCallSaveApplication = (requestObject, onLocalSuccess, onErrorLocal) => {
    callService(new ServiceRequestData(
      ProcessStateController.saveApplication,
      requestObject,
      null,
      null,
      onLocalSuccess,
        onErrorLocal,
        null,
        null,
        null,
        onErrorLocal
    ))
  };

  const handleGetCallSaveApplicationInternal = (requestObject, onLocalSuccess) => {
    callService(new ServiceRequestData(
      ProcessStateController.saveApplicationInternal,
      requestObject,
      null,
      null,
      onLocalSuccess,
      null
    ))
  }

  const handleCallCountApplications = (requestObject, onLocalSuccess) => {
    callService(new ServiceRequestData(
      ProcessStateController.countApplicationsByOptionsInternal,
      requestObject,
      null,
      onCountApplicationsError,
      onLocalSuccess,
      null
    ))
  };

  const handleCallSaveVehicle = (requestObject, onLocalSuccess, onErrorLocal) => {
    callService(new ServiceRequestData(
        VehicleService.saveVehicle,
        requestObject,
        null,
        null,
        onLocalSuccess,
        onErrorLocal,
        null,
        null,
        null,
        onErrorLocal
    ))
  }

  const handleCallUpdateVehicleByTxId = (requestObject, onLocalSuccess, onErrorLocal) => {
    callService(new ServiceRequestData(
        VehicleService.updateVehicleByTransactionId,
        requestObject,
        null,
        null,
        onLocalSuccess,
        onErrorLocal,
        null,
        null,
        null,
        onErrorLocal
    ))
  }

  const handleCallGetMyVehicles = (requestObject, onLocalSuccess) => {
    callService(new ServiceRequestData(
        VehicleService.getMyVehicles,
        requestObject,
        null,
        null,
        onLocalSuccess,
        null
    ))
  }

  const handleCallGetMyIdentity = (requestObject, onLocalSuccess) => {
    callService(new ServiceRequestData(
        IdentityManagementService.getIdentity,
        requestObject,
        null,
        null,
        onLocalSuccess,
        null
    ))
  }

  const handleCallSaveIdentity = (requestObject, onLocalSuccess) => {
    callService(new ServiceRequestData(
      IdentityManagementService.saveIdentity,
      requestObject,
      null,
      null,
      onLocalSuccess,
      null
    ))
  };

  const handleCallSaveIdentityForLegalEntity = (requestObject, onLocalSuccess) => {
    callService(new ServiceRequestData(
        IdentityManagementService.saveIdentityForLegalEntity,
        requestObject,
        null,
        null,
        onLocalSuccess,
        null
    ))
  };

  const handleCallGetMyIdentityForLegalEntity = (requestObject, onLocalSuccess) => {
    callService(new ServiceRequestData(
        IdentityManagementService.getIdentityForLegalEntity,
        requestObject,
        null,
        null,
        onLocalSuccess,
        null
    ))
  }

  const handleCallGetVehicleByTransactionId = (requestObject, onLocalSuccess) => {
    callService(new ServiceRequestData(
        VehicleService.getVehicleByTransactionId,
        requestObject,
        null,
        null,
        onLocalSuccess,
        null
    ))
  };

  const handleCallGetAvailableLicenseNumbers = (requestData, onLocalSuccess, onLocalError) => {
    // todo 422 error code can't be handled
    callService(new ServiceRequestData(
        InterfaceAdapterService.getAvailableLicenseNumber,
        requestData,
        handleGetAvailableLicenseNumbersSuccessResult,
        handleGetAvailableLicenseNumbersErrorResult,
        onLocalSuccess,
        onLocalError
    ));
  };

  const handleCallReserveLicenseNumbers = (requestData, onLocalSuccess, onLocalError) => {
    // todo 422 error code can't be handled
    callService(new ServiceRequestData(
        InterfaceAdapterService.reserveLicenseNumber,
        requestData,
        handleGetAvailableLicenseNumbersSuccessResult,
        handleGetAvailableLicenseNumbersErrorResult,
        onLocalSuccess,
        onLocalError
    ));
  };

  const handleCallUnReserveLicenseNumbers = (requestData, onLocalSuccess, onLocalError) => {
    // todo 422 error code can't be handled
    callService(new ServiceRequestData(
      InterfaceAdapterService.unreserveLicenseNumber,
      requestData,
      handleGetAvailableLicenseNumbersSuccessResult,
      handleGetAvailableLicenseNumbersErrorResult,
      onLocalSuccess,
      onLocalError
    ));
  };

  // const handleCallGenerateEnrollmentReport = (formValues, onLocalSuccess) => {
  //   callService(new ServiceRequestData(
  //       InterfaceAdapter.createEnrollmentReport,
  //       extractFormValuesToApplicationData(formValues, applicationGenericFieldsList, projectDefaultDocuments(formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldNames.USE_CASE], formValues[formFieldsMeta.WORKFLOW]), applicantGenericFieldsList),
  //       null,
  //       null,
  //       onLocalSuccess,
  //       null
  //   ))
  // };

  const handleCallGenerateVREnrollmentReport = (formValues, applicantsDTOList, vehiclesDTOList, onLocalSuccess) => {
    callService(new ServiceRequestData(
        InterfaceAdapterService.createEnrollmentReport,
        extractFormValuesToApplicationDataWithMultiData(formValues, [...applicationGenericFieldsList, ...proxyGenericFieldsList], null, applicantsDTOList, vehiclesDTOList),
        null,
        null,
        onLocalSuccess,
        null
    ))
  };

  const handleCallGenerateEnrollmentReport = (formValues, onLocalSuccess) => {
        callService(new ServiceRequestData(
            InterfaceAdapterService.createEnrollmentReport,
            extractFormValuesToApplicationData(formValues, applicationGenericFieldsList, null, applicantGenericFieldsList),
            null,
            null,
            onLocalSuccess,
            null
        ))
    };

  const handleCallGeneratePortalReport = (formValues, onLocalSuccess) => {
    callService(new ServiceRequestData(
        InterfaceAdapterService.createPortalReport,
        extractFormValuesToApplicationData(formValues, applicationGenericFieldsList, null, applicantGenericFieldsList),
        null,
        null,
        onLocalSuccess,
        null
    ))
  };

  const handleCallGenerateEnrollmentReportProtected = (payload, onLocalSuccess) => {
    callService(new ServiceRequestData(
      InterfaceAdapterService.createEnrollmentReport,
      payload,
      null,
      null,
      onLocalSuccess,
      null
    ))
  }

  const handleCallCreateAccountReport = (accountDTO, onLocalSuccess) => {
    callService(new ServiceRequestData(
        InterfaceAdapterService.createAccountReport,
        accountDTO,
        null,
        null,
        onLocalSuccess,
        null
    ))
  };

  const handleCallPrintApplicationReport = (requestData, onLocalSuccess) => {
    callService(new ServiceRequestData(
        InterfaceAdapterService.createApplicationReport,
        requestData,
        null,
        null,
        onLocalSuccess,
        null
    ))
  };

  const handleCallCheckPersonIdentityState = (onLocalSuccess, onErrorLocal) => {
    callService(new ServiceRequestData(
        IdentityManagementService.checkPersonIdentityState,
        null,
        null,
        null,
        onLocalSuccess,
        onErrorLocal ?? null
    ))
  }

  const handleCallCheckLeIdentityState = (onLocalSuccess) => {
    callService(new ServiceRequestData(
        IdentityManagementService.checkLegalEntityIdentityState,
        null,
        null,
        null,
        onLocalSuccess,
        null
    ))
  }

  const handleCallGetPersons = (requestData, onLocalSuccess, onLocalError) => {
    callService(new ServiceRequestData(
      InterfaceAdapterService.searchPersons,
      requestData,
      null,
      null,
      onLocalSuccess,
      onLocalError,
        null,
        null,
        null,
        onLocalError
    ));
  };

  const handleCallSearchLegalEntities = (requestData, onSuccessSearchLE, onLocalError) => {
    callService(new ServiceRequestData(
      InterfaceAdapterService.searchLegalEntities,
      requestData,
      null,
      null,
      onSuccessSearchLE,
        onLocalError,
        null,
        null,
        null,
        onLocalError
    ))
  };

  const handleCallCheckConditionsToApply = (requestData, onSuccessLocal, onErrorLocal) => {
    callService(new ServiceRequestData(
      InterfaceAdapterService.checkConditionsToApply,
      requestData,
      null,
      null,
      onSuccessLocal,
      onErrorLocal,
        null,
        null,
        null,
        onErrorLocal
    ))
  };

  const handleCallSearchVehicles = (requestData, onErrorLocal) => {
    callService(new ServiceRequestData(
      InterfaceAdapterService.searchVehicles,
      requestData,
      handleSearchVehicleOnSuccess,
        null,
        null,
        onErrorLocal,
        null,
        null,
        null,
        onErrorLocal
    ))
  };

  const handleCallGetOwners = (requestData, onSuccessLocal, onErrorLocal) => {
    callService(new ServiceRequestData(
        InterfaceAdapterService.getVehicleOwners,
        requestData,
        null,
        null,
        onSuccessLocal,
        onErrorLocal,
        null,
        null,
        null,
        onErrorLocal
    ))
  };

  const handleCallAllocateOldPlateNumber = (requestData, onLocalSuccess) => {
    callService(new ServiceRequestData(
        InterfaceAdapterService.allocateOldPlateNumber,
        requestData,
        null,
        null,
        onLocalSuccess,
        null
    ))
  };

    const handleCallSyncData = (onLocalSuccess) => {
        callService(new ServiceRequestData(
            InterfaceAdapterService.syncData,
            null,
            null,
            null,
            onLocalSuccess,
            null
        ))
    };

  const isVehicleExists = (requestData, onSuccessLocal, onErrorLocal) => {
    callService(new ServiceRequestData(
        VehicleService.isVehicleExists,
        requestData,
        null,
        null,
        onSuccessLocal,
        onErrorLocal
    ))
  };

  const handleCallGetPlateReservationUseAndReference = (requestData, onLocalSuccess, onLocalError) => {
    callService(new ServiceRequestData(
        InterfaceAdapterService.getPlateReservationUseAndReference,
        requestData,
        null,
        null,
        onLocalSuccess,
        onLocalError,
        null,
        null,
        null,
        onLocalError
    ));
  };

  const handleCallSaveTransaction = (requestData, onLocalSuccess, onLocalError) => {
    callService(new ServiceRequestData(
        InterfaceAdapterService.saveTransaction,
        requestData,
        null,
        null,
        onLocalSuccess,
        onLocalError,
        null,
        null,
        null,
        onLocalError
    ));
  };


  return {
    // handleGenerateEnrollmentReport: handleCallGenerateEnrollmentReport,
    handleDownloadPreEnrollmentSummaryReport: handleCallDownloadPreEnrollmentSummaryReport,
    handleGetSingleDictionary: handleCallGetSingleDictionary,
    handleGetCallSaveApplication: handleCallSaveApplication,
    handleGetCallSaveApplicationInternal: handleGetCallSaveApplicationInternal,
    handleCallSaveVehicle: handleCallSaveVehicle,
    handleCallUpdateVehicleByTxId: handleCallUpdateVehicleByTxId,
    handleCallGetMyVehicles: handleCallGetMyVehicles,
    handleCallGetMyIdentity: handleCallGetMyIdentity,
    handleGetMyIdentityForLegalEntity: handleCallGetMyIdentityForLegalEntity,
    handleCallSaveIdentity: handleCallSaveIdentity,
    handleSaveIdentityForLegalEntity: handleCallSaveIdentityForLegalEntity,
    handleGetVehicleByTransactionId: handleCallGetVehicleByTransactionId,
    handleGetAvailableLicenseNumbers: handleCallGetAvailableLicenseNumbers,
    handleReserveLicenseNumbers: handleCallReserveLicenseNumbers,
    handleUnReserveLicenseNumbers: handleCallUnReserveLicenseNumbers,
    handleGenerateVREnrollmentReport: handleCallGenerateVREnrollmentReport,
    handleGenerateEnrollmentReport: handleCallGenerateEnrollmentReport,
    handleCallGeneratePortalReport: handleCallGeneratePortalReport,
    handleGenerateEnrollmentReportProtected: handleCallGenerateEnrollmentReportProtected,
    handleCreateAccountReport: handleCallCreateAccountReport,
    handlePrintApplicationReport: handleCallPrintApplicationReport,
    handleCheckPersonIdentityState: handleCallCheckPersonIdentityState,
    handleCheckLeIdentityState: handleCallCheckLeIdentityState,
    handleCountApplications: handleCallCountApplications,
    handleGetPersons: handleCallGetPersons,
    handleSearchLegalEntities: handleCallSearchLegalEntities,
    handleCheckConditionsToApply: handleCallCheckConditionsToApply,
    handleSearchVehicles: handleCallSearchVehicles,
    handleAllocateOldPlateNumber: handleCallAllocateOldPlateNumber,
    handleCallSyncData: handleCallSyncData,
    isVehicleExists: isVehicleExists,
    handleCallGetOwners: handleCallGetOwners,
    handleGetPlateReservationUseAndReference: handleCallGetPlateReservationUseAndReference,
    handleCallSaveTransaction: handleCallSaveTransaction
  }
}