import React, { useEffect, useRef } from "react";
import {
  NavigationWizardWrapper,
    addInitialValueToForm
} from "@mb-react/mb-react-core";
import {wizardSteps} from "../../../constants/store";
import { I18n } from "react-redux-i18n";
import { i18nl } from "../../../constants/i18n";
import { isMobile } from "../../../util/environment";
import ServiceProviderTask from "../../tasks/workflowTasks/appointments/ServiceProviderTask";
import DateAndTimeTask from "../../tasks/workflowTasks/appointments/DateAndTimeTask";
import { forms } from "../../../constants/form";
import SummaryAppointmentTask from "../../tasks/workflowTasks/appointments/summary/SummaryAppointmentTask";
import { change, Toast } from "@mb-react/mb-react-core";
import { GetAccountDataRequest } from "../../../constants/services";
import { useHandleGetAccountDataSuccessResult } from "../../../services/account/handleAccountServiceResponse";
import { useHandleAccountServices } from "../../../services/account/accountServiceHandler";
import {formFieldNames, getCookie, getPath} from "@mb-react/svarog-ui";
import { useDispatch } from "react-redux";



const AppointmentBookingWorkflow = () => {
  const dispatch = useDispatch();
  const handleAccountServices = useHandleAccountServices();
  const handleGetAccountDataSuccessResult = useHandleGetAccountDataSuccessResult();
  useEffect(() => {
    if (getCookie('accountId')) {
      getAccountData();
    }
  },
    []);
  useEffect(() => {
    if (sessionStorage.getItem('selsectedApplicationId')) {
      dispatch(change(formFieldNames.APPLICATION_ID, sessionStorage.getItem('selsectedApplicationId')))
      sessionStorage.removeItem('selsectedApplicationId')
    }
  },
    []);
  const getAccountData = () => {
    let accountRequest = new GetAccountDataRequest()
    accountRequest.accountId = getCookie('accountId')
    accountRequest.meta = true
    handleAccountServices.handleGetAccountById(accountRequest, handleGetAccountDataSuccessResult)
  }

  dispatch(addInitialValueToForm(forms.APPLICATION, "useApplyWithoutAccount", true))

  const items = [
    { id: wizardSteps.STEP1, label: I18n.t(getPath(i18nl.SERVICE)), icon: 'pi pi-cog', component: <ServiceProviderTask /> },
    { id: wizardSteps.STEP3, label: I18n.t(getPath(i18nl.TIME)), icon: 'pi pi-calendar-plus', component: <DateAndTimeTask /> },
    { id: wizardSteps.STEP4, label: I18n.t(getPath(i18nl.SUMMARY)), icon: 'pi pi-file', component: <SummaryAppointmentTask /> },
  ]


  return (
    <div className="card navigation">
      <NavigationWizardWrapper items={items} mobile={isMobile()} progressBar={!isMobile()} readOnly/>
    </div>
  );
};

export default AppointmentBookingWorkflow;
