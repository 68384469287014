import React from 'react'
import moment from 'moment'
import { I18n } from 'react-redux-i18n'
import { getPath, i18nl } from '../constants/i18n'
import {NATURAL_PERSON} from "../constants/application";
import {extractIdentificationNumberByType} from "./businesslogicUtil";
import {identificationNumberTypes} from "@mb-react/svarog-ui";

/**
 * Created by Minja Raicevic on 02/10/2018.
 */

export const GET_MY_AMS_APPLICATIONS_PATH = '/'


/**
 * @author jovanovicp *
 * Handling error codes from server and browser
 */
export function handleError(e) {
  if (e.name === 'TypeError') {
    return 'err.serverUnavailable'
  } else {
    return e.message
  }
}

/**
 * @author stankovicm *
 * Preparing appointment data from server for MBTable
 */
export function prepareAppointmentsForTable(allProviders) {
  let array = []
  for (var key in allProviders) {
    array.push({
      appointmentId: allProviders[key].appointmentId,
      date: allProviders[key].date,
      provider: allProviders[key].providerName,
      service: allProviders[key].serviceName,
      startTime: allProviders[key].startTime,
      status: I18n.t(getPath(allProviders[key].status)),
      handle: '@fat'
    })
  }

  return array
}

export function findServiceRequirementsByValue(value, allServices) {
  if (value && allServices) {
    for (let i = 0; i < allServices.length; i++) {
      if (allServices[i].id == value) {
        return allServices[i].requirements
      }
    }
  }
}

/**
 * @author stankovicm *
 * Preparing data from server for drop down
 */

export function prepareForDropDown(allAP) {
  let array = []
  for (var key in allAP) {
    array.push({ value: allAP[key].id, label: allAP[key].name })
  }

  for (let key of array) {
    switch (key.label) {
      case "Enrollment":
        key.label = I18n.t('label.enrollment');
        break;
      case "Payment":
        key.label = I18n.t('label.payment');
        break;
      case "Issuing": key.label = I18n.t('label.issuing');

    }

  }
  return array
}



export function preparePrefferedChannelsForDropDown(values) {
  let array = []
  if (values) {
    for (let i = 0; i < values.length; i++) {
      array.push({ value: values[i], label: `preferredChannel_${values[i]}` })
    }
    for (let key of array) {
      if (key.label === "preferredChannel_PHONE") {
        key.label = I18n.t(getPath(i18nl.PHONE))
        delete array[0] //Deleted because it's not needed at the moment
      }
      else if (key.label === "preferredChannel_EMAIL") {
        key.label = I18n.t(getPath(i18nl.EMAIL))
      }
      else { key.label = I18n.t(getPath(i18nl.PHONE_AND_EMAIL),
        delete array[2]) //Deleted because it's not needed at the moment
      }
    }
  }
  return array
}
/**
 * @author stankovicm *
 * Preparing data from server for list tree
 */

export function prepareForListTree(allLocations) {
  let array = []
  for (var key in allLocations) array.push({ id: allLocations[key].id, name: allLocations[key].name, parent_id: allLocations[key].parentLocationId })
  return array
}

export function prepareProvidersForListTree(providersForLocationService) {
  let array = []
  for (var key in providersForLocationService) array.push({ id: providersForLocationService[key].id, name: providersForLocationService[key].name, parent_id: providersForLocationService[key].parentProviderId })
  return array
}

export function prepareServicesForListTree(allServices) {
  let array = []
  for (var key in allServices) array.push({ id: allServices[key].id, name: I18n.t('label.' + allServices[key].name), parent_id: allServices[key].parentProviderId })
  return array
}
export function prepareServicesForListBox(allServices) {
  let array = []
  if (allServices && allServices.length > 0) {
    array = allServices.map(x => ({
      name: I18n.t('label.' + x.name),
      code: x.id
    }))
  }
  return array
}
export function prepareTimeSlots(data) {
  let array = []
  if (data && data.length > 0) {
    array = data.map(x => ({
      name: x.startTime,
      value: x.startTime
    }))
  }
  return array
}

export function prepareLocationsForTree(allLocations, parent) {
  let array = []
  if (allLocations && allLocations.length > 0) {
    array = allLocations.map(x => ({
      key: parent ? x.id + "-" + x.id : x.id,
      label: I18n.t('label.' + x.name),
      data: parent ? x.id + "-" + x.id : x.id,
      icon: parent ? "pi pi-fw pi-map-marker" : "pi pi-fw pi-flag",
      selectable: !parent,
      leaf: !parent

    }))
  }
  return array
}

/**
 * @author stankovicm *
 * Table header for appointments
 */
export const headerData = [
  { dataField: 'id', isKey: true, width: '5%', innerElement: I18n.t(getPath(i18nl.APPOINTMENT_NUMBER)) },
  { dataField: 'date', dataSort: true, width: '15%', innerElement: I18n.t(getPath(i18nl.APPOINTMENT_DATE)) },
  { dataField: 'time', dataSort: true, width: '15%', innerElement: I18n.t(getPath(i18nl.APPOINTMENT_TIME)) },
  { dataField: 'provider', dataSort: true, width: '20%', innerElement: I18n.t(getPath(i18nl.APPOINTMENT_PROVIDER)) },
  { dataField: 'service', dataSort: true, width: '20%', innerElement: I18n.t(getPath(i18nl.APPOINTMENT_SERVICE)) },
  { dataField: 'status', dataSort: true, width: '10%', innerElement: I18n.t(getPath(i18nl.APPOINTMENT_STATUS)) }
]

/**
 * @author stankovicm *
 * Converting moment to string
 */

export function momentToString(momentDate) {
  let stringDate = moment(momentDate).format('DD/MM/YYYY').toString()

  return stringDate
}

/**
 * @author stankovicm *
 * Finding value by id by going through the whole array
 */

export function findById(array, id) {
  let foundObject
  for (let i = 0; i < array.length; i++) {
    if (array[i].id == id) {
      foundObject = array[i]
    }
  }
  return foundObject
}

export function isMobile() {
  if (/Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  }
  else {
    return false
  }
}

export function getCurrentWeekDays() {
  let now = moment()
  let day = now.day()
  let week = [['monday', 1], ['tuesday', 2], ['wednesday', 3], ['thursday', 4], ['friday', 5], ['saturday', 6], ['sunday', 7]]
  let monday = moment().day(-(week[0][1] - day))

  return { monday: monday, today: now }
}

export function prepareVehiclesForTable(vehiclesDTO) {
  let array = []
  for (let key in vehiclesDTO) {
    array.push({
      vid: vehiclesDTO[key].vid,
      brand: vehiclesDTO[key].brand,
      category: vehiclesDTO[key].category,
      productionYear: vehiclesDTO[key].productionYear,
      productionCountry: vehiclesDTO[key].productionCountry,
      chassisNumber: vehiclesDTO[key].chassisNumber,
      axles: vehiclesDTO[key].axles,
      transactionId: vehiclesDTO[key].transactionId,
      genericFieldsDTO: vehiclesDTO[key].genericFieldsDTO,
      plateDTOList: vehiclesDTO[key].plateDTOList,
      usageType: vehiclesDTO[key].usageType,
      color: vehiclesDTO[key].color,
      subcategory: vehiclesDTO[key].subcategory,
      vehicleModel: vehiclesDTO[key].vehicleModel
    })
  }

  return array
}

export function preparePersonForTable(persons) {
  let array = []
  for (let key in persons) {
    if(!extractValueFromGenericField(persons[key]?.applicantData?.genericFieldsDTO?.genericFieldDTOList, "isRemoved")){
      array.push({
        id: key,
        sellerId: persons[key].sellerId,
        buyerId: persons[key].buyerId,
        tpid: persons[key]?.entityType == NATURAL_PERSON ? extractIdentificationNumberByType(persons[key]?.applicantData?.demographicDataDTO?.personDataDTO?.identificationNumbers, identificationNumberTypes.TPID) : extractValueFromGenericField(persons[key]?.applicantData?.genericFieldsDTO?.genericFieldDTOList, "leTpId"),
        entityName: persons[key]?.entityType == NATURAL_PERSON ? persons[key]?.applicantData?.demographicDataDTO?.personDataDTO?.givenName : persons[key]?.applicantData?.legalEntityDTO?.leName,
        entityType: persons[key]?.entityType ? I18n.t("label.table.entityType." + persons[key].entityType) : null,
        enrollmentStatus: persons[key]?.enrollmentStatus ? I18n.t("label.table.enrollmentStatus." + persons[key].enrollmentStatus) : null
      })
    }
  }

  return array
}

//returns value for requested generic field or null if not found
export function extractValueFromGenericField(gfList, gfName){
  if(gfList && gfList.length > 0){
    for(let i = 0; i < gfList.length; i++){
      if(gfList[i]?.key == gfName){
        return gfList[i]?.value
      }
    }
  }
  return null;
}

export function extractValueFromLinkedPerson(lpList, linkedType){
  if(lpList && lpList.length > 0){
    for(let i = 0; i < lpList.length; i++){
      if(lpList[i]?.linkedType == linkedType){
        return lpList[i]?.givenName
      }
    }
  }
  return null;
}


//Returns object key/value of extracted data from generic fields or null if none of them is extracted
export function extractMultipleValuesFromGenericField(gfList, gfNames){
  if(gfNames && gfNames.length > 0){
    let returnList = {};
    for(let i = 0; i < gfNames.length; i++){
      let gf = extractValueFromGenericField(gfList, gfNames[i]);
      if(gf){
        returnList = {...returnList, [gfNames[i]]: gf}
      }
    }
    return returnList;
  }
  return null
}