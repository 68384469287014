import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { I18n, Translate } from "react-redux-i18n";
import PropTypes from 'prop-types'
import {nextStep, previousStep} from "@mb-react/mb-react-core";
import {navigationName} from "../../constants/form";

const NavButtonsLocal = (props) => {
  const { firstStep, lastStep, hideCancel, finalSubmitFunction, customOnNext, customOnSkip, allowSkip, hideNext, customOnPrevious, customOnCancel, disableNextBtn, draftFunction, hideSubmit, hideDraft } = props
  const { validationMode, formState } = { validationMode: "Restrict", formState: {}};
  const [disableNext, setDisableNext] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    let formErrors = formState.errors
    let errorsCount = -1;
    let warningCount = -1;
    if (formErrors && Object.keys(formErrors).length > 0 && validationMode != "Restrict") {
      Object.keys(formErrors).forEach(x => {
        if (formErrors[x].type != "required") {
          errorsCount++
        } else {
          if (validationMode == "Hard") {
            warningCount++
          }
        }
      })
      setDisableNext(errorsCount != -1)
      setShowWarning(warningCount != -1 && errorsCount == -1)
    }
  }, [formState])
  function goToNextStepWithoutValidation() {
    dispatch(nextStep(navigationName.MAIN_NAVIGATION))
  }
  function goBackWithoutValidation() {
    if(customOnPrevious){
      customOnPrevious()
    }else{
      dispatch(previousStep(navigationName.MAIN_NAVIGATION))
    }
  }
  function onNext() {
    if (validationMode && validationMode != "Restrict") {
      goToNextStepWithoutValidation()
    }
    if (customOnNext) {
      customOnNext()
    }
  }
  function onSkip() {
    if (customOnSkip) {
      customOnSkip()
    } else {
      dispatch(nextStep(navigationName.MAIN_NAVIGATION))
    }
  }
  function finalSubmit() {
    if (finalSubmitFunction) {
      finalSubmitFunction()
    }
  }

  function draftApp() {
    if (draftFunction) {
      draftFunction()
    }
  }

  function cancel() {
    if(customOnCancel){
      customOnCancel()
    }else{
      window.location = "/"
    }
  }
  const getWarningMessage = () => {
    return showWarning && <small className={"p-warning"}><i className="pi pi-info-circle p-warning"></i> <Translate value={"action.warningMessage"} /></small>
  };
  return (
    <div className="col-lg-12 col-sm-12 navButtonsLocal" style={{ marginTop: '1rem' }}>
      <div className="grid">
        <div className="col-6 navButtonsLocationOne">
          {!hideCancel && <Button type="button" label={<Translate value="action.cancel"/>} className="mt-2 p-button-danger" icon="pi pi-times" onClick={() => cancel()} />}
          {!firstStep && <Button type="button" label={<Translate value="action.previous"/>} className="mt-2 p-button-secondary" icon="pi pi-angle-left" onClick={() => goBackWithoutValidation()} />}
        </div>
        <div className="col-6 navButtonsLocationTwo">
          {!lastStep ? <div> {allowSkip && <Button type="button" disabled={disableNext} label={<Translate value="action.skip"/>} tooltip={I18n.t('message.skipStepMessage')} className="mt-2 mr-2 p-button-secondary" icon="pi pi-angle-right" onClick={() => onSkip()} />}
                {!hideNext && <Button type="submit" disabled={disableNext || disableNextBtn} label={<Translate value="action.next"/>} className="mt-2 p-button-primary" icon="pi pi-angle-right" onClick={() => onNext()} />}</div>
            : <div>
                {!hideDraft && <Button type="button" label={<Translate value="action.draft"/>} className="mt-2 p-button-info" icon="pi pi-check" onClick={() => draftApp()} />}
                {!hideSubmit && <Button type="submit" label={<Translate value="action.submit"/>} className="mt-2 p-button-success" icon="pi pi-check" onClick={() => finalSubmit()} />}
              </div>
          }
        </div>
      </div>
    </div>
  )
}
NavButtonsLocal.propTypes = {
  register: PropTypes.any.isRequired,
  firstStep: PropTypes.any,
  lastStep: PropTypes.any,
  hideCancel: PropTypes.any,
  disableStepValidation: PropTypes.any,
  finalSubmitFunction: PropTypes.any,
  customOnPrevious: PropTypes.any,
  customOnNext: PropTypes.any,
  previousStep: PropTypes.any,
  customOnCancel: PropTypes.any
}
export default NavButtonsLocal;