import React, { Component } from "react";
import MainWrapper from "./wrappers/MainWrapper";
import MyAppointmentsPreview from "../tasks/myAppointments/MyAppointmentsPreview";

export default class MyAppointmentsWrapper extends Component {

    render() {
        return (
            <MainWrapper>
                <MyAppointmentsPreview />
            </MainWrapper>
        );
    }

}
