import {Button, Captcha, FormElement, Message, fieldType} from "@mb-react/mb-react-core";
import {formFieldNames, getCookie} from '@mb-react/svarog-ui';
import React, {Fragment, useEffect} from 'react';
import {useForm} from "react-final-form";
import {I18n, Translate} from 'react-redux-i18n';
import {TRG, getPath, i18nl} from '../../../constants/i18n';
import {useHandleAccountServices} from '../../../services/account/accountServiceHandler';
import krLogo1 from "../../../../public/media/KRG_logo.svg";

const EditAccount = (props) => {

    let form = useForm()

    const handleAccountServices = useHandleAccountServices();

    useEffect(() => {
        props.getCaptcha();
        getAccountData(getCookie("accountId"), true);
    }, []);


    const onGetAccountDataSuccess = (data) => {
        for (let i of Object.keys(data)) {
            if (data[i] != null) {
                switch (i) {
                    case formFieldNames.USER_PHONE:
                        form.change(formFieldNames.PHONE, data[i]);
                        break;
                    case formFieldNames.EMAIL:
                        form.change(formFieldNames.EMAIL, data[i]);
                        break;
                    case formFieldNames.USERNAME:
                        form.change(formFieldNames.USERNAME, data[i]);
                        break;
                }
            }
        }
    }

    const onGetAccountDataError = (data) => {
        form.change(formFieldNames.CAPTCHA, '')
    }

    const getAccountData = (accountId, meta) => {
        let getAccountDataParams = {
            accountId,
            meta
        };

        handleAccountServices.handleGetAccountById(getAccountDataParams, onGetAccountDataSuccess, onGetAccountDataError)
    };

    return (
        <Fragment>
            {props.editAccountSuccessfullyPerformed ?
                <div className="flex align-items-center justify-content-center mt-2">
                    <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
                        <div className="text-center mb-5">
                            <div className="logoWithTagline">
                                <img src={krLogo1} alt="Logo" width={"120px"}/>
                                <div className="tagline"><Translate value="label.headerLogo"/></div>
                                <div className="tagline"><Translate value="label.headerLogo1"/></div>
                                <div className="tagline"><Translate value="label.headerLogo2"/></div>
                            </div>
                            <div className="text-900 text-5xl font-bold mb-3">
                                <Message
                                    severity="success"
                                    text="You have successfully edited your account."/>
                            </div>
                        </div>

                        <div>
                            <Button label={I18n.t("action.goToHomePage")} onClick={() => (window.location = "/")}
                                    icon="pi pi-user" className="w-full"/>
                        </div>
                    </div>
                </div>
                :
                <div className="flex align-items-center justify-content-center mt-2">
                    <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
                        <div className="text-center mb-5">
                            <div className="logoWithTagline">
                                <img src={krLogo1} alt="Logo" width={"120px"}/>
                                <div className="tagline"><Translate value="label.headerLogo"/></div>
                                <div className="tagline"><Translate value="label.headerLogo1"/></div>
                                <div className="tagline"><Translate value="label.headerLogo2"/></div>
                            </div>
                            <div className="text-900 text-5xl font-bold mb-3"><Translate
                                value={getPath(i18nl.EDIT_ACCOUNT, TRG.LABEL)}/></div>
                        </div>
                        <div>
                        <FormElement
                                label='label.username'
                                fieldType={fieldType.INPUT_TEXT}
                                required
                                fieldProps={{name: formFieldNames.USERNAME, readOnly: true}}
                            />
                            <FormElement
                                label='label.phone'
                                fieldType={fieldType.INPUT_TEXT}
                                required
                                fieldProps={{name: formFieldNames.PHONE}}
                            />
                            <FormElement
                                label='label.email'
                                fieldType={fieldType.INPUT_TEXT}
                                required
                                fieldProps={{name: formFieldNames.EMAIL}}
                            />
                            <Captcha
                                captchaURL={props.captchaUrl}
                                fieldName={formFieldNames.CAPTCHA}
                                onRefreshCaptcha={() => props.getCaptcha()}
                                captchaPlaceholder={false}
                            />
                            <Button label={<Translate value="action.editAccount" />} type="submit"
                                    icon="pi pi-user" className="w-full"/>
                        </div>
                    </div>
                </div>}
        </Fragment>
    );
};

export default EditAccount;


