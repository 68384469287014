import { Button, openModal, getFromAppStore, addToAppStore } from '@mb-react/mb-react-core';
import { AccountService, getCookie, ServiceRequestData, useCreateServiceWrapper } from "@mb-react/svarog-ui";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import { ids } from "../../../../constants/modal";
import { accountStore } from "../../../../constants/store";
import ContainerHOC from "../../../ContainerHOC";
import ProcessHistory from "../../../shared/modal/admin/ProcessHistory";
import StatesComponent from "./StatesComponent";
import {SELECTED_ACCOUNT_ID} from "../../../../constants/general";

const AdminAccountAdministration = () => {
    let dispatch = useDispatch();
    const serviceWrapper = useCreateServiceWrapper();

    const accountDetails = useSelector(state => getFromAppStore(state, accountStore.ACCOUNT_DATA))

    const config = configurationData.settings.accountProfileData.accountFields
    let accountAdditionalData = accountDetails ? prepareAccountAdditionalData(accountDetails) : [];


    useEffect(() => {
        getAccountData();
    }, [])

    const getAccountData = () => {

        let requestData = {
            accountId: getCookie(SELECTED_ACCOUNT_ID),
            meta: false
        }

        serviceWrapper(new ServiceRequestData(
            AccountService.getAccountDataById,
            requestData,
            null,
            null,
            (result) => {
                if (result) {
                    onSuccessFetch(result)
                }
            },
            null
        ))
    };

    const onSuccessFetch = (result) => {
        if (result) {
            dispatch(addToAppStore(accountStore.ACCOUNT_DATA, result.data))
        }
    };

    let suppDocs;
    if (accountDetails && accountDetails.supportingDocumentDTOS.length != 0) {
        suppDocs =
            <>
                {accountDetails.supportingDocumentDTOS && accountDetails.supportingDocumentDTOS.length > 0 &&
                    accountDetails.supportingDocumentDTOS.map((sd, index) =>
                        <div className="col-4" key={index}>
                            <img className="imageSD" src={`data:image/jpeg;base64,${sd.documents[0]}`} />
                        </div>)
                }
            </>
    } else {
        suppDocs = <ul className="list-group listUL">{I18n.t("label.noSuppDocsData")}</ul>
    }

    function prepareAccountAdditionalData(data) {
        if (data) {
            const configAdditionalData = configurationData.settings.accountProfileData.accountFields.additionalData;

            let preparedAdditionalData = [];

            for (let e of configAdditionalData) {
                if (e in data) {
                    if (e.includes("Date") || e.includes("date")) {
                        preparedAdditionalData.push({ key: e, value: data[e] })
                    } else {
                        preparedAdditionalData.push({ key: e, value: data[e] })
                    }
                } else if (e == "personalNumber") {
                    if (data.entityIdentificationNumbers) {
                        preparedAdditionalData.push({ key: e, value: data.entityIdentificationNumbers[0].personalNumber })
                    }
                } else {
                    data.genericFieldsDTO.genericFieldDTOList.forEach(el => {
                        if (e == el.key) {

                            if (e.includes("Date") || e.includes("date")) {
                                preparedAdditionalData.push({ key: e, value: el.value })
                            } else {

                                switch (e) {
                                    case "rank":
                                        const configRank = configurationData.settings.rank
                                        preparedAdditionalData.push({
                                            key: e,
                                            value: Object.keys(configRank).length > 0 ? configRank[el.value] : "Config is missing."
                                        })
                                        break;
                                    default:
                                        preparedAdditionalData.push({ key: e, value: el.value })
                                }

                            }

                        }
                    })
                }
            }

            return preparedAdditionalData
        }
    }

    function renderAdditionalData(grid) {
        let gridNumber = Math.ceil(accountAdditionalData.length / 2)

        let layout = []

        accountAdditionalData.forEach((e, i) => {

            if (grid === 1 && i < gridNumber) {
                layout.push(<li key={"additionalData" + i} className="list-group-item listText">
                    {I18n.t("label." + e.key)}: <span className="personData">{e.value}</span>
                </li>);
            } else if (grid === 2 && i >= gridNumber) {
                layout.push(<li key={"additionalData" + i} className="list-group-item listText">
                    {I18n.t("label." + e.key)}: <span className="personData">{e.value}</span>
                </li>)
            }

        })

        return layout;
    }

    return (
        <ContainerHOC fullPage>
            <>
                <div className="container-fluid padding-content">
                    <h1 className="adminAccOverview"> {I18n.t("label.accountOverview")}</h1>
                    <div className="grid buttonProcessHistoryWrap">
                        <div className="col-lg-12 btn-margin text-right flip">
                            <Button label='label.processHistory' className="p-button p-component mt-2 p-button-secondary"
                                onClick={() => dispatch(openModal(ids.PROCESS_HISTORY))} />
                            <ProcessHistory />
                        </div>
                    </div>
                    <div className="grid adminAccWrap">
                        <div className="sm:col-4 md:col-6 xl:col-6">
                            {config.faceImage && <div className="sm:col-12 md:col-6">
                                <h4 className="h4AdminAcc"> {I18n.t('label.faceImage')} </h4>
                                <div className="imageDiv">
                                    {faceImage}
                                </div>
                            </div>}
                            <div className="sm:col-12 md:col-6">
                                <h4 className="h4AdminAcc"> {I18n.t('label.accountData')} </h4>
                                <ul className="list-group listUL">
                                    {config.accountData.accountID && accountDetails && <li className="list-group-item listText">{I18n.t("label.accountId")}: <span className="personData">{accountDetails.accountId}</span></li>}
                                    {config.accountData.username && accountDetails && <li className="list-group-item listText">{I18n.t("label.username")}: <span className="personData">{accountDetails.username}</span></li>}
                                    {config.accountData.state && accountDetails && <li className="list-group-item listText">{I18n.t("label.state")}: <span className="personData">{accountDetails.accountState}</span></li>}
                                </ul>
                            </div>
                            <div className="sm:col-12 md:col-6">
                                <h4 className="h4AdminAcc"> {I18n.t('label.supportingDocuments')} </h4>
                                {config.supportingDocuments && suppDocs}
                            </div>
                        </div>
                        <div className="sm:col-12 md:col-6">
                            <h4 className="h4AdminAcc"> {I18n.t('label.personalInformation')} </h4>
                            <div className="personalInfoAdminAcc">
                                <div className="sm:col-12 md:col-6">
                                    <ul className="list-group listUL">
                                        {config.personalInformation.firstname && accountDetails && <li className="list-group-item listText">
                                            {I18n.t("label.firstname")}: <span className="personData">{accountDetails.entityName}</span>
                                        </li>}
                                        {config.personalInformation.surname && accountDetails && <li className="list-group-item listText">
                                            {I18n.t("label.surname")}: <span className="personData">{accountDetails.entitySurname}</span>
                                        </li>}
                                        {config.personalInformation.dateOfBirth && accountDetails && <li className="list-group-item listText">
                                            {I18n.t("label.dateOfBirth")}: <span className="personData">{accountDetails.entityDateOfBirth}</span>
                                        </li>}
                                        {config.personalInformation.gender && accountDetails && <li className="list-group-item listText">
                                            {I18n.t("label.gender")}: <span className="personData">{accountDetails.entityGender}</span>
                                        </li>}
                                        {config.personalInformation.email && accountDetails && <li className="list-group-item listText">
                                            {I18n.t("label.email")}: <span className="personData">{accountDetails.email}</span>
                                        </li>}
                                        {config.personalInformation.phone && accountDetails && <li className="list-group-item listText">
                                            {I18n.t("label.phone")}: <span className="personData">{accountDetails.phone}</span>
                                        </li>}
                                    </ul>
                                </div>
                            </div>
                            {accountAdditionalData.length > 0 &&
                                <>
                                    <div className="sm:col-12 md:col-6">
                                        <h4 className="h4AdminAcc"> {I18n.t('label.additionalData')} </h4>
                                        <ul className="list-group listUL">
                                            {renderAdditionalData(1)}
                                        </ul>
                                    </div>
                                    <div className="sm:col-12 md:col-6">
                                        <ul className="list-group listUL">
                                            {renderAdditionalData(2)}
                                        </ul>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>

                <StatesComponent personData={accountDetails} />
            </>
        </ContainerHOC>
    );
}
export default AdminAccountAdministration
