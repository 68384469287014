import {
    NavigationWizardWrapper, addMultipleInitialValuesToForm, addToAppStore, getFromAppStore
} from "@mb-react/mb-react-core";
import { PagedRequest, extractPersonDataToFormValues, getPath } from '@mb-react/svarog-ui';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {Translate} from "react-redux-i18n";
import {forms, navigationName} from '../../../constants/form';
import { i18nl } from '../../../constants/i18n';
import {APPLICATION_STEPS, applicationStore} from '../../../constants/store';
import { isMobile } from '../../../util/environment';
import SummaryTaskWrapper from '../../tasks/workflowTasks/common/summary/SummaryTaskWrapper';
import DriverLicenseForm from "../../tasks/workflowTasks/document/driverLicense/DriverLicenseForm";
import QuestionnaireData from "../../tasks/workflowTasks/document/driverLicense/QuestionnaireData";
import { useHandleWorkflowSettings } from './handleWorkflowSettings';
import { useHandleCommonServices } from "../../../services/commonServiceHandler";
import { addressDataFormFieldList, personalInformationFormFieldList } from "@mb-react/krg-getid-form-data-common";
import PersonalInformationForm from "../../tasks/workflowTasks/document/personalData/PersonalInformationForm";
import AddressDataForm from "../../tasks/workflowTasks/document/addressData/AddressDataForm";
import {useDriversLicenceEnrollmentFormValidation} from "../../tasks/workflowTasks/document/driverLicense/DriversLicenseDataValidation";
import {disableDraft, isIdentityActivated} from "../../../util/businesslogicUtil";
import { extractObjectBasedOnKeysList } from "../../../util/dataExtractorUtil";

const CancelApllicationWorkflow = () => {
    const handleWorkflowSettings = useHandleWorkflowSettings();
    const questionnariesCompleted = useSelector((state) => getFromAppStore(state, "questionnariesCompletedDL"));
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))
    const handleCommonServices = useHandleCommonServices();
    const dispatch = useDispatch();

    const getMyIdentityOnSuccess = (response) => {
        let newObj = extractPersonDataToFormValues(response.data, {});
        let personalInformation = extractObjectBasedOnKeysList(newObj, personalInformationFormFieldList);
        let addressData = extractObjectBasedOnKeysList(newObj, addressDataFormFieldList);

        dispatch(addMultipleInitialValuesToForm(forms.PERSONAL_INFORMATION, personalInformation))
        dispatch(addMultipleInitialValuesToForm(forms.ADDRESS_DATA, addressData))
        dispatch(addToAppStore('fetchedApplication', {...personalInformation, ...addressData}))

    }

    useEffect(() => {
        handleWorkflowSettings.initWorkflowSettings()
        let pagedRequest = new PagedRequest();
        pagedRequest.requestObject = {}
        pagedRequest.page = 1
        pagedRequest.sizePerPage = 10

        handleCommonServices.handleCallGetMyIdentity(pagedRequest, getMyIdentityOnSuccess)
    }, []);

    const items = [
        {
            id: APPLICATION_STEPS.DL_TASK,
            label: <Translate value={"label." + i18nl.DL_DATA} />,
            icon: 'pi pi-car',
            component: <DriverLicenseForm navigationName={navigationName.MAIN_NAVIGATION}/>,
            validateFn: useDriversLicenceEnrollmentFormValidation
        },
        {
            id: APPLICATION_STEPS.CONFIRMATION_TASK,
            label: <Translate value={"label." + i18nl.SUMMARY} />,
            icon: 'pi pi-file',
            component: <SummaryTaskWrapper navigationName={navigationName.MAIN_NAVIGATION}/>
        },
    ]

    if (!isIdentityActivated()) {
        items.unshift(
            {
                id: APPLICATION_STEPS.PERSONAL_DATA,
                label: <Translate value={"label." + i18nl.PERSONAL_INFORMATION} />,
                icon: 'pi pi-user',
                component: <PersonalInformationForm formName={forms.PERSONAL_INFORMATION} navigationName={navigationName.MAIN_NAVIGATION}/>,
            },
            {
                id: APPLICATION_STEPS.ADDRESS_DATA,
                label: <Translate value={"label." + i18nl.ADDRESS_DATA} />,
                icon: 'pi pi-id-card',
                component: <AddressDataForm formName={forms.ADDRESS_DATA} navigationName={navigationName.MAIN_NAVIGATION}/>,
            },
        );
      }

    return (
        <div className="card navigation">
            {!questionnariesCompleted && <QuestionnaireData />}
            {questionnariesCompleted && (
                <NavigationWizardWrapper items={items} mobile={isMobile()} progressBar={!isMobile()} readOnly={disableDraft(userRights)}
                    formName={forms.APPLICATION} />)}
        </div>

    );
}

CancelApllicationWorkflow.propTypes = {};

function mapStateToProps(state) {
    return {
        activeLocale: state.i18n.locale
    }
}

export default connect(mapStateToProps)(CancelApllicationWorkflow)

