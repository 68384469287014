import React from "react";



export const docImageComponent = () => {

    return (
        <div className="docImageWrap">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 842.14 1018.61" id="docWithThick">
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_2-2" data-name="Layer 2">
                        <g id="Layer_2-3" data-name="Layer 2">
                            <g className="docWithThickPath" data-name="Layer 4">
                                <path className="colorPathWthite"
                                    d="M585.56,747.3H245.8a6.21,6.21,0,0,0-1.24-.61c-22.71-4.27-36.37-20.61-36.38-43.87q-.08-153.65-.07-307.27a18.07,18.07,0,0,1,5.43-13.49q55.2-55.56,110.32-111.25a17.88,17.88,0,0,1,13.45-5.51q96.68.12,193.36.12a49.11,49.11,0,0,1,13,1.6c20.68,5.78,32.47,21.67,32.48,43.42V520.29c2.24.15,3.94.22,5.64.37C631,525,665.16,549.75,683.21,595.81c3.5,8.91,4.61,18.76,6.82,28.18v18.83c-.33,1.67-.71,3.33-1,5-7.28,44.47-31.67,75.47-73.6,92C606,743.55,595.55,744.87,585.56,747.3ZM465.83,661.64H280.26c-9.2,0-15.54-5.8-15.59-14s6.29-14.07,15.42-14.2H461.78c1.24-9.69,2.4-18.79,3.61-28.23H282.84c-1.72,0-3.46.07-5.17-.09a14.06,14.06,0,0,1-.18-28,56.07,56.07,0,0,1,5.65-.11q94.8,0,189.62.08c3.58,0,5.58-1.07,7.5-4.07,15.09-23.46,36.11-39.27,62.77-47.37,3.86-1.17,5-2.71,5-6.67q-.19-103.53-.1-207.07c0-12.82-5.43-18.24-18.31-18.24H349.33v96.12c0,12.88-5,17.78-18,17.78h-94.8c-.07,1.53-.13,2.31-.13,3.09V703.38c0,10.25,5.71,15.75,16.23,15.76h244.2c1.16,0,2.32-.16,4.33-.31C483.51,702.78,471.91,684,465.83,661.64ZM576.33,548.7c-46.92-.14-85.47,37.72-85.81,84.26-.34,46.29,39,85.95,85.45,86.09,46.16.14,85.52-39,85.81-85.26C662.07,587.23,623.51,548.84,576.33,548.7ZM320.85,316.34,256.67,379h64.18Z"
                                    fill="none" stroke="rgb(85, 73, 148)" strokeMiterlimit="10"
                                    strokeWidth="12" />
                                <path className="colorPathWthite"
                                    d="M391.6,492.21H282.91c-1.73,0-3.46.07-5.18-.08A14.07,14.07,0,0,1,277.9,464c1.56-.13,3.13-.07,4.7-.07H503.28c9.14.13,15.47,6,15.42,14.2s-6.38,14-15.58,14Q447.37,492.25,391.6,492.21Z"
                                    fill="none" stroke="rgb(85, 73, 148)" strokeMiterlimit="10"
                                    strokeWidth="12" />
                                <path className="colorPathWthite"
                                    d="M365.9,548.69H282.67c-1.72,0-3.46.07-5.17-.1a14.07,14.07,0,0,1,.14-28.05c.93-.09,1.87-.08,2.81-.08H451.62c7.89,0,13.43,3.69,15.27,10,2.73,9.33-3.86,18.07-14,18.18-14.27.16-28.53.05-42.8.05Z"
                                    fill="none" stroke="rgb(85, 73, 148)" strokeMiterlimit="10"
                                    strokeWidth="12" />
                                <path className="colorPathWthite"
                                    d="M448,379.25c-18.51,0-37,.09-55.52,0-11-.08-18-10.23-13.65-19.71,2.78-6.07,7.86-8.53,14.45-8.51,27,.06,54,0,80.93,0,9.72,0,19.44-.06,29.17,0,9.09.07,15.43,6,15.33,14.26-.09,8-6.35,13.9-15.19,13.93C485,379.3,466.49,379.25,448,379.25Z"
                                    fill="none" stroke="rgb(85, 73, 148)" strokeMiterlimit="10"
                                    strokeWidth="12" />
                                <path className="colorPathWthite"
                                    d="M448.11,435.73c-18.51,0-37,.08-55.52,0-10.83-.06-17.71-9.63-14-19.17,2.24-5.76,7.36-9,14.57-9H502.8c9.56,0,16,5.76,15.93,14.15s-6.42,14.05-16,14.07Q475.42,435.78,448.11,435.73Z"
                                    fill="none" stroke="rgb(85, 73, 148)" strokeMiterlimit="10"
                                    strokeWidth="12" />
                                <path className="colorPathWthite"
                                    d="M560.8,657.18c16.77-16.52,32-31.45,47.13-46.42,4.4-4.35,9.29-6.88,15.64-4.85a13.89,13.89,0,0,1,7.17,21.66,29.84,29.84,0,0,1-3.46,3.82Q600.66,657.64,574,683.83c-8.19,8-15.51,8.13-23.67.27-9-8.7-18.16-17.3-27-26.21-8.25-8.35-4.73-21.35,6.31-24.06,5.58-1.38,10.27.59,14,4.57C549.4,644.42,554.88,650.66,560.8,657.18Z"
                                    fill="none" stroke="rgb(85, 73, 148)" strokeMiterlimit="10"
                                    strokeWidth="12" />
                                <path className="colorTwoLinesWthite" d="M425.59,52.56V174.75l-8,.22V52.7Z" fill="rgb(85, 73, 148)"
                                    stroke="#9aa8af" strokeMiterlimit="10" />
                                <path className="colorTwoLinesWthite" d="M426.59,843.87v122h-8V844Z"
                                    fill="rgb(85, 73, 148)" />
                                <path className="colorPathWthite"
                                    d="M10.48,384.49c-.07-73.73.61-147.81-.48-221.67,0-19.27,6.15-28.16,24.53-34.17Q220,71.59,404.82,12.69a51.51,51.51,0,0,1,32.87,0c122.18,39,244.36,78,367.08,115.35,22.07,6.83,27.34,17.69,27.34,38.81-.82,131.88-1.71,263.76,0,395.64,1.84,145.28-69.16,250.37-180,333.6a712.2,712.2,0,0,1-211.83,107.76c-9.77,3.14-18.79,6.83-29.59,3.35C267.75,958.2,139.83,888,59.13,753.75a325,325,0,0,1-48.52-168C10,518.62,10.48,451.52,10.48,384.49Z"
                                    fill="none" stroke="rgb(85, 73, 148)" strokeMiterlimit="10"
                                    strokeWidth="20" />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}