/**
 * @author Minja Raicevic on 30/08/2018.
 */
import { isRTLStyleApplied } from "@mb-react/mb-react-core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ContainerHOC from "../../ContainerHOC";
import "../../shared/fontawesome";

class MainWrapper extends Component {
  render() {
    return (
        <ContainerHOC>{this.props.children}</ContainerHOC>

    );
  }
}

export default connect(isRTLStyleApplied)(MainWrapper);
MainWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  carousel: PropTypes.any,
  carouselImages: PropTypes.any,
};
