import React, { useState } from "react";
import { FormElement } from "@mb-react/mb-react-core";
import { fieldType } from "@mb-react/mb-react-core";
import { Translate } from "react-redux-i18n";
import { getPath, i18nl } from "../../../../../constants/i18n";
import { formFieldNames } from "@mb-react/svarog-ui";

const SummaryContactForm = (props) => {
  const [showEmail, setShowEmail] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const onChangeChanel = (e) => {
    if (e.target.value) {
      switch (e.target.value) {
        case "EMAIL":
          setShowEmail(true);
          setShowPhone(false);
          break;
        case "PHONE":
          setShowEmail(false);
          setShowPhone(true);
          break;
        case "PHONE_EMAIL":
          setShowEmail(true);
          setShowPhone(true);
          break;
      }
    }
  };
  return (
    <div className="contactInfo">
      <h5 className="contactInfoHead">
        <Translate value={getPath(i18nl.CONTACT_INFORMATION)} />
      </h5>
      <FormElement
        label='label.username'
        fieldType={fieldType.INPUT_TEXT}
        fieldProps={{ name: formFieldNames.USERNAME }}
        required
        validationRules={{}}
      />
      <FormElement
        label='label.preferredNotification'
        fieldType={fieldType.INPUT_DROPDOWN}
        fieldProps={{
          name: formFieldNames.PREFERRED_NOTIFICATION_CHANNEL,
          selectOptions: props.chanelsOptions,
          onChange: (e) => onChangeChanel(e),
        }}
        required
        validationRules={{}}
      />
      {showEmail && (
        <FormElement
          label='label.email'
          fieldType={fieldType.INPUT_TEXT}
          fieldProps={{ name: formFieldNames.USER_EMAIL }}
          required
          validationRules={{}}
        />
      )}
      {showPhone && (
        <FormElement
          label='label.phone'
          fieldType={fieldType.INPUT_TEXT}
          fieldProps={{ name: formFieldNames.USER_PHONE }}
          required
          validationRules={{}}
        />
      )}
    </div>
  );
};

export default SummaryContactForm;
