import {addToAppStore, getFromAppStore} from "@mb-react/mb-react-core";
import { useEffect, useState, } from "react";
import {useDispatch, useSelector} from "react-redux";
import { appStoreFields } from "../constants/store";
import {
  REDIRECT_TO_APPOINTMENT,
  REDIRECT_TO_CHANGE_PASSWORD, REDIRECT_TO_DRIVER_LICENSE, REDIRECT_TO_EDIT_ACCOUNT,
  REDIRECT_TO_HOME_PAGE,
  REDIRECT_TO_ID_CARD, REDIRECT_TO_NEW_DRIVER_LICENSE
} from "../constants/environment";
import {AuthenticationService, ServiceRequestData, useCreateServiceWrapper} from "@mb-react/svarog-ui";
import {useHandleLoginErrorResult} from "../services/authentication/handleAuthServiceResponse";

export function useContainerController() {
  const dispatch = useDispatch()
  const showSplash = useSelector((state) => getFromAppStore(state, appStoreFields.displaySplashScreen))
  const [showScreen, setShowScreen] = useState(showSplash || false)
  const handleErrorResponse = useHandleLoginErrorResult();
  const serviceWrapper = useCreateServiceWrapper();


  useEffect(() => {

    switch (window.location.pathname) {
      case REDIRECT_TO_ID_CARD :
      case REDIRECT_TO_HOME_PAGE :
      case REDIRECT_TO_APPOINTMENT :
      case REDIRECT_TO_NEW_DRIVER_LICENSE :
      case REDIRECT_TO_CHANGE_PASSWORD:
      case REDIRECT_TO_EDIT_ACCOUNT:
        if(configurationData.operationMode !== "PROTECTED"){
          dispatch(addToAppStore(appStoreFields.displaySplashScreen, true))

          serviceWrapper(new ServiceRequestData(
              AuthenticationService.resumeLoginAccount,
              null,
              null,
              handleErrorResponse,
              () => {
                dispatch(addToAppStore(appStoreFields.displaySplashScreen, false))
              }
          ))
        }
      default :
        return
    }

  }, []);
  useEffect(() => {
    setShowScreen(showSplash)
  }, [showSplash]);

  return {
    showSplashScreen: showScreen,
  }
}