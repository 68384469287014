import React, { useEffect } from "react";

import { Button, getFromAppStore } from "@mb-react/mb-react-core";
import { useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";
import {
    ADMIN,
    CONTENT_MANAGEMENT,
    MANAGE_ACCOUNT,
    PRINT_ACCOUNT_REPORT,
    PRINT_APPLICATION_REPORT
} from "../../../constants/admin/rights";
import {
    REDIRECT_TO_ADMIN_ACCOUNT_OVERVIEW,
    REDIRECT_TO_CONTENT_MANAGEMENT_HOME,
    REDIRECT_TO_PRINT_ACCOUNT_REPORT,
    REDIRECT_TO_PRINT_APPLICATION_REPORT,
} from "../../../constants/environment";
import { applicationStore } from "../../../constants/store";
import { isLoggedIn } from "../../../util/businesslogicUtil";
import { checkUserRights, delete_cookie } from "../../../util/util";
import { userImageComponent } from "../../iconComponents/userImage";
import { DocImageAcc } from "../../iconComponents/docImageAcc";
import { setCookie } from "@mb-react/svarog-ui";

const AdminHome = () => {
    const userRights = useSelector((state) => getFromAppStore(state, applicationStore.USER_RIGHTS));
    const manageAccount = [ADMIN, MANAGE_ACCOUNT];
    const contentManagement = [ADMIN, CONTENT_MANAGEMENT];
    const printAccountReport = [ADMIN, PRINT_ACCOUNT_REPORT];
    const printApplicationReport = [ADMIN, PRINT_APPLICATION_REPORT];
    const applyWithoutAccount = () => {
        setCookie("useApplyWithoutAccount", true)
        window.location = REDIRECT_TO_ADMIN_ACCOUNT_OVERVIEW
    }

    useEffect(() => {
        delete_cookie("useApplyWithoutAccount");
    }, []);

    return (
        <>
            {
                isLoggedIn() &&
                <div className="grid home-page nav">
                    <div className="col-12 btnImgTextWrap">
                        {checkUserRights(manageAccount, userRights) &&
                            <Button label={<Translate value="action.adminAccount"/>} type="button"
                                    icon={userImageComponent}
                                    className="p-button-rounded p-button-text btnCompImg"
                                    onClick={() => window.location = REDIRECT_TO_ADMIN_ACCOUNT_OVERVIEW}/>}
                        {checkUserRights(manageAccount, userRights) &&
                            <Button label={<Translate value="action.applyWithoutAccount"/>} type="button"
                                    icon={DocImageAcc}
                                    className="p-button-rounded p-button-text btnCompImg"
                                    onClick={() => applyWithoutAccount()}/>}
                        {checkUserRights(contentManagement, userRights) &&
                            <Button label={<Translate value="action.contentMngmtBtn"/>} type="button"
                                    icon={DocImageAcc}
                                    className="p-button-rounded p-button-text btnCompImg"
                                    onClick={() => window.location = REDIRECT_TO_CONTENT_MANAGEMENT_HOME}/>}
                        {checkUserRights(printAccountReport, userRights) &&
                            <Button label={<Translate value="action.printAccountReport"/>} type="button"
                                    icon={DocImageAcc}
                                    className="p-button-rounded p-button-text btnCompImg"
                                    onClick={() => window.location = REDIRECT_TO_PRINT_ACCOUNT_REPORT}/>}
                        {checkUserRights(printApplicationReport, userRights) &&
                            <Button label={<Translate value="action.printApplicationReport"/>} type="button"
                                    icon={DocImageAcc}
                                    className="p-button-rounded p-button-text btnCompImg"
                                    onClick={() => window.location = REDIRECT_TO_PRINT_APPLICATION_REPORT}/>}

                    </div>
                </div>
            }
        </>
    );
}
export default AdminHome
