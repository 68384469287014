import { genericFormFieldNames } from "@mb-react/krg-getid-form-data-common";
import { formFieldNames } from "@mb-react/svarog-ui";

export const commonMandatoryFieldListIM = [
    formFieldNames.GIVEN_NAME,
    formFieldNames.GIVEN_NAME_LATIN,
    formFieldNames.SURNAME,
    formFieldNames.SURNAME_LATIN,
    formFieldNames.GENDER,
    formFieldNames.NATIONALITY,
    formFieldNames.BLOOD_GROUP,
    formFieldNames.DATE_OF_BIRTH,
    formFieldNames.PLACE_OF_BIRTH_COUNTRY,
    formFieldNames.POSITION,
    formFieldNames.MARITAL_STATUS,
    formFieldNames.FATHERS_NAME,
    formFieldNames.FATHERS_NAME_LATIN,
    formFieldNames.MOTHERS_NAME,
    formFieldNames.MOTHERS_NAME_LATIN,
    genericFormFieldNames.MOTHERS_FATHER_NAME,
    genericFormFieldNames.GRANDFATHER_NAME,
    genericFormFieldNames.GRANDFATHER_NAME_LATIN,
    formFieldNames.ADDRESS_PROVINCE,
    formFieldNames.ADDRESS_CITY,
    genericFormFieldNames.ADDRESS_VILLAGE,
    genericFormFieldNames.ADDRESS_BUILDING_CLASS,
    genericFormFieldNames.ADDRESS_INFORMATION_CENTER,
    genericFormFieldNames.PROVINCE_OF_REGISTRATION,
    genericFormFieldNames.CITY_OF_REGISTRATION,
    genericFormFieldNames.VILLAGE_OF_REGISTRATION,
    genericFormFieldNames.REGISTRATION_BOOK_NUMBER,
    genericFormFieldNames.REGISTRATION_PAGE_NUMBER
  ];
  
  export const readOnlyFieldListIM = [
    formFieldNames.GIVEN_NAME,
    formFieldNames.GIVEN_NAME_LATIN,
    formFieldNames.SURNAME,
    formFieldNames.SURNAME_LATIN,
    formFieldNames.GENDER,
    formFieldNames.PERSONAL_NUMBER,
    formFieldNames.NATIONALITY,
    formFieldNames.BLOOD_GROUP,
    formFieldNames.TAX_NUMBER,
    formFieldNames.DATE_OF_BIRTH,
    formFieldNames.PLACE_OF_BIRTH_COUNTRY,
    formFieldNames.PLACE_OF_BIRTH_PROVINCE,
    formFieldNames.PLACE_OF_BIRTH_CITY,
    genericFormFieldNames.PLACE_OF_BIRTH_VILLAGE,
    formFieldNames.POSITION,
    formFieldNames.MARITAL_STATUS,
    formFieldNames.FATHERS_NAME,
    formFieldNames.FATHERS_NAME_LATIN,
    formFieldNames.MOTHERS_NAME,
    formFieldNames.MOTHERS_NAME_LATIN,
    genericFormFieldNames.MOTHERS_FATHER_NAME,
    genericFormFieldNames.GRANDFATHER_NAME,
    genericFormFieldNames.GRANDFATHER_NAME_LATIN,
    formFieldNames.ADDRESS_PROVINCE,
    formFieldNames.ADDRESS_CITY,
    genericFormFieldNames.ADDRESS_VILLAGE,
    genericFormFieldNames.ADDRESS_BUILDING_CLASS,
    genericFormFieldNames.ADDRESS_INFORMATION_CENTER,
    formFieldNames.TPID,
    genericFormFieldNames.NUMBER_DL,
    genericFormFieldNames.ISSUE_DATE_DL,
    genericFormFieldNames.VALID_DATE_DL,
    genericFormFieldNames.ISSUE_COUNTRY_DL,
    genericFormFieldNames.CLASSES_DL,
    formFieldNames.PHONE,
    formFieldNames.EMAIL,
    genericFormFieldNames.FOOD_CENTER_INDICATOR,
    formFieldNames.PROXY,
    formFieldNames.PROXY_NAME,
    formFieldNames.PROXY_SURNAME,
    formFieldNames.PROXY_PERSONAL_NUMBER,
    formFieldNames.PROXY_GENDER,
    formFieldNames.PROXY_DATE_OF_BIRTH,
    genericFormFieldNames.PROXY_FATHER_NAME,
    genericFormFieldNames.PROXY_GRANDFATHER_NAME,
    genericFormFieldNames.REQUEST_MEDICAL_EXEM,
    genericFormFieldNames.REQUEST_APPROVAL,
    genericFormFieldNames.REQUEST_APPROVAL_COMMENT,
    formFieldNames.COMMENT,
  ];

  export const mandatoryFieldListNEW_IM = [
    ...commonMandatoryFieldListIM
  ];

  export const mandatoryFieldListMODIFY_IM = [
    ...commonMandatoryFieldListIM
  ];

  export const readOnlyFieldListMODIFY_IM = []

  export const mandatoryFieldListCANCEL_IM = [];