import React from 'react';
import {Translate} from "react-redux-i18n";
import {ContractDetails} from "@mb-react/krg-getid-form-data-common";
import {EDocumentType, EUseCases} from "@mb-react/svarog-ui";
import {changeOwnershipSubUseCases} from "../../../../../../constants/application";
import {useSelector} from "react-redux";
import {getFormValues} from "@mb-react/mb-react-core";
import {forms} from "../../../../../../constants/form";
import {contractDetailsFieldsSettings} from "./ContractData/contractDataFieldSettings";

function ApplicationData(props) {
  const docTypeFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)["documentType"]);
  const useCaseFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)["useCase"]);
  const subUseCase = useSelector((state) => getFormValues(state, forms.APPLICATION)["changeOwnershipSubUseCase"]);

  function renderContractData(){
    if(docTypeFromStore !== EDocumentType.VR){
      return false;
    }
    if(useCaseFromStore === EUseCases.NEW){
      return true;
    }
    if(useCaseFromStore === EUseCases.CHANGE_OWNERSHIP &&
      [changeOwnershipSubUseCases.SALE_AND_BUY,
        changeOwnershipSubUseCases.CHANGE_LEAD,
        changeOwnershipSubUseCases.CHANGE_SHARE,
        changeOwnershipSubUseCases.BY_AUCTION].includes(subUseCase)){
      return true;
    }
    return false;
  }

  return (
    <div className="card">
      {renderContractData() && (
        <div>
          <h5><Translate value="label.contractDetails" /></h5>
          <hr className="sectionSeparator" />
          <div className="grid">
            <ContractDetails {...contractDetailsFieldsSettings()} />
          </div>
        </div>
      )}
    </div>
  );
}

export default ApplicationData;