import {formFieldNames, isRequired, EUseCases} from "@mb-react/svarog-ui";
import {useGlobalValidation} from "../../../../../hooks/useGlobalValidation";
import {genericFormFieldNames} from '@mb-react/krg-getid-form-data-common';
import {useSelector} from "react-redux";
import {getFormValues} from "@mb-react/mb-react-core";
import {forms} from "../../../../../constants/form";
import {
    getAccountLegalEntityType,
    isLoggedAccountLegalEntity,
    isModificationVRUseCase, isOperationModeNotProtected,
    isOperationModeProtected,
    isUseCaseReRegisterVehicle, usePlateDataFromSystemForUCs
} from "../../../../../util/businesslogicUtil";
import {accountSubType, changeOwnershipSubUseCases, plateCategories} from "../../../../../constants/application";

export const plateNumberLatinRegex = "^(21|22|23|24)\\s[A-Z]{1,2}\\s\\d{1,5}$";
export const serialPlateNumberLatinRegex = "[0-9]{3}";

export const plateDataValidation = (values) => {
    const globalValidation = useGlobalValidation()
    const formValuesApp = useSelector((state) => getFormValues(state, forms.APPLICATION))

    const validate = (values) => {
        const errors = {};

        let regexNumbersOnly = /^[0-9]*$/;
        let desiredPlateRegex = /^[A-Za-z][0-9]*$/;
        let desiredPlateNumLatinRegex = /^[A-Z][1-9][0-9]{0,4}$/;
        let fieldValidation = values[genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN];
        let assignedPlateNumber = values["assignedPlateNumber"];

        if (!isModificationVRUseCase(formValuesApp)) {
            if (fieldValidation != undefined) {
                if (fieldValidation && !fieldValidation.toString().match(desiredPlateRegex)) {
                    errors[genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN] = "invalidPlateNumber";
                }
            }
        }

        if (isOperationModeNotProtected() && isUseCaseReRegisterVehicle(formValuesApp)) {
            if (assignedPlateNumber != undefined) {
                if (assignedPlateNumber && !assignedPlateNumber.toString().match(desiredPlateRegex)) {
                    errors["assignedPlateNumber"] = "invalidPlateNumber";
                }
            }
        }

        if (!values) {
            errors.validation = 'Form values undefined!'
        } else {

            if(usePlateDataFromSystemForUCs(formValuesApp[formFieldNames.USE_CASE])){
                if (values && values[formFieldNames.PLATE_NUMBER_LATIN] &&
                    !(values[formFieldNames.PLATE_NUMBER_LATIN].toString().match(desiredPlateNumLatinRegex) ||
                        values[formFieldNames.PLATE_NUMBER_LATIN].toString().match(plateNumberLatinRegex))) {
                    errors[formFieldNames.PLATE_NUMBER_LATIN] = "invalidPlateNumber";
                }
            }

            if (!isModificationVRUseCase(formValuesApp)) {
                if (globalValidation.isMandatory(values, genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN)) {
                    isRequired(values, genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN, errors);
                }

                if (!isOperationModeProtected() && isUseCaseReRegisterVehicle(formValuesApp)) {
                    if (values && values[genericFormFieldNames.OLD_PLATE_NUMBER_LATIN] && !values[genericFormFieldNames.OLD_PLATE_NUMBER_LATIN].toString().match(regexNumbersOnly)) {
                        errors[genericFormFieldNames.OLD_PLATE_NUMBER_LATIN] = "onlyNumbersAllowed";
                    }
                }

                if ([EUseCases.NEW, EUseCases.MODIFY_VEHICLE_DATA, EUseCases.RE_REGISTER, EUseCases.RESERVE_PLATE_NUMBER, EUseCases.CHANGE_OWNERSHIP].includes(formValuesApp["useCase"])) {
                    if(formValuesApp["useCase"] === EUseCases.CHANGE_OWNERSHIP){
                        if([changeOwnershipSubUseCases.SALE_AND_BUY, changeOwnershipSubUseCases.BY_COURT, changeOwnershipSubUseCases.BY_AUCTION].includes(formValuesApp["changeOwnershipSubUseCase"])){
                            if(values && (!values["sourcePlate"] || values["sourcePlate"] !== "FURTHER_USE")){
                                isRequired(values, formFieldNames.PLATE_CATEGORY, errors);
                                isRequired(values, formFieldNames.PLATE_USAGE, errors);
                            }
                        }
                    }else{
                        if(values && (!values["sourcePlate"] || values["sourcePlate"] !== "FURTHER_USE")) {
                            isRequired(values, formFieldNames.PLATE_CATEGORY, errors);
                            isRequired(values, formFieldNames.PLATE_USAGE, errors);
                        }
                    }
                    if (isLoggedAccountLegalEntity()
                        && [accountSubType.GOVERNMENT, accountSubType.COMPANY, accountSubType.NATIONAL_ORGANIZATION].includes(getAccountLegalEntityType())){
                        if (plateCategories.OTHER === values[formFieldNames.PLATE_CATEGORY]) {
                        } else if (plateCategories.MOTORCYCLE === values[formFieldNames.PLATE_CATEGORY]
                            && "private" !== values[formFieldNames.PLATE_USAGE]) {
                        }else{
                            if(!values[formFieldNames.PLATE_PROVINCE] && !values[formFieldNames.COUNTRY_CODE]){
                                isRequired(values, formFieldNames.PLATE_PROVINCE, errors);
                            }
                        }
                    }else{
                        if(!values[formFieldNames.PLATE_PROVINCE] && !values[formFieldNames.COUNTRY_CODE]){
                            if(values && (!values["sourcePlate"] || values["sourcePlate"] !== "FURTHER_USE")) {
                                isRequired(values, formFieldNames.PLATE_PROVINCE, errors);
                            }
                        }
                    }
                }
            }
        }
        return errors;
    }
    return {validate};
};