import { call } from 'redux-saga/effects';
import { performSagaAction } from './sagaUtil';


export function* testSaga(action) {
    const callBackFunc = function* (action) {
            console.log('test')
    };
    yield call(performSagaAction, callBackFunc, action, 'testSaga');
}
