import {formFieldNames} from '@mb-react/svarog-ui';
import {genericFormFieldNames} from "@mb-react/krg-getid-form-data-common";
export const plateDataList = [
    formFieldNames.PLATE_USAGE,
    formFieldNames.PLATE_CATEGORY,
    genericFormFieldNames.ADDITIONAL_PLATES,
    genericFormFieldNames.LOST_OR_STOLEN,
    formFieldNames.PLATE_NUMBER,
    formFieldNames.PLATE_PROVINCE,
    genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN,
    formFieldNames.PLATE_COUNTRY_CODE,
    formFieldNames.PLATE_NUMBER_LATIN
];