import React from "react";
import HomeLogin from "./HomeLogin";
import HomeApplyWithoutAccount from "./HomeApplyWithoutAccount";
import {useSelector} from "react-redux";
import {getFormValues, getFromAppStore} from "@mb-react/mb-react-core";
import {forms} from "../../../constants/form";
import {isLoggedIn, isUser} from "../../../util/businesslogicUtil";
import {applicationStore} from "../../../constants/store";

const HomeWrapper = () => {
    const useApplyWithoutAccount = useSelector((state) => getFormValues(state, forms.APPLICATION)?.["useApplyWithoutAccount"])
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))

    return (
        (useApplyWithoutAccount || (isLoggedIn() && userRights && isUser(userRights))) ? <HomeApplyWithoutAccount /> : <HomeLogin />
    );
}
export default HomeWrapper
