import React, {useState, useEffect, useRef} from 'react';
import {
    addMultipleErrorToStep, addMultipleInitialValuesToForm,
    addToAppStore,
    Button, Checkbox, completeStep, getFormValues, getFromAppStore,
    InputText,
    Messages,
    NavButtons, NavigationWizardWrapper,
    nextStep,
    previousStep, removeAllValuesFromForm,
    SimpleTable
} from '@mb-react/mb-react-core';
import {
    PagedRequest,
    formFieldNames,
    getPath, extractApplicantDataToFormValues
} from '@mb-react/svarog-ui';
import {I18n, Translate} from 'react-redux-i18n';
import {i18nl} from '../../../../../constants/i18n';
import {isMobile, preparePersonForTable} from '../../../../../util/environment';
import {useDispatch, useSelector} from "react-redux";
import {forms, navigationName} from "../../../../../constants/form";
import {APPLICATION_STEPS, applicationStore} from "../../../../../constants/store";
import PersonalInformationForm from "../../document/personalData/PersonalInformationForm";
import AddressDataForm from "../../document/addressData/AddressDataForm";
import {useHandleCommonServices} from "../../../../../services/commonServiceHandler";
import TargetPersonSummaryTaskWrapper from "../vehicleData/TargetPersonSummaryTaskWrapper";
import {
    addressDataFormFieldList,
    legalEntityFormFieldList,
    personalInformationFormFieldList
} from "@mb-react/krg-getid-form-data-common";
import LegalEntityForm from "../../document/legalEntityData/LegalEntityForm";
import {LEGAL_ENTITY, NATURAL_PERSON} from "../../../../../constants/application";
import {BUYER, enrollmentStatus, projectDictionaries, dictionaryReference} from "../../../../../constants/general";
import {
    disableDraft,
    disableLeButtonForSubUC, disableNextButton,
    isLoggedAccountLegalEntity,
    isOperationModeNotProtected, isOperationModeProtected
} from "../../../../../util/businesslogicUtil";
import {usePersonalInformationValidation} from "../../document/personalData/personalInformationValidation";
import {useAddressEnrollmentFormDataValidation} from "../../document/addressData/AddressDataValidation";
import {extractObjectBasedOnKeysList} from "../../../../../util/dataExtractorUtil";
import SearchApplicant from "./SearchApplicant";
import {onCancel} from "../../../../../util/componentUtil";
import NavButtonsLocal from "../../../../reactCoreComponents/NavButtonsLocal";

const TargetOwnerPreview = (props) => {
    const applicationsFromStore = useSelector(state => getFromAppStore(state, 'fetchedApplication'))
    const appFormValues = useSelector((state) => getFormValues(state, forms.APPLICATION))
    const [targetPersonTableData, setTargetPersonTableData] = useState([]);
    const handleCommonServices = useHandleCommonServices();
    const [validationError, setValidationError] = useState();
    const guidanceMessage = useRef(null);
    const dispatch = useDispatch();
    const isNotProtectedMode = isOperationModeNotProtected();
    let ownershipPercentage = useSelector((state) => getFromAppStore(state, 'ownershipPercentage')) || {}
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))
    const isProtectedMode = isOperationModeProtected();

    const showAddPersonForm = useSelector((state) =>
        getFromAppStore(state, "showAddTargetPersonForm")
    );
    const selectedPerson = useSelector((state) =>
        getFromAppStore(state, "selectedTargetPerson")
    );
    const isTargetLegalEntity = useSelector((state) =>
        getFromAppStore(state, "isTargetLegalEntity")
    );

    const handleMyPersonsTableState = (data) => {
        setTargetPersonTableData(data)
    }

    const listOfSellers = useSelector((state) => getFromAppStore(state, 'listOfSellers'));
    const listOfBuyers = useSelector((state) => getFromAppStore(state, 'listOfBuyers'))
    let totalPercentageBuyers = useSelector((state) => getFromAppStore(state, 'totalPercentageBuyers'))

    let isListOfBuyersValid = false;

    if (listOfBuyers && listOfBuyers.length > 0) {
        isListOfBuyersValid = listOfBuyers.every((value) => {
            return value.enrollmentStatus == enrollmentStatus.COMPLETE
        })
    }

    const fetchMyIdentity = (data, page, sizePerPage) => {
        let pagedRequest = new PagedRequest();
        pagedRequest.requestObject = data
        pagedRequest.page = page
        pagedRequest.sizePerPage = sizePerPage

        if (isLoggedAccountLegalEntity()) {
            handleCommonServices.handleGetMyIdentityForLegalEntity(pagedRequest, getMyIdentityOnSuccess)
        } else {
            handleCommonServices.handleCallGetMyIdentity(pagedRequest, getMyIdentityOnSuccess)
        }
    }

    const getMyIdentityOnSuccess = (response) => {
        dispatch(addToAppStore("identity", response.data))
    }

    const paginationFunc = (page, sizePerPage) => {
        fetchMyIdentity({}, page + 1, sizePerPage)
    }

    useEffect(() => {
        if (!applicationsFromStore && isNotProtectedMode) {
            fetchMyIdentity({}, 1, 10)
        }
        if (!totalPercentageBuyers) {
            dispatch(addToAppStore('totalPercentageBuyers', []))
        }
        if (!listOfBuyers) {
            dispatch(addToAppStore('listOfBuyers', []))
        } else {
            setTargetPersonTableData(preparePersonForTable(listOfBuyers))
        }
    }, [])

    useEffect(() => {

        if (totalPercentageBuyers && totalPercentageBuyers.length > 0) {
            const totalPercentage = totalPercentageBuyers.reduce((sum, obj) => sum + (obj.percentage ? parseInt(obj.percentage) : 0), 0);

            if (totalPercentage === 100) {
                setValidationError(null);
            } else {
                setValidationError({"error": "Invalid percentage"});
            }
        }

        if (listOfSellers && listOfSellers.length > 0)
            setTargetPersonTableData(preparePersonForTable(listOfBuyers))
    }, [JSON.stringify(listOfBuyers)])

    useEffect(() => {
        if (validationError) {
            guidanceMessage.current.replace({
                severity: 'error', sticky: true, content: (
                    <React.Fragment className="mb-1">
                        <i className="pi pi-info p-2"
                           style={{
                               borderRadius: '50%',
                               backgroundColor: 'var(--surface-b)',
                               color: 'var(--surface-d)'
                           }}/>
                        <div className="ml-2">
                            <Translate value="message.invalidPercentageMessage"/>
                        </div>
                    </React.Fragment>
                )
            });
        } else {
            guidanceMessage.current.clear();
        }
    }, [validationError])

    useEffect(() => {
        if (listOfBuyers && listOfBuyers.length > 0)
            setTargetPersonTableData(preparePersonForTable(listOfBuyers))
    }, [listOfBuyers])

    const onChangePercentage = (value, id) => {
        dispatch(addToAppStore("ownershipPercentage", {...ownershipPercentage, id: value}));

        totalPercentageBuyers = totalPercentageBuyers.filter(function (obj) {
            if (obj.id == id) {
                obj.percentage = parseInt(value) ? parseInt(value) : ""
            }
            return obj
        })
        const totalPercentage = totalPercentageBuyers.reduce((sum, obj) => sum + (obj.percentage ? parseInt(obj.percentage) : 0), 0);

        if (totalPercentage > 100 || totalPercentage < 100) {
            setValidationError({"error": "Invalid percentage"})
        } else {
            setValidationError(null)
        }
        dispatch(addToAppStore("totalPercentageBuyers", totalPercentageBuyers));
    };

    const onChangeLead = (buyerId) => {
        dispatch(addToAppStore("leadId", buyerId));

        totalPercentageBuyers = totalPercentageBuyers.filter(function (obj) {
            obj.lead = obj.id === buyerId;

            return obj;
        });
        dispatch(addToAppStore("totalPercentageBuyers", totalPercentageBuyers));
    };

    const percentageTemplate = (rowData) => {
        let percForPerson = totalPercentageBuyers.find((obj) => {
            return obj.id === rowData.buyerId;
        });

        return (
            <InputText
                value={percForPerson ? percForPerson.percentage : ""}
                maxLength={3}
                onChange={(e) => onChangePercentage(e.target.value, rowData.buyerId)}
            />
        );
    };

    const leadTemplate = (rowData) => {

        let leadForPerson = totalPercentageBuyers.find((obj) => {
            return obj.id === rowData.buyerId;
        });

        return (
            <Checkbox
                checked={leadForPerson.lead}
                onChange={() => {
                    onChangeLead(rowData.buyerId);
                }}
            />
        );
    };

    const columns = [
        {
            field: 'id',
            header: I18n.t(getPath("id")),
            hidden: true
        },
        {
            field: 'tpid',
            header: I18n.t(getPath("tpid"))
        },
        {
            field: 'entityName',
            header: I18n.t(getPath("Name"))
        },
        {
            field: 'entityType',
            header: I18n.t(getPath("entityType"))
        },
        {
            field: 'percentage',
            header: I18n.t(getPath("percentage")),
            body: percentageTemplate
        },
        {
            field: 'lead',
            header: I18n.t(getPath("lead")),
            body: leadTemplate
        },
        {
            field: 'enrollmentStatus',
            header: I18n.t(getPath("enrollmentStatus"))
        }
    ];

    const customActionTemplate = (row) => {
        return (
            <div className="actionColumn" style={{display: "flex"}}>
                <Button icon="pi pi-minus-circle" className="p-button-rounded p-button-danger"
                        onClick={() => removePersonFromStore(row)}/>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-secondary"
                        onClick={() => editPerson(row)}/>
            </div>
        )
    }


    dispatch(addToAppStore("totalPercentageBuyers", totalPercentageBuyers))


    const removePersonFromStore = (row) => {
        listOfBuyers.splice(row.id, 1);
        totalPercentageBuyers.splice(row.id, 1);
        dispatch(addToAppStore('listOfBuyers', listOfBuyers))
        dispatch(addToAppStore('totalPercentageBuyers', totalPercentageBuyers))
        setTargetPersonTableData(preparePersonForTable(listOfBuyers));
    }

    const isFormValid = () => {
        if (totalPercentageBuyers && totalPercentageBuyers.length > 0) {
            let checkBuyer = totalPercentageBuyers.find((obj) => {
                return obj;
            });

            let checkBuyerPercentage = checkBuyer ? checkBuyer.percentage : null;

            let checkBuyerLead = totalPercentageBuyers.some((obj) => {
                return obj.lead === true;
            });
            if (isListOfBuyersValid && checkBuyerPercentage !== undefined && checkBuyerLead && !validationError) {
                return true;
            }
        }
        return false;
    }
    const onNextStep = (values) => {

        if (isFormValid()) {
            dispatch(addToAppStore('targetPersonStepCompleted', true))
            dispatch(addMultipleErrorToStep(APPLICATION_STEPS.TARGET_OWNER, {}, props.navigationName))
            dispatch(completeStep(props.navigationName, APPLICATION_STEPS.TARGET_OWNER))
        } else {
            dispatch(addToAppStore('targetPersonStepCompleted', false))
            dispatch(addMultipleErrorToStep(APPLICATION_STEPS.TARGET_OWNER, {"error": "testerror"}, props.navigationName))
        }
        dispatch(nextStep(props.navigationName))
    }

    const onPrevious = () => {
        dispatch(previousStep(props.navigationName))
    }

    let addPersonButtonLabel = showAddPersonForm ? "action.cancelAddPerson" : "action.addPerson"
    let addLegalEntityButtonLabel = showAddPersonForm ? "action.cancelAddLegalEntity" : "action.addLegalEntity"

    const addTargetOwner = (entityType) => {
        dispatch(addToAppStore('showAddTargetPersonForm', !showAddPersonForm))
        if (entityType === LEGAL_ENTITY) {
            dispatch(addToAppStore('isTargetLegalEntity', true))
        } else {
            dispatch(addToAppStore('isTargetLegalEntity', false))
        }
    }

    const editPerson = (row) => {
        let personData = listOfBuyers[row.id];
        personData = extractApplicantDataToFormValues(personData.applicantData);

        if (row.entityType === LEGAL_ENTITY) {
            dispatch(addToAppStore('isTargetLegalEntity', true))
            let legalEntityData = extractObjectBasedOnKeysList(personData, legalEntityFormFieldList)
            dispatch(addMultipleInitialValuesToForm(forms.LEGAL_ENTITY + props.formNameSufix, legalEntityData));
        } else {
            dispatch(addToAppStore('isTargetLegalEntity', false))
            personalInformationFormFieldList.push(formFieldNames.OWNERSHIP_PERCENTAGE, formFieldNames.OWNERSHIP_LEAD);
            let personalInformation = extractObjectBasedOnKeysList(personData, personalInformationFormFieldList);
            let addressData = extractObjectBasedOnKeysList(personData, addressDataFormFieldList);
            if (configurationData?.operationMode === "PROTECTED") {
                if (personalInformation["placeOfBirthProvince"]) {
                    handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, personalInformation["placeOfBirthProvince"], dictionaryReference.CITIES_PLACE_OF_BIRTH)
                }

                if (personalInformation["placeOfBirthCity"]) {
                    handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, personalInformation["placeOfBirthCity"], dictionaryReference.VILLAGES_PLACE_OF_BIRTH)
                }

                if (addressData["addressProvince"]) {
                    handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, addressData["addressProvince"], dictionaryReference.CITIES_ADDRESS)
                    handleCommonServices.handleGetSingleDictionary(projectDictionaries.INFO, addressData["addressProvince"])
                }

                if (addressData["addressCity"]) {
                    handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, addressData["addressCity"], dictionaryReference.VILLAGES_ADDRESS)
                }

                if (addressData["provinceOfRegistration"]) {
                    handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, addressData["provinceOfRegistration"], dictionaryReference.CITIES_OF_REGISTRATION)
                }

                if (addressData["cityOfRegistration"]) {
                    handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, addressData["cityOfRegistration"], dictionaryReference.VILLAGES_OF_REGISTRATION)
                }
            }

            dispatch(addMultipleInitialValuesToForm(forms.PERSONAL_INFORMATION + props.formNameSufix, personalInformation));
            dispatch(addMultipleInitialValuesToForm(forms.ADDRESS_DATA + props.formNameSufix, addressData));
        }

        totalPercentageBuyers = totalPercentageBuyers.filter(function (obj) {
            return obj.id !== listOfBuyers[row.id].buyerId
        })

        dispatch(addToAppStore("totalPercentageBuyers", totalPercentageBuyers))

        //TODO logic should be moved elsewhere due to possibility to cancel person edit probably to summary submit
        listOfBuyers.splice(row.id, 1);
        dispatch(addToAppStore('listOfBuyers', listOfBuyers))
        dispatch(addToAppStore('showAddTargetPersonForm', true))
    }

    const personInformationSteps = [
        {
            id: APPLICATION_STEPS.PERSONAL_DATA,
            label: isMobile() ? null : <Translate value={"label." + i18nl.PERSONAL_INFORMATION}/>,
            icon: 'pi pi-user',
            component: <PersonalInformationForm navigationName={navigationName.SUB_NAVIGATION_TARGET}
                                                formNameSufix={props.formNameSufix}
                                                formName={forms.PERSONAL_INFORMATION + props.formNameSufix}/>,
            validateFn: usePersonalInformationValidation
        },
        {
            id: APPLICATION_STEPS.ADDRESS_DATA,
            label: isMobile() ? null : <Translate value={"label." + i18nl.ADDRESS_DATA}/>,
            icon: 'pi pi-id-card',
            component: <AddressDataForm navigationName={navigationName.SUB_NAVIGATION_TARGET}
                                        formName={forms.ADDRESS_DATA + props.formNameSufix}
                                        formNameSufix={props.formNameSufix}/>,
            validateFn: useAddressEnrollmentFormDataValidation
        },
        {
            id: "personSummaryInformation",
            label: isMobile() ? null : <Translate value={"label." + i18nl.PERSON_SUMMARY}/>,
            icon: "pi pi-file",
            component: <TargetPersonSummaryTaskWrapper navigationName={navigationName.SUB_NAVIGATION_TARGET}
                                                       formNameSufix={props.formNameSufix}
                                                       formName={"personSummaryInformation" + props.formNameSufix}
                                                       setTargetPersonTableData={handleMyPersonsTableState}/>,
        }
    ]

    const LegalEntityInformationSteps = [
        {
            id: APPLICATION_STEPS.LEGAL_ENTITY,
            label: isMobile() ? null : <Translate value={"label." + i18nl.LEGAL_ENTITY}/>,
            icon: 'pi pi-user',
            component: <LegalEntityForm navigationName={navigationName.SUB_NAVIGATION_TARGET}
                                        formNameSufix={props.formNameSufix}
                                        formName={forms.LEGAL_ENTITY + props.formNameSufix}/>,
            validateFn: usePersonalInformationValidation
        },
        {
            id: "personSummaryInformation",
            label: isMobile() ? null : <Translate value={"label." + i18nl.PERSON_SUMMARY}/>,
            icon: "pi pi-file",
            component: <TargetPersonSummaryTaskWrapper navigationName={navigationName.SUB_NAVIGATION_TARGET}
                                                       formNameSufix={props.formNameSufix}
                                                       formName={"personSummaryInformation" + props.formNameSufix}
                                                       setTargetPersonTableData={handleMyPersonsTableState}/>,
        }
    ]


    function legalEntityButtonShow() {
        if (disableLeButtonForSubUC(appFormValues.changeOwnershipSubUseCase) &&
            (!showAddPersonForm || (showAddPersonForm && isTargetLegalEntity)) &&
            (!listOfBuyers || (listOfBuyers && listOfBuyers.length === 0))) {
            return true;
        }
        return false;
    }

    function isSellerLegalEntity() {
        if (listOfSellers && listOfSellers.length > 0 && listOfSellers[0].entityType === LEGAL_ENTITY) {
            return true;
        } else {
            return false;
        }
    }

    function isBuyerLegalEntity() {
        if (listOfBuyers && listOfBuyers.length > 0 && listOfBuyers[0].entityType === LEGAL_ENTITY) {
            return true;
        } else {
            return false;
        }
    }

    function addPersonButtonShow() {
        if ((!showAddPersonForm || (showAddPersonForm && !isTargetLegalEntity)) && !isBuyerLegalEntity()) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <div>
            <div className="card circleNavWrapper" style={{padding: '1rem'}}>
                {/*<div className="card" style={{padding: '1rem'}}>*/}
                <Messages ref={guidanceMessage}/>
                <h5><Translate value={I18n.t(getPath(i18nl.SEARCH_PERSON))}/></h5>
                {isProtectedMode && <SearchApplicant role={BUYER}/>}
                {!showAddPersonForm && <SimpleTable tableData={targetPersonTableData}
                                                    actionTitle={I18n.t("label.actionTitle")}
                                                    customActionTemplate={customActionTemplate}
                                                    columns={columns}
                                                    paginator={!selectedPerson}
                                                    paginationFunc={paginationFunc}
                />}
                {showAddPersonForm &&
                    <NavigationWizardWrapper
                        items={isTargetLegalEntity ? LegalEntityInformationSteps : personInformationSteps}
                        progressBar={false}
                        readOnly={disableDraft(userRights)}
                        navigationId={navigationName.SUB_NAVIGATION_TARGET}
                        navigationType="CIRCLE"
                        formName={forms.TARGET_DATA}/>
                }
                <div className="col-lg-12 col-sm-12" style={{marginTop: '1rem'}}>
                    <div className="grid">
                        <div className="col-12 text-center">
                            {addPersonButtonShow() &&
                                <Button label={<Translate value={addPersonButtonLabel}/>}
                                        className="mt-2 p-button-secondary" style={{marginRight: '1rem'}} type="button"
                                        onClick={() => addTargetOwner(NATURAL_PERSON)}/>}
                            {legalEntityButtonShow() &&
                                <Button label={<Translate value={addLegalEntityButtonLabel}/>}
                                        className="mt-2 p-button-primary" style={{marginLeft: '1rem'}} type="button"
                                        onClick={() => addTargetOwner(LEGAL_ENTITY)}/>}
                        </div>
                    </div>
                </div>
                {!showAddPersonForm &&
                    <NavButtonsLocal customOnPrevious={() => onPrevious()} customOnNext={() => onNextStep()}
                                disableNextBtn={disableNextButton(isFormValid(), userRights)}
                                customOnCancel={() => onCancel(dispatch)}/>
                }
            </div>
        </div>
    )
}
export default TargetOwnerPreview;