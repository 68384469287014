import React from "react";
import PropTypes from "prop-types";
import { Menubar } from "@mb-react/mb-react-core";
import {
  REDIRECT_TO_APPOINTMENT,
  REDIRECT_TO_DRIVER_LICENSE,
  REDIRECT_TO_EPASSPORT,
  REDIRECT_TO_ID_CARD,
  REDIRECT_TO_LOGIN,
  REDIRECT_TO_MY_APPLICATIONS,
  REDIRECT_TO_MY_APPOINTMENTS,
} from "../../../constants/environment";
import { I18n } from "react-redux-i18n";
import { getPath, i18nl } from "../../../constants/i18n";
import { isLoggedIn } from "../../../util/businesslogicUtil";
import krLogo from "../../../../public/media/KRG_logo.svg";
import { getCookie } from '@mb-react/svarog-ui';
import { isMobile } from "../../../util/environment";
import { useSelector } from "react-redux";

const HomeFooter = () => {
  useSelector(state => state.i18n.locale)

  let items = [
    {
      label: I18n.t(getPath(i18nl.LOGIN)),
      icon: "pi pi-fw pi-prime",
      command: () => {
        window.location = REDIRECT_TO_LOGIN;
      },
    },
  ];

  let username = "Username";
  if (getCookie("username")) {
    username = getCookie("username");
  }
  if (!isLoggedIn()) {
    items = [
      {
        label: I18n.t(getPath(i18nl.BOOK_APPOINTMENT)),
        icon: "pi pi-calendar-plus",
        command: () => {
          window.location = REDIRECT_TO_APPOINTMENT;
        },
      },
      {
        label: I18n.t(getPath(i18nl.LOGIN)),
        icon: "pi pi-fw pi-prime",
        command: () => {
          window.location = REDIRECT_TO_LOGIN;
        },
      },
    ];
  } else {
    items = [
      {
        label: I18n.t(getPath(i18nl.BOOK_APPOINTMENT)),
        icon: "pi pi-calendar-plus",
        command: () => {
          window.location = REDIRECT_TO_APPOINTMENT;
        },
      },
      {
        label: I18n.t(getPath(i18nl.MY_APPOINTMENTS)),
        icon: "pi pi-database",
        command: () => {
          window.location = REDIRECT_TO_MY_APPOINTMENTS;
        },
      },
      {
        label: I18n.t(getPath(i18nl.MY_APPLICATIONS)),
        icon: "pi pi-book",
        command: () => {
          window.location = REDIRECT_TO_MY_APPLICATIONS;
        },
      },
      {
        label: I18n.t(getPath(i18nl.DOCUMENTS)),
        icon: "pi pi-file",
        items: [
          {
            label: I18n.t(getPath(i18nl.CREATE_ID_CARD)),
            icon: "pi pi-id-card",
            command: () => {
              window.location = REDIRECT_TO_ID_CARD;
            },
          },
          {
            label: I18n.t(getPath(i18nl.CREATE_E_PASSPORT)),
            icon: "pi pi-tablet",
            command: () => {
              window.location = REDIRECT_TO_EPASSPORT;
            },
          },
          {
            label: I18n.t(getPath(i18nl.CREATE_DRIVER_LICENSE)),
            icon: "pi pi-credit-card",
            command: () => {
              window.location = REDIRECT_TO_DRIVER_LICENSE;
            },
          },
        ],
      },
    ];
  }

  return (
    <>
      {!isMobile &&
        <div id="homeFooter">
          <div className="grid footer">
            <div className="col-9 xl:col-2 logoFooter">
              <img src={krLogo} alt="" />
              <div id="copyright-text">
                &copy; {I18n.t("label.krgCopyright")}
              </div>
            </div>
            <div className="col-3 xl:col-9">
            </div>
          </div>
        </div>
      }
    </>
  );
};

HomeFooter.propTypes = {
  changeComponent: PropTypes.func,
};

export default HomeFooter;
