import {genericFormFieldNames} from "@mb-react/krg-getid-form-data-common";
import {EUseCases, formFieldNames, isRequired} from "@mb-react/svarog-ui";
import {useGlobalValidation} from "../../../../../hooks/useGlobalValidation";
import { getFormValues } from "@mb-react/mb-react-core";
import { forms } from "../../../../../constants/form";
import { useSelector } from "react-redux";

export const useAddressEnrollmentFormDataValidation = (props) => {
    const globalValidation = useGlobalValidation()
    const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION));
    const personalInformationFormValues = useSelector((state) => getFormValues(state, forms.PERSONAL_INFORMATION + props));
    const addressDataFormValues = useSelector((state) => getFormValues(state, forms.ADDRESS_DATA + props));

    const validate = (values) => {
        const errors = {};

        if (!values) {
            errors.validation = 'Form values undefine!'
        } else {

            values = {...values, ...personalInformationFormValues, ...addressDataFormValues, ...formValues}


            let regexPhone = /^[\+]?[0-9\-().\s]{7,15}$/;
            let phone = values[formFieldNames.PHONE];
            if (phone != undefined) {
                if (phone && !phone.match(regexPhone)) {
                    errors[formFieldNames.PHONE] = "phoneNumberInvalid";
                }
            }

            let regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            let email = values[formFieldNames.EMAIL];
            if (email != undefined) {
                if (email && !email.match(regexEmail)) {
                    errors[formFieldNames.EMAIL] = "eMailInvalid";
                }
            }

            if (globalValidation.isMandatory(values, formFieldNames.ADDRESS_PROVINCE)) {
                isRequired(values, formFieldNames.ADDRESS_PROVINCE, errors);
            }
            if (globalValidation.isMandatory(values, formFieldNames.ADDRESS_CITY)) {
                isRequired(values, formFieldNames.ADDRESS_CITY, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.ADDRESS_VILLAGE)) {
                isRequired(values, genericFormFieldNames.ADDRESS_VILLAGE, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.ADDRESS_INFORMATION_CENTER)) {
                isRequired(values, genericFormFieldNames.ADDRESS_INFORMATION_CENTER, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.ADDRESS_BUILDING_CLASS)) {
                isRequired(values, genericFormFieldNames.ADDRESS_BUILDING_CLASS, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.QUARTER_NAME)) {
                isRequired(values, genericFormFieldNames.QUARTER_NAME, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.SECTOR_NUMBER)) {
                isRequired(values, genericFormFieldNames.SECTOR_NUMBER, errors);
            }
            if (globalValidation.isMandatory(values, formFieldNames.ADDRESS_STREET_NUMBER)) {
                isRequired(values, formFieldNames.ADDRESS_STREET_NUMBER, errors);
            }
            if (globalValidation.isMandatory(values, formFieldNames.ADDRESS_HOUSE_NUMBER)) {
                isRequired(values, formFieldNames.ADDRESS_HOUSE_NUMBER, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.VILLAGE_REGION)) {
                isRequired(values, genericFormFieldNames.VILLAGE_REGION, errors);
            }
            if (globalValidation.isMandatory(values, formFieldNames.PHONE)) {
                isRequired(values, formFieldNames.PHONE, errors);
            }
            if (globalValidation.isMandatory(values, formFieldNames.EMAIL)) {
                isRequired(values, formFieldNames.EMAIL, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.RESIDENCE_CARD_NUMBER)) {
                isRequired(values, genericFormFieldNames.RESIDENCE_CARD_NUMBER, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.RESIDENCE_CARD_DATE_OF_ISSUE)) {
                isRequired(values, genericFormFieldNames.RESIDENCE_CARD_DATE_OF_ISSUE, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.RESIDENCE_CERTIFICATE_NUMBER)) {
                isRequired(values, genericFormFieldNames.RESIDENCE_CERTIFICATE_NUMBER, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.RESIDENCE_CERTIFICATE_DATE_OF_ISSUE)) {
                isRequired(values, genericFormFieldNames.RESIDENCE_CERTIFICATE_DATE_OF_ISSUE, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.RESIDENCE_PERMIT_NUMBER)) {
                isRequired(values, genericFormFieldNames.RESIDENCE_PERMIT_NUMBER, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.RESIDENCE_PERMIT_ISSUE_DATE)) {
                isRequired(values, genericFormFieldNames.RESIDENCE_PERMIT_ISSUE_DATE, errors);
            }

            if (globalValidation.isMandatory(values, genericFormFieldNames.PROVINCE_OF_REGISTRATION)) {
                isRequired(values, genericFormFieldNames.PROVINCE_OF_REGISTRATION, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.CITY_OF_REGISTRATION)) {
                isRequired(values, genericFormFieldNames.CITY_OF_REGISTRATION, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.VILLAGE_OF_REGISTRATION)) {
                isRequired(values, genericFormFieldNames.VILLAGE_OF_REGISTRATION, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.REGISTRATION_BOOK_NUMBER)) {
                isRequired(values, genericFormFieldNames.REGISTRATION_BOOK_NUMBER, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.REGISTRATION_PAGE_NUMBER)) {
                isRequired(values, genericFormFieldNames.REGISTRATION_PAGE_NUMBER, errors);
            }

            if (globalValidation.isMandatory(values, genericFormFieldNames.FOOD_CENTER_INDICATOR)) {
                isRequired(values, genericFormFieldNames.FOOD_CENTER_INDICATOR, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.CATERING_CENTER_PROVINCE)) {
                isRequired(values, genericFormFieldNames.CATERING_CENTER_PROVINCE, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.CATERING_CENTER_NUMBER_NAME)) {
                isRequired(values, genericFormFieldNames.CATERING_CENTER_NUMBER_NAME, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.FOOD_RATION_CARD_NUMBER)) {
                isRequired(values, genericFormFieldNames.FOOD_RATION_CARD_NUMBER, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.FOOD_RATION_CARD_ISSUE_DATE)) {
                isRequired(values, genericFormFieldNames.FOOD_RATION_CARD_ISSUE_DATE, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.REQUEST_APPROVAL_COMMENT)) {
                isRequired(values, genericFormFieldNames.REQUEST_APPROVAL_COMMENT, errors);
            }
        }
        return errors;
    }
    return {validate};
};