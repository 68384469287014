/**
 * Available i18n translation groups
 * @author Minja Raicevic on 19/04/2021.
 * @author radulovicf
 */

export const TRG = Object.freeze({
  LABEL: 'label',
  ERROR: 'err',
  ACTION: 'action',
  MESSAGE: 'message',
  DATE: 'date',
  INFO: 'info',
  CONFIG: 'config',
  ENUM: 'enum'
}
)

export function getPath(code, translationGroup) {
  if (code) {
    switch (translationGroup) {
      case TRG.ERROR: return `${TRG.ERROR}.${code}`
      case TRG.ACTION: return `${TRG.ACTION}.${code}`
      case TRG.MESSAGE: return `${TRG.MESSAGE}.${code}`
      case TRG.DATE: return `${TRG.DATE}.${code}`
      case TRG.INFO: return `${TRG.INFO}.${code}`
      case TRG.CONFIG: return `${TRG.CONFIG}.${code}`
      case TRG.ENUM: return `${TRG.ENUM}.${code}`
      case TRG.LABEL:
      default: {
        return `${TRG.LABEL}.${code}`
      }
    }
  }
  return `label.${i18nl.UNDEFINED}`
}

export const i18nl = Object.freeze({
  LOCALIZATION: "localization",
  EN: "english",
  ES: "spanish",
  HOME_ADDRESS_INFORMATION: "homeAddressInformation",
  APPOINTMENT_NUMBER: "appointmentNumber",
  APPOINTMENT_DATE: "appointmentDate",
  APPOINTMENT_TIME: "appointmentTime",
  APPOINTMENT_PROVIDER: "appointmentProvider",
  APPOINTMENT_SERVICE: "appointmentService",
  APPOINTMENT_STATUS: "appointmentStatus",
  APPOINTMENT_ACTIONS: "appointmentActions",
  PASSWORD_PLACEHOLDER: "passwordPlaceholder",
  PHONE_PLACEHOLDER: "phonePlaceholder",
  CHECK_ACTIVE_VISA: 'checkActiveVisa',
  QUESTIONNAIRE: 'questionnaire',
  APPLICATION_ID: 'applicationId',
  DRAFT_APPLICATION_ID: 'draftApplicationId',
  EXTERNAL_APPLICATION_ID: 'externalApplicationId',
  ADDRESS_INFORMATION: 'addressInformation',
  APPLICATION_DELETE: 'deleteApplication',
  APPLICATION_DELETE_HEADER: 'deleteApplicationHeader',
  APPLICATION_FEE: 'applicationFee',
  APPLICATIONS_REPORT: 'applicationsReport',
  APPOINTMENT_DETAILS: 'appointmentDetails',
  ARE_YOU_SURE: 'areYouSureYouWantToCancel',
  ACCOUNT_RECOVERY: 'accountRecovery',
  ACCOUNT_TYPE: 'accountType',
  ENTITY_TYPE: 'entityType',
  ENTITY_SUB_TYPE: 'entitySubType',
  ALL_DOCUMENTS_MUST_BE_SCANNED: 'allDocumentsMustBeScanned',
  ALL_DOCUMENTS_NOT_IN_ENGLISH: 'allDocumentsNotInEnglish',
  BIOMETRICS: 'biometrics',
  BIO_DATE_PAGE_VISA: 'bioDatePageVisa',
  BIO_DATE_PAGE_PASSPORT: 'bioDatePagePassport',
  BOOK_APPOINTMENT: 'bookAppointment',
  BOOK_APPOINTMENT_WITHOUT_ACCOUNT: 'bookAppointmentWithoutAccount',
  BOOK_APPOINTMENT_LAYOUT: 'bookAppointmentLayout',
  MY_APPOINTMENTS: 'myAppointments',
  SOURCE_VEHICLE: 'sourceVehicle',
  TARGET_VEHICLE: 'targetVehicle',
  SOURCE_OWNER: 'sourceOwner',
  TARGET_OWNER: 'targetOwner',
  SEARCH_PERSON: 'searchPerson',
  ADDED_PERSONS: 'addedPersons',
  SEARCH_VEHICLE: 'searchVehicle',
  VEHICLE_WORKFLOW: 'vehicleWorkflow',
  MY_APPOINTMENTS_LAYOUT: 'myAppointmentsLayout',
  CANCEL: 'cancel',
  CANCELATION: 'cancelation',
  EDIT: 'edit',
  CATEGORY: 'category',
  CHANGE_PASSWORD: 'changePassword',
  PASSWORD_CHANGED: 'passwordChanged',
  ACCOUNT_UNLOCKED: 'accountUnlocked',
  CLEAR: 'clear',
  CLOSE: 'close',
  CONTACT: 'contact',
  CONTACT_MOBILE: 'contactMobile',
  CONFIRMATION_INFORMATION: 'confirmationInformation',
  CONTACT_INFORMATION: 'contactInformation',
  CREATE_E_VISA: 'createEVisa',
  CREATE_E_PASSPORT: 'createEPassport',
  CREATE_ID_CARD: 'createIDCard',
  CREATE_DRIVER_LICENSE: 'createDriverLicense',
  GENERATE_REPORT: 'generateReport',
  HOST_INFORMATION: 'hostInformation',
  DOCUMENTS: 'documents',
  DATA_REQUIRED: 'dataRequired',
  EMAIL: 'email',
  EMAIL_OPTIONAL: 'emailOptional',
  COMPANY_NAME: 'companyName',
  ERROR: 'error',
  ERROR_BAD_USERNAME_PASSWORD: 'errorBadUsernamePassword',
  EVISA_INFORMATION: 'eVisaInformation',
  FAQ: 'faq',
  FATHER: 'father',
  FAMILY_INFORMATION: 'familyData',
  IDENTIFICATION_DATA: 'identificationData',
  INFO: 'info',
  LOCATION_REQUIRED: 'locationRequired',
  LOGIN: 'login',
  HOME: 'homePage',
  LOGIN_WITH_NEW_PASSWORD: 'loginWithNewPassword',
  LOGOUT: 'logout',
  EDIT_ACCOUNT: 'editAccount',
  MARITAL_STATUS_INFORMATION: 'maritalStatusInformation',
  MOTHER: 'mother',
  MY_APPLICATIONS: 'myApplications',
  CHECK_APPLICATION_STATUS: 'checkApplicationStatus',
  APPLICATION_REPORT: 'applicationReport',
  MY_APPLICATIONS_LAYOUT: 'myApplicationsLayout',
  NEXT: 'next',
  NO: 'no',
  NO_DATA_TO_DISPLAY: 'noDataToDisplay',
  OTHER_INFORMATION: 'otherInformation',
  ENROLLMENT: 'enrollment',
  PAYMENT: 'payment',
  ISSUING: 'issuing',
  PASSPORT_INFORMATION: 'passportInformation',
  PASSPORT_PHOTO: 'passportPhoto',
  PERSON_PHOTO: 'personPhoto',
  PERSONAL_INFORMATION: 'personalInformation',
  LEGAL_ENTITY: 'legalEntity',
  PHONE: 'phone',
  PHONE_FROMAT: 'phoneFormat',
  PHONE_AND_EMAIL: 'phoneAndEmail',
  PREFERRED_CONFIRMATION_CHANNEL: 'preferredConfirmationChannel',
  PREFILL: 'prefill',
  PREPARE_SUPPORTING_DOCUMENTS: 'prepareSupportingDocuments',
  PREPARE_SUPPORTING_DOCUMENTS_VISA: 'prepareSupportingDocumentsVisa',
  PREVIOUS: 'previous',
  RECENT_PASSPORT_PHOTO_SIZE: 'recentPassportPhotoSize',
  REGISTER_ACCOUNT: 'registerAccount',
  REGISTER_ACCOUNT_INSTRUCTIONS: 'registerAccountInstructions',
  SHOW_APPLICATIONS: 'showApplications',
  SPOUSE_GIVEN_NAME: 'spouseGivenName',
  SPOUSE_SURNAME: 'spouseSurname',
  STATUS: 'status',
  STATE: 'state',
  SUBMIT: 'submit',
  SUMMARY: 'summary',
  SUCCESS: 'success',
  SUCCESSFULLY_CREATED_ACCOUNT: 'successfullyCreatedAccount',
  SUCCESSFULLY_CREATED_REPORT: "successfullyCreatedReport",
  SUCCESSFULLY_CREATED_PASSPORT: 'successfullyCreatedPassport',
  SUCCESSFULLY_CREATED_VISA: 'successfullyCreatedVisa',
  SUCCESSFULLY_CREATED_ID_CARD: 'successfullyCreatedIDCard',
  SUCCESSFULLY_DELETED_APPLICATION: 'successfullyDeletedApplication',
  SUPPORTING_DOCUMENTS: 'supportingDocuments',
  SUPPORTING_DOC_APPLICATION_FEE: 'supportingDocApplicationFee',
  SEARCH: 'search',
  SELECT_PASSPORT_TYPE: 'selectTypeOfPassport',
  SELECT_VISA_TYPE: 'selectTypeOfVisa',
  ID_CARD: 'ID_CARD',
  DRIVER_LICENSE: 'driverLicense',
  ID_CARD_LAYOUT: 'idCard',
  IDENTITY_MANAGEMENT: 'identityManagement',
  NEW_IDENTITY: "newIdentity",
  MODIFY_IDENTITY: "modifyIdentity",
  CANCEL_IDENTITY: "cancelIdentity",
  DRAFT: 'draft',
  CHASSIS_NUMBER_DIALOG: 'chassisNumberDialog',
  APPLICATION_COMPLETED: 'applicationCompleted',
  CONTINUE: 'continue',
  APPLICATION: 'application',
  BIOMETRIC_INFO: 'biometricInformation',
  ADVANCED: "advanced",
  DL_INFO: "dlInfo",
  DL_DATA: "dlData",
  PLATE_HANDLING: 'plateHandling',
  PROXY_DATA: 'proxyData',
  DOCUMENTS_DATA: 'documentsData',
  HOW_TO_APPLY: 'howToApply',
  INSTRUCTIONS_AND_DIRECTIVES: 'instructionsAndDirectives',
  LEGAL_FORMS: 'legalForms',
  ENTITY_NOT_UNIQUE_MODAL: "entityNotUniqueModal",
  INTERRUPT_WORKFLOW: "interruptWorkflow",

  //NameData
  GIVEN_NAME: "givenName",
  BIRTH_SURNAME: "birthSurname",
  MARRIAGE_SURNAME: "marriageSurname",
  PREVIOUS_NATIONALITY: "previousNationality",
  LE_NAME: "leName",
  //BirthData
  ESTIMATED_YEAR_OF_BIRTH: "estimatedYearOfBirth",
  PLACE_OF_BIRTH_COUNTRY: "placeOfBirthCountry",
  PLACE_OF_BIRTH_LOCATION: "placeOfBirthCity",
  PROVINCE_OF_BIRTH: "provinceOfBirth",
  DISTRICT_OF_BIRTH: "districtOfBirth",
  //OtherPersonalInformationData
  SEX: "sex",
  HEIGHT: "height",
  //MaritalData
  MARITAL_STATUS: "maritalStatus",
  SPOUSE_DATA: "spouseData",
  SPOUSE_DATA_MOBILE: "spouseDataMobile",
  ADDRESS_DATA: "addressData",
  BIRTH_DATA: "birthData",
  PARENTS_DATA: "parentsData",
  REPRESENTATIVE_DATA: "representativeData",
  OTHER_DATA: "otherData",
  OTHER_DATA_MOBILE: "otherDataMobile",
  REQUIRED_AT_LEAST_NAME_OR_SURNAME: "atLeastNameOrSurnameRequired",
  PORTAL_RELATED_DATA: "portalRelatedData",
  ADDITIONAL_DATA: "additionalData",
  SPOUSE_NAME: "spouseName",
  //ParentsData
  APPLICANT_ADOPTED: "applicantAdopted",
  UNKNOWN_FATHER: "unknownFather",
  UNKNOWN_MOTHER: "unknownMother",
  FATHERS_NAME: "fathersName",
  FATHERS_SURNAME: "fathersSurname",
  MOTHERS_NAME: "mothersName",
  MOTHERS_SURNAME: "mothersSurname",
  //representative data
  ACCOMPANIED_WITH_REPRESENTATIVE: "accompaniedWithRepresentative",
  //residentialAddress
  ADDRESS_COUNTRY: "addressCountry",
  ADDRESS_PROVINCE: "addressProvince",
  ADDRESS_DISTRICT: "addressDistrict",
  ADDRESS_LOCATION: "addressLocation",
  ADDRESS_STREET_AND_HOUSE_NUMBER: "addressStreetAndHouseNumber",
  ADDRESS_POSTAL_CODE: "addressZipCode",
  ADDRESS_CITY: "addressCity",
  ADDRESS_STREET_NUMBER: "addressStreetNumber",
  ADDRESS_STREET: "addressStreet",
  //IDENTIFICATION DOCUMENT
  IDENTIFICATION_DOCUMENT_DATA: "identificationDocumentData",
  IDENTIFICATION_DOCUMENT_TYPE: "identificationDocumentType",
  IDENTIFICATION_DOCUMENT_NUMBER: "identificationDocumentNumber",
  IDENTIFICATION_DOCUMENT_DATE_OF_EXPIRY: "identificationDocumentDateOfExpiry",
  IDENTIFICATION_DOCUMENT_ISSUING_AUTHORITY: "identificationDocumentIssuingAuthority",
  IDENTIFICATION_DOCUMENT_ISSUING_AUTHORITY_PROVINCE: "identificationDocumentIssuingAuthorityProvince",
  IDENTIFICATION_DOCUMENT_ISSUING_AUTHORITY_DISTRICT: "identificationDocumentIssuingAuthorityDistrict",
  //not show on gui
  APPLICATION_ID_FIELD: "applicationID",
  //contact details
  PHONE_NUMBER: "phoneNumber",
  //APPLICATION TYPE
  USE_CASE: "useCase",
  APPLICATION_TYPE: "applicationType",
  DOCUMENT_TYPE: "documentType",
  REASON_FOR_REPLACE_USE_CASE: "reasonForReplaceUseCase",
  //global portal
  SATISFIED_WITH_PORTAL_VALUES: "satisfiedWithPortalService",
  PORTAL_RELATED_COMMENT: "portalRelatedComment",
  USER_EXPERIENCE: "userExperience",

  GENERAL_INFO: "generalInfo",
  APPLICATION_DETAILS: "applicationDetails",

  CONFIRMATION_CHECKBOX: "confirmationCheckbox",

  TYPE: 'type',
  TRANSLATION_REQUIREMENTS: 'translationRequirements',
  TRANSLATION_ACCURATE: 'translationAccurate',
  TRANSLATOR_COMPETENT: 'translatorCompetent',
  UNDEFINED: 'undefined',
  UPLOAD_REQUIREMENTS: 'uploadRequirements',
  UPLOAD_FAILED: 'uploadFailed',
  USERNAME: 'username',
  PERSON_ID: 'personID',
  USD_100: 'usd100',
  UPLOAD: 'upload',
  WRONG_EMAIL_FORMAT: 'wrongEmailFormat',
  WRONG_PHONE_FORMAT: 'wrongPhoneFormat',
  WRONG_PERSONAL_NUMBER_FORMAT: 'wrongNationalIdFormat',
  WRONG_ESTIMATED_YEAR_FORMAT: 'wrongEstimatedYearFormat',
  MUST_BE_OLDER_THAN_18: 'mustBeOlderThan18',
  DATE_IN_THE_FUTURE: 'dateInTheFuture',
  YES: 'yes',
  SERVICE: 'service',
  SERVICE_REQUIRED: 'serviceRequired',
  LOCATION_PROVIDER: 'locationProvider',
  TIME: 'time',
  SELECT_APPLICATION_PROCESSING_STEP: 'selectApplicationProcessingStep',
  PROCESSING_STEP: 'processingStep',
  SELECT_LOCATION_PROVIDER: 'selectLocationAndProvider',
  SELECT_LOCATION_WITH_PROVIDERS: 'selectLocationWithProviders',
  LOCATION: 'location',
  PROVIDER: 'provider',
  POST: 'post',
  SELECT_DATE_AND_TIME: 'selectDateAndTime',
  DATE: 'date',
  DATE_AND_TIME: 'dateAndTime',
  AVAILABLE_DATE: 'availableDate',
  AVAILABLE_TIME: 'availableTime',
  NO_AVAILABLE_TIME_SLOTS: 'noAvailableSlots',
  CONFIRM_DATA: 'confirmData',
  FORGOT_USERNAME_PASSWORD: 'forgotUsernamePassword',
  PASSWORD: 'password',
  REPEAT_PASSWORD: 'repeatPassword',
  REPEATED_PASSWORD_INVALID: 'repeatedPasswordInvalid',
  WRONG_PASSWORD_FORMAT: 'wrongPasswordFormat',
  EXPPECTED_PASSWORD_FORMAT: 'expectedPasswordFormat',
  GIVENNAME_OR_SURNAME_REQUIRED: "givenNameOrSurnameRequired",
  NEW_PASSWORD: 'newPassword',
  CAPTCHA: 'captcha',
  REQUIREMENTS: 'requirements',
  WELCOME: 'welcome',
  PORTAL: 'portal',
  NUMBER: 'number',
  BAD_CREDENTIALS: "badCredentials",
  REFRESH_CAPTCHA: "refreshCaptcha",
  USERNAME_NOT_UNIQUE: "usernameNotUnique",
  ACCOUNT_NOT_UNIQUE: "accountIsNotUnique",
  REPEAT_PASS_NOT_SAME: "repeatPassNotSame",
  EMAIL_NOT_UNIQUE: "emailNotUnique",
  ERROR_NO_ACCOUNT_WITH_GIVEN_USERNAME: "errorNoAccountWithGivenUsername",
  ERROR_ACCOUNT_NOT_LOCKED: "errorAccountNotLocked",
  ERROR_MAX_NUMBER_OF_APPOINTMENTS_REACHED: "errorMaxNumeberOfAppointmentsReached",
  MAX_NUMBER_OF_APPOINTMENTS_REACHED: "maxNumeberOfAppointmentsReached",
  MAX_NUMBER_OF_APPLICATIONS_REACHED: "maxNumberOfApplicationsReached",
  MAX_NUMBER_OF_APPLICATIONS_REACHED_MESSAGE: "maxNumberOfApplicationsReachedMessage",
  NATURAL_PERSON: "naturalPerson",
  CHANGE: "change",
  DOWNLOAD: "download",
  FINISH: "finish",
  LOGIN_LINE_1: "line1",
  LOGIN_LINE_2: "line2",
  LOGIN_LINE_3: "line3",
  SCHEDULE_APPOINTMENT_1: "sheduleAppointment1",
  SCHEDULE_APPOINTMENT_2: "sheduleAppointment2",
  TEXT_LINE_1: "textLine1",
  TEXT_LINE_2: "textLine2",
  TEXT_LINE_3: "textLine3",
  HOME_PAGE: "homePage",
  ADMIN_HOME: "adminHome",
  PRINT_REPORTS: 'printReports',
  PRINT_ACCOUNT_REPORT: 'printAccountReport',
  PRINT_APPLICATION_REPORT: 'printApplicationReport',
  ADMIN_ACCOUNT_HOME: "adminAccountHome",
  ADMIN_ACCOUNT_OVERVIEW: "adminAccountOverview",
  ADMIN_ACCOUNT_ADMINISTRATION: "adminAccountAdministration",
  ACCOUNT_ID: "accountId",
  CONTENT_MANAGEMENT_HOME: "contentManagementHome",
  APPOINTMENT_ID: "appointmentId",
  START_DATE: "startDate",
  END_DATE: "endDate",
  CANCEL_APPOINTMENT: 'cancelAppointment',
  SUCCESSFULLY_CANCELLED_APPOINTMENT: 'successfullyCancelledAppointment',
  BAD_PASSWORD: 'badPassword',
  NO_ACCOUNT_WITH_GIVEN_USERNAME: 'noAccountWithGivenUsername',
  SMS_WITH_PASSWORD: 'smsWithPassword',
  CREATED_BY_REPRESENTATIVE: "createdByRepresentative",
  RECEIVE_NOTIFICATIONS: "receiveNotifications",
  CREATED_BY_HOT_LINE: "createdByHotline",
  CREATED: "created",
  UPDATED: "updated",
  TRANSITION_COMMENTS: "transitionComments",
  TRANSITION_NAME: "transitionName",
  DESTINATION_STATUS: "destinationStatus",
  SOURCE_STATUS: "sourceStatus",

  ERROR_ACCOUNT_LOCKED: "errorAccountLocked",
  ERROR_ACCOUNT_NOT_CONFIRMED: "errorAccountNotConfirmed",
  ERROR_ACCOUNT_INACTIVE: "errorAccountNotInactive",
  ERROR_ACCOUNT_EXPIRED: "errorAccountNotExpired",
  ERROR_ACCOUNT_NOT_APPROVED: "errorAccountNotApproved",
  ERROR_ACCOUNT_DELETED: "errorAccountNotDeleted",
  ERROR_BAD_OLD_PASSWORD: "errorBadOldPassword",
  ERROR_BAD_NEW_PASSWORD: "errorBadNewPassword",

  //representative data
  REPRESENTATIVE_TYPE: "representativeType",
  REPRESENTATIVE_SURNAME: "representativeSurname",
  REPRESENTATIVE_NAME: "representativeName",
  REPRESENTATIVE_PERSONAL_NUMBER: "representativePersonalNumber",
  REPRESENTATIVE_DATE_OF_BIRTH: "representativeDateOfBirth",
  REPRESENTATIVE_SEX: "representativeSex",


  //identification data
  ESTIMATED_DATE_OF_BIRTH: "estimatedDateOfBirth",
  ESTIMATED_DAY: "estimatedDay",
  ESTIMATED_MONTH: "estimatedMonth",
  ESTIMATED_YEAR: "estimatedYear",
  PASSWORD_PERSONAL: "password",
  DATE_OF_BIRTH: 'dateOfBirth',
  PERSONAL_NUMBER: 'personalNumber',
  FIRST_NAME: 'firstName',
  SURNAME: 'surname',

  PUBLIC_PERSON: "PUBLIC_PERSON",
  HOTLINE_OPERATOR: "HOTLINE_OPERATOR",
  SingleM: 'singleM',
  MarriedM: 'marriedM',
  DivorcedM: 'divorcedM',
  WidowedM: 'widowedM',
  SingleF: 'singleF',
  MarriedF: 'marriedF',
  DivorcedF: 'divorcedF',
  WidowedF: 'widowedF',

  //messages
  UNAUTHORIZED_REQUEST_MESSAGE: "unauthorizedRequestMessage",
  FORBIDDEN_REQUEST_MESSAGE: "forbiddenRequestMessage",
  NOT_FOUND_RESOURCE_MESSAGE: "notFoundResourceMessage",
  SERVICE_UNAVAILABLE_MESSAGE: "serviceUnavailableMessage",
  INTERNAL_SERVER_ERROR_MESSAGE: "internalServerErrorMessage",
  BAD_REQUEST_MESSAGE: "badRequestMessage",
  GO_TO_HOME_PAGE: "goToHomePage",//Go to the main page.&nbsp;
  APPLICATION_NOT_COMPLETE: "applicationIsNotComplete",
  SUPPORTING_DOCUMENTS_ENROLLMENT: "supportingDocumentsEnrollment",
  CHASSIS_NUMBER_MATCH_MESSAGE: "chassisNumberMatchMessage",
  ENTITY_NOT_UNIQUE: "entityNotUnique",

  //reasons for replace
  LOST: "LOST",
  STOLEN: "STOLEN",
  DAMAGED: "DAMAGED",

  // use case description

  NEW_DESC: "NEW_DESC",
  NATURALIZATION_DESC: "NATURALIZATION_DESC",
  RENEW_DESC: "RENEW_DESC",
  REPLACE_DESC: "REPLACE_DESC",
  MODIFY_DESC: "MODIFY_DESC",

  // use case description for passport

  NEW_DESC_PASSPORT: "NEW_DESC_PASSPORT",
  NATURALIZATION_DESC_PASSPORT: "NATURALIZATION_DESC_PASSPORT",
  RENEW_DESC_PASSPORT: "RENEW_DESC_PASSPORT",
  REPLACE_DESC_PASSPORT: "REPLACE_DESC_PASSPORT",
  MODIFY_DESC_PASSPORT: "MODIFY_DESC_PASSPORT",

  // use case description for driver license 
  NEW_DESC_DL: "NEW_DESC_DL",
  NATURALIZATION_DESC_DL: "NATURALIZATION_DESC_DL",
  RENEW_DESC_DL: "RENEW_DESC_DL",
  REPLACE_DESC_DL: "REPLACE_DESC_DL",
  MODIFY_DESC_DL: "MODIFY_DESC_DL",

  //identity
  USE_IDENTITY: "useMyIdentity",

  VEHICLE_IDENTIFICATION: "vehicleIdentification",
  TECHNICAL_DESCRIPTION: "technicalDescription",
  VEHICLE_SUMMARY: "vehicleSummary",
  PERSON_SUMMARY: "personSummary"

});
