import {
    applicantGenericFieldsList,
    applicationGenericFieldsList,
    genericFormFieldNames,
    identityGenericFieldsList
} from "@mb-react/krg-getid-form-data-common";
import {
    Button, Dialog, Message, NavButtons,
    addMultipleErrorToStep,
    addToAppStore,
    createMapErrors,
    getDictionaryByKey,
    getFormErrors,
    getFormValues,
    hasValidationErrors, getFromAppStore, previousStep
} from "@mb-react/mb-react-core";
import {
    extractFormValuesToApplicationData,
    extractFormValuesToPersonData,
    extractLegalEntityData,
    formFieldNames, setCookie
} from "@mb-react/svarog-ui";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {I18n, Translate} from "react-redux-i18n";
import {REDIRECT_TO_APPOINTMENT, ROOT} from "../../../../../constants/environment";
import {forms} from "../../../../../constants/form";
import {dictionaryReference, projectDictionaries} from "../../../../../constants/general";
import {TRG, getPath, i18nl} from "../../../../../constants/i18n";
import {APPLICATION_STEPS, applicationStore} from "../../../../../constants/store";
import {loadFile, prepareServerStringDate} from "../../../../../sagas/generators/sagaUtil";
import {useHandleCommonServices} from "../../../../../services/commonServiceHandler";
import {getLocalSelectOptionTranslation} from "../../../../componentUtil";
import {
    getAccountType,
    isLoggedAccountLegalEntity, isNotRegisteredUserRole, isOperationModeNotProtected, isOperationModeProtected,
    LEGAL_ENTITY_GENERIC_FIELDS,
    legalEntityTypeMapper
} from "../../../../../util/businesslogicUtil";
import {ACCOUNT_IDENTITY, IDENTITY_STATE, NATURAL_PERSON} from "../../../../../constants/application";
import {onCancel} from "../../../../../util/componentUtil";
import NavButtonsLocal from "../../../../reactCoreComponents/NavButtonsLocal";

const SummaryTaskWrapperIM = (props) => {
    let formValuesApplication = useSelector((state) => getFormValues(state, forms.APPLICATION))
    let formValuesPersonalInformation = useSelector((state) => getFormValues(state, forms.PERSONAL_INFORMATION))
    let formValuesAddressData = useSelector((state) => getFormValues(state, forms.ADDRESS_DATA + props.formNameSufix))
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS));

    const isPersonalInformationFormValid = useSelector((state) => hasValidationErrors(state, forms.PERSONAL_INFORMATION));
    const isAddressDataValid = useSelector((state) => hasValidationErrors(state, forms.ADDRESS_DATA + props.formNameSufix));

    const personalInformationFormErrors = useSelector((state) => getFormErrors(state, forms.PERSONAL_INFORMATION));
    const addressDataErrors = useSelector((state) => getFormErrors(state, forms.ADDRESS_DATA + props.formNameSufix));

    const legalEntityData = useSelector((state) => getFormValues(state, forms.LEGAL_ENTITY + props.formNameSufix))
    const accountIdentity = useSelector(state => getFromAppStore(state, ACCOUNT_IDENTITY));

    let formValues = {...formValuesApplication, ...formValuesPersonalInformation, ...formValuesAddressData, ...legalEntityData}

    const [showDraftDialog, setShowDraftDialog] = useState(false);
    const [showApplicationSubmittedModal, setShowApplicationSubmittedModal] = useState(false);

    const provinceOfRegistrationOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROVINCES))
    const cityOfRegistrationOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.CITIES_OF_REGISTRATION))
    const villageOfRegistrationOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VILLAGES_OF_REGISTRATION))
    const countryOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.COUNTRY_CODES))
    const provincesOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROVINCES))
    const cityOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.CITIES_PLACE_OF_BIRTH))
    const villageOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VILLAGES_PLACE_OF_BIRTH))
    const provincesAddressOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROVINCES))
    const cityAddressOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.CITIES_ADDRESS))
    const infoCenterAddressOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.INFO))
    const villageAddressOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VILLAGES_ADDRESS))
    const buildingClassAddressOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.BUILDING_CLASS))
    const genderOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.GENDER_CODES))
    const maritalOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.MARITAL_CODES))
    const foodCenterOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.FOOD_CENTER_INDICATOR))
    const cateringCenterProvinceOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROVINCES))
    const cateringCenterNumberOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.FOOD))
    const professionOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROFESSION))
    const nationalityOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.NATIONALITIES))
    const bloodGroupOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.BLOOD_GROUP))
    const dispatch = useDispatch();
    const handleCommonServices = useHandleCommonServices();
    let isLegalEntity = isLoggedAccountLegalEntity()
    const [draftApplication, setDraftApplication] = useState(false)

    const dynamicColumns = Object.keys(formValues).map((x, i) => {

        if (x == "applicationType") {
            return;
        }

        return formValues[x] && <div className="grid IDsummary" key={i}>
            <div className="col-12 xl:col-6">
                {I18n.t("label." + x)}
            </div>
            <div className="col-12 xl:col-6 summaryValue">
                {decorateField(x, formValues[x])}
            </div>
        </div>
    });

    function decorateField(formLabel, formValue) {
        switch (formLabel) {
            case genericFormFieldNames.PROVINCE_OF_REGISTRATION:
                return getLocalSelectOptionTranslation(provinceOfRegistrationOptions, formValue)
            case genericFormFieldNames.CITY_OF_REGISTRATION:
                return getLocalSelectOptionTranslation(cityOfRegistrationOptions, formValue)
            case genericFormFieldNames.VILLAGE_OF_REGISTRATION:
                return getLocalSelectOptionTranslation(villageOfRegistrationOptions, formValue)
            case formFieldNames.PLACE_OF_BIRTH_COUNTRY:
                return getLocalSelectOptionTranslation(countryOptions, formValue)
            case formFieldNames.PLACE_OF_BIRTH_PROVINCE:
                return getLocalSelectOptionTranslation(provincesOptions, formValue)
            case formFieldNames.PLACE_OF_BIRTH_CITY:
                return getLocalSelectOptionTranslation(cityOptions, formValue)
            case genericFormFieldNames.PLACE_OF_BIRTH_VILLAGE:
                return getLocalSelectOptionTranslation(villageOptions, formValue)
            case formFieldNames.ADDRESS_PROVINCE:
                return getLocalSelectOptionTranslation(provincesAddressOptions, formValue)
            case formFieldNames.ADDRESS_CITY:
                return getLocalSelectOptionTranslation(cityAddressOptions, formValue)
            case genericFormFieldNames.ADDRESS_VILLAGE:
                return getLocalSelectOptionTranslation(villageAddressOptions, formValue)
            case genericFormFieldNames.ADDRESS_INFORMATION_CENTER:
                return getLocalSelectOptionTranslation(infoCenterAddressOptions, formValue)
            case genericFormFieldNames.ADDRESS_BUILDING_CLASS:
                return getLocalSelectOptionTranslation(buildingClassAddressOptions, formValue)
            case formFieldNames.GENDER:
                return getLocalSelectOptionTranslation(genderOptions, formValue)
            case formFieldNames.MARITAL_STATUS:
                return getLocalSelectOptionTranslation(maritalOptions, formValue)
            case genericFormFieldNames.FOOD_CENTER_INDICATOR:
                const selectedOption = getLocalSelectOptionTranslation(foodCenterOptions, formValue);
                return selectedOption === "NO" ? "NO" : "YES";
            case genericFormFieldNames.CATERING_CENTER_PROVINCE:
                return getLocalSelectOptionTranslation(cateringCenterProvinceOptions, formValue)
            case genericFormFieldNames.CATERING_CENTER_NUMBER_NAME:
                return getLocalSelectOptionTranslation(cateringCenterNumberOptions, formValue)
            case formFieldNames.POSITION:
                return getLocalSelectOptionTranslation(professionOptions, formValue)
            case formFieldNames.NATIONALITY:
                return getLocalSelectOptionTranslation(nationalityOptions, formValue)
            case formFieldNames.BLOOD_GROUP:
                return getLocalSelectOptionTranslation(bloodGroupOptions, formValue)
            case "enrolmentStarted":
                return prepareServerStringDate(formValue)
            default:
                return typeof formValue == "string" || typeof formValue == "number" ? formValue : prepareServerStringDate(formValue)
        }
    }


    const isFormComplete = () => {
        if (isPersonalInformationFormValid || isAddressDataValid) {
            return false
        } else {
            return true
        }
    };

    const saveApplication = (state) => {
        if (isNotRegisteredUserRole(userRights) || !accountIdentity) {
            saveIdentityApplication(state)
        } else {
            saveAccountIdentity(state)
        }
    };

    const saveAccountIdentity = () => {
        if (isLegalEntity) {
            let requestObject = extractLegalEntityData({
                    ...formValues,
                    [formFieldNames.LE_TYPE]: legalEntityTypeMapper(formValues[formFieldNames.LE_TYPE]),
                    [formFieldNames.STATE]: formValuesApplication.useCase == "CANCEL_PERSON" ? "SUSPENDED" : "ACTIVE"
                },
                Object.values(LEGAL_ENTITY_GENERIC_FIELDS))
            handleCommonServices.handleSaveIdentityForLegalEntity(requestObject, () => {
                setShowDraftDialog(false)
                setShowApplicationSubmittedModal(true)
                handleCommonServices.handleCheckLeIdentityState(getIdentityStateOnSuccess)
            })
        } else {
            let requestObject = extractFormValuesToPersonData({
                    ...formValues,
                    [formFieldNames.STATE]: formValuesApplication.useCase == "CANCEL_PERSON" ? "SUSPENDED" : "ACTIVE"
                },
                Object.values([...identityGenericFieldsList, formFieldNames.SPOUSE_NAME]))
            handleCommonServices.handleCallSaveIdentity(requestObject, () => {
                setShowDraftDialog(false)
                setShowApplicationSubmittedModal(true)
                handleCommonServices.handleCheckPersonIdentityState(getIdentityStateOnSuccess)
            })
        }
    }

    const getIdentityStateOnSuccess = (result) => {
        setCookie(IDENTITY_STATE, result)

        setShowDraftDialog(false)
        setShowApplicationSubmittedModal(true)
    }

    const saveIdentityApplication = (state) => {
        let requestObject = extractFormValuesToApplicationData({
                ...formValues,
                [formFieldNames.APPLICATION_STATUS]: state,
                [formFieldNames.APPLICANT_TYPE]: isNotRegisteredUserRole(userRights) ? NATURAL_PERSON : getAccountType(),
            },
            Object.values(applicationGenericFieldsList), null, Object.values(applicantGenericFieldsList))
        handleCommonServices.handleGetCallSaveApplication(requestObject, onSuccessSaveApplication(state))
    }

    const onSuccessSaveApplication = (state) => {
        if (isFormComplete() && state == "CREATED") {
            downloadPreEnrollmentSummaryReport()
        }

        if(state == "CREATED_INCOMPLETE"){
            setDraftApplication(true)
        }

        setShowDraftDialog(false)
        setShowApplicationSubmittedModal(true)
    }

    function createApplication() {
        if (isFormComplete()) {

            saveApplication("CREATED")
        } else {
            openDraftModal()
        }
    }

    function createDraftApplication() {
        saveApplication("CREATED_INCOMPLETE")
    }

    const renderDraftFooter = () => {
        return (
            <div>
                <Button label={I18n.t("label.draft")} icon="pi pi-edit" onClick={() => createDraftApplication()}
                        className="p-button-text"/>
                <Button label={I18n.t("label.continue")} icon="pi pi-arrow" onClick={() => {
                    dispatch(addToAppStore('validationMode', "Hard"))
                    setShowDraftDialog(false)
                }} className="p-button-text"/>
            </div>
        );
    };

    const renderApplicationModalFooter = () => {
        let homeRedirect = isOperationModeProtected() ? "/admin" : "/"
        return (
            <div>
                <Button
                    label={I18n.t("label.Home")}
                    icon="pi pi-home"
                    onClick={() => {
                        window.location = ROOT + homeRedirect
                    }}
                    className="p-button"
                />
                {!accountIdentity && !draftApplication && <Button
                    label={I18n.t("label.printSummary")}
                    icon="pi pi-home"
                    className="p-button"
                    onClick={downloadPreEnrollmentSummaryReport}
                />}
                {!accountIdentity && !configurationData.dlGoLive && !draftApplication && isOperationModeNotProtected() && <Button
                    label={I18n.t("label.bookAppointment")}
                    icon="pi pi-calendar-plus"
                    onClick={startBookApp}
                    className="p-button"
                />}
            </div>
        );
    };

    const startBookApp = () => {
        sessionStorage.setItem("selsectedApplicationId", formValues[formFieldNames.APPLICATION_ID]);
        window.location = REDIRECT_TO_APPOINTMENT
    };

    const onPreEnrollmentReportSuccess = (response) => {
        if (response && response.data) {
            loadFile(response.data, "Pre_Enrollment_Summary_Report.pdf");
        }
    };

    const downloadPreEnrollmentSummaryReport = () => {
        handleCommonServices.handleDownloadPreEnrollmentSummaryReport({
                ...formValues
            },
            genericFormFieldNames,
            onPreEnrollmentReportSuccess
        );
    };

    function openDraftModal() {
        if (isPersonalInformationFormValid) {
            dispatch(addMultipleErrorToStep(APPLICATION_STEPS.PERSONAL_DATA, createMapErrors(personalInformationFormErrors)))
        }
        if (isAddressDataValid) {
            dispatch(addMultipleErrorToStep(APPLICATION_STEPS.ADDRESS_DATA, createMapErrors(addressDataErrors)))
        }
        if (isOperationModeNotProtected()){
            setShowDraftDialog(true)
        }
    }

    const onPrevious = () => {
        dispatch(previousStep(props.navigationName))
    }

    return (
        <div className="card summary">
            <Dialog header={I18n.t(getPath(i18nl.SUCCESS, TRG.LABEL))} visible={showApplicationSubmittedModal}
                    style={{width: '54vw'}} footer={renderApplicationModalFooter}>
                <Message severity="info" text={I18n.t("message.applicationSuccessfullySubmitted")}/>
            </Dialog>
            <div className="grid">
                {formValues && formValues.capturedFaceData &&
                    <div className="col-12 md:col-3 xl:col-3">
                        <h5><Translate value="label.faceImage"/></h5>
                        <hr className="sectionSeparator"/>
                        <div className="text-center">
                            <img alt={"uploadedFace"} role="presentation"
                                 src={"data:image/jpg;base64," + formValues.capturedFaceData.image} width={100}/>
                        </div>
                    </div>
                }
                <div className={formValues && formValues.capturedFaceData ? "col-12 md:col-9 xl:col-9 p-2" : 'col-12'}>
                    <h5><Translate value="label.applicationData"/></h5>
                    <hr className="sectionSeparator"/>
                    {dynamicColumns}
                </div>
            </div>
            <Dialog header={I18n.t(getPath(i18nl.DRAFT, TRG.LABEL))} visible={showDraftDialog} style={{width: '54vw'}}
                    footer={renderDraftFooter} onHide={() => setShowDraftDialog(false)}>
                <Message severity="info" text={I18n.t(getPath(i18nl.APPLICATION_NOT_COMPLETE, TRG.MESSAGE))}/>
            </Dialog>
            <NavButtonsLocal lastStep customOnPrevious={() => onPrevious()}
                             finalSubmitFunction={() => createApplication()}
                             customOnCancel={() => onCancel(dispatch)}
                             draftFunction={() => openDraftModal()}
                             hideDraft={isOperationModeProtected() || accountIdentity}/></div>

    )
};
export default SummaryTaskWrapperIM