import { genericFormFieldNames } from "@mb-react/krg-getid-form-data-common"
import {getFormValues, getFromAppStore, removeFromDictionaryStore} from "@mb-react/mb-react-core"
import { formFieldNames } from "@mb-react/svarog-ui"
import { useMemo } from "react"
import { useForm } from "react-final-form"
import { useDispatch, useSelector } from "react-redux"
import { formFieldsMeta, forms } from "../../../../../../constants/form"
import { dictionaryReference, projectDictionaries } from "../../../../../../constants/general"
import { useHandleCommonServices } from "../../../../../../services/commonServiceHandler"
import { isMandatory, isReadOnly } from "../../../../../../util/businesslogicUtil"
import {ACCOUNT_IDENTITY} from "../../../../../../constants/application";

export const useGetRegistrationDetailsFieldsSettings = (props) => {
    const dispatch = useDispatch()
    const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION))
    let addressDataFormValues = useSelector((state) => getFormValues(state, props.formName))
    let formSuffix = props.formNameSufix ? props.formNameSufix : ""
    const accountIdentity = useSelector(state => getFromAppStore(state, ACCOUNT_IDENTITY));

    let personalInformationFormValues = useSelector((state) => getFormValues(state, forms.PERSONAL_INFORMATION + formSuffix))

    addressDataFormValues = {...addressDataFormValues, ...personalInformationFormValues, ...formValues}

    const handleCommonServices = useHandleCommonServices()

    let form = useForm();

    const onProvinceOfRegistrationChange = (event) => {
        form.change(genericFormFieldNames.CITY_OF_REGISTRATION, "")
        form.change(genericFormFieldNames.VILLAGE_OF_REGISTRATION, "")
        dispatch(removeFromDictionaryStore(dictionaryReference.VILLAGES_OF_REGISTRATION))
        handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, event.target.value, dictionaryReference.CITIES_OF_REGISTRATION)
    }

    const onCityOfRegistrationChange = (event) => {
        form.change(genericFormFieldNames.VILLAGE_OF_REGISTRATION, "")
        handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, event.target.value, dictionaryReference.VILLAGES_OF_REGISTRATION)
    }

    const handleProvinceOfRegistrationSettings = useMemo(() => {
        let registrationDataProps = {
            [genericFormFieldNames.PROVINCE_OF_REGISTRATION]: {
                [formFieldNames.FIELD_PROPS]: {
                    reference: projectDictionaries.PROVINCES,
                    readOnly: isReadOnly(addressDataFormValues, genericFormFieldNames.PROVINCE_OF_REGISTRATION, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                    [formFieldNames.ON_CHANGE]: onProvinceOfRegistrationChange
                },
                required: isMandatory(addressDataFormValues, genericFormFieldNames.PROVINCE_OF_REGISTRATION, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return registrationDataProps
    }, [])

    const handleCityOfRegistrationSettings = useMemo(() => {
        let registrationDataProps = {
            [genericFormFieldNames.CITY_OF_REGISTRATION]: {
                [formFieldNames.FIELD_PROPS]: {
                    reference: dictionaryReference.CITIES_OF_REGISTRATION,
                    readOnly: isReadOnly(addressDataFormValues, genericFormFieldNames.CITY_OF_REGISTRATION, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                    [formFieldNames.ON_CHANGE]: onCityOfRegistrationChange
                },
                required: isMandatory(addressDataFormValues, genericFormFieldNames.CITY_OF_REGISTRATION, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return registrationDataProps
    }, [addressDataFormValues[genericFormFieldNames.PROVINCE_OF_REGISTRATION]])

    const handleVillageOfRegistrationSettings = useMemo(() => {
        let registrationDataProps = {
            [genericFormFieldNames.VILLAGE_OF_REGISTRATION]: {
                [formFieldNames.FIELD_PROPS]: {
                    reference: dictionaryReference.VILLAGES_OF_REGISTRATION,
                    readOnly: isReadOnly(addressDataFormValues, genericFormFieldNames.VILLAGE_OF_REGISTRATION, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW])
                },
                required: isMandatory(addressDataFormValues, genericFormFieldNames.VILLAGE_OF_REGISTRATION, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return registrationDataProps
    }, [addressDataFormValues[genericFormFieldNames.PROVINCE_OF_REGISTRATION], addressDataFormValues[genericFormFieldNames.CITY_OF_REGISTRATION]])

    const handleRegBookNumberSettings = useMemo(() => {
        let registrationDataProps = {
            [genericFormFieldNames.REGISTRATION_BOOK_NUMBER]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(addressDataFormValues, genericFormFieldNames.REGISTRATION_BOOK_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW])
                },
                required: isMandatory(addressDataFormValues, genericFormFieldNames.REGISTRATION_BOOK_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return registrationDataProps
    }, [])

    const handleRegPageNumberSettings = useMemo(() => {
        let registrationDataProps = {
            [genericFormFieldNames.REGISTRATION_PAGE_NUMBER]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(addressDataFormValues, genericFormFieldNames.REGISTRATION_PAGE_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW])
                },
                required: isMandatory(addressDataFormValues, genericFormFieldNames.REGISTRATION_PAGE_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return registrationDataProps
    }, [])


    return { ...handleProvinceOfRegistrationSettings, ...handleCityOfRegistrationSettings, ...handleVillageOfRegistrationSettings,
    ...handleRegBookNumberSettings, ...handleRegPageNumberSettings}
}