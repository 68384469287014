import {
    getFormValues, getFromAppStore
} from "@mb-react/mb-react-core";
import { formFieldNames } from "@mb-react/svarog-ui";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-final-form";
import { useSelector } from "react-redux";
import { formFieldsMeta, forms } from "../../../../../../constants/form";
import { isMandatory, isReadOnly } from "../../../../../../util/businesslogicUtil";
import {ACCOUNT_IDENTITY} from "../../../../../../constants/application";

export const useOwnershipDataSettings = (props) => {
    const form = useForm();
    const useCase = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.USE_CASE])
    const documentType = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.DOCUMENT_TYPE])
    const workflow = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldsMeta.WORKFLOW])
    let totalPercentageSellers = useSelector((state) => getFromAppStore(state, 'totalPercentageSellers'));
    let totalPercentageBuyers = useSelector((state) => getFromAppStore(state, 'totalPercentageBuyers'));
    const accountIdentity = useSelector(state => getFromAppStore(state, ACCOUNT_IDENTITY));

    const [leadProps, setLeadProps] = useState({});

    const disableLeadCheck = () => {
        if(props === "Target"){
            if(totalPercentageBuyers){
                return totalPercentageBuyers.some(seller => seller.lead === true);
            }
            return false
        }

        if (totalPercentageSellers) {
            return totalPercentageSellers.some(seller => seller.lead === true);
        }
        return false;
    }

    useEffect(() => {
        const leadProps = {
            [formFieldNames.OWNERSHIP_LEAD]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: disableLeadCheck(),
                },
                required: isMandatory(formValues, formFieldNames.OWNERSHIP_LEAD, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        };
        setLeadProps(leadProps);
    }, [totalPercentageSellers]);

    useSelector((state) => getFormValues(state, forms.PERSONAL_INFORMATION))

    let formValues = form?.getState()?.values ?? {}

    const handleOwnerRoleSettings = useMemo(() => {
        let ownerProps = {
            [formFieldNames.OWNER_ROLE]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(formValues, formFieldNames.OWNER_ROLE, useCase, documentType, workflow),
                },
                exclude: true,
                required: false, //isMandatory(formValues, formFieldNames.OWNER_ROLE, useCase, documentType, workflow),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return ownerProps
    }, [])

    const handleOwnershipPercentageSettings = useMemo(() => {
        let ownerProps = {
            [formFieldNames.OWNERSHIP_PERCENTAGE]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(formValues, formFieldNames.OWNERSHIP_PERCENTAGE, useCase, documentType, workflow),
                    digitsOnly: true,
                    maxLength: 3
                },
                required: isMandatory(formValues, formFieldNames.OWNERSHIP_PERCENTAGE, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return ownerProps
    }, [])

    const handleOwnershipLeadSettings = useMemo(() => {
        let ownerProps = {
            [formFieldNames.OWNERSHIP_LEAD]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: disableLeadCheck() || isReadOnly(formValues, formFieldNames.OWNERSHIP_LEAD, useCase, documentType, workflow),
                },
                required: isMandatory(formValues, formFieldNames.OWNERSHIP_LEAD, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return ownerProps
    }, [totalPercentageSellers])

    const handleSharedOwnershipSettings = useMemo(() => {
        let ownerProps = {
            [formFieldNames.SHARED_OWNERSHIP]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(formValues, formFieldNames.SHARED_OWNERSHIP, useCase, documentType, workflow),
                },
                exclude: true,
                required: false, //isMandatory(formValues, formFieldNames.SHARED_OWNERSHIP, useCase, documentType, workflow),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return ownerProps
    }, [])

    return { ...handleOwnerRoleSettings, ...handleOwnershipPercentageSettings, ...handleOwnershipLeadSettings, ...handleSharedOwnershipSettings }
};