/**
 * Individually imports icons just once inside this module -
 * there’s no need to import the icons into each component once they’ve been added to the library.
 *
 * @author Minja Raicevic on 05/20/2021.
 */
import {library} from '@fortawesome/fontawesome-svg-core';
import {faAsterisk} from '@fortawesome/free-solid-svg-icons/faAsterisk';
import {faBan} from '@fortawesome/free-solid-svg-icons/faBan';
import {faBars} from '@fortawesome/free-solid-svg-icons/faBars';
import {faCaretDown} from '@fortawesome/free-solid-svg-icons/faCaretDown';
import {faChartArea} from '@fortawesome/free-solid-svg-icons/faChartArea';
import {faFolder} from '@fortawesome/free-solid-svg-icons/faFolder';
import {faList} from '@fortawesome/free-solid-svg-icons/faList';
import {faTable} from '@fortawesome/free-solid-svg-icons/faTable';
import {faTachometerAlt} from '@fortawesome/free-solid-svg-icons/faTachometerAlt';
import {faTrash} from '@fortawesome/free-solid-svg-icons/faTrash';
import {faSearch} from '@fortawesome/free-solid-svg-icons/faSearch';

import {faUser} from '@fortawesome/free-solid-svg-icons/faUser';
import {faClock} from '@fortawesome/free-solid-svg-icons/faClock';
import {faSortDown} from '@fortawesome/free-solid-svg-icons/faSortDown';
import {faSignInAlt} from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import {faCopyright} from '@fortawesome/free-solid-svg-icons/faCopyright';
import {faChevronCircleUp} from '@fortawesome/free-solid-svg-icons/faChevronCircleUp';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons/faAngleRight';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import {faEdit} from '@fortawesome/free-solid-svg-icons/faEdit';
import {faQuestion} from "@fortawesome/free-solid-svg-icons/faQuestion";

library.add(faAsterisk,
    faBan,
    faBars,
    faCaretDown,
    faChartArea,
    faFolder,
    faList,
    faTable,
    faTachometerAlt,
    faTrash,
    faSearch,
    faEdit,
    faUser,
    faClock,
    faSortDown,
    faSignInAlt,
    faCopyright,
    faChevronCircleUp,
    faAngleRight,
    faAngleLeft,
    faQuestion);
