import {
  MBForm,
} from "@mb-react/mb-react-core";
import React from "react";
import {forms} from "../../../../../../constants/form";
import ApplicationData from "./ApplicationData";
import {applicationDataValidation} from "./ApplicationDataValidation";

const ApplicationDataForm = () => {
  const validation = applicationDataValidation();
  return (
    <MBForm formName={forms.APPLICATION_DATA} showErrorPanel submitFunction={() => {}} validate={validation.validate}>
      <ApplicationData/>
    </MBForm>
  );
}

export default ApplicationDataForm;