import {formFieldNames, isRequired} from "@mb-react/svarog-ui";
import {I18n} from "react-redux-i18n"
import {EMAIL_REGEX, PASSWORD_REGEX} from "../../../constants/form"
import {LEGAL_ENTITY} from "../../../constants/application";
import {isOperationModeNotProtected} from "../../../util/businesslogicUtil";
import {getPath, i18nl} from "../../../constants/i18n";

export const useRegisterAccountValidation = (values) => {
    const isNotProtectedMode = isOperationModeNotProtected()
    const usernameRegex = /^[a-zA-Z0-9]{3,16}$/
    let errors = {};

    //requested by Wady Al-Ain ticket #215977
    //isRequired(values, formFieldNames.PERSONAL_NUMBER, errors)

    if(isNotProtectedMode){
        const checkRepeatPassword = (data) => values && values[formFieldNames.PASSWORD] && data == values[formFieldNames.PASSWORD];
        isRequired(values, formFieldNames.PASSWORD, errors)
        if (values[formFieldNames.PASSWORD] && !PASSWORD_REGEX.test(values[formFieldNames.PASSWORD])) {
            errors[formFieldNames.PASSWORD] = I18n.t(getPath(i18nl.WRONG_PASSWORD_FORMAT));
        }
        isRequired(values, formFieldNames.PASSWORD_REPEATED, errors)
        if(values[formFieldNames.PASSWORD_REPEATED] && !checkRepeatPassword(values[formFieldNames.PASSWORD_REPEATED])){
            errors[formFieldNames.PASSWORD_REPEATED] = I18n.t('label.repeatedPasswordInvalid')
        }
        isRequired(values, formFieldNames.EMAIL, errors)
        isRequired(values, formFieldNames.CAPTCHA, errors)
    }

    isRequired(values, formFieldNames.USERNAME, errors)
    if(values[formFieldNames.USERNAME] && !usernameRegex.test(values[formFieldNames.USERNAME])){
        errors[formFieldNames.USERNAME] = I18n.t('label.usernameLengthInvalid')
    }

    if(values[formFieldNames.EMAIL] && !values[formFieldNames.EMAIL].match(EMAIL_REGEX)){
        errors[formFieldNames.EMAIL] = I18n.t('label.wrongEmailFormat')
    }

    if(!configurationData.dlGoLive){
        isRequired(values, formFieldNames.ACCOUNT_TYPE, errors)

        if(values[formFieldNames.ACCOUNT_TYPE] === LEGAL_ENTITY){
            isRequired(values, formFieldNames.ACCOUNT_TYPE, errors)
            isRequired(values, formFieldNames.ACCOUNT_TYPE, errors)
        }
    }

    return errors;
};