import {useSelector} from "react-redux";
import {getFormValues} from "@mb-react/mb-react-core";
import {forms} from "../../../../constants/form";
import React from "react";
import {I18n, Translate} from "react-redux-i18n";
import {ProxyData} from "@mb-react/krg-getid-form-data-common";
import {useProxyDataSettings} from "./proxyDataSettings/proxyDataSettings";

const ProxyDataTask = (props) => {
    const useCaseFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)["useCase"]);
    const docTypeFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)["documentType"]);

    return (
        <div className="card">
            {
                useCaseFromStore && docTypeFromStore && <div className={"useCaseHeader"}><h5>{I18n.t("label.useCaseHeader", {documentType: I18n.t("label." + docTypeFromStore), useCase: I18n.t("label." + useCaseFromStore)})}</h5></div>
            }
            <>
                <h5><Translate value="label.proxyData" /></h5>
                <hr className="sectionSeparator" />
                <div className="grid">
                    <ProxyData {...useProxyDataSettings(props)} />
                </div>
            </>
        </div>
    )
};
export default ProxyDataTask;