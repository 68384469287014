import {
  getFormValues, getFromAppStore,
} from "@mb-react/mb-react-core"
import { formFieldNames,  } from "@mb-react/svarog-ui"
import { useSelector} from "react-redux"
import { formFieldsMeta, forms } from "../../../../../../constants/form"
import {
  isMandatory,
  isReadOnly, isUseCaseChangeOwnership, usePlateDataFromSystemForUCs
} from "../../../../../../util/businesslogicUtil"
import {useMemo} from "react";
import {applicationStore} from "../../../../../../constants/store";
import {changeOwnershipSubUseCases} from "../../../../../../constants/application";
import {useForm} from "react-final-form";

export const usePlateNumberDataSettings = () => {
  const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION))
  const plateDataFormValues = useSelector((state) => getFormValues(state, forms.PLATE_DATA))
  const plateDataHelper = useSelector((state) => getFromAppStore(state, applicationStore.PLATE_DATA_HELPER));

  const form = useForm();

  let excludePlateNumber = false;

  if(isUseCaseChangeOwnership(formValues) && formValues?.["changeOwnershipSubUseCase"] === changeOwnershipSubUseCases.SALE_AND_BUY &&
      !plateDataHelper && plateDataFormValues["sourcePlate"] === "FURTHER_USE"){
    excludePlateNumber = true;
  }

  if(configurationData.enablePlateHandling){
    if(usePlateDataFromSystemForUCs(formValues[formFieldNames.USE_CASE])){
      excludePlateNumber = true;
    }
  }

  const synchronizePlateNumberAndPlateNumberLatin = (e) => {
    const plateNumberLatin = e?.target?.value;
    form.change(formFieldNames.PLATE_NUMBER, plateNumberLatin);
  };


  const plateNumberSettings = useMemo(() => {

    let format = true;

    if(plateDataFormValues[formFieldNames.PLATE_NUMBER] && !plateDataFormValues[formFieldNames.PLATE_NUMBER].includes(" ")){
      format = false;
    }

    let plateDataProps = {
      [formFieldNames.PLATE_NUMBER]: {
        value: plateDataFormValues[formFieldNames.PLATE_NUMBER],
        numberFormat: format,
        [formFieldNames.FIELD_PROPS]: {
          readOnly: true //isReadOnly(plateDataFormValues, formFieldNames.PLATE_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        },
        exclude: excludePlateNumber,
        required: isMandatory(plateDataFormValues, formFieldNames.PLATE_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12"
      }
    }

    return plateDataProps
  }, [plateDataFormValues[formFieldNames.PLATE_NUMBER], plateDataFormValues[formFieldNames.PLATE_PROVINCE], plateDataFormValues["sourcePlate"]])

  const plateNumberLatinSettings = useMemo(() => {
    let plateDataProps = {
      [formFieldNames.PLATE_NUMBER_LATIN]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: plateDataHelper,
          keyfilter: /^[A-Z0-9]*$/,
          maxLength: 6,
          [formFieldNames.ON_CHANGE]: synchronizePlateNumberAndPlateNumberLatin
        },
        exclude: !excludePlateNumber,
        required: excludePlateNumber,
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return plateDataProps
  }, [plateDataFormValues["sourcePlate"], excludePlateNumber, plateDataHelper])

  const serialNumberSettings = useMemo(() => {

    //TODO excluded based on PlateHandling.jpg diagram provided by dr P. Jovanovic :)
    let excludeSerialNumber = true;
    if(plateDataFormValues && plateDataFormValues[formFieldNames.PLATE_TYPE]){
      switch (plateDataFormValues[formFieldNames.PLATE_TYPE]) {
        case "Standard":
          excludeSerialNumber = true;
          break;
        case "Special":
          excludeSerialNumber =  false;
          break;
        case "MotorCycle":
          excludeSerialNumber =  true;
          break;
        case "Other":
          excludeSerialNumber =  true;
          break;
      }
    }

    let plateDataProps = {
      [formFieldNames.PLATE_SERIAL_NUMBER]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(plateDataFormValues, formFieldNames.PLATE_SERIAL_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        },
        exclude: true,
        required: isMandatory(plateDataFormValues, formFieldNames.PLATE_SERIAL_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return plateDataProps
  }, [])

  const serialNumberLatinSettings = useMemo(() => {
    let plateDataProps = {
      [formFieldNames.PLATE_SERIAL_NUMBER_LATIN]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(plateDataFormValues, formFieldNames.PLATE_SERIAL_NUMBER_LATIN, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        },
        exclude: true,
        required: isMandatory(plateDataFormValues, formFieldNames.PLATE_SERIAL_NUMBER_LATIN, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return plateDataProps
  }, [])

  return {...plateNumberSettings, ...plateNumberLatinSettings, ...serialNumberSettings, ...serialNumberLatinSettings}
}