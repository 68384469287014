import {addInitialValueToForm, getFromAppStore} from '@mb-react/mb-react-core';
import { change } from '@mb-react/mb-react-core';
import { EDocumentType, EUseCases, formFieldNames, getCookie } from '@mb-react/svarog-ui';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import {applicationStore, appStoreFields} from '../../constants/store';
import ContainerHOC from '../ContainerHOC';
import VehicleApplicationWorkflow from '../tasks/workflowTasks/vehicle/VehicleApplicationWorkflow';
import { forms } from "../../constants/form";
import moment from 'moment';
import {genericFormFieldNames} from "@mb-react/krg-getid-form-data-common";
import {SELECTED_ACCOUNT_EMAIL, SELECTED_ACCOUNT_ID} from "../../constants/general";
import {delete_cookie} from "../../util/util";
import {changeOwnershipSubUseCases} from "../../constants/application";

const NewVRCreateWrapper = (props) => {
    useEffect(() => {
        const { dispatch, editDraft } = props;
        if (!editDraft) {
            dispatch(change(forms.APPLICATION, formFieldNames.DOCUMENT_TYPE, EDocumentType.VR));

            //TODO set proper application type based UC
            dispatch(change(forms.APPLICATION, "applicationType", "VR"));

            if (getCookie([formFieldNames.USE_CASE])) {
                dispatch(change(forms.APPLICATION, formFieldNames.USE_CASE, getCookie([formFieldNames.USE_CASE])));
            } else{
                dispatch(change(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.NEW));
            }

            if(getCookie(SELECTED_ACCOUNT_EMAIL)){
                dispatch(change(forms.APPLICATION, applicationStore.REGISTERED_ACCOUNT_EMAIL, atob(getCookie(SELECTED_ACCOUNT_EMAIL))));
                delete_cookie(SELECTED_ACCOUNT_EMAIL)
            }
            if(getCookie(SELECTED_ACCOUNT_ID)){
                dispatch(change(forms.APPLICATION, formFieldNames.ACCOUNT_ID, getCookie(SELECTED_ACCOUNT_ID)));
                delete_cookie(SELECTED_ACCOUNT_ID)
            }
            dispatch(change(forms.APPLICATION, formFieldNames.ENROLLMENT_STARTED, moment(Date.now()).format("yyyy-MM-DDTHH:mm:ss.SSSSSSS")));

            if (getCookie([genericFormFieldNames.CHANGE_OWNERSHIP_SUB_USE_CASE])) {
                dispatch(change(forms.APPLICATION, genericFormFieldNames.CHANGE_OWNERSHIP_SUB_USE_CASE, getCookie([genericFormFieldNames.CHANGE_OWNERSHIP_SUB_USE_CASE])))

                if(configurationData.filterPlateReservation &&
                    [changeOwnershipSubUseCases.SALE_AND_BUY, changeOwnershipSubUseCases.BY_COURT, changeOwnershipSubUseCases.BY_AUCTION].includes(getCookie([genericFormFieldNames.CHANGE_OWNERSHIP_SUB_USE_CASE]))){
                    dispatch(addInitialValueToForm(forms.PLATE_DATA, "sourcePlate", "FURTHER_USE"))
                }
            }
        }
    }, [])

    return (
        <ContainerHOC fullPage>
            <VehicleApplicationWorkflow />
        </ContainerHOC>
    )
}
const mapStateToProps = state => {
    const editDraft = getFromAppStore(state, appStoreFields.editDraft);
    return {
        editDraft: editDraft
    };
};
export default connect(mapStateToProps)(NewVRCreateWrapper);
NewVRCreateWrapper.propTypes = {
    dispatch: PropTypes.any
};
