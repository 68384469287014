import { genericFormFieldNames } from "@mb-react/krg-getid-form-data-common"
import {
  getFormValues, getFromAppStore,
  removeFromDictionaryStore
} from "@mb-react/mb-react-core"
import { formFieldNames } from "@mb-react/svarog-ui"
import { useDispatch, useSelector } from "react-redux"
import { formFieldsMeta, forms } from "../../../../../../constants/form"
import { dictionaryReference, projectDictionaries } from "../../../../../../constants/general"
import { useHandleCommonServices } from "../../../../../../services/commonServiceHandler"
import { isMandatory, isReadOnly } from "../../../../../../util/businesslogicUtil"
import {useMemo} from "react";
import {useForm} from "react-final-form";
import {ACCOUNT_IDENTITY} from "../../../../../../constants/application";

export const useAddressDataFieldsSettings = (props) => {
  const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION))
  let addressDataFormValues = useSelector((state) => getFormValues(state, props.formName))
  const accountIdentity = useSelector(state => getFromAppStore(state, ACCOUNT_IDENTITY));

  let formSuffix = props.formNameSufix ? props.formNameSufix : ""

  let personalInformationFormValues = useSelector((state) => getFormValues(state, forms.PERSONAL_INFORMATION + formSuffix))

  addressDataFormValues = {...addressDataFormValues, ...personalInformationFormValues, ...formValues}
  const form = useForm();

  const dispatch = useDispatch();
  const handleCommonServices = useHandleCommonServices()

  const onProvinceChange = (event) => {
    form.change(formFieldNames.ADDRESS_CITY, "");
    form.change(genericFormFieldNames.ADDRESS_VILLAGE, "");
    form.change(genericFormFieldNames.ADDRESS_INFORMATION_CENTER, "");
    dispatch(removeFromDictionaryStore(dictionaryReference.VILLAGES_ADDRESS))
    handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, event.target.value, dictionaryReference.CITIES_ADDRESS)
    handleCommonServices.handleGetSingleDictionary(projectDictionaries.INFO, event.target.value)

  }
  const onCityChange = (event) => {
    form.change(genericFormFieldNames.ADDRESS_VILLAGE, "")
    handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, event.target.value, dictionaryReference.VILLAGES_ADDRESS)
  }
  const onBuildingClassChange = () => {
    form.change(formFieldNames.ADDRESS_HOUSE_NUMBER, "");
    form.change(formFieldNames.ADDRESS_STREET_NUMBER, "");
    form.change(genericFormFieldNames.SECTOR_NUMBER, "");
    form.change(genericFormFieldNames.QUARTER_NAME, "");
    form.change(genericFormFieldNames.ELDER_NAME, "");
    form.change(genericFormFieldNames.VILLAGE_REGION, "");
    form.change(genericFormFieldNames.RESIDENCE_CARD_NUMBER, "");
    form.change(genericFormFieldNames.RESIDENCE_CARD_DATE_OF_ISSUE, "");
    form.change(genericFormFieldNames.RESIDENCE_CERTIFICATE_NUMBER, "");
    form.change(genericFormFieldNames.RESIDENCE_CERTIFICATE_DATE_OF_ISSUE, "");
  }

  const handleAddressProvinceSettings = useMemo(() => {
    let addressDataProps = {
      [formFieldNames.ADDRESS_PROVINCE]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(addressDataFormValues, formFieldNames.ADDRESS_PROVINCE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: projectDictionaries.PROVINCES,
          [formFieldNames.ON_CHANGE]: onProvinceChange
        },
        required: isMandatory(addressDataFormValues, formFieldNames.ADDRESS_PROVINCE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return addressDataProps
  }, [])

  const handleAddressCitySettings = useMemo(() => {
    let addressDataProps = {
      [formFieldNames.ADDRESS_CITY]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(addressDataFormValues, formFieldNames.ADDRESS_CITY, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: dictionaryReference.CITIES_ADDRESS,
          [formFieldNames.ON_CHANGE]: onCityChange
        },
        required: isMandatory(addressDataFormValues, formFieldNames.ADDRESS_CITY, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return addressDataProps
  }, [addressDataFormValues[formFieldNames.ADDRESS_PROVINCE]])

  const handleAddressVillageSettings = useMemo(() => {
    let addressDataProps = {
      [genericFormFieldNames.ADDRESS_VILLAGE]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(addressDataFormValues, genericFormFieldNames.ADDRESS_VILLAGE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: dictionaryReference.VILLAGES_ADDRESS,
        },
        required: isMandatory(addressDataFormValues, genericFormFieldNames.ADDRESS_VILLAGE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return addressDataProps
  }, [addressDataFormValues[formFieldNames.ADDRESS_PROVINCE], formValues[formFieldNames.ADDRESS_CITY]])

  const handleAddressInformationCenterSettings = useMemo(() => {
    let addressDataProps = {
      [genericFormFieldNames.ADDRESS_INFORMATION_CENTER]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(addressDataFormValues, genericFormFieldNames.ADDRESS_INFORMATION_CENTER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: projectDictionaries.INFO,
        },
        required: isMandatory(addressDataFormValues, genericFormFieldNames.ADDRESS_INFORMATION_CENTER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return addressDataProps
  }, [addressDataFormValues[formFieldNames.ADDRESS_PROVINCE]])


  const handleAddressBuildingClassSettings = useMemo(() => {
    let addressDataProps = {
      [genericFormFieldNames.ADDRESS_BUILDING_CLASS]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(addressDataFormValues, genericFormFieldNames.ADDRESS_BUILDING_CLASS, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: projectDictionaries.BUILDING_CLASS,
          [formFieldNames.ON_CHANGE]: onBuildingClassChange
        },
        required: isMandatory(addressDataFormValues, genericFormFieldNames.ADDRESS_BUILDING_CLASS, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return addressDataProps
  }, [])


  const handleQuarterNameSettings = useMemo(() => {
    let addressDataProps = {
      [genericFormFieldNames.QUARTER_NAME]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(addressDataFormValues, genericFormFieldNames.QUARTER_NAME, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        },
        required: isMandatory(addressDataFormValues, genericFormFieldNames.QUARTER_NAME, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return addressDataProps
  }, [addressDataFormValues[genericFormFieldNames.ADDRESS_BUILDING_CLASS]])

  const handleSectorNumberSettings = useMemo(() => {
    let addressDataProps = {
      [genericFormFieldNames.SECTOR_NUMBER]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(addressDataFormValues, genericFormFieldNames.SECTOR_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        },
        required: isMandatory(addressDataFormValues, genericFormFieldNames.SECTOR_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return addressDataProps
  }, [addressDataFormValues[genericFormFieldNames.ADDRESS_BUILDING_CLASS]])

  const handleVillageRegionSettings = useMemo(() => {
    let addressDataProps = {
      [genericFormFieldNames.VILLAGE_REGION]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(addressDataFormValues, genericFormFieldNames.VILLAGE_REGION, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        },
        required: isMandatory(addressDataFormValues, genericFormFieldNames.VILLAGE_REGION, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return addressDataProps
  }, [addressDataFormValues[genericFormFieldNames.ADDRESS_BUILDING_CLASS]])

  const handleElderNameSettings = useMemo(() => {
    let addressDataProps = {
      [genericFormFieldNames.ELDER_NAME]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(addressDataFormValues, genericFormFieldNames.ELDER_NAME, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        },
        required: false, //isMandatory(addressDataFormValues, genericFormFieldNames.ELDER_NAME, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return addressDataProps
  }, [addressDataFormValues[genericFormFieldNames.ADDRESS_BUILDING_CLASS]])

  const handleAddressStreetNumberSettings = useMemo(() => {
    let addressDataProps = {
      [formFieldNames.ADDRESS_STREET_NUMBER]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(addressDataFormValues, formFieldNames.ADDRESS_STREET_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        },
        required: isMandatory(addressDataFormValues, formFieldNames.ADDRESS_STREET_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return addressDataProps
  }, [addressDataFormValues[genericFormFieldNames.ADDRESS_BUILDING_CLASS]])

  const handleAddressHouseNumberSettings = useMemo(() => {
    let addressDataProps = {
      [formFieldNames.ADDRESS_HOUSE_NUMBER]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(addressDataFormValues, formFieldNames.ADDRESS_HOUSE_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        },
        required: isMandatory(addressDataFormValues, formFieldNames.ADDRESS_HOUSE_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return addressDataProps
  }, [addressDataFormValues[genericFormFieldNames.ADDRESS_BUILDING_CLASS]])

  const handlePhoneSettings = useMemo(() => {
    let addressDataProps = {
      [formFieldNames.PHONE]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(addressDataFormValues, formFieldNames.PHONE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        },
        required: isMandatory(addressDataFormValues, formFieldNames.PHONE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return addressDataProps
  }, [])

  const handleEmailSettings = useMemo(() => {
    let addressDataProps = {
      [formFieldNames.EMAIL]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(addressDataFormValues, formFieldNames.EMAIL, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        },
        required: isMandatory(addressDataFormValues, formFieldNames.EMAIL, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return addressDataProps
  }, [])

  return {...handleAddressProvinceSettings, ...handleAddressCitySettings, ...handleAddressVillageSettings, ...handleAddressInformationCenterSettings,
  ...handleAddressBuildingClassSettings, ...handleQuarterNameSettings, ...handleSectorNumberSettings, ...handleVillageRegionSettings, ...handleElderNameSettings,
  ...handleAddressStreetNumberSettings, ...handleAddressHouseNumberSettings, ...handlePhoneSettings, ...handleEmailSettings}
}