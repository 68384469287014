import { addressDataFormFieldList, personalInformationFormFieldList } from "@mb-react/krg-getid-form-data-common";
import {
    NavigationWizardWrapper, addMultipleInitialValuesToForm, addToAppStore, getFromAppStore
} from "@mb-react/mb-react-core";
import { PagedRequest, extractPersonDataToFormValues, getPath } from '@mb-react/svarog-ui';
import React, { useEffect } from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {I18n, Translate} from "react-redux-i18n";
import { forms } from '../../../constants/form';
import { i18nl } from '../../../constants/i18n';
import {APPLICATION_STEPS, applicationStore} from '../../../constants/store';
import { useHandleCommonServices } from "../../../services/commonServiceHandler";
import { isMobile } from '../../../util/environment';
import SummaryTaskWrapperIM from "../../tasks/workflowTasks/common/summary/SummaryTaskWrapperIM";
import { useHandleWorkflowSettings } from './handleWorkflowSettings';
import {usePersonalInformationValidation} from "../../tasks/workflowTasks/document/personalData/personalInformationValidation";
import PreSummaryForm from "../../tasks/workflowTasks/document/personalData/PreSummaryForm";
import { extractObjectBasedOnKeysList } from '../../../util/dataExtractorUtil';
import {disableDraft} from "../../../util/businesslogicUtil";

const CancelIdentityManagementWorkflow = (props) => {
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))
    const handleWorkflowSettings = useHandleWorkflowSettings();
    const handleCommonServices = useHandleCommonServices();
    const dispatch = useDispatch();

    const getMyIdentityOnSuccess = (response) => {
        let newObj = extractPersonDataToFormValues(response.data, {});
        let personalInformation = extractObjectBasedOnKeysList(newObj, personalInformationFormFieldList);
        let addressData = extractObjectBasedOnKeysList(newObj, addressDataFormFieldList);

        dispatch(addMultipleInitialValuesToForm(forms.PERSONAL_INFORMATION, personalInformation))
        dispatch(addMultipleInitialValuesToForm(forms.ADDRESS_DATA + props.formNameSufix, addressData))
        dispatch(addToAppStore('fetchedApplication', {...addressData, ...personalInformation}))

    }
      

    useEffect(() => {
        handleWorkflowSettings.initWorkflowSettings()

        let pagedRequest = new PagedRequest();
        pagedRequest.requestObject = {}
        pagedRequest.page = 1
        pagedRequest.sizePerPage = 10

        handleCommonServices.handleCallGetMyIdentity(pagedRequest, getMyIdentityOnSuccess)
        
    }, []);

    const items = [
        {
            id: APPLICATION_STEPS.PERSONAL_DATA,
            label: <Translate value={"label." + i18nl.PERSONAL_INFORMATION} />,
            icon: "pi pi-user",
            component: <PreSummaryForm formName={forms.PERSONAL_INFORMATION} useCase={"cancelIdentity"}/>,
            validateFn: usePersonalInformationValidation
        },
        {
            id: APPLICATION_STEPS.CONFIRMATION_TASK,
            label: <Translate value={"label." + i18nl.SUMMARY} />,
            icon: 'pi pi-file',
            component: <SummaryTaskWrapperIM formNameSufix={'Source'}/>
        },
    ];

    return (
        <div className="card navigation">
            {props.activeLocale && <NavigationWizardWrapper items={items} mobile={isMobile()} progressBar={!isMobile()} readOnly={disableDraft(userRights)}
                    formName={forms.APPLICATION} />}
        </div>

    );
}

CancelIdentityManagementWorkflow.propTypes = {};

function mapStateToProps(state) {
    return {
        activeLocale: state.i18n.locale
    }
}

export default connect(mapStateToProps)(CancelIdentityManagementWorkflow)

