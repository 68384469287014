import { formFieldNames,  } from "@mb-react/svarog-ui"
import {useMemo} from "react";

export const useModifyProvinceSiteData = () => {
  const modifyProvinceSiteSettings = useMemo(() => {
    let modifyProvinceSiteProps = {
      ["modifyProvinceSite"]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: false,
          showClear: true,
        },
        required: false,
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return modifyProvinceSiteProps
  }, [])

  return {...modifyProvinceSiteSettings}
}