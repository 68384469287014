import React, {Fragment, useEffect} from 'react';
import {I18n} from "react-redux-i18n";
import {Accordion, AccordionTab, addInitialValueToForm, change, getFormValues} from "@mb-react/mb-react-core";
import {
    ContentManagementService,
    ServiceRequestData,
    useCreateServiceWrapper
} from "@mb-react/svarog-ui";
import {useDispatch, useSelector} from "react-redux";


const FAQ = () => {
    const dispatch = useDispatch();

    const serviceWrapper = useCreateServiceWrapper();
    const listOfSd = useSelector((state) => getFormValues(state, "lstOfDocsForm",)["listOfDocumentsForDownload"]) || {}

    console.log(listOfSd)

    useEffect(() => {
        fetchDocuments();
    }, []);

    const fetchDocuments = () => {
        serviceWrapper(new ServiceRequestData(
            ContentManagementService.fetchDocumentsByType,
            "DOCUMENT",
            null,
            null,
            (result) => {
                if (result) {
                    onSuccessFetch(result)
                }
            },
            null
        ))
    };

    const onSuccessFetch = (result) => {
        let listOfFetchedDocuments = [];
        if (result && result.length > 0) {
            result.forEach(sd => {
                let newSD = {
                    id: sd.id,
                    name: sd.documentNumber,
                    mimeType: sd.mimeType,
                    documents: sd.documents,
                    documentNumber: sd.documentNumber,
                    issuingAuthority: sd.issuingAuthority,
                    documentType: sd.documentType,
                    description: sd.description,
                    mandatory: true
                }
                listOfFetchedDocuments.push(newSD);

            })

            console.log("dispatch list of documents")
            dispatch(addInitialValueToForm("lstOfDocsForm", "listOfDocumentsForDownload", listOfFetchedDocuments))
        } else {
            console.log("dispatch list null of documents")

            dispatch(addInitialValueToForm("lstOfDocsForm","listOfDocumentsForDownload", null))
        }
    }

    return (
        <Fragment>
            <div className="accordion-demo">
                <div className="card">
                    <Accordion activeIndex={0}>
                        <AccordionTab
                            header={
                                <React.Fragment>
                                    <i className="pi pi-file"></i>
                                    <span>{I18n.t('label.contentManagement.downloadList')}</span>
                                </React.Fragment>}
                        >
                            {listOfSd && !(Object.keys(listOfSd).length === 0) && Object.values(listOfSd).map((item, i) => (
                                <div className="col-12">
                                    <a download={item.name} href={'data:' + item.mimeType + ';base64,' + item.documents[0]}>{item.name}</a>
                                </div>
                            ))}
                        </AccordionTab>
                    </Accordion>
                </div>
            </div>
        </Fragment>
    );
}

export default FAQ;
