import {
    completeStep,
    getFromAppStore,
    hasValidationErrors,
    MBForm,
    NavButtons,
    nextStep,
    previousStep
} from "@mb-react/mb-react-core";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {APPLICATION_STEPS, applicationStore} from "../../../../../constants/store";
import {vehicleDataValidation} from "./vehicleDataValidation";
import VehicleDataTask from "./VehicleDataTask";
import {onCancel} from "../../../../../util/componentUtil";
import {useSkip} from "../../../../../util/businesslogicUtil";
import NavButtonsLocal from "../../../../reactCoreComponents/NavButtonsLocal";
import {isMobile} from "../../../../../util/environment";

const VehicleDataForm = (props) => {
    const errors = useSelector((state) => hasValidationErrors(state, props.formName));
    const dispatch = useDispatch();
    const vehicleDataFormValidation = vehicleDataValidation();
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))

    const onSkip = () => {
        dispatch(nextStep(props.navigationName))
    }

    const onPrevious = () => {
        dispatch(previousStep(props.navigationName))
    }

    return (
        <MBForm formName={props.formName} stepId={APPLICATION_STEPS.TECHNICAL_DESCRIPTION}  navigationId={props.navigationName}
                warningButtonLabel={isMobile() ? "  " : null}
                showErrorPanel
                submitFunction={() => {
                    dispatch(completeStep(props.navigationName, APPLICATION_STEPS.TECHNICAL_DESCRIPTION))
                    dispatch(nextStep(props.navigationName))
                }}
                validate={vehicleDataFormValidation.validate}>
            <VehicleDataTask formName={props.formName}/>
            <NavButtonsLocal customOnPrevious={() => onPrevious()} customOnSkip={() => onSkip()}
                        allowSkip={useSkip(userRights, errors)} hideNext={useSkip(userRights, errors)}
                        customOnCancel={() => onCancel(dispatch)}/>
        </MBForm>
    );
}

export default VehicleDataForm;