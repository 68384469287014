import {addToAppStore, openModal, showToast, toastIDs} from '@mb-react/mb-react-core';
import { useDispatch } from 'react-redux';
import {applicationStore, globalProps} from '../../constants/store';
import {modalIDs} from "../../constants/environment";
import {I18n} from "react-redux-i18n";

export function useHandleGetAllDictionariesSuccessResult() {
  const dispatch = useDispatch();
  const handleResponse = (response) => {
    dispatch(addToAppStore(globalProps.DICTIONARY, response.data));
  }
  return handleResponse;
}

export function handleOnCountApplicationsError() {
  const dispatch = useDispatch();
  return (response) => {
    dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {messageId: response.data}))
  }
}