import { locals, SelectButton } from "@mb-react/mb-react-core";
import { getCookie, setCookie } from "@mb-react/svarog-ui";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { I18n, setLocale } from "react-redux-i18n";
import { ThemeContext } from "../ThemeContext";
import AccessibilityButtonWrap from "./AccessabilitySettings";

const TopHeaderLine = (props) => {
    const lang = getCookie("lang") ? getCookie("lang") : locals.KR;
    const [localeValue, setLocaleValue] = useState(lang);
    const [displayLangMenu, setDisplayLangMenu] = useState(false);
    const dispatch = useDispatch();
    const justifyOptions = [
        { label: "English", value: locals.EN },
        { label: "کوردی", value: locals.KR },
        { label: "العربية", value: locals.AR }
    ];

    const onLocaleChange = (value) => {
        if (value && value !== localeValue) {
            setCookie("lang", value);
            dispatch(setLocale(value));
            setLocaleValue(value);
        }
    };

    const justifyTemplate = (option) => (
        <>
            <img src={option.image} alt={option.label} />
        </>
    );

    let homeUrl = configurationData && configurationData.operationMode === "PROTECTED" ? "/start-client-server/admin" : "/";

    if (configurationData && configurationData.operationMode === "PROTECTED") {
        document.title = "Start Client"
    };

    const { isDarkTheme, toggleTheme } = useContext(ThemeContext);

    const toggleDarkTheme = () => {
        toggleTheme(); // Toggle the dark theme
    };

    const isRtl = localeValue === locals.AR || localeValue === locals.KR;


    useEffect(() => {
        // Dynamically add or remove the 'rtl' class on the root HTML element
        document.documentElement.classList.toggle('rtl', isRtl);

        // Clean up function to remove the class when the component is unmounted
        return () => {
            document.documentElement.classList.remove('rtl');
        };
    }, [isRtl]);

    return (
        <div className="top-header-line pl-2 pr-2">
            <div className="grid">
                <div className="col-4 flex">
                    <a href={homeUrl} className="headerLogo" style={{ display: "flex", alignItems: "center", fontFamily: "kurdish, 'Open Sans', sans-serif" }}>
                        <div className="headerLogoTitle">
                            <div>{I18n.t("label.DLVRPortal")}</div>
                        </div>
                    </a>
                </div>
                <div className="col-2">

                </div>

                <div className="col-6 text-right localeWrap">
                    <AccessibilityButtonWrap {...props} />
                    <div className="localeIconWrapper">
                        <i className="pi pi-globe" style={{ fontSize: "20px" }} onClick={() => setDisplayLangMenu(!displayLangMenu)} />
                        {displayLangMenu && (
                            <SelectButton
                                className="p-button-label p-c displayLangMenu"
                                value={localeValue}
                                options={justifyOptions}
                                onChange={(e) => {
                                    onLocaleChange(e.value);
                                    setDisplayLangMenu(false);
                                }}
                                // itemTemplate={justifyTemplate}
                                optionLabel="label"
                            />
                        )}
                        <i className={`mr-4 ml-4 pi ${isDarkTheme ? "pi-sun" : "pi-moon"}`} style={{ fontSize: "20px" }} onClick={toggleDarkTheme} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopHeaderLine;
