import React, { Component } from 'react';
import { connect } from 'react-redux';
import ContainerHOC from '../../ContainerHOC';
import PropTypes from 'prop-types';
import AccountRecoveryChangePassword from './AccountRecoveryChangePassword';
import { recoveryReason } from '../../../constants/general';


class AccountRecoveryChangePasswordContainer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ContainerHOC>
                <AccountRecoveryChangePassword recoveryReason={recoveryReason.ACCOUNT_BLOCKED} />
            </ContainerHOC>
        )
    }
}


const mapStateToProps = state => {
    return {
    };
};

export default connect(mapStateToProps)(AccountRecoveryChangePasswordContainer);

AccountRecoveryChangePasswordContainer.propTypes = {
    dispatch: PropTypes.any
};
