import { genericFormFieldNames } from "@mb-react/krg-getid-form-data-common";
import {
    getFormValues, getFromAppStore
} from "@mb-react/mb-react-core";
import { formFieldNames } from "@mb-react/svarog-ui";
import { useMemo } from "react";
import { useForm } from "react-final-form";
import { useSelector } from "react-redux";
import { formFieldsMeta, forms } from "../../../../../../constants/form";
import {
    isMandatory,
    isOperationModeProtected,
    isReadOnly,
    isUseCaseReservePlateNumber
} from "../../../../../../util/businesslogicUtil";
import { useFieldValuesHandler } from "../../../../../../util/translateUtil";
import {ACCOUNT_IDENTITY} from "../../../../../../constants/application";

export const useFatherDataSettings = () => {
    const useCase = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.USE_CASE])
    const documentType = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.DOCUMENT_TYPE])
    const workflow = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldsMeta.WORKFLOW])
    const preSummaryAdded = useSelector((state) => getFromAppStore(state, 'preSummaryAdded'))
    const form = useForm();
    let formValues = form?.getState()?.values ?? {}
    const formValuesApp = useSelector((state) => getFormValues(state, forms.APPLICATION))
    const accountIdentity = useSelector(state => getFromAppStore(state, ACCOUNT_IDENTITY));

    let reservePlateNumberReadOnly = !isOperationModeProtected() && isUseCaseReservePlateNumber(formValuesApp);

    const fieldValuesHandler = useFieldValuesHandler();
    const onFatherNameChange = (e) => {
        fieldValuesHandler.changeArabicValueToLatin(e.target.value, formFieldNames.FATHERS_NAME_LATIN)
    }

    const handleFathersNameSettings = useMemo(() => {
        let fatherDataProps = {
            [formFieldNames.FATHERS_NAME]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || preSummaryAdded || isReadOnly(formValues, formFieldNames.FATHERS_NAME, useCase, documentType, workflow),
                    [formFieldNames.ON_CHANGE]: onFatherNameChange
                },
                required: isMandatory(formValues, formFieldNames.FATHERS_NAME, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return fatherDataProps
    }, [formValues[formFieldNames.TPID]])

    const handleFathersNameLatinSettings = useMemo(() => {
        let fatherDataProps = {
            [genericFormFieldNames.FATHER_NAME_LATIN]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || preSummaryAdded || isReadOnly(formValues, genericFormFieldNames.FATHER_NAME_LATIN, useCase, documentType, workflow),
                },
                required: isMandatory(formValues, genericFormFieldNames.FATHER_NAME_LATIN, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return fatherDataProps
    }, [formValues.fatherName, formValues[formFieldNames.TPID]])

    return { ...handleFathersNameSettings, ...handleFathersNameLatinSettings }
};