import {MBForm, addToAppStore} from "@mb-react/mb-react-core";
import {
  ProcessStateController,
  ServiceRequestData,
  formFieldNames,
  useCreateServiceWrapper,
} from "@mb-react/svarog-ui";
import React, { useEffect } from "react";
import {useDispatch} from "react-redux";
import { I18n } from "react-redux-i18n";
import { forms } from "../../../constants/form";
import { applicationStore } from "../../../constants/store";
import MyApplicationsSearchTask from "./MyApplicationSearchTask";

const MyApplicationsSearchForm = (props) => {
  const { searchApplicationFunc } = props;
  let dispatch = useDispatch();
  const callService = useCreateServiceWrapper();

  let initialValues = {
    [formFieldNames.APPLICATION_ID]: "",
    [formFieldNames.GIVEN_NAME]: "",
    [formFieldNames.SURNAME]: "",
  };

  useEffect(() => {
    searchApplicationRequest({});
  }, []);
  const searchApplicationRequest = (formData) => {
    callService(
        new ServiceRequestData(
            ProcessStateController.searchApplications,
            formData,
            null,
            null,
            (result) => {
              let applications = [];
              if (result) {
                applications = result.pagedResults;
                if (JSON.stringify(applications)) {
                  const tableData = [];
                  applications.map((app) => {
                    let appObject = {
                      applicationID: app.applicationMetaDataDTO.applicationID,
                      useCase: app.applicationMetaDataDTO.useCase,
                      givenName:
                      app.applicantsDTO.applicantDTOList[0].demographicDataDTO
                          .personDataDTO.givenName,
                      surname:
                      app.applicantsDTO.applicantDTOList[0].demographicDataDTO
                          .personDataDTO.surname,
                      dateOfBirth:
                      app.applicantsDTO.applicantDTOList[0].demographicDataDTO
                          .personDataDTO.dateOfBirth,
                      applicationStatus: I18n.t(
                          "label." + app.applicationMetaDataDTO.applicationStatus
                      ),
                    };
                    tableData.push(appObject);
                  });
                  dispatch(
                      addToAppStore(applicationStore.APPLICATIONS_DATA, tableData)
                  );
                }
              }
            },
            null
        )
    );
  };

  const searchApplication = (data) => {
    if(searchApplicationFunc){
      searchApplicationFunc(1, 10, data)
    }
  };

  return (
      <div>
        <MBForm
            formName={forms.SEARCH_APPLICATION}
            submitFunction={(data) => searchApplication(data)}
            initialValues={initialValues}
        >
          <MyApplicationsSearchTask />
        </MBForm>
      </div>
  );
};
export default MyApplicationsSearchForm;
