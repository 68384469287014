import { fieldType, FormElement, getFormValues } from "@mb-react/mb-react-core";
import React from "react";
import { useForm } from 'react-final-form';
import { useSelector } from "react-redux";
import { I18n, Translate } from "react-redux-i18n";
import { formFieldsQuestionnaire, forms } from "../../../../../constants/form";
import { NO, YES, yesNoSelect } from "../../../../../constants/general";

export function useHandleQuestionnairesComponent() {
  useSelector(state => state.i18n.locale)
  const form = useForm()
  const formValues = useSelector((state) => getFormValues(state, forms.QUESTIONNAIRES));

  const isNewDocument = () => {
    return formValues?.[formFieldsQuestionnaire.IS_NEW_DOCUMENT] && formValues[formFieldsQuestionnaire.IS_NEW_DOCUMENT] === YES
  };

  const noNewDocument = () => {
    return formValues?.[formFieldsQuestionnaire.IS_NEW_DOCUMENT] && formValues[formFieldsQuestionnaire.IS_NEW_DOCUMENT] === NO
  };

  const replaceDocument = () => {
    return formValues?.[formFieldsQuestionnaire.IS_REPLACE_DOCUMENT] && formValues[formFieldsQuestionnaire.IS_REPLACE_DOCUMENT] === YES
  };

  const noReplaceDocument = () => {
    return formValues?.[formFieldsQuestionnaire.IS_REPLACE_DOCUMENT] && formValues[formFieldsQuestionnaire.IS_REPLACE_DOCUMENT] === NO
  };

  const modifyDocument = () => {
    return formValues?.[formFieldsQuestionnaire.IS_MODIFY_DOCUMENT] && formValues[formFieldsQuestionnaire.IS_MODIFY_DOCUMENT] === YES
  };

  const noModifyDocument = () => {
    return formValues?.[formFieldsQuestionnaire.IS_MODIFY_DOCUMENT] && formValues[formFieldsQuestionnaire.IS_MODIFY_DOCUMENT] === NO
  };

  const extendDocument = () => {
    return formValues?.[formFieldsQuestionnaire.IS_EXTEND_DOCUMENT] && formValues[formFieldsQuestionnaire.IS_EXTEND_DOCUMENT] === YES
  };

  const noExtendDocument = () => {
    return formValues?.[formFieldsQuestionnaire.IS_EXTEND_DOCUMENT] && formValues[formFieldsQuestionnaire.IS_EXTEND_DOCUMENT] === NO
  };

  const cancelDocument = () => {
    return formValues?.[formFieldsQuestionnaire.IS_CANCEL_DOCUMENT] && formValues[formFieldsQuestionnaire.IS_CANCEL_DOCUMENT] === YES
  };

  const isFirstTimeApply = () => {
    return formValues?.[formFieldsQuestionnaire.ADDITIONAL_QUESTION] && formValues[formFieldsQuestionnaire.ADDITIONAL_QUESTION] === formFieldsQuestionnaire.FIRST_TIME_APPLY;
  };

  const isOldDocument = () => {
    return formValues?.[formFieldsQuestionnaire.ADDITIONAL_QUESTION] && formValues[formFieldsQuestionnaire.ADDITIONAL_QUESTION] === formFieldsQuestionnaire.OLD_DOCUMENT;
  };

  const isForeignDocument = () => {
    return formValues?.[formFieldsQuestionnaire.ADDITIONAL_QUESTION] && formValues[formFieldsQuestionnaire.ADDITIONAL_QUESTION] === formFieldsQuestionnaire.FOREIGN_DOCUMENT;
  };

  const isLostDocument = () => {
    return formValues?.[formFieldsQuestionnaire.LOST_DOCUMENT] && formValues[formFieldsQuestionnaire.LOST_DOCUMENT] === true;
  };

  const isDamagedDocument2 = () => {
    return formValues?.[formFieldsQuestionnaire.DAMAGED_DOCUMENT2] && formValues[formFieldsQuestionnaire.DAMAGED_DOCUMENT2] === true;
  };

  const isExpiredDocument = () => {
    return formValues?.[formFieldsQuestionnaire.EXPIRED_DOCUMENT] && formValues[formFieldsQuestionnaire.EXPIRED_DOCUMENT] === true;
  };

  const isExpiredDocument2 = () => {
    return formValues?.[formFieldsQuestionnaire.EXPIRED_DOCUMENT2] && formValues[formFieldsQuestionnaire.EXPIRED_DOCUMENT2] === true;
  };

  const isExpiredDocument3 = () => {
    return formValues?.[formFieldsQuestionnaire.EXPIRED_DOCUMENT3] && formValues[formFieldsQuestionnaire.EXPIRED_DOCUMENT3] === true;
  };

  const readOnlyForModifyDocument = () => {
    if (formValues && formValues[formFieldsQuestionnaire.IS_MODIFY_DOCUMENT] === YES) {
      return false
    } else {
      return true
    }
  };

  const readOnlyForExpiredDocument = () => {
    if (formValues?.[formFieldsQuestionnaire.IS_EXTEND_DOCUMENT] === YES) {
      return false
    } else {
      return true
    }
  };

  const readOnlyForSecondQuestion = () => {
    if (formValues?.[formFieldsQuestionnaire.LOST_DOCUMENT]) {
      return true
    } else {
      return false
    }
  };

  const readOnlyForSecondQuestion2 = () => {
    if (formValues?.[formFieldsQuestionnaire.DAMAGED_DOCUMENT2]) {
      return true
    } else {
      return false
    }
  };

  const onIsNewDocumentChange = () => {
    console.log(form, 'form')
    form.change(formFieldsQuestionnaire.IS_REPLACE_DOCUMENT, "");
    form.change(formFieldsQuestionnaire.IS_MODIFY_DOCUMENT, "");
    form.change(formFieldsQuestionnaire.IS_EXTEND_DOCUMENT, "");
    form.change(formFieldsQuestionnaire.EXPIRED_DOCUMENT2, "");
    form.change(formFieldsQuestionnaire.DAMAGED_DOCUMENT, "");
    form.change(formFieldsQuestionnaire.ADDITIONAL_QUESTION, "");
    form.change(formFieldsQuestionnaire.LOST_DOCUMENT, "");
    form.change(formFieldsQuestionnaire.DAMAGED_DOCUMENT2, "");
    form.change(formFieldsQuestionnaire.EXPIRED_DOCUMENT, "");
    form.change(formFieldsQuestionnaire.EXPIRED_DOCUMENT3, "");
    form.change(formFieldsQuestionnaire.IS_CANCEL_DOCUMENT, "");
  };

  const isReplaceClearForm = () => {
    form.change(formFieldsQuestionnaire.IS_MODIFY_DOCUMENT, "");
    form.change(formFieldsQuestionnaire.IS_EXTEND_DOCUMENT, "");
    form.change(formFieldsQuestionnaire.EXPIRED_DOCUMENT2, "");
    form.change(formFieldsQuestionnaire.LOST_DOCUMENT, "");
    form.change(formFieldsQuestionnaire.DAMAGED_DOCUMENT, "");
    form.change(formFieldsQuestionnaire.EXPIRED_DOCUMENT, "");
    form.change(formFieldsQuestionnaire.DAMAGED_DOCUMENT2, "");
    form.change(formFieldsQuestionnaire.ADDITIONAL_QUESTION, "");
    form.change(formFieldsQuestionnaire.EXPIRED_DOCUMENT3, "");
    form.change(formFieldsQuestionnaire.IS_CANCEL_DOCUMENT, "");
  };

  const isModifyClearForm = () => {
    form.change(formFieldsQuestionnaire.IS_EXTEND_DOCUMENT, "");
    form.change(formFieldsQuestionnaire.EXPIRED_DOCUMENT2, "");
    form.change(formFieldsQuestionnaire.EXPIRED_DOCUMENT3, "");
    form.change(formFieldsQuestionnaire.IS_CANCEL_DOCUMENT, "");
  };

  const isExtendClearForm = () => {
    form.change(formFieldsQuestionnaire.IS_CANCEL_DOCUMENT, "");
  };

  const isNewDocumentComponent =
    <FormElement
      label={'label.' + formFieldsQuestionnaire.IS_NEW_DOCUMENT} fieldType={fieldType.RADIO_INLINE}
      fieldProps={{
        className: "radioQuest",
        name: formFieldsQuestionnaire.IS_NEW_DOCUMENT,
        options: yesNoSelect,
        onChange: onIsNewDocumentChange
      }}
    />;

  const newDocumentYesComponent =
    <>
      <FormElement
        label={'label.' + formFieldsQuestionnaire.ADDITIONAL_QUESTION}
        fieldType={fieldType.RADIO_INLINE} additionalClass={"additionalQuestLabel"}
        fieldProps={{
          className: "radioQuest",
          name: formFieldsQuestionnaire.ADDITIONAL_QUESTION,
          options: [
            {
              name: I18n.t('label.' + formFieldsQuestionnaire.FIRST_TIME_APPLY), value: formFieldsQuestionnaire.FIRST_TIME_APPLY
            },
            {
              name: I18n.t('label.' + formFieldsQuestionnaire.OLD_DOCUMENT), value: formFieldsQuestionnaire.OLD_DOCUMENT
            },
            {
              name: I18n.t('label.' + formFieldsQuestionnaire.FOREIGN_DOCUMENT), value: formFieldsQuestionnaire.FOREIGN_DOCUMENT
            }
          ]
        }}
      />
    </>;

  const replaceDocumentComponent =
    <>
      <FormElement
        label={'label.' + formFieldsQuestionnaire.IS_REPLACE_DOCUMENT}
        fieldType={fieldType.RADIO_INLINE}
        fieldProps={{
          className: "radioQuest",
          name: formFieldsQuestionnaire.IS_REPLACE_DOCUMENT,
          options: yesNoSelect,
          onChange: isReplaceClearForm
        }}
      />
    </>;

  const replaceDocumentComponentYes =
    <>
      <div className="additionalQuestionsWrapper">
        <p className="additionalQuestionLabel">
          <Translate value={'label.' + formFieldsQuestionnaire.ADDITIONAL_QUESTION} />
        </p>
        <FormElement
          label={'label.' + formFieldsQuestionnaire.LOST_DOCUMENT}
          fieldType={fieldType.CHECKBOX_CUSTOM} additionalClass={"checkBoxQuestLeft"}
          fieldProps={{
            readOnly: readOnlyForSecondQuestion2(),
            name: formFieldsQuestionnaire.LOST_DOCUMENT
          }}
        />
        <FormElement
          label={'label.' + formFieldsQuestionnaire.DAMAGED_DOCUMENT2}
          fieldType={fieldType.CHECKBOX_CUSTOM} additionalClass={"checkBoxQuestLeft"}
          fieldProps={{
            readOnly: readOnlyForSecondQuestion(),
            name: formFieldsQuestionnaire.DAMAGED_DOCUMENT2
          }}
        />
        <FormElement
          label={'label.' + formFieldsQuestionnaire.EXPIRED_DOCUMENT}
          fieldType={fieldType.CHECKBOX_CUSTOM} additionalClass={"checkBoxQuest"}
          fieldProps={{
            name: formFieldsQuestionnaire.EXPIRED_DOCUMENT
          }}
        />
      </div>
    </>;

  const isModifyDocumentComponent =
    <div className="radionInlineWrapForQuest">
      <FormElement
        label={'label.' + formFieldsQuestionnaire.IS_MODIFY_DOCUMENT}
        fieldType={fieldType.RADIO_INLINE}
        fieldProps={{
          className: "radioQuest",
          name: formFieldsQuestionnaire.IS_MODIFY_DOCUMENT,
          options: yesNoSelect,
          onChange: isModifyClearForm
        }}
      />
      <FormElement
        label={'label.' + formFieldsQuestionnaire.EXPIRED_DOCUMENT3}
        fieldType={fieldType.CHECKBOX_CUSTOM} additionalClass={"checkBoxQuest"}
        fieldProps={{
          readOnly: readOnlyForModifyDocument(),
          name: formFieldsQuestionnaire.EXPIRED_DOCUMENT3
        }}
      />
    </div>;

  const isExtendDocumentComponent =
    <div className="radionInlineWrapForQuest">
      <FormElement
        label={'label.' + formFieldsQuestionnaire.IS_EXTEND_DOCUMENT}
        fieldType={fieldType.RADIO_INLINE}
        fieldProps={{
          className: "radioQuest",
          name: formFieldsQuestionnaire.IS_EXTEND_DOCUMENT,
          options: yesNoSelect,
          onChange: isExtendClearForm
        }}
      />
      <FormElement
        label={'label.' + formFieldsQuestionnaire.EXPIRED_DOCUMENT2}
        fieldType={fieldType.CHECKBOX_CUSTOM} additionalClass={"checkBoxQuest"}
        fieldProps={{
          readOnly: readOnlyForExpiredDocument(),
          name: formFieldsQuestionnaire.EXPIRED_DOCUMENT2
        }}
      />
    </div>;

  const isCancelDocumentComponent =
    <div className="radionInlineWrapForQuest">
      <FormElement
        label={'label.' + formFieldsQuestionnaire.IS_CANCEL_DOCUMENT}
        fieldType={fieldType.RADIO_INLINE}
        fieldProps={{
          className: "radioQuest",
          name: formFieldsQuestionnaire.IS_CANCEL_DOCUMENT,
          options: yesNoSelect
        }}
      />
    </div>
  return {
    isNewDocumentComponent,
    newDocumentYesComponent,
    replaceDocumentComponent,
    replaceDocumentComponentYes,
    isModifyDocumentComponent,
    isExtendDocumentComponent,
    isCancelDocumentComponent,
    isNewDocument: isNewDocument(),
    noNewDocument: noNewDocument(),
    replaceDocument: replaceDocument(),
    modifyDocument: modifyDocument(),
    noReplaceDocument: noReplaceDocument(),
    noModifyDocument: noModifyDocument(),
    extendDocument: extendDocument(),
    noExtendDocument: noExtendDocument(),
    cancelDocument: cancelDocument(),
    isFirstTimeApply: isFirstTimeApply(),
    isOldDocument: isOldDocument(),
    isForeignDocument: isForeignDocument(),
    isLostDocument: isLostDocument(),
    isDamagedDocument2: isDamagedDocument2(),
    isExpiredDocument: isExpiredDocument(),
    isExpiredDocument2: isExpiredDocument2(),
    isExpiredDocument3: isExpiredDocument3(),
  }
}

