/**
 * @author Aleksa Vucenovic on 09/12/2021.
 * @author Kristian P on 25/03/2022.
 */
import { Button, addToAppStore } from '@mb-react/mb-react-core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from "react-redux-i18n";
import { getFromAppStore } from "@mb-react/mb-react-core";
import {
    ACTIVATE_ACCOUNT,
    ADMIN,
    APPROVE_ACCOUNT,
    DELETE_ACCOUNT,
    SUSPEND_ACCOUNT,
    UNLOCK_ACCOUNT
} from "../../../../constants/admin/rights";
import { AccountService, getCookie, ServiceRequestData, useCreateServiceWrapper } from "@mb-react/svarog-ui";
import { useHandleAuthenticationServices } from "../../../../services/authentication/authenticationServiceHandler";
import { isLoggedIn } from "../../../../util/businesslogicUtil";
import { useChangeAccountStateErrorResult } from "../../../../services/account/handleAccountServiceResponse";
import { accountStore, applicationStore } from "../../../../constants/store";
import { REDIRECT_TO_ADMIN_ACCOUNT_OVERVIEW } from "../../../../constants/environment";
import {SELECTED_ACCOUNT_ID} from "../../../../constants/general";
import {checkUserRights} from "../../../../util/util";

const StatesComponent = ({ personData }) => {
    let dispatch = useDispatch();
    const serviceWrapper = useCreateServiceWrapper();
    const handleAuthenticationServices = useHandleAuthenticationServices();
    const handleAccountServiceError = useChangeAccountStateErrorResult();

    let showActivateButton = false
    let showSuspendButton = false
    let showDeleteButton = false
    let showApproveButton = false
    let showUnlockButton = false

    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))

    useEffect(() => {
        if (isLoggedIn()) {
            handleAuthenticationServices.getUserRights()
        }
    }, []);

    const activeRights = [ADMIN, ACTIVATE_ACCOUNT];
    const suspendRights = [ADMIN, SUSPEND_ACCOUNT];
    const approveRights = [ADMIN, APPROVE_ACCOUNT];
    const unlockRights = [ADMIN, UNLOCK_ACCOUNT];
    const deleteRights = [ADMIN, DELETE_ACCOUNT];

    const accountStateActivate = (accountId) => {
        if (accountId) {
            let prams = { accountId: accountId }
            serviceWrapper(new ServiceRequestData(
                AccountService.accountStateActivate,
                prams,
                null,
                handleAccountServiceError,
                () => {
                    getAccountData()
                }
            ))
        }

    }

    const accountStateSuspend = (accountId) => {
        if (accountId) {
            let prams = { accountId: accountId }
            serviceWrapper(new ServiceRequestData(
                AccountService.accountStateSuspend,
                prams,
                null,
                handleAccountServiceError,
                () => {
                    getAccountData()
                }
            ))
        }

    }

    const accountStateApprove = (accountId) => {
        if (accountId) {
            let prams = { accountId: accountId }
            serviceWrapper(new ServiceRequestData(
                AccountService.accountStateApprove,
                prams,
                null,
                handleAccountServiceError,
                () => {
                    getAccountData()
                }
            ))
        }

    }

    const accountStateUnlock = (accountId) => {
        if (accountId) {
            let prams = {
                accountId: accountId,
                recoveryReason: "ACCOUNT_BLOCKED"
            }
            serviceWrapper(new ServiceRequestData(
                AccountService.accountStateUnlock,
                prams,
                null,
                handleAccountServiceError,
                () => {
                    getAccountData()
                }
            ))
        }

    }

    const accountStateDelete = (accountId) => {
        if (accountId) {
            let prams = { accountId: accountId }
            serviceWrapper(new ServiceRequestData(
                AccountService.accountStateDelete,
                prams,
                null,
                handleAccountServiceError,
                () => {
                    getAccountData()
                }
            ))
        }

    }

    const getAccountData = () => {

        let requestData = {
            accountId: getCookie(SELECTED_ACCOUNT_ID),
            meta: false
        }

        serviceWrapper(new ServiceRequestData(
            AccountService.getAccountDataById,
            requestData,
            null,
            null,
            (result) => {
                if (result) {
                    onSuccessFetch(result)
                }
            },
            null
        ))
    };

    const onSuccessFetch = (result) => {
        if (result) {
            dispatch(addToAppStore(accountStore.ACCOUNT_DATA, result.data))
        }
    };

    if (personData?.accountState) {
        switch (personData.accountState) {
            case "CREATED":
                showActivateButton = true
                showDeleteButton = true
                showApproveButton = true
                showSuspendButton = false
                showUnlockButton = false
                break;
            case "CONFIRMED":
                showDeleteButton = true
                showApproveButton = true
                showActivateButton = false
                showSuspendButton = true
                showUnlockButton = false
                break;
            case "ACTIVE":
                showSuspendButton = true
                showDeleteButton = true
                showActivateButton = false
                showApproveButton = false
                showUnlockButton = false
                break;
            case "INACTIVE":
                showActivateButton = true
                showDeleteButton = true
                showApproveButton = false
                showSuspendButton = false
                showUnlockButton = false
                break;
            case "LOCKED":
                showUnlockButton = true
                showDeleteButton = true
                showActivateButton = false
                showApproveButton = false
                showSuspendButton = false
                break;
            case "DELETED":
                showActivateButton = true
                showDeleteButton = false
                showApproveButton = false
                showSuspendButton = false
                showUnlockButton = false
                break;
            case "EXPIRED":
                showActivateButton = true
                showDeleteButton = true
                showApproveButton = false
                showSuspendButton = false
                showUnlockButton = false
                break;
            case "NOTIFIED":
                showActivateButton = true
                showDeleteButton = false
                showApproveButton = false
                showSuspendButton = false
                showUnlockButton = false
                break;
        }
    }


    return (
        <>
            <div className="grid main-button-bar adminAccBtns">
                <div className="col-lg-4 btn-margin text-left flip">
                    <Button label='action.back' className="p-button p-component mt-2 p-button-secondary" onClick={() => window.location = REDIRECT_TO_ADMIN_ACCOUNT_OVERVIEW} />
                </div>

                <div className="col-lg-6 btn-margin text-right flip">
                    {showActivateButton &&
                        checkUserRights(activeRights, userRights) &&
                        <Button label={I18n.t("label.states.activate")} className="p-button-success p-component mt-2 p-button-secondary"
                            onClick={() => dispatch(accountStateActivate(personData.accountId))} />}

                    {showSuspendButton &&
                        checkUserRights(suspendRights, userRights) &&
                        <Button label={I18n.t("label.states.suspend")} className="p-button-warning p-component mt-2 p-button-secondary "
                            onClick={() => dispatch(accountStateSuspend(personData.accountId))} />}

                    {showApproveButton &&
                        checkUserRights(approveRights, userRights) &&
                        <Button label={I18n.t("label.states.approve")} className="p-button p-component mt-2 p-button-secondary"
                            onClick={() => dispatch(accountStateApprove(personData.accountId))} />}

                    {showUnlockButton &&
                        checkUserRights(unlockRights, userRights) &&
                        <Button label={I18n.t("label.states.unlock")} className="p-button p-component mt-2 p-button-secondary"
                            onClick={() => dispatch(accountStateUnlock(personData.username))} />}

                    {showDeleteButton &&
                        checkUserRights(deleteRights, userRights) &&
                        <Button label={I18n.t("label.states.delete")} className="p-button-danger p-button mr-2"
                            onClick={() => dispatch(accountStateDelete(personData.accountId))} />}
                </div>
            </div>
        </>
    );
}

export default StatesComponent