import React from 'react';

export const prepareSupportingDocumentDocuments = (array, docType) => {
    let documentsState = [];

    for (let i = 0; i < array.length; i++) {
        if (array[i].type === docType) {
            documentsState.push({name: array[i].name, data: array[i].data, size: array[i].size});
        }
    }
    return documentsState;
};

// parses the additional fields JSON file
export const parseAdditionalFieldsFromConfig = data => {
    const stringData = JSON.stringify(data);
    return JSON.parse(stringData);
};


export function getDictionarySubTypeKey(dictionary, key) {
    if (dictionary && key) {
        for (let i = 0; i < dictionary.length; i++) {
            const dictionaryI = dictionary[i]
            if(dictionaryI.value === key){
                return dictionaryI.subtypeKey
            }
        }
    }
}

export const getLocalSelectOptionTranslation = (selectOptions, value) => {
    if(selectOptions && selectOptions.length){
        for (let i = 0; i < selectOptions.length; i++) {
            if (selectOptions[i].key === value) {
                return selectOptions[i].values.kr
            }
        }
    }
    return value;
};
