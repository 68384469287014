import {
    applicantGenericFieldsList,
    LegalEntityData, legalEntityFormFieldList,
    VehicleOwnershipData
} from "@mb-react/krg-getid-form-data-common";
import {
    addToAppStore,
    Button,
    getFormValues, getFromAppStore, Messages
} from "@mb-react/mb-react-core";
import React, {useEffect, useRef} from "react";
import {useSelector, useDispatch} from "react-redux";
import {I18n, Translate} from "react-redux-i18n";
import {forms} from "../../../../../constants/form";
import {dictionaryReference, projectDictionaries} from "../../../../../constants/general";
import {useHandleCommonServices} from "../../../../../services/commonServiceHandler";
import {useOwnershipDataSettings} from "../personalData/personalDataSettings/ownershipDataSettings";
import {
    EDocumentType, EUseCases, extractFormValuesToSingleApplicantData,
    extractLegalEntityDTODataToFormValues,
    formFieldNames, PagedRequest
} from "@mb-react/svarog-ui";
import {legalEntityDataSettings} from "./legalEntityDataSettings/legalEntityDataSettings";
import {
    isLoggedAccountLegalEntity,
    isOperationModeProtected, isUseCaseReservePlateNumber,
    prepareLETypeForDropDownList
} from "../../../../../util/businesslogicUtil";
import {getPath, i18nl, TRG} from "../../../../../constants/i18n";
import {useForm} from "react-final-form";
import {extractObjectBasedOnKeysList} from "../../../../../util/dataExtractorUtil";
import {LEGAL_ENTITY} from "../../../../../constants/application";

const LegalEntityTask = (props) => {
    const applicationsFromStore = useSelector(state => getFromAppStore(state, 'fetchedApplication'));
    const useCaseFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.USE_CASE]);
    const docTypeFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.DOCUMENT_TYPE]);
    const formValuesApplication = useSelector((state) => getFormValues(state, forms.APPLICATION));
    const guidanceMessage1 = useRef(null);
    const handleCommonServices = useHandleCommonServices()
    const identity = useSelector(state => getFromAppStore(state, 'identity'))

    const dispatch = useDispatch()
    const form = useForm();

    useEffect(() => {
        if (applicationsFromStore) {
            if (applicationsFromStore["placeOfBirthProvince"]) {
                handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, applicationsFromStore["placeOfBirthProvince"], dictionaryReference.CITIES_PLACE_OF_BIRTH)
            }

            if (applicationsFromStore["placeOfBirthCity"]) {
                handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, applicationsFromStore["placeOfBirthCity"], dictionaryReference.VILLAGES_PLACE_OF_BIRTH)
            }

            if (applicationsFromStore["provinceOfRegistration"]) {
                handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, applicationsFromStore["provinceOfRegistration"], dictionaryReference.CITIES_OF_REGISTRATION)
            }

            if (applicationsFromStore["cityOfRegistration"]) {
                handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, applicationsFromStore["cityOfRegistration"], dictionaryReference.VILLAGES_OF_REGISTRATION)
            }
        }
        guidanceMessage1.current.show({
            severity: 'info', sticky: true, content: (
                <React.Fragment className="mb-1">
                    <i className="pi pi-info p-2" style={{
                        borderRadius: '50%',
                        backgroundColor: 'var(--surface-b)',
                        color: 'var(--surface-d)'
                    }}></i>
                    <div className="ml-2">
                        <Translate value="message.legalEntityDataGuidanceMessage1"/>
                    </div>
                </React.Fragment>
            )
        });

        if (props.useCase == "modifyIdentity" || props.useCase == "cancelIdentity" || useCaseFromStore == "CANCEL_DOCUMENT" || (useCaseFromStore == EUseCases.RESERVE_PLATE_NUMBER && !isOperationModeProtected())) {
            let pagedRequest = new PagedRequest();
            pagedRequest.requestObject = {}
            pagedRequest.page = 1
            pagedRequest.sizePerPage = 10

            handleCommonServices.handleGetMyIdentityForLegalEntity(pagedRequest, getMyIdentityOnSuccess)
        }

    }, []);

    const getMyIdentityOnSuccess = (response) => {
        let newObj = extractLegalEntityDTODataToFormValues(response.data, {});
        let legalEntity = extractObjectBasedOnKeysList(newObj, legalEntityFormFieldList);

        legalEntity = {...legalEntity, [formFieldNames.LE_TYPE]: prepareLETypeForDropDownList(legalEntity.leType)}

        if (legalEntity["leAddressProvince"]) {
            handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, legalEntity["leAddressProvince"], dictionaryReference.CITIES_PLACE_OF_BIRTH)
        }

        if (legalEntity["leAddressCity"]) {
            handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, legalEntity["leAddressCity"], dictionaryReference.VILLAGES_PLACE_OF_BIRTH)
        }

        if(!isOperationModeProtected() && isUseCaseReservePlateNumber(formValuesApplication)){
            let entityForApplication = extractFormValuesToSingleApplicantData({
                ...legalEntity,
                [formFieldNames.APPLICANT_TYPE]: LEGAL_ENTITY,
            }, applicantGenericFieldsList)

            dispatch(addToAppStore("reservePlateNumberEntity", entityForApplication))
        }

        form.initialize(legalEntity)
        dispatch(addToAppStore('fetchedApplication', {legalEntity}))

    }

    const addIdentityToForm = () => {

        dispatch(addToAppStore('identityAdd', true))

        let app = extractLegalEntityDTODataToFormValues(identity)
        let legalEntity = extractObjectBasedOnKeysList(app, legalEntityFormFieldList)
        legalEntity = {...legalEntity, [formFieldNames.LE_TYPE]: prepareLETypeForDropDownList(legalEntity.leType)}

        form.initialize(legalEntity)
    }

    return (
        <div className="card">
            <Messages ref={guidanceMessage1}/>
            {
                useCaseFromStore && docTypeFromStore && <div className={"useCaseHeader"}>
                    <h5>{I18n.t("label.useCaseHeader", {
                        documentType: I18n.t("label." + docTypeFromStore),
                        useCase: I18n.t("label." + useCaseFromStore)
                    })}</h5></div>
            }
            {isLoggedAccountLegalEntity() && (docTypeFromStore == EDocumentType.VR) && identity && !isOperationModeProtected() && <div>
                <Button
                    label={I18n.t(getPath(i18nl.USE_IDENTITY, TRG.ACTION))}
                    icon="pi pi-file-pdf"
                    onClick={addIdentityToForm}
                    className="p-button"
                    autoFocus
                />
            </div>}
            <h5><Translate value="label.legalEntityInformation"/></h5>
            <hr className="sectionSeparator"/>
            <div className="grid">
                 <LegalEntityData {...legalEntityDataSettings()} />
            </div>
            {(docTypeFromStore == EDocumentType.VR) && <div>
                <h5><Translate value="label.shareData"/></h5>
                <hr className="sectionSeparator"/>
                <div className="grid">
                    <VehicleOwnershipData {...useOwnershipDataSettings(props.formNameSufix)} />
                </div>
            </div>}
        </div>
    )
};
export default LegalEntityTask;