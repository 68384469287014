import {AuthenticationService, ServiceRequestData, useCreateServiceWrapper} from "@mb-react/svarog-ui";
import {useHandleGetUserRightsSuccessResult} from "./handleAuthServiceResponse";

export function useHandleAuthenticationServices() {
    const callService = useCreateServiceWrapper();
    const handleGetUserRightsSuccessResult = useHandleGetUserRightsSuccessResult();

    const getUserRights = () => {
        callService(new ServiceRequestData(
            AuthenticationService.getUserRights,
            null,
            handleGetUserRightsSuccessResult,
            null,
            null,
            null
        ))
    };

    return {
        getUserRights: getUserRights
    }
}