import {formFieldNames, getCookie} from "@mb-react/svarog-ui";
import axios from "axios";

let GATEWAY_URL = configurationData && configurationData.gatewayURL ? configurationData.gatewayURL : "http://localhost:10300";
let ACCESS_TOKEN_NAME = configurationData && configurationData.accessTokenName ? configurationData.accessTokenName : "jwt";

// /*** DL ***/
const SEARCH_PERSONS = '/interface-adapter/persons/search';
const SEARCH_LEGAL_ENTITIES = '/interface-adapter/legalEntities/search';
const CREATE_REPORT = '/interface-adapter/reports/summary';
const CREATE_ACCOUNT_REPORT = '/interface-adapter/reports/createAccountReport';
const CREATE_PORTAL_REPORT = '/interface-adapter/public/reports/summary';

const CHECK_CONDITIONS_TO_APPLY = '/interface-adapter/documents/checkConditionsToApply';

const GET_DL_BLOCKING_STATE = '/interface-adapter/applications/blockingState';

// /*** VR ***/
const SEARCH_VEHICLES = '/interface-adapter/vehicles/portal/search';
const GET_AVAILABLE_LICENSE_NUMBERS = '/interface-adapter/plates/number/available';
const RESERVE_LICENSE_NUMBERS = '/interface-adapter/plates/number/reserve';
const UNRESERVE_LICENSE_NUMBERS = '/interface-adapter/plates/number/unreserve';
const GET_VEHICLE_OWNERS = '/interface-adapter/vehicles/owners';
const ALLOCATE_OLD_PLATE_NUMBER = "/interface-adapter/plates/number/old/allocate";
const SYNC_DATA = "/interface-adapter/sync/data";
const GET_PLATE_RESERVATION_USE_AND_REFERENCE = '/interface-adapter/plates/number/use-existing';

const GET_VR_BLOCKING_STATE = '/interface-adapter/applications/vr/blockingState';

const SAVE_TRANSACTION = '/tx-service/sagas'


export class InterfaceAdapterService {
    // /*** DL ***/
    static searchPersons(values) {
        return axios.post(GATEWAY_URL + SEARCH_PERSONS, values, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    static searchLegalEntities(values) {
        return axios.post(GATEWAY_URL + SEARCH_LEGAL_ENTITIES, values, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    static createEnrollmentReport(values) {
        return axios.post(GATEWAY_URL + CREATE_REPORT, values, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    static createPortalReport(values) {
        return axios.post(GATEWAY_URL + CREATE_PORTAL_REPORT, values, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    static createApplicationReport(requestData) {
        let getRequest = "/" + requestData.applicationId;
        return axios.get(GATEWAY_URL + CREATE_REPORT + getRequest, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    static createAccountReport(values) {
        return axios.post(GATEWAY_URL + CREATE_ACCOUNT_REPORT, values, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    static checkConditionsToApply(requestData) {
        const params = new URLSearchParams();
        params.append("tpId", requestData?.tpId);
        params.append("useCase", requestData?.useCase);
        params.append("fetchedClasses", requestData?.fetchedClasses ?? []);

        return axios.get(GATEWAY_URL + CHECK_CONDITIONS_TO_APPLY + '?' + params.toString(), {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    // /*** VR ***/
    static searchVehicles(values) {
        console.log("searchVehicles values", values)
        return axios.post(GATEWAY_URL + SEARCH_VEHICLES, values, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    static getAvailableLicenseNumber(values) {
        return axios.post(GATEWAY_URL + GET_AVAILABLE_LICENSE_NUMBERS, values, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    static searchPersons(values) {
        return axios.post(GATEWAY_URL + SEARCH_PERSONS, values, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    static reserveLicenseNumber(values) {
        return axios.post(GATEWAY_URL + RESERVE_LICENSE_NUMBERS, values, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    static unreserveLicenseNumber(values) {
        return axios.post(GATEWAY_URL + UNRESERVE_LICENSE_NUMBERS, values, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    static getVehicleOwners(values) {
        const getRequest = '/' + values?.[formFieldNames.VEHICLE_ID];
        return axios.get(GATEWAY_URL + GET_VEHICLE_OWNERS + getRequest, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        });
    }

    static allocateOldPlateNumber(oldPlateNumber) {
        const getRequest = '/' + oldPlateNumber;
        return axios.get(GATEWAY_URL + ALLOCATE_OLD_PLATE_NUMBER + getRequest, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        });
    }

    static syncData() {
        return axios.get(GATEWAY_URL + SYNC_DATA, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        });
    }

    static getDLBlockingState({states, useCase}) {
        const params = new URLSearchParams();
        params.append("stateHandlingStatesList", states);
        params.append("useCase", useCase);

        return axios.get(GATEWAY_URL + GET_DL_BLOCKING_STATE + "?" + params.toString(), {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        });
    };

    static getVRBlockingState({entityType, states, useCase, isTaxi, isProxy, isImporter}) {
        const params = new URLSearchParams();
        params.append("entityType", entityType);
        params.append("stateHandlingStatesList", states);
        params.append("useCase", useCase);
        params.append("isTaxi", isTaxi ? isTaxi : false);
        params.append("isProxy", isProxy ? isProxy : false);
        params.append("isImporter", isImporter ? isImporter : false);

        return axios.get(GATEWAY_URL + GET_VR_BLOCKING_STATE + "?" + params.toString(), {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        });
    };

    static getPlateReservationUseAndReference(values) {
        return axios.post(GATEWAY_URL + GET_PLATE_RESERVATION_USE_AND_REFERENCE, values, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    static saveTransaction(values) {
        return axios.post(GATEWAY_URL + SAVE_TRANSACTION, values, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }
}

export default InterfaceAdapterService;