import {
    addMultipleErrorToStep,
    completeStep, getFromAppStore,
    hasValidationErrors,
    MBForm,
    NavButtons,
    nextStep
} from "@mb-react/mb-react-core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {APPLICATION_STEPS, applicationStore} from "../../../../../constants/store";
import VehicleIdentificationTask from "./VehicleIdentificationTask";
import {vehicleIdentificaitonDataValidation} from "./vehicleDataSettings/vehicleIndentificationDataValidation";
import {onCancel} from "../../../../../util/componentUtil";
import {useSkip} from "../../../../../util/businesslogicUtil";
import NavButtonsLocal from "../../../../reactCoreComponents/NavButtonsLocal";
import {isMobile} from "../../../../../util/environment";

const VehicleIdentificationForm = (props) => {
    const errors = useSelector((state) => hasValidationErrors(state, props.formName));
    const dispatch = useDispatch();
    const vehicleDataFormValidation = vehicleIdentificaitonDataValidation();
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))

    const onSkip = () => {
        dispatch(nextStep(props.navigationName))
    }

    return (
        <MBForm formName={props.formName} stepId={APPLICATION_STEPS.VEHICLE_IDENTIFICATION} navigationId={props.navigationName} warningButtonLabel={isMobile() ? "  " : null} showErrorPanel submitFunction={() => {
            dispatch(completeStep(props.navigationName, APPLICATION_STEPS.VEHICLE_IDENTIFICATION))
            dispatch(nextStep(props.navigationName))
        }} validate={vehicleDataFormValidation.validate}>
            <VehicleIdentificationTask formName={props.formName}/>
            <NavButtonsLocal firstStep={true} customOnSkip={() => onSkip()}
                        allowSkip={useSkip(userRights, errors)} hideNext={useSkip(userRights, errors)} customOnCancel={() => onCancel(dispatch)}/>
        </MBForm>
    );
}

export default VehicleIdentificationForm;