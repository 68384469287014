import { addToAppStore, closeModal, openModal } from "@mb-react/mb-react-core";
import { useDispatch } from "react-redux";
import { modalIDs } from "../constants/environment";
import { restErrors } from "../constants/general";
import { i18nl } from "../constants/i18n";
import { ON_ERROR_GLOBAL, ON_ERROR_LOCAL, ON_SUCCESS_GLOBAL, ON_SUCCESS_LOCAL, REQUEST_DATA, SERVICE } from "../constants/services";
import { applicationStore } from "../constants/store";

export function useCreateServiceWrapper() {
  const dispatch = useDispatch();

  const handleResponse = (params) => {
    let service = params[SERVICE]
    let requestData = params[REQUEST_DATA]
    let onSuccessGlobalFunc = params[ON_SUCCESS_GLOBAL]
    let onErrorGlobalFunc = params[ON_ERROR_GLOBAL]
    let onSuccessLocalFunc = params[ON_SUCCESS_LOCAL]
    let onErrorLocalFunc = params[ON_ERROR_LOCAL]

    const onSuccess = (data) => {
      dispatch(closeModal(modalIDs.SPINNER));
      if (data && onSuccessGlobalFunc) {
        onSuccessGlobalFunc(data);
      }
      if (onSuccessLocalFunc) {
        onSuccessLocalFunc(data)
      }
    }
    const onError = (data) => {
      dispatch(closeModal(modalIDs.SPINNER));
      if (!onErrorGlobalFunc && !onErrorLocalFunc) {
        dispatch(addToAppStore(applicationStore.ERROR_MESSAGE, 'err.' + restErrors.GENERAL_SERVER_ERROR));
        dispatch(openModal(modalIDs.ERROR));
      } else {
        if (data && onErrorGlobalFunc) {
          onErrorGlobalFunc(data);
        }
        if (onErrorLocalFunc) {
          onErrorLocalFunc(data)
        }
      }


    }

    let responseHandler = {
      onSuccess: onSuccess,
      onError: onError
    }
    dispatch(openModal(modalIDs.SPINNER));
    if (requestData) {
      service(requestData, responseHandler)
    } else {
      service(responseHandler)
    }
  }
  return handleResponse;
}