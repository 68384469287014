import { formFieldNames } from "@mb-react/svarog-ui";
import {convertDateFromServerToMoment, formatServerStringDate} from "../sagas/generators/sagaUtil";

/**
 * @author pavlovicn, created on 8/31/2021.
 */

export const DEMOGRAPHIC_DATA_DTO = "demographicDataDTO";
export const PERSON_DATA_DTO = "personDataDTO";
export const APPLICATION_DETAILS_DTO = "applicationDetailsDTO";
export const APPLICATION_META_DATA_DTO = "applicationMetaDataDTO";
export const BIOMETRIC_DATA_DTO = "biometricsDTO";
export const GENERIC_FIELDS_DTO = "genericFieldsDTO";
export const GENERIC_FIELD_DTO_LIST = "genericFieldDTOList";
export const SPOUSE_DATA_DTO = "spouseDataDTO";
export const SPOUSE_DTO = "spouseDTO";
export const PARENT_DTO = "parentDTO";
export const FATHER_DTO = "fatherDTO";
export const MOTHER_DTO = "motherDTO";
export const CONTACT_ADDRESS_DTO = "contactAddressDTO";
export const REASONS_DTO = "reasonsDTO";

export const SUPPORTING_DOCUMENTS_DATA = "supportingDocumentsData";
export const REASON_DATA = "reasonsDTO";
export const PROXY_DATA = "proxyDTO";
export const ACCOUNT_ID = "accountID";
export const APPLICATION_ID = "applicationID";

export function formatFetchedIDCardApplication(serverObject) {
    let formValues = {};
    if (serverObject) {

        assignDemographicData(formValues, serverObject);
        assignApplicationDetails(formValues, serverObject);
        assignAppMetaData(formValues, serverObject);
        assignReasonsDTO(formValues, serverObject);

        assignGenericFieldsData(formValues, serverObject);
    }
    return formValues;
}

function assignDemographicData(formValues, serverObject) {
    if (serverObject) {
        let serverDemographicData = serverObject[DEMOGRAPHIC_DATA_DTO];
        if (serverDemographicData) {
            assignSpouseData(formValues, serverDemographicData);
            assignParentData(formValues, serverDemographicData);
            assignPersonData(formValues, serverDemographicData);
        }
    }
}

function assignApplicationDetails(formValues, serverObject) {
    if (serverObject) {
        let applicationDetailsDTO = serverObject[APPLICATION_DETAILS_DTO];
        if (applicationDetailsDTO) {
            //assignValue(applicationDetailsDTO, "applicationStatus", applicationDetailsDTO.applicationStatus);

            assignValue(formValues, formFieldNames.APPLICATION_ID, applicationDetailsDTO.applicationID);
            assignValue(formValues, formFieldNames.USE_CASE, applicationDetailsDTO.useCase);
            assignValue(formValues, formFieldNames.DOCUMENT_TYPE, applicationDetailsDTO.documentType);

            extractSupportingDocumentList(formValues, applicationDetailsDTO);

            //reason for replace
            let reasonsDTO = applicationDetailsDTO[REASONS_DTO];
            if (reasonsDTO) {
                assignValue(formValues, formFieldNames.REASON_FOR_REPLACE_USE_CASE, reasonsDTO.reasonForReplacement);
            }
        }
    }
}

function assignPersonData(formValues, serverObject) {
    if (serverObject) {
        let personDataDTO = serverObject[PERSON_DATA_DTO];
        if (personDataDTO) {
            assignValue(formValues, formFieldNames.GIVEN_NAME, personDataDTO.givenName);
            assignValue(formValues, formFieldNames.SURNAME, personDataDTO.surname);
            assignValue(formValues, formFieldNames.BIRTH_SURNAME, personDataDTO.birthSurname);
            assignValue(formValues, formFieldNames.SEX, personDataDTO.sex);
            assignValue(formValues, formFieldNames.PLACE_OF_BIRTH_CITY, personDataDTO.placeOfBirthCity);
            assignValue(formValues, formFieldNames.PLACE_OF_BIRTH_COUNTRY, personDataDTO.placeOfBirthCountry);
            assignValue(formValues, formFieldNames.PROVINCE_OF_BIRTH, personDataDTO.placeOfBirthProvince);
            assignValue(formValues, formFieldNames.DISTRICT_OF_BIRTH, personDataDTO.placeOfBirthDistrict);
            assignValue(formValues, formFieldNames.HEIGHT, personDataDTO.height);
            assignValue(formValues, formFieldNames.MARITAL_STATUS, personDataDTO.maritalStatus);
            assignValue(formValues, formFieldNames.PERSONAL_NUMBER, personDataDTO.personalNumber);




            assignValue(formValues, formFieldNames.DATE_OF_BIRTH, formatServerValue(formFieldNames.DATE_OF_BIRTH, personDataDTO.dateOfBirth));

            assignContactData(formValues, personDataDTO);
        }
    }
}

function assignAppMetaData(formValues, serverObject) {
    if (serverObject) {
        let appMetaData = serverObject[APPLICATION_META_DATA_DTO];
        if (appMetaData) {
            assignValue(formValues, formFieldNames.ENROLLMENT_STARTED, appMetaData.enrolmentStarted);
            assignValue(formValues, formFieldNames.CREATED_BY, appMetaData.createdBy);
        }
    }
}

function assignGenericFieldsData(formValues, serverObject) {
    if (serverObject) {
        let genericData = serverObject[GENERIC_FIELDS_DTO];
        if (genericData) {
            let genericFields = genericData[GENERIC_FIELD_DTO_LIST];
            if (genericFields && genericFields.length > 0) {
                genericFields.forEach(field => {
                    assignValue(formValues, field.key, formatServerValue(field.key, field.value));
                })
            }
        }
    }
}

function assignSpouseData(formValues, serverObject) {
    if (serverObject) {
        let spouseData = serverObject[SPOUSE_DTO];
        if (spouseData) {
            assignValue(formValues, formFieldNames.SPOUSE_NAME, spouseData.name);
            assignValue(formValues, formFieldNames.SPOUSE_SURNAME, spouseData.surname);

        }
    }
}

function assignParentData(formValues, serverObject) {
    if (serverObject) {
        let parentData = serverObject[PARENT_DTO];
        if (parentData) {
            assignMotherData(formValues, parentData);
            assignFatherData(formValues, parentData);
        }
    }
}

function assignMotherData(formValues, serverObject) {
    if (serverObject) {
        let motherData = serverObject[MOTHER_DTO];
        if (motherData) {
            assignValue(formValues, formFieldNames.MOTHERS_NAME, motherData.name);
            assignValue(formValues, formFieldNames.MOTHERS_SURNAME, motherData.surname);
        }
    }
}

function assignFatherData(formValues, serverObject) {
    if (serverObject) {
        let fatherData = serverObject[FATHER_DTO];
        if (fatherData) {
            assignValue(formValues, formFieldNames.FATHERS_NAME, fatherData.name);
            assignValue(formValues, formFieldNames.FATHERS_SURNAME, fatherData.surname);
        }
    }
}

function assignContactData(formValues, serverObject) {
    if (serverObject) {
        let contactData = serverObject[CONTACT_ADDRESS_DTO];
        if (contactData) {
            assignValue(formValues, formFieldNames.ADDRESS_COUNTRY, contactData.addressCountry);
            assignValue(formValues, formFieldNames.ADDRESS_PROVINCE, contactData.addressProvince);
            assignValue(formValues, formFieldNames.ADDRESS_POSTAL_CODE, contactData.addressZipCode);
            assignValue(formValues, formFieldNames.ADDRESS_CITY, contactData.addressCity);
            assignValue(formValues, formFieldNames.EMAIL, contactData.email);
            assignValue(formValues, formFieldNames.PHONE_NUMBER, contactData.phone);

            assignValue(formValues, formFieldNames.ADDRESS_DISTRICT, contactData.addressDistrict);
            assignValue(formValues, formFieldNames.ADDRESS_LOCATION, contactData.addressLocation);
            assignValue(formValues, formFieldNames.ADDRESS_STREET_AND_HOUSE_NUMBER, contactData.addressStreetAndHouseNumber);
            assignValue(formValues, formFieldNames.ADDRESS_STREET_NUMBER, contactData.addressStreetNumber);

        }
    }
}

function assignReasonsDTO(formValues, serverObject) {
    if (serverObject) {
        let reasonsDTO = serverObject[REASONS_DTO];
        if (reasonsDTO) {
            assignValue(formValues, formFieldNames.REASON_FOR_REPLACE_USE_CASE, "reasonForReplacement");
        }
    }
}

function extractSupportingDocumentList(formValues, serverObject) {
    if (serverObject) {
        let supDocsData = serverObject[SUPPORTING_DOCUMENTS_DATA];
        if (supDocsData && supDocsData.length > 0) {
            let supportingDocs = [];
            supDocsData.forEach(doc => {
                supportingDocs.push(
                    {type: doc.id},
                    {data: doc.documents})
            });
            assignValue(formValues, SUPPORTING_DOCUMENTS_DATA, formValues[SUPPORTING_DOCUMENTS_DATA]);
        }
    }
}

function formatServerValue(fieldName, value) {
    switch (fieldName) {
        case formFieldNames.DATE_OF_BIRTH:
        case formFieldNames.REPRESENTATIVE_DATE_OF_BIRTH:
        case formFieldNames.IDENTIFICATION_DOCUMENT_DATE_OF_EXPIRY:
            return convertDateFromServerToMoment(value);
        default:
            return value;
    }
}

function assignValue(formValues, fieldName, value) {
    Object.assign(formValues ? formValues : {}, {[fieldName]: value});
}