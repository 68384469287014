import {store} from "../store";
import {addToAppStore} from "@mb-react/mb-react-core/dist";
import {applicationStore} from "../constants/store";
import { I18n } from "react-redux-i18n";

export function reverseString(str){
    return str.split("").reverse().join("");
}

export function delete_cookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function generatePassword(pwLength){
    if(!pwLength || pwLength?.length < 6) pwLength = 6

    let password = [];
    let lowerCase = "abcdefghijklmnopqrstuvwxyz";
    let numbers = "0123456789";
    let symbols = "!@#$%^&*+?></-=";
    let array = [numbers, lowerCase, symbols];

    for (let i = 0; i < pwLength; i++){
        let element = array[randomNumber(0, 3)];
        password.push( element.split('')[randomNumber(0, element.length)] )
    }

    return password.join("");
}

function randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
}

export function addVehicleToAppStore(value){
    store.dispatch(addToAppStore(applicationStore.ADDED_VEHICLES, value));
}

export function addAdditionalVehicleToAppStore(value){
    store.dispatch(addToAppStore(applicationStore.ADDITIONAL_VEHICLES, value));
}

export function checkUserRights(rights, userRights) {
    if (!userRights || userRights?.length === 0)
        return false;

    let hasRights = []

    rights.forEach(e => {
        if (userRights.includes(e)) hasRights.push(e);
    })

    return hasRights.length > 0
}

export const generateID = () => {
    const numbers = "0123456789";
    const idLength = 6;
    let randomID = "";

    for (let i = 0; i < idLength; i++) {
        const randomIndex = Math.floor(Math.random() * numbers.length);
        randomID += numbers[randomIndex];
    }

    return randomID;
};


export const chassisNumberValidation = (chassisNumberValue, chassisLength) => {
    if (chassisNumberValue != undefined) {
      if (chassisLength >= 6 && chassisLength < 17) {
        return I18n.t('message.chassisWarning')
      }
    }
}