import { Button } from "@mb-react/mb-react-core";
import { OverlayPanel } from 'primereact/overlaypanel';
import React, { useRef } from "react";
import { accessibilityIconComponent } from "../../../iconComponents/accessibilityIcon";


const AccessibilityButtonWrap = ({ increaseFontSize, decreaseFontSize, toggleContrast, toggleInvertColors }) => {
    const op = useRef(null);

    return <> <Button icon={accessibilityIconComponent} className="p-button-rounded p-button-text btnAccessabilityIcon" onClick={(e) => op.current.toggle(e)} />
        <OverlayPanel ref={op}>
            <><Button icon="pi pi-plus" onClick={increaseFontSize}>Increase Font </Button>
                <Button icon="pi pi-minus" onClick={decreaseFontSize}>Reset Font </Button>
                <Button icon="pi pi-sort-alt" onClick={toggleInvertColors}>Invert Colors</Button></>
        </OverlayPanel>
    </>;
}



export default AccessibilityButtonWrap;
