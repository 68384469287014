import {ADDRESS_DATA_STEP, APPLICATION_STEPS, PERSONAL_DATA_STEPS, VEHICLE_DATA_STEP, VISA_DATA_STEPS,} from "./store";
//task lists
export const defaultTaskList = [APPLICATION_STEPS.NEW_ID_CARD_INTRO, APPLICATION_STEPS.SUPPORTING_DOCUMENTS, APPLICATION_STEPS.PERSONAL_DATA, APPLICATION_STEPS.VISA_DATA, APPLICATION_STEPS.BIOMETRIC_TASK, APPLICATION_STEPS.DL_TASK, APPLICATION_STEPS.CONFIRMATION_TASK];
export const dlWorkflowTasks = [APPLICATION_STEPS.QUESTIONNAIRE_DATA, APPLICATION_STEPS.PERSONAL_DATA, APPLICATION_STEPS.ADDRESS_DATA, APPLICATION_STEPS.ADDITIONAL_DATA, APPLICATION_STEPS.DL_TASK, APPLICATION_STEPS.CONFIRMATION_TASK];

//step lists
export const defaultPersonalDataSteps = [PERSONAL_DATA_STEPS.NAME_DATA, PERSONAL_DATA_STEPS.BIRTH_DATA, PERSONAL_DATA_STEPS.OTHER_PERSONAL_INFORMATION, PERSONAL_DATA_STEPS.PARENTS_DATA, PERSONAL_DATA_STEPS.MARITAL_DATA, PERSONAL_DATA_STEPS.ADDRESS_DATA, PERSONAL_DATA_STEPS.CONTACT_DATA, PERSONAL_DATA_STEPS.EMPLOYMENT_DATA];
export const defaultVisaDataSteps = [VISA_DATA_STEPS.PASSPORT_DATA, VISA_DATA_STEPS.VISA_DATA];

export const addressDataSteps = [ADDRESS_DATA_STEP.ADDRESS_DATA, ADDRESS_DATA_STEP.RESIDENCE_DATA];

export const vehicleDataSteps = [VEHICLE_DATA_STEP.VEHICLE_DATA, VEHICLE_DATA_STEP.PLATE_DATA];

