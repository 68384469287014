import { useForm } from "react-final-form";
import { ARABIC_UNICODE } from "../constants/translateConstants";
export function useFieldValuesHandler() {
  const form = useForm()

  const changeArabicValueToLatin = (value, dependentField) => {
    let translatedValue = ""
    if (value && value.length > 0) {
      if (arabicNames && arabicNames[value]) {
        translatedValue += arabicNames[value]
      } else {
        Array.from(value).forEach(element => {
          if (ARABIC_UNICODE[element]) {
            translatedValue += ARABIC_UNICODE[element]
          } else {
            translatedValue += element
          }
        });
      }

    } else {
      translatedValue = value
    }
    form.change(dependentField, translatedValue )
  };

  const changeLatinValueToArabic = (value, dependentField) => {
    let translatedValue = "";
    if (value?.length > 0) {
      Array.from(value).forEach(element => {
        let latinValue = Object.keys(ARABIC_UNICODE)?.find(key => ARABIC_UNICODE[key] === element);
        if (latinValue) {
          translatedValue += latinValue
        } else {
          translatedValue += element
        }
      });
    } else {
      translatedValue = ""
    }

    form.change(dependentField, translatedValue )
  };

  return {
    changeArabicValueToLatin: changeArabicValueToLatin,
    changeLatinValueToArabic: changeLatinValueToArabic
  }
}