import {
    addToAppStore,
    completeStep, getFromAppStore,
    hasValidationErrors,
    MBForm,
    NavButtons,
    nextStep
} from "@mb-react/mb-react-core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {APPLICATION_STEPS, applicationStore} from "../../../../../constants/store";
import PersonalInformationTask from "./PersonalInformationTask";
import { usePersonalInformationValidation } from "./personalInformationValidation";
import PreSummaryTask from "./PreSummaryTask";
import {onCancel} from "../../../../../util/componentUtil";
import {disableNextButton, useSkip} from "../../../../../util/businesslogicUtil";
import {ACCOUNT_IDENTITY} from "../../../../../constants/application";
import NavButtonsLocal from "../../../../reactCoreComponents/NavButtonsLocal";

const PreSummaryForm = (props) => {
    const errors = useSelector((state) => hasValidationErrors(state, props.formName));
    const dispatch = useDispatch();
    const personalInformationValidation = usePersonalInformationValidation()
    const identityAdd = useSelector(state => getFromAppStore(state, 'identityAdd'))
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))
    const accountIdentity = useSelector(state => getFromAppStore(state, ACCOUNT_IDENTITY));

    const onSkip = () => {
        dispatch(nextStep(props.navigationName))
    }

    const isFormValid = () => {
        return !errors
    }

    return (
        <MBForm formName={props.formName} stepId={APPLICATION_STEPS.PERSONAL_DATA} showErrorPanel submitFunction={() => {
            if(!identityAdd){
                dispatch(completeStep(APPLICATION_STEPS.PERSONAL_DATA))
                dispatch(nextStep(props.navigationName))
            }
            dispatch(addToAppStore('identityAdd', false))
        }} validate={personalInformationValidation.validate}>
            <PreSummaryTask formName={props.formName} formNameSufix={props.formNameSufix} useCase={props.useCase}/>
            <NavButtonsLocal firstStep={true}
                        customOnSkip={() => onSkip()}
                        allowSkip={useSkip(userRights, errors, accountIdentity)}
                        hideNext={useSkip(userRights, errors, accountIdentity)}
                        disableNextBtn={disableNextButton(isFormValid(), userRights, accountIdentity)}
                        customOnCancel={() => onCancel(dispatch)}/>
        </MBForm>
    );
}

export default PreSummaryForm;