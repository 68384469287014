// ThemeContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCookie, setCookie } from '@mb-react/svarog-ui';

export const ThemeContext = createContext({ isDarkTheme: false, toggleTheme: () => { } });

export const ThemeProvider = ({ children }) => {
    const initialTheme = getCookie('theme') === 'dark';
    const [isDarkTheme, setIsDarkTheme] = useState(initialTheme);

    const toggleTheme = () => {
        const newTheme = !isDarkTheme;
        setIsDarkTheme(newTheme);
        setCookie('theme', newTheme ? 'dark' : '');
    };

    useEffect(() => {
        const handleThemeChange = () => {
            const newCookieTheme = getCookie('theme') === 'dark';
            if (newCookieTheme !== isDarkTheme) {
                setIsDarkTheme(newCookieTheme);
            }
        };

        window.addEventListener('storage', handleThemeChange);

        return () => {
            window.removeEventListener('storage', handleThemeChange);
        };
    }, [isDarkTheme]);

    useEffect(() => {
        const htmlElement = document.documentElement;

        if (isDarkTheme) {
            htmlElement.classList.add('darkTheme');
        } else {
            htmlElement.classList.remove('darkTheme');
        }
    }, [isDarkTheme]);

    return (
        <ThemeContext.Provider value={{ isDarkTheme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => {
    return useContext(ThemeContext);
};
