import React from 'react';
import {forms} from "../../../../../constants/form";
import {MBForm} from "@mb-react/mb-react-core/dist";
import VehicleSearchFields from "./VehicleSearchFields";

function VehicleSearchForm(props) {
  return (
    <MBForm submitFunction={() => {}} formName={forms.VEHICLE_SEARCH_FORM}>
      <VehicleSearchFields/>
    </MBForm>
  );
}

export default VehicleSearchForm;