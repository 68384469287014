import React, { useState, useEffect } from 'react';
import { Button, ConfirmDialog, SimpleTable } from '@mb-react/mb-react-core';
import {
  useCreateServiceWrapper,
  AppointmentService,
  CancelAppointmentRequest,
  GetAppointmentRequest,
  PagedRequest,
  ServiceRequestData,
  TRG,
  getPath,
  useHandleEditAppointmentsSuccessResponse,
  getCookie
} from '@mb-react/svarog-ui';
import { I18n, Translate } from 'react-redux-i18n';
import { i18nl } from '../../../constants/i18n';
import { prepareAppointmentsForTable } from '../../../util/environment';
import AppointmentBookingWorkflow from '../../workflows/appointments/AppointmentBookingWorkflow';
import MyAppointmentsSearch from './MyAppointmentsSearch';
import { EStatusName } from '../../../constants/server';

const MyAppointmentsPreview = () => {
  const [appointmentTableData, setAppontmentTableData] = useState([]);
  const [editAppointmentData, setEditAppointmentData] = useState(false)
  const [visible, setVisible] = useState(false);
  const [appointmentToDelete, setAppointmentToDelete] = useState(null);
  const [appointmentsCount, setAppointmentsCount] = useState(0);

  const callService = useCreateServiceWrapper();
  const useHandleEditAppointmentsSuccess = useHandleEditAppointmentsSuccessResponse();

  const fetchAppointments = (data, page, sizePerPage) => {
    let pagedRequest = new PagedRequest();
    pagedRequest.requestObject = data
    pagedRequest.page = page
    pagedRequest.sizePerPage = sizePerPage

    let request = new ServiceRequestData();
    request.service = AppointmentService.searchAppointments
    request.requestData = pagedRequest
    request.onSuccessLocal = (response) => {
      let data = response.data
      setAppontmentTableData(prepareAppointmentsForTable(data))
      setAppointmentsCount(data.length)
    }

    callService(request)
  }
  const paginationFunc = (page, sizePerPage) => {
    fetchAppointments({}, page + 1, sizePerPage)
  }
  
  useEffect(() => {
    fetchAppointments({}, 1, 10)
  }, [])
  const editAppointment = (appointment) => {
    let getAppointmentRequestObject = new GetAppointmentRequest();
    getAppointmentRequestObject.accountId = getCookie('accountId')
    getAppointmentRequestObject.appointmentId = appointment.appointmentId

    let request = new ServiceRequestData();
    request.service = AppointmentService.getAppointment
    request.requestData = getAppointmentRequestObject
    request.onSuccessGlobalFunc = useHandleEditAppointmentsSuccess
    request.onSuccessLocal = () => {
      setEditAppointmentData(true)
    }

    callService(request)
  }
  const cancelAppointment = (appointmentId) => {
    let cancelAppointmentObject = new CancelAppointmentRequest();
    cancelAppointmentObject.appointmentId = appointmentId
    let request = new ServiceRequestData();
    request.service = AppointmentService.cancelAppointments
    request.requestData = cancelAppointmentObject
    request.onSuccessLocal =() => {
      fetchAppointments({}, 1, 10)
    }
    callService(request)

  }
  const columns = [
    {
      field: 'appointmentId',
      header: <Translate value={'label.' + i18nl.APPOINTMENT_NUMBER} />
    },
    {
      field: 'date',
      header: <Translate value={'label.' + i18nl.APPOINTMENT_DATE} />
    },
    {
      field: 'provider',
      header: <Translate value={'label.' + i18nl.APPOINTMENT_PROVIDER} />
    },
    {
      field: 'service',
      header: <Translate value={'label.' + i18nl.APPOINTMENT_SERVICE} />
    },
    {
      field: 'startTime',
      header: <Translate value={'label.' + i18nl.APPOINTMENT_TIME} />
    },
    {
      field: 'status',
      header: <Translate value={'label.' + i18nl.STATUS} />
    }
  ];
  const accept = () => {
    cancelAppointment(appointmentToDelete)
  }

  const reject = () => {
    setAppointmentToDelete(null)
    setVisible(false)
  }
  const customActionTemplate = (row) => {
    let displayEdit = false;
    let displayDelete = false;
    if (row.status && row.status == EStatusName.SCHEDULED) {
      displayEdit = true;
      displayDelete = true;
    }
    return (
      <div className="actionColumn" style={{ display: "flex" }}>
        {displayEdit && <Button icon="pi pi-pencil" className="p-button-rounded p-button-success" onClick={() => editAppointment(row)} />}
        {displayDelete && <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => {
          setAppointmentToDelete(row.appointmentId)
          setVisible(true)
        }} />}
      </div>
    )

  }
  return (
    <div className='card navigation'>
      <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message={I18n.t(getPath(i18nl.CANCEL_APPOINTMENT, TRG.MESSAGE))}
        header={I18n.t(getPath(i18nl.CANCELATION, TRG.LABEL))} icon="pi pi-exclamation-triangle" accept={accept} reject={reject} />
      {editAppointmentData ? <AppointmentBookingWorkflow /> : <div className="card" style={{ padding: '1rem' }}>
        <h5><Translate value='label.myAppointments' /></h5>
        <MyAppointmentsSearch searchAppointmentsFunc={fetchAppointments} />
        <SimpleTable tableData={appointmentTableData}
          actionTitle={<Translate value='label.actionTitle' />}
          customActionTemplate={customActionTemplate}
          columns={columns}
          count={appointmentsCount}
          paginator={true}
          paginationFunc={paginationFunc}
        />
      </div>}
    </div>
  )
}
export default MyAppointmentsPreview;