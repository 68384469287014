import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { prepareServicesForListBox } from '../../../../util/environment'
import { applicationStore, appStoreFields } from '../../../../constants/store'
import { addToAppStore, getFromAppStore } from '@mb-react/mb-react-core'
import { getPath, i18nl } from '../../../../constants/i18n'
import { Translate } from 'react-redux-i18n'
import PropTypes from 'prop-types'
import { ListBox, Message } from '@mb-react/mb-react-core'
import {AppointmentService, useCreateServiceWrapper, ServiceRequestData, useHandleGetAllServicesSuccessResponse } from '@mb-react/svarog-ui'
const ServiceTask = () => {

  const originalServicesData = useSelector((state) => getFromAppStore(state, appStoreFields.ALL_SERVICES))
  const selectedService = useSelector((state) => getFromAppStore(state, appStoreFields.selectedService))
  const applicationType = useSelector((state) => getFromAppStore(state, applicationStore.WORKFLOW_TYPE))

  const [allServices, setAllServices] = useState(originalServicesData ? prepareServicesForListBox(originalServicesData) : []);
  const [requirements, setRequirements] = useState(null);
  const [selectedServiceState, setSelectedService] = useState(selectedService);

  const dispatch = useDispatch()
  const callService = useCreateServiceWrapper();
  const useHandleGetAllServicesSuccess = useHandleGetAllServicesSuccessResponse();

  useEffect(() => {
    callService(new ServiceRequestData(
      AppointmentService.getServices,
      null,
      useHandleGetAllServicesSuccess,
      null,
      (response) => {
        setAllServices(response && response.data ? prepareServicesForListBox(response.data) : [])
      },
      null
  ))

  }, [])
  useEffect(() => {
    dispatch(addToAppStore(appStoreFields.selectedService, selectedServiceState))
    dispatch(addToAppStore(appStoreFields.selectedProvider, ''))
    dispatch(addToAppStore(appStoreFields.PROVIDERS_FOR_LOCATION_SERVICE, []))
    setRequirements(originalServicesData && originalServicesData.length > 0 && selectedServiceState ? originalServicesData.filter(x => x.id == selectedServiceState.code)[0].requirements : null)
  }, [selectedServiceState])
  return (
    <div className="card" style={{ padding: '1rem' }} >
      <h5><Translate value={getPath(i18nl.SELECT_APPLICATION_PROCESSING_STEP)} /></h5>
      <ListBox value={selectedServiceState} options={allServices} onChange={(e) => setSelectedService(e.value)} optionLabel="name" />
      {
        requirements &&
        <div className="card border-none">
          <h5><Translate value={getPath(i18nl.REQUIREMENTS)} /></h5>
          <Message severity="info" text={requirements} />
        </div>
      }
    </div>

  )
}
ServiceTask.propTypes = {
  dispatch: PropTypes.any,
  allServices: PropTypes.any,
  selectedService: PropTypes.any,
  selectedProvider: PropTypes.any
}
export default ServiceTask
