import React, {useEffect, useState} from 'react';
import {addToAppStore, Button, getFromAppStore, removeFromAppStore, SimpleTable} from "@mb-react/mb-react-core/dist";
import {useDispatch, useSelector} from "react-redux";
import {applicationStore} from "../../../../../constants/store";
import {isEntityAlreadyAddedInTable, returnLegalEntityMetaDataFromDTOForTable} from "../../../../../util/tableUtil";
import { I18n } from "react-redux-i18n";
import {formFieldNames, EDocumentType} from '@mb-react/svarog-ui';
import {BUYER, SELLER} from "../../../../../constants/general";
import {
    extractGenericField,
    prepareApplicantDTOforStore,
    VRcheckStateHandling
} from "../../../../../util/businesslogicUtil";
import {generateID} from "../../../../../util/util";
import InterfaceAdapterService from "../../../../../services/interfaceAdapter/InterfaceAdapterService";
import {entityTypes, forms} from "../../../../../constants/form";
import {getFormValues, hideSpinner, showToast, toastIDs} from "@mb-react/mb-react-core";

function SearchLegalEntityTableResult(props) {
    const dispatch = useDispatch();
    const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION));
    const {[formFieldNames.DOCUMENT_TYPE]: documentType, [formFieldNames.USE_CASE]: useCase} = formValues;
    const legalEntitiesData = useSelector((state) => getFromAppStore(state, applicationStore.LEGAL_ENTITY_DATA));
    const selectedPerson = useSelector((state) => getFromAppStore(state, applicationStore.SELECTED_PERSON));
    const [selectedRow, setSelectedRow] = useState();
    const [tableData, setTableData] = useState([]);
    const [tableCount, setTableCount] = useState(1);
    const listOfBuyers = useSelector((state) => getFromAppStore(state, 'listOfBuyers'))
    const listOfSellers = useSelector((state) => getFromAppStore(state, 'listOfSellers'));
    const totalPercentageSellers = useSelector((state) => getFromAppStore(state, 'totalPercentageSellers'));
    const totalPercentageBuyers = useSelector((state) => getFromAppStore(state, 'totalPercentageBuyers'))

    let columns = [
        {field: "id", hidden: true},
        {
            field: formFieldNames.LE_NAME,
            header: I18n.t('label.' + formFieldNames.LE_NAME)
        },
        {
            field: formFieldNames.LE_TYPE,
            header: I18n.t('label.' + formFieldNames.LE_TYPE)
        },
        {
            field: formFieldNames.TPID,
            header: I18n.t('label.' + formFieldNames.TPID)
        },
        {
            field: formFieldNames.TPID_PARENT,
            header: I18n.t('label.' + formFieldNames.TPID_PARENT)
        }
    ];

    const handleSelectionChange = (e) => {
        setSelectedRow(e.value);

        if (e.value) {
            dispatch(addToAppStore(applicationStore.SELECTED_PERSON, legalEntitiesData[e.value.id]))
        } else {
            dispatch(removeFromAppStore(applicationStore.SELECTED_PERSON))
        }
    };

    useEffect(() => {
        if (legalEntitiesData != null) {
            let tableData = returnLegalEntityMetaDataFromDTOForTable(legalEntitiesData);
            setTableData(tableData);
        } else {
            setTableData([]);
            setSelectedRow();
        }
    }, [legalEntitiesData]);

    const addSelectedLegalEntity = async () => {
        if(!selectedPerson) return false;

        const legalEntityStates = extractGenericField(selectedPerson?.genericFieldsDTO.genericFieldDTOList, "legalEntityStates");

        if (legalEntityStates?.length > 0) {
            try{
                if(documentType === EDocumentType.VR){
                    await VRcheckStateHandling(InterfaceAdapterService, dispatch, entityTypes.LEGAL_ENTITY, useCase, legalEntityStates?.map(state => state?.stateName))
                }
            }catch(e){
                dispatch(hideSpinner())
                console.log(e)
                if(e){
                    dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {
                        messageId: I18n.t(e)
                    }))
                }
                return;
            }
        }

        let list = [];
        if (listOfSellers && props.role === SELLER) list = [...listOfSellers]
        if (listOfBuyers && props.role === BUYER) list = [...listOfBuyers]

        if(isEntityAlreadyAddedInTable(selectedPerson, list))
            return false;

        const bindObject = {
            id: generateID(),
            lead: false,
            percentage: 0
        };

        let store = "selectedPersonForDL";
        let preparedEntity = prepareApplicantDTOforStore(selectedPerson);

        if (props.role === BUYER) {
            preparedEntity.buyerId = bindObject.id
            totalPercentageBuyers.push(bindObject)
            store = "listOfBuyers";
        } else if (props.role === SELLER) {
            preparedEntity.sellerId = bindObject.id
            totalPercentageSellers.push(bindObject)
            store = "listOfSellers";
        }
        if (props.role) preparedEntity = [...list, preparedEntity]

        dispatch(addToAppStore(store, preparedEntity))
    }

    return (
        <>
            <div className="col-lg-12">
                <SimpleTable
                    tableData={tableData}
                    actionTitle=""
                    columns={columns}
                    count={tableCount}
                    tableProps={{
                        dataKey: "id",
                        emptyMessage: I18n.t("message.noResultsFound"),
                        metaKeySelection: false,
                        selectionMode: "single",
                        selection: selectedRow,
                        responsiveLayout: "scroll",
                        onSelectionChange: (e) => {
                            handleSelectionChange(e)
                        }
                    }}
                />
                <Button label={I18n.t("label.addLegalEntity")}
                        icon="pi pi-plus-circle"
                        className="p-button-rounded mt-4 mb-5"
                        disabled={!selectedRow}
                        onClick={addSelectedLegalEntity}/>
            </div>
        </>
    );
}

export default SearchLegalEntityTableResult;