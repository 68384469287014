import React from "react";
import ContainerHOC from "../../../ContainerHOC";
import ApplicationReport from "../../myApplications/ApplicationReport/ApplicationReport";

const PrintApplicationReport = () => {

  return (
    <ContainerHOC fullPage>
      <div className='card navigation'>
        <div className="card" style={{ padding: '1rem' }}>
            <div>
                <>
                    <ApplicationReport />
                </>
            </div>
        </div>
      </div>
    </ContainerHOC>
  );
};

export default PrintApplicationReport;
