import {getFromAppStore, Dialog, SimpleTable} from '@mb-react/mb-react-core';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, closeModal} from '@mb-react/mb-bootstrap-core';
import {I18n, Translate} from "react-redux-i18n";
import {accountStore} from "../../../../constants/store";
import {getPath, i18nl, TRG} from "../../../../constants/i18n";
import {ids} from "../../../../constants/modal";


const ProcessHistory = () => {

    let dispatch = useDispatch();
    const personData = useSelector(state => getFromAppStore(state, accountStore.ACCOUNT_DATA)) || []
    const modalReducer = useSelector((state) => state.mbUIReducer)
    const [showDialog, setShowDialog] = useState(false)

    console.log("personData -> ", personData)

    useEffect(() => {
        setShowDialog(modalReducer.activeModals.findIndex(modal => modal.id === ids.PROCESS_HISTORY) !== -1)
    }, [modalReducer])

    const HISTORY_HEADERS = [
        {
            field: 'created',
            header: I18n.t(getPath(i18nl.CREATED))
        },
        {
            field: 'updated',
            header: I18n.t(getPath(i18nl.UPDATED))
        },
        {
            field: 'sourceStatus',
            header: I18n.t(getPath(i18nl.SOURCE_STATUS))
        },
        {
            field: 'destinationStatus',
            header: I18n.t(getPath(i18nl.DESTINATION_STATUS))
        },
        {
            field: 'transitionName',
            header: I18n.t(getPath(i18nl.TRANSITION_NAME))
        },
        {
            field: 'transitionComments',
            header: I18n.t(getPath(i18nl.TRANSITION_COMMENTS))
        }
    ];

    const onClose = () => {
        dispatch(closeModal(ids.PROCESS_HISTORY));
    }

    const footer = <Button label="action.close" onClick={() => onClose()} className="p-button-text"/>

    return (
        <Dialog header={<Translate value={getPath(i18nl.INFO, TRG.LABEL)} />} visible={showDialog} style={{ width: '50vw' }} footer={footer} onHide={() => onClose()}>
            {personData && personData.accountProcessHistoryDTOS && <SimpleTable tableData={personData.accountProcessHistoryDTOS}
                         actionTitle={I18n.t("label.viewAccount")}
                         columns={HISTORY_HEADERS}
                         count={(personData && personData.accountProcessHistoryDTOS) ? personData.accountProcessHistoryDTOS.length : 0}
                         onViewIcon="pi pi-calendar-plus"
            />}
        </Dialog>
    );
}
export default ProcessHistory;