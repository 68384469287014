import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {applicationStore} from "../../../../../constants/store";
import {
    addMultipleInitialValuesToForm,
    getFormValues,
    getFromAppStore, hideSpinner, showSpinner,
    showToast,
    toastIDs
} from "@mb-react/mb-react-core";
import {I18n} from "react-redux-i18n";
import {addToAppStore, Button, removeFromAppStore, SimpleTable} from "@mb-react/mb-react-core/dist";
import {returnVehicleMetaDataFromDTOForTable} from "../../../../../util/tableUtil";
import {EUseCases, formFieldNames} from '@mb-react/svarog-ui';
import {
    checkIfVehicleExistsInVehicleService,
    extractGenericField,
    isModificationVRUseCase, isOperationModeProtected, isUseCaseChangeOwnership,
    isUseCaseExchangeRegistration, plateHandlingFurtherUseUseCaseList,
    prepareApplicantDTOforStore, prepareModificationFieldFlags, saveVehicleInVehicleService, VRcheckStateHandling
} from "../../../../../util/businesslogicUtil";
import {entityTypes, forms} from "../../../../../constants/form";
import {extractObjectBasedOnKeysList} from "../../../../../util/dataExtractorUtil";
import {plateDataList} from "../../../../../constants/wpFormFieldList";
import {useHandleCommonServices} from "../../../../../services/commonServiceHandler";
import {generateID} from "../../../../../util/util";
import {cleanObject, vehicleFormValuesObject} from "../../../../../util/formUtils";
import {changeOwnershipSubUseCases} from "../../../../../constants/application";
import InterfaceAdapterService from "../../../../../services/interfaceAdapter/InterfaceAdapterService";
import VehicleService from "../../../../../services/vehicle/VehicleService";

function VehicleTableResult(props) {
    const dispatch = useDispatch();
    const vehicleFormValues = useSelector((state) => getFormValues(state, forms.VEHICLE_SEARCH_FORM));
    const vehiclesData = useSelector(state => getFromAppStore(state, applicationStore.VEHICLES_DATA))
    const vehiclesDataCount = useSelector(state => getFromAppStore(state, applicationStore.VEHICLES_DATA_COUNT))
    const addedVehicles = useSelector(state => getFromAppStore(state, applicationStore.ADDED_VEHICLES))
    const additionalVehicles = useSelector(state => getFromAppStore(state, applicationStore.ADDITIONAL_VEHICLES))
    const addedSdmsVehicles = useSelector(state => getFromAppStore(state, applicationStore.ADDED_SDMS_VEHICLES))
    const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION));
    const {[formFieldNames.USE_CASE]: useCase} = formValues;
    const [selectedRow, setSelectedRow] = useState();
    const [isAddVehicleDisabled, setIsAddVehicleDisabled] = useState(false);
    const handleCommonServices = useHandleCommonServices();

    const columns = [
        {field: "id", hidden: true},
        {
            field: formFieldNames.VEHICLE_ID,
            header: I18n.t('label.' + formFieldNames.VEHICLE_ID)
        },
        {
            field: formFieldNames.CHASSIS_NUMBER,
            header: I18n.t('label.' + formFieldNames.CHASSIS_NUMBER)
        },
        {
            field: formFieldNames.PLATE_NUMBER_LATIN,
            header: I18n.t('label.' + formFieldNames.PLATE_NUMBER_LATIN)
        }
    ];

    const handleSelectionChange = (e) => {
        setSelectedRow(e.value);

        if (e.value) {
            dispatch(addToAppStore(applicationStore.SELECTED_VEHICLE_SEARCH_TABLE, vehiclesData[e.value.id]))
        } else {
            dispatch(removeFromAppStore(applicationStore.SELECTED_VEHICLE_SEARCH_TABLE))
        }
    };

    let tableData = [];
    if (vehiclesData != null) {
        tableData = returnVehicleMetaDataFromDTOForTable(vehiclesData);
    }

    const addVehicle = async () => {

        if (vehiclesData[selectedRow.id] && vehiclesData[selectedRow.id]?.genericFieldsDTO?.genericFieldDTOList?.length > 0) {
            const vehicleState = extractGenericField(vehiclesData[selectedRow.id].genericFieldsDTO.genericFieldDTOList, "vehicleStates");

            if (vehicleState?.length > 0) {
              try{
                  await VRcheckStateHandling(InterfaceAdapterService, dispatch, entityTypes.VEHICLE, useCase, vehicleState?.map(state => state?.stateName))
              }catch(e){
                dispatch(hideSpinner())
                if(e){
                  dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {
                    messageId: I18n.t(e)
                  }))
                }
                return;
              }
            }
        }

        if (props.additionalVehicle && isUseCaseExchangeRegistration(formValues)) {

            if (isOperationModeProtected() && vehiclesData[selectedRow.id]?.chassisNumber) {
                try {
                    let vehicleExistsInVehicleService = await checkIfVehicleExistsInVehicleService(dispatch, VehicleService, vehiclesData[selectedRow.id].chassisNumber)
                    if (!vehicleExistsInVehicleService) {
                        await saveVehicleInVehicleService(dispatch, VehicleService, vehiclesData[selectedRow.id])
                    }
                } catch (e) {
                    dispatch(hideSpinner())
                    dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {messageId: e}))
                }
            }

            dispatch(addToAppStore(applicationStore.ADDITIONAL_VEHICLES, [...additionalVehicles, vehiclesData[selectedRow.id]]));
            dispatch(addToAppStore(applicationStore.SELECTED_ADDITIONAL_VEHICLE, vehiclesData[selectedRow.id]))
            dispatch(addToAppStore(applicationStore.SHOW_ADD_VEHICLE_FORM, false))
        } else {
            dispatch(addToAppStore(applicationStore.ADDED_VEHICLES_FROM_SDMS, true))
            dispatch(addToAppStore(applicationStore.ADDED_VEHICLES, [...addedVehicles, vehiclesData[selectedRow.id]]))
            dispatch(addToAppStore(applicationStore.ADDED_SDMS_VEHICLES, [...addedSdmsVehicles, vehiclesData[selectedRow.id]]))
            dispatch(addToAppStore(applicationStore.SELECTED_VEHICLE, vehiclesData[selectedRow.id]))
            dispatch(addToAppStore(applicationStore.SHOW_ADD_VEHICLE_FORM, false))
            if (vehiclesData[selectedRow.id] && vehiclesData[selectedRow.id]?.plateDTOList?.length > 0) {
                let plateData = extractObjectBasedOnKeysList(vehiclesData[selectedRow.id].plateDTOList[0], plateDataList)
                dispatch(addMultipleInitialValuesToForm(forms.PLATE_DATA, plateData))
                if (plateHandlingFurtherUseUseCaseList.includes(formValues[formFieldNames.USE_CASE])) {
                    dispatch(addToAppStore(applicationStore.PLATE_DATA_HELPER, plateData))
                }
                if (isModificationVRUseCase(formValues)) {
                    prepareModificationFieldFlags(dispatch, {...formValues, ...plateData})
                }
            }

            if (vehiclesData[selectedRow.id] && vehiclesData[selectedRow.id]?.[formFieldNames.VEHICLE_ID]) {
                handleCommonServices.handleCallGetOwners({[formFieldNames.VEHICLE_ID]: vehiclesData[selectedRow.id]?.[formFieldNames.VEHICLE_ID]}, onSuccessLocal, onErrorLocal)
            } else {
                dispatch(showToast(toastIDs.MESSAGE_TOAST, 'warn', {
                    messageId: "error.chassesNumberMissing"
                }))
            }
        }
    };

    const onSuccessLocal = (response) => {
        if (response && response.length > 0) {

            let totalPercentageSellers = [];

            let listOfOwners = response.map((e) => {
                let owner = prepareApplicantDTOforStore(e);

                const bindObject = {
                    id: generateID(),
                    lead: false,
                    percentage: 0
                };

                owner.sellerId = bindObject.id;

                if (e?.ownershipDTO) {
                    let ownership = e.ownershipDTO;
                    if (ownership.percent) {
                        bindObject.percentage = ownership.percent
                    }
                    if (ownership.lead) {
                        bindObject.lead = ownership.lead
                    }
                }

                totalPercentageSellers.push(bindObject)

                return owner
            })
            if(useCase === EUseCases.CHANGE_OWNERSHIP){
                dispatch(addToAppStore("countOfFetchedOwners", listOfOwners?.length))
            }
            dispatch(addToAppStore("listOfSellers", listOfOwners))
            dispatch(addToAppStore('totalPercentageSellers', totalPercentageSellers))

        }
    }

    const onErrorLocal = (response) => {
        console.log("Do something on error ", response)
    }

    useEffect(() => {
        checkIsAddVehicleDisabled();
    }, [addedVehicles?.length, selectedRow])

    const checkIsAddVehicleDisabled = () => {
        if (!selectedRow ||
            (isUseCaseExchangeRegistration(formValues) && addedVehicles?.length === 2) ||
            (!isUseCaseExchangeRegistration(formValues) && addedVehicles?.length === 1)) {
            setIsAddVehicleDisabled(true);
            return;
        }
        setIsAddVehicleDisabled(false);
    }

    const searchVehicleHandler = (pageNumber, pageSize) => {
        dispatch(showSpinner(I18n.t('message.searchVehicle')))
        let requestData = cleanObject(vehicleFormValuesObject(vehicleFormValues));
        requestData.pageNumber = pageNumber
        requestData.pageSize = pageSize
        handleCommonServices.handleSearchVehicles(requestData, () => {
            dispatch(addToAppStore(applicationStore.VEHICLES_DATA, null));
            dispatch(hideSpinner())
        })
    };

    return (
        <div className="col-lg-12 mb-5">
            <SimpleTable
                tableData={tableData}
                actionTitle=""
                columns={columns}
                count={vehiclesDataCount ? vehiclesDataCount : 0}
                paginator={true}
                paginationFunc={searchVehicleHandler}
                tableProps={{
                    dataKey: "id",
                    emptyMessage: I18n.t("message.noResultsFound"),
                    metaKeySelection: false,
                    selectionMode: "single",
                    selection: selectedRow,
                    responsiveLayout: "scroll",
                    onSelectionChange: (e) => {
                        handleSelectionChange(e)
                    }
                }}
            />
            <div className={"pt-5 mb-5"}>
                <Button disabled={isAddVehicleDisabled} type="button" label={I18n.t('action.addVehicle')}
                        className="p-button-success"
                        icon="pi pi-plus" onClick={addVehicle}/>
            </div>
            <hr/>
        </div>
    );
}

export default VehicleTableResult;
