import {
    addInitialValueToForm,
    addToAppStore, Button,
    CommonModals, Dialog,
    getFormValues, getFromAppStore,
    locals, Message
} from '@mb-react/mb-react-core';
import { getCookie } from "@mb-react/svarog-ui";
import { ScrollTop } from "primereact/scrolltop";
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {I18n, loadTranslations, setLocale} from 'react-redux-i18n';
import i18nApi from '../api/i18n';
import { forms } from '../constants/form';
import { applicationStore } from '../constants/store';
import '../stylesheets/template.scss';
import { useContainerController } from './containerController';
import './shared/fontawesome';
import Footer from "./shared/global/Footer";
import DevMenu from './shared/global/menu/DevMenu';
import MenubarComponent from './shared/global/menu/MenuBar';
import TopHeaderLine from './shared/global/menu/TopHeaderLine';
import { ThemeProvider } from './shared/global/ThemeContext';
import useSize from './shared/global/UseSize';
import SplashScreen from './tasks/SplashScreen';
import {getPath, i18nl, TRG} from "../constants/i18n";
import {ROOT} from "../constants/environment";
import {isOperationModeProtected} from "../util/businesslogicUtil";


const ContainerHOC = (props) => {
    const dispatch = useDispatch();
    const targetRef = useRef();
    const mainWrapSize = useSize(targetRef);
    const useApplyWithoutAccount = useSelector((state) => getFormValues(state, forms.APPLICATION)?.["useApplyWithoutAccount"])
    const [preferences, setPreferences] = useState({
        contrast: 'normal',
        invertColors: false,
    });

    const [footerPosition, setFooterPosition] = useState('relative');
    const [footerPositionBottom, setFooterPositionBottom] = useState(0);
    const [footerPositionWidth, setFooterPositionWidth] = useState('100%');
    const showCancelModal = useSelector(state => getFromAppStore(state, applicationStore.SHOW_CANCEL_MODAL))

    dispatch(addToAppStore(applicationStore.MAIN_WRAP_REF, mainWrapSize))

    useLayoutEffect(() => {

        if (mainWrapSize && mainWrapSize.height < 890) {
            setFooterPosition('fixed');
            setFooterPositionBottom(0);
            setFooterPositionWidth('100%');
        } else {
            setFooterPosition('relative');
            setFooterPositionBottom(0);
            setFooterPositionWidth('100%');
        }
    }, [mainWrapSize]);


    const [fontSize, setFontSize] = useState(22);
    const [bigFontSize, setBigFontSize] = useState("");
    const MAX_FONT_SIZE = 32;
    const MIN_FONT_SIZE = 12;

    const [containerHeight, setContainerHeight] = useState(0);
    function increaseFontSize() {

        if (fontSize < MAX_FONT_SIZE) {
            setFontSize(MAX_FONT_SIZE);
            setBigFontSize("bigFontSize");
        }
    }
    function decreaseFontSize() {
        if (fontSize <= MAX_FONT_SIZE && fontSize >= MIN_FONT_SIZE) {
            setFontSize(22);
            setBigFontSize("");
        }
    }

    function toggleContrast() {
        setPreferences({
            ...preferences,
            contrast: preferences.contrast === 'normal' ? 'high' : 'normal',
        });
    }

    function toggleInvertColors() {
        setPreferences({
            ...preferences,
            invertColors: !preferences.invertColors,
        });
    }

    useEffect(() => {
        // Disable console.log in production
        if (process.env.NODE_ENV === 'production') console.log = () => { };

        // Check if useApplyWithoutAccount is not set
        if (useApplyWithoutAccount == null) {
            // Set the initial value for useApplyWithoutAccount in the Redux form
            dispatch(addInitialValueToForm(forms.APPLICATION, "useApplyWithoutAccount", false));
        }

        // Load translations using the i18n API
        dispatch(loadTranslations(i18nApi.fetchI18n()));

        // Get the language from the cookie
        let lang = getCookie("lang");

        // Check if the language is one of the supported locales
        if ([locals.EN, locals.AR, locals.KR].includes(lang)) {
            // Set the locale in the Redux store
            dispatch(setLocale(lang));
        } else {
            // Set the default locale to KR if the language is not supported
            dispatch(setLocale(locals.KR));
        }

        const invertColors = preferences.invertColors ? 'invert(1)' : 'none';
        document.documentElement.style.filter = invertColors;

    }, [preferences.invertColors]);


    const containerController = useContainerController()

    const { fullPage, leftSideContent, rightSideContent, children, cssClass } = props;
    let contentClass = "col-12 md:col-8"
    if (fullPage) {
        contentClass = "col-12 md:col-12"
    }
    if (cssClass) {
        contentClass = "col-12 md:col-12 conditionalForFooter"
    }
    let accessibilityMethods = {
        increaseFontSize,
        decreaseFontSize,
        toggleContrast,
        toggleInvertColors
    }

    const renderCancelModalFooter = () => {
        return (
            <div>
                <Button
                    label={I18n.t("action.yes")}
                    onClick={() => {
                        dispatch(addToAppStore(applicationStore.SHOW_CANCEL_MODAL, false))
                        window.location = isOperationModeProtected() ? ROOT + "/admin" : "/"
                    }}
                    className="p-button-danger"
                />
                <Button
                    label={I18n.t("action.no")}
                    onClick={() => {
                        dispatch(addToAppStore(applicationStore.SHOW_CANCEL_MODAL, false))
                    }}
                    className="p-button"
                />
            </div>
        );
    };

    return (
        <ThemeProvider>
            <div id="mainWrap" className={`
            ${bigFontSize}`} ref={targetRef} style={{
                    fontSize: fontSize,
                }}>
                {configurationData.DevMenu && <DevMenu />}
                <CommonModals />
                <Dialog header={I18n.t(getPath(i18nl.INTERRUPT_WORKFLOW, TRG.MESSAGE))} visible={showCancelModal}
                        style={{width: '54vw'}} footer={renderCancelModalFooter}>
                    <Message severity="warn" text={I18n.t("message.cancelApplicationWorkflow")}/>
                </Dialog>
                <TopHeaderLine {...accessibilityMethods} />
                <MenubarComponent />
                <ScrollTop
                    threshold={100}
                    className="custom-scrolltop"
                    icon="pi pi-arrow-up"
                />
                <div className="grid home">
                    {!fullPage && <div className="col-12 md:col-2">
                        {leftSideContent}
                    </div>}
                    <div className={contentClass}>
                        {containerController.showSplashScreen
                            ? <SplashScreen />
                            : children

                        }
                    </div>
                    {!fullPage && <div className="col-12 md:col-2">
                        {rightSideContent}
                    </div>}
                    <footer style={{ position: footerPosition, bottom: footerPositionBottom, width: footerPositionWidth }}>
                        <Footer />
                    </footer>
                </div>
            </div>
        </ThemeProvider>

    )

};

ContainerHOC.propTypes = {
    dispatch: PropTypes.any,
    children: PropTypes.any,
};
const mapStateToProps = (state) => {
    return {
        locale: state.i18n.locale,
    };
};
export default connect(mapStateToProps)(ContainerHOC);
