import moment from 'moment';
import { formatDate } from '../../../../../util/dateUtil';
import { formFieldNames, isRequired } from '@mb-react/svarog-ui';
import { genericFormFieldNames } from '@mb-react/krg-getid-form-data-common';
import { EMAIL_REGEX, PHONE_REGEX, forms } from "../../../../../constants/form"
import { useGlobalValidation } from '../../../../../hooks/useGlobalValidation';
import { validatePercentage } from '../../../../../util/formUtils';
import { I18n } from 'react-redux-i18n';

export const usePersonalInformationValidation = (values) => {

    const globalValidation = useGlobalValidation()
    const validate = (values) => {
        const errors = {};
        if (!values) {
            errors.validation = 'Form values undefine!'
        } else {
            let dateRegex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
            let today = new Date();
            let currentDate = today.getDate() + "-" + parseInt(today.getMonth() + 1) + "-" + today.getFullYear();
            let dateBefore125years = moment(currentDate, "DD/MM/YYYY").subtract(125, 'years');

            if (globalValidation.isMandatory(values, formFieldNames.GIVEN_NAME)) {
                isRequired(values, formFieldNames.GIVEN_NAME, errors);

            }
            if (globalValidation.isMandatory(values, formFieldNames.GIVEN_NAME_LATIN)) {
                isRequired(values, formFieldNames.GIVEN_NAME_LATIN, errors);

            }
            if (globalValidation.isMandatory(values, formFieldNames.SURNAME)) {
                isRequired(values, formFieldNames.SURNAME, errors);

            }
            if (globalValidation.isMandatory(values, formFieldNames.SURNAME_LATIN)) {
                isRequired(values, formFieldNames.SURNAME_LATIN, errors);
            }

            if (globalValidation.isMandatory(values, formFieldNames.DATE_OF_BIRTH)) {
                isRequired(values, formFieldNames.DATE_OF_BIRTH, errors);

            }
            if (globalValidation.isMandatory(values, formFieldNames.GENDER)) {
                isRequired(values, formFieldNames.GENDER, errors);

            }
            if (globalValidation.isMandatory(values, formFieldNames.PERSONAL_NUMBER)) {
                isRequired(values, formFieldNames.PERSONAL_NUMBER, errors);

            }
            if (globalValidation.isMandatory(values, formFieldNames.NATIONALITY)) {
                isRequired(values, formFieldNames.NATIONALITY, errors);

            }
            if (globalValidation.isMandatory(values, formFieldNames.BLOOD_GROUP)) {
                isRequired(values, formFieldNames.BLOOD_GROUP, errors);

            }
            if (globalValidation.isMandatory(values, formFieldNames.TPID)) {
                isRequired(values, formFieldNames.TPID, errors);

            }
            if (globalValidation.isMandatory(values, formFieldNames.TAX_NUMBER)) {
                isRequired(values, formFieldNames.TAX_NUMBER, errors);

            }
            let dateOfBirth = formatDate(values[formFieldNames.DATE_OF_BIRTH]);

            if (!dateOfBirth) {
                if (dateOfBirth && !dateOfBirth.match(dateRegex) ||
                    !moment(dateOfBirth, "DD/MM/YYYY").isBetween(dateBefore125years, moment(currentDate, "DD/MM/YYYY"))) {
                    errors[formFieldNames.DATE_OF_BIRTH] = "dateOfBirthInvalid";
                }
            }
            if (globalValidation.isMandatory(values, formFieldNames.PLACE_OF_BIRTH_CITY)) {
                isRequired(values, formFieldNames.PLACE_OF_BIRTH_CITY, errors);

            }
            if (globalValidation.isMandatory(values, formFieldNames.PLACE_OF_BIRTH_COUNTRY)) {
                isRequired(values, formFieldNames.PLACE_OF_BIRTH_COUNTRY, errors);

            }
            if (globalValidation.isMandatory(values, formFieldNames.PLACE_OF_BIRTH_PROVINCE)) {
                isRequired(values, formFieldNames.PLACE_OF_BIRTH_PROVINCE, errors);

            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.PLACE_OF_BIRTH_VILLAGE)) {
                isRequired(values, genericFormFieldNames.PLACE_OF_BIRTH_VILLAGE, errors);

            }
            if (globalValidation.isMandatory(values, formFieldNames.POSITION)) {
                isRequired(values, formFieldNames.POSITION, errors);

            }
            if (globalValidation.isMandatory(values, formFieldNames.EMPLOYER_OR_SCHOOL)) {
                isRequired(values, formFieldNames.EMPLOYER_OR_SCHOOL, errors);
            }
            if (globalValidation.isMandatory(values, formFieldNames.WORK_PHONE_NUMBER)) {
                isRequired(values, formFieldNames.WORK_PHONE_NUMBER, errors);

                if (values && values[formFieldNames.WORK_PHONE_NUMBER] && !values[formFieldNames.WORK_PHONE_NUMBER].match(PHONE_REGEX)) {
                    errors[formFieldNames.WORK_PHONE_NUMBER] = "wrongPhoneFormat";
                }
            }
            if (globalValidation.isMandatory(values, formFieldNames.EMAIL)) {
                isRequired(values, formFieldNames.EMAIL, errors);

                if (values && values[formFieldNames.EMAIL] && !values[formFieldNames.EMAIL].match(EMAIL_REGEX)) {
                    errors[formFieldNames.EMAIL] = "wrongPhoneFormat";
                }
            }

            //family data validation
            if (globalValidation.isMandatory(values, formFieldNames.FATHERS_NAME)) {
                isRequired(values, formFieldNames.FATHERS_NAME, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.FATHER_NAME_LATIN)) {
                isRequired(values, genericFormFieldNames.FATHER_NAME_LATIN, errors);
            }
            if (globalValidation.isMandatory(values, formFieldNames.MOTHERS_NAME)) {
                isRequired(values, formFieldNames.MOTHERS_NAME, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.MOTHERS_FATHER_NAME)) {
                isRequired(values, genericFormFieldNames.MOTHERS_FATHER_NAME, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.GRANDFATHER_NAME)) {
                isRequired(values, genericFormFieldNames.GRANDFATHER_NAME, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.GRANDFATHER_NAME_LATIN)) {
                isRequired(values, genericFormFieldNames.GRANDFATHER_NAME_LATIN, errors);
            }
            if (globalValidation.isMandatory(values, formFieldNames.MARITAL_STATUS)) {
                isRequired(values, formFieldNames.MARITAL_STATUS, errors);
            }
            if (globalValidation.isMandatory(values, formFieldNames.SPOUSE_NAME)) {
                isRequired(values, formFieldNames.SPOUSE_NAME, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.SPOUSE_FATHER_NAME)) {
                isRequired(values, genericFormFieldNames.SPOUSE_FATHER_NAME, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.SPOUSE_GRANDFATHER_NAME)) {
                isRequired(values, genericFormFieldNames.SPOUSE_GRANDFATHER_NAME, errors);
            }
            if (values[formFieldNames.OWNERSHIP_PERCENTAGE]) {
                const totalPercentage = parseInt(values[formFieldNames.OWNERSHIP_PERCENTAGE], 10);
            
                if (!validatePercentage && values[formFieldNames.OWNERSHIP_PERCENTAGE] || isNaN(totalPercentage)) {
                    errors[formFieldNames.OWNERSHIP_PERCENTAGE] = I18n.t("err.invalidPercentage");
                }
            }
        }

        return errors;
    }
    return {validate}
};
