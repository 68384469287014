import {getFormValues, getFromAppStore} from "@mb-react/mb-react-core";
import { EUseCases } from "@mb-react/svarog-ui";
import { useSelector } from "react-redux";
import { isLoggedAccountLegalEntity, isOperationModeProtected } from "../util/businesslogicUtil";
import { changeOwnershipSubUseCases } from "./application";
import { forms } from "./form";
import {APPLICATION_STEPS, applicationStore} from "./store";
import {MODIFY_PROVINCE_RIGHTS} from "./admin/rights";
import {checkUserRights} from "../util/util";


export const useCaseNew = [
    APPLICATION_STEPS.VEHICLE_DATA,
    APPLICATION_STEPS.PERSONAL_DATA,
    APPLICATION_STEPS.PLATE_HANDLING,
    APPLICATION_STEPS.PROXY_DATA,
    APPLICATION_STEPS.CONFIRMATION_TASK
];

export const useCaseModifyDataSC = [
    APPLICATION_STEPS.SEARCH_APPLICATION_STEP,
    APPLICATION_STEPS.VEHICLE_DATA,
    APPLICATION_STEPS.PERSONAL_DATA,
    APPLICATION_STEPS.PLATE_HANDLING,
    APPLICATION_STEPS.PROXY_DATA,
    APPLICATION_STEPS.CONFIRMATION_TASK
]

export const useCaseReplace = [
    APPLICATION_STEPS.VEHICLE_DATA,
    APPLICATION_STEPS.PERSONAL_DATA,
    APPLICATION_STEPS.DOCUMENTS_DATA,
    APPLICATION_STEPS.PLATE_HANDLING,
    APPLICATION_STEPS.PROXY_DATA,
    APPLICATION_STEPS.CONFIRMATION_TASK
];

export const useCaseExpired = [
    APPLICATION_STEPS.VEHICLE_DATA,
    APPLICATION_STEPS.PERSONAL_DATA,
    APPLICATION_STEPS.DOCUMENTS_DATA,
    APPLICATION_STEPS.PROXY_DATA,
    APPLICATION_STEPS.CONFIRMATION_TASK
];

export const sellAndBuy = [
    APPLICATION_STEPS.VEHICLE_DATA,
    APPLICATION_STEPS.PERSONAL_DATA,
    APPLICATION_STEPS.TARGET_OWNER,
    APPLICATION_STEPS.PLATE_HANDLING,
    APPLICATION_STEPS.PROXY_DATA,
    APPLICATION_STEPS.CONFIRMATION_TASK
];

export const reservePlateList = [
    APPLICATION_STEPS.PERSONAL_DATA_RO,
    APPLICATION_STEPS.PLATE_HANDLING,
    APPLICATION_STEPS.PROXY_DATA,
    APPLICATION_STEPS.CONFIRMATION_TASK
];

export const reservePlateForLeList = [
    APPLICATION_STEPS.LEGAL_ENTITY,
    APPLICATION_STEPS.PLATE_HANDLING,
    APPLICATION_STEPS.PROXY_DATA,
    APPLICATION_STEPS.CONFIRMATION_TASK
];

export const reservePlateProtectedModeList = [
    APPLICATION_STEPS.PERSONAL_DATA,
    APPLICATION_STEPS.PLATE_HANDLING,
    APPLICATION_STEPS.PROXY_DATA,
    APPLICATION_STEPS.CONFIRMATION_TASK
];

export const exchangeVrList = [
    APPLICATION_STEPS.VEHICLE_DATA,
    APPLICATION_STEPS.TARGET_VEHICLE,
    APPLICATION_STEPS.PERSONAL_DATA,
    APPLICATION_STEPS.PLATE_HANDLING,
    APPLICATION_STEPS.PROXY_DATA,
    APPLICATION_STEPS.CONFIRMATION_TASK
];

export const cancelVrList = [
    APPLICATION_STEPS.VEHICLE_DATA,
    APPLICATION_STEPS.PERSONAL_DATA,
    APPLICATION_STEPS.PLATE_HANDLING,
    APPLICATION_STEPS.PROXY_DATA,
    APPLICATION_STEPS.CONFIRMATION_TASK
];

export const defaultList = [
    APPLICATION_STEPS.VEHICLE_DATA,
    APPLICATION_STEPS.PERSONAL_DATA,
    APPLICATION_STEPS.CONFIRMATION_TASK
];

//TODO refactor list name to be acceptable for multiple UCs
export function getListOfNavItemsByUC() {
    const useCase = useSelector((state) => getFormValues(state, forms.APPLICATION)["useCase"]);
    const subUseCase = useSelector((state) => getFormValues(state, forms.APPLICATION)["changeOwnershipSubUseCase"]);
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))

    let listOfNavItems = defaultList

    if (useCase) {
        switch (useCase) {
            case EUseCases.NEW:
            case EUseCases.RE_REGISTER:
                listOfNavItems = useCaseNew;
                break;
            case EUseCases.MODIFY_VEHICLE_DATA:
                if(isOperationModeProtected() && userRights && checkUserRights(MODIFY_PROVINCE_RIGHTS, userRights)){
                    listOfNavItems = useCaseModifyDataSC
                }else{
                    listOfNavItems = useCaseNew;
                }
                break;
            case EUseCases.EXPIRED:
            case EUseCases.REPLACE:
            case EUseCases.PARTLY_DAMAGED:
                listOfNavItems = useCaseReplace;
                if(configurationData && !configurationData.enablePlateHandling){
                    listOfNavItems = listOfNavItems.filter((item) => {return item !== APPLICATION_STEPS.PLATE_HANDLING});
                }
                break;
            case EUseCases.RESERVE_PLATE_NUMBER:
                if (isOperationModeProtected()) {
                    listOfNavItems = reservePlateProtectedModeList;
                    break;
                }
                if (isLoggedAccountLegalEntity()) {
                    listOfNavItems = reservePlateForLeList;
                    break;
                }
                listOfNavItems = reservePlateList;
                break;
            case EUseCases.EXCHANGE_REGISTRATION:
                listOfNavItems = exchangeVrList;
                if(configurationData && !configurationData.enablePlateHandling){
                    listOfNavItems = listOfNavItems.filter((item) => {return item !== APPLICATION_STEPS.PLATE_HANDLING});
                }
                break;
            case EUseCases.CANCEL:
                listOfNavItems = cancelVrList;
                if(configurationData && !configurationData.enablePlateHandling){
                    listOfNavItems = listOfNavItems.filter((item) => {return item !== APPLICATION_STEPS.PLATE_HANDLING});
                }
                break;
            case EUseCases.CHANGE_OWNERSHIP:
                if (subUseCase) {
                    switch (subUseCase) {
                        case changeOwnershipSubUseCases.SALE_AND_BUY:
                        case changeOwnershipSubUseCases.BY_COURT:
                            listOfNavItems = sellAndBuy;
                            break;
                        case changeOwnershipSubUseCases.ADD_OWNER:
                        case changeOwnershipSubUseCases.DELETE_OWNER:
                        case changeOwnershipSubUseCases.CHANGE_LEAD:
                        case changeOwnershipSubUseCases.CHANGE_SHARE:
                            listOfNavItems = cancelVrList;
                            if(configurationData && !configurationData.enablePlateHandling){
                                listOfNavItems = listOfNavItems.filter((item) => {return item !== APPLICATION_STEPS.PLATE_HANDLING});
                            }
                            break;
                        case changeOwnershipSubUseCases.BY_AUCTION:
                            listOfNavItems = useCaseNew;
                            break;
                        default:
                            listOfNavItems = defaultList;
                    }
                }
                break;
            default:
                listOfNavItems = defaultList;
        }

        if(configurationData?.useTransactionService){
            listOfNavItems.pop();
            listOfNavItems.push(APPLICATION_STEPS.CONFIRMATION_TASK_TX)
        }

        return listOfNavItems;
    }
}