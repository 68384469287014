import { PreviousDriverLicenseData, dLCategories } from "@mb-react/krg-getid-form-data-common";
import {
    FormElement,
    Messages,
    fieldType,
    getFormValues
} from "@mb-react/mb-react-core";
import { formFieldNames } from "@mb-react/svarog-ui";
import React, { useEffect, useRef } from "react";
import { useForm } from "react-final-form";
import { useSelector } from "react-redux";
import { I18n, Translate } from "react-redux-i18n";
import { forms } from "../../../../../constants/form";
import { useGetDriverLicenseDetailsFieldsSettings } from "./driverLicenseSettings/driverLicenseFieldsSettings";

const DriverLicenseCancelTask = () => {
    const useCaseFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)["useCase"]);
    const docTypeFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)["documentType"]);

    const form = useForm();

    const guidanceMessage1 = useRef(null);
    useEffect(() => {
        guidanceMessage1.current.show({
            severity: 'info', sticky: true, content: (
                <React.Fragment className="mb-1">
                    <i className="pi pi-info p-2" style={{
                        borderRadius: '50%',
                        backgroundColor: 'var(--surface-b)',
                        color: 'var(--surface-d)'
                    }}></i>
                    <div className="ml-2">
                        <Translate value="message.dlDataGuidanceMessage1" />
                    </div>
                </React.Fragment>
            )
        });
    }, []);

    return (
        <div className="card">
            <Messages ref={guidanceMessage1} />
            {useCaseFromStore && docTypeFromStore && (
                <div className={"useCaseHeader"}>
                    <h5>
                        {I18n.t("label.useCaseHeader", {
                            documentType: I18n.t("label." + docTypeFromStore),
                            useCase: I18n.t("label." + useCaseFromStore),
                        })}
                    </h5>
                </div>
            )}
            <h5>
                <Translate value={"label.dlData"} />
            </h5>
            <hr className="sectionSeparator" />
            <div className="grid task-containerWrapper ">
                <div className="grid task-container step-container row col-4">
                    <h6 className="m-0 ml-3 absolute"><Translate value="label.dlCategory" />:</h6>
                    {dLCategories.map((category) => (
                        <FormElement
                            key={category.value}    
                            label={category.label}
                            additionalClass={'ml-4 font-bold'}
                            required
                            fieldType={fieldType.CHECKBOX}
                            fieldProps={{
                                name: `${formFieldNames.DRIVERS_LICENSE_DATA}[${category.value}]`,
                                value: category.value
                            }}
                        />
                    ))}
                </div>
                <div className="task-container step-container row col-8">
                    <PreviousDriverLicenseData {...useGetDriverLicenseDetailsFieldsSettings()} />
                </div>
            </div>
        </div>
    );
};

export default DriverLicenseCancelTask;