import React, {useEffect, useRef} from "react";
import {Messages} from "@mb-react/mb-react-core";
import {useSelector} from "react-redux";
import {I18n, Translate} from "react-redux-i18n";
import {VEHICLE_DATA_STEP} from "../../../../../constants/store";
import {useHandleWorkflowSettings} from "../../../../entries/application/handleWorkflowSettings";
import {
    VehicleEngineData,
    VehicleDimensionsData,
    VehicleLoadData,
    VehicleAdditionalData, genericFormFieldNames
} from "@mb-react/krg-getid-form-data-common";
import {getFormValues} from "@mb-react/mb-react-core";
import {forms} from "../../../../../constants/form";
import {useGetVehicleDetailsFieldsSettings} from "./vehicleDataSettings/vehicleDetailsFieldSettings";
import {isVehicleType} from "../../../../../util/businesslogicUtil";

const VehicleDataTask = (formName) => {
    const useCaseFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)["useCase"]);
    const docTypeFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)["documentType"]);
    const vehicleIdentificationFormValues = useSelector((state) => getFormValues(state, forms.VEHICLE_IDENTIFICATION))
    const handleWorkflowSettings = useHandleWorkflowSettings();
    const guidanceMessage1 = useRef(null);
    const chassisMessage = useRef(null);


    useEffect(() => {

        guidanceMessage1.current.show({
            severity: 'info', sticky: true, content: (
                <React.Fragment className="mb-1">
                    <i className="pi pi-info p-2" style={{
                        borderRadius: '50%',
                        backgroundColor: 'var(--surface-b)',
                        color: 'var(--surface-d)'
                    }}></i>
                    <div className="ml-2">
                        <Translate value={"message.vehicleDataGuidanceMessage1"}/>
                    </div>
                </React.Fragment>
            )
        });
    }, []);


    let sectionsToRender = handleWorkflowSettings.vehicleDataStepsToRender

    return (
        <div className="card">
            <Messages ref={guidanceMessage1}/>

            {
                useCaseFromStore && docTypeFromStore && <div className={"useCaseHeader"}>
                    <h5>{I18n.t("label.useCaseHeader", {
                        documentType: I18n.t("label." + docTypeFromStore),
                        useCase: I18n.t("label." + useCaseFromStore)
                    })}</h5></div>
            }
            {isVehicleType(vehicleIdentificationFormValues) &&
            <div>
                <h5><Translate value="label.vehicleEngineData"/></h5>
                <hr className="sectionSeparator"/>
                <div className="grid">
                    {sectionsToRender.includes(VEHICLE_DATA_STEP.VEHICLE_DATA) &&
                        <VehicleEngineData {...useGetVehicleDetailsFieldsSettings(formName)} />}
                </div>
            </div>}
            <h5><Translate value={isVehicleType(vehicleIdentificationFormValues) ? "label.vehicleDimensionsData" : "label.trailerDimensionsData"}/></h5>
            <hr className="sectionSeparator"/>
            <div className="grid">
                {sectionsToRender.includes(VEHICLE_DATA_STEP.VEHICLE_DATA) &&
                    <VehicleDimensionsData {...useGetVehicleDetailsFieldsSettings(formName)} />}
            </div>
            <h5><Translate value={isVehicleType(vehicleIdentificationFormValues) ? "label.vehicleLoadData" : "label.trailerLoadData"}/></h5>
            <hr className="sectionSeparator"/>
            <div className="grid">
                {sectionsToRender.includes(VEHICLE_DATA_STEP.VEHICLE_DATA) &&
                    <VehicleLoadData {...useGetVehicleDetailsFieldsSettings(formName)} />}
            </div>
            <h5><Translate value={isVehicleType(vehicleIdentificationFormValues) ? "label.vehicleAdditionalData" : "label.trailerAdditionalData"}/></h5>
            <hr className="sectionSeparator"/>
            <div className="grid">
                {sectionsToRender.includes(VEHICLE_DATA_STEP.VEHICLE_DATA) &&
                    <VehicleAdditionalData {...useGetVehicleDetailsFieldsSettings(formName)} />}
            </div>
        </div>
    )
};
export default VehicleDataTask;