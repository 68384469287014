import React from 'react';
import { Translate } from 'react-redux-i18n';
import { TRG, getPath, i18nl } from '../../../../constants/i18n';
import ApplicationsStatusTable from "./ApplicationsStatusTable";

const ApplicationStatus = () => {
    return (
        <div className='card navigation'>
                <div className="card" style={{ padding: '1rem' }}>
                    <h5><Translate value={getPath(i18nl.CHECK_APPLICATION_STATUS, TRG.LABEL)} /></h5>
                    <ApplicationsStatusTable />
                </div>
        </div>
    )
}
export default ApplicationStatus