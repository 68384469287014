import { addMultipleErrorToStep, completeStep, getFormValues, getFromAppStore, hasValidationErrors, MBForm, NavButtons, nextStep, previousStep } from "@mb-react/mb-react-core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {APPLICATION_STEPS, applicationStore} from "../../../../../constants/store";
import { plateDataValidation } from "./plateDataValidation";
import PlateDataTask from "./PlateDataTask";
import { forms } from "../../../../../constants/form";
import {
    isOperationModeProtected,
    useSkip
} from "../../../../../util/businesslogicUtil";
import {onCancel} from "../../../../../util/componentUtil";
import {EUseCases} from "@mb-react/svarog-ui";
import NavButtonsLocal from "../../../../reactCoreComponents/NavButtonsLocal";
import {isMobile} from "../../../../../util/environment";

const PlateDataForm = (props) => {

    const isPlateReserved = useSelector((state) => getFromAppStore(state, 'plateReserved'))
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))
    const errors = useSelector((state) => hasValidationErrors(state, props.formName));
    const dispatch = useDispatch();
    const plateDataFormValidation = plateDataValidation();
    const useCaseFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)["useCase"])

    const onPrevious = () => {
        dispatch(previousStep(props.navigationName))
    }

    const isFormValid = () => {
        if(isOperationModeProtected() && errors){
            return false;
        }
        if ([EUseCases.RESERVE_PLATE_NUMBER, EUseCases.RE_REGISTER].includes(useCaseFromStore)) {
            if (isPlateReserved) {
                return true
            } else {
                return false
            }
        }else{
            return true
        }
    }

    const onNextStep = () => {
        if (!isFormValid()) {
            dispatch(addMultipleErrorToStep(APPLICATION_STEPS.PLATE_HANDLING, { "error": "testerror" }, props.navigationName))
        }else{
            dispatch(addMultipleErrorToStep(APPLICATION_STEPS.PLATE_HANDLING, {}, props.navigationName))
            dispatch(completeStep(props.navigationName, APPLICATION_STEPS.PLATE_HANDLING))
        }
        dispatch(nextStep(props.navigationName))
    }

    const onSkip = () => {
        dispatch(nextStep(props.navigationName))
    }

    return (
        <MBForm formName={props.formName} navigationId={props.navigationName}
            stepId={APPLICATION_STEPS.PLATE_HANDLING}
                warningButtonLabel={isMobile() ? "  " : null}
            showErrorPanel
            submitFunction={() => {
                dispatch(completeStep(props.navigationName, APPLICATION_STEPS.PLATE_HANDLING))
                dispatch(nextStep(props.navigationName))
            }}
            validate={plateDataFormValidation.validate}>
            <PlateDataTask formName={props.formName} />
            <NavButtonsLocal customOnPrevious={() => onPrevious()} customOnSkip={() => onSkip()}
                        customOnNext={() => onNextStep()} disableNextBtn={!isFormValid()} allowSkip={useSkip(userRights, errors)}
                        hideNext={useSkip(userRights, errors)} customOnCancel={() => onCancel(dispatch)}/>
        </MBForm>
    );
}

export default PlateDataForm;