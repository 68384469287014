import {
    Button,
    NavButtons,
    SimpleTable,
    addMultipleErrorToStep,
    addToAppStore,
    completeStep,
    getFormValues,
    getFromAppStore,
    nextStep, previousStep, DataTable, Column, NavigationWizardWrapper
} from '@mb-react/mb-react-core';
import {
    PagedRequest,
    formFieldNames,
    getPath
} from '@mb-react/svarog-ui';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {I18n, Translate} from 'react-redux-i18n';
import {forms, navigationName} from "../../../../../constants/form";
import {APPLICATION_STEPS, applicationStore} from "../../../../../constants/store";
import { useHandleCommonServices } from "../../../../../services/commonServiceHandler";
import {
    disableDraft,
    disableNextButton,
    isComponentApplicableOnVehicleTaskForUC, isModificationVRUseCase,
    isNotRegisteredUserRole,
    isOperationModeNotProtected,
    isOperationModeProtected,
    isUseCaseNEW
} from "../../../../../util/businesslogicUtil";
import {isMobile, prepareVehiclesForTable} from '../../../../../util/environment';
import VehicleSearchWrapper from "./VehicleSearchWrapper";
import {addAdditionalVehicleToAppStore} from "../../../../../util/util";
import {onCancel} from "../../../../../util/componentUtil";
import {i18nl} from "../../../../../constants/i18n";
import VehicleIdentificationForm from "../vehicleData/VehicleIdentificationForm";
import VehicleDataForm from "../vehicleData/VehicleDataForm";
import VehicleSummaryTaskWrapper from "../vehicleData/VehicleSummaryTaskWrapper";
import NavButtonsLocal from "../../../../reactCoreComponents/NavButtonsLocal";

const SearchAdditionalVehiclePreview = (props) => {
    const useCase = useSelector((state) => getFormValues(state, forms.APPLICATION))[formFieldNames.USE_CASE]
    const appFormValues = useSelector((state) => getFormValues(state, forms.APPLICATION))
    const addedVehicles = useSelector(state => getFromAppStore(state, applicationStore.ADDITIONAL_VEHICLES))
    const applicationsFromStore = useSelector(state => getFromAppStore(state, 'fetchedApplication'))
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))
    const handleCommonServices = useHandleCommonServices();
    const dispatch = useDispatch();

    const selectedVehicle = useSelector((state) =>
        getFromAppStore(state, applicationStore.SELECTED_ADDITIONAL_VEHICLE)
    );

    const showAddVehicleForm = useSelector((state) =>
        getFromAppStore(state, applicationStore.SHOW_ADDITIONAL_ADD_VEHICLE_FORM)
    );

    const fetchMyVehicles = (data, page, sizePerPage) => {
        let pagedRequest = new PagedRequest();
        pagedRequest.requestObject = data
        pagedRequest.page = page
        pagedRequest.sizePerPage = sizePerPage
        handleCommonServices.handleCallGetMyVehicles(pagedRequest, getMyVehicleOnSuccess)
    }

    const getMyVehicleOnSuccess = (response) => {
        addAdditionalVehicleToAppStore(prepareVehiclesForTable(response))
    }

    useEffect(() => {
        if (selectedVehicle) {
            let a = [];
            a.push(selectedVehicle)
            addAdditionalVehicleToAppStore(prepareVehiclesForTable(a))
        } else if(applicationsFromStore){
            addVehicleToStore({transactionId: applicationsFromStore.transactionId})
        }
    }, [])

    useEffect(() => {
        if(isOperationModeNotProtected()){
            if (userRights && !isNotRegisteredUserRole(userRights) && !isUseCaseNEW(appFormValues) && !selectedVehicle) {
                fetchMyVehicles({}, 1, 10)
            }
        }
    }, [userRights]);

    useEffect(() => {
        if(addedVehicles?.length === 0) return [];

        addAdditionalVehicleToAppStore(prepareVehiclesForTable(addedVehicles))
    }, [addedVehicles?.length])

    const addVehicleToStore = (vehicle) => {
        dispatch(addToAppStore(applicationStore.SELECTED_ADDITIONAL_VEHICLE, vehicle))
        handleCommonServices.handleGetVehicleByTransactionId({transactionId: vehicle.transactionId}, getVehicleByTransactionIdOnSuccess);
    }

    const getVehicleByTransactionIdOnSuccess = (response) => {
        let a = [];
        a.push(response)
        addAdditionalVehicleToAppStore(prepareVehiclesForTable(a))
    }


    const removeVehicleFromStore = (vehicle) => {
        dispatch(addToAppStore(applicationStore.SELECTED_ADDITIONAL_VEHICLE, null))
        dispatch(addToAppStore(applicationStore.ADDITIONAL_VEHICLES, addedVehicles.filter(e => e?.chassisNumber !== vehicle?.chassisNumber)))
        if(isOperationModeNotProtected() && !isNotRegisteredUserRole(userRights)){
            fetchMyVehicles({}, 1, 10)
        }
    }


    const customActionTemplate = (row) => {
        return (
            <div className="actionColumn" style={{display: "flex"}}>
                {selectedVehicle ?
                    <div>
                        <Button icon="pi pi-minus-circle" className="p-button-rounded p-button-danger"
                                onClick={() => removeVehicleFromStore(row)}/>
                    </div> :
                    <Button icon="pi pi-plus-circle" className="p-button-rounded p-button-success"
                            onClick={() => addVehicleToStore(row)}/>}
            </div>
        )

    }

    const columns = [
        {
            field: 'vid',
            header: I18n.t(getPath("VID")),
            sortable: true
        },
        {
            field: 'chassisNumber',
            header: I18n.t(getPath("Chassis Number")),
            sortable: true,
            filter: true
        },
        {
            field: 'axles',
            header: I18n.t(getPath("Number of axles"))
        },
        {
            field: 'transactionId',
            header: I18n.t(getPath("Transaction Id")),
            hidden: true
        },
        {
            field: 'actions'
        },
    ];

    const onPrevious = () => {
        dispatch(previousStep(props.navigationName))
    }

    let displayTableBasedOnUC = isComponentApplicableOnVehicleTaskForUC(useCase);

    const isFormValid = () => {
        if(!selectedVehicle){
            return false;
        }
        return true;
    }

    const onNextStep = () => {
        if(!isFormValid()){
            dispatch(addToAppStore('targetVehicleStepCompleted', false))
            dispatch(addMultipleErrorToStep(APPLICATION_STEPS.TARGET_VEHICLE, {"error": "testerror"}, props.navigationName))
        }else {
            dispatch(addToAppStore('targetVehicleStepCompleted', true))
            dispatch(addMultipleErrorToStep(APPLICATION_STEPS.TARGET_VEHICLE, {}, props.navigationName))
            dispatch(completeStep(props.navigationName, APPLICATION_STEPS.TARGET_VEHICLE))
        }
        dispatch(nextStep(props.navigationName))
    }

    let dynamicColumns = [];

    if (columns.length > 0) {
        dynamicColumns = columns.map((col) => {
            if (col.field == "actions") {
                return <Column key={col.field} field={col.field} header={I18n.t("label.actionTitle")} body={customActionTemplate} style={{ width: '10%' }} />
            }
            else {
                return <Column {...col} key={col.field} field={col.field} header={col.header} style={{ width: col.width ? col.width : "auto" }} />;

            }
        });
    }

    const vehicleInformationSteps = [
        {
            id: APPLICATION_STEPS.VEHICLE_IDENTIFICATION,
            label: isMobile() ? null : <Translate value={"label." + i18nl.VEHICLE_IDENTIFICATION} />,
            icon: "pi pi-circle-fill",
            component: <VehicleIdentificationForm navigationName={navigationName.SUB_NAVIGATION}
                                                  formName={forms.ADDITIONAL_VEHICLE_IDENTIFICATION}/>,
        },
        {
            id: APPLICATION_STEPS.TECHNICAL_DESCRIPTION,
            label: isMobile() ? null : <Translate value={"label." + i18nl.TECHNICAL_DESCRIPTION} />,
            icon: "pi pi-circle-fill",
            component: <VehicleDataForm navigationName={navigationName.SUB_NAVIGATION} formName={forms.ADDITIONAL_VEHICLE_DATA}/>,
        },
        {
            id: "summaryInformation",
            label: isMobile() ? null : <Translate value={"label." + i18nl.VEHICLE_IDENTIFICATION} />,
            icon: "pi pi-circle-fill",
            component: <VehicleSummaryTaskWrapper navigationName={navigationName.SUB_NAVIGATION}
                                                  formName={forms.VEHICLE_SUMMARY_INFORMATION}
                                                    additionalVehicleForm={true}/>,
        }
    ]

    let addNewVehicleButtonLabel = showAddVehicleForm ? "action.cancelAddNewVehicle" : "action.addNewVehicle"

    const showAddVehicle = () => {
        dispatch(addToAppStore(applicationStore.SHOW_ADDITIONAL_ADD_VEHICLE_FORM, !showAddVehicleForm))
    }

    return (
        <div>
            <div className="card circleNavWrapper" style={{padding: '1rem'}}>
              {isOperationModeProtected() && <VehicleSearchWrapper isAdditionalVehicle={true}/>}
                {(!displayTableBasedOnUC || selectedVehicle) && !showAddVehicleForm &&
                <DataTable
                    value={addedVehicles}
                    paginator rows={10} rowsPerPageOptions={[10, 20, 50]}
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    emptyMessage={I18n.t("message.noResultsFound")}
                >
                    {dynamicColumns}
                </DataTable>
                }
                {showAddVehicleForm &&
                    <NavigationWizardWrapper items={vehicleInformationSteps}
                                             progressBar={false}
                                             readOnly={disableDraft(userRights)}
                                             navigationId={navigationName.SUB_NAVIGATION}
                                             navigationType="CIRCLE"
                                             formName={forms.VEHICLE_DATA}/>
                }
                {(!isModificationVRUseCase(appFormValues) || isNotRegisteredUserRole(userRights)) &&
                    <Button label={<Translate value={addNewVehicleButtonLabel} />} className="p-button-outlined m-2" type="button"
                            onClick={() => showAddVehicle()}/>}
                {!showAddVehicleForm &&
                <NavButtonsLocal customOnPrevious={() => onPrevious()} customOnNext={() => onNextStep()}
                            disableNextBtn={disableNextButton(isFormValid(), userRights)} customOnCancel={() => onCancel(dispatch)}/>}
            </div>
        </div>
    )
}
export default SearchAdditionalVehiclePreview;