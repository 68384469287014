import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {addToAppStore, Button, getFromAppStore, removeFromAppStore, SimpleTable} from "@mb-react/mb-react-core/dist";
import {applicationStore} from "../../../../../constants/store";
import {isEntityAlreadyAddedInTable, returnApplicantMetaDataFromDTOForTable} from "../../../../../util/tableUtil";
import {I18n} from "react-redux-i18n";
import PropTypes from 'prop-types';
import {EDocumentType, formFieldNames, EUseCases} from '@mb-react/svarog-ui';
import {BUYER, SELLER} from "../../../../../constants/general";
import {
    extractGenericField,
    isUseCaseExpired,
    prepareApplicantDTOforStore, VRcheckStateHandling
} from "../../../../../util/businesslogicUtil";
import {
    addInitialValueToForm,
    getFormValues,
    hideSpinner,
    showSpinner,
    showToast,
    toastIDs
} from "@mb-react/mb-react-core";
import {entityTypes, forms} from "../../../../../constants/form";
import {useHandleCommonServices} from "../../../../../services/commonServiceHandler";
import {generateID} from "../../../../../util/util";
import InterfaceAdapterService from "../../../../../services/interfaceAdapter/InterfaceAdapterService";

function SearchPersonTableResult(props) {
    const dispatch = useDispatch();
    const totalPercentageSellers = useSelector((state) => getFromAppStore(state, 'totalPercentageSellers'));
    const totalPercentageBuyers = useSelector((state) => getFromAppStore(state, 'totalPercentageBuyers'))
    const data = useSelector((state) => getFromAppStore(state, applicationStore.PERSONS_DATA));
    const selectedPerson = useSelector((state) => getFromAppStore(state, applicationStore.SELECTED_PERSON));
    const [selectedRow, setSelectedRow] = useState();
    const [tableData, setTableData] = useState([]);
    const [tableCount, setTableCount] = useState(1);
    const listOfBuyers = useSelector((state) => getFromAppStore(state, 'listOfBuyers'))
    const listOfSellers = useSelector((state) => getFromAppStore(state, 'listOfSellers'));
    const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION));
    const {[formFieldNames.DOCUMENT_TYPE]: documentType, [formFieldNames.USE_CASE]: useCase} = formValues;
    const handleServices = useHandleCommonServices();
    const [selectedPersonTpId, setSelectedPersonTpid] = useState();

    let columns = [
        {field: "id", hidden: true},
        {
            field: formFieldNames.GIVEN_NAME,
            header: I18n.t('label.' + formFieldNames.GIVEN_NAME)
        },
        {
            field: formFieldNames.SURNAME,
            header: I18n.t('label.' + formFieldNames.SURNAME)
        },
        {
            field: formFieldNames.TPID,
            header: I18n.t('label.' + formFieldNames.TPID)
        },
        {
            field: formFieldNames.PERSONAL_NUMBER,
            header: I18n.t('label.' + formFieldNames.PERSONAL_NUMBER)
        }
    ];

    const displayError = (e) => {

        let errorMessage = "err.checkConditionsToApplyUnsupported";

        if(e === "useCaseMissing"){
            errorMessage = "err.useCaseMissing"
        }else if(e === "err.docsHaveToBeCanceled"){
            errorMessage = e
        }else if(isUseCaseExpired(formValues) && e?.code && e.code === "documentStillValid"){
            errorMessage = "err.documentStillValid"
        }

        dispatch(showToast(toastIDs.MESSAGE_TOAST, 'warn', {
            messageId: I18n.t(errorMessage)
        }))
    }

    const enrollNewPerson = () => {
        dispatch(addToAppStore('preSummaryAdded', false));
        dispatch(addToAppStore("enrollPersonFromScratch", true))
    }

    const addSelectedPerson = async () => {
        if (!selectedPerson) return false;

        const personStates = extractGenericField(selectedPerson?.genericFieldsDTO.genericFieldDTOList, "personStates");

        // handle person states
        if (personStates?.length > 0) {
            try{
                if(documentType === EDocumentType.VR){
                    await VRcheckStateHandling(InterfaceAdapterService, dispatch, entityTypes.PERSON, useCase, personStates?.map(state => state?.stateName))
                }else{
                    await DLcheckStateHandling(personStates)
                }
            }catch(e){
                dispatch(hideSpinner())
                console.log(e)
                if(e){
                    dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {
                        messageId: I18n.t(e)
                    }))
                }
                return;
            }
        }

        if (documentType === EDocumentType.DL && useCase !== EUseCases.NEW && useCase !== EUseCases.RE_REGISTER && useCase !== EUseCases.FOREIGN) {
            try {
                await DLcheckConditionsToApply()
            } catch (e) {
                dispatch(hideSpinner())
                displayError(e);
                return;
            }
        }

        let list = [];
        if (listOfSellers && props.role === SELLER) list = [...listOfSellers]
        if (listOfBuyers && props.role === BUYER) list = [...listOfBuyers]

        if (isEntityAlreadyAddedInTable(selectedPerson, list))
            return false;

        const bindObject = {
            id: generateID(),
            lead: false,
            percentage: 0
        };

        let store = "selectedPersonForDL";
        let preparedEntity = prepareApplicantDTOforStore(selectedPerson);

        if (props.role === BUYER) {
            preparedEntity.buyerId = bindObject.id
            totalPercentageBuyers.push(bindObject)
            store = "listOfBuyers";
        } else if (props.role === SELLER) {
            preparedEntity.sellerId = bindObject.id
            totalPercentageSellers.push(bindObject)
            store = "listOfSellers";
        }
        if (props.role) preparedEntity = [...list, preparedEntity]

        dispatch(addToAppStore(store, preparedEntity))
    }
    const checkConditionsToApplyOnSuccess = (data) => {
        dispatch(hideSpinner())

        if (data?.currentClasses) {
            if (data.currentClasses?.length > 0) {
                let clase = data.currentClasses.map(x => {
                    return x?.className
                });

                dispatch(addInitialValueToForm(forms.DRIVER_LICENSE, formFieldNames.DRIVERS_LICENSE_DATA, clase));
            }

            return true;
        } else {
            dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {
                messageId: "err.noValidDocumentsFound"
            }));
            return false;
        }
    };

    const DLcheckConditionsToApply = () => {
        dispatch(showSpinner(I18n.t('message.checkConditionsToApply')))
        const request = {
            tpId: selectedPersonTpId,
            useCase
        }
        return new Promise((resolve, reject) => {
            handleServices.handleCheckConditionsToApply(request,
                response => {
                    let conditions = checkConditionsToApplyOnSuccess(response)
                    if(conditions){
                        resolve(true)
                    }else{
                        reject(true)
                    }

                },
                errorResponse => {
                    dispatch(hideSpinner())
                    reject(errorResponse)

                })
        });
    }

    const DLcheckStateHandling = (personStates) => {
        dispatch(showSpinner(I18n.t('message.checkStateHandling')))
        return new Promise((resolve, reject) => {
            let blockingState = null;
            const requestData = {
                useCase: formValues?.[formFieldNames.USE_CASE],
                states: personStates?.map(state => state?.stateName)
            };

            InterfaceAdapterService.getDLBlockingState(requestData)
                .then(response => {
                    if (response?.data?.data) {
                        blockingState = `label.stateHandling${response.data.data}`;
                        reject(blockingState)
                    }
                    resolve(true)
                })
                .catch(() => {
                    blockingState = "err.blockingStateCheckFailed";
                    reject(blockingState)
                });
        });
    }

    const handleSelectionChange = (e) => {
        if (e?.originalEvent?.target?.localName === "i") return;

        setSelectedRow(e.value);
        if (e.value) {
            setSelectedPersonTpid(e.value.tpId)
            dispatch(addToAppStore(applicationStore.SELECTED_PERSON, data[e.value.id]))
        } else {
            setSelectedPersonTpid()
            dispatch(removeFromAppStore(applicationStore.SELECTED_PERSON))
        }
    };

    useEffect(() => {
        if (data != null) {
            setTableData(returnApplicantMetaDataFromDTOForTable(data));
        } else {
            setTableData([]);
            setSelectedRow();
        }
    }, [data]);

    return (
        <div className="col-lg-12">
            <SimpleTable
                tableData={tableData}
                actionTitle=""
                columns={columns}
                count={tableCount}
                tableProps={{
                    dataKey: "id",
                    emptyMessage: I18n.t("message.noResultsFound"),
                    metaKeySelection: false,
                    selectionMode: "single",
                    selection: selectedRow,
                    responsiveLayout: "scroll",
                    onSelectionChange: (e) => {
                        handleSelectionChange(e)
                    }
                }}
            />
            {(documentType === EDocumentType.DL) &&
                <div style={{ textAlign: "center"}}>
                    <Button label={I18n.t("action.addNewPerson")}
                            className="mt-2 p-button-success"
                            disabled={selectedRow}
                            onClick={enrollNewPerson}/>
                </div>}

            <div style={{ textAlign: documentType === EDocumentType.DL ? "right" : "left"}}>
                <Button label={I18n.t(documentType === EDocumentType.DL ? "action.enrollPerson" : "action.addPerson")}
                        className="p-button-rounded mt-4 mb-5"
                        disabled={!selectedRow}
                        onClick={addSelectedPerson}/>
            </div>
        </div>
    )
};

SearchPersonTableResult.defaultProps = {
    personData: []
};

SearchPersonTableResult.propTypes = {
    disabled: PropTypes.bool,
};

export default SearchPersonTableResult;