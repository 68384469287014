export const ARABIC_UNICODE = {
  '\u0660' : '0',
  '\u0661' : '1',
  '\u0662' : '2',
  '\u0663' : '3',
  '\u0664' : '4',
  '\u0665' : '5',
  '\u0666' : '6',
  '\u0667' : '7',
  '\u0668' : '8',
  '\u0669' : '9',
  '\u0623' : "A",
  '\u0628' : "B",
  '\u062C' : "J",
  '\u062F' : "D",
  '\u0631' : "R",
  '\u0633' : "S",
  '\u0637' : "T",
  '\u0641' : "F",
  '\u0643' : "K",
  '\u0645' : "M",
  '\u0646' : "N",
  '\u06BE' : "H",
  '\u0648' : "W",
  '\u0649' : "E",
  '\u062c' : "J",
  '\u0644' : "L",
  '\u0642' : "Q",
  '\u0632' : "Z"
}
