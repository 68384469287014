import React from "react";
import AppointmentBookingWorkflow from "../../workflows/appointments/AppointmentBookingWorkflow";
import MainWrapper from "./MainWrapper";

const BookAppointmentWrapper = () => {
  // const application = useSelector(state => getFromAppStore(state, appStoreFields.ALL_SERVICES))
  return (
    <MainWrapper>
      <AppointmentBookingWorkflow />
    </MainWrapper>
  )
}
export default BookAppointmentWrapper;