import { addToAppStore, getFromAppStore } from '@mb-react/mb-react-core'
import { Message, Tree } from '@mb-react/mb-react-core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { getPath, i18nl } from '../../../../constants/i18n'
import { appStoreFields } from '../../../../constants/store'
import { prepareLocationsForTree } from '../../../../util/environment'
import {AppointmentService, useCreateServiceWrapper, ServiceRequestData, GetProvidersForLocationAppointmentRequest, useHandleGetAllLocationsSuccessResponse } from '@mb-react/svarog-ui'

const LocationProviderTask = () => {

  const originalLocations = useSelector((state) => getFromAppStore(state, appStoreFields.ALL_LOCATIONS))
  const selectedService = useSelector((state) => getFromAppStore(state, appStoreFields.selectedService))
  const selectedProviderProp = useSelector((state) => getFromAppStore(state, appStoreFields.selectedProvider))
  const selectedLatestLocation = useSelector((state) => getFromAppStore(state, appStoreFields.selectedLocation))

  const [allLocations, setAllLocations] = useState(originalLocations ? prepareLocationsForTree(originalLocations, true) : []);
  const [latestExpandedLocation, setLatestExpandedLocation] = useState(selectedLatestLocation);
  const [selectedProvider, setSelectedProvider] = useState(selectedProviderProp || null);
  const [loading, setLoading] = useState(true);


  const dispatch = useDispatch()
  const serviceCall = useCreateServiceWrapper();
  const useHandleGetAllServicesSuccess = useHandleGetAllLocationsSuccessResponse();

  useEffect(() => {
    serviceCall(new ServiceRequestData(
      AppointmentService.getAllLocations,
      null,
      useHandleGetAllServicesSuccess,
      null,
      (response) => {
        setAllLocations(response && response.data ? prepareLocationsForTree(response.data, true) : [])
        setLoading(false)
      },
      null
    ))

  }, [])
  useEffect(() => {
    if (selectedProvider && selectedProvider.value) {
      dispatch(addToAppStore(appStoreFields.selectedProvider, selectedProvider))
      if (selectedProvider.originalEvent && selectedProvider.originalEvent.target) {
        dispatch(addToAppStore(appStoreFields.selectedProviderName, selectedProvider.originalEvent.target.innerText))
      }

    }
    dispatch(addToAppStore(appStoreFields.selectedLocation, latestExpandedLocation))

  }, [selectedProvider])

  const getProvidersForLocation = (event) => {
    let requestData = new GetProvidersForLocationAppointmentRequest(
      selectedService.code,
      event.node.data.split("-")[0]
    )
    serviceCall(new ServiceRequestData(
      AppointmentService.getLocationProvidersByService,
      requestData,
      null,
      null,
      (response) => {
        let node = { ...event.node };
        node.children = prepareLocationsForTree(response.data, false);
        let value = [...allLocations];
        value[allLocations.indexOf(event.node)] = node;
        setAllLocations(value);
        setLoading(false);
      },
      null
    ))
  }

  const loadOnExpand = (event) => {
    if (!event.node.children) {
      setLoading(true);

      setLatestExpandedLocation(event.node)
      getProvidersForLocation(event)
    }
  }
  return (
    <div className="card" style={{ padding: '1rem' }} >
      <h5><Translate value={getPath(i18nl.LOCATION)} /></h5>
      {
        selectedService ? <Tree value={allLocations}  metaKeySelection={false}  selectionMode="single" selectionKeys={selectedProvider && selectedProvider.value ? selectedProvider.value : null} onSelectionChange={e => setSelectedProvider(e)} loading={loading} onExpand={loadOnExpand} />
          : <Message severity="info" text="Please choose one of the available services to see locations" />
      }

    </div>
  )
}

export default LocationProviderTask;