import {
    getFormValues, getFromAppStore,
} from "@mb-react/mb-react-core";
import { formFieldNames } from "@mb-react/svarog-ui";
import { useSelector } from "react-redux";
import { formFieldsMeta, forms } from "../../../../../../constants/form";
import { isMandatory, isReadOnly } from "../../../../../../util/businesslogicUtil";
import { genericFormFieldNames } from "@mb-react/krg-getid-form-data-common";
import { useMemo } from "react";
import { useForm } from "react-final-form";
import {ACCOUNT_IDENTITY} from "../../../../../../constants/application";

export const useMotherDataSettings = () => {
    const form = useForm();
    const useCase = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.USE_CASE])
    const documentType = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.DOCUMENT_TYPE])
    const workflow = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldsMeta.WORKFLOW])
    const accountIdentity = useSelector(state => getFromAppStore(state, ACCOUNT_IDENTITY));

    let formValues = form?.getState()?.values ?? {}

    const handleMothersNameSettings = useMemo(() => {
        let motherDataProps = {
            [formFieldNames.MOTHERS_NAME]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(formValues, formFieldNames.MOTHERS_NAME, useCase, documentType, workflow),
                },
                required: isMandatory(formValues, formFieldNames.MOTHERS_NAME, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return motherDataProps
    }, [formValues[formFieldNames.TPID]])

    const handleMothersFatherNameLatinSettings = useMemo(() => {
        let motherDataProps = {
            [genericFormFieldNames.MOTHERS_FATHER_NAME]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(formValues, genericFormFieldNames.MOTHERS_FATHER_NAME, useCase, documentType, workflow),
                },
                required: isMandatory(formValues, genericFormFieldNames.MOTHERS_FATHER_NAME, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return motherDataProps
    }, [formValues[formFieldNames.TPID]])

    return { ...handleMothersNameSettings, ...handleMothersFatherNameLatinSettings }
};