import {formFieldNames} from "@mb-react/svarog-ui";
import { useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {dictionaryReference, projectDictionaries} from "../../../../../../constants/dictionaries";
import {formFieldsMeta, forms} from "../../../../../../constants/form";
import {
  getVehicleTypes,
  handleBrandDictionaries,
  isMandatory,
  isReadOnly,
  isTrailerType,
  isVehicleType
} from "../../../../../../util/businesslogicUtil";
import {useForm} from "react-final-form";
import {useHandleCommonServices} from "../../../../../../services/commonServiceHandler";
import {addInitialValueToForm, getFormValues} from "@mb-react/mb-react-core";
import { genericFormFieldNames } from "@mb-react/krg-getid-form-data-common";
import {vehicleTypes} from "../../../../../../constants/application";
import {prepareSpecificDictionaryElementForDropdown} from "../../../../../../util/dataPreparationUtil";

export const useGetVehicleGeneralFieldsSettings = (props) => {

  const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION))
  const vehicleDataFormValues = useSelector((state) => getFormValues(state, props.formName))

  let vehicleSubcategories = useSelector((state) => prepareSpecificDictionaryElementForDropdown(state, dictionaryReference.VEHICLE_SUBCATEGORIES));
  let trailerSubcategories = useSelector((state) => prepareSpecificDictionaryElementForDropdown(state, dictionaryReference.TRAILER_SUBCATEGORIES));

  const form = useForm();
  const dispatch = useDispatch();

  const handleCommonServices = useHandleCommonServices()

  const onVehicleTypeChange = (e) => {
    form.change(formFieldNames.VEHICLE_CATEGORY, null)
    form.change(formFieldNames.VEHICLE_SUBCATEGORY, null)
    form.change(formFieldNames.VEHICLE_BRAND, null)
    form.change(formFieldNames.VEHICLE_MODEL, null)
    if(e.value === vehicleTypes.TRAILER){
      form.change(formFieldNames.CABIN_TYPE, null)
      dispatch(addInitialValueToForm(forms.VEHICLE_DATA, formFieldNames.SEATS, null))
      dispatch(addInitialValueToForm(forms.VEHICLE_DATA, formFieldNames.ENGINE_CAPACITY, null))
      dispatch(addInitialValueToForm(forms.VEHICLE_DATA, formFieldNames.ENGINE_POWER, null))
      dispatch(addInitialValueToForm(forms.VEHICLE_DATA, formFieldNames.FUEL, null))
      dispatch(addInitialValueToForm(forms.VEHICLE_DATA, formFieldNames.CYLINDERS, null))
      dispatch(addInitialValueToForm(forms.VEHICLE_DATA, formFieldNames.FRONT_TINT_PERCENTAGE, null))
      dispatch(addInitialValueToForm(forms.VEHICLE_DATA, formFieldNames.REAR_TINT_PERCENTAGE, null))
    }
  };

  const onChassisNumberChange = (e) => {
    form.change(formFieldNames.CABIN_NUMBER, e.target.value)
  };

  const onBrandChange = (event) => {
    form.change(formFieldNames.VEHICLE_MODEL, null);
    handleBrandDictionaries(vehicleDataFormValues, event?.target?.value, handleCommonServices);
  }

  const onCategoryChange = (event) => {
    form.change(formFieldNames.VEHICLE_SUBCATEGORY, null)
    if(isTrailerType(vehicleDataFormValues)){
      handleCommonServices.handleGetSingleDictionary(projectDictionaries.TRAILER_SUBCATEGORIES, event.target.value, dictionaryReference.TRAILER_SUBCATEGORIES);
    }else{
      handleCommonServices.handleGetSingleDictionary(projectDictionaries.VEHICLE_SUBCATEGORIES, event.target.value, dictionaryReference.VEHICLE_SUBCATEGORIES);
    }
  }

  const handleVehicleIdSettings = useMemo(() => {
    let props = {
      [formFieldNames.VEHICLE_ID]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.VEHICLE_ID, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        },
        exclude: true,
        required: isMandatory(vehicleDataFormValues, formFieldNames.VEHICLE_ID, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])

  const handleVehicleTypeSettings = useMemo(() => {
    let props = {
      [genericFormFieldNames.VEHICLE_TYPE]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, genericFormFieldNames.VEHICLE_TYPE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          showClear: true,
          selectOptions: getVehicleTypes(),
          [formFieldNames.ON_CHANGE]: onVehicleTypeChange
        },
        required: isMandatory(vehicleDataFormValues, genericFormFieldNames.VEHICLE_TYPE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])

  const handleVehicleBrandSettings = useMemo(() => {

    let reference = projectDictionaries.VEHICLE_BRANDS;

    if(vehicleDataFormValues[genericFormFieldNames.VEHICLE_TYPE] === vehicleTypes.TRAILER){
      reference = projectDictionaries.TRAILER_BRANDS
    }

    let props = {
      [formFieldNames.VEHICLE_BRAND]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.VEHICLE_BRAND, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: reference,
          showClear: true,
          filter: true,
          filterBy: "label",
          [formFieldNames.ON_CHANGE]: onBrandChange
        },
        label: isTrailerType(vehicleDataFormValues) ? "label.trailerBrand" : `label.${formFieldNames.VEHICLE_BRAND}`,
        required: isMandatory(vehicleDataFormValues, formFieldNames.VEHICLE_BRAND, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleDataFormValues[genericFormFieldNames.VEHICLE_TYPE]])

  const handleVehicleCategorySettings = useMemo(() => {

    let reference = projectDictionaries.VEHICLE_CATEGORIES;

    if(vehicleDataFormValues[genericFormFieldNames.VEHICLE_TYPE] === vehicleTypes.TRAILER){
      reference = projectDictionaries.TRAILER_CATEGORIES
    }

    let props = {
      [formFieldNames.VEHICLE_CATEGORY]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.VEHICLE_CATEGORY, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: reference,
          showClear: true,
          [formFieldNames.ON_CHANGE]: onCategoryChange
        },
        label: isTrailerType(vehicleDataFormValues) ? "label.trailerCategory" : `label.${formFieldNames.VEHICLE_CATEGORY}`,
        required: isMandatory(vehicleDataFormValues, formFieldNames.VEHICLE_CATEGORY, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleDataFormValues[genericFormFieldNames.VEHICLE_TYPE]])

  const handleProductionYearSettings = useMemo(() => {
    let props = {
      [formFieldNames.PRODUCTION_YEAR]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.PRODUCTION_YEAR, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          maxLength: 4
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.PRODUCTION_YEAR, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleDataFormValues[genericFormFieldNames.VEHICLE_TYPE]])

  const handleProductionCountrySettings = useMemo(() => {
    let props = {
      [formFieldNames.PRODUCTION_COUNTRY]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.PRODUCTION_COUNTRY, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: projectDictionaries.COUNTRY_CODES,
          showClear: true,
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.PRODUCTION_COUNTRY, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleDataFormValues[genericFormFieldNames.VEHICLE_TYPE]])

  const handleChassisNumberSettings = useMemo(() => {

    let props = {
      [formFieldNames.CHASSIS_NUMBER]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.CHASSIS_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          keyfilter: /^[^a-zOIQ+\\\-*/.,!?_@#$%^&<>()'"={}\[\]`;:|]+$/,
          maxLength: 17,
          [formFieldNames.ON_CHANGE]: onChassisNumberChange
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.CHASSIS_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleDataFormValues[genericFormFieldNames.VEHICLE_TYPE]])

  const handleVehicleUsageTypeSettings = useMemo(() => {
    let props = {
      [formFieldNames.VEHICLE_USAGE_TYPE]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.VEHICLE_USAGE_TYPE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: projectDictionaries.VEHICLE_USAGE_TYPES,
          showClear: true,
        },
        exclude: true,
        required: isMandatory(vehicleDataFormValues, formFieldNames.VEHICLE_USAGE_TYPE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleDataFormValues[genericFormFieldNames.VEHICLE_TYPE]])

  const handleVehicleColorSettings = useMemo(() => {
    let props = {
      [formFieldNames.VEHICLE_COLOR]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.VEHICLE_COLOR, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: projectDictionaries.VEHICLE_COLORS,
          showClear: true,
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.VEHICLE_COLOR, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleDataFormValues[genericFormFieldNames.VEHICLE_TYPE]])

  const handleSeatsSettings = useMemo(() => {
    let props = {
      [formFieldNames.SEATS]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.SEATS, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          digitsOnly: true,
          maxLength: 2
        },
        exclude: vehicleDataFormValues[genericFormFieldNames.VEHICLE_TYPE] === vehicleTypes.TRAILER,
        required: false, //isVehicleType(vehicleDataFormValues) && isMandatory(vehicleDataFormValues, formFieldNames.SEATS, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [isVehicleType(vehicleDataFormValues)])


  const handleVehicleModelSettings = useMemo(() => {

    let reference = dictionaryReference.VEHICLE_MODELS;

    if(isTrailerType(vehicleDataFormValues)){
      reference = dictionaryReference.TRAILER_MODELS
    }

    let props = {
      [formFieldNames.VEHICLE_MODEL]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.VEHICLE_MODEL, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          showClear: true,
          filter: true,
          filterBy: "label",
          reference: reference,
        },
        label: isTrailerType(vehicleDataFormValues) ? "label.trailerModel" : `label.${formFieldNames.VEHICLE_MODEL}`,
        required: isMandatory(vehicleDataFormValues, formFieldNames.VEHICLE_MODEL, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleDataFormValues[genericFormFieldNames.VEHICLE_TYPE], vehicleDataFormValues[formFieldNames.VEHICLE_BRAND]])

  const handleVehicleSubcategorySettings = useMemo(() => {
    let selectOptions = vehicleSubcategories

    if(isTrailerType(vehicleDataFormValues)){
      selectOptions = trailerSubcategories
    }

    let props = {
      [formFieldNames.VEHICLE_SUBCATEGORY]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: !(selectOptions.length > 0),
          showClear: true,
          options: selectOptions
        },
        label: isTrailerType(vehicleDataFormValues) ? "label.trailerSubcategory" : `label.${formFieldNames.VEHICLE_SUBCATEGORY}`,
        required: false,//(selectOptions.length > 0),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleDataFormValues[genericFormFieldNames.VEHICLE_TYPE], vehicleSubcategories])

  const handleCabinNumberSettings = useMemo(() => {
    let props = {
      [formFieldNames.CABIN_NUMBER]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.CABIN_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          keyfilter: /^[^a-zOIQ+\\\-*/.,!?_@#$%^&<>()'"={}\[\]`;:|]+$/,
          maxLength: 17
        },
        required: isMandatory(vehicleDataFormValues, formFieldNames.CABIN_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleDataFormValues[genericFormFieldNames.VEHICLE_TYPE], formFieldNames.CHASSIS_NUMBER])

  const handleCabinTypeSettings = useMemo(() => {
    let props = {
      [formFieldNames.CABIN_TYPE]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, formFieldNames.CABIN_TYPE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: projectDictionaries.VEHICLE_CABIN_TYPES,
          showClear: true
        },
        exclude: vehicleDataFormValues[genericFormFieldNames.VEHICLE_TYPE] === vehicleTypes.TRAILER,
        required: isVehicleType(vehicleDataFormValues) && isMandatory(vehicleDataFormValues, formFieldNames.CABIN_TYPE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [vehicleDataFormValues[genericFormFieldNames.VEHICLE_TYPE]])

  return {...handleVehicleIdSettings, ...handleVehicleTypeSettings, ...handleVehicleBrandSettings, ...handleVehicleCategorySettings, ...handleProductionYearSettings,
        ...handleProductionCountrySettings, ...handleChassisNumberSettings, ...handleVehicleUsageTypeSettings, ...handleVehicleColorSettings,
  ...handleSeatsSettings, ...handleCabinTypeSettings,
  ...handleVehicleModelSettings, ...handleVehicleSubcategorySettings,
  ...handleCabinNumberSettings}

};