import {
    Button,
    NavigationWizardWrapper,
    addMultipleErrorToStep,
    addToAppStore,
    completeStep,
    getFormValues,
    getFromAppStore,
    hasValidationErrors,
    nextStep,
    removeAllValuesFromForm,
    Column,
    DataTable, previousStep
} from '@mb-react/mb-react-core';
import {
    PagedRequest,
    formFieldNames,
    EUseCases
} from '@mb-react/svarog-ui';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {I18n, Translate} from 'react-redux-i18n';
import {forms, navigationName} from "../../../../../constants/form";
import {APPLICATION_STEPS, applicationStore} from "../../../../../constants/store";
import {useHandleCommonServices} from "../../../../../services/commonServiceHandler";
import {
    disableAddIdentitiesButtonListOfUC,
    disableDraft, disableNextButton,
    isComponentApplicableOnVehicleTaskForUC, isModificationVRUseCase,
    isNotRegisteredUserRole, isOperationModeNotProtected,
    isOperationModeProtected,
    isUseCaseChangeOwnership,
    isUseCaseNEW, isUseCaseReplaceVR, mapVehicleToFormValues
} from "../../../../../util/businesslogicUtil";
import {isMobile, prepareVehiclesForTable} from '../../../../../util/environment';
import VehicleDataForm from "../vehicleData/VehicleDataForm";
import VehicleIdentificationForm from "../vehicleData/VehicleIdentificationForm";
import VehicleSummaryTaskWrapper from "../vehicleData/VehicleSummaryTaskWrapper";
import VehicleSearchWrapper from "./VehicleSearchWrapper";
import {addVehicleToAppStore, checkUserRights} from "../../../../../util/util";
import {changeOwnershipSubUseCases, vehicleState} from "../../../../../constants/application";
import {enrollmentStatus} from "../../../../../constants/general";
import {mapOwnerDtoToApplicantData} from "../../../../../util/dataMapperUtil";
import {onCancel} from "../../../../../util/componentUtil";
import {MODIFY_PROVINCE_RIGHTS} from "../../../../../constants/admin/rights";
import {i18nl} from "../../../../../constants/i18n";
import NavButtonsLocal from "../../../../reactCoreComponents/NavButtonsLocal";

const SearchVehiclePreview = (props) => {
    const useCase = useSelector((state) => getFormValues(state, forms.APPLICATION))[formFieldNames.USE_CASE]
    const appFormValues = useSelector((state) => getFormValues(state, forms.APPLICATION))
    const addedVehicles = useSelector(state => getFromAppStore(state, applicationStore.ADDED_VEHICLES))
    const addedSdmsVehicles = useSelector(state => getFromAppStore(state, applicationStore.ADDED_SDMS_VEHICLES))
    const applicationsFromStore = useSelector(state => getFromAppStore(state, 'fetchedApplication'))
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))
    const isVehicleFromSDMSAdded = useSelector(state => getFromAppStore(state, applicationStore.ADDED_VEHICLES_FROM_SDMS))
    const [showSearchVehicleForm, setShowSearchVehicleForm] = useState(false);
    const handleCommonServices = useHandleCommonServices();
    const dispatch = useDispatch();

    const showAddVehicleForm = useSelector((state) =>
        getFromAppStore(state, applicationStore.SHOW_ADD_VEHICLE_FORM)
    );

    const selectedVehicle = useSelector((state) =>
        getFromAppStore(state, applicationStore.SELECTED_VEHICLE)
    );

    const handleMyVehicleTableState = (data) => {
        addVehicleToAppStore(data)
    }

    const errorsVI = useSelector((state) => hasValidationErrors(state, forms.VEHICLE_IDENTIFICATION));
    const errorsVD = useSelector((state) => hasValidationErrors(state, forms.VEHICLE_DATA));

    const fetchMyVehicles = (data, page, sizePerPage) => {
        let pagedRequest = new PagedRequest();
        pagedRequest.requestObject = data
        pagedRequest.page = page
        pagedRequest.sizePerPage = sizePerPage
        handleCommonServices.handleCallGetMyVehicles(pagedRequest, getMyVehicleOnSuccess)
    }

    const getMyVehicleOnSuccess = (response) => {
        let filteredVehicles = filterVehiclesBasedOnUc(response);
        addVehicleToAppStore(prepareVehiclesForTable(filteredVehicles))
    }

    function filterVehiclesBasedOnUc(vehicles) {
        if (vehicles && vehicles.length > 0) {
            if (isUseCaseChangeOwnership(appFormValues)) {
                switch (appFormValues.changeOwnershipSubUseCase) {
                    case changeOwnershipSubUseCases.CHANGE_LEAD:
                    case changeOwnershipSubUseCases.CHANGE_SHARE:
                        return filterVehiclesByState(filterVehiclesWithMultipleOwners(vehicles), vehicleState.REGISTERED)
                        break;
                    case changeOwnershipSubUseCases.BY_AUCTION:
                        return filterVehiclesByState(vehicles, vehicleState.UNREGISTERED)
                        break;
                    case changeOwnershipSubUseCases.ADD_OWNER:
                    case changeOwnershipSubUseCases.BY_COURT:
                    case changeOwnershipSubUseCases.REMOVE_OWNER:
                    case changeOwnershipSubUseCases.SALE_AND_BUY:
                    default:
                        return vehicles;
                }
            }
        }
        return vehicles
    }

    function filterVehiclesWithMultipleOwners(vehicles) {
        const filteredVehicles = vehicles.filter((vehicle) => {
            if (vehicle.ownersDTO && vehicle.ownersDTO.length > 1) {
                return vehicle;
            }
        })
        return filteredVehicles;
    }

    function filterVehiclesByState(vehicles, state) {
        const filteredVehicles = vehicles.filter((vehicle) => {
            return vehicle.status === state;
        })
        return filteredVehicles;
    }

    useEffect(() => {
        if (selectedVehicle) {
            let a = [];
            a.push(selectedVehicle)
            addVehicleToAppStore(prepareVehiclesForTable(a))
        } else if (applicationsFromStore && isComponentApplicableOnVehicleTaskForUC(useCase)) {
            dispatch(addToAppStore(applicationStore.SHOW_ADD_VEHICLE_FORM, true))
        } else if (applicationsFromStore) {
            addVehicleToStore({transactionId: applicationsFromStore.transactionId})
        }
    }, [])

    useEffect(() => {
        if(isOperationModeNotProtected()){
            if (userRights && !isNotRegisteredUserRole(userRights) && !isUseCaseNEW(appFormValues) && !selectedVehicle) {
                fetchMyVehicles({}, 1, 10)
            }
        }
    }, [userRights]);

    useEffect(() => {
        if (addedVehicles?.length === 0) return [];

        addVehicleToAppStore(prepareVehiclesForTable(addedVehicles))
    }, [addedVehicles?.length])

    useEffect(() => {
        if (addedSdmsVehicles?.length === 0) return [];

        if(addedSdmsVehicles && addedSdmsVehicles.length > 0){
            dispatch(addToAppStore(applicationStore.ADDED_SDMS_VEHICLES, addedSdmsVehicles))
        }else{
            dispatch(addToAppStore(applicationStore.ADDED_SDMS_VEHICLES, []))
        }
    }, [addedSdmsVehicles?.length])

    const addVehicleToStore = (vehicle) => {
        dispatch(addToAppStore(applicationStore.VEHICLE_FROM_SYSTEM, true))
        dispatch(addToAppStore(applicationStore.SELECTED_VEHICLE, vehicle))
        dispatch(addToAppStore(applicationStore.SHOW_ADD_VEHICLE_FORM, false))
        handleCommonServices.handleGetVehicleByTransactionId({transactionId: vehicle.transactionId}, getVehicleByTransactionIdOnSuccess);
    }

    const getVehicleByTransactionIdOnSuccess = (response) => {

        mapVehicleToFormValues(dispatch, handleCommonServices, response, appFormValues)

        if ((isUseCaseChangeOwnership(appFormValues) || isUseCaseReplaceVR(appFormValues)) && response?.ownersDTO?.length > 0) {
            assembleApplicantsDataFromOwners(response.ownersDTO);
        }

        let a = [];
        a.push(response)
        addVehicleToAppStore(prepareVehiclesForTable(a))
    }

    function assembleApplicantsDataFromOwners(owners) {
        console.log(owners, "owners")
        let listOfSellers = [];
        let totalPercentageSellers = [];
        let fetchedValuesTPS = [];

        owners.forEach((owner, id) => {
            listOfSellers.push({
                sellerId: id,
                enrollmentStatus: enrollmentStatus.COMPLETE,
                entityType: owner["ownerType"],
                applicantData: mapOwnerDtoToApplicantData(owner)
            })
        })

        listOfSellers.forEach((seller) => {

            let me = {
                id: seller.sellerId,
                lead: seller.applicantData && seller.applicantData.ownershipDTO ? seller.applicantData.ownershipDTO.lead : null,
                percentage: seller.applicantData && seller.applicantData.ownershipDTO ? seller.applicantData.ownershipDTO.percent : null
            }

            totalPercentageSellers.push(me)
            fetchedValuesTPS.push({...me})
        })

        if (listOfSellers.length > 0) {
            dispatch(addToAppStore('listOfSellers', listOfSellers))
            dispatch(addToAppStore('totalPercentageSellers', totalPercentageSellers))
            dispatch(addToAppStore('fetchedValuesTPS', fetchedValuesTPS))
        }
    }


    const removeVehicleFromStore = (vehicle) => {
        dispatch(removeAllValuesFromForm(forms.VEHICLE_IDENTIFICATION))
        dispatch(removeAllValuesFromForm(forms.VEHICLE_DATA))
        dispatch(removeAllValuesFromForm(forms.PLATE_DATA))
        dispatch(addToAppStore(applicationStore.SELECTED_VEHICLE, null))
        dispatch(addToAppStore(applicationStore.VEHICLE_FROM_SYSTEM, false));
        dispatch(addToAppStore(applicationStore.ADDED_VEHICLES_FROM_SDMS, false));
        dispatch(addToAppStore(applicationStore.ADDED_VEHICLES, addedVehicles.filter(e => e?.chassisNumber !== vehicle?.chassisNumber)))
        dispatch(addToAppStore(applicationStore.ADDED_SDMS_VEHICLES, addedSdmsVehicles.filter(e => e?.chassisNumber !== vehicle?.chassisNumber)))
        dispatch(addToAppStore(applicationStore.PLATE_DATA_HELPER, null))

        if (!isOperationModeProtected()) {
            fetchMyVehicles({}, 1, 10)
        }
    }

    const customActionTemplate = (row) => {
        return (
            <div className="actionColumn" style={{display: "flex"}}>
                {selectedVehicle ?
                    <div>
                        <Button icon="pi pi-minus-circle" className="p-button-rounded p-button-danger"
                                onClick={() => removeVehicleFromStore(row)}/>
                        {(!isUseCaseChangeOwnership(appFormValues) || isNotRegisteredUserRole(userRights)) &&
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-secondary"
                                onClick={() => editVehicle(row)}/>}
                    </div> :
                    <Button icon="pi pi-plus-circle" className="p-button-rounded p-button-success"
                            onClick={() => addVehicleToStore(row)}/>}
            </div>
        )
    }

    const columns = [
        {
            field: 'vid',
            header: <Translate value={'label.vid'} />,
            sortable: true
        },
        {
            field: 'chassisNumber',
            header: <Translate value={'label.chassisNumber'} />,
            sortable: true,
            filter: true
        },
        {
            field: 'axles',
            header: <Translate value={'label.numberOfAxles'} />
        },
        {
            field: 'transactionId',
            header: <Translate value={'label.transactionId'} />,
            hidden: true
        },
        {
            field: 'actions'
        },
    ];

    let dynamicColumns = [];


    if (columns.length > 0) {
        dynamicColumns = columns.map((col) => {
            if (col.field == "actions") {
                return <Column key={col.field} field={col.field} header={I18n.t("label.actionTitle")} body={customActionTemplate}
                               style={{width: '10%'}}/>
            } else {
                return <Column {...col} key={col.field} field={col.field} header={col.header}
                               style={{width: col.width ? col.width : "auto"}}/>;

            }
        });
    }

    const editVehicle = (row) => {

        if(isOperationModeProtected() && addedSdmsVehicles && addedSdmsVehicles.length > 0){

            let vehicleForEdit = addedSdmsVehicles.find(e => e.vid === row[formFieldNames.VEHICLE_ID])
            if(vehicleForEdit){
                mapVehicleToFormValues(dispatch, handleCommonServices, vehicleForEdit, appFormValues)
            }
        }


        dispatch(addToAppStore(applicationStore.SHOW_ADD_VEHICLE_FORM, true))
    }

    const isFormValid = () => {
        if ((errorsVD && !isVehicleFromSDMSAdded) || (errorsVI && !isVehicleFromSDMSAdded) || !selectedVehicle){
            return false;
        }
        return true;
    }

    const onNextStep = () => {
        if (!isFormValid()) {
            dispatch(addToAppStore('sourceVehicleStepCompleted', false))
            dispatch(addMultipleErrorToStep(APPLICATION_STEPS.VEHICLE_DATA, {"error": "testerror"}, props.navigationName))
        } else {
            dispatch(addToAppStore('sourceVehicleStepCompleted', true))
            dispatch(addMultipleErrorToStep(APPLICATION_STEPS.VEHICLE_DATA, {}, props.navigationName))
            dispatch(completeStep(props.navigationName, APPLICATION_STEPS.VEHICLE_DATA))
        }
        dispatch(nextStep(props.navigationName))
    }

    const onPrevious = () => {
        if(isOperationModeProtected() && useCase === EUseCases.MODIFY_VEHICLE_DATA) {
            dispatch(previousStep(props.navigationName))
        }
        //dispatch(addToAppStore('questionnariesCompleted', false))
    }

    const showAddVehicle = () => {
        dispatch(addToAppStore(applicationStore.SHOW_ADD_VEHICLE_FORM, !showAddVehicleForm))
        setShowSearchVehicleForm(false)
    }

    const vehicleInformationSteps = [
        {
            id: APPLICATION_STEPS.VEHICLE_IDENTIFICATION,
            label: isMobile() ? null : <Translate value={"label." + i18nl.VEHICLE_IDENTIFICATION} />,
            icon: "pi pi-circle-fill",
            component: <VehicleIdentificationForm navigationName={navigationName.SUB_NAVIGATION}
                                                  formName={forms.VEHICLE_IDENTIFICATION}/>,
        },
        {
            id: APPLICATION_STEPS.TECHNICAL_DESCRIPTION,
            label: isMobile() ? null : <Translate value={"label." + i18nl.TECHNICAL_DESCRIPTION} />,
            icon: "pi pi-circle-fill",
            component: <VehicleDataForm navigationName={navigationName.SUB_NAVIGATION} formName={forms.VEHICLE_DATA}/>,
        },
        {
            id: "summaryInformation",
            label: isMobile() ? null : <Translate value={"label." + i18nl.VEHICLE_IDENTIFICATION} />,
            icon: "pi pi-circle-fill",
            component: <VehicleSummaryTaskWrapper navigationName={navigationName.SUB_NAVIGATION}
                                                  formName={forms.VEHICLE_SUMMARY_INFORMATION}
                                                  setMyVehicleTableData={handleMyVehicleTableState}/>,
        }
    ]

    let addNewVehicleButtonLabel = showAddVehicleForm ? "action.cancelAddNewVehicle" : "action.addNewVehicle"
    let searchVehicleButtonLabel = showSearchVehicleForm ? "action.cancelSearchVehicle" : "action.searchVehicle"
    let displayTableBasedOnUC = isComponentApplicableOnVehicleTaskForUC(useCase);

    let isFirstStep = true;
    if(isOperationModeProtected() && useCase === EUseCases.MODIFY_VEHICLE_DATA && userRights && checkUserRights(MODIFY_PROVINCE_RIGHTS, userRights)){
        isFirstStep = false;
    }

    return (
        <div>
            <div className="card circleNavWrapper" style={{padding: '1rem'}}>
                {isOperationModeProtected() && showSearchVehicleForm && <VehicleSearchWrapper/>}
                {(!displayTableBasedOnUC || selectedVehicle) && !showAddVehicleForm &&
                <DataTable
                    value={addedVehicles}
                    paginator rows={10} rowsPerPageOptions={[10, 20, 50]}
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    emptyMessage={I18n.t("message.noResultsFound")}
                >
                    {dynamicColumns}
                </DataTable>
                }
                {showAddVehicleForm &&
                <NavigationWizardWrapper items={vehicleInformationSteps}
                                         progressBar={false}
                                         readOnly={disableDraft(userRights)}
                                         navigationId={navigationName.SUB_NAVIGATION}
                                         navigationType="CIRCLE"
                                         formName={forms.VEHICLE_DATA}/>
                }
                {!selectedVehicle &&
                <div className="flex justify-content-center mb-5">
                    {(!isModificationVRUseCase(appFormValues) || isNotRegisteredUserRole(userRights)) &&
                        !(isOperationModeProtected() && disableAddIdentitiesButtonListOfUC.includes(appFormValues["useCase"])) &&
                        <Button label={<Translate value={addNewVehicleButtonLabel} />} className="p-button-outlined m-2" type="button"
                            onClick={() => showAddVehicle()}/>}
                    {isOperationModeProtected() &&
                    <Button label={<Translate value={searchVehicleButtonLabel} />} className="m-2" type="button"
                            onClick={() => {
                                setShowSearchVehicleForm(!showSearchVehicleForm);
                                dispatch(addToAppStore(applicationStore.SHOW_ADD_VEHICLE_FORM, false));
                            }}/>}
                </div>
                }
                {!showAddVehicleForm &&
                <NavButtonsLocal firstStep={isFirstStep} customOnPrevious={() => onPrevious()}
                            disableNextBtn={disableNextButton(isFormValid(), userRights)} customOnNext={() => onNextStep()}
                            customOnCancel={() => onCancel(dispatch)}/>
                }
            </div>
        </div>
    )
}
export default SearchVehiclePreview;