/**
 * @author krsticn on 21/09/2018.
 *
 * This file should contain constants of all form and field names
 */
export const forms = Object.freeze({
  E_PASSPORT_FORM: 'EPassportForm',
  E_VISA_FORM: 'EVisaForm',
  ID_CARD_FORM: 'IDCardForm',
  DL_FORM: 'DLForm',
  CONTACT_FORM: 'ContactForm',
  CHECK_STATUS_FORM: 'CheckStatusForm',
  FORM_LOGIN: 'FormLogin',
  REGISTER_ACCOUNT: 'FormRegister',
  EDIT_ACCOUNT: 'FormEditAccount',
  FORM_CHANGE_PASSWORD: 'FormChangePassword',
  FORM_PASSWORD_RECOVERY: 'FormPasswordRecovery',
  BOOK_APPOINTMENT_FORM: 'ScheduleAppointmentForm',
  APPOINTMENTS: 'AppointmentsForm',
  RESCHEDULE_APPOINTMENT_FORM: 'RescheduleAppointmentForm',
  APPLICATION: 'Application',
  PERSONAL_INFORMATION: 'PersonalInformation',
  LEGAL_ENTITY: 'LegalEntity',
  QUESTIONNAIRES: 'Questionnaires',
  QUESTIONNAIRES_VEHICLE: 'QuestionnairesVehicle',
  ADDRESS_DATA: 'AddressData',
  SEARCH_APPLICATION: 'SearchApplication',
  SEARCH_APPLICATION_REPORT: 'SearchApplicationReport',
  DRIVER_LICENSE: 'DriverLicense',
  APPOINTMENTS_SEARCH_FORM: 'AppointmentSearchForm',
  ACCOUNT_RECOVERY: 'AccountRecoveryForm',
  VEHICLE_DATA: 'VEHICLE_DATA',
  PERSON_DATA: 'PERSON_DATA',
  TARGET_DATA: 'TARGET_DATA',
  VEHICLE_IDENTIFICATION: 'VEHICLE_IDENTIFICATION',
  ADDITIONAL_VEHICLE_IDENTIFICATION: 'ADDITIONAL_VEHICLE_IDENTIFICATION',
  ADDITIONAL_VEHICLE_DATA: 'ADDITIONAL_VEHICLE_DATA',
  PLATE_DATA: 'PLATE_DATA',
  PROXY_DATA: 'PROXY_DATA',
  VEHICLE_SEARCH_FORM: 'VehicleSearchForm',
  PERSON_SEARCH_FORM: 'PersonSearchForm',
  LE_SEARCH_FORM: 'LESearchForm',
  VEHICLE_SUMMARY_INFORMATION: 'vehicleSummaryInformation',
  DOCUMENTS_DATA: 'documentsData',
  START_CLIENT_MANAGE_WF: "startClientManageWF",
  APPLICATION_DATA: "ApplicationData"
});
export const formFieldsMeta = Object.freeze({
  WORKFLOW: "workflow",
});

export const EMAIL_REGEX = /^.+@.*\..+$/;
export const PASSWORD_SYSTEM_GENERATED_REGEX = /^[a-zA-Z0-9]{5,}$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}[\]:;<>,.?/~\\-]).{6,12}$/;
export const PHONE_REGEX = /^[+]?[0-9\-().\s]{7,15}$/;
export const PERSONAL_NUMBER_REGEX = /\d{2}[A-z]{2}\d{8}[A-z]{1}|\d{12}[A-z]{1}/;

export const formFieldsQuestionnaire = Object.freeze({
  IS_NEW_DOCUMENT: 'isNewDocument',
  IS_REPLACE_DOCUMENT: 'isReplaceDocument',
  IS_MODIFY_DOCUMENT: 'isModifyDocument',
  IS_EXTEND_DOCUMENT: 'isExtendDocument',
  IS_CANCEL_DOCUMENT: 'isCancelDocument',
  DAMAGED_DOCUMENT: 'damagedDocument',
  ADDITIONAL_QUESTION: 'additionalQuestion',
  FIRST_TIME_APPLY: 'firstTimeApply',
  OLD_DOCUMENT: 'oldDocument',
  FOREIGN_DOCUMENT: 'foreignDocument',
  LOST_DOCUMENT: 'myLostDocument',
  DAMAGED_DOCUMENT2: 'myDamagedDocument',
  EXPIRED_DOCUMENT: 'myExpiredDocument',
  EXPIRED_DOCUMENT2: 'myExpiredDocument2',
  EXPIRED_DOCUMENT3: 'myExpiredDocument3',

});

export const formFieldsVRQuestionnaire = Object.freeze({
  IS_NEW_VR: 'isNewVR',
  IS_REPLACE_VR: 'isReplaceVR',
  IS_MODIFY_VR: 'isModifyVR',
  IS_EXCHANGE_VR: 'isExchangeVR',
  IS_EXTEND_VR: 'isExtendVR',
  IS_CANCEL_VR: 'isCancelVR',
  IS_RESERVE_PLATE_VR: "isReservePlateVR",
  IS_CHANGE_OWNERSHIP_VR: "isChangeOwnershipVR",
  IS_CHANGE_OWNERSHIP_VR_SELL_OR_BUY: "isChangeOwnershipVRSellOrBuy",
  IS_CHANGE_OWNERSHIP_VR_ADD_OWNER: "isChangeOwnershipVRAddOwner",
  IS_CHANGE_OWNERSHIP_VR_REMOVE_OWNER: "isChangeOwnershipVRRemoveOwner",
  IS_CHANGE_OWNERSHIP_VR_CHANGE_OWNERSHIP_SHARES: "isChangeOwnershipVRChangeOwnershipShares",
  IS_CHANGE_OWNERSHIP_VR_CHANGE_LEAD_OWNER: "isChangeOwnershipVRChangeLeadOwner",
  IS_CHANGE_OWNERSHIP_VR_COURT_SELL_OR_BUY: "isChangeOwnershipVRCourtSellOrBuy",
  IS_CHANGE_OWNERSHIP_VR_AUCTION_SELL_OR_BUY: "isChangeOwnershipVRAuctionSellOrBuy",
  DAMAGED_VR: 'damagedVR',
  ADDITIONAL_QUESTION_VR: 'additionalQuestion',
  FIRST_TIME_APPLY_VR: 'firstTimeApply',
  OLD_VR: 'oldVR',
  FOREIGN_VR: 'foreignVR',
  LOST_VR: 'myLostVR',
  DAMAGED_VR2: 'myDamagedVR',
  EXPIRED_VR: 'myExpiredVR',
  EXPIRED_VR2: 'myExpiredVR2',
  EXPIRED_VR3: 'myExpiredVR3',

});

export const formFieldsEVisa = Object.freeze({
  CURRENT_NATIONALITY: 'currentNationality',
  DUAL_NATIONALITY: 'dualNationality',
  FORMER_NATIONALITY: 'formerNationality',
  GENDER: 'gender',
  COUNTRY_OF_BIRTH: 'countryOfBirth',
  PLACE_OF_BIRTH: 'placeOfBirth',
  OCCUPATION: 'occupation',
  NATIONALITY: 'nationality',
  PERMANENT_ADDRESS: 'permanentAddress',
  PASSPORT_NUMBER: 'passportNumber',
  COUNTRY_OF_ISSUE: 'countryOfIssue',
  EMAIL: 'email',
  PHONE: 'phone',
  DATE_OF_ISSUE: 'dateOfIssue',
  DATE_OF_EXPIRY: 'dateOfExpiry',
  DESCRIPTION: 'Description',
  DROPDOWN_PAYMENT_TYPE: 'DropdownPaymentType',
  CARDHOLDER: 'Cardholder',
  CARDNUMBER: 'CardNumber',
  CVC: 'CVC',
  EXP_MONTH_DROPDOWN: 'expMonth',
  EXP_YEAR_DROPDOWN: 'expYear',
  NAME_ON_CARD: 'nameOnCard',
  CARD_NO: 'cardNumber',
  CVV: 'cvv',
  ON_BEHALF_OF_CHILD: 'onBehalfOfChild',
  ON_BEHALF_OF_PROXY: 'onBehalfOfProxy',
  ON_BEHALF_OF: 'OnBehalfOf',
  CAPTCHA_VALUE: 'captchaValue',
  VISA_TYPE: 'visaType',
  VISA_CATEGORY: 'visaCategory',
  DATA_CONFIRMATION: 'dataConfirmation',
  DATE_OF_BIRTH: 'dateOfBirth',
  MARITAL_STATUS: 'maritalStatus',
  IMMIGRATION_STATUS: 'immigrationStatus',
  COUNTRY_OF_RESIDENCE: 'countryOfResidence',
  CITY_OF_RESIDENCE: 'cityOfResidence',
  CURRENT_RESIDENTIAL_ADDRESS: 'currentResidentialAddress',
  EMAIL_CONFIRMATION: 'emailConfirmation',
  PREVIOUS_PASSPORTS: 'previousPassport',
  PLACE_OF_ISSUE: 'placeOfIssue',
  PASSPORT_TYPE: 'passportType',
  CHILDREN_ENDORSED_ON_PASSPORT: 'childrenEndorsedOnPassport',
  PURPOSE_OF_VISIT: 'purposeOfVisit',
  DATE_OF_ARRIVAL: 'dateOfArrival',
  HOST_FULL_NAME: 'hostFullName',
  HOST_ACTING_ON: 'hostActingOn',
  HOST_FULL_ADDRESS: 'hostFullAddress',
  HOST_PHONE_NUMBER: 'hostPhoneNumber',
  TRAVEL_HISTORY: 'travelHistory',
  DENIED_VISA: 'deniedVisa',
  DEPORTED: 'deported',
  CONVICTED: 'convicted',
  CRIMINAL_PROCEEDINGS: 'criminalProceedings',
  INFECTIONS_ILLNESS: 'infectionsIllness',
  APPLICATION_ID: 'applicationId',
  FULL_NAME: 'fullName',
  COUNTRY: 'country',
  FEEDBACK: 'feedback',
  STATUS: 'status',
  SUFFIX_DOC_DATE_OF_ISSUE: 'DocumentDateOfIssue',
  DOCUMENT_TO_UPLOAD: 'uploadDocument',
  PHOTO: 'Photo'
});


export const monthsEn = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const weekdaysLongEn = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const weekdaysShortEn = [
  "Sun", "Mon", "Tue", "Wed", "thu", "Fri", "Sat"
];

export const formFields = Object.freeze({
  //demographic
  NAME: "name",
  SPOUSE_DATA: "spouseData",
  MILITARY_RANK: "militaryRank",
  MILITARY_SERVICE: "militaryService",
  WEAPON_DATA: "weaponData",
  SELECTED_DOCUMENTS: "selectedDocuments",
  DRIVER_LICENSE_DATA: "driversLicenseData",
  GENERIC_FIELDS: 'genericFields',
  CUSTOM_FORM_FIELDS: "customFormFields",
  CUSTOM_FORM_ALL_FIELDS: "allFields",
  CUSTOM_FORM_REQUIRED_FIELDS: "requiredFields",
  CUSTOM_FORM_DATES: "customFormDates",
  VACCINATION_DATA: "vaccinationData",
  PERSONAL_NUMBER: 'personalNumber',
  PERSONAL_ID_NUMBER: 'personalIdNumber',
  PERSONAL_NUMBER_TYPE: 'personalNumberType',
  PERSONAL_ID_TYPE: 'personalIdType',
  FACE_PIN: 'pin',
  EMAIL_ISSUING: 'emailIssuing',
  AVAILABLE_STATE: 'availableStates',
  AVAILABLE_STATE_LABEL: 'availableStateLabel',
  STATE_START_DATE: 'stateStartDate',
  STATE_VALIDITY_DATE: 'stateValidityDate',
  STATE_COMMENT: 'stateComment',
  STATE_REASON: 'stateReason',
  STATE_ARRAY: 'stateArray',
  STATE_TO_EDIT: 'stateToEdit',
  STATE_VALUE: 'stateValue',
  REMOVED_STATES: 'removedStates',

  //address details
  ADDRESS_STREET_HOUSE_NUMBER: "addressHouseNumber",
  ADDRESS_DISTRICT: "addressDistrict",

  //contact details
  EMAIL: "email",

  //BIOMETRIC
  signatureTask: {
    CAPTURED_DATA: "signatureCapturedData",
    LIVE_STREAM_DATA: "signatureLiveStreamData",
    WORKFLOW_CAPTURE_TYPE: "signatureWorkflowCaptureType",
    TASK_NOT_COMPLETE: "signatureTaskNotComplete"
  },
  physicalLimitations: {
    PHYSICAL_LIMITATION: "physicalLimitation"
  },
  irisTask: {
    CAPTURED_DATA: "irisCapturedData",
    TASK_NOT_COMPLETE: "irisTaskNotComplete"
  },
  faceTask: {
    CAPTURED_DATA: "capturedFaceData",
    PROCESSED_FACE_DATA: "processedFaceData"
  },
  fingerprintTask: {
    CAPTURED_DATA: "capturedFingerprintData",
  },
  supportingDocumentsTask: {
    SUPPORTING_DOCUMENTS_DATA: "supportingDocumentsData",
    PAYMENT_RECEIPT: "Payment receipt",
    BIRTH_CERTIFICATE: "Birth certificate",
    PAYMENT_TAX: "Payment tax"
  },
  //Place of Birth
  placeOfBirth: {
    PLACE_OF_BIRTH_CITY: "placeOfBirthCity",
    PLACE_OF_BIRTH_PROVINCE: "placeOfBirthProvince",
    PLACE_OF_BIRTH_COUNTRY: "placeOfBirthCountry"
  }
});

export const DRIVER_LICENSE_PROPERTIES = Object.freeze({
  DL_CATEGORY: "className",
  DL_ISSUING_DATE: "validFromDate",
  DL_EXPIRY_DATE: "validUntilDate",
  DL_RESTRICTIONS: "restrictions",
  DL_DISABLED: "dldDisabled",
  ID: "id",
});

export const navigationName = Object.freeze({
  MAIN_NAVIGATION: "mainNavigation",
  SUB_NAVIGATION: "subNavigation",
  SUB_NAVIGATION_TARGET: "subNavigationTarget"
});

export const entityTypes = Object.freeze(
    {
      PERSON: "PERSON",
      VEHICLE: "VEHICLE",
      LEGAL_ENTITY: "LE",
    }
);