import {Button, FormElement, fieldType, getFormValues, change} from "@mb-react/mb-react-core";
import {
  EApplicationStatus,
  EUseCases,
  formFieldNames,
} from "@mb-react/svarog-ui";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import { I18n } from "react-redux-i18n";
import { forms } from "../../../constants/form";
import { getPath } from "../../../constants/i18n";
import {getVRUseCases} from "../../../constants/application";
import {useForm} from "react-final-form";

const MyApplicationsSearchTask = () => {
  const formValues = useSelector((state) => getFormValues(state, forms.ADDRESS_DATA))

  const form = useForm();

  const getApplicationStatusDropdown = () => {
    let appStatus = [
      {
        label: I18n.t(getPath(EApplicationStatus.CREATED)),
        value: EApplicationStatus.CREATED,
      },
      {
        label: I18n.t(getPath(EApplicationStatus.CREATED_INCOMPLETE)),
        value: EApplicationStatus.CREATED_INCOMPLETE,
      },
      {
        label: I18n.t(getPath(EApplicationStatus.APPROVED)),
        value: EApplicationStatus.APPROVED,
      },
    ];
    return appStatus;
  };

  const getApplicationTypeDropdown = () => {
    let applicationType = [
      {
        label: I18n.t("label.driverLicense"),
        value: "DL",
      },
      {
        label: I18n.t("label.vehicleRegistration"),
        value: "VR",
      },
    ]
    return applicationType
  }

  const getUseCaseDropdown = () => {
    let dlUseCases = [
      {
        label: I18n.t("label." + EUseCases.NEW),
        value: EUseCases.NEW,
      },
      {
        label: I18n.t("label." + EUseCases.RE_REGISTER),
        value: EUseCases.RE_REGISTER,
      },
      {
        label: I18n.t("label." + EUseCases.EXTEND),
        value: EUseCases.EXTEND,
      },
      {
        label: I18n.t("label." + EUseCases.EXPIRED),
        value: EUseCases.EXPIRED,
      },
      {
        label: I18n.t("label." + EUseCases.FOREIGN),
        value: EUseCases.FOREIGN,
      },
      {
        label: I18n.t("label." + EUseCases.REPLACE),
        value: EUseCases.REPLACE,
      },
      {
        label: I18n.t("label." + EUseCases.PARTLY_DAMAGED),
        value: EUseCases.PARTLY_DAMAGED,
      },
      {
        label: I18n.t("label." + EUseCases.MODIFICATION),
        value: EUseCases.MODIFICATION,
      },
      {
        label: I18n.t("label." + EUseCases.CANCEL_DOCUMENT),
        value: EUseCases.CANCEL_DOCUMENT,
      },
    ];
    return dlUseCases;
  };

  return (
      <div>
          <div className="grid appSearchForm">
            <div className="col-12 xl:col-4 ">
              <FormElement
                  label="label.givenName"
                  fieldType={fieldType.INPUT_TEXT}
                  fieldProps={{ name: formFieldNames.GIVEN_NAME }}
              />
            </div>
            <div className="col-12 xl:col-4 ">
              <FormElement
                  label="label.surname"
                  fieldType={fieldType.INPUT_TEXT}
                  fieldProps={{ name: formFieldNames.SURNAME }}
              />
            </div>
            <div className="col-12 xl:col-4">
              <FormElement
                  label="label.applicationId"
                  fieldType={fieldType.INPUT_TEXT}
                  fieldProps={{ name: "applicationId" }}
              />
            </div>
            <div className="col-12 xl:col-4 ">
              <FormElement
                  label="label.applicationType"
                  fieldType={fieldType.INPUT_DROPDOWN}
                  fieldProps={{
                    name: formFieldNames.APPLICATION_TYPE,
                    onChange: () => {
                      form.change(formFieldNames.USE_CASE, null);
                    },
                    options: getApplicationTypeDropdown(),
                    showClear: true,
                  }}
              />
            </div>
            <div className="col-12 xl:col-4 ">
              <FormElement
                  label="label.useCase"
                  fieldType={fieldType.INPUT_DROPDOWN}
                  fieldProps={{
                    name: formFieldNames.USE_CASE,
                    options: formValues?.[formFieldNames.APPLICATION_TYPE] == "VR" ? getVRUseCases() : getUseCaseDropdown(),
                    readOnly: (!formValues?.[formFieldNames.APPLICATION_TYPE] && !formValues?.[formFieldNames.USE_CASE]),
                    filter: true,
                    showClear: true,
                  }}
              />
            </div>
            <div className="col-12 xl:col-4 ">
              <FormElement
                  label="label.status"
                  fieldType={fieldType.INPUT_DROPDOWN}
                  fieldProps={{
                    name: formFieldNames.STATE,
                    options: getApplicationStatusDropdown(),
                    filter: true,
                    showClear: true,
                  }}
              />
            </div>
            <div className="col-12 xl:col-4">
              <Button
                  type="submit"
                  label={I18n.t("action.search")}
                  className="p-button-primary"
                  icon="pi pi-search"
              />
            </div>
          </div>
      </div>
  );
};
export default MyApplicationsSearchTask;
