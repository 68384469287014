import { Button, Captcha, FormElement, MBForm, fieldType } from "@mb-react/mb-react-core";
import {
  AuthenticationService,
  ServiceRequestData,
  formFieldNames,
  isRequired,
  useCreateServiceWrapper
} from '@mb-react/svarog-ui';
import React, { useEffect, useState } from "react";
import { Translate } from "react-redux-i18n";
import { forms } from "../../../constants/form";
import { TRG, getPath, i18nl } from "../../../constants/i18n";
import { useHandleAccountServices } from "../../../services/account/accountServiceHandler";
import {
  useHandleLoginErrorResult,
  useHandleLoginSuccessResult
} from "../../../services/authentication/handleAuthServiceResponse";
import krLogo1 from "../../../../public/media/KRG_logo.svg";

const LoginPublic = () => {
  const serviceWrapper = useCreateServiceWrapper();
  const handleAccountServices = useHandleAccountServices();

  const handleSuccessResponse = useHandleLoginSuccessResult();
  const handleErrorResponse = useHandleLoginErrorResult();

  const localErrorHandler = (error) => {
    handleErrorResponse(error)
    getCaptcha();
  }

  const [captchaData, setCaptchaData] = useState();
  const [captchaUrl, setCaptchaUrl] = useState(null);
  const submitLogin = (data, form) => {
    if (data != null && captchaData != null) {
      const loginParams = {
        username: data.username,
        password: data.password,
        accountMode: "PUBLIC",
        captcha: data.captcha,
        captchaId: captchaData.id,
      };
      serviceWrapper(new ServiceRequestData(
        AuthenticationService.loginAccount,
        loginParams,
        handleSuccessResponse,
        handleErrorResponse,
        null,
        null,
        null,
        null,
        null,
        localErrorHandler,
      ))
    }
    form.change(formFieldNames.CAPTCHA, '')
  };
  const prepareCaptchaImage = (data) => {
    if (data != null) {
      return "data:image/jpg;base64," + data.base64;
    }
    return null;
  };
  const prepareCaptcha = (data) => {
    if (data) {
      setCaptchaData(data);
      setCaptchaUrl(prepareCaptchaImage(data));
    }
  }
  const getCaptcha = () => {
    handleAccountServices.handleGetCaptcha(prepareCaptcha)
  };

  useEffect(() => {
    getCaptcha();
  }, []);

  const validationFunc = (values) => {
    let errors = {}

    isRequired(values, formFieldNames.USERNAME, errors)
    isRequired(values, formFieldNames.PASSWORD, errors)
    isRequired(values, formFieldNames.CAPTCHA, errors)

    return errors
  }

  return (
    <MBForm formName={forms.REGISTER_ACCOUNT} submitFunction={(data, form) => {
      submitLogin(data, form)
    }
    } validate={validationFunc}>
      <div className="flex align-items-center justify-content-center mt-2">
        <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div className="text-center mb-5">
            <div className="logoWithTagline">
              <img src={krLogo1} alt="Logo" width={"120px"}/>
              <div className="tagline"><Translate value="label.headerLogo"/></div>
              <div className="tagline"><Translate value="label.headerLogo1"/></div>
              <div className="tagline"><Translate value="label.headerLogo2"/></div>
            </div>
            <div className="text-900 text-5xl font-bold mb-3"><Translate
                value={"message.walcomeBack"}/></div>
            <span className="text-600 font-medium line-height-3"><Translate
                value={"message.dontHaveAccount"} /></span>
            <a href={"/registerAccount"}
              className="font-medium no-underline ml-2 text-blue-500 cursor-pointer"><Translate
                value={"message.createToday"} /></a>
          </div>

          <div>
            <FormElement
              label='label.username'
              fieldType={fieldType.INPUT_TEXT}
              required
              fieldProps={{ name: formFieldNames.USERNAME }}
            />
            <FormElement
              label='label.password'
              fieldType={fieldType.PASSWORD}
              required
              fieldProps={{
                name: formFieldNames.PASSWORD,
                feedback: false,
                toggleMask: true,
              }}
            />
            <Captcha
              captchaURL={captchaUrl}
              fieldName={formFieldNames.CAPTCHA}
              onRefreshCaptcha={() => getCaptcha()}
              captchaPlaceholder={false}
            />
            <div className="flex align-items-center justify-content-between mb-3">
              <div className="flex align-items-center">
              </div>
              <a href={"/forgotPassword"}
                className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"><Translate
                  value={getPath(i18nl.FORGOT_USERNAME_PASSWORD, TRG.MESSAGE)} /></a>
            </div>

            <Button label={<Translate value="action.login" />} type="submit"
              icon="pi pi-user" className="w-full" />
          </div>
        </div>
      </div>
    </MBForm>
  );
};
export default LoginPublic;
