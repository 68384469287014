import React from "react"
import { APPLICATION_STEPS } from "../../../constants/store";
import SearchVehiclePreview from "../../tasks/workflowTasks/vehicle/searchData/SearchVehiclePreview";
import { forms, navigationName } from "../../../constants/form";
import {I18n, Translate} from "react-redux-i18n";
import { getPath, i18nl } from "../../../constants/i18n";
import SearchAdditionalVehiclePreview from "../../tasks/workflowTasks/vehicle/searchData/SearchAdditionalVehiclePreview";
import SearchPersonPreview from "../../tasks/workflowTasks/vehicle/searchData/SearchPersonPreview";
import PreSummaryForm from "../../tasks/workflowTasks/document/personalData/PreSummaryForm";
import LegalEntityForm from "../../tasks/workflowTasks/document/legalEntityData/LegalEntityForm";
import TargetOwnerPreview from "../../tasks/workflowTasks/vehicle/searchData/TargetOwnerPreview";
import PlateDataForm from "../../tasks/workflowTasks/vehicle/plateData/PlateDataForm";
import RequestedDocumentDataForm from "../../tasks/workflowTasks/document/requestedDocumentData/RequestedDocumentDataForm";
import ProxyDataForm from "../../tasks/workflowTasks/proxy/prroxyDataForm";
import SummaryTaskWrapperVR from "../../tasks/workflowTasks/common/summary/SummaryTaskWrapperVR";
import ApplicationSearchWrapper from "../../tasks/workflowTasks/vehicle/searchData/ApplicationSearchWrapper";
import SummaryTaskWrapperVRTX from "../../tasks/workflowTasks/common/summary/SummaryTaskWrapperVRTX";
export function handleWorkflowTasks() {
    const navigationItems = () => {
        return {
            SEARCH_APPLICATION_STEP: {
                id: APPLICATION_STEPS.SEARCH_APPLICATION_STEP,
                label: <Translate value="label.searchApplicationStep" />,
                icon: 'pi pi-car',
                component: <ApplicationSearchWrapper />,
            },
            VEHICLE_DATA: {
                id: APPLICATION_STEPS.VEHICLE_DATA,
                label: <Translate value="label.sourceVehicle" />,
                icon: 'pi pi-car',
                component: <SearchVehiclePreview navigationName={navigationName.MAIN_NAVIGATION} />,
            },
            TARGET_VEHICLE: {
                id: APPLICATION_STEPS.TARGET_VEHICLE,
                label: <Translate value={"label." + i18nl.TARGET_VEHICLE} />,
                icon: 'pi pi-car',
                component: <SearchAdditionalVehiclePreview navigationName={navigationName.MAIN_NAVIGATION} />,
            },
            PERSONAL_DATA: {
                id: APPLICATION_STEPS.PERSONAL_DATA,
                label: <Translate value={"label." + i18nl.SOURCE_OWNER} />,
                icon: 'pi pi-user',
                component: <SearchPersonPreview navigationName={navigationName.MAIN_NAVIGATION} formNameSufix={'Source'} applicationStep={APPLICATION_STEPS.PERSONAL_DATA} />,
            },
            PERSONAL_DATA_RO: {
                id: APPLICATION_STEPS.PERSONAL_DATA,
                label: <Translate value={"label." + i18nl.PERSONAL_INFORMATION} />,
                icon: 'pi pi-user',
                component: <PreSummaryForm navigationName={navigationName.MAIN_NAVIGATION} formName={forms.PERSONAL_INFORMATION} />
            },
            LEGAL_ENTITY: {
                id: APPLICATION_STEPS.LEGAL_ENTITY,
                label: <Translate value={"label." + i18nl.LEGAL_ENTITY} />,
                icon: 'pi pi-user',
                component: <LegalEntityForm navigationName={navigationName.MAIN_NAVIGATION} formName={forms.LEGAL_ENTITY} />
            },
            TARGET_OWNER: {
                id: APPLICATION_STEPS.TARGET_OWNER,
                label: <Translate value={"label." + i18nl.TARGET_OWNER} />,
                icon: 'pi pi-user',
                component: <TargetOwnerPreview navigationName={navigationName.MAIN_NAVIGATION} formNameSufix={'Target'} applicationStep={APPLICATION_STEPS.TARGET_OWNER} />,
            },
            PLATE_HANDLING: {
                id: APPLICATION_STEPS.PLATE_HANDLING,
                label: <Translate value={"label." + i18nl.PLATE_HANDLING} />,
                icon: 'pi pi-id-card',
                component: <PlateDataForm navigationName={navigationName.MAIN_NAVIGATION} formName={forms.PLATE_DATA} />,
            },
            DOCUMENTS_DATA: {
                id: APPLICATION_STEPS.DOCUMENTS_DATA,
                label: <Translate value={"label." + i18nl.DOCUMENTS_DATA} />,
                icon: 'pi pi-id-card',
                component: <RequestedDocumentDataForm navigationName={navigationName.MAIN_NAVIGATION} formName={forms.DOCUMENTS_DATA} />,
            },
            PROXY_DATA: {
                id: APPLICATION_STEPS.PROXY_DATA,
                label: <Translate value={"label." + i18nl.PROXY_DATA} />,
                icon: 'pi pi-id-card',
                component: <ProxyDataForm navigationName={navigationName.MAIN_NAVIGATION} formName={forms.PROXY_DATA} />,
            },
            CONFIRMATION_TASK: {
                id: APPLICATION_STEPS.CONFIRMATION_TASK,
                label: <Translate value={"label." + i18nl.SUMMARY} />,
                icon: 'pi pi-file',
                component: <SummaryTaskWrapperVR navigationName={navigationName.MAIN_NAVIGATION} />
            },
            CONFIRMATION_TASK_TX: {
                id: APPLICATION_STEPS.CONFIRMATION_TASK,
                label: <Translate value={"label." + i18nl.SUMMARY} />,
                icon: 'pi pi-file',
                component: <SummaryTaskWrapperVRTX navigationName={navigationName.MAIN_NAVIGATION} />
            },
        }

    };
    return {
        navigationItems: navigationItems
    }
}

