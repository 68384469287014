import {addToDictionaryStore} from "@mb-react/mb-react-core";
import {useDispatch} from "react-redux";

export function useHandleGetDictionarySuccessResult() {
    const dispatch = useDispatch();
    const handleResponse = (response) => {
        if (response) {
            if (response.length > 0) {
                response.forEach(element => {
                    dispatch(addToDictionaryStore(element.key, element.items));
                });
            }
        }
    };
    return handleResponse

}

export function useHandleGetSingleDictionarySuccessResult() {
    const dispatch = useDispatch();
    const handleResponse = (response, request) => {
        if (response) {
            let referenceKey = response.key
            if (request.reference) {
                referenceKey = request.reference
            }
            dispatch(addToDictionaryStore(referenceKey, response.items));
        }
    };
    return handleResponse

}