import React from "react";



export const VehicleIcon = () => {

    return (
        <div className="docImageWrap">
            <svg version="1.1" id="Layer_1" className="docImageSVGWrap" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 842.1 1018.6" space="preserve">
                <path class="fillPathWthite" d="M425.6,52.6v122.2l-8,0.2V52.7L425.6,52.6z" fill="rgb(85, 73, 148)" />
                <path class="fillPathWthite" d="M426.6,843.9v122h-8V844L426.6,843.9z" fill="rgb(85, 73, 148)" />
                <path class="st1" d="M10.5,384.5c-0.1-73.7,0.6-147.8-0.5-221.7c0-19.3,6.1-28.2,24.5-34.2c123.6-38,247.1-76.7,370.3-116
	c10.7-3.6,22.2-3.6,32.9,0c122.2,39,244.4,78,367.1,115.3c22.1,6.8,27.3,17.7,27.3,38.8c-0.8,131.9-1.7,263.8,0,395.6
	c1.8,145.3-69.2,250.4-180,333.6c-64.1,47.5-135.7,84-211.8,107.8c-9.8,3.1-18.8,6.8-29.6,3.3C267.8,958.2,139.8,888,59.1,753.8
	c-31.2-50.5-48-108.6-48.5-168C10,518.6,10.5,451.5,10.5,384.5z"
                    fill="none" stroke="rgb(85, 73, 148)" strokeMiterlimit="10" strokeWidth="20" />
                <g>
                    <path class="fillPathWthite" d="M650.3,484.1c3.3,4,6.2,7.4,9.1,10.8c14.1,16.6,21.4,35.7,21.3,57.6c0,44.6,0,89.2,0,133.8
		c0,16.6-10.3,27-26.9,27.1c-16.7,0.1-33.3,0.1-50,0c-17.1-0.1-27.4-10.4-27.4-27.4c0-5.1,0-10.3,0-15.9c-103.7,0-206.7,0-310.6,0
		c0,5.7,0,11.3,0,16.9c-0.2,15.4-10.5,26.1-25.7,26.3c-17.8,0.2-35.6,0.3-53.4,0c-14.8-0.2-25.2-11.1-25.3-26
		c-0.1-42.6,0.5-85.2-0.2-127.7c-0.5-27.7,8-51.2,27-71.1c1.1-1.1,2-2.3,3.8-4.4c-6.5-2-12.3-3.9-18.2-5.7c-7.3-2.2-14.7-4.2-22-6.6
		c-14.3-4.8-22.8-18.2-20.7-32.4c2.2-15,13.7-25.7,29-26.2c12.8-0.4,25.7-0.5,38.5,0c12.9,0.5,21.8,7.5,27.4,19.9
		c1.2-1.9,2.2-3.2,3-4.7c12.1-23.4,24.8-46.5,36-70.3c11.5-24.4,29.4-38.3,56.1-43.3c69.5-13.1,138.7-13.1,207.8,1.9
		c20.8,4.5,35.5,16.6,45.1,35.3c13,25.4,26.1,50.8,39.1,76.2c0.7,1.4,1.4,2.7,2.6,4.9c1.2-2,2-3.4,2.8-4.8
		c5.9-9.8,14.7-15.1,26.1-15.3c12.2-0.2,24.3-0.2,36.5,0c15.6,0.2,27.4,10.6,29.8,25.9c2.2,14.1-6.1,27.8-20.4,32.6
		c-10.9,3.6-21.9,6.7-32.9,10C655.4,482.2,653.2,483.1,650.3,484.1z M595.1,669c0,6.2,0,11.6,0,16.9c-0.1,5.9,2.7,8.5,8.7,8.4
		c16-0.2,32-0.1,48-0.1c8.9,0,10.3-1.3,10.3-10c0-43.9,0.1-87.8,0-131.8c0-16.8-5.5-31.8-16.4-44.7c-5.7-6.7-11.5-13.3-17.3-19.9
		c-7.7-8.8-5.9-15.4,5.4-18.8c16.4-4.9,32.8-9.7,49.1-14.7c6.8-2.1,10.3-7.1,9.6-12.9c-0.8-5.9-5.4-9.6-12.6-9.7
		c-11.3-0.1-22.5,0.1-33.8-0.1c-6.2-0.1-10.2,2.7-12.5,8.4c-2.6,6.5-5.1,13-8,19.3c-3.4,7.3-12.2,7.8-16.7,1.1
		c-1.6-2.4-2.9-5.1-4.2-7.7c-16-31-31.9-62-47.9-93c-7-13.6-17.9-22-33.1-25.2c-62.4-13.4-124.9-13.3-187.9-3.9
		c-28.1,4.2-46.6,16.7-58.2,43.1c-12.7,29-28.7,56.5-43.1,84.7c-2.1,4.2-5.4,7.6-10,6.2c-3.3-1-6.8-4-8.6-7.1
		c-3.2-5.6-5.2-11.9-7.7-18c-2.1-5.1-5.7-7.9-11.4-7.9c-11.7,0-23.4-0.1-35.1,0.1c-6.4,0.1-10.8,3.1-12.1,9.6
		c-1.2,5.7,2.6,10.9,9.4,12.9c16.3,5,32.7,9.8,49.1,14.7c11.4,3.4,13.3,9.9,5.7,18.7c-5,5.8-9.9,11.7-15.1,17.3
		c-13,14.1-18.8,30.8-18.8,49.9c0.2,39.2,0,78.4,0.1,117.6c0,5-0.1,9.9,0.1,14.9c0.1,4.2,2.4,6.8,6.7,6.8c17.8,0,35.6,0.2,53.4-0.3
		c2.3-0.1,6.1-3.2,6.4-5.2c0.8-6.3,0.3-12.8,0.3-19.8c-7,0-13,0.2-19.1-0.1c-5.4-0.3-8.1-3.8-8.1-9c0-5.1,2.7-8.5,8.1-9.1
		c2-0.2,4-0.1,6.1-0.1c125,0,250,0,375,0c1.4,0,2.7,0,4.1,0c5.9,0.1,9.3,3.2,9.6,9c0.4,5.7-3.4,9.1-10,9.3
		C607,669.1,601.4,669,595.1,669z"
                        fill="rgb(85, 73, 148)" />
                    <path class="fillPathWthite" fill="rgb(85, 73, 148)" d="M421.1,476.7c-53.2,0-106.3,0-159.5,0c-14,0-17.6-5.6-11.5-18.1c14.1-28.9,28.5-57.6,42.4-86.6
		c6.1-12.7,15.6-20.4,29.2-23.6c66.5-15.8,132.9-15.8,199.3,0.2c12.9,3.1,22.1,10.3,27.9,22.3c14.5,29.7,29.2,59.3,43.7,89
		c5.3,10.8,1.3,16.9-10.8,16.9C528.3,476.7,474.7,476.7,421.1,476.7C421.1,476.7,421.1,476.7,421.1,476.7z M570,457.4
		c-0.8-2.1-1.3-3.5-1.9-4.9c-11.9-24.2-23.9-48.4-35.6-72.7c-3.5-7.3-8.9-11.5-16.6-13.3c-63.3-15.1-126.5-15.2-189.8,0.1
		c-7.8,1.9-13,6.2-16.5,13.4c-11.6,24.1-23.5,48-35.3,72c-0.8,1.5-1.2,3.2-2,5.3C371.6,457.4,470.3,457.4,570,457.4z"/>
                    <path class="fillPathWthite" fill="rgb(85, 73, 148)" d="M287.4,549c0,22.8-18.6,41.3-41.3,41.2c-22.8-0.2-41.1-18.8-40.9-41.6c0.2-22.4,18.6-40.8,41-40.8
		C269,507.7,287.4,526.1,287.4,549z M246.5,571.2c12.4-0.1,22.3-10,22.2-22.3c0-12.6-10.1-22.4-22.9-22.3
		c-12.2,0.2-22.1,10.4-21.9,22.6C224.1,561.6,234.1,571.3,246.5,571.2z"/>
                    <path class="fillPathWthite" fill="rgb(85, 73, 148)" d="M554.7,548.5c0.2-22.7,19-41.1,41.7-40.8c22.3,0.3,40.5,18.8,40.5,41.3c0,22.7-18.7,41.3-41.4,41.1
		C572.8,589.9,554.5,571.3,554.7,548.5z M595.6,571.2c12.8,0,22.7-9.9,22.6-22.5c-0.1-12.3-10-22-22.5-22
		c-12.3,0-22.3,9.9-22.3,22.2C573.5,561.2,583.3,571.2,595.6,571.2z"/>
                    <path class="fillPathWthite" fill="rgb(85, 73, 148)" d="M421.1,619.2c-28.1,0-56.2,0-84.3,0c-2.2,0-5.1,0.7-6.6-0.4c-2.9-2.2-6.5-5.1-7.3-8.3c-1.1-4.5,2-8.1,6.7-9.4
		c1.9-0.5,4-0.3,6-0.3c56.9,0,113.8,0,170.7,0c1.8,0,3.6-0.1,5.4,0.2c4.9,0.9,7.7,4.1,7.7,8.8c0,4.9-2.5,8.6-8,9.2
		c-2.7,0.3-5.4,0.2-8.1,0.2C476,619.2,448.6,619.2,421.1,619.2z"/>
                </g>
            </svg>


        </div>
    )
}