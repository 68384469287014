import { AccountService, ServiceRequestData, useCreateServiceWrapper } from "@mb-react/svarog-ui";
import {
  useChangePasswordErrorResult,
  useCheckCaptchaErrorResult,
  useEditAccountErrorResult,
  useHandleAccountRecoveryChangePasswordErrorResult,
  useHandleCaptchaErrorResult,
  useHandleRegisterAccountErrorResult
} from "./handleAccountServiceResponse";
import {LocalAccountService} from "./LocalAccountService";

export function useHandleAccountServices() {
  const callService = useCreateServiceWrapper();
  const handleCaptchaErrorResponse = useHandleCaptchaErrorResult();
  const handleRegisterAccountErrorResponse = useHandleRegisterAccountErrorResult();
  const editAccountErrorResponse = useEditAccountErrorResult();
  const changePasswordErrorResponse = useChangePasswordErrorResult();
  const handleAccountRecoveryChangePasswordErrorResponse = useHandleAccountRecoveryChangePasswordErrorResult();
  const checkCaptchaErrorResponse = useCheckCaptchaErrorResult();

  const handleCallGetCaptcha = (additionalOnSuccess) => {
    callService(new ServiceRequestData(
      AccountService.getCaptcha,
      null,
      null,
      handleCaptchaErrorResponse,
      additionalOnSuccess,
      null
    ))
  };

  const handleCallRegisterAccount = (registerParams, additionalOnSuccess, additionalOnError) => {
    callService(new ServiceRequestData(
      AccountService.registerAccountPublic,
      registerParams,
      null,
      handleRegisterAccountErrorResponse,
      additionalOnSuccess,
      additionalOnError
    ));
  };

  const handleCallRegisterAccountProtected = (registerParams, additionalOnSuccess, additionalOnError) => {
    callService(new ServiceRequestData(
      AccountService.registerAccountProtected,
      registerParams,
      null,
      handleRegisterAccountErrorResponse,
      additionalOnSuccess,
      additionalOnError
    ));
  };

  const handleCallGetAccountById = (getAccountDataParams, additionalOnSuccess, additionalOnError) => {
    callService(new ServiceRequestData(
      AccountService.getAccountDataById,
      getAccountDataParams,
      null,
      editAccountErrorResponse,
      additionalOnSuccess,
      additionalOnError
    ));
  };

  const handleCallEditAccount = (editAccountParams, additionalOnSuccess, additionalOnError) => {
    callService(new ServiceRequestData(
      AccountService.editAccount,
      editAccountParams,
      null,
      editAccountErrorResponse,
      additionalOnSuccess,
      additionalOnError
    ));
  };

  const handleCallChangePassword = (changePasswordParams, additionalOnSuccess, additionalOnError) => {
    callService(new ServiceRequestData(
      AccountService.changePassword,
      changePasswordParams,
      null,
      changePasswordErrorResponse,
      additionalOnSuccess,
      additionalOnError
    ))
  };

  const handleCallRecoveryChangePass = (accountRecoveryChangePasswordParams, additionalOnSuccess, additionalOnError) => {
    callService(new ServiceRequestData(
      AccountService.accountRecoveryPublic,
      accountRecoveryChangePasswordParams,
      null,
      handleAccountRecoveryChangePasswordErrorResponse,
      additionalOnSuccess,
      additionalOnError,
      null,
      null,
      true,
      additionalOnError
    ));
  };

  const handleCallCheckCaptcha = (params, additionalOnSuccess, onLocalError) => {
    callService(new ServiceRequestData(
        LocalAccountService.checkChaptcha,
        params,
        null,
        null,
        additionalOnSuccess,
        onLocalError,
        null,
        null,
        true,
        onLocalError
    ))
  };

  return {
    handleGetCaptcha: handleCallGetCaptcha,
    handleCheckCaptcha: handleCallCheckCaptcha,
    handleRegisterAccount: handleCallRegisterAccount,
    handleRegisterAccountProtected: handleCallRegisterAccountProtected,
    handleGetAccountById: handleCallGetAccountById,
    handleEditAccount: handleCallEditAccount,
    handleChangePassword: handleCallChangePassword,
    handleRecoveryChangePass: handleCallRecoveryChangePass,
  }
}