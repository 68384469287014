import {useDispatch} from "react-redux";
import {addInitialValueToForm, addToAppStore, openModal} from "@mb-react/mb-react-core";
// import {applicationStore} from "../../constants/store";
import {change} from "redux-form";
import {forms} from "../../constants/form";
import {genericFormFieldNames} from "@mb-react/krg-getid-form-data-common";
import {modalIDs} from "@mb-react/svarog-ui";

// export function useHandleSearchVehiclesSuccessResult() {
//   const dispatch = useDispatch();
//   return (response) => {
//     if (response) {
//       dispatch(addToAppStore(applicationStore.VEHICLES_DATA, response));
//     }
//   }
// }
//
// export function useHandleSearchVehiclesSHSuccessResult() {
//   const dispatch = useDispatch();
//   return (response) => {
//     if (response && response.length > 0) {
//       let vehiclesResponse = response.map(element => element?.applicationDTO?.applicationDetailsDTO?.vehicleDTOList?.[0]);
//       let vehicleStateHandlingStates = response.map(element => element.stateHandlingStateDTOList);
//       dispatch(addToAppStore(applicationStore.VEHICLE_STATE_HANDLING_STATES, vehicleStateHandlingStates));
//       dispatch(addToAppStore(applicationStore.VEHICLES_DATA_SH, vehiclesResponse));
//       dispatch(addToAppStore(applicationStore.VEHICLES_DATA, vehiclesResponse));
//     }
//   }
// }

export function useHandleGetAvailableLicenseNumbersSuccessResult() {
  const dispatch = useDispatch();

  return (response) => {
    if (Array.isArray(response) && response.length > 0) {
      dispatch(addInitialValueToForm(forms.APPLICATION, genericFormFieldNames.AVAILABLE_PLATE_NUMBER_LATIN, response?.[0]));
    }
  };
}

export function useHandleGetAvailableLicenseNumbersErrorResult() {
  const dispatch = useDispatch();

  return () => {
    dispatch(addInitialValueToForm(forms.APPLICATION, genericFormFieldNames.AVAILABLE_PLATE_NUMBER_LATIN, null));
    dispatch(openModal(
      modalIDs.ERROR,
      {messageId: "err.GENERAL_SERVER_ERROR"}
    ));
  };
}