import 'polyfill-object.fromentries';
import {identificationNumberTypes, formFieldNames} from "@mb-react/svarog-ui";

export function mapOwnerDtoToApplicantData (owner) {
    return {
        demographicDataDTO: extractOwnerToDemographicData(owner),
        ownershipDTO: extractOwnerToOwnershipData(owner),
        genericFieldsDTO: extractGenericFields(owner),
        //legalEntityDTO: extractLegalEntityData(owner, null),
        applicantType: owner["ownerType"],
        applicantSubType: owner[formFieldNames.APPLICANT_SUB_TYPE],
        applicantState: owner[formFieldNames.APPLICANT_STATE]
    }
}

function extractOwnerToDemographicData(owner){
    return {
        personDataDTO: extractOwnerToPersonData(owner),
        linkedPersons: extractLinkedPersonsData(owner)
    }
}

function extractOwnerToPersonData(owner){
    return {
        givenName: owner["name1"],
        surname: owner["name2"],
        identificationNumbers: extractPersonIdentificationNumberData(owner),
    }
}

function extractPersonIdentificationNumberData(owner){
    let idNumbers = []
    idNumbers.push({
        personalNumber: owner["idNumber1"],
        personalNumberType: identificationNumberTypes.TPID
    })
    return idNumbers
}

function extractLinkedPersonsData(owner) {
    let linkedPerson = [];
    linkedPerson.push({
        linkedType: "FATHER",
        givenName: owner["name3"]
    })
    return linkedPerson
}

function extractGenericFields(owner){
    let genericFields = [];
    genericFields.push({key: "fatherNameLatin", value: owner["name3"], genericFieldDataType: "STRING"}, {key: "grandfatherName", value: owner["name4"], genericFieldDataType: "STRING"})
    return {"genericFieldDTOList": genericFields}
}

function extractOwnerToOwnershipData(owner){
    return {
        ownerRole: owner[formFieldNames.OWNER_ROLE],
        percent: owner[formFieldNames.OWNERSHIP_PERCENTAGE],
        sharedOwnership: owner[formFieldNames.SHARED_OWNERSHIP],
        lead: owner[formFieldNames.OWNERSHIP_LEAD]
    }
}

export function mapApplicationStatusForTable(state){
    switch(state){
        case "CREATED":
        case "READY_FOR_DISPATCHING":
            return "CREATED"
        case "CREATED_INCOMPLETE":
            return "CREATED_INCOMPLETE"
        case "PREPARE_FOR_ISSUANCE":
        case "READY_FOR_ISSUANCE":
        case "READY_FOR_PERSONALIZATION":
        case "IN_PERSONALIZATION":
        case "IN_BIOMETRIC_PREPARATION":
        case "WAITING_FOR_REWORK_APPROVAL":
        case "ISSUANCE_OF_DOCUMENTS":
        case "INVALIDATION_OF_DOCUMENTS":
        case "PROCESSING_ERROR":
        case "DISPATCHED":
        case "WAITING_FOR_PAYMENT":
        case "PAID":
        case "PROCESSING":
        case "NOT_PAID":
        case "BAD_DATA":
            return "PROCESSING"
        case "FINISHED":
            return "FINISHED"
        case "ISSUED":
            return "ISSUED"
        case "APPROVED":
            return "APPROVED"
        case "DISAPPROVED":
            return "DISAPPROVED"
    }
}