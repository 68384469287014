import {
    getFormValues, getFromAppStore
} from "@mb-react/mb-react-core";
import { formFieldNames } from "@mb-react/svarog-ui";
import { useMemo } from "react";
import { useForm } from 'react-final-form';
import { useSelector } from "react-redux";
import { formFieldsMeta, forms } from "../../../../../../constants/form";
import { projectDictionaries } from "../../../../../../constants/general";
import { isMandatory, isReadOnly } from "../../../../../../util/businesslogicUtil";
import {ACCOUNT_IDENTITY} from "../../../../../../constants/application";

export const useOtherPersonalInformationDataDataSettings = () => {
    const form = useForm();
    const useCase = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.USE_CASE])
    const documentType = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.DOCUMENT_TYPE])
    const workflow = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldsMeta.WORKFLOW])
    const accountIdentity = useSelector(state => getFromAppStore(state, ACCOUNT_IDENTITY));

    let formValues = form?.getState()?.values ?? {}

    let years16Before = new Date();
    years16Before.setFullYear(years16Before.getFullYear() - 16);
    years16Before.setDate(years16Before.getDate() + 2)
    years16Before.setMonth(years16Before.getMonth() + 2)

    let years125Before = new Date();
    years125Before.setFullYear(years125Before.getFullYear() - 125);

    const handleGenderSettings = useMemo(() => {
        let otherPersonalInformationDataProps = {
            [formFieldNames.GENDER]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(formValues, formFieldNames.GENDER, useCase, documentType, workflow),
                    reference: projectDictionaries.GENDER_CODES,
                },
                required: isMandatory(formValues, formFieldNames.GENDER, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return otherPersonalInformationDataProps
    }, [formValues[formFieldNames.TPID]])

    const handleNationalitySettings = useMemo(() => {
        let otherPersonalInformationDataProps = {
            [formFieldNames.NATIONALITY]: {
                [formFieldNames.FIELD_PROPS]: {
                    filter: true,
                    showClear: true,
                    filterBy: "label",
                    readOnly: isReadOnly(formValues, formFieldNames.NATIONALITY, useCase, documentType, workflow),
                    reference: projectDictionaries.NATIONALITIES,
                },
                required: isMandatory(formValues, formFieldNames.NATIONALITY, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return otherPersonalInformationDataProps
    }, [formValues[formFieldNames.TPID]])

    const handleBloodGroupSettings = useMemo(() => {
        let otherPersonalInformationDataProps = {
            [formFieldNames.BLOOD_GROUP]: {
                [formFieldNames.FIELD_PROPS]: {
                    showClear: true,
                    readOnly: isReadOnly(formValues, formFieldNames.BLOOD_GROUP, useCase, documentType, workflow),
                    reference: projectDictionaries.BLOOD_GROUP,
                },
                required: isMandatory(formValues, formFieldNames.BLOOD_GROUP, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return otherPersonalInformationDataProps
    }, [formValues[formFieldNames.TPID]])

    const handleTpidSettings = useMemo(() => {
        let otherPersonalInformationDataProps = {
            [formFieldNames.TPID]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(formValues, formFieldNames.TPID, useCase, documentType, workflow),
                },
                exclude: true,
                required: isMandatory(formValues, formFieldNames.TPID, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
                }
        }

        return otherPersonalInformationDataProps
    }, [formValues[formFieldNames.TPID]])

    const handleTaxNumberSettings = useMemo(() => {
        let otherPersonalInformationDataProps = {
            [formFieldNames.TAX_NUMBER]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(formValues, formFieldNames.TAX_NUMBER, useCase, documentType, workflow),
                },
                required: isMandatory(formValues, formFieldNames.TAX_NUMBER, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return otherPersonalInformationDataProps
    }, [formValues[formFieldNames.TPID]])

    const handlePersonalNumberSettings = useMemo(() => {
        let otherPersonalInformationDataProps = {
            [formFieldNames.PERSONAL_NUMBER]: {
                [formFieldNames.FIELD_PROPS]: {
                    keyfilter: "alphanum",
                    maxLength: 100,
                    readOnly: isReadOnly(formValues, formFieldNames.PERSONAL_NUMBER, useCase, documentType, workflow),
                },
                required: isMandatory(formValues, formFieldNames.PERSONAL_NUMBER, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return otherPersonalInformationDataProps
    }, [formValues[formFieldNames.TPID]])

    return { ...handleGenderSettings, ...handleNationalitySettings, ...handleBloodGroupSettings, ...handleTpidSettings, ...handleTaxNumberSettings, ...handlePersonalNumberSettings }
};