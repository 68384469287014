/**
 * @author jovanovicp
 * @contributor Mina Stankovic 04/04/2019
 */
import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

export const DEFAULT_COUNTRY = 'DEU';
export const YES = "YES";
export const NO = "NO";

export const monthSelect = [
  { label: '01', value: '1' },
  { label: '02', value: '2' },
  { label: '03', value: '3' },
  { label: '04', value: '4' },
  { label: '05', value: '5' },
  { label: '06', value: '6' },
  { label: '07', value: '7' },
  { label: '08', value: '8' },
  { label: '09', value: '9' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' }
];

export const yearSelect = [
  { label: '2019', value: '2019' },
  { label: '2020', value: '2020' },
  { label: '2021', value: '2021' },
  { label: '2022', value: '2022' },
  { label: '2023', value: '2023' },
  { label: '2024', value: '2024' }
];

export const enumKeys = Object.freeze({
  COUNTRIES: 'enum',
  GENDER: 'GENDER_',
  OPTION: 'OPTION_',
  VISIT_PURPOSE: 'VISIT_PURPOSE_',
  PASSPORT_TYPE: 'PASSPORT_TYPE_',
  MARITAL_STATUS: 'MARITAL_STATUS_',
  IMMIGRATION_STATUS: 'IMMIGRATION_STATUS_'
});


export const genderSelect = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' }
];


export const countrySelect = [
  { label: 'Serbia', value: 'SRB' },
  { label: 'Germany', value: 'DEU' },
  { label: 'USA', value: 'USA' }
];

export const citySelect = [
  { label: 'Belgrade', value: 'Belgrade' },
  { label: 'Roding', value: 'Roding' },
  { label: 'Washington', value: 'Washington' }
]

export const yesNoSelect = [
  { name: <Translate value="action.yes" />, value: YES },
  { name: <Translate value="action.no" />, value: NO },
]
export const yesSelect = [
  { name: <Translate value="action.yes" />, value: YES },
]

export const EMaritalStatus = Object.freeze({
  SINGLE: 'Single',
  MARRIED: 'Married',
  DIVORCED: 'Divorced',
  WIDOWED: 'Widowed'
});

export const plateTypesSelect = [
  { name: I18n.t('label.standard'), value: "Standard" },
  //TO BE SET ONLY FOR LEGAL ENTITY
  { name: I18n.t('label.special'), value: "SPECIAL" },
  //MotorCycle removed based on information from Wady Al Ain MotorCycle added as usage for standard
  { name: I18n.t('label.motorcycle'), value: "MotorCycle" },
  //TO BE SET ONLY FOR LEGAL ENTITY
  { name: I18n.t('label.other'), value: "Other" },
]

export function sourcePlateSelect() {
  return [
    { name: I18n.t('label.furtherUse'), value: "FURTHER_USE" },
    { name: I18n.t('label.reserveForSeller'), value: "FOR_SELLER" },
    { name: I18n.t('label.reserveForBuyer'), value: "FOR_BUYER" },
    { name: I18n.t('label.freePlate'), value: "FREE_PLATE" }
  ]
}

export function filteredSourcePlateSelect() {
  return [
    { name: I18n.t('label.furtherUse'), value: "FURTHER_USE" }
  ]
}

export const dictionaryNames = {
  VISA_TYPE_CODES: 'visa-type-codes',
  COUNTRY_CODES: 'country-codes',
  PROVINCES: 'provinces',
  ADDRESS_DISTRICT: 'address_district',
  PLACE_OF_BIRTH_DISTRICT: 'place_of_birth_district',
  CERTAINTY_CODES: 'certainty-codes',
  GENDER_CODES: 'gender-codes',
  MARITAL_CODES: 'marital-codes',
  PASSPORT_TYPES: 'passport-type-codes',
  PASSPORT_DOCUMENTSUBTYPE_CODES: 'passport-documentsubtype-codes',
  PRIORITY_CODES: 'priority-codes'
};
export const projectDictionaries = {
  PROFESSION: 'professions',
  INFO: 'info',
  GOVERNORATE: 'governorate',
  VILLAGES: 'villages',
  COUNTRY_CODES: 'icao_countries',
  NATIONALITIES: 'icao_nationalities',
  BLOOD_GROUP: 'blood_group',
  PROVINCES: 'provinces',
  CITIES: 'cities',
  FOOD: 'food',
  GENDER_CODES: 'gender',
  MARITAL_CODES: 'marital_status',
  BUILDING_CLASS: 'building_class',
  CARD_RESTRICTIONS: 'card_restrictions',
  HOLDER_RESTRICTIONS: 'holder_restrictions',
  LEGAL_ENTITY_TYPE: 'legal_entity_type',
  LEGAL_ENTITY_SUBTYPE: 'legal_entity_subtype',

  VEHICLE_BRANDS: 'vehicle_brands',
  TRAILER_BRANDS: 'trailer_brands',
  VEHICLE_MODELS: 'vehicle_models',
  TRAILER_MODELS: 'trailer_models',
  VEHICLE_CATEGORIES: 'vehicle_categories',
  TRAILER_CATEGORIES: 'trailer_categories',
  VEHICLE_SUBCATEGORIES: 'vehicle_subcategories',
  TRAILER_SUBCATEGORIES: 'trailer_subcategories',
  VEHICLE_USAGE_TYPES: 'vehicle_usage_types',
  VEHICLE_COLORS: 'vehicle_colors',
  VEHICLE_WHEEL_TYPES: 'vehicle_wheel_types',
  VEHICLE_SUB_CATEGORY: 'vehicle_subcategory',
  VEHICLE_FUEL_TYPES: 'vehicle_fuel_types',
  VEHICLE_CABIN_TYPES: "vehicle_cabin_types",
  VEHICLE_CABIN_SIZES: "vehicle_cabin_sizes",

  PLATE_TYPES: "plate_types",
  PLATE_CATEGORIES: "plate_categories",
  PLATE_USAGES: "plate_usages",
  STATE_HANDLING_REASONS: "state_handling_reasons",
  PLATE_CATEGORY: "plate_category"
};
export const dictionaryReference = {
  VILLAGES_PLACE_OF_BIRTH: 'villages_of_birth',
  CITIES_PLACE_OF_BIRTH: 'cities_of_birth',
  VILLAGES_ADDRESS: 'villages_address',
  CITIES_ADDRESS: 'cities_address',
  CATERING_CENTER_NUMBER: 'catering_center_number',
  VILLAGES_OF_REGISTRATION: 'villages_of_registration',
  CITIES_OF_REGISTRATION: 'cities_of_registration',
  VEHICLE_CATEGORIES: 'vehicle_categories',
  VEHICLE_CABIN_TYPES: 'vehicle_cabin_types',
  VEHICLE_BRANDS: 'vehicle_brands',
  VEHICLE_MODELS: 'vehicle_models',
  VEHICLE_USAGE_TYPES: 'vehicle_usage_types',
  VEHICLE_COLORS: 'vehicle_colors',
  VEHICLE_FUEL_TYPES: 'vehicle_fuel_types',
  VEHICLE_WHEEL_TYPES: 'vehicle_wheel_types',
  PLATE_PROVINCE: 'governorate'
};
export const EPassportTypeName = Object.freeze({
  SPECIAL: 'SPECIAL',
  DIPLOMATIC: 'DIPLOMATIC',
  SERVICE: 'SERVICE',
  NORMAL: 'NORMAL'
});

export const restErrors = Object.freeze({
  ERROR_WRONG_CAPTCHA: "ERROR_WRONG_CAPTCHA",
  ERROR_BAD_USERNAME_OR_PASSWORD: "ERROR_BAD_USERNAME_OR_PASSWORD",
  ERROR_BAD_USERNAME: "ERROR_BAD_USERNAME",
  GENERAL_SERVER_ERROR: "GENERAL_SERVER_ERROR",
  ERROR_CODE_GENERIC: "ERROR_CODE_GENERIC",
  ERROR_ACCOUNT_LOCKED: "ERROR_ACCOUNT_LOCKED",
  ERROR_ACCOUNT_NOT_CONFIRMED: "ERROR_ACCOUNT_NOT_CONFIRMED",
  ERROR_ACCOUNT_INACTIVE: "ERROR_ACCOUNT_INACTIVE",
  ERROR_ACCOUNT_EXPIRED: "ERROR_ACCOUNT_EXPIRED",
  ERROR_ACCOUNT_NOT_APPROVED: "ERROR_ACCOUNT_NOT_APPROVED",
  ERROR_ACCOUNT_DELETED: "ERROR_ACCOUNT_DELETED",
  ERROR_USERNAME_NOT_UNIQUE: "ERROR_USERNAME_NOT_UNIQUE",
  ERROR_EMAIL_NOT_UNIQUE: "ERROR_EMAIL_NOT_UNIQUE",
  ERROR_BAD_PASSWORD: "ERROR_BAD_PASSWORD",
  ERROR_REPEAT_PASS_NOT_SAME: "ERROR_REPEAT_PASS_NOT_SAME",
  ERROR_NO_ACCOUNT_WITH_GIVEN_USERNAME: "ERROR_NO_ACCOUNT_WITH_GIVEN_USERNAME",
  ERROR_ACCOUNT_NOT_LOCKED: "ERROR_ACCOUNT_NOT_LOCKED",
  ERROR_MAX_NUMBER_OF_APPOINTMENTS_REACHED: "MAX_NUMBER_OF_APPOINTMENTS_REACHED",
  ERROR_ACCOUNT_NOT_ACTIVE: "ERROR_ACCOUNT_NOT_ACTIVE",
  ERROR_BAD_OLD_PASSWORD: "ERROR_BAD_OLD_PASSWORD",
  ERROR_BAD_NEW_PASSWORD: "ERROR_BAD_NEW_PASSWORD"
});

export const restResponseStatusValues = Object.freeze({
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  UNAUTHORIZED: "Unauthorized"
});

export const recoveryReason = Object.freeze({
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  ACCOUNT_BLOCKED: "ACCOUNT_BLOCKED"
});
export const ACCOUNT_ID = 'accountId'
export const ENROLLMENT = 'Enrollment'
export const extendDLCategories = [
  "BE", "C", "CE", "C1", "C1E", "D", "D1", "D1E", "DE"
]

export const foreignerSelectedDLCategories = [
  "B", "BE"]

export const accountMode = Object.freeze({
  PUBLIC: "PUBLIC",
  INTERNAL: "INTERNAL"
})

export const ReferenceDocumentTypes = Object.freeze({
  VEHICLE_REGISTRATION_CARD: 'VehicleRegistrationCard',
  WIND_SHIELD_STICKER: 'WindshieldSticker',
  LICENSE_PLATE: 'LicensePlate'
})

export const leStateOptions = [
  { label: I18n.t('label.active'), value: 'active' },
  { label: I18n.t('label.inactive'), value: 'inactive' },
  { label: I18n.t('label.blacklisted'), value: 'blacklisted' }
]

export const enrollmentStatus = Object.freeze({
  COMPLETE: "COMPLETE",
  INCOMPLETE: "INCOMPLETE"
})

export const ACCOUNT_TYPE = "ACCOUNT_TYPE";
export const PROTECTED = "PROTECTED";
export const SELECTED_ACCOUNT_ID = "selectedAccountId";
export const SELECTED_ACCOUNT_EMAIL = "selectedAccountEmail";
export const SELECTED_EXISTING_ACCOUNT = "selectedExistingAccount";
export const BUYER = "BUYER";
export const SELLER = "SELLER";

export const THEME = "theme";
export const DARK_THEME = "dark";
export const WHITE_THEME = "white";
export const THEMES = [
  DARK_THEME,
  WHITE_THEME
]