import {
    addToAppStore,
    getFromAppStore, getFormValues, DataTable, Column,
} from '@mb-react/mb-react-core';
import {
    ProcessStateController,
    PagedRequest,
    ServiceRequestData,
    useCreateServiceWrapper
} from '@mb-react/svarog-ui';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {applicationStore} from '../../../../constants/store';
import ApplicationStatusSearchForm from "./ApplicationStatusSearchForm";
import {forms} from "../../../../constants/form";
import {mapApplicationStatusForTable} from "../../../../util/dataMapperUtil";

const ApplicationsStatusTable = () => {
    const applicationsFromStore = useSelector(state => getFromAppStore(state, applicationStore.APPLICATIONS_DATA))
    const searchForm = useSelector(state => getFormValues(state, forms.ADDRESS_DATA))
    const serviceWrapper = useCreateServiceWrapper();
    const [count, setCount] = useState(0);
    let dispatch = useDispatch();

    const fetchApplication = (pageNumber, pageSize, params) => {
        let pagedRequest = new PagedRequest();
        if(params){
            pagedRequest = {...params}
        }
        pagedRequest.pageNumber = pageNumber
        pagedRequest.pageSize = pageSize

        let request = new ServiceRequestData();
        request.service = ProcessStateController.searchApplications
        request.requestData = pagedRequest
        request.onSuccessLocal = (result) => {
            let applications = []
            if (result) {
                applications = result.pagedResults;
                if (JSON.stringify(applications)) {
                    const tableData = [];
                    applications.map(app => {
                        let appObject = {
                            applicationID: app.applicationMetaDataDTO.applicationID,
                            applicationType: app.applicationMetaDataDTO.applicationType,
                            useCase: app.applicationMetaDataDTO.useCase,
                            email: searchForm?.email ? searchForm.email : app?.applicantsDTO?.applicantDTOList[0]?.demographicDataDTO?.personDataDTO?.contactAddress?.email,
                            applicationStatus: mapApplicationStatusForTable(app.applicationMetaDataDTO.applicationState),
                        }
                        tableData.push(appObject)
                    })

                    let filteredData = filterApplicationData(tableData)

                    dispatch(addToAppStore(applicationStore.APPLICATIONS_DATA, filteredData))
                    setCount(result.count);
                }
            }
        }
        serviceWrapper(request)
    }

    function filterApplicationData(tableData) {
        let filteredData = [];
        if (tableData) {
            tableData.map(data => {
                let listOfAppsWithId = listOfAppsWithSameId(tableData, data.applicationID)

                if (listOfAppsWithId && listOfAppsWithId.length > 1) {
                    if (!checkIfApplicationIsAlreadyAdded(filteredData, data.applicationID)) {
                        filteredData.push(data)
                    }
                } else {
                    filteredData.push(data)
                }
            })
        }

        return filteredData;
    }

    const listOfAppsWithSameId = (listOfApps, appId) => {
        return listOfApps.filter(x => x.applicationID === appId);
    }
    const checkIfApplicationIsAlreadyAdded =(listOfApps, appId) => {
        return listOfApps.some(x => x.applicationID === appId);
    }

    const columns = [
        {
            field: 'applicationID',
            translate: false
        },
        {
            field: 'applicationType',
            translate: true
        },
        {
            field: 'useCase',
            translate: true
        },
        {
            field: 'email',
            translate: false
        },
        {
            field: 'applicationStatus',
            translate: true
        }
    ];

    const paginationFunc = (pageNumber, pageSize) => {
        fetchApplication(pageNumber + 1, pageSize, searchForm)
    }

    const fieldTranslator = (rowData, col) => {
        if(col?.column?.props?.translate){
            return <Translate value={"label.table." + col.field + "."+ rowData[col.field]}/>
        }
        return rowData[col.field]
    }

    let dynamicColumns = [];

    if (columns.length > 0) {
        dynamicColumns = columns.map((col) => {
                return <Column {...col} key={col.field} field={col.field}
                               body={fieldTranslator}
                               header={<Translate value={"label." + col.field}/>}
                               style={{width: col.width ? col.width : "auto"}}/>;
        });
    }

    return (
        <div>
            <ApplicationStatusSearchForm searchApplicationFunc={fetchApplication}/>
            <DataTable value={applicationsFromStore}
                       actionTitle={I18n.t('label.viewApplications')}
                         count={applicationsFromStore != null ? applicationsFromStore.length : 0}
                         onViewIcon="pi pi-calendar-plus"
                         paginator={true}
                       emptyMessage={I18n.t('message.noResultsFound')}
                       rows={5}
                       rowsPerPageOptions={[5, 10, 25, 50]}
                         paginationFunc={paginationFunc}
            >
                {dynamicColumns}
            </DataTable>
        </div>
    )
}
export default ApplicationsStatusTable