import {dLCategories, genericFormFieldNames} from "@mb-react/krg-getid-form-data-common";
import {formFieldNames} from "@mb-react/svarog-ui";
import {useSelector} from "react-redux";
import {getFormValues} from "@mb-react/mb-react-core";
import {projectDictionaries} from "../../../../../../constants/general";
import {formFieldsMeta, forms} from "../../../../../../constants/form";
import {isMandatory, isReadOnly, isUseCaseReRegister, USE_CASE_FOREIGN} from "../../../../../../util/businesslogicUtil";
import {formatDate} from "../../../../../../util/dateUtil";
import {useMemo} from "react";
import {useForm} from "react-final-form";

export const useGetDriverLicenseDetailsFieldsSettings = () => {
    const formValues = useSelector(state => getFormValues(state, forms.APPLICATION));
    let dlFormValues = useSelector(state => getFormValues(state, forms.DRIVER_LICENSE));
    const questionnaireValues = useSelector(state => getFormValues(state, forms.QUESTIONNAIRES));

    let form = useForm();

    dlFormValues = {...formValues, ...dlFormValues, ...questionnaireValues}

    let dateDL = dlFormValues && dlFormValues[genericFormFieldNames.ISSUE_DATE_DL]

    let today = new Date();

    let years125Before = new Date();
    years125Before.setFullYear(years125Before.getFullYear() - 125);

    let maxDateNew = new Date(dateDL);
    maxDateNew.setFullYear(maxDateNew.getFullYear() + 1);

    const onPreviousDLChange = () => {
        form.change(genericFormFieldNames.NUMBER_DL, "");
        form.change(genericFormFieldNames.VALID_DATE_DL, "");
        form.change(genericFormFieldNames.ISSUE_COUNTRY_DL, "");
        form.change(genericFormFieldNames.ISSUE_DATE_DL, "");
        form.change(genericFormFieldNames.CLASSES_DL, "");
    };

    const handleOwnsPreviousDlSettings = useMemo(() => {
        let previousDriverLicenseDataProps = {
            [genericFormFieldNames.OWNS_PREVIOUS_DL]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(dlFormValues, genericFormFieldNames.OWNS_PREVIOUS_DL, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                    [formFieldNames.ON_CHANGE]: onPreviousDLChange
                },
                required: isMandatory(dlFormValues, genericFormFieldNames.OWNS_PREVIOUS_DL, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
            }
        }

        return previousDriverLicenseDataProps
    }, [])

    const handleNumberDlSettings = useMemo(() => {
        let previousDriverLicenseDataProps = {
            [genericFormFieldNames.NUMBER_DL]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(dlFormValues, genericFormFieldNames.NUMBER_DL, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                required: isMandatory(dlFormValues, genericFormFieldNames.NUMBER_DL, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
            }
        }

        return previousDriverLicenseDataProps
    }, [])

    const handleIssueDateDlSettings = useMemo(() => {
        let previousDriverLicenseDataProps = {
            [genericFormFieldNames.ISSUE_DATE_DL]: {
                [formFieldNames.FIELD_PROPS]: {
                    minDate: years125Before, maxDate: today,
                    dateFormat: formatDate(dlFormValues[genericFormFieldNames.ISSUE_DATE_DL]),
                    readOnly: isReadOnly(dlFormValues, genericFormFieldNames.ISSUE_DATE_DL, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                required: isMandatory(dlFormValues, genericFormFieldNames.ISSUE_DATE_DL, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
            }
        }

        return previousDriverLicenseDataProps
    }, [])

    const handleIssueCountryDlSettings = useMemo(() => {
        let previousDriverLicenseDataProps = {
            [genericFormFieldNames.ISSUE_COUNTRY_DL]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(dlFormValues, genericFormFieldNames.ISSUE_COUNTRY_DL, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                    reference: projectDictionaries.COUNTRY_CODES,
                },
                required: isMandatory(dlFormValues, genericFormFieldNames.ISSUE_COUNTRY_DL, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
            }
        }

        return previousDriverLicenseDataProps
    }, [])

    const handleValidDateDlSettings = useMemo(() => {
        let previousDriverLicenseDataProps = {
            [genericFormFieldNames.VALID_DATE_DL]: {
                [formFieldNames.FIELD_PROPS]: {
                    dateFormat: formatDate(dlFormValues[genericFormFieldNames.VALID_DATE_DL]),
                    maxDate: formValues[formFieldNames.USE_CASE] == USE_CASE_FOREIGN ? maxDateNew : null,
                    readOnly: isReadOnly(dlFormValues, genericFormFieldNames.VALID_DATE_DL, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                required: isMandatory(dlFormValues, genericFormFieldNames.VALID_DATE_DL, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
            }
        }

        return previousDriverLicenseDataProps
    }, [])

    const handleClassesDlSettings = useMemo(() => {
        let previousDriverLicenseDataProps = {
            [genericFormFieldNames.CLASSES_DL]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(dlFormValues, genericFormFieldNames.CLASSES_DL, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                    [formFieldNames.SELECT_OPTIONS]: dLCategories,
                    multiselect: isUseCaseReRegister(formValues)
                },
                required: isMandatory(dlFormValues, genericFormFieldNames.CLASSES_DL, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
            }
        }

        return previousDriverLicenseDataProps
    }, [])

    return {
        ...handleOwnsPreviousDlSettings, ...handleNumberDlSettings, ...handleIssueDateDlSettings, ...handleIssueCountryDlSettings,
        ...handleValidDateDlSettings, ...handleClassesDlSettings
    }
};