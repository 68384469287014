import React from 'react';
import { Translate } from 'react-redux-i18n';
import { TRG, getPath, i18nl } from '../../../../constants/i18n';
import ApplicationsReportTable from "./ApplicationReportsTable";

const ApplicationReport = () => {
    return (
        <div className='card navigation'>
                <div className="card" style={{ padding: '1rem' }}>
                    <h5><Translate value={getPath(i18nl.APPLICATION_REPORT, TRG.LABEL)} /></h5>
                    <ApplicationsReportTable />
                </div>
        </div>
    )
}
export default ApplicationReport