import {genericFormFieldNames} from "@mb-react/krg-getid-form-data-common";
import {
    addToAppStore,
    getFormValues,
    getDictionaryByKey,
    hasValidationErrors,
    previousStep, hideSpinner, showToast, toastIDs, getFromAppStore
} from "@mb-react/mb-react-core";
import {Button, Dialog, Message, NavButtons} from "@mb-react/mb-react-core";
import {
    extractFormValuesToSingleVehicleData,
    formFieldNames,
} from "@mb-react/svarog-ui";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {I18n, Translate} from "react-redux-i18n";
import {REDIRECT_TO_APPOINTMENT, ROOT} from "../../../../../constants/environment";
import {getPath, i18nl, TRG} from "../../../../../constants/i18n";
import {prepareServerStringDate} from "../../../../../sagas/generators/sagaUtil";
import {useHandleCommonServices} from "../../../../../services/commonServiceHandler";
import {dictionaryReference, projectDictionaries} from "../../../../../constants/general";
import {getLocalSelectOptionTranslation} from "../../../../componentUtil";
import {forms} from "../../../../../constants/form";
import {prepareVehiclesForTable} from "../../../../../util/environment";
import {applicationStore} from "../../../../../constants/store";
import {addAdditionalVehicleToAppStore, addVehicleToAppStore, reverseString} from "../../../../../util/util";
import {onCancel} from "../../../../../util/componentUtil";
import {
    checkIfVehicleExistsInVehicleService,
    isTrailerType,
    saveVehicleInVehicleService, updateVehicleByTxId
} from "../../../../../util/businesslogicUtil";
import VehicleService from "../../../../../services/vehicle/VehicleService";
import {b64ToStringEncoder} from "../../../../../util/dataPreparationUtil";
import NavButtonsLocal from "../../../../reactCoreComponents/NavButtonsLocal";

const VehicleSummaryTaskWrapper = (props) => {
    let formValuesApplication = useSelector((state) => getFormValues(state, forms.APPLICATION))
    let formValuesDL = useSelector((state) => getFormValues(state, forms.VEHICLE_DATA))
    let formValuesVehicleIdentification = useSelector((state) => getFormValues(state, forms.VEHICLE_IDENTIFICATION))
    let formAdditionalVehicleValues = useSelector((state) => getFormValues(state, forms.VEHICLE_DATA))
    let formAdditionalValuesVehicleIdentification = useSelector((state) => getFormValues(state, forms.ADDITIONAL_VEHICLE_IDENTIFICATION))
    const isVehicleFormValid = useSelector((state) => hasValidationErrors(state, forms.ADDITIONAL_VEHICLE_DATA));
    const isVehicleIdentificationFormValid = useSelector((state) => hasValidationErrors(state, forms.VEHICLE_IDENTIFICATION));
    const isAdditionalVehicleFormValid = useSelector((state) => hasValidationErrors(state, forms.ADDITIONAL_VEHICLE_DATA));
    const isAdditionalVehicleIdentificationFormValid = useSelector((state) => hasValidationErrors(state, forms.ADDITIONAL_VEHICLE_IDENTIFICATION));
    let formValues = {...formValuesDL, ...formValuesVehicleIdentification, ...formValuesApplication}
    let additionalFormValues = {...formAdditionalVehicleValues, ...formAdditionalValuesVehicleIdentification, ...formValuesApplication}
    const [showDraftDialog, setShowDraftDialog] = useState(false);
    const [showApplicationSubmittedModal, setShowApplicationSubmittedModal] = useState(false);

    const provinceOfRegistrationOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROVINCES))
    const cityOfRegistrationOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.CITIES_OF_REGISTRATION))
    const villageOfRegistrationOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VILLAGES_OF_REGISTRATION))
    const countryOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.COUNTRY_CODES))
    const provincesOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROVINCES))
    const cityOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.CITIES_PLACE_OF_BIRTH))
    const villageOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VILLAGES_PLACE_OF_BIRTH))
    const provincesAddressOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROVINCES))
    const cityAddressOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.CITIES_ADDRESS))
    const infoCenterAddressOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.INFO))
    const villageAddressOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VILLAGES_ADDRESS))
    const buildingClassAddressOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.BUILDING_CLASS))
    const genderOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.GENDER_CODES))
    const maritalOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.MARITAL_CODES))
    const foodCenterOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.FOOD_CENTER_INDICATOR))
    const cateringCenterProvinceOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROVINCES))
    const cateringCenterNumberOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.FOOD))
    const professionOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROFESSION))
    const nationalityOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.NATIONALITIES))
    const bloodGroupOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.BLOOD_GROUP))
    const categoryOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VEHICLE_CATEGORIES))
    const brandOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VEHICLE_BRANDS))
    const cabinTypeOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VEHICLE_CABIN_TYPES))
    const vehicleColorOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VEHICLE_COLORS))
    const vehicleModelOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VEHICLE_MODELS))
    const usageTypeOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VEHICLE_USAGE_TYPES))
    const fuelOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VEHICLE_FUEL_TYPES))
    const wheelSizeOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VEHICLE_WHEEL_TYPES))
    const trailerCategoryOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.TRAILER_CATEGORIES))
    const trailerSubCategoryOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.TRAILER_SUBCATEGORIES))
    const vehicleSubCategoryOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.VEHICLE_SUBCATEGORIES))
    const trailerBrandOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.TRAILER_BRANDS))
    const trailerVehicleModelOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.TRAILER_MODELS))
    const updateAdditionalVehicle = useSelector((state) =>
        getFromAppStore(state, applicationStore.UPDATE_ADDITIONAL_VEHICLE)
    );

    const dispatch = useDispatch();

    const dynamicColumns = Object.keys(formValues).map((x, i) => {

        if (["applicationType", "workflow", "ownerRole", "enrollmentStatus", "undefined"].includes(x)) {
            return;
        }

        return formValues[x] && <div className="grid IDsummary" key={i}>
            <div className="col-12 xl:col-6">
                {labelValue(x)}
            </div>
            <div className="col-12 xl:col-6 summaryValue">
                {decorateField(x, formValues[x])}
            </div>
        </div>
    });

    function labelValue(formLabel) {
        if(isTrailerType(formValues)){
            switch (formLabel) {
                case formFieldNames.VEHICLE_CATEGORY:
                    return I18n.t("label.trailerCategory")
                case formFieldNames.VEHICLE_SUBCATEGORY:
                    return I18n.t("label.trailerSubcategory")
                case formFieldNames.VEHICLE_BRAND:
                    return I18n.t("label.trailerBrand")
                case formFieldNames.VEHICLE_MODEL:
                    return I18n.t("label.trailerModel")
                case formFieldNames.VEHICLE_LENGTH:
                    return I18n.t("label.trailerLength")
                case formFieldNames.VEHICLE_HEIGHT:
                    return I18n.t("label.trailerHeight")
                case formFieldNames.VEHICLE_WIDTH:
                    return I18n.t("label.trailerWidth")
                default:
                    return I18n.t("label." + formLabel)
            }
        }
        return I18n.t("label." + formLabel)
    }

    function decorateField(formLabel, formValue) {
        switch (formLabel) {
            case genericFormFieldNames.PROVINCE_OF_REGISTRATION:
                return getLocalSelectOptionTranslation(provinceOfRegistrationOptions, formValue)
            case genericFormFieldNames.CITY_OF_REGISTRATION:
                return getLocalSelectOptionTranslation(cityOfRegistrationOptions, formValue)
            case genericFormFieldNames.VILLAGE_OF_REGISTRATION:
                return getLocalSelectOptionTranslation(villageOfRegistrationOptions, formValue)
            case formFieldNames.PLACE_OF_BIRTH_COUNTRY:
                return getLocalSelectOptionTranslation(countryOptions, formValue)
            case formFieldNames.PLACE_OF_BIRTH_PROVINCE:
                return getLocalSelectOptionTranslation(provincesOptions, formValue)
            case formFieldNames.PLACE_OF_BIRTH_CITY:
                return getLocalSelectOptionTranslation(cityOptions, formValue)
            case genericFormFieldNames.PLACE_OF_BIRTH_VILLAGE:
                return getLocalSelectOptionTranslation(villageOptions, formValue)
            case formFieldNames.ADDRESS_PROVINCE:
                return getLocalSelectOptionTranslation(provincesAddressOptions, formValue)
            case formFieldNames.ADDRESS_CITY:
                return getLocalSelectOptionTranslation(cityAddressOptions, formValue)
            case genericFormFieldNames.ADDRESS_VILLAGE:
                return getLocalSelectOptionTranslation(villageAddressOptions, formValue)
            case genericFormFieldNames.ADDRESS_INFORMATION_CENTER:
                return getLocalSelectOptionTranslation(infoCenterAddressOptions, formValue)
            case genericFormFieldNames.ADDRESS_BUILDING_CLASS:
                return getLocalSelectOptionTranslation(buildingClassAddressOptions, formValue)
            case formFieldNames.GENDER:
                return getLocalSelectOptionTranslation(genderOptions, formValue)
            case formFieldNames.MARITAL_STATUS:
                return getLocalSelectOptionTranslation(maritalOptions, formValue)
            case genericFormFieldNames.FOOD_CENTER_INDICATOR:
                const selectedOption = getLocalSelectOptionTranslation(foodCenterOptions, formValue);
                return selectedOption === "NO" ? "NO" : "YES";
            case genericFormFieldNames.CATERING_CENTER_PROVINCE:
                return getLocalSelectOptionTranslation(cateringCenterProvinceOptions, formValue)
            case genericFormFieldNames.CATERING_CENTER_NUMBER_NAME:
                return getLocalSelectOptionTranslation(cateringCenterNumberOptions, formValue)
            case formFieldNames.POSITION:
                return getLocalSelectOptionTranslation(professionOptions, formValue)
            case formFieldNames.NATIONALITY:
                return getLocalSelectOptionTranslation(nationalityOptions, formValue)
            case formFieldNames.BLOOD_GROUP:
                return getLocalSelectOptionTranslation(bloodGroupOptions, formValue)
            case formFieldNames.VEHICLE_CATEGORY:
                if(isTrailerType(formValues)){
                    return getLocalSelectOptionTranslation(trailerCategoryOptions, formValue)
                }else{
                    return getLocalSelectOptionTranslation(categoryOptions, formValue)
                }
            case formFieldNames.VEHICLE_BRAND:
                if(isTrailerType(formValues)) {
                    return getLocalSelectOptionTranslation(trailerBrandOptions, formValue)
                }else{
                    return getLocalSelectOptionTranslation(brandOptions, formValue)
                }
            case formFieldNames.VEHICLE_SUBCATEGORY:
                if(isTrailerType(formValues)) {
                    return getLocalSelectOptionTranslation(trailerSubCategoryOptions, formValue)
                }else{
                    return getLocalSelectOptionTranslation(vehicleSubCategoryOptions, formValue)
                }
            case formFieldNames.VEHICLE_COLOR:
                return getLocalSelectOptionTranslation(vehicleColorOptions, formValue)
            case formFieldNames.VEHICLE_MODEL:
                if(isTrailerType(formValues)) {
                    return getLocalSelectOptionTranslation(trailerVehicleModelOptions, formValue)
                }else{
                    return getLocalSelectOptionTranslation(vehicleModelOptions, formValue)
                }
            case formFieldNames.VEHICLE_USAGE_TYPE:
                return getLocalSelectOptionTranslation(usageTypeOptions, formValue)
            case formFieldNames.CABIN_TYPE:
                return getLocalSelectOptionTranslation(cabinTypeOptions, formValue)
            case formFieldNames.FUEL:
                return getLocalSelectOptionTranslation(fuelOptions, formValue)
            case formFieldNames.WHEEL_SIZE:
                return getLocalSelectOptionTranslation(wheelSizeOptions, formValue)
            case "enrolmentStarted":
                return prepareServerStringDate(formValue)
            case "operatorUsername":
                return b64ToStringEncoder(formValue)
            case genericFormFieldNames.TEMPORARY_PLATE_NUMBER:
                const plateValue = formValue.split(" ");
                if (plateValue.length === 2) {
                    const formattedPlateNumber = `${plateValue[0]} ${parseInt(plateValue[1], 10)}`;
                    return formattedPlateNumber;
                }
                return formValue;
            case formFieldNames.DOCUMENT_TYPE:
                return I18n.t("label." + formValue)
            default:
                return typeof formValue == "string" || typeof formValue == "number" ? formValue : prepareServerStringDate(formValue)
        }
    }


    const isFormComplete = () => {
        if (isVehicleFormValid && isVehicleIdentificationFormValid) {
            return false
        } else {
            return true
        }
    };

    const isAdditionalFormComplete = () => {
        if (isAdditionalVehicleFormValid && isAdditionalVehicleIdentificationFormValid) {
            return false
        } else {
            return true
        }
    };

    const addVehicle = (state) => {

        let requestObject = extractFormValuesToSingleVehicleData({
            ...formValues,
            [formFieldNames.APPLICATION_STATUS]: state,
        },  null, null)
        dispatch(addToAppStore(applicationStore.SELECTED_VEHICLE, requestObject))
        let a = [];
        a.push(requestObject)
        addVehicleToAppStore(prepareVehiclesForTable(a))
        setShowDraftDialog(false)
        dispatch(addToAppStore(applicationStore.SHOW_ADD_VEHICLE_FORM, false))

    };

    const addAdditionalVehicle = async (state) => {

        let requestObject = extractFormValuesToSingleVehicleData({
            ...additionalFormValues,
            [formFieldNames.APPLICATION_STATUS]: state,
        },  null, null)

        if(requestObject[formFieldNames.CHASSIS_NUMBER]){
            try {

                let status = ["REGISTERED"];

                let vehicleExistsInVehicleService = await checkIfVehicleExistsInVehicleService(dispatch, VehicleService, requestObject[formFieldNames.CHASSIS_NUMBER], status)
                if(updateAdditionalVehicle){
                    requestObject = {...requestObject, [formFieldNames.TRANSACTION_ID]: reverseString(additionalFormValues.applicationID) + 'av'}
                    await updateVehicleByTxId(dispatch, VehicleService, requestObject)
                }else if(!vehicleExistsInVehicleService) {

                    requestObject = {...requestObject, [formFieldNames.TRANSACTION_ID]: reverseString(additionalFormValues.applicationID) + 'av'}

                    await saveVehicleInVehicleService(dispatch, VehicleService, requestObject)
                    dispatch(addToAppStore(applicationStore.UPDATE_ADDITIONAL_VEHICLE, true))
                }else{
                    requestObject = {...requestObject, [formFieldNames.TRANSACTION_ID]: vehicleExistsInVehicleService}
                }

                console.log("Additional vehicle => ", requestObject)

                dispatch(addToAppStore(applicationStore.SELECTED_ADDITIONAL_VEHICLE, requestObject))
                let a = [];
                a.push(requestObject)
                addAdditionalVehicleToAppStore(prepareVehiclesForTable(a))
                setShowDraftDialog(false)
                dispatch(addToAppStore(applicationStore.SHOW_ADDITIONAL_ADD_VEHICLE_FORM, false))
            }catch(e){
                dispatch(hideSpinner())
                dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {messageId: e}))
            }
        }
    };

    function addVehicleToStore() {
        if(props.additionalVehicleForm && isAdditionalFormComplete()){
            addAdditionalVehicle("CREATED")
        }else if (isFormComplete()) {
            addVehicle("CREATED")
        } else {
            setShowDraftDialog(true)
        }
    }

    function createDraftApplication() {
        addVehicle("CREATED_INCOMPLETE")
    }

    const renderDraftFooter = () => {
        return (
            <div>
                <Button label={I18n.t("label.draft")} icon="pi pi-edit" onClick={() => createDraftApplication()}
                        className="p-button-text"/>
                <Button label={I18n.t("label.continue")} icon="pi pi-arrow" onClick={() => {
                    dispatch(addToAppStore('validationMode', "Hard"))
                    setShowDraftDialog(false)
                }} className="p-button-text"/>
            </div>
        );
    };

    const renderApplicationModalFooter = () => {
        return (
            <div>
                <Button
                    label={I18n.t("label.Home")}
                    icon="pi pi-home"
                    onClick={() => {
                        window.location = ROOT + "/"
                    }}
                    className="p-button"
                />
                <Button
                    label={I18n.t("label.bookAppointment")}
                    icon="pi pi-calendar-plus"
                    onClick={startBookApp}
                    className="p-button"
                />
            </div>
        );
    };

    const startBookApp = () => {
        sessionStorage.setItem("selsectedApplicationId", formValues[formFieldNames.APPLICATION_ID]);
        window.location = REDIRECT_TO_APPOINTMENT
    };

    const onPrevious = () => {
        dispatch(previousStep(props.navigationName))
    }

    return (
        <div className="card summary">
            <Dialog header={I18n.t(getPath(i18nl.DRAFT, TRG.MESSAGE))} visible={showApplicationSubmittedModal}
                    style={{width: '54vw'}} footer={renderApplicationModalFooter}>
                <Message severity="info" text={I18n.t("message.applicationSuccessfullySubmitted")}/>
            </Dialog>
            <div className="grid">
                {formValues && formValues.capturedFaceData &&
                <div className="col-12 md:col-3 xl:col-3">
                    <h5><Translate value="label.faceImage"/></h5>
                    <hr className="sectionSeparator"/>
                    <div className="text-center">
                        <img alt={"uploadedFace"} role="presentation"
                             src={"data:image/jpg;base64," + formValues.capturedFaceData.image} width={100}/>
                    </div>
                </div>
                }
                <div className={formValues && formValues.capturedFaceData ? "col-12 md:col-9 xl:col-9 p-2" : 'col-12'}>
                    <h5><Translate value="label.applicationData"/></h5>
                    <hr className="sectionSeparator"/>
                    {dynamicColumns}
                </div>
            </div>
            <Dialog header={I18n.t(getPath(i18nl.DRAFT, TRG.MESSAGE))} visible={showDraftDialog} style={{width: '54vw'}}
                    footer={renderDraftFooter} onHide={() => setShowDraftDialog(false)}>
                <Message severity="info" text={I18n.t(getPath(i18nl.APPLICATION_NOT_COMPLETE, TRG.MESSAGE))}/>
            </Dialog>
            <NavButtonsLocal customOnPrevious={() => onPrevious()}
                             lastStep finalSubmitFunction={() => addVehicleToStore()}
                             customOnCancel={() => onCancel(dispatch)}
                             hideDraft={true}/>
        </div>

    )
};
export default VehicleSummaryTaskWrapper