import {addToAppStore, showToast, toastIDs} from '@mb-react/mb-react-core';
import {useDispatch} from 'react-redux';
import {ROOT} from '../../constants/environment';
import {extractValueFromGenericField} from '../../util/environment';
import {restErrors, ACCOUNT_TYPE} from '../../constants/general';
import {NATURAL_PERSON, LEGAL_ENTITY, IDENTITY_STATE} from '../../constants/application';
import {getPath, i18nl, TRG} from '../../constants/i18n';
import {applicationStore} from '../../constants/store';
import {formFieldNames, setCookie} from '@mb-react/svarog-ui';
import {isOperationModeProtected} from "../../util/businesslogicUtil";
import {I18n} from "react-redux-i18n";
import {useHandleCommonServices} from "../commonServiceHandler";

export function useHandleGetUserRightsSuccessResult() {
    const dispatch = useDispatch();
    const handleResponse = (response) => {
        if (response) {
            dispatch(addToAppStore(applicationStore.USER_RIGHTS, response.userRights))
        }
    };
    return handleResponse
}

export function useHandleLoginSuccessResult() {
    const handleCommonServices = useHandleCommonServices()
    const handleResponse = (response) => {
        if(isOperationModeProtected()){
            window.location = ROOT + "/admin";
            return;
        }
        if(response && response.accountType){
            setCookie(ACCOUNT_TYPE, response.accountType)
            if(response.genericFieldsDTO){
                setCookie(formFieldNames.LE_TYPE, extractValueFromGenericField(response.genericFieldsDTO, "legalEntityType"))
                setCookie(formFieldNames.LE_SUBTYPE, extractValueFromGenericField(response.genericFieldsDTO, "leSubType"))
            }

            handleCommonServices.handleCallSyncData(onCallSyncDataSucess)

            switch(response.accountType) {
                case NATURAL_PERSON:
                    handleCommonServices.handleCheckPersonIdentityState(getIdentityStateOnSuccess)
                    break;
                case LEGAL_ENTITY:
                    handleCommonServices.handleCheckLeIdentityState(getIdentityStateOnSuccess)
                    break;
                default:
                    break;
            }
        }

    };
    return handleResponse;
}

export function useHandleLoginErrorResult() {
    const dispatch = useDispatch();
    const handleResponse = (response) => {
        // let error = {message: null};
        if(response == restErrors.ERROR_ACCOUNT_LOCKED){
            dispatch(addToAppStore(applicationStore.ERROR_MESSAGE, getPath(i18nl.ERROR_ACCOUNT_LOCKED, TRG.ERROR)));
            window.location = '/accountRecovery';
        }else{
            dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {messageId: I18n.t("err." + response)}))
        }
    };
    return handleResponse;
}

export function useHandleGetFreeAccessTokenSuccessResult(){
    const handleResponse = () => {
        window.location = isOperationModeProtected() && configurationData.contextPath ? configurationData.contextPath : '/';
    };
    return handleResponse;

}

const getIdentityStateOnSuccess = (result) => {
    setCookie(IDENTITY_STATE, result)
    if(isOperationModeProtected()){
        window.location = ROOT + "/admin";
    }else{
        window.location = ROOT + "/";
    }
}

const onCallSyncDataSucess = () => {
    console.log("SYNC COMPLETED")
}
