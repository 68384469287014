import {
    addressDataFormFieldList, legalEntityFormFieldList,
    personalInformationFormFieldList
} from "@mb-react/krg-getid-form-data-common";
import {
    Button,
    Checkbox,
    InputText,
    Messages,
    NavButtons, NavigationWizardWrapper,
    SimpleTable,
    addMultipleErrorToStep, addMultipleInitialValuesToForm,
    addToAppStore,
    completeStep, getFormValues, getFromAppStore,
    nextStep,
    previousStep, removeAllValuesFromForm
} from '@mb-react/mb-react-core';
import {
    PagedRequest,
    extractApplicantDataToFormValues,
    formFieldNames,
    getPath, getCookie
} from '@mb-react/svarog-ui';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { I18n, Translate } from 'react-redux-i18n';
import {
    changeOwnershipSubUseCases,
    LEGAL_ENTITY,
    NATURAL_PERSON
} from "../../../../../constants/application";
import { forms, navigationName } from "../../../../../constants/form";
import {SELLER, enrollmentStatus, projectDictionaries, dictionaryReference} from "../../../../../constants/general";
import { i18nl } from '../../../../../constants/i18n';
import { APPLICATION_STEPS, applicationStore } from "../../../../../constants/store";
import { useHandleCommonServices } from "../../../../../services/commonServiceHandler";
import {
    disableAddIdentitiesButtonListOfUC,
    disableDraft,
    disableLeButtonForSubUC,
    disableNextButton,
    isLoggedAccountLegalEntity,
    isNotRegisteredUserRole,
    isOperationModeNotProtected,
    isOperationModeProtected,
    isSubUseCaseDeleteOwner,
    isUseCaseChangeOwnership, isUseCaseReplaceVR,
    isUseCaseReRegister
} from "../../../../../util/businesslogicUtil";
import {isMobile, preparePersonForTable} from '../../../../../util/environment';
import AddressDataForm from "../../document/addressData/AddressDataForm";
import { useAddressEnrollmentFormDataValidation } from "../../document/addressData/AddressDataValidation";
import LegalEntityForm from "../../document/legalEntityData/LegalEntityForm";
import PersonalInformationForm from "../../document/personalData/PersonalInformationForm";
import { usePersonalInformationValidation } from "../../document/personalData/personalInformationValidation";
import PersonSummaryTaskWrapper from "../vehicleData/PersonSummaryTaskWrapper";
import SearchApplicant from "./SearchApplicant";
import { extractObjectBasedOnKeysList } from '../../../../../util/dataExtractorUtil';
import {onCancel} from "../../../../../util/componentUtil";
import NavButtonsLocal from "../../../../reactCoreComponents/NavButtonsLocal";

const SearchPersonPreview = (props) => {
    const applicationsFromStore = useSelector(state => getFromAppStore(state, 'fetchedApplication'))
    const assignedPersonTpId = useSelector(state => getFromAppStore(state, applicationStore.ASSIGNED_PERSON_TPID)) || false
    const appFormValues = useSelector((state) => getFormValues(state, forms.APPLICATION))
    const [personsTableData, setPersonsTableData] = useState([]);
    const handleCommonServices = useHandleCommonServices();
    const dispatch = useDispatch();
    const isNotProtectedMode = isOperationModeNotProtected();
    const isProtectedMode = isOperationModeProtected();
    const listOfBuyers = useSelector((state) => getFromAppStore(state, 'listOfBuyers'));
    const listOfSellers = useSelector((state) => getFromAppStore(state, 'listOfSellers'));
    const guidanceMessage = useRef(null);
    const [validationError, setValidationError] = useState();
    let ownershipPercentage = useSelector((state) => getFromAppStore(state, 'ownershipPercentage')) || {}
    let totalPercentageSellers = useSelector((state) => getFromAppStore(state, 'totalPercentageSellers'));
    const fetchedValuesTPS = useSelector((state) => getFromAppStore(state, 'fetchedValuesTPS'));
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))
    const countOfFetchedOwners = useSelector((state) => getFromAppStore(state, 'countOfFetchedOwners')) || 0;
    let isListOfSellersValid = false;
    const applyWithoutAccount = getCookie("useApplyWithoutAccount");

    const showAddPersonForm = useSelector((state) =>
        getFromAppStore(state, "showAddPersonForm")
    );

    const selectedPerson = useSelector((state) =>
        getFromAppStore(state, "selectedPerson")
    );

    if (listOfSellers && listOfSellers.length > 0) {
        isListOfSellersValid = listOfSellers.every((value) => {
            return value.enrollmentStatus === enrollmentStatus.COMPLETE
        })
    }

    const handleMyPersonsTableState = (data) => {
        setPersonsTableData(data)
    };
    const isIdentityLegalEntity = useSelector((state) =>
        getFromAppStore(state, "isIdentityLegalEntity")
    );

    const fetchMyIdentity = (data, page, sizePerPage) => {
        let pagedRequest = new PagedRequest();
        pagedRequest.requestObject = data
        pagedRequest.page = page
        pagedRequest.sizePerPage = sizePerPage

        if (isLoggedAccountLegalEntity()) {
            handleCommonServices.handleGetMyIdentityForLegalEntity(pagedRequest, getMyIdentityOnSuccess)
        } else {
            handleCommonServices.handleCallGetMyIdentity(pagedRequest, getMyIdentityOnSuccess)
        }
    }

    const getMyIdentityOnSuccess = (response) => {
        dispatch(addToAppStore("identity", response.data))
    }

    const paginationFunc = (page, sizePerPage) => {
        fetchMyIdentity({}, page + 1, sizePerPage)
    }

    useEffect(() => {

        if (!applicationsFromStore && isNotProtectedMode) {
            fetchMyIdentity({}, 1, 10)
        }

        if (!totalPercentageSellers) {
            dispatch(addToAppStore('totalPercentageSellers', []))
        }else if (totalPercentageSellers.length && totalPercentageSellers.length > 0) {
            const totalPercentage = totalPercentageSellers.reduce((sum, obj) => sum + (obj.percentage ? parseInt(obj.percentage) : 0), 0);

            if (totalPercentage === 100) {
                setValidationError(null);
            } else {
                setValidationError({"error": "Invalid percentage"});
            }
        }

        if (!listOfSellers) {
            dispatch(addToAppStore('listOfSellers', []))
        } else {
            setPersonsTableData(preparePersonForTable(listOfSellers))
        }


    }, [])

    useEffect(() => {

        if (totalPercentageSellers && totalPercentageSellers.length > 0) {
            const totalPercentage = totalPercentageSellers.reduce((sum, obj) => sum + (obj.percentage ? parseInt(obj.percentage) : 0), 0);

            if (totalPercentage === 100) {
                setValidationError(null);
            } else {
                setValidationError({"error": "Invalid percentage"});
            }
        }

        if (listOfSellers && listOfSellers.length > 0)
            setPersonsTableData(preparePersonForTable(listOfSellers))
    }, [JSON.stringify(listOfSellers)])

    const assignPersonToAccountBody = (row) => {
        return <Button icon={row?.tpid === assignedPersonTpId ? "pi pi-check" : "pi pi-times"}
                       onClick={() => dispatch(addToAppStore(applicationStore.ASSIGNED_PERSON_TPID, row?.tpid))}/>
    }

    useEffect(() => {
        if (validationError) {
            guidanceMessage.current.replace({
                severity: 'error', sticky: true, content: (
                    <React.Fragment className="mb-1">
                        <i className="pi pi-info p-2"
                           style={{borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}/>
                        <div className="ml-2">
                            <Translate value="message.invalidPercentageMessage"/>
                        </div>
                    </React.Fragment>
                )
            });
        } else {
            guidanceMessage.current.clear();
        }
    }, [validationError])

    const onChangePercentage = (value, id) => {
        dispatch(addToAppStore("ownershipPercentage", {...ownershipPercentage, id: value}));

        totalPercentageSellers = totalPercentageSellers.filter(function (obj) {
            if (obj.id === id) {
                obj.percentage = parseInt(value) ? parseInt(value) : ""
            }
            return obj
        })

        const totalPercentage = totalPercentageSellers.reduce((sum, obj) => sum + (obj.percentage ? parseInt(obj.percentage) : 0), 0);

        if (totalPercentage > 100 || totalPercentage < 100) {
            setValidationError({"error": "Invalid percentage"});
        } else {
            setValidationError(null);
        }
        dispatch(addToAppStore("totalPercentageSellers", totalPercentageSellers));
    };

    const onChangeLead = (sellerId) => {
        totalPercentageSellers = totalPercentageSellers.filter(function (obj) {
            obj.lead = obj.id === sellerId;

            return obj;
        });
        dispatch(addToAppStore("totalPercentageSellers", totalPercentageSellers));
    };

    const percentageTemplate = (rowData) => {
        let percForPerson = totalPercentageSellers.find((obj) => {
            return obj.id === rowData.sellerId;
        });

        let readOnly = false;

        if(!isOperationModeProtected() && !isNotRegisteredUserRole(userRights) && appFormValues && isUseCaseChangeOwnership(appFormValues) && appFormValues.changeOwnershipSubUseCase ===  changeOwnershipSubUseCases.CHANGE_LEAD){
            readOnly = true;
        }

        return (
            <InputText
                value={percForPerson ? percForPerson.percentage : ""}
                maxLength={3}
                onChange={(e) => onChangePercentage(e.target.value, rowData.sellerId)}
                disabled={readOnly}
            />
        );
    };

    const leadTemplate = (rowData) => {

        let leadForPerson = totalPercentageSellers.find((obj) => {
            return obj.id === rowData.sellerId;
        });

        let readOnly = false;

        if(!isOperationModeProtected() && !isNotRegisteredUserRole(userRights) && appFormValues && isUseCaseChangeOwnership(appFormValues) && appFormValues.changeOwnershipSubUseCase ===  changeOwnershipSubUseCases.CHANGE_SHARE){
            readOnly = true;
        }

        return (
            <Checkbox
                checked={leadForPerson?.lead ?? false}
                disabled={readOnly}
                onChange={() => {
                    onChangeLead(rowData?.sellerId);
                }}
            />
        );
    };

    const columns = [
        {
            field: "id",
            header: I18n.t(getPath("id")),
            hidden: true,
        },
        {
            field: "sellerId",
            header: I18n.t(getPath("sellerId")),
            hidden: true,
        },
        {
            field: "tpid",
            header: I18n.t(getPath("tpid")),
        },
        {
            field: "entityName",
            header: I18n.t(getPath("name")),
        },
        {
            field: "entityType",
            header: I18n.t(getPath("entityType")),
        },
        {
            field: "percentage",
            header: I18n.t(getPath("percentage")),
            body: percentageTemplate,
        },
        {
            field: "lead",
            header: I18n.t(getPath("lead")),
            body: leadTemplate,
        },
        {
            field: "enrollmentStatus",
            header: I18n.t(getPath("enrollmentStatus")),
        },
        {
            field: "assignPersonToAccount",
            header: I18n.t(getPath("assignPersonToAccount")),
            body: assignPersonToAccountBody,
            hidden: (isNotProtectedMode || (applyWithoutAccount && isProtectedMode))
        }
    ];

    const customActionTemplate = (row) => {
        return (
            <div className="actionColumn" style={{display: "flex"}}>
                {allowRemoveFromStore() && <Button icon="pi pi-minus-circle" className="p-button-rounded p-button-danger"
                                                   onClick={() => removePersonFromStore(row)}/>}
                {allowEditPerson() && <Button icon="pi pi-pencil" className="p-button-rounded p-button-secondary"
                                              onClick={() => editPerson(row)}/>}
            </div>
        )
    }

    function allowEditPerson(){
        if(isNotRegisteredUserRole(userRights)){
            return true;
        }

        if(isOperationModeProtected() && disableAddIdentitiesButtonListOfUC.includes(appFormValues["useCase"])){
            return false;
        }

        if(isUseCaseChangeOwnership(appFormValues)){
            switch (appFormValues.changeOwnershipSubUseCase) {
                case changeOwnershipSubUseCases.CHANGE_LEAD:
                case changeOwnershipSubUseCases.CHANGE_SHARE:
                case changeOwnershipSubUseCases.DELETE_OWNER:
                case changeOwnershipSubUseCases.ADD_OWNER:
                case changeOwnershipSubUseCases.BY_COURT:
                case changeOwnershipSubUseCases.SALE_AND_BUY:
                    return false;
                case changeOwnershipSubUseCases.BY_AUCTION:
                default:
                    return true;
            }
        }
        return true;
    }

    function allowRemoveFromStore(){
        if(isNotRegisteredUserRole(userRights)){
            return true;
        }

        if(isOperationModeProtected() && disableAddIdentitiesButtonListOfUC.includes(appFormValues["useCase"])){
            return false;
        }

        if(isUseCaseChangeOwnership(appFormValues)){
            switch (appFormValues.changeOwnershipSubUseCase) {
                case changeOwnershipSubUseCases.CHANGE_LEAD:
                case changeOwnershipSubUseCases.CHANGE_SHARE:
                case changeOwnershipSubUseCases.SALE_AND_BUY:
                case changeOwnershipSubUseCases.BY_COURT:
                case changeOwnershipSubUseCases.ADD_OWNER:
                    return false;
                case changeOwnershipSubUseCases.BY_AUCTION:
                case changeOwnershipSubUseCases.REMOVE_OWNER:
                default:
                    return true;
            }
        }
        return true;
    }

    const editPerson = (row) => {
        let personData = listOfSellers[row.id];
        personData = extractApplicantDataToFormValues(personData.applicantData);

        if (row.entityType === LEGAL_ENTITY) {
            dispatch(addToAppStore('isIdentityLegalEntity', true))
            let legalEntityData = extractObjectBasedOnKeysList(personData, legalEntityFormFieldList)
            dispatch(addMultipleInitialValuesToForm(forms.LEGAL_ENTITY + props.formNameSufix, legalEntityData));
        } else {
            dispatch(addToAppStore('isIdentityLegalEntity', false))
            personalInformationFormFieldList.push(formFieldNames.OWNERSHIP_PERCENTAGE, formFieldNames.OWNERSHIP_LEAD);
            let personalInformation = extractObjectBasedOnKeysList(personData, personalInformationFormFieldList);
            let addressData = extractObjectBasedOnKeysList(personData, addressDataFormFieldList);
            if(configurationData?.operationMode === "PROTECTED"){
                if (personalInformation["placeOfBirthProvince"]) {
                    handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, personalInformation["placeOfBirthProvince"], dictionaryReference.CITIES_PLACE_OF_BIRTH)
                }

                if (personalInformation["placeOfBirthCity"]) {
                    handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, personalInformation["placeOfBirthCity"], dictionaryReference.VILLAGES_PLACE_OF_BIRTH)
                }

                if (addressData["addressProvince"]) {
                    handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, addressData["addressProvince"], dictionaryReference.CITIES_ADDRESS)
                    handleCommonServices.handleGetSingleDictionary(projectDictionaries.INFO, addressData["addressProvince"])
                }

                if (addressData["addressCity"]) {
                    handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, addressData["addressCity"], dictionaryReference.VILLAGES_ADDRESS)
                }

                if (addressData["provinceOfRegistration"]) {
                    handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, addressData["provinceOfRegistration"], dictionaryReference.CITIES_OF_REGISTRATION)
                }

                if (addressData["cityOfRegistration"]) {
                    handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, addressData["cityOfRegistration"], dictionaryReference.VILLAGES_OF_REGISTRATION)
                }
            }
            dispatch(addMultipleInitialValuesToForm(forms.PERSONAL_INFORMATION + props.formNameSufix, personalInformation));
            dispatch(addMultipleInitialValuesToForm(forms.ADDRESS_DATA + props.formNameSufix, addressData));
        }

        //TODO logic should be moved elsewhere due to possibility to cancel person edit
        totalPercentageSellers = totalPercentageSellers.filter(function (obj) {
            return obj.id != listOfSellers[row.id].sellerId
        })

        dispatch(addToAppStore("totalPercentageSellers", totalPercentageSellers))

        listOfSellers.splice(row.id, 1);
        dispatch(addToAppStore('listOfSellers', listOfSellers))
        dispatch(addToAppStore('showAddPersonForm', true))

    }

    const removePersonFromStore = (row) => {
        let sellers = [];
        let removedApplicant;
        if(isSubUseCaseDeleteOwner(appFormValues)){
            listOfSellers.forEach((e,i) => {
                if(i == row?.id){
                    removedApplicant = {...listOfSellers[i]}
                    removedApplicant.applicantData.genericFieldsDTO.genericFieldDTOList.push({
                        key: "isRemoved",
                        value: true,
                        genericFieldDataType: 'BOOLEAN'
                    })
                    removedApplicant.applicantData.ownershipDTO.percent = 0;
                    sellers.push(removedApplicant)
                }else{
                    sellers.push(listOfSellers[i])
                }
            })
        }else{
            listOfSellers.splice(row.id, 1)
            sellers = listOfSellers
        }

        totalPercentageSellers.splice(row.id, 1)
        dispatch(addToAppStore('listOfSellers', sellers))
        dispatch(addToAppStore('totalPercentageSellers', totalPercentageSellers))
        setPersonsTableData(preparePersonForTable(sellers));
    }

    const isFormValid = () => {
        if(totalPercentageSellers && totalPercentageSellers.length > 0){
            let checkSeller = totalPercentageSellers.find((obj) => {
                return obj;
            });

            let totalPercentage = checkSeller ? totalPercentageSellers.reduce((total, obj) => {
                let sumNum = obj.percentage ? parseInt(obj.percentage) : 0
                return total + sumNum;
            }, 0) : 0;

            let checkSellerLead = totalPercentageSellers.some((obj) => {
                return obj.lead === true;
            });

            if(isListOfSellersValid && totalPercentage === 100 && checkSellerLead && !validationError && !changeOwnershipValidation()){
                return true;
            }
        }
        return false;
    }
    const onNextStep = () => {
        if (isFormValid()) {
            dispatch(addToAppStore('sourcePersonStepCompleted', true))
            dispatch(addMultipleErrorToStep(APPLICATION_STEPS.PERSONAL_DATA, {}, props.navigationName))
            dispatch(completeStep(props.navigationName, APPLICATION_STEPS.PERSONAL_DATA))
        } else {
            dispatch(addToAppStore('sourcePersonStepCompleted', false))
            dispatch(addMultipleErrorToStep(APPLICATION_STEPS.PERSONAL_DATA, {"error": "testerror"}, props.navigationName))
        }
        dispatch(nextStep(props.navigationName))
    }

    function changeOwnershipValidation(){
        if(isNotRegisteredUserRole(userRights)){
            return false;
        }
        if(isUseCaseChangeOwnership(appFormValues)){
            switch (appFormValues.changeOwnershipSubUseCase) {
                case changeOwnershipSubUseCases.CHANGE_LEAD:
                    return changeLeadValidation();
                case changeOwnershipSubUseCases.CHANGE_SHARE:
                    return changePercentValidation();
                case changeOwnershipSubUseCases.ADD_OWNER:
                    return addOwnerValidation();
                case changeOwnershipSubUseCases.DELETE_OWNER:
                    return removeOwnerValidation();
                case changeOwnershipSubUseCases.BY_AUCTION:
                case changeOwnershipSubUseCases.BY_COURT:
                case changeOwnershipSubUseCases.SALE_AND_BUY:
                default:
                    return false;
            }
        }
        return false;
    }

    function addOwnerValidation(){
        if(totalPercentageSellers.length > countOfFetchedOwners){
            return false;
        }
        return true;
    }

    function removeOwnerValidation(){
        if(totalPercentageSellers.length < countOfFetchedOwners){
            return false;
        }
        return true;
    }

    function changeLeadValidation(){
        for(let a = 0; a <totalPercentageSellers.length; a++){
            if(totalPercentageSellers[a].lead !== fetchedValuesTPS[a].lead){
                return false;
            }
        }
        return true;
    }

    function changePercentValidation(){
        for(let a = 0; a <totalPercentageSellers.length; a++){
            if(totalPercentageSellers[a].percentage !== fetchedValuesTPS[a].percentage){
                return false;
            }
        }
        return true;
    }

    const onPrevious = () => {
        dispatch(previousStep(props.navigationName))
    }

    let addPersonButtonLabel = showAddPersonForm ? "action.cancelAddPerson" : "action.addNewPerson"
    let addLegalEntityButtonLabel = showAddPersonForm ? "action.cancelAddLegalEntity" : "action.addLegalEntity"

    const addPerson = (entityType) => {
        dispatch(addToAppStore('showAddPersonForm', !showAddPersonForm))
        if (entityType === LEGAL_ENTITY) {
            dispatch(addToAppStore('isIdentityLegalEntity', true))
            dispatch(addToAppStore('isPersonLegalEntity', true))
        } else {
            dispatch(addToAppStore('isIdentityLegalEntity', false))
            dispatch(addToAppStore('isPersonLegalEntity', false))
        }
    }

    const cancelAddEntityEnrollment = () => {
        dispatch(addToAppStore('showAddPersonForm', !showAddPersonForm))
        dispatch(removeAllValuesFromForm(forms.PERSONAL_INFORMATION + props.formNameSufix))
        dispatch(removeAllValuesFromForm(forms.ADDRESS_DATA + props.formNameSufix))
        dispatch(removeAllValuesFromForm(forms.LEGAL_ENTITY + props.formNameSufix))
    }

    const personInformationSteps = [
        {
            id: APPLICATION_STEPS.PERSONAL_DATA,
            label: isMobile() ? null : <Translate value={"label." + i18nl.PERSONAL_INFORMATION} />,
            icon: 'pi pi-user',
            component: <PersonalInformationForm navigationName={navigationName.SUB_NAVIGATION}
                                                formNameSufix={props.formNameSufix}
                                                formName={forms.PERSONAL_INFORMATION + props.formNameSufix}/>,
            validateFn: usePersonalInformationValidation
        },
        {
            id: APPLICATION_STEPS.ADDRESS_DATA,
            label: isMobile() ? null : <Translate value={"label." + i18nl.ADDRESS_DATA} />,
            icon: 'pi pi-id-card',
            component: <AddressDataForm navigationName={navigationName.SUB_NAVIGATION} formNameSufix={props.formNameSufix}
                                        formName={forms.ADDRESS_DATA + props.formNameSufix}/>,
            validateFn: useAddressEnrollmentFormDataValidation
        },
        {
            id: "personSummaryInformation",
            label: isMobile() ? null : <Translate value={"label." + i18nl.PERSON_SUMMARY} />,
            icon: "pi pi-file",
            component: <PersonSummaryTaskWrapper navigationName={navigationName.SUB_NAVIGATION}
                                                 formNameSufix={props.formNameSufix}
                                                 formName={"personSummaryInformation" + props.formNameSufix}
                                                 setPersonsTableData={handleMyPersonsTableState}/>,
        }
    ]

    const LegalEntityInformationSteps = [
        {
            id: APPLICATION_STEPS.LEGAL_ENTITY,
            label: isMobile() ? null : <Translate value={"label." + i18nl.LEGAL_ENTITY} />,
            icon: 'pi pi-user',
            component: <LegalEntityForm navigationName={navigationName.SUB_NAVIGATION} formNameSufix={props.formNameSufix}
                                        formName={forms.LEGAL_ENTITY + props.formNameSufix}/>,
            validateFn: usePersonalInformationValidation
        },
        {
            id: "personSummaryInformation",
            label: isMobile() ? null : <Translate value={"label." + i18nl.PERSON_SUMMARY} />,
            icon: "pi pi-file",
            component: <PersonSummaryTaskWrapper navigationName={navigationName.SUB_NAVIGATION}
                                                 formNameSufix={props.formNameSufix}
                                                 formName={"personSummaryInformation" + props.formNameSufix}
                                                 setPersonsTableData={handleMyPersonsTableState}/>,
        }
    ]

    function legalEntityButtonShow() {
        if (disableLeButtonForSubUC(appFormValues.changeOwnershipSubUseCase) &&
            (!showAddPersonForm || (showAddPersonForm && isIdentityLegalEntity)) &&
            (!listOfSellers || (listOfSellers && listOfSellers.length === 0)) &&
            !isBuyerLegalEntity()
        ) {
            return true;
        }
        return false;
    }

    function isBuyerLegalEntity() {
        if (listOfBuyers && listOfBuyers.length > 0 && listOfBuyers[0].entityType === LEGAL_ENTITY) {
            return true;
        } else {
            return false;
        }
    }

    function isSellerLegalEntity() {
        if (listOfSellers && listOfSellers.length > 0 && listOfSellers[0].entityType === LEGAL_ENTITY) {
            return true;
        } else {
            return false;
        }
    }

    function addPersonButtonShow() {
        if ((!showAddPersonForm || (showAddPersonForm && !isIdentityLegalEntity)) && !isSellerLegalEntity()) {
            return true;
        } else {
            return false;
        }
    }

    function addIdentityButtons() {
        if(isNotRegisteredUserRole(userRights)){
            return true;
        }
        if(appFormValues && ((isOperationModeProtected() && disableAddIdentitiesButtonListOfUC.includes(appFormValues["useCase"])) || (isUseCaseChangeOwnership(appFormValues) &&
            [changeOwnershipSubUseCases.CHANGE_LEAD, changeOwnershipSubUseCases.CHANGE_SHARE, changeOwnershipSubUseCases.DELETE_OWNER].includes(appFormValues.changeOwnershipSubUseCase)))){
            return false;
        }

        if (!showAddPersonForm) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <div>
            <div className="card circleNavWrapper" style={{padding: '1rem'}}>
                <Messages ref={guidanceMessage}/>
                {(isProtectedMode && !disableAddIdentitiesButtonListOfUC.includes(appFormValues["useCase"])) && <h5 className="mb-5"><Translate value={I18n.t(getPath(i18nl.SEARCH_PERSON))}/></h5>}
                {(isProtectedMode && !disableAddIdentitiesButtonListOfUC.includes(appFormValues["useCase"])) && <SearchApplicant role={SELLER}/>}
                {isProtectedMode && <h5 className="mb-5"><Translate value={I18n.t(getPath(i18nl.ADDED_PERSONS))}/></h5>}
                {!showAddPersonForm && <div className={"personTableWrapper"}> <SimpleTable  tableData={personsTableData}
                                                    actionTitle={I18n.t("label.actionTitle")}
                                                    customActionTemplate={customActionTemplate}
                                                    columns={columns}
                                                    paginator={!selectedPerson}
                                                    paginationFunc={paginationFunc}
                /></div>}
                {showAddPersonForm &&
                <NavigationWizardWrapper items={isIdentityLegalEntity ? LegalEntityInformationSteps : personInformationSteps}
                                         progressBar={false}
                                         readOnly={disableDraft(userRights)}
                                         navigationId={navigationName.SUB_NAVIGATION}
                                         navigationType="CIRCLE"
                                         formName={forms.PERSON_DATA}/>
                }
                <div className="col-lg-12 col-sm-12" style={{marginTop: '1rem'}}>
                    <div className="grid">
                        <div className="col-12 text-center">
                            {addIdentityButtons() && !showAddPersonForm &&
                                <div>
                                    <Button label={<Translate value={addPersonButtonLabel}/>}
                                            className="mt-2 p-button-secondary"
                                            style={{marginRight: '1rem'}} type="button"
                                            onClick={() => addPerson(NATURAL_PERSON)}/>
                                    <Button label={<Translate value={addLegalEntityButtonLabel}/>}
                                                className="mt-2 p-button-primary"
                                                style={{marginLeft: '1rem'}} type="button"
                                                onClick={() => addPerson(LEGAL_ENTITY)}/>
                                </div>
                            }
                            {showAddPersonForm &&
                                <Button label={<Translate value={"action.cancelEnrollment"}/>} className="mt-2 p-button-secondary"
                                        style={{marginRight: '1rem'}} type="button"
                                        onClick={() => cancelAddEntityEnrollment()}/>
                            }
                        </div>
                    </div>
                </div>
                {!showAddPersonForm &&
                <NavButtonsLocal customOnPrevious={() => onPrevious()}
                            customOnNext={() => onNextStep()}
                            disableNextBtn={disableNextButton(isFormValid(), userRights)}
                            customOnCancel={() => onCancel(dispatch)}/>
                }
            </div>
        </div>
    )
}
export default SearchPersonPreview;