import React from 'react';
import {navigationName} from "../../../../../constants/form";
import ApplicationsReportTable from "../../../myApplications/ApplicationReport/ApplicationReportsTable";
import {completeStep, NavButtons, nextStep} from "@mb-react/mb-react-core";
import {onCancel} from "../../../../../util/componentUtil";
import {APPLICATION_STEPS} from "../../../../../constants/store";
import {useDispatch} from "react-redux";
import NavButtonsLocal from "../../../../reactCoreComponents/NavButtonsLocal";

function ApplicationSearchWrapper() {
    const dispatch = useDispatch();

    const onNextStep = () => {
        dispatch(completeStep(navigationName.MAIN_NAVIGATION, APPLICATION_STEPS.SEARCH_APPLICATION_STEP))
        dispatch(nextStep(navigationName.MAIN_NAVIGATION))
    }

    return (
        <>
            <ApplicationsReportTable navigationName={navigationName.MAIN_NAVIGATION} searchApplicationStep={true}/>
            <NavButtonsLocal firstStep={true}
                        customOnNext={() => onNextStep()}
                        customOnCancel={() => onCancel(dispatch)}/>
        </>
    );
}

export default ApplicationSearchWrapper;