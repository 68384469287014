import React, { Component } from 'react';
import TemplateDocuments from '../tasks/faq/templateDocuments';
import MainWrapper from './wrappers/MainWrapper';

export default class TemplateDocumentsWrapper extends Component {
    render() {
        return (
            <MainWrapper>
                <div className="mbPanel">
                    <TemplateDocuments />
                </div>
            </MainWrapper>
        );
    }
}
