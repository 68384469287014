import React, { useEffect, useRef } from "react";
import {
    fieldType,
    FormElement,
    Messages,
    getFormValues
} from "@mb-react/mb-react-core";
import { formFieldNames } from "@mb-react/svarog-ui";
import { useSelector } from "react-redux";
import { I18n, Translate } from "react-redux-i18n";
import {driversLicenseDataReadOnly, filterDlCategoriesPerUseCase} from "../../../../../util/businesslogicUtil";
import { PreviousDriverLicenseData, genericFormFieldNames } from "@mb-react/krg-getid-form-data-common";
import {formFieldsQuestionnaire, forms} from "../../../../../constants/form";
import {useGetDriverLicenseDetailsFieldsSettings} from "./driverLicenseSettings/driverLicenseFieldsSettings";
import {useForm} from "react-final-form";

const DriverLicenseTask = () => {
    const useCaseFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)["useCase"])
    const docTypeFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)["documentType"])
    const workflowFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)["workflow"]);
    const questionnaireFormValues = useSelector((state) => getFormValues(state, forms.QUESTIONNAIRES))

    const form = useForm();

    const guidanceMessage1 = useRef(null);
    useEffect(() => {
        guidanceMessage1.current.show({
            severity: 'info', sticky: true, content: (
                <React.Fragment className="mb-1">
                    <i className="pi pi-info p-2" style={{
                        borderRadius: '50%',
                        backgroundColor: 'var(--surface-b)',
                        color: 'var(--surface-d)'
                    }}></i>
                    <div className="ml-2">
                        <Translate value="message.dlDataGuidanceMessage1" />
                    </div>
                </React.Fragment>
            )
        });

        if(questionnaireFormValues && questionnaireFormValues[formFieldsQuestionnaire.IS_NEW_DOCUMENT]){
            if((questionnaireFormValues?.[formFieldsQuestionnaire.ADDITIONAL_QUESTION] && questionnaireFormValues[formFieldsQuestionnaire.ADDITIONAL_QUESTION] === formFieldsQuestionnaire.FIRST_TIME_APPLY) ||
                (questionnaireFormValues?.[formFieldsQuestionnaire.ADDITIONAL_QUESTION] && questionnaireFormValues[formFieldsQuestionnaire.ADDITIONAL_QUESTION] === formFieldsQuestionnaire.FOREIGN_DOCUMENT)){
                form.change(genericFormFieldNames.ISSUE_COUNTRY_DL, "")
            }else if(questionnaireFormValues?.[formFieldsQuestionnaire.ADDITIONAL_QUESTION] && questionnaireFormValues[formFieldsQuestionnaire.ADDITIONAL_QUESTION] === formFieldsQuestionnaire.OLD_DOCUMENT){
                form.change(genericFormFieldNames.ISSUE_COUNTRY_DL, "IRQ")
            }
        }
    }, []);

    return (
            <div className="card">
                <Messages ref={guidanceMessage1} />
                {
                    useCaseFromStore && docTypeFromStore && <div className={"useCaseHeader"}><h5>{I18n.t("label.useCaseHeader", { documentType: I18n.t("label." + docTypeFromStore), useCase: I18n.t("label." + useCaseFromStore) })}</h5></div>
                }
                <h5><Translate value={"label.dlData"} /></h5>
                <hr className="sectionSeparator" />
                <div className="grid task-containerWrapper dlTaskWrapper">
                    <div className='task-container step-container row col-4'>
                        <FormElement
                            label={I18n.t('label.dlCategory')}
                            required
                            fieldType={fieldType.INPUT_DROPDOWN}
                            fieldProps={{
                                name: formFieldNames.DRIVERS_LICENSE_DATA,
                                options: filterDlCategoriesPerUseCase(useCaseFromStore),
                                readOnly: driversLicenseDataReadOnly(useCaseFromStore),
                                multiselect: true,

                            }} />
                    </div>
                    <div className='task-container step-container row col-8'>
                        <PreviousDriverLicenseData {...useGetDriverLicenseDetailsFieldsSettings()} />
                    </div>
                </div>
            </div>
    )
}
export default DriverLicenseTask;