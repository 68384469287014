import React, {useCallback, useState} from 'react';
import {addToAppStore, Button, FormElement, getFormValues, hideSpinner, showSpinner} from '@mb-react/mb-react-core';
import {fieldType} from '@mb-react/mb-react-core';
import {I18n} from 'react-redux-i18n';
import {formFieldNames} from '@mb-react/svarog-ui';
import {forms} from "../../../../../constants/form";
import {useDispatch, useSelector} from "react-redux";
import {
    handleBrandDictionaries,
    isTrailerType,
    isUseCaseExchangeRegistration
} from "../../../../../util/businesslogicUtil";
import {SelectButton} from "@mb-react/mb-react-core/dist";
import {
    cleanObject,
    personFormValuesObject,
    searchVehicleFormValues,
    vehicleFormValuesObject
} from "../../../../../util/formUtils";
import debounce from 'lodash.debounce';
import {projectDictionaries} from "../../../../../constants/dictionaries";
import {change} from 'redux-form';
import {useForm} from "react-final-form";
import {useHandleCommonServices} from "../../../../../services/commonServiceHandler";
import {prepareSpecificDictionaryElementForDropdown} from "../../../../../util/dataPreparationUtil";
import {applicationStore} from "../../../../../constants/store";

const VehicleSearchFields = () => {
    const vehicleForm = 'vehicleForm';
    const personForm = 'personForm';
    const dispatch = useDispatch();
    const form = useForm();
    const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION));
    let vehicleFormValues = useSelector((state) => getFormValues(state, forms.VEHICLE_SEARCH_FORM));
    const [showForm, setFormToShow] = useState(isUseCaseExchangeRegistration(formValues) ? personForm : vehicleForm);
    const handleCommonServices = useHandleCommonServices();
    const vehicleDataEmpty = Object.values(searchVehicleFormValues(vehicleFormValues))?.every(value => value === undefined || value === "");
    const personDataEmpty = Object.values(personFormValuesObject(vehicleFormValues))?.every(value => value === undefined || value === "");
    const trailerCategoryOptions = useSelector((state) => prepareSpecificDictionaryElementForDropdown(state, projectDictionaries.TRAILER_CATEGORIES));
    const usageTypeOptions = useSelector((state) => prepareSpecificDictionaryElementForDropdown(state, projectDictionaries.PLATE_USAGES));
    const trailerModelOptions = useSelector((state) => prepareSpecificDictionaryElementForDropdown(state, projectDictionaries.TRAILER_MODELS));
    const categoryOptions = useSelector((state) => prepareSpecificDictionaryElementForDropdown(state, projectDictionaries.VEHICLE_CATEGORIES));
    const trailerBrandOptions = useSelector((state) => prepareSpecificDictionaryElementForDropdown(state, projectDictionaries.TRAILER_BRANDS));
    const brandOptions = useSelector((state) => prepareSpecificDictionaryElementForDropdown(state, projectDictionaries.VEHICLE_BRANDS));
    const modelOptions = useSelector((state) => prepareSpecificDictionaryElementForDropdown(state, projectDictionaries.VEHICLE_MODELS));
    const onBrandChange = useCallback(debounce(event => {
        dispatch(change(forms.APPLICATION, formFieldNames.VEHICLE_MODEL, null));
        handleBrandDictionaries(formValues, event?.target?.value, handleCommonServices);
        }, 400), []
    );

    const searchVehicleEnabled = (!vehicleDataEmpty || vehicleFormValues?.[formFieldNames.VEHICLE_ID]) ||
        ((vehicleFormValues?.[formFieldNames.GIVEN_NAME] && vehicleFormValues?.[formFieldNames.SURNAME] && vehicleFormValues?.[formFieldNames.DATE_OF_BIRTH]) || vehicleFormValues?.[formFieldNames.TPID]);

    const readOnlyTPID = () => {
        if (showForm && showForm !== personForm) return true;

        if (!vehicleFormValues?.[formFieldNames.GIVEN_NAME] &&
            !vehicleFormValues?.[formFieldNames.SURNAME] &&
            !vehicleFormValues?.[formFieldNames.DATE_OF_BIRTH]) {
            return false;
        }
        return true;
    }

    const searchVehicleHandler = () => {
        dispatch(showSpinner(I18n.t('message.searchVehicle')))
        let requestData = cleanObject(vehicleFormValuesObject(vehicleFormValues));
        requestData.pageNumber = 0
        requestData.pageSize = 10
        handleCommonServices.handleSearchVehicles(requestData, () => {
            dispatch(addToAppStore(applicationStore.VEHICLES_DATA, null));
            dispatch(hideSpinner())
        })
    };

    const personDataFields = () => {
        let readOnlyPersonData = showForm ? showForm === vehicleForm || vehicleFormValues?.[formFieldNames.TPID] : true;

        return <div className='mb-4'>
            <div className='grid appSearchForm'>
                <FormElement
                    additionalClass={"col-3"}
                    label={"label." + formFieldNames.GIVEN_NAME}
                    fieldType={fieldType.INPUT_TEXT}
                    fieldProps={{
                        name: formFieldNames.GIVEN_NAME,
                        readOnly: readOnlyPersonData,
                    }}
                />
                <FormElement
                    additionalClass={"col-3"}
                    label={"label." + formFieldNames.SURNAME}
                    fieldType={fieldType.INPUT_TEXT}
                    fieldProps={{
                        name: formFieldNames.SURNAME,
                        readOnly: readOnlyPersonData,
                    }}
                />
                <FormElement
                    additionalClass={"col-3"}
                    label={"label." + formFieldNames.DATE_OF_BIRTH}
                    fieldType={fieldType.INPUT_DATE_PICKER}
                    fieldProps={{
                        name: formFieldNames.DATE_OF_BIRTH,
                        readOnly: readOnlyPersonData,
                    }}
                />
                <FormElement
                    additionalClass={"col-3"}
                    label={"label." + formFieldNames.TPID}
                    fieldType={fieldType.INPUT_TEXT}
                    fieldProps={{
                        name: formFieldNames.TPID,
                        readOnly: readOnlyTPID(),
                    }}
                />
            </div>
        </div>
    };

    const vehicleFields = () => {
        let readOnlyVehicleData = showForm ? showForm === personForm || vehicleFormValues?.[formFieldNames.VEHICLE_ID] : true;
        let readOnlyVID = true;
        if (showForm && showForm === vehicleForm && vehicleDataEmpty) {
            readOnlyVID = false;
        }

        return <div className='mb-4'>
            <div className='grid appSearchForm'>
                <FormElement
                    additionalClass={"col-3"}
                    label={"label." + formFieldNames.PLATE_NUMBER}
                    fieldType={fieldType.INPUT_TEXT}
                    fieldProps={{
                        name: formFieldNames.PLATE_NUMBER,
                        readOnly: readOnlyVehicleData,
                    }}
                />
                <FormElement
                    additionalClass={"col-3"}
                    label={"label." + formFieldNames.CHASSIS_NUMBER}
                    fieldType={fieldType.INPUT_TEXT}
                    fieldProps={{
                        name: formFieldNames.CHASSIS_NUMBER,
                        readOnly: readOnlyVehicleData,
                        maxLength: 17,
                        keyfilter: /^[^a-zOIQ+\\\-*/.,!?_@#$%^&<>()'"={}\[\]`;:|]+$/,
                    }}
                />
                <FormElement
                    additionalClass={"col-3"}
                    label={"label." + formFieldNames.VEHICLE_USAGE_TYPE}
                    fieldType={fieldType.INPUT_DROPDOWN}
                    fieldProps={{
                        name: formFieldNames.VEHICLE_USAGE_TYPE,
                        readOnly: readOnlyVehicleData,
                        selectOptions: usageTypeOptions ?? [],
                        showClear: true
                    }}
                />
                <FormElement
                    additionalClass={"col-3"}
                    label={"label." + formFieldNames.VEHICLE_ID}
                    fieldType={fieldType.INPUT_TEXT}
                    fieldProps={{
                        name: formFieldNames.VEHICLE_ID,
                        readOnly: readOnlyVID,
                    }}
                />
            </div>
            <div className='grid appSearchForm'>
                <FormElement
                    additionalClass={"col-3"}
                    label={"label." + formFieldNames.VEHICLE_BRAND}
                    fieldType={fieldType.INPUT_DROPDOWN}
                    fieldProps={{
                        name: formFieldNames.VEHICLE_BRAND,
                        readOnly: readOnlyVehicleData,
                        onChange: onBrandChange,
                        selectOptions: isTrailerType(formValues) && trailerBrandOptions ? trailerBrandOptions : brandOptions ? brandOptions : [],
                        showClear: true,
                        filter: true,
                        filterBy: "label"
                    }}
                />
                <FormElement
                    additionalClass={"col-3"}
                    label={"label." + formFieldNames.VEHICLE_MODEL}
                    fieldType={fieldType.INPUT_DROPDOWN}
                    fieldProps={{
                        name: formFieldNames.VEHICLE_MODEL,
                        readOnly: readOnlyVehicleData,
                        selectOptions: isTrailerType(formValues) && trailerModelOptions ? trailerModelOptions : modelOptions ? modelOptions : [],
                        showClear: true,
                        filter: true,
                        filterBy: "label"
                    }}
                />
                <FormElement
                    additionalClass={"col-3"}
                    label={"label." + formFieldNames.VEHICLE_CATEGORY}
                    fieldType={fieldType.INPUT_DROPDOWN}
                    fieldProps={{
                        name: formFieldNames.VEHICLE_CATEGORY,
                        readOnly: readOnlyVehicleData,
                        selectOptions: isTrailerType(formValues) && trailerCategoryOptions ? trailerCategoryOptions : categoryOptions ? categoryOptions : [],
                        showClear: true
                    }}
                />
                <FormElement
                    additionalClass={"col-3"}
                    label={"label." + formFieldNames.PRODUCTION_YEAR}
                    fieldType={fieldType.INPUT_TEXT}
                    fieldProps={{
                        name: formFieldNames.PRODUCTION_YEAR,
                        readOnly: readOnlyVehicleData,
                    }}
                />
            </div>
        </div>
    };
    const itemTemp = (e) => <span>{I18n.t('label.' + e)}</span>;
    const selectOptions = [vehicleForm, personForm];
    const selectOptionsER = [personForm];
    const searchButtons = isUseCaseExchangeRegistration(formValues) ? selectOptionsER : selectOptions
    return (
        <>
            <div className="flex justify-content-center mb-5">
                <SelectButton
                    className="mb-4 mt-3 customSelectBtn"
                    options={searchButtons}
                    value={showForm}
                    onChange={(e) => {
                        setFormToShow(e.value);
                        form.initialize({})
                    }}
                    itemTemplate={itemTemp}
                />
            </div>
            {!isUseCaseExchangeRegistration(formValues) && vehicleFields()}
            {personDataFields()}
            <div className='grid m-2'>
                <Button
                    label={I18n.t("action.search")}
                    icon="pi pi-search"
                    onClick={searchVehicleHandler}
                    type={"submit"}
                    className="p-button-primary mr-1"
                    disabled={!showForm || !searchVehicleEnabled}
                />
            </div>
        </>
    )
}
export default VehicleSearchFields;