import React from 'react';
import {Button, FormElement, getFormValues, hideSpinner, MBForm, showSpinner} from '@mb-react/mb-react-core';
import {fieldType} from '@mb-react/mb-react-core';
import {I18n} from 'react-redux-i18n';
import {formFieldNames, identificationNumberTypes} from '@mb-react/svarog-ui';
import {forms} from "../../../../../constants/form";
import {useHandleCommonServices} from "../../../../../services/commonServiceHandler";
import {useDispatch, useSelector} from "react-redux";
import {addToAppStore} from "@mb-react/mb-react-core/dist";
import {applicationStore} from "../../../../../constants/store";
import {genericFormFieldNames} from "@mb-react/krg-getid-form-data-common";

const PersonSearch = () => {
  const handleCommonServices = useHandleCommonServices();
  const formValues = useSelector((state) => getFormValues(state, forms.PERSON_SEARCH_FORM))
  const dispatch = useDispatch();

  const disabledSearchPersons = !(formValues
    && (formValues[formFieldNames.GIVEN_NAME]
      || formValues[formFieldNames.SURNAME]
      || formValues[formFieldNames.PERSONAL_NUMBER]
      || formValues[formFieldNames.TPID]
      || formValues[formFieldNames.FATHERS_NAME]
      || formValues[genericFormFieldNames.GRANDFATHER_NAME]
      || formValues[formFieldNames.MOTHERS_NAME]
      || formValues[genericFormFieldNames.MOTHERS_FATHER_NAME])
  );

  const fetchPersons = () => {
    let identificationNumbers = [];

    if (formValues[formFieldNames.TPID] != null) {
      identificationNumbers.push({
        personalNumberType: identificationNumberTypes.TPID,
        personalNumber: formValues[formFieldNames.TPID]
      })
    }

    if (formValues[formFieldNames.PERSONAL_NUMBER] != null) {
      identificationNumbers.push({
        personalNumberType: identificationNumberTypes.NATIONAL_ID,
        personalNumber: formValues[formFieldNames.PERSONAL_NUMBER]
      })
    }

    const requestData = {
      givenName: formValues[formFieldNames.GIVEN_NAME],
      surname: formValues[formFieldNames.SURNAME],
      personalNumber: formValues[formFieldNames.PERSONAL_NUMBER],
      fatherName: formValues[formFieldNames.FATHERS_NAME],
      grandfatherName: formValues[genericFormFieldNames.GRANDFATHER_NAME],
      mothersName: formValues[formFieldNames.MOTHERS_NAME],
      motherFatherName: formValues[genericFormFieldNames.MOTHERS_FATHER_NAME],
      identificationNumbers
    };

    dispatch(showSpinner(I18n.t('message.searchPerson')))
    handleCommonServices.handleGetPersons(requestData, response => {
      dispatch(hideSpinner())
      dispatch(addToAppStore(applicationStore.PERSONS_DATA, response));
    }, () => {
      dispatch(hideSpinner())
      dispatch(addToAppStore(applicationStore.PERSONS_DATA, null));
    })
  };

  return (
    <MBForm formName={forms.PERSON_SEARCH_FORM} submitFunction={() => fetchPersons()}>
      <div className='grid appSearchForm'>
        <FormElement
          label={"label." + formFieldNames.GIVEN_NAME}
          additionalClass={"col-3"}
          fieldType={fieldType.INPUT_TEXT}
          fieldProps={{
            name: formFieldNames.GIVEN_NAME,
          }}
        />
        <FormElement
          label={"label." + formFieldNames.SURNAME}
          additionalClass={"col-3"}
          fieldType={fieldType.INPUT_TEXT}
          fieldProps={{
            name: formFieldNames.SURNAME,
          }}
        />
        <FormElement
          label={"label." + formFieldNames.PERSONAL_NUMBER}
          additionalClass={"col-3"}
          fieldType={fieldType.INPUT_TEXT}
          fieldProps={{
            name: formFieldNames.PERSONAL_NUMBER,
          }}
        />
        <FormElement
          label={"label." + formFieldNames.TPID}
          additionalClass={"col-3"}
          fieldType={fieldType.INPUT_TEXT}
          fieldProps={{
            name: formFieldNames.TPID,
          }}
        />
      </div>
      <div className='grid appSearchForm'>
        <FormElement
          label={"label." + formFieldNames.FATHERS_NAME}
          additionalClass={"col-3"}
          fieldType={fieldType.INPUT_TEXT}
          fieldProps={{
            name: formFieldNames.FATHERS_NAME,
          }}
        />
        <FormElement
          label={"label." + genericFormFieldNames.GRANDFATHER_NAME}
          additionalClass={"col-3"}
          fieldType={fieldType.INPUT_TEXT}
          fieldProps={{
            name: genericFormFieldNames.GRANDFATHER_NAME,
          }}
        />
        <FormElement
          label={"label." + formFieldNames.MOTHERS_NAME}
          additionalClass={"col-3"}
          fieldType={fieldType.INPUT_TEXT}
          fieldProps={{
            name: formFieldNames.MOTHERS_NAME,
          }}
        />
        <FormElement
          label={"label." + genericFormFieldNames.MOTHERS_FATHER_NAME}
          additionalClass={"col-3"}
          fieldType={fieldType.INPUT_TEXT}
          fieldProps={{
            name: genericFormFieldNames.MOTHERS_FATHER_NAME,
          }}
        />
      </div>
      <div className='col-12 xl:col-4'>
        <Button type="submit"
                label={I18n.t('action.search')}
                className="mt-2 p-button-primary"
                icon="pi pi-search"
                disabled={disabledSearchPersons}
        />
      </div>
    </MBForm>
  )
}
export default PersonSearch;