/**
 * @author Filip Radulovic
@author Jovanovic Predrag
@author Iva Petkovic
@author Aleksandar Radovanovic
 */
import React, { Component } from 'react';
import FAQ from '../tasks/faq/FAQ';
import MainWrapper from './wrappers/MainWrapper';

export default class FAQWrapper extends Component {
    render() {
        return (
            <MainWrapper>
                <div className="mbPanel">
                    <FAQ />
                </div>
            </MainWrapper>
        );
    }
}
