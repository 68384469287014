import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ContainerHOC from '../../ContainerHOC';
import { fieldType } from '@mb-react/mb-react-core';
import { getPath, i18nl } from '../../../constants/i18n';
import { I18n, Translate } from 'react-redux-i18n';
import { Button, Captcha, change, FormElement, MBForm, Message } from "@mb-react/mb-react-core";
import { useHandleAccountServices } from '../../../services/account/accountServiceHandler';
import { formFieldNames, isRequired } from '@mb-react/svarog-ui';
import { ROOT } from "../../../constants/environment";
import krLogo1 from "../../../../public/media/KRG_logo.svg";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const handleAccountServices = useHandleAccountServices();

  const [captchaData, setCaptchaData] = useState();
  const [captchaUrl, setCaptchaUrl] = useState(null);
  const [changePasswordSuccessfullyPerformed, setChangePasswordSuccessfullyPerformed] = useState(false);

  const submitChangePassword = (data) => {
    if (data != null && captchaData != null) {
      let changePasswordParams = {
        username: null,
        password: data.password,
        newPassword: data.newPassword,
        repeatPassword: data.repeatPassword,
        captcha: data.captcha,
        captchaId: captchaData.id
      };

      handleAccountServices.handleChangePassword(changePasswordParams, () => {
        setChangePasswordSuccessfullyPerformed(true);
      },
        () => {
          getCaptcha();
          dispatch(change(formFieldNames.CAPTCHA, ''))
        })

    }
  };

  useEffect(() => {
    getCaptcha();
  }, []);


  const prepareCaptchaImage = (captchaData) => {
    if (captchaData != null) {
      return "data:image/jpg;base64," + captchaData.base64;
    }
    return null;
  };

  const prepareCaptcha = (data) => {
    if (data) {
      setCaptchaData(data);
      setCaptchaUrl(prepareCaptchaImage(data));
    }
  }
  const getCaptcha = () => {
    handleAccountServices.handleGetCaptcha(prepareCaptcha)
  };




  const validationFunc = (values) => {
    let errors = {}

    const checkRepeatPassword = (data) => values && values[formFieldNames.PASSWORD_NEW] && data == values[formFieldNames.PASSWORD_NEW];

    isRequired(values, formFieldNames.PASSWORD, errors)
    isRequired(values, formFieldNames.PASSWORD_NEW, errors)
    isRequired(values, formFieldNames.PASSWORD_REPEATED, errors)

    if (values[formFieldNames.PASSWORD_REPEATED] && !checkRepeatPassword(values[formFieldNames.PASSWORD_REPEATED])) {
      errors[formFieldNames.PASSWORD_REPEATED] = I18n.t('label.repeatedPasswordInvalid')
    }

    isRequired(values, formFieldNames.CAPTCHA, errors)

    return errors
  }

  return (
    <ContainerHOC>
      {changePasswordSuccessfullyPerformed ?
        <div className='text-center'>
          <Message
            severity="success"
            text="You have successfully changed your password. Please login with your new password."
          />
          <Button
            className="p-button-secondary p-button-text"
            style={{ width: "100%", fontSize: "13px" }}
            label={I18n.t(getPath(i18nl.LOGIN))}
            onClick={() => (window.location = ROOT + "/login")}
          />
        </div> :
        <MBForm
          formName="ChangePassword"
          submitFunction={(data) => submitChangePassword(data)}
          validate={validationFunc}
        >
          <div className="flex align-items-center justify-content-center mt-2">
            <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
              <div className="text-center">
                <div className="logoWithTagline">
                  <img src={krLogo1} alt="Logo" width={"120px"}/>
                  <div className="tagline"><Translate value="label.headerLogo"/></div>
                  <div className="tagline"><Translate value="label.headerLogo1"/></div>
                  <div className="tagline"><Translate value="label.headerLogo2"/></div>
                </div>
                <div className="text-900 text-5xl font-bold mb-3">
                  <div>
                    <Translate value="label.changePassword"/>
                  </div>
                </div>
              </div>
              <div>
              {" "}
                <FormElement
                  label='label.password'
                  fieldType={fieldType.PASSWORD}
                  required
                  fieldProps={{
                    name: formFieldNames.PASSWORD,
                    feedback: false,
                    toggleMask: true,
                  }}
                />
                <FormElement
                  label='label.newPassword'
                  fieldType={fieldType.PASSWORD}
                  required
                  fieldProps={{
                    name: formFieldNames.PASSWORD_NEW,
                    feedback: false,
                    toggleMask: true,
                  }}
                />
                <FormElement
                  label='label.repeatPassword'
                  fieldType={fieldType.PASSWORD}
                  required
                  fieldProps={{
                    name: formFieldNames.PASSWORD_REPEATED,
                    feedback: false,
                    toggleMask: true,
                  }}
                />
                <Captcha
                  captchaURL={captchaUrl}
                  fieldName={formFieldNames.CAPTCHA}
                  onRefreshCaptcha={() => getCaptcha()}
                  captchaPlaceholder={false}
                />
                <Button
                  style={{ width: "100%" }}
                  type="submit"
                  label={<Translate value="action.change" />}
                  className="mt-2 p-button-success"
                />
              </div>
            </div>
          </div>
        </MBForm>}
    </ContainerHOC>
  );
};

const validateChangePassword = values => {
  const errors = {};
  if (!values[formFieldNames.PASSWORD]) {
    errors[formFieldNames.PASSWORD] = 'required';
  } else if (!PASSWORD_SYSTEM_GENERATED_REGEX.test(values[formFieldNames.PASSWORD])) {
    errors[formFieldNames.PASSWORD] = I18n.t(getPath(i18nl.WRONG_PASSWORD_FORMAT));
  }
  if (!values[formFieldNames.PASSWORD_NEW]) {
    errors[formFieldNames.PASSWORD_NEW] = 'required';
  } else if (!PASSWORD_REGEX.test(values[formFieldNames.PASSWORD_NEW])) {
    errors[formFieldNames.PASSWORD_NEW] = I18n.t(getPath(i18nl.WRONG_PASSWORD_FORMAT));
  }
  if (!values[formFieldNames.PASSWORD_REPEATED]) {
    errors[formFieldNames.PASSWORD_REPEATED] = 'required';
  } else if (values[formFieldNames.PASSWORD_NEW] !== values[formFieldNames.PASSWORD_REPEATED]) {
    errors[formFieldNames.PASSWORD_REPEATED] = I18n.t(getPath(i18nl.REPEATED_PASSWORD_INVALID));
  }
  if (!values[formFieldNames.CAPTCHA]) {
    errors[formFieldNames.CAPTCHA] = 'required';
  }
  return errors;
};

export default ChangePassword;
