import React, {useEffect, useRef} from "react";
import {getFromAppStore} from "@mb-react/mb-react-core";
import {Messages} from "@mb-react/mb-react-core";
import {useSelector} from "react-redux";
import {I18n, Translate} from "react-redux-i18n";
import {ADDRESS_DATA_STEP} from "../../../../../constants/store";
import {useHandleWorkflowSettings} from "../../../../entries/application/handleWorkflowSettings";
import {
    AddressData,
    FoodCenterData,
    RegistrationData,
    ResidenceData
} from "@mb-react/krg-getid-form-data-common";
import {useHandleCommonServices} from "../../../../../services/commonServiceHandler";
import {dictionaryReference, projectDictionaries} from "../../../../../constants/general";
import {getFormValues} from "@mb-react/mb-react-core";
import {forms} from "../../../../../constants/form";
import {useAddressDataFieldsSettings} from "./addressDataSettings/addressDataSettings";
import {useGetRegistrationDetailsFieldsSettings} from "./addressDataSettings/registrationDataFieldSettings";
import {useGetFoodCenterDetailsFieldsSettings} from "./addressDataSettings/foodCenterDataFieldSettings";
import {useGetResidenceDetailsFieldsSettings} from "./addressDataSettings/residenceDataFieldSettings";
import {extractMultipleValuesFromGenericField} from "../../../../../util/environment";

const AddressDataTask = (formName) => {
    const applicationsFromStore = useSelector(state => getFromAppStore(state, 'fetchedApplication'));
    const useCaseFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)["useCase"]);
    const docTypeFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)["documentType"]);
    const handleWorkflowSettings = useHandleWorkflowSettings();
    const guidanceMessage1 = useRef(null);

    const identity = useSelector(state => getFromAppStore(state, 'identity'))
    const addIdentityToForm = useSelector(state => getFromAppStore(state, 'addIdentityToForm'))

    const handleCommonServices = useHandleCommonServices()


    useEffect(() => {
        if (applicationsFromStore) {
            if (applicationsFromStore["addressProvince"]) {
                handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, applicationsFromStore["addressProvince"], dictionaryReference.CITIES_ADDRESS)
                handleCommonServices.handleGetSingleDictionary(projectDictionaries.INFO, applicationsFromStore["addressProvince"])
            }

            if (applicationsFromStore["addressCity"]) {
                handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, applicationsFromStore["addressCity"], dictionaryReference.VILLAGES_ADDRESS)
            }

            if (applicationsFromStore["provinceOfRegistration"]) {
                handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, applicationsFromStore["provinceOfRegistration"], dictionaryReference.CITIES_OF_REGISTRATION)
            }

            if (applicationsFromStore["cityOfRegistration"]) {
                handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, applicationsFromStore["cityOfRegistration"], dictionaryReference.VILLAGES_OF_REGISTRATION)

            }

            if (applicationsFromStore["cateringCenterProvince"]) {
                handleCommonServices.handleGetSingleDictionary(projectDictionaries.FOOD, applicationsFromStore["cateringCenterProvince"])
            }

        }

        if (addIdentityToForm && identity) {
            if (identity["contactAddress"]) {
                let contactAddress = identity["contactAddress"];
                if (contactAddress["addressProvince"]) {
                    handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, contactAddress["addressProvince"], dictionaryReference.CITIES_ADDRESS)
                    handleCommonServices.handleGetSingleDictionary(projectDictionaries.INFO, contactAddress["addressProvince"])
                }

                if (contactAddress["addressCity"]) {
                    handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, contactAddress["addressCity"], dictionaryReference.VILLAGES_ADDRESS)
                }
            }


            if (identity["genericFieldsDTO"] && identity["genericFieldsDTO"]["genericFieldDTOList"] && identity["genericFieldsDTO"]["genericFieldDTOList"].length > 0) {

                let gfList = extractMultipleValuesFromGenericField(identity["genericFieldsDTO"]["genericFieldDTOList"], ["provinceOfRegistration", "cityOfRegistration", "cateringCenterProvince"])

                if (gfList["provinceOfRegistration"]) {
                    handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, gfList["provinceOfRegistration"], dictionaryReference.CITIES_OF_REGISTRATION)
                }

                if (gfList["cityOfRegistration"]) {
                    handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, gfList["cityOfRegistration"], dictionaryReference.VILLAGES_OF_REGISTRATION)

                }

                if (gfList["cateringCenterProvince"]) {
                    handleCommonServices.handleGetSingleDictionary(projectDictionaries.FOOD, gfList["cateringCenterProvince"])
                }
            }
        }


        guidanceMessage1.current.show({
            severity: 'info', sticky: true, content: (
                <React.Fragment className="mb-1">
                    <i className="pi pi-info p-2" style={{
                        borderRadius: '50%',
                        backgroundColor: 'var(--surface-b)',
                        color: 'var(--surface-d)'
                    }}></i>
                    <div className="ml-2">
                        <Translate value="message.addressDataGuidanceMessage1"/>
                    </div>
                </React.Fragment>
            )
        });
    }, []);

    let sectionsToRender = handleWorkflowSettings.addressDataSectionsToRender

    return (
        <div className="card">
            <Messages ref={guidanceMessage1}/>
            {
                useCaseFromStore && docTypeFromStore && <div className={"useCaseHeader"}>
                    <h5>{I18n.t("label.useCaseHeader", {
                        documentType: I18n.t("label." + docTypeFromStore),
                        useCase: I18n.t("label." + useCaseFromStore)
                    })}</h5></div>
            }
            <h5><Translate value="label.addressData"/></h5>
            <hr className="sectionSeparator"/>
            <div className="grid">
                {sectionsToRender.includes(ADDRESS_DATA_STEP.ADDRESS_DATA) &&
                    <AddressData {...useAddressDataFieldsSettings(formName)} />}
            </div>
            <h5><Translate value="label.residenceData"/></h5>
            <hr className="sectionSeparator"/>
            <div className="grid">
                {sectionsToRender.includes(ADDRESS_DATA_STEP.RESIDENCE_DATA) &&
                    <ResidenceData {...useGetResidenceDetailsFieldsSettings(formName)} />}
            </div>
            <h5><Translate value="label.registrationData"/></h5>
            <hr className="sectionSeparator"/>
            <div className="grid">
                <RegistrationData {...useGetRegistrationDetailsFieldsSettings(formName)} />

            </div>
            <h5><Translate value="label.foodCenterDetails"/></h5>
            <hr className="sectionSeparator"/>
            <div className="grid">
                <FoodCenterData {...useGetFoodCenterDetailsFieldsSettings(formName)} />
            </div>
        </div>
    )
};
export default AddressDataTask;