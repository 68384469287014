/**
 * @author pavlovicn, created on 8/18/2021.
 * @author radulovicf
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import ContainerHOC from "../ContainerHOC";
import {I18n, Translate} from "react-redux-i18n";
import {getPath, i18nl, TRG} from "../../constants/i18n";
import {Button, Message} from '@mb-react/mb-react-core';
import {ROOT} from "../../constants/environment";

class ErrorPage extends Component {
    constructor(props) {
        super(props);
    }

    getMessage() {
        switch (this.props.errorCode) {
            case 401:
                return i18nl.UNAUTHORIZED_REQUEST_MESSAGE;
            case 403:
                return i18nl.FORBIDDEN_REQUEST_MESSAGE;
            case 404:
                return i18nl.NOT_FOUND_RESOURCE_MESSAGE;
            case 503:
                return i18nl.SERVICE_UNAVAILABLE_MESSAGE;
            case 500:
                return i18nl.INTERNAL_SERVER_ERROR_MESSAGE;
            case 400:
                return i18nl.BAD_REQUEST_MESSAGE;
        }
    }


    render() {
        const {} = this.props;
        let message = this.getMessage();
        return (
            <ContainerHOC>
                <div className="surface-0 text-700 text-center padding-10">
                    <div className="text-blue-600 font-bold mb-3"></div>
                    <div className="text-900 font-bold text-5xl mb-3">Error</div>
                    <div className="text-700 text-2xl mb-5"><Message
                        severity="error"
                        text={<Translate value={getPath(message, TRG.ERROR)}></Translate>}
                    /></div>
                    {this.props.errorCode == 401 &&
                    <Button label={I18n.t(getPath(i18nl.LOGIN, TRG.MESSAGE))} icon="pi pi-home"
                            className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"
                            onClick={() => window.location = ROOT + "/logoutPage"}/>}
                    {(this.props.errorCode == 400||
                        this.props.errorCode == 403||
                        this.props.errorCode == 404||
                        this.props.errorCode == 500 ||
                        this.props.errorCode == 503)
                    &&
                    <Button label={I18n.t(getPath(i18nl.GO_TO_HOME_PAGE, TRG.MESSAGE))} icon="pi pi-home"
                            className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"
                            onClick={() => window.location = ROOT + "/"}/>}
                </div>
            </ContainerHOC>
        )
    }
}


const mapStateToProps = state => {
    return {};
}

export default connect(mapStateToProps)(ErrorPage);