import React from "react";
import ContainerHOC from "../../ContainerHOC";
import LoginProtected from "./LoginProtected";
import LoginPublic from "./LoginPublic";
import { isOperationModeProtected } from "../../../util/businesslogicUtil";

const Login = () => {

  return (
    <ContainerHOC>
      {isOperationModeProtected() ? <LoginProtected/> : <LoginPublic/>}
    </ContainerHOC>
  );
};
export default Login;
