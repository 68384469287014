import { genericFormFieldNames } from "@mb-react/krg-getid-form-data-common";
import {
    getFormValues, getFromAppStore
} from "@mb-react/mb-react-core";
import { formFieldNames } from "@mb-react/svarog-ui";
import { useMemo } from "react";
import { useForm } from "react-final-form";
import { useSelector } from "react-redux";
import { formFieldsMeta, forms } from "../../../../../../constants/form";
import { projectDictionaries } from "../../../../../../constants/general";
import { isMandatory, isReadOnly } from "../../../../../../util/businesslogicUtil";
import {ACCOUNT_IDENTITY} from "../../../../../../constants/application";

export const useMaritalDataSettings = () => {
    const form = useForm();
    const useCase = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.USE_CASE])
    const documentType = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.DOCUMENT_TYPE])
    const workflow = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldsMeta.WORKFLOW])
    const accountIdentity = useSelector(state => getFromAppStore(state, ACCOUNT_IDENTITY));

    let formValues = form?.getState()?.values ?? {}

    const onMaritalStatusChange = (e) => {
        form.change(formFieldNames.SPOUSE_NAME, '')
        form.change(genericFormFieldNames.SPOUSE_FATHER_NAME, '')
        form.change(genericFormFieldNames.SPOUSE_GRANDFATHER_NAME, '')
    }

    const handleMeritalStatusSettings = useMemo(() => {
        let maritalDataProps = {
            [formFieldNames.MARITAL_STATUS]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(formValues, formFieldNames.MARITAL_STATUS, useCase, documentType, workflow),
                    reference: projectDictionaries.MARITAL_CODES,
                    [formFieldNames.ON_CHANGE]: onMaritalStatusChange
                },
                required: isMandatory(formValues, formFieldNames.MARITAL_STATUS, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return maritalDataProps
    }, [formValues[formFieldNames.TPID]])

    const handleSpouseNameSettings = useMemo(() => {
        let maritalDataProps = {
            [formFieldNames.SPOUSE_NAME]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(formValues, formFieldNames.SPOUSE_NAME, useCase, documentType, workflow),
                },
                required: isMandatory(formValues, formFieldNames.SPOUSE_NAME, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return maritalDataProps
    }, [formValues.maritalStatus, formValues[formFieldNames.TPID]])

    const handleSpouseFatherNameSettings = useMemo(() => {
        let maritalDataProps = {
            [genericFormFieldNames.SPOUSE_FATHER_NAME]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(formValues, genericFormFieldNames.SPOUSE_FATHER_NAME, useCase, documentType, workflow),
                },
                required: isMandatory(formValues, genericFormFieldNames.SPOUSE_FATHER_NAME, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return maritalDataProps
    }, [formValues.maritalStatus, formValues[formFieldNames.TPID]])

    const handleSpouseGrandatherNameSettings = useMemo(() => {
        let maritalDataProps = {
            [genericFormFieldNames.SPOUSE_GRANDFATHER_NAME]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(formValues, genericFormFieldNames.SPOUSE_GRANDFATHER_NAME, useCase, documentType, workflow),
                },
                required: isMandatory(formValues, genericFormFieldNames.SPOUSE_GRANDFATHER_NAME, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return maritalDataProps
    }, [formValues.maritalStatus, formValues[formFieldNames.TPID]])

    return { ...handleMeritalStatusSettings, ...handleSpouseNameSettings, ...handleSpouseFatherNameSettings, ...handleSpouseGrandatherNameSettings }
};