import React from "react";


export const userImageComponent = () => {

    return (
        <div className="docImageWrap">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 840.58 1018" className="docImageSVGWrap">
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_3" data-name="Layer 3">
                        <g id="Layer_2-2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1-2">
                                <path d="M829.25,379.25c0,76,4.28,152.47-2.51,227.84-10.31,114.48-70.38,203.78-159.6,274.71A713.6,713.6,0,0,1,441.69,1003c-11.14,3.61-21.57,7.35-34.9,3C279.41,964.61,167.43,899.93,85.14,791.67,35.8,726.73,10.75,652.31,10.57,570.43c-.3-133.36.43-266.63-.57-399.9-.17-23.23,5.56-34.86,29.46-42.17C160.35,91.18,280.65,52,401,13.1a59.06,59.06,0,0,1,38.28.18C559.64,52.16,680,91.15,800.84,128.67c21.56,6.71,29.54,16.19,29,39.16-1.58,70.46-.56,141-.56,211.47Z" fill="none" stroke="rgb(85, 73, 148)" strokeMiterlimit="10" strokeWidth="20" />
                                <path d="M418.82,699.89c-33,0-65.93.38-98.89,0-41.78-.6-57.91-16.89-58.28-57.64-.82-92.3,50.89-144.72,142.76-147.62,62-2,118.81,4.91,155.13,64.21,20.2,32.95,22.47,69.23,16.94,105.09-4,26.14-26.71,35.8-52.18,36C489.14,700,454,699.89,418.82,699.89Z" fill="none" stroke="rgb(85, 73, 148)" strokeMiterlimit="10" strokeWidth="20" />
                                <path d="M520,366.45c-.14,56.19-45,101.66-100.2,101.58s-99.94-45.62-100-101.84C319.75,311.47,366.34,263.9,420,264S520.09,311.5,520,366.45Z" fill="none" stroke="rgb(85, 73, 148)" strokeMiterlimit="10" strokeWidth="20" />
                                <path d="M416.34,966.21V848.81h6.73V966.38Z" fill="rgb(85, 73, 148)" />
                                <path d="M416.34,175.42V58h6.73v117.7Z" fill="rgb(85, 73, 148)" />
                                <path d="M418.22,667.56c-33,0-66.13-.87-99.15.33-20.52.74-26.11-7.72-26.63-27.22-2-74.11,35-113.58,109.24-113.67,43.62,0,88.41-6.64,122.21,33.36,23.88,28.23,24.51,60.23,22.2,93.43-1.09,15.79-14.66,13.61-25.4,13.67C486.54,667.68,452.39,667.56,418.22,667.56Z" fill="none" />
                                <path d="M415,436.23c-38.78-1.9-69.34-36.08-66.93-74.85,2.36-37.89,37.15-67.59,76.62-65.56,36.93,1.91,69.19,36.66,66.77,71.95C488.59,410.13,457.13,438.31,415,436.23Z" fill="none" />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}