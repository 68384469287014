import {
    getFromAppStore,
    getFormValues,
    openModal,
    Accordion,
    AccordionTab,
    Button,
    FileUpload,
    MBForm,
    fieldType,
    FormElement,
    addInitialValueToForm
} from "@mb-react/mb-react-core";
import {
    ContentManagementService,
    formFieldNames,
    ServiceRequestData,
    useCreateServiceWrapper
} from "@mb-react/svarog-ui";
import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {I18n, Translate} from "react-redux-i18n";
import {SUPPORTING_DOCUMENTS_DATA} from "../../../../util/idCardFetchExtractor";
import downloadDoc from "../../../../../public/media/Images/download.png";
import docWithTick from "../../../../../public/media/Images/docWithThick.svg";
import {modalIDs} from "../../../../constants/environment";
import {applicationStore} from "../../../../constants/store";
import {ADD_DOCUMENT, ADMIN, DELETE_DOCUMENT} from "../../../../constants/admin/rights";
import {checkUserRights} from "../../../../util/util";

const AddDocumentsTask = () => {
    const dispatch = useDispatch();
    const validationMode = useSelector((state) => getFromAppStore(state, "validationMode")) || "Soft"
    const userRights = useSelector((state) => getFromAppStore(state, applicationStore.USER_RIGHTS))
    const formValuesNewDoc = useSelector((state) => getFormValues(state, "addNewDocumentForm"))
    const sdFormValues = useSelector((state) => getFormValues(state, SUPPORTING_DOCUMENTS_DATA))
    const [activeIndex, setActiveIndex] = useState(null);
    const listOfSd = useSelector((state) => getFormValues(state, "listOfFetchedSD")) || {}
    const serviceWrapper = useCreateServiceWrapper();

    const addNewDocument = [ADMIN, ADD_DOCUMENT];
    const deleteDocument = [ADMIN, DELETE_DOCUMENT];

    useEffect(() => {
        fetchDocuments();
    }, []);

    const collapseAccordion = (itemIndex) => {
        let _activeIndex = activeIndex ? [...activeIndex] : [];

        if (_activeIndex.length === 0) {
            _activeIndex.push(itemIndex);
        } else {
            const index = _activeIndex.indexOf(itemIndex);
            if (index === -1) {
                _activeIndex.push(itemIndex);
            } else {
                _activeIndex.splice(index, 1);
            }
        }

        setActiveIndex(_activeIndex);
    }

    function addDocument(docName) {
        if(sdFormValues){
            let sdArrays = Object.values(sdFormValues)

            sdArrays = sdArrays[0];

            if (sdArrays && sdArrays.length > 0) {
                for (let i = 0; i < sdArrays.length; i++) {
                    if (sdArrays[i].id === docName) {
                        let documentsListDTO = [];
                        let documentObject = {
                            name: sdArrays[i].name,
                            documentNumber: formValuesNewDoc[docName + formFieldNames.DOCUMENT_NUMBER],
                            documentType: formValuesNewDoc[docName + formFieldNames.DOCUMENT_TYPE],
                            issuingAuthority: formValuesNewDoc[docName + formFieldNames.ISSUING_AUTHORITY],
                            description: formValuesNewDoc[docName + formFieldNames.DESCRIPTION_OF_DOCUMENT],
                            documents: sdArrays[i].documents,
                            mimeType: sdArrays[i].mimeType
                        }

                        documentsListDTO.push(documentObject);

                        serviceWrapper(new ServiceRequestData(
                            ContentManagementService.saveDocument,
                            documentsListDTO,
                            null,
                            null,
                            () => {
                                onSuccessAddDocument()
                            },
                            null,
                            null,
                            null
                        ))
                    }
                }
            }
        }
    }

    const onSuccessAddDocument = () => {
        emptyTemplate(null, null);
        dispatch(addInitialValueToForm("addNewDocumentForm","newDoc" + formFieldNames.DOCUMENT_NUMBER, ""))
        dispatch(addInitialValueToForm("addNewDocumentForm","newDoc" + formFieldNames.DOCUMENT_TYPE, ""))
        dispatch(addInitialValueToForm("addNewDocumentForm","newDoc" + formFieldNames.ISSUING_AUTHORITY, ""))
        dispatch(addInitialValueToForm("addNewDocumentForm","newDoc" + formFieldNames.DESCRIPTION_OF_DOCUMENT, ""))
        fetchDocuments();
        collapseAccordion(0);
    }

    function deleteDocumentById(docId) {
        dispatch(openModal(modalIDs.CONFIRM_MODAL,
            {
                messageId: I18n.t("message.contentManagement.deleteDocument"),
                modalTitle: I18n.t("label.contentManagement.deleteDocumentModal"),
                onConfirm: () => {
                    serviceWrapper(new ServiceRequestData(
                        ContentManagementService.deleteDocumentById,
                        docId,
                        null,
                        null,
                        () => {
                            fetchDocuments();
                        },
                        () => {
                            onDeleteError()
                        }
                    ))
                }
            }))
    }

    const onDeleteError = () => {
        dispatch(openModal(modalIDs.ERROR,
            {
                messageId: I18n.t("err.contentManagement.deleteDocumentError")
            }))
    }

    const fetchDocuments = () => {
        serviceWrapper(new ServiceRequestData(
            ContentManagementService.fetchAllDocuments,
            null,
            null,
            null,
            (result) => {
                if (result) {
                    onSuccessFetch(result)
                }
            },
            null
        ))
    };

    const onSuccessFetch = (result) => {
        let listOfFetchedDocuments = [];
        if (result && result.length > 0) {
            result.forEach(sd => {
                let newSD = {
                    id: sd.id,
                    name: sd.documentNumber,
                    mimeType: sd.mimeType,
                    documents: sd.documents,
                    documentNumber: sd.documentNumber,
                    issuingAuthority: sd.issuingAuthority,
                    documentType: sd.documentType,
                    description: sd.description,
                    mandatory: true
                }
                listOfFetchedDocuments.push(newSD);

                dispatch(addInitialValueToForm("documentsListForm", sd.documentNumber + formFieldNames.DOCUMENT_NUMBER, sd.documentNumber))
                dispatch(addInitialValueToForm("documentsListForm", sd.documentNumber + formFieldNames.DOCUMENT_TYPE, sd.documentType))
                dispatch(addInitialValueToForm("documentsListForm", sd.documentNumber + formFieldNames.ISSUING_AUTHORITY, sd.issuingAuthority))
                dispatch(addInitialValueToForm("documentsListForm", sd.documentNumber + formFieldNames.DESCRIPTION_OF_DOCUMENT, sd.description))

            })

            dispatch(addInitialValueToForm("listOfFetchedSD", "listOfDocs", listOfFetchedDocuments))
        } else {
            dispatch(addInitialValueToForm("listOfFetchedSD", "listOfDocs", null))
        }
    }

    const getDocumentTypeList = () => {
        let docTypes = [
            {label: I18n.t('label.contentManagement.template'), value: "TEMPLATE"},
            {label: I18n.t('label.contentManagement.document'), value: "DOCUMENT"}
        ]
        return docTypes
    }

    let supportingDocumentsTemplateList = (listOfSd && listOfSd.listOfDocs) ? Object.values(listOfSd.listOfDocs).filter(sd => sd.documentType === "TEMPLATE") : [];
    let supportingDocumentsList = (listOfSd && listOfSd.listOfDocs) ? Object.values(listOfSd.listOfDocs).filter(sd => sd.documentType === "DOCUMENT") : [];

    const onUpload = () => {
        toast.current.show({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
    }

    const createSupportingDocumentFormArray = (documentName, document, fileName, mimeType) => {
        let array = []

        let documentObject = {}

        let sdArrays = Object.values(sdFormValues)

        if (sdArrays && sdArrays.length > 0) {
            for (let i = 0; i < sdArrays.length; i++) {
                documentObject = {
                    id: sdArrays[i].id,
                    mimeType: mimeType,
                    name: fileName,
                    documents: sdArrays[i].documents
                }
                array.push(documentObject);
            }

            //remove document from array if exists
            array = array.filter((document) => {
                return document.id !== documentName
            })
        }

        documentObject = {
            id: documentName,
            mimeType: mimeType,
            name: fileName,
            documents: [
                document
            ]
        }

        array.push(documentObject)
        dispatch(addInitialValueToForm(SUPPORTING_DOCUMENTS_DATA, "documents", array))
    }

    const customBase64Uploader = async (event) => {
        const file = event.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            const base64data = reader.result;
            const base64Doc = base64data.split(",")
            createSupportingDocumentFormArray(event.options.props.name, base64Doc[1], file.name, file.type)
        }
    }

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap itemMargin">
                <div className="flex align-items-center" style={{width: '40%'}}>
                    <img alt={file.name} role="presentation" src={docWithTick} width={100}/>
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
            </div>
        )
    }

    function openFile(e, docId) {
        e.persist();

        let b64str = "";
        let mimeType = "application/pdf"

        let arrayOfSd = Object.values(listOfSd)

        if (arrayOfSd && arrayOfSd.length > 0) {
            for (let i = 0; i < arrayOfSd.length; i++) {
                if (arrayOfSd[i].id === docId) {
                    b64str = arrayOfSd[i].documents[0];
                    mimeType = arrayOfSd[i].mimeType;
                }
            }
        }

        let documentData = "data:" + mimeType + ";base64," + b64str;

        let iframe = "<iframe width='100%' height='100%' src='" + documentData + "'></iframe>";
        let x = window.open();
        if (x) {
            x.document.open();
            x.document.write(iframe);
            x.document.close();
        }
    }

    const emptyTemplate = (file, props) => {
        return (
            <>
                <div className="flex align-items-center flex-column">
                    <i className="pi pi-image mt-3 p-5" style={{
                        'fontSize': '5em',
                        borderRadius: '50%',
                        backgroundColor: 'var(--surface-b)',
                        color: 'var(--surface-d)'
                    }} />
                    <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}}
                          className="my-5"><Translate value={"label.contentManagement.dragAndDrop"}/></span>
                </div>
            </>
        )
    }

    return (
        <Fragment>
            {checkUserRights(deleteDocument, userRights) &&
            <div className="accordion-demo">
                <div className="card">
                    <h5><Translate value="label.contentManagement.documentsList"/></h5>
                    <hr className="sectionSeparator"/>
                    <Accordion multiple>
                        {supportingDocumentsList.map((item, i) => (
                            <AccordionTab
                                header={
                                    <React.Fragment>
                                        <i className="pi pi-file"></i>
                                        <span>{I18n.t('label.' + item.name)} {item.mandatory &&
                                        <i className="pi pi-exclamation-circle" color="red"></i>}</span>
                                    </React.Fragment>}
                                key={i}>
                                <div class="docFlex">
                                    <div class="flex-1 justify-content-center">
                                        <div className="flex-1 justify-content-center">
                                            <div className="docFlex justify-content-center">
                                                <a onClick={(e) => openFile(e, item.id)}>
                                                    <img width={100} src={downloadDoc}
                                                         alt="Scanned image."/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-1 flex border-round">
                                        <MBForm formName="documentsListForm"
                                            validationMode={validationMode}
                                            submitFunction={() => {
                                            }}>
                                            <div class="formWidth">
                                                <div className="docData">
                                                    <h5><Translate value="label.contentManagement.documentData"/></h5>
                                                    <hr className="sectionSeparator"/>
                                                </div>
                                                <div className="grid">
                                                    <div className="col-12">
                                                        <FormElement
                                                            label={I18n.t('label.contentManagement.' + formFieldNames.DOCUMENT_NUMBER)}
                                                            fieldType={fieldType.INPUT_TEXT} required
                                                            fieldProps={{
                                                                name: item.name + formFieldNames.DOCUMENT_NUMBER,
                                                                readOnly: true
                                                            }}/>
                                                    </div>
                                                    <div className="col-12">
                                                        <FormElement
                                                            label={I18n.t('label.contentManagement.' + formFieldNames.DOCUMENT_TYPE)}
                                                            fieldType={fieldType.INPUT_DROPDOWN} required readOnly
                                                            fieldProps={{
                                                                name: item.name + formFieldNames.DOCUMENT_TYPE,
                                                                options: getDocumentTypeList(), readOnly: true
                                                            }}/>
                                                    </div>
                                                    <div className="col-12">

                                                        <FormElement
                                                            label={I18n.t('label.contentManagement.' + formFieldNames.ISSUING_AUTHORITY)}
                                                            fieldType={fieldType.INPUT_TEXT} required
                                                            fieldProps={{
                                                                name: item.name + formFieldNames.ISSUING_AUTHORITY,
                                                                readOnly: true
                                                            }}/>
                                                    </div>
                                                    <div className="col-12">

                                                        <FormElement
                                                            label={I18n.t('label.contentManagement.' + formFieldNames.DESCRIPTION_OF_DOCUMENT)}
                                                            fieldType={fieldType.INPUT_TEXT}
                                                            fieldProps={{
                                                                name: item.name + formFieldNames.DESCRIPTION_OF_DOCUMENT,
                                                                readOnly: true
                                                            }}/>
                                                    </div>
                                                    <div className="col-12">

                                                        <Button label={I18n.t("action.delete")}
                                                                className="p-button-danger p-button-rounded mr-2"
                                                                onClick={() => deleteDocumentById(item.id)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </MBForm>
                                    </div>
                                </div>

                            </AccordionTab>))}
                    </Accordion>
                </div>
            </div>}
            {checkUserRights(deleteDocument, userRights) &&
            <div className="accordion-demo">
                <div className="card">
                    <h5><Translate value="label.contentManagement.documentsTemplate"/></h5>
                    <hr className="sectionSeparator"/>
                    <Accordion multiple>
                        {supportingDocumentsTemplateList.map((item, i) => (
                            <AccordionTab
                                header={
                                    <React.Fragment>
                                        <i className="pi pi-file"/>
                                        <span>{I18n.t('label.' + item.name)} {item.mandatory &&
                                        <i className="pi pi-exclamation-circle" color="red"/>}</span>
                                    </React.Fragment>}
                                key={i}>
                                <div className="docFlex">
                                    <div className="flex-1 justify-content-center">
                                        <div className="docFlex justify-content-center">
                                            <a onClick={(e) => openFile(e, item.id)}>
                                                <img width={100} src={downloadDoc}
                                                     alt="Scanned image."/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="flex-1 flex border-round">
                                        <MBForm formName="documentsListForm"
                                            validationMode={validationMode}
                                            submitFunction={() => {
                                            }}>
                                            <div className="formWidth">
                                                <div className="docData">
                                                    <h5><Translate value="label.contentManagement.documentData"/></h5>
                                                    <hr className="sectionSeparator"/>
                                                </div>
                                                <div className="grid">
                                                    <div className="col-12">
                                                        <FormElement
                                                            label={I18n.t('label.contentManagement.' + formFieldNames.DOCUMENT_NUMBER)}
                                                            fieldType={fieldType.INPUT_TEXT} required
                                                            fieldProps={{
                                                                name: item.name + formFieldNames.DOCUMENT_NUMBER,
                                                                readOnly: true
                                                            }}/>
                                                    </div>
                                                    <div className="col-12">
                                                        <FormElement
                                                            label={I18n.t('label.contentManagement.' + formFieldNames.DOCUMENT_TYPE)}
                                                            fieldType={fieldType.INPUT_DROPDOWN} required
                                                            fieldProps={{
                                                                name: item.name + formFieldNames.DOCUMENT_TYPE,
                                                                options: getDocumentTypeList(), readOnly: true
                                                            }}/>
                                                    </div>
                                                    <div className="col-12">

                                                        <FormElement
                                                            label={I18n.t('label.contentManagement.' + formFieldNames.ISSUING_AUTHORITY)}
                                                            fieldType={fieldType.INPUT_TEXT} required
                                                            fieldProps={{
                                                                name: item.name + formFieldNames.ISSUING_AUTHORITY,
                                                                readOnly: true
                                                            }}/>
                                                    </div>
                                                    <div className="col-12">

                                                        <FormElement
                                                            label={I18n.t('label.contentManagement.' + formFieldNames.DESCRIPTION_OF_DOCUMENT)}
                                                            fieldType={fieldType.INPUT_TEXT} required
                                                            fieldProps={{
                                                                name: item.name + formFieldNames.DESCRIPTION_OF_DOCUMENT,
                                                                readOnly: true
                                                            }}/>
                                                    </div>
                                                    <div className="col-12">

                                                        <Button label={I18n.t("action.delete")}
                                                                className="p-button-danger p-button-rounded mr-2"
                                                                onClick={() => deleteDocumentById(item.id)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </MBForm>
                                    </div>
                                </div>

                            </AccordionTab>))}
                    </Accordion>
                </div>
            </div>}
            {checkUserRights(addNewDocument, userRights) &&
            <div className="accordion-demo">
                <div className="card">
                    <h5><Translate value="label.contentManagement.addNewDocument"/></h5>
                    <hr className="sectionSeparator"/>
                    <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <AccordionTab
                            header={
                                <React.Fragment>
                                    <i className="pi pi-file"/>
                                    <span>{I18n.t('label.contentManagement.newDocument')}</span>
                                </React.Fragment>}
                        >
                            <div className="flex">
                                <div className="flex-1 justify-content-center">
                                    <FileUpload name="newDoc" url="./upload" className="suppDocStyle"
                                                onUpload={onUpload} accept=".pdf, .docx" maxFileSize={1000000}
                                                itemTemplate={itemTemplate}
                                                emptyTemplate={emptyTemplate}
                                                chooseOptions={{label: I18n.t('label.choose')}}
                                                customUpload uploadHandler={customBase64Uploader} auto/>
                                </div>
                                <div className="flex-1 flex border-round">
                                    <MBForm formName="addNewDocumentForm"
                                        validationMode={validationMode}
                                        submitFunction={() => {
                                        }}>
                                        <div className="formWidth">
                                            <div className="docData">
                                                <h5><Translate value="label.contentManagement.documentData"/></h5>
                                                <hr className="sectionSeparator"/>
                                            </div>
                                            <div className="grid">
                                                <div className="col-12">
                                                    <FormElement
                                                        label={I18n.t('label.contentManagement.' + formFieldNames.DOCUMENT_NUMBER)}
                                                        fieldType={fieldType.INPUT_TEXT} required
                                                        fieldProps={{name: "newDoc" + formFieldNames.DOCUMENT_NUMBER}}/>
                                                </div>
                                                <div className="col-12">
                                                    <FormElement
                                                        label={I18n.t('label.contentManagement.' + formFieldNames.DOCUMENT_TYPE)}
                                                        fieldType={fieldType.INPUT_DROPDOWN} required
                                                        fieldProps={{
                                                            name: "newDoc" + formFieldNames.DOCUMENT_TYPE,
                                                            options: getDocumentTypeList()
                                                        }}/>
                                                </div>
                                                <div className="col-12">

                                                    <FormElement
                                                        label={I18n.t('label.contentManagement.' + formFieldNames.ISSUING_AUTHORITY)}
                                                        fieldType={fieldType.INPUT_TEXT} required
                                                        fieldProps={{name: "newDoc" + formFieldNames.ISSUING_AUTHORITY}}/>
                                                </div>
                                                <div className="col-12">

                                                    <FormElement
                                                        label={I18n.t('label.contentManagement.' + formFieldNames.DESCRIPTION_OF_DOCUMENT)}
                                                        fieldType={fieldType.INPUT_TEXT} required
                                                        fieldProps={{name: "newDoc" + formFieldNames.DESCRIPTION_OF_DOCUMENT}}/>
                                                </div>
                                                <div className="col-12">

                                                    <Button label={I18n.t("action.add")}
                                                            className="p-button-primary p-button-rounded mr-2"
                                                            onClick={() => addDocument("newDoc")}/>
                                                </div>
                                            </div>
                                        </div>
                                    </MBForm>
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                </div>
            </div>}
        </Fragment>

    )
}
export default AddDocumentsTask;