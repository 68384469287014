import {
    addToAppStore,
    getFromAppStore,
    NavigationWizardWrapper
} from "@mb-react/mb-react-core";
import {getCookie, getPath} from '@mb-react/svarog-ui';
import React, {useEffect} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {I18n, Translate} from "react-redux-i18n";
import {forms} from '../../../constants/form';
import {i18nl} from '../../../constants/i18n';
import {APPLICATION_STEPS, applicationStore} from '../../../constants/store';
import {disableDraft, isLoggedAccountLegalEntity} from "../../../util/businesslogicUtil";
import {isMobile} from '../../../util/environment';
import SummaryTaskWrapperIM from "../../tasks/workflowTasks/common/summary/SummaryTaskWrapperIM";
import AddressDataForm from "../../tasks/workflowTasks/document/addressData/AddressDataForm";
import LegalEntityForm from "../../tasks/workflowTasks/document/legalEntityData/LegalEntityForm";
import PersonalInformationForm from "../../tasks/workflowTasks/document/personalData/PersonalInformationForm";
import {useHandleWorkflowSettings} from './handleWorkflowSettings';
import {
    usePersonalInformationValidation
} from "../../tasks/workflowTasks/document/personalData/personalInformationValidation";
import {
    useAddressEnrollmentFormDataValidation
} from "../../tasks/workflowTasks/document/addressData/AddressDataValidation";
import {ACCOUNT_IDENTITY} from "../../../constants/application";
import {delete_cookie} from "../../../util/util";

const CreateIdentityManagementWorkflow = (props) => {
    const handleWorkflowSettings = useHandleWorkflowSettings();
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))
    const dispatch = useDispatch();
    let isLegalEntity = isLoggedAccountLegalEntity()
    const accountIdentity = useSelector(state => getFromAppStore(state, ACCOUNT_IDENTITY));

    useEffect(() => {
        handleWorkflowSettings.initWorkflowSettings()
        if(getCookie(ACCOUNT_IDENTITY)){
            dispatch(addToAppStore(ACCOUNT_IDENTITY, getCookie(ACCOUNT_IDENTITY)))
            delete_cookie(ACCOUNT_IDENTITY)
        }
    }, []);

    const items = [
        {
            id: APPLICATION_STEPS.PERSONAL_DATA,
            label: <Translate value={"label." + i18nl.PERSONAL_INFORMATION} />,
            icon: "pi pi-user",
            component: <PersonalInformationForm formName={forms.PERSONAL_INFORMATION}/>,
            validateFn: usePersonalInformationValidation
        },
        {
            id: APPLICATION_STEPS.ADDRESS_DATA,
            label: <Translate value={"label." + i18nl.ADDRESS_DATA} />,
            icon: 'pi pi-id-card',
            component: <AddressDataForm formName={forms.ADDRESS_DATA + props.formNameSufix}/>,
            validateFn: useAddressEnrollmentFormDataValidation
        },
        {
            id: APPLICATION_STEPS.CONFIRMATION_TASK,
            label: <Translate value={"label." + i18nl.SUMMARY} />,
            icon: 'pi pi-file',
            component: <SummaryTaskWrapperIM formNameSufix={'Source'}/>
        },
    ];

    const itemsLE = [
        {
            id: APPLICATION_STEPS.LEGAL_ENTITY,
            label: <Translate value={"label." + i18nl.LEGAL_ENTITY} />,
            icon: 'pi pi-user',
            component: <LegalEntityForm formNameSufix={props.formNameSufix}
                                        formName={forms.LEGAL_ENTITY + props.formNameSufix}/>,
            validateFn: usePersonalInformationValidation
        },
        {
            id: APPLICATION_STEPS.CONFIRMATION_TASK,
            label: <Translate value={"label." + i18nl.SUMMARY} />,
            icon: 'pi pi-file',
            component: <SummaryTaskWrapperIM formNameSufix={'Source'}/>
        },
    ];

    return (
        <div className="card navigation">
            {props.activeLocale && <NavigationWizardWrapper items={isLegalEntity ? itemsLE : items} mobile={isMobile()}
                                                            progressBar={!isMobile()}
                                                            readOnly={disableDraft(userRights, accountIdentity)}
                                                            formName={forms.APPLICATION}/>}
        </div>

    );
}

CreateIdentityManagementWorkflow.propTypes = {};

function mapStateToProps(state) {
    return {
        activeLocale: state.i18n.locale
    }
}

export default connect(mapStateToProps)(CreateIdentityManagementWorkflow)

