import { addInitialValueToForm, addToAppStore, change, completeStep, getFormValues, MBForm, Messages, NavButtons, nextStep, showToast, toastIDs } from "@mb-react/mb-react-core";
import { EUseCases, formFieldNames } from "@mb-react/svarog-ui";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { I18n, Translate } from "react-redux-i18n";
import { formFieldsVRQuestionnaire, forms } from "../../../../constants/form";
import { APPLICATION_STEPS } from "../../../../constants/store";
import { useHandleVRQuestionariesComponent } from "./vehicleQuestionnaireComponent";
import {onCancel} from "../../../../util/componentUtil";


const VehicleQuestionnaireData = () => {
    const dispatch = useDispatch();
    const formValues = useSelector((state) => getFormValues(state, forms.QUESTIONNAIRES_VEHICLE));

    let handleVRQuestionariesComponent = useHandleVRQuestionariesComponent()
    const questionnaireMessage = useRef(null);

    useEffect(() => {
        questionnaireMessage.current.show({
            severity: 'info', sticky: true, content: (
                <React.Fragment className="mb-1">
                    <i className="pi pi-info p-2"
                        style={{ borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                    <div className="ml-2">
                        <Translate value="message.QuestioonnarieMessage1" />
                    </div>
                </React.Fragment>
            )
        });

        //TODO update useHandleQuestionnairesComponent
        if (formValues) {
            Object.keys(formValues).forEach(key => {
                dispatch(change(forms.QUESTIONNAIRES_VEHICLE, key, formValues[key]))
            }
            )
        }
    }, []);

    return (
        <>
            <div className="card">
                <Messages ref={questionnaireMessage} />
                {handleVRQuestionariesComponent.isNewVRComponent}
                {handleVRQuestionariesComponent.isNewVR && handleVRQuestionariesComponent.newVRYesComponent}
                {handleVRQuestionariesComponent.noNewVR && handleVRQuestionariesComponent.replaceVRComponent}
                {handleVRQuestionariesComponent.replaceVR && handleVRQuestionariesComponent.replaceVRComponentYes}
                {handleVRQuestionariesComponent.noReplaceVR && handleVRQuestionariesComponent.isModifyDocumentComponentVR}
                {handleVRQuestionariesComponent.noModifyDocumentVR && handleVRQuestionariesComponent.isExchangeDocumentComponent}
                {handleVRQuestionariesComponent.noExchangeDocumentVR && handleVRQuestionariesComponent.isReserveDocumentComponent}
                {handleVRQuestionariesComponent.noReserveDocumentVR && handleVRQuestionariesComponent.isChangeOwnershipDocumentComponent}
                {handleVRQuestionariesComponent.changeOwnershipDocumentVR && handleVRQuestionariesComponent.isChangeOwnershipDocumentYesComponent}
                {handleVRQuestionariesComponent.noChangeOwnershipDocumentVR && handleVRQuestionariesComponent.cancelDocumentComponent}
            </div>
            <NavButtons firstStep={true} customOnCancel={() => onCancel(dispatch)}/>
        </>
    )
};

const QuestionnaireVehicleDataForm = () => {
    const dispatch = useDispatch();

    const questionnaireMessage = useRef(null);

    const validationFunc = () => {
        let errors = {}

        return errors
    }

    const toMoveOn = (formValues) => {
        if ((formValues?.[formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR] && formValues[formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR] === formFieldsVRQuestionnaire.FIRST_TIME_APPLY_VR) ||
            (formValues?.[formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR] && formValues[formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR] === formFieldsVRQuestionnaire.OLD_VR) ||
            (formValues?.[formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR] && formValues[formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR] === formFieldsVRQuestionnaire.FOREIGN_VR) ||
            (formValues?.[formFieldsVRQuestionnaire.LOST_VR] && formValues[formFieldsVRQuestionnaire.LOST_VR] === true) ||
            (formValues?.[formFieldsVRQuestionnaire.DAMAGED_VR2] && formValues[formFieldsVRQuestionnaire.DAMAGED_VR2] === true) ||
            (formValues?.[formFieldsVRQuestionnaire.EXPIRED_VR] && formValues[formFieldsVRQuestionnaire.EXPIRED_VR] === true) ||
            (formValues?.[formFieldsVRQuestionnaire.EXPIRED_VR2] && formValues[formFieldsVRQuestionnaire.EXPIRED_VR2] === true) ||
            (formValues?.[formFieldsVRQuestionnaire.EXPIRED_VR3] && formValues[formFieldsVRQuestionnaire.EXPIRED_VR3] === true) ||
            (formValues?.[formFieldsVRQuestionnaire.IS_MODIFY_VR] && formValues[formFieldsVRQuestionnaire.IS_MODIFY_VR] === "YES") ||
            (formValues?.[formFieldsVRQuestionnaire.IS_EXTEND_VR] && formValues[formFieldsVRQuestionnaire.IS_EXTEND_VR] === "YES") ||
            (formValues?.[formFieldsVRQuestionnaire.IS_CANCEL_VR] && formValues[formFieldsVRQuestionnaire.IS_CANCEL_VR] === "YES") ||
            (formValues?.[formFieldsVRQuestionnaire.IS_EXCHANGE_VR] && formValues[formFieldsVRQuestionnaire.IS_EXCHANGE_VR] === "YES") ||
            (formValues?.[formFieldsVRQuestionnaire.IS_RESERVE_PLATE_VR] && formValues[formFieldsVRQuestionnaire.IS_RESERVE_PLATE_VR] === "YES") ||
            ((formValues?.[formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR] && formValues[formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR] === "YES") &&
            formValues[formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR])
        ) {
            dispatch(addToAppStore('questionnariesCompletedVR', true))

            //TODO need's to be set based on UC if UC contain this steps
            dispatch(addToAppStore('sourcePersonStepCompleted', false))
            dispatch(addToAppStore('sourceVehicleStepCompleted', false))
        } else {
            dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', { messageId: I18n.t("message.QuestioonnarieMessage1") }))
        }
    };

    const mapQuestionsToIsNewDocumentVRUseCase = (formValues) => {
        if (formValues?.[formFieldsVRQuestionnaire.IS_NEW_VR] && formValues[formFieldsVRQuestionnaire.IS_NEW_VR]) {
            if (formValues?.[formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR] && formValues[formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR] === formFieldsVRQuestionnaire.FIRST_TIME_APPLY_VR) {
                dispatch(addInitialValueToForm(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.NEW));
            } else if (formValues?.[formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR] && formValues[formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR] === formFieldsVRQuestionnaire.OLD_VR) {
                dispatch(addInitialValueToForm(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.RE_REGISTER));
            }
        }
    };

    const mapQuestionsToIsReplaceDocumentVRUseCase = (formValues) => {
        if (formValues?.[formFieldsVRQuestionnaire.IS_REPLACE_VR]) {
            if (formValues?.[formFieldsVRQuestionnaire.LOST_VR] && formValues[formFieldsVRQuestionnaire.LOST_VR] === true) {
                dispatch(addInitialValueToForm(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.REPLACE));
            } else if (formValues?.[formFieldsVRQuestionnaire.DAMAGED_VR2] && formValues[formFieldsVRQuestionnaire.DAMAGED_VR2] === true) {
                dispatch(addInitialValueToForm(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.PARTLY_DAMAGED));
            }
        }
    };

    const mapQuestionsToIsExpiredDocumentVRUseCase = (formValues) => {
        if ((formValues?.[formFieldsVRQuestionnaire.LOST_VR] && formValues[formFieldsVRQuestionnaire.LOST_VR] === true) &&
            (formValues?.[formFieldsVRQuestionnaire.EXPIRED_VR] && formValues[formFieldsVRQuestionnaire.EXPIRED_VR] === true) ||
            (formValues?.[formFieldsVRQuestionnaire.LOST_VR] && formValues[formFieldsVRQuestionnaire.LOST_VR] === true)) {
            dispatch(addInitialValueToForm(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.REPLACE));
        } else if ((formValues?.[formFieldsVRQuestionnaire.DAMAGED_VR2] && formValues[formFieldsVRQuestionnaire.DAMAGED_VR2] === true) &&
            (formValues?.[formFieldsVRQuestionnaire.EXPIRED_VR] && formValues[formFieldsVRQuestionnaire.EXPIRED_VR] === true) ||
            (formValues?.[formFieldsVRQuestionnaire.DAMAGED_VR2] && formValues[formFieldsVRQuestionnaire.DAMAGED_VR2] === true)) {
            dispatch(addInitialValueToForm(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.PARTLY_DAMAGED));
        } else if (formValues?.[formFieldsVRQuestionnaire.EXPIRED_VR] && formValues[formFieldsVRQuestionnaire.EXPIRED_VR] === true) {
            dispatch(addInitialValueToForm(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.EXPIRED));
        }
    };

    const mapQuestionsToIsModifyDocumentVRUseCase = (formValues) => {
        if ((formValues?.[formFieldsVRQuestionnaire.IS_MODIFY_VR] && formValues[formFieldsVRQuestionnaire.IS_MODIFY_VR] === "YES") ||
            (formValues?.[formFieldsVRQuestionnaire.IS_MODIFY_VR] && formValues[formFieldsVRQuestionnaire.IS_MODIFY_VR] === "YES") &&
            (formValues?.[formFieldsVRQuestionnaire.EXPIRED_VR3] && formValues[formFieldsVRQuestionnaire.EXPIRED_VR3] === true)) {
            dispatch(addInitialValueToForm(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.MODIFY_VEHICLE_DATA))
        }
    };

    const mapQuestionsToIsExchangeDocumentVRUseCase = (formValues) => {
        if ((formValues?.[formFieldsVRQuestionnaire.IS_EXCHANGE_VR])) {
            dispatch(change(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.EXCHANGE_REGISTRATION))
        }
    };
    const mapQuestionsToIsReserveDocumentVRUseCase = (formValues) => {
        if ((formValues?.[formFieldsVRQuestionnaire.IS_RESERVE_PLATE_VR])) {
            dispatch(change(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.RESERVE_PLATE_NUMBER))
        }
    };
    const mapQuestionsToIsChangeOwnershipDocumentVRUseCase = (formValues) => {
        if ((formValues?.[formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR])) {
            dispatch(change(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.CHANGE_OWNERSHIP))
        }
    };
    const mapQuestionsToIsCancelDocumentUseCase = (formValues) => {
        if ((formValues?.[formFieldsVRQuestionnaire.IS_CANCEL_VR])) {
            dispatch(change(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.CANCEL))
        }
    };

    const onNextStep = (values) => {
        mapQuestionsToIsNewDocumentVRUseCase(values)
        mapQuestionsToIsReplaceDocumentVRUseCase(values)
        mapQuestionsToIsExpiredDocumentVRUseCase(values)
        mapQuestionsToIsModifyDocumentVRUseCase(values)
        mapQuestionsToIsExchangeDocumentVRUseCase(values)
        mapQuestionsToIsReserveDocumentVRUseCase(values)
        mapQuestionsToIsChangeOwnershipDocumentVRUseCase(values)
        mapQuestionsToIsCancelDocumentUseCase(values)

        toMoveOn(values)
    }

    return (
        <MBForm
            formName={forms.QUESTIONNAIRES_VEHICLE}
            stepId={APPLICATION_STEPS.QUESTIONNAIRE_VR_DATA}
            submitFunction={(values) => {
                dispatch(onNextStep(values))
            }} validate={validationFunc}>
            <VehicleQuestionnaireData />
        </MBForm>
    )

}


export default QuestionnaireVehicleDataForm;