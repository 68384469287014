import { Button, getFromAppStore } from "@mb-react/mb-react-core";
import React from "react";
import { useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";
import {
  DEVELOPMENT, REDIRECT_TO_LOGIN, REDIRECT_TO_MY_APPLICATIONS,
  REDIRECT_TO_MY_APPOINTMENTS,
  REDIRECT_TO_NEW_DRIVER_LICENSE, REDIRECT_TO_NEW_VEHICLE_REGISTRATION
} from "../../../constants/environment";
import { applicationStore } from "../../../constants/store";
import { isIdentityActivated, isLoggedIn, isOperationModeProtected, isUser } from "../../../util/businesslogicUtil";
import ContainerHOC from "../../ContainerHOC";
import { docImageComponent } from "../../iconComponents/docImage";
import { GearImageComponent } from "../../iconComponents/gearImage";
import { myAppointmentsComponent } from "../../iconComponents/myappointments";
import HomeFooter from "../../shared/global/HomeFooter";
import HomeFooter2 from "../../shared/global/HomeFooter2";
import AdminHome from "../admin/AdminHome";
import { VehicleIcon } from "../../iconComponents/VehicleIcon";
import {delete_cookie} from "../../../util/util";
import {formFieldNames} from "@mb-react/svarog-ui";
import {genericFormFieldNames} from "@mb-react/krg-getid-form-data-common";
import HomeWrapper from "./HomeWrapper";

const Home = () => {

  const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))
  let isIdentityActive = isIdentityActivated()
  useSelector(state => state.i18n.locale)

  if (isOperationModeProtected() && !isLoggedIn() && !(process.env.NODE_ENV == DEVELOPMENT)) {
    window.location = REDIRECT_TO_LOGIN
  }

  //clear usecase, subusecase cookies
  delete_cookie(formFieldNames.USE_CASE)
  delete_cookie([genericFormFieldNames.CHANGE_OWNERSHIP_SUB_USE_CASE])

  return (
    <div className="homeWrapper">
      <ContainerHOC fullPage cssClass>
        {isOperationModeProtected() ? <AdminHome /> : <HomeWrapper />}
        {
          (isLoggedIn() && isUser(userRights) && !isOperationModeProtected() && isIdentityActive) &&
          <div className="grid home-page nav">
            <div className="col-12 btnImgTextWrap">
              {!configurationData.useDDL && <Button label={<Translate value="label.applyForNewDriverLicense" />} applyForNewDriverLicense icon={docImageComponent} className="p-button-rounded p-button-text btnCompImg" aria-label="Submit"
                onClick={() => window.location = REDIRECT_TO_NEW_DRIVER_LICENSE} />}
              {/* 
              {!configurationData.dlGoLive &&
                <Button label={<Translate value="label.bookAppointment" />} icon={dateTimeColorComponent} className="p-button-rounded p-button-text btnCompImg" aria-label="Submit"
                  onClick={() => window.location = REDIRECT_TO_APPOINTMENT} />} */}
              {!configurationData.dlGoLive && !configurationData.useDDL &&
                <Button label={<Translate value="label.vehicleRegistration" />} icon={VehicleIcon} className="p-button-rounded p-button-text btnCompImg" aria-label="Submit"
                  onClick={() => window.location = REDIRECT_TO_NEW_VEHICLE_REGISTRATION} />}

              <Button label={<Translate value="label.myApplications" />} icon={GearImageComponent} className="p-button-rounded p-button-text btnCompImg" aria-label="Submit"
                onClick={() => window.location = REDIRECT_TO_MY_APPLICATIONS} />

              {!configurationData.dlGoLive &&
                <Button label={<Translate value="label.myAppointments" />} icon={myAppointmentsComponent} className="p-button-rounded p-button-text btnCompImg" aria-label="Submit"
                  onClick={() => window.location = REDIRECT_TO_MY_APPOINTMENTS} />}

            </div>
          </div>
        }
        {/*{isLoggedIn() && <HomeFooter /> || !isLoggedIn() && <HomeFooter2 />}*/}
      </ContainerHOC>
    </div>
  );
}
export default Home
