import React, {useState} from 'react';
import ContainerHOC from '../../ContainerHOC';
import EditAccount from './EditAccount';
import {addInitialValueToForm, MBForm} from "@mb-react/mb-react-core";
import {forms} from "../../../constants/form";
import {formFieldNames, getCookie} from "@mb-react/svarog-ui";
import {useHandleAccountServices} from "../../../services/account/accountServiceHandler";
import { useEditAccountValidation } from "./editAccountValidation";


const EditAccountContainer = () => {

    const handleAccountServices = useHandleAccountServices();
    const [editAccountSuccessfullyPerformed, setEditAccountSuccessfullyPerformed] = useState(false);
    const [captchaData, setCaptchaData] = useState();
    const [captchaUrl, setCaptchaUrl] = useState(null);


    const submitEditAccount = (data, form) => {
        if (data != null && captchaData != null && data.captcha) {
            const editAccountParams = {
                phone: data.phone,
                email: data.email,
                accountId: getCookie("accountId"),
                captcha: data.captcha,
                captchaId: captchaData.id
            };
            handleAccountServices.handleEditAccount(editAccountParams, () => {
                setEditAccountSuccessfullyPerformed(true)
            }, () => {
                getCaptcha();
                form.change(addInitialValueToForm(forms.EDIT_ACCOUNT, formFieldNames.CAPTCHA, ''))
            })

        }
    };

    const getCaptcha = () => {
        handleAccountServices.handleGetCaptcha(prepareCaptcha)
    };

    const prepareCaptcha = (data) => {
        if (data) {
            setCaptchaData(data);
            setCaptchaUrl(prepareCaptchaImage(data));
        }
    }

    const prepareCaptchaImage = (captchaData) => {
        if (captchaData != null) {
            return "data:image/jpg;base64," + captchaData.base64;
        }
        return null;
    };

    return (
        <ContainerHOC>
            <MBForm
                formName={forms.EDIT_ACCOUNT}
                validate={useEditAccountValidation}
                submitFunction={(data, form) => submitEditAccount(data, form)}>
                <EditAccount getCaptcha={getCaptcha} captchaUrl={captchaUrl} editAccountSuccessfullyPerformed={editAccountSuccessfullyPerformed} />
            </MBForm>
        </ContainerHOC>
    );
}


export default EditAccountContainer;
