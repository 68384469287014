import {
    addToAppStore,
    getFromAppStore,
    getFormValues,
    DataTable,
    Column,
    Button,
    addMultipleInitialValuesToForm,
    nextStep, showToast, toastIDs,
} from '@mb-react/mb-react-core';
import {
    ProcessStateController, EUseCases, extractApplicationDataToFormValues, formFieldNames, ownerRoles,
    PagedRequest,
    ServiceRequestData,
    useCreateServiceWrapper
} from '@mb-react/svarog-ui';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {applicationStore} from '../../../../constants/store';
import {forms, navigationName} from "../../../../constants/form";
import {mapApplicationStatusForTable} from "../../../../util/dataMapperUtil";
import ApplicationReportSearchForm from "./ApplicationReportSearchForm";
import {useHandleCommonServices} from "../../../../services/commonServiceHandler";
import {getPath, i18nl} from "../../../../constants/i18n";
import {
    isOperationModeProtected, mapVehicleToFormValues
} from "../../../../util/businesslogicUtil";
import {
    prepareFetchedBloodGroupTypeForDDL
} from "../../../../util/dataPreparationUtil";
import {extractObjectBasedOnKeysList} from "../../../../util/dataExtractorUtil";
import {
    addressDataFormFieldList,
    driverLicenseFormFieldList, genericFormFieldNames,
    personalInformationFormFieldList, vehicleDataList, vehicleInformationList
} from "@mb-react/krg-getid-form-data-common";
import {extractValueFromGenericField, prepareVehiclesForTable} from "../../../../util/environment";
import {addVehicleToAppStore} from "../../../../util/util";
import {plateDataList} from "../../../../constants/wpFormFieldList";
const ApplicationsReportTable = (props) => {
    let formValues = useSelector((state) => getFormValues(state, forms.APPLICATION))
    const applicationsFromStore = useSelector(state => getFromAppStore(state, applicationStore.APPLICATIONS_DATA))
    const searchForm = useSelector(state => getFormValues(state, forms.ADDRESS_DATA))
    const serviceWrapper = useCreateServiceWrapper();
    const [count, setCount] = useState(0);
    let dispatch = useDispatch();
    const handleCommonServices = useHandleCommonServices()
    const [report, setReport] = useState();

    const fetchApplication = (pageNumber, pageSize, params) => {
        let pagedRequest = new PagedRequest();
        if(params){
            pagedRequest = {...params}
        }
        pagedRequest.pageNumber = pageNumber
        pagedRequest.pageSize = pageSize

        let request = new ServiceRequestData();
        request.service = ProcessStateController.searchApplications
        request.requestData = pagedRequest
        request.onSuccessLocal = (result) => {
            let applications = []
            if (result) {
                applications = result.pagedResults;
                if (JSON.stringify(applications)) {
                    const tableData = [];
                    applications.map(app => {
                        let appObject = {
                            applicationID: app.applicationMetaDataDTO.applicationID,
                            applicationType: app.applicationMetaDataDTO.applicationType,
                            useCase: app.applicationMetaDataDTO.useCase,
                            email: searchForm?.email ? searchForm.email : app?.applicantsDTO?.applicantDTOList[0]?.demographicDataDTO?.personDataDTO?.contactAddress?.email,
                            applicationStatus: mapApplicationStatusForTable(app.applicationMetaDataDTO.applicationState),
                            originId: app?.applicationMetaDataDTO.originId
                        }
                        tableData.push(appObject)
                    })

                    let filteredData = filterApplicationData(tableData)

                    dispatch(addToAppStore(applicationStore.APPLICATIONS_DATA, filteredData))
                    setCount(result.count);
                }
            }
        }
        serviceWrapper(request)
    }

    const printReportButton = (row) => {
        return (
            <div className="actionColumn" style={{display: "flex"}}>
                <Button icon="pi pi-print" className="p-button-rounded p-button-info"
                        onClick={() => printReport(row)}/>
            </div>
        )
    }

    const getApplicationButton = (row) => {
        return (
            <div className="actionColumn" style={{display: "flex"}}>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-secondary"
                        onClick={() => getApplication(row)}/>
            </div>
        )
    }

    function getApplication(row){
        serviceWrapper(new ServiceRequestData(
            ProcessStateController.getApplicationByApplicationIdInternal,
            row.applicationID,
            null,
            null,
            (result) => {
                if (result) {
                    onSuccessFetch(result)
                }
            },
            null
        ))
    }

    const onSuccessFetch = (result) => {
        if (result) {
            let application = extractApplicationDataToFormValues(result);

            if (application["bloodGroup"]) {
                application["bloodGroup"] = prepareFetchedBloodGroupTypeForDDL(application["bloodGroup"])
            }

            let applicationMetaDataList = ["workflow", "documentType", "applicationType", "useCase", "enrolmentStarted", "applicationID", "transactionId"];

            let applicationMetaData = extractObjectBasedOnKeysList(application, applicationMetaDataList)
            let personalInformation = extractObjectBasedOnKeysList(application, personalInformationFormFieldList);
            let addressData = extractObjectBasedOnKeysList(application, addressDataFormFieldList);
            let dlData = extractObjectBasedOnKeysList(application, driverLicenseFormFieldList)

            dispatch(addMultipleInitialValuesToForm(forms.APPLICATION, applicationMetaData))

            if (application.applicationType === "VR" && result.applicantsDTO && result.applicantsDTO.applicantDTOList) {

                let listOfApplicants = [];
                let listOfSellers = [];
                let listOfBuyers = [];
                let totalPercentageSellers = [];
                let totalPercentageBuyers = [];

                result.applicantsDTO.applicantDTOList.forEach((applicant, id) => {
                    let enrollmentStatus = extractValueFromGenericField(applicant.genericFieldsDTO.genericFieldDTOList, "enrollmentStatus");
                    let entityType = applicant.applicantType;
                    listOfApplicants.push({
                        sellerId: id,
                        buyerId: id,
                        enrollmentStatus: enrollmentStatus,
                        entityType: entityType,
                        applicantData: applicant
                    })
                })

                if (listOfApplicants.length > 0) {
                    listOfApplicants.forEach((applicant) => {
                        if (applicant.applicantData.ownershipDTO && applicant.applicantData.ownershipDTO.ownerRole === ownerRoles.BUYER) {
                            listOfBuyers.push(applicant)
                            totalPercentageBuyers.push({
                                id: applicant.buyerId,
                                lead: applicant.applicantData && applicant.applicantData.ownershipDTO ? applicant.applicantData.ownershipDTO.lead : null,
                                percentage: applicant.applicantData && applicant.applicantData.ownershipDTO ? applicant.applicantData.ownershipDTO.percent : null
                            })
                        } else {
                            listOfSellers.push(applicant)
                            totalPercentageSellers.push({
                                id: applicant.sellerId,
                                lead: applicant.applicantData && applicant.applicantData.ownershipDTO ? applicant.applicantData.ownershipDTO.lead : null,
                                percentage: applicant.applicantData && applicant.applicantData.ownershipDTO ? applicant.applicantData.ownershipDTO.percent : null
                            })
                        }
                    })
                }

                if (listOfSellers.length > 0) {
                    dispatch(addToAppStore('listOfSellers', listOfSellers))
                    dispatch(addToAppStore('totalPercentageSellers', totalPercentageSellers))
                }

                if (listOfBuyers.length > 0) {
                    dispatch(addToAppStore('listOfBuyers', listOfBuyers))
                    dispatch(addToAppStore('totalPercentageBuyers', totalPercentageBuyers))
                }

            } else {
                dispatch(addMultipleInitialValuesToForm(forms.PERSONAL_INFORMATION, personalInformation))
                dispatch(addMultipleInitialValuesToForm(forms.ADDRESS_DATA, addressData))
                dispatch(addMultipleInitialValuesToForm(forms.DRIVER_LICENSE, dlData))
            }

            dispatch(addToAppStore('fetchedApplication', application))

            if (application.applicationType === "VR" && application.transactionId) {
                handleCommonServices.handleGetVehicleByTransactionId({transactionId: application.transactionId}, getVehicleByTransactionIdOnSuccess);
            }

            dispatch(addToAppStore(applicationStore.UPDATE_VEHICLE, true))

        }
    };

    const getVehicleByTransactionIdOnSuccess = (response) => {

        mapVehicleToFormValues(dispatch, handleCommonServices, response)

        let a = [];
        a.push(response)
        addVehicleToAppStore(prepareVehiclesForTable(a))

        dispatch(nextStep(navigationName.MAIN_NAVIGATION))
    }

    function printReport(row){
        handleCommonServices.handlePrintApplicationReport(
            { applicationId: row.applicationID},
            onPrintReportFunction
        );
    }

    const onPrintReportFunction = (response) => {
        if (response?.data) {
            setReport(response.data)
        }
    }

    useEffect(() => {
        if (report) {
            let pdf_newTab = window.open("");
            pdf_newTab.document.write(
                "<html><head><title>PRINT APPLICATION REPORT</title></head><body><iframe title='PRINT APPLICATION REPORT'  width='100%' height='100%' src='data:application/pdf;base64, " + report + "'></iframe></body></html>"
            );
        }
    }, [report]);

    function filterApplicationData(tableData) {
        let filteredData = [];
        if (tableData) {
            tableData.map(data => {
                let listOfAppsWithId = listOfAppsWithSameId(tableData, data.applicationID)

                if (listOfAppsWithId && listOfAppsWithId.length > 1) {
                    if (!checkIfApplicationIsAlreadyAdded(filteredData, data.applicationID)) {
                        filteredData.push(data)
                    }
                } else {
                    filteredData.push(data)
                }
            })
        }

        if(props?.searchApplicationStep){
            let portalApps = filterPortalApplication(filteredData)
            if(portalApps?.length < filteredData?.length){
                dispatch(showToast(toastIDs.MESSAGE_TOAST, 'warn', {messageId: I18n.t("err.onlyPortalAppsCanBeFetched")}))
            }
            return portalApps
        }

        return filteredData;
    }

    const filterPortalApplication = (listOfApps) => {
        return listOfApps.filter(x => x.originId === "WP")
    }

    const listOfAppsWithSameId = (listOfApps, appId) => {
        return listOfApps.filter(x => x.applicationID === appId);
    }
    const checkIfApplicationIsAlreadyAdded =(listOfApps, appId) => {
        return listOfApps.some(x => x.applicationID === appId);
    }

    const columns = [
        {
            field: 'applicationID',
            translate: false
        },
        {
            field: 'applicationType',
            translate: true
        },
        {
            field: 'useCase',
            translate: true
        },
        {
            field: 'email',
            translate: false
        },
        {
            field: 'applicationStatus',
            translate: true
        },
        {
            field: 'actions',
            header: I18n.t(getPath(i18nl.PRINT_REPORTS)),
        },
    ];

    const paginationFunc = (pageNumber, pageSize) => {
        fetchApplication(pageNumber + 1, pageSize, searchForm)
    }

    const fieldTranslator = (rowData, col) => {
        if(col?.column?.props?.translate){
            return <Translate value={"label.table." + col.field + "."+ rowData[col.field]}/>
        }
        return rowData[col.field]
    }

    let dynamicColumns = [];

    if (columns.length > 0) {
        dynamicColumns = columns.map((col) => {
            if (col.field == "actions") {
                if(isOperationModeProtected() && formValues && formValues[formFieldNames.USE_CASE] && formValues[formFieldNames.USE_CASE] === EUseCases.MODIFY_VEHICLE_DATA){
                    return <Column key={col.field} field={col.field} header={col.header} body={getApplicationButton} style={{ width: '10%' }} />
                }else{
                    return <Column key={col.field} field={col.field} header={col.header} body={printReportButton} style={{ width: '10%' }} />
                }
            }
            else {
                return <Column {...col} key={col.field} field={col.field}
                               body={fieldTranslator}
                               header={<Translate value={"label." + col.field}/>}
                               style={{width: col.width ? col.width : "auto"}}/>;
            }

        });
    }

    return (
        <div>
            <ApplicationReportSearchForm searchApplicationFunc={fetchApplication}/>
            <DataTable value={applicationsFromStore}
                       actionTitle={I18n.t('label.viewApplications')}
                         count={applicationsFromStore != null ? applicationsFromStore.length : 0}
                         onViewIcon="pi pi-calendar-plus"
                         paginator={true}
                       emptyMessage={I18n.t('message.noResultsFound')}
                       rows={5}
                       rowsPerPageOptions={[5, 10, 25, 50]}
                         paginationFunc={paginationFunc}
            >
                {dynamicColumns}
            </DataTable>
        </div>
    )
}
export default ApplicationsReportTable