import axios from "axios";
import {getCookie} from "@mb-react/svarog-ui";

let GATEWAY_URL = configurationData && configurationData.gatewayURL ? configurationData.gatewayURL : "http://localhost:10300";
let ACCESS_TOKEN_NAME = configurationData && configurationData.accessTokenName ? configurationData.accessTokenName : "jwt";

const CHECK_CAPTCHA = "/account-service/captcha/check-captcha";


export class LocalAccountService {
    static checkChaptcha(params) {
        return axios.post(GATEWAY_URL + CHECK_CAPTCHA, params, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }
}