export const SERVICE = "service";
export const REQUEST_DATA = "requestData";
export const ON_SUCCESS_GLOBAL = "onSuccessGlobalFunc";
export const ON_ERROR_GLOBAL = "onErrorGlobalFunc";
export const ON_SUCCESS_LOCAL = "onSuccessLocal";
export const ON_ERROR_LOCAL = "onErrorLocal";

export const REQUEST_OBJECT = "requestObject";
export const PAGE = "page";
export const SIZE_PER_PAGE = "sizePerPage";
export const SORT_NAME = "sortName";
export const ASCENDING = "ascending";

export const APPOINTMENT_ID = "appointmentId";
export const ACCOUNT_ID = "accountId";
export const META = "meta";

export const ACCOUNT_ID_BIG = "accountID";

export const SERVICE_ID = "serviceID";
export const LOCATION_ID = "locationID";
export const PROVIDER_ID = "providerID";
export const START_DATE = "startDate";
export const START_TIME = "startTime";

export const ID = "id";
export const APPLICATION_ID = "applicationId";
export const APPLICATION_ID_BIG = "applicationID";
export const PHONE = "phone";
export const EMAIL = "email";
export const APPLICANT_NAME = "applicantName";
export const SERVICE_NAME = "serviceName";
export const PROVIDER_NAME = "providerName";
export const PREFERRED_NOTIFICATION_CHANNER = "preferredNotificationChannel";
export const DATE = "date";
export const ACCOUNT_MODE = "accountMode";



export const ARRAY_OF_CODES = "arrayOfCodes";
export class ServiceRequestData {
  constructor(service, requestData, onSuccessGlobalFunc, onErrorGlobalFunc, onSuccessLocalFunc, onErrorLocalFunc) {
    this[SERVICE] = service;
    this[REQUEST_DATA] = requestData;
    this[ON_SUCCESS_GLOBAL] = onSuccessGlobalFunc;
    this[ON_ERROR_GLOBAL] = onErrorGlobalFunc;
    this[ON_SUCCESS_LOCAL] = onSuccessLocalFunc;
    this[ON_ERROR_LOCAL] = onErrorLocalFunc;
  }
}

export class PagedRequest {
  constructor(requestObject, page, sizePerPage, sortName, ascending) {
    this[REQUEST_OBJECT] = requestObject;
    this[PAGE] = page;
    this[SIZE_PER_PAGE] = sizePerPage;
    this[SORT_NAME] = sortName;
    this[ASCENDING] = ascending;
  }
}
//appointmentId, accountId
export class GetAppointmentRequest {
  constructor(appointmentId, accountId) {
    this[APPOINTMENT_ID] = appointmentId;
    this[ACCOUNT_ID] = accountId;
  }
}
export class CancelAppointmentRequest {
  constructor(appointmentId) {
    this[APPOINTMENT_ID] = appointmentId;
  }
}
export class GetProvidersForLocationAppointmentRequest {
  constructor(serviceID, locationID) {
    this[SERVICE_ID] = serviceID;
    this[LOCATION_ID] = locationID;
  }
}
export class GetNonWorkingDaysAppointmentRequest {
  constructor(startDate, serviceID, providerID) {
    this[START_DATE] = startDate;
    this[SERVICE_ID] = serviceID;
    this[PROVIDER_ID] = providerID;
  }
}
export class GetTimeSlotsAppointmentRequest {
  constructor(startDate, serviceID, providerID) {
    this[START_DATE] = startDate;
    this[SERVICE_ID] = serviceID;
    this[PROVIDER_ID] = providerID;
  }
}
export class GetGenerateAppointmentReportRequest {
  constructor(accountID, appointmentId,) {
    this[ACCOUNT_ID] = accountID;
    this[APPOINTMENT_ID] = appointmentId;
  }
}

export class CreateAppointmentRequest {
  constructor(id, appointmentId, providerID, startTime, date, applicationID, serviceID, phone, email, applicantName, serviceName, providerName, preferredNotificationChannel, accountID, accountMode, locationID) {
    this[ID] = id;
    this[APPOINTMENT_ID] = appointmentId;
    this[PROVIDER_ID] = providerID;
    this[START_TIME] = startTime;
    this[DATE] = date;
    this[APPLICATION_ID_BIG] = applicationID;
    this[SERVICE_ID] = serviceID;
    this[PHONE] = phone;
    this[EMAIL] = email;
    this[APPLICANT_NAME] = applicantName;
    this[SERVICE_NAME] = serviceName;
    this[PROVIDER_NAME] = providerName;
    this[PREFERRED_NOTIFICATION_CHANNER] = preferredNotificationChannel;
    this[ACCOUNT_ID_BIG] = accountID;
    this[ACCOUNT_MODE] = accountMode;
    this[LOCATION_ID] = locationID;
  }
}
export class GetAllDictionaryRequest {
  constructor(arrayOfCodes) {
    this[ARRAY_OF_CODES] = arrayOfCodes;
  }
}
export class GetAccountDataRequest {
  constructor(accountId, meta) {
    this[ACCOUNT_ID] = accountId;
    this[META] = meta;
  }
}