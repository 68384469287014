import { useMemo } from 'react'
import {
    getFormValues
} from "@mb-react/mb-react-core";
import { formFieldNames } from "@mb-react/svarog-ui";
import { useSelector } from "react-redux";
import { formFieldsMeta, forms } from "../../../../../../constants/form";
import {
    isMandatory,
    isOperationModeProtected,
    isReadOnly,
    isUseCaseReservePlateNumber
} from "../../../../../../util/businesslogicUtil";
import { useForm } from 'react-final-form';
import {dictionaryReference} from "../../../../../../constants/dictionaries";
import {genericFormFieldNames} from "@mb-react/krg-getid-form-data-common";
import {leStateOptions, projectDictionaries} from "../../../../../../constants/general";
import {useHandleCommonServices} from "../../../../../../services/commonServiceHandler";

export const legalEntityDataSettings = () => {
    const form = useForm();
    const useCase = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.USE_CASE])
    const documentType = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.DOCUMENT_TYPE])
    const workflow = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldsMeta.WORKFLOW])
    const formValuesApp = useSelector((state) => getFormValues(state, forms.APPLICATION))

    let reservePlateNumberReadOnly = !isOperationModeProtected() && isUseCaseReservePlateNumber(formValuesApp);

    const handleCommonServices = useHandleCommonServices()

    let formValues = form?.getState()?.values ?? {}

    const onTypeChange = (event) => {
        form.change(formFieldNames.LE_SUBTYPE, '')
        handleCommonServices.handleGetSingleDictionary(projectDictionaries.LEGAL_ENTITY_SUBTYPE, event.target.value, dictionaryReference.LEGAL_ENTITY_SUBTYPE)
    };

    const onCountryChange = () => {
        form.change(formFieldNames.LE_ADDRESS_PROVINCE, '')
        form.change(formFieldNames.LE_ADDRESS_CITY, '')
        form.change(genericFormFieldNames.LE_ADDRESS_VILLAGE, '')
    };

    const onProvinceChange = (event) => {
        form.change(formFieldNames.LE_ADDRESS_CITY, '')
        form.change(genericFormFieldNames.LE_ADDRESS_VILLAGE, '')
        handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, event.target.value, dictionaryReference.CITIES_PLACE_OF_BIRTH)
    };

    const onCityChange = (event) => {
        form.change(genericFormFieldNames.LE_ADDRESS_VILLAGE, '')
        handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, event.target.value, dictionaryReference.VILLAGES_PLACE_OF_BIRTH)
    };

    const handleLeTpidSettings = useMemo(() => {
        let leProps = {
            [formFieldNames.LE_TPID]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || isReadOnly(formValues, formFieldNames.LE_TPID, useCase, documentType, workflow),
                },
                required: isMandatory(formValues, formFieldNames.LE_TPID, useCase, documentType, workflow),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return leProps
    }, [])

    const handleLeNameSettings = useMemo(() => {
        let leProps = {
            [formFieldNames.LE_NAME]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || isReadOnly(formValues, formFieldNames.LE_NAME, useCase, documentType, workflow),
                },
                required: isMandatory(formValues, formFieldNames.LE_NAME, useCase, documentType, workflow),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return leProps
    }, [])


    const handleLeTypeSettings = useMemo(() => {
        let leProps = {
            [formFieldNames.LE_TYPE]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || isReadOnly(formValues, formFieldNames.LE_TYPE, useCase, documentType, workflow),
                    reference: dictionaryReference.LEGAL_ENTITY_TYPE,
                    [formFieldNames.ON_CHANGE]: onTypeChange
                },
                required: isMandatory(formValues, formFieldNames.LE_TYPE, useCase, documentType, workflow),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return leProps
    }, [])


    //TODO WTF does this mean
    // if(formValues?.[formFieldNames.LE_TYPE] && !["IO", "embassy"].includes(formValues?.[formFieldNames.LE_TYPE])){
    //     legalEntityDataProps[formFieldNames.LE_SUBTYPE].fieldType = fieldType.INPUT_TEXT
    // }

    const handleLeSubypeSettings = useMemo(() => {
        let leProps = {
            [formFieldNames.LE_SUBTYPE]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || isReadOnly(formValues, formFieldNames.LE_SUBTYPE, useCase, documentType, workflow),
                    reference: dictionaryReference.LEGAL_ENTITY_SUBTYPE,
                },
                required: isMandatory(formValues, formFieldNames.LE_SUBTYPE, useCase, documentType, workflow),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return leProps
    }, [formValues[formFieldNames.LE_TYPE]])

    const handleLeAddressCountrySettings = useMemo(() => {
        let leProps = {
            [formFieldNames.LE_ADDRESS_COUNTRY]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || isReadOnly(formValues, formFieldNames.LE_ADDRESS_COUNTRY, useCase, documentType, workflow),
                    reference: projectDictionaries.COUNTRY_CODES,
                    [formFieldNames.ON_CHANGE]: onCountryChange
                },
                required: isMandatory(formValues, formFieldNames.LE_ADDRESS_COUNTRY, useCase, documentType, workflow),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return leProps
    }, [])

    const handleLeCountryCodeSettings = useMemo(() => {
        let leProps = {
            [formFieldNames.LE_COUNTRY_CODE]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || isReadOnly(formValues, formFieldNames.LE_COUNTRY_CODE, useCase, documentType, workflow),
                },
                exclude: true,
                required: isMandatory(formValues, formFieldNames.LE_COUNTRY_CODE, useCase, documentType, workflow),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }
        return leProps
    }, [])

    const handleLeAddressProvinceSettings = useMemo(() => {
        let leProps = {
            [formFieldNames.LE_ADDRESS_PROVINCE]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || isReadOnly(formValues, formFieldNames.LE_ADDRESS_PROVINCE, useCase, documentType, workflow),
                    reference: projectDictionaries.PROVINCES,
                    [formFieldNames.ON_CHANGE]: onProvinceChange
                },
                required: isMandatory(formValues, formFieldNames.LE_ADDRESS_PROVINCE, useCase, documentType, workflow),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return leProps
    }, [formValues[formFieldNames.LE_ADDRESS_COUNTRY]])

    const handleLeAddressCitySettings = useMemo(() => {
        let leProps = {
            [formFieldNames.LE_ADDRESS_CITY]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || isReadOnly(formValues, formFieldNames.LE_ADDRESS_CITY, useCase, documentType, workflow),
                    reference: dictionaryReference.CITIES_PLACE_OF_BIRTH,
                    [formFieldNames.ON_CHANGE]: onCityChange
                },
                required: isMandatory(formValues, formFieldNames.LE_ADDRESS_CITY, useCase, documentType, workflow),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return leProps
    }, [formValues[formFieldNames.LE_ADDRESS_COUNTRY], formValues[formFieldNames.LE_ADDRESS_PROVINCE]])

    const handleLeAddressRegionSettings = useMemo(() => {
        let leProps = {
            [formFieldNames.LE_ADDRESS_REGION]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || isReadOnly(formValues, formFieldNames.LE_ADDRESS_REGION, useCase, documentType, workflow),
                },
                required: isMandatory(formValues, formFieldNames.LE_ADDRESS_REGION, useCase, documentType, workflow),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return leProps
    }, [])

    const handleLeAddressVillageSettings = useMemo(() => {
        let leProps = {
            [genericFormFieldNames.LE_ADDRESS_VILLAGE]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || isReadOnly(formValues, genericFormFieldNames.LE_ADDRESS_VILLAGE, useCase, documentType, workflow),
                    reference: dictionaryReference.VILLAGES_PLACE_OF_BIRTH,
                },
                required: isMandatory(formValues, genericFormFieldNames.LE_ADDRESS_VILLAGE, useCase, documentType, workflow),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return leProps
    }, [formValues[formFieldNames.LE_ADDRESS_COUNTRY], formValues[formFieldNames.LE_ADDRESS_PROVINCE], formValues[formFieldNames.LE_ADDRESS_CITY]])

    const handleLeAddressStreetSettings = useMemo(() => {
        let leProps = {
            [formFieldNames.LE_ADDRESS_STREET]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || isReadOnly(formValues, formFieldNames.LE_ADDRESS_STREET, useCase, documentType, workflow),
                },
                required: isMandatory(formValues, formFieldNames.LE_ADDRESS_STREET, useCase, documentType, workflow),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return leProps
    }, [])

    const handleLeAddressHouseNumberSettings = useMemo(() => {
        let leProps = {
            [formFieldNames.LE_ADDRESS_HOUSE_NUMBER]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || isReadOnly(formValues, formFieldNames.LE_ADDRESS_HOUSE_NUMBER, useCase, documentType, workflow),
                },
                required: isMandatory(formValues, formFieldNames.LE_ADDRESS_HOUSE_NUMBER, useCase, documentType, workflow),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return leProps
    }, [])

    const handleLeAddressZipCodeSettings = useMemo(() => {
        let leProps = {
            [formFieldNames.LE_ADDRESS_ZIP_CODE]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || isReadOnly(formValues, formFieldNames.LE_ADDRESS_ZIP_CODE, useCase, documentType, workflow),
                },
                required: isMandatory(formValues, formFieldNames.LE_ADDRESS_ZIP_CODE, useCase, documentType, workflow),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return leProps
    }, [])

    const handleLeQuarterNameSettings = useMemo(() => {
        let leProps = {
            [formFieldNames.LE_QUARTER_NAME]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || isReadOnly(formValues, formFieldNames.LE_QUARTER_NAME, useCase, documentType, workflow),
                },
                required: isMandatory(formValues, formFieldNames.LE_QUARTER_NAME, useCase, documentType, workflow),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return leProps
    }, [])

    const handleLeSectorNumberSettings = useMemo(() => {
        let leProps = {
            [formFieldNames.LE_SECTOR_NUMBER]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || isReadOnly(formValues, formFieldNames.LE_SECTOR_NUMBER, useCase, documentType, workflow),
                },
                required: isMandatory(formValues, formFieldNames.LE_SECTOR_NUMBER, useCase, documentType, workflow),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return leProps
    }, [])

    const handleLeStateNumberSettings = useMemo(() => {
        let leProps = {
            [formFieldNames.LE_STATE]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || isReadOnly(formValues, formFieldNames.LE_STATE, useCase, documentType, workflow),
                    selectOptions: leStateOptions
                },
                required: isMandatory(formValues, formFieldNames.LE_STATE, useCase, documentType, workflow),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return leProps
    }, [])

    const handleTpidParentSettings = useMemo(() => {
        let leProps = {
            [formFieldNames.TPID_PARENT]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || isReadOnly(formValues, formFieldNames.TPID_PARENT, useCase, documentType, workflow),
                },
                required: isMandatory(formValues, formFieldNames.TPID_PARENT, useCase, documentType, workflow),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return leProps
    }, [])

    return { ...handleLeTpidSettings, ...handleLeNameSettings, ...handleLeTypeSettings, ...handleLeSubypeSettings, ...handleLeAddressCountrySettings,
        ...handleLeCountryCodeSettings, ...handleLeAddressProvinceSettings, ...handleLeAddressCitySettings, ...handleLeAddressRegionSettings,
        ...handleLeAddressVillageSettings, ...handleLeAddressStreetSettings, ...handleLeAddressHouseNumberSettings, ...handleLeAddressZipCodeSettings,
        ...handleLeQuarterNameSettings, ...handleLeSectorNumberSettings, ...handleLeStateNumberSettings, ...handleTpidParentSettings}
};