import React from "react";



export const GearImageComponent = () => {

    return (
        <div className="docImageWrap">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 842.14 1018.61" className="docImageSVGWrap">
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path
                            d="M10.48,384.49c-.07-73.73.61-147.81-.48-221.67,0-19.27,6.15-28.16,24.53-34.17Q220,71.59,404.82,12.69a51.51,51.51,0,0,1,32.87,0c122.18,39,244.36,78,367.08,115.35,22.07,6.83,27.34,17.69,27.34,38.81-.82,131.88-1.71,263.76,0,395.64,1.84,145.28-69.16,250.37-180,333.6a712.2,712.2,0,0,1-211.83,107.76c-9.77,3.14-18.79,6.83-29.59,3.35C267.75,958.2,139.83,888,59.13,753.75a325,325,0,0,1-48.52-168C10,518.62,10.48,451.52,10.48,384.49Z"
                            fill="none" stroke="rgb(85, 73, 148)" strokeMiterlimit="10" strokeWidth="20" />
                        <path className="fillPathWthite"
                            d="M369.59,647.64l77.09-132.41H600.43c25,43.13,50.31,86.87,76.65,132.37L600.6,780.87H446.41Zm288.32-.16-66.69-114h-136L389.06,648.35l66.26,114H592Z"
                            fill="rgb(85, 73, 148)" />
                        <path className="fillPathWthite"
                            d="M421.53,497.34l-76.27,130H189.32L113.6,497.82l75.68-132.38H344.85ZM197.72,383.51c-21.59,37.56-42.94,74.71-65.18,113.43,22.23,38.68,44.18,76.88,66.49,115.68H335.5c22.51-39.25,44.43-77.45,66.77-116.39L335.76,383.51Z"
                            fill="rgb(85, 73, 148)" />
                        <path className="fillPathWthite"
                            d="M445.2,476.53l-74.72-130.6,75.3-131H600.31l75.91,130.38c-25,43.92-49.55,86.95-74.8,131.24ZM658.08,346,591.23,229.3H454.9l-66.54,117,67.15,116.32H591.29Z"
                            fill="rgb(85, 73, 148)" />
                        <path className="colorTwoLinesWthite" d="M425.26,52.56V174.75l-7.6.22V52.7Z" fill="rgb(85, 73, 148)" />
                        <path className="colorTwoLinesWthite" d="M424.9,843.87v122h-7.66V844Z" fill="rgb(85, 73, 148)" />
                        <path className="fillPathWthite"
                            d="M565.67,625.39c-1.89-20.19-18.42-36.59-38.46-38.15-1.28-.1-2.56-.15-3.81-.15a42.31,42.31,0,0,0-32.74,15.15c-7.05,8.15-10.68,20-9.7,31.57s5.76,18.52,10.31,23.77a108.31,108.31,0,0,0,8.33,8.64l.47.42c3.9,3.53,7.29,6.6,7.21,13.71a5.87,5.87,0,0,0-4.37,5.71,6.35,6.35,0,0,0,2.39,5.5,6.73,6.73,0,0,0-2.41,5.51,5.58,5.58,0,0,0,2.47,4.74,6.8,6.8,0,0,0-2.39,6.41c.54,3.1,3.08,5.11,6.49,5.11h.35c.94,6.16,6.29,6.66,13.66,6.66,4.55,0,9.19-.21,11.55-2.69a6.06,6.06,0,0,0,1.59-3.89,6.4,6.4,0,0,0,7.08-5.69,6.17,6.17,0,0,0-2.39-5.78,6.72,6.72,0,0,0,2.44-5.53,5.52,5.52,0,0,0-2.69-4.87,6.28,6.28,0,0,0,2.91-5.2,5.81,5.81,0,0,0-4.6-5.69c.07-8.46,5.38-13.08,10.52-17.56a56.16,56.16,0,0,0,5.47-5.19A42.68,42.68,0,0,0,565.67,625.39Zm-11.21,20.16c-2.45,4.89-6.42,8.57-10.25,12.13-6.18,5.73-12.55,11.64-12.51,22.54h-4.56V664.93c0-.89.07-1.84.13-2.77.2-2.74.39-5.33-1-6.84a3.76,3.76,0,0,0-2.88-1.1h-.24a3.8,3.8,0,0,0-2.79,1.17c-1.36,1.51-1.18,4.06-1,6.76.06.93.13,1.88.13,2.78v15.29h-4.57c.09-10.86-5.31-15.7-11-20.81-1.71-1.53-3.48-3.11-5.15-4.9-6.32-6.77-9.64-14.19-10.15-22.67A33.16,33.16,0,0,1,492.48,614c4.7-9.24,15.22-19.12,30.64-19.12.61,0,1.22,0,1.83,0a34.81,34.81,0,0,1,30.1,20.56C559,624.14,558.7,637.08,554.46,645.55Z"
                            fill="rgb(85, 73, 148)" />
                        <path className="fillPathWthite"
                            d="M528,625.05c-1,0-2.08,0-3.1.08s-1.88.07-2.7.07-1.56,0-2.31,0-1.44,0-2.11,0c-1.81,0-3.65.12-4.9,1a.92.92,0,0,0-.39.84,5.5,5.5,0,0,1-.24.58,2.1,2.1,0,0,0-.27.83,3,3,0,0,0,.84,2.21c1.18,1.18,3.34,1.34,5.29,1.34.78,0,1.59,0,2.38,0s1.51-.06,2.21-.06,1.75,0,2.63.06,1.83,0,2.7,0c2.85,0,5.59-.26,6.34-2.2a3,3,0,0,0-.15-2.92C533.14,625.26,530.46,625.05,528,625.05Z"
                            fill="rgb(85, 73, 148)" />
                        <path className="fillPathWthite"
                            d="M532.34,634.54a12.76,12.76,0,0,0-4.42-.49c-1.28,0-2.69.05-3.93.09-1,0-2,.08-2.81.08l-1.31,0h-1.46c-2,0-6.29,0-6.45,3.16a2.9,2.9,0,0,0,.77,2.2c1.21,1.26,3.54,1.43,5.67,1.43,1,0,2,0,2.91-.08s1.81-.07,2.63-.07,1.57,0,2.43.07,1.76.08,2.61.08h0c2.21,0,4.56-.23,5.34-1.94a3,3,0,0,0,.08-2.43A3.86,3.86,0,0,0,532.34,634.54Z"
                            fill="rgb(85, 73, 148)" />
                        <path className="fillPathWthite"
                            d="M532.05,643.35a14.21,14.21,0,0,0-4.46-.44c-1.21,0-2.51,0-3.66.07-.94,0-1.83.06-2.5.06-.48,0-1,0-1.6,0s-1.36,0-2.05,0c-1.88,0-4.17.18-5.22,1.61a3.43,3.43,0,0,0-.3,3.19c.62,2,3.51,2.27,6.25,2.27.91,0,1.82,0,2.71-.06s1.55-.05,2.22-.05c.83,0,1.72,0,2.59.07s1.79.07,2.64.07h0c2.45,0,5.05-.28,5.69-2.29A3.44,3.44,0,0,0,532.05,643.35Z"
                            fill="rgb(85, 73, 148)" />
                        <path className="fillPathWthite"
                            d="M227.37,445.77h80.06a13.13,13.13,0,0,1,13.13,13.13v74.93A13.13,13.13,0,0,1,307.43,547H227.37a13.14,13.14,0,0,1-13.14-13.14V458.91A13.13,13.13,0,0,1,227.37,445.77Z"
                            fill="rgb(85, 73, 148)" />
                        <path className="fillPathWthite"
                            d="M299.94,519.3a8,8,0,0,1-8,8h-49a8,8,0,0,1-8-8V473.44a8,8,0,0,1,8-8h49a8,8,0,0,1,8,8Z"
                            fill="#879093" opacity="0.5" />
                        <line className="fillPathWthite" x1="230" y1="428.59" x2="230" y2="445.77" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="242.47" y1="428.59" x2="242.47" y2="445.77" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="254.93" y1="428.59" x2="254.93" y2="445.77" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="267.4" y1="428.59" x2="267.4" y2="445.77" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="279.87" y1="428.59" x2="279.87" y2="445.77" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="292.34" y1="428.59" x2="292.34" y2="445.77" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="304.8" y1="428.59" x2="304.8" y2="445.77" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="337.75" y1="458.97" x2="320.57" y2="458.97" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="337.75" y1="471.43" x2="320.57" y2="471.43" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="337.75" y1="483.91" x2="320.57" y2="483.91" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="337.75" y1="496.37" x2="320.57" y2="496.37" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="337.75" y1="508.84" x2="320.57" y2="508.84" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="337.75" y1="521.3" x2="320.57" y2="521.3" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="337.75" y1="533.77" x2="320.57" y2="533.77" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="304.8" y1="564.15" x2="304.8" y2="546.97" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="292.34" y1="564.15" x2="292.34" y2="546.97" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="279.87" y1="564.15" x2="279.87" y2="546.97" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="267.4" y1="564.15" x2="267.4" y2="546.97" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="254.93" y1="564.15" x2="254.93" y2="546.97" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="242.47" y1="564.15" x2="242.47" y2="546.97" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="230" y1="564.15" x2="230" y2="546.97" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="197.06" y1="533.77" x2="214.24" y2="533.77" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="197.06" y1="521.3" x2="214.24" y2="521.3" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="197.06" y1="508.84" x2="214.24" y2="508.84" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="197.06" y1="496.37" x2="214.24" y2="496.37" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="197.06" y1="483.91" x2="214.24" y2="483.91" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="197.06" y1="471.43" x2="214.24" y2="471.43" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <line className="fillPathWthite" x1="197.06" y1="458.97" x2="214.24" y2="458.97" fill="#5ecbf4" stroke="#879093"
                            strokeMiterlimit="10" strokeWidth="2" opacity="0.5" />
                        <path d="M231.29,460.24a3.89,3.89,0,1,1-3.89-3.89A3.89,3.89,0,0,1,231.29,460.24Z"
                            fill="none" stroke="#879093" strokeMiterlimit="10" strokeWidth="6.38"
                            opacity="0.5" />
                        <path d="M231.29,532.5a3.89,3.89,0,1,1-3.89-3.89A3.89,3.89,0,0,1,231.29,532.5Z"
                            fill="none" stroke="#879093" strokeMiterlimit="10" strokeWidth="6.38"
                            opacity="0.5" />
                        <g opacity="0.5">
                            <path d="M312.58,460.24a3.89,3.89,0,1,1-3.89-3.89A3.89,3.89,0,0,1,312.58,460.24Z"
                                fill="none" stroke="#879093" strokeMiterlimit="10" strokeWidth="6.38" />
                            <path d="M312.58,532.5a3.89,3.89,0,1,1-3.89-3.89A3.89,3.89,0,0,1,312.58,532.5Z"
                                fill="none" stroke="#879093" strokeMiterlimit="10" strokeWidth="6.38" />
                        </g>
                        <path className="fillPathWthite"
                            d="M584.6,347.4c0-1.86-.09-3.7-.26-5.52l12.31-3.23-5.52-21.07-12.54,3.28a61.7,61.7,0,0,0-10.18-15l8.05-10.43-17.24-13.31-8.15,10.56a61.3,61.3,0,0,0-17-5.72V273.52H512.28V287a60.72,60.72,0,0,0-16.21,5.32l-8-10.68-17.44,13.06,7.91,10.56a61.41,61.41,0,0,0-8,10.44l-12.24-4.51-7.53,20.45,12,4.42a61,61,0,0,0-1.08,11.39c0,1.34.06,2.68.14,4L449.7,354.6l5.52,21.07,11.91-3.11a61.48,61.48,0,0,0,10.16,15.67l-7.41,9.6,17.25,13.32,7.3-9.45a61.07,61.07,0,0,0,17.85,6.14v11.88h21.79V407.84a60.74,60.74,0,0,0,17-5.74l7.16,9.57,17.44-13.07-7.26-9.69a61.21,61.21,0,0,0,8-11L588,382.1l7.52-20.44-11.74-4.32A61.44,61.44,0,0,0,584.6,347.4Zm-61.43,41.05A41.83,41.83,0,1,1,565,346.62,41.83,41.83,0,0,1,523.17,388.45Z"
                            fill="rgb(85, 73, 148)" stroke="rgb(85, 73, 148)" strokeMiterlimit="10"
                            strokeWidth="6.38" />
                    </g>
                </g>
            </svg>
        </div>
    )
}

