import 'polyfill-object.fromentries';

export function extractAccountDTOtoPersonalInformationForm (AccountDTO) {

    let formObject = {};

    for (let key in AccountDTO) {
        switch (key) {
            case "entityName":
                formObject["givenName"] = AccountDTO[key];
                break;
            case "entitySurname":
                formObject["surname"]= AccountDTO[key];
                break;
            case "entityDateOfBirth":
                formObject["dateOfBirth"]= AccountDTO[key];
                break;
            case "entityGender":
                formObject["gender"]= AccountDTO[key];
            default:
                formObject[key] = AccountDTO[key];
        }
    }
    return formObject;
}

export const extractObjectBasedOnKeysList = (obj, keys) => Object.fromEntries(
    keys
        .filter(key => key in obj)
        .map(key => [key, obj[key]])
);