import React from "react";

import ContainerHOC from "../../../ContainerHOC";
import { Button } from "@mb-react/mb-react-core";
import {
    REDIRECT_TO_ADMIN_ACCOUNT_OVERVIEW,
    REDIRECT_TO_ADMIN_CREATE_ACCOUNT
} from "../../../../constants/environment";
import { isLoggedIn } from "../../../../util/businesslogicUtil";
import { GearImageComponent } from "../../../iconComponents/gearImage";
import { userImageComponent } from "../../../iconComponents/userImage";

const AdminAccountHome = () => {
    return (
        <ContainerHOC fullPage>
            {
                isLoggedIn() &&
                <div className="grid home-page nav adminWrap">
                    <div className="col-12 btnImgTextWrap">
                        <Button label="Create account" icon={userImageComponent}
                            className="p-button-rounded p-button-text btnCompImg" aria-label="Submit"
                            onClick={() => window.location = REDIRECT_TO_ADMIN_CREATE_ACCOUNT} />
                        <Button label="Print account" icon={GearImageComponent}
                            className="p-button-rounded p-button-text btnCompImg" aria-label="Submit"
                            onClick={() => window.location = REDIRECT_TO_ADMIN_ACCOUNT_OVERVIEW} />
                    </div>
                </div>
            }
        </ContainerHOC>
    );
}
export default AdminAccountHome
