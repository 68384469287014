import {fieldType} from '@mb-react/mb-bootstrap-core';
import React, {useEffect} from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {useDispatch} from 'react-redux';
import {AccountService, formFieldNames, ServiceRequestData, useCreateServiceWrapper} from "@mb-react/svarog-ui";
import {Button, MBForm, FormElement, addToAppStore} from "@mb-react/mb-react-core";
import {accountStore} from "../../../../constants/store";
import moment from 'moment';

const PersonTableSearch = () => {
    let dispatch = useDispatch();
    const callService = useCreateServiceWrapper();

    useEffect(() => {
        search({})
    }, [])

    const inputState = () => {
        return [
            {value: 'CREATED', label: I18n.t("label.created")},
            {value: 'NOTIFIED', label: I18n.t("label.notify")},
            {value: 'CONFIRMED', label: I18n.t("label.confirmed")},
            {value: 'ACTIVE', label: I18n.t("label.active")},
            {value: 'INACTIVE', label: I18n.t("label.inactive")},
            {value: 'EXPIRED', label: I18n.t("label.expired")},
            {value: 'DELETED', label: I18n.t("label.deleted")},
            {value: 'LOCKED', label: I18n.t("label.locked")}
        ];
    }

    const search = (formValues) => {
        const selectedState = formValues[formFieldNames.STATE];
        let dateOfBirth = null;
        if (formValues[formFieldNames.DATE_OF_BIRTH]) {
            dateOfBirth = moment(formValues[formFieldNames.DATE_OF_BIRTH]).format("YYYY-MM-DD");
        }
        callService(new ServiceRequestData(
            AccountService.searchAccounts,
            { accountState: "ACTIVE", ...formValues, entityDateOfBirth: dateOfBirth },
            null,
            null,
            (result) => {
                let accounts = [];
                if (result) {
                    accounts = result.pagedResults;
                    const tableData = [];
                    accounts.forEach((acc) => {
                        if (!selectedState || acc.accountState === selectedState) {
                            let tpid = getTpidFromEntity(acc.entityIdentificationNumbers);

                            let accObject = {
                                accountId: acc.accountId,
                                username: acc.username,
                                personId: tpid,
                                givenName: acc.entityName,
                                surname: acc.entitySurname,
                                email: acc.email,
                                dateOfBirth: acc.entityDateOfBirth,
                                state: acc.accountState,
                            };
                            tableData.push(accObject);
                        }
                    });
                    dispatch(addToAppStore(accountStore.ACCOUNTS_DATA, tableData));
                }
            },
            null
        ));
    };

    const getTpidFromEntity = (identificationNumbers) => {
        let personalNumber;

        if(identificationNumbers && identificationNumbers.length > 0){
            personalNumber = identificationNumbers.find(idNum => {return idNum.personalNumberType === "TPID"});
        }

        if(personalNumber){
            return personalNumber.personalNumber;
        }
        return null;
    }

    let initialValues = {
        [formFieldNames.USERNAME]: '',
        [formFieldNames.ENTITY_PERSONAL_NUMBER]: '',
        [formFieldNames.ENTITY_FIRST_NAME]: '',
        [formFieldNames.ENTITY_SURNAME]: '',
        [formFieldNames.EMAIL]: '',
        [formFieldNames.DATE_OF_BIRTH]: '',
        [formFieldNames.STATE]: ''
    }

    return (
        <div>
            <MBForm formName="SearchAccountsForm" validationMode="Soft" submitFunction={(data) => search(data)} initialValues={initialValues}>
                <div className='grid appSearchForm'>
                    <div className='col-12 xl:col-4 '>
                        <FormElement label={"label.username"} fieldType={fieldType.INPUT_TEXT}
                                     fieldProps={{name: formFieldNames.USERNAME}}/>
                    </div>
                    <div className='col-12 xl:col-4 '>
                        <FormElement label={"label.nationalId"} fieldType={fieldType.INPUT_TEXT}
                                     fieldProps={{name: formFieldNames.ENTITY_PERSONAL_NUMBER}}/>
                    </div>
                    <div className='col-12 xl:col-4 '>
                        <FormElement label={"label.name"} fieldType={fieldType.INPUT_TEXT}
                                     fieldProps={{name: formFieldNames.ENTITY_FIRST_NAME}}/>
                    </div>
                    <div className='col-12 xl:col-4 '>
                        <FormElement label={"label.surname"} fieldType={fieldType.INPUT_TEXT}
                                     fieldProps={{name: formFieldNames.ENTITY_SURNAME}}/>
                    </div>
                    <div className='col-12 xl:col-4 '>
                        <FormElement label={"label.email"} fieldType={fieldType.INPUT_TEXT}
                                     fieldProps={{name: formFieldNames.EMAIL}}/>
                    </div>
                    <div className='col-12 xl:col-4 '>
                        <FormElement label={"label.dateOfBirth"} fieldType={fieldType.INPUT_DATE_PICKER}
                                     fieldProps={{
                                        maxDate: new Date(),
                                        name: formFieldNames.DATE_OF_BIRTH
                                        }}/>
                    </div>
                    <div className='col-12 xl:col-4 '>
                        <FormElement label={"label.state"} fieldType={fieldType.INPUT_DROPDOWN}
                                     fieldProps={{
                                         name: formFieldNames.STATE,
                                         selectOptions: inputState(),
                                         filter: true, showClear: true,
                                     }}/>
                    </div>
                    <div className='col-12 xl:col-4'>
                        <Button type="submit" label={<Translate value="action.search" />} className="p-button"
                                icon="pi pi-search"/>
                    </div>
                </div>
            </MBForm>
        </div>
    )
}
export default PersonTableSearch
