import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { EDocumentType, EUseCases, formFieldNames, getCookie, setCookie} from '@mb-react/svarog-ui';
import CreateApplicationWorkflow from './application/CreateApplicationWorkflow';
import {applicationStore, appStoreFields} from '../../constants/store';
import ContainerHOC from '../ContainerHOC';
import { addToAppStore, getFromAppStore } from '@mb-react/mb-react-core';
import { change } from '@mb-react/mb-react-core';
import { forms } from '../../constants/form';
import moment from 'moment';
import CancelApplicationWorkflow from './application/CancelApplicationWorkflow';
import {SELECTED_ACCOUNT_EMAIL, SELECTED_ACCOUNT_ID} from "../../constants/general";
import {delete_cookie} from "../../util/util";
import { isIdentityActivated, isLoggedIn } from '../../util/businesslogicUtil';

const NewDLCreateWrapper = (props) => {

  useEffect(() => {
    const { dispatch, editDraft } = props;

    if (isIdentityActivated() && isLoggedIn()) {
      dispatch(addToAppStore('preSummaryAdded', true));
    }

    if (!editDraft) {

      if(getCookie(SELECTED_ACCOUNT_EMAIL)){
        dispatch(change(forms.APPLICATION, applicationStore.REGISTERED_ACCOUNT_EMAIL, atob(getCookie(SELECTED_ACCOUNT_EMAIL))));
        delete_cookie(SELECTED_ACCOUNT_EMAIL)
      }
      if(getCookie(SELECTED_ACCOUNT_ID)){
        dispatch(change(forms.APPLICATION, formFieldNames.ACCOUNT_ID, getCookie(SELECTED_ACCOUNT_ID)));
        delete_cookie(SELECTED_ACCOUNT_ID)
      }
      dispatch(change(forms.APPLICATION, formFieldNames.DOCUMENT_TYPE, EDocumentType.DL));
      //TODO set proper application type based UC
      dispatch(change(forms.APPLICATION, "applicationType", "DL"));

      if (getCookie([formFieldNames.USE_CASE])) {
        dispatch(change(forms.APPLICATION, formFieldNames.USE_CASE, getCookie([formFieldNames.USE_CASE])));
      } else {
        dispatch(change(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.NEW));
      }
      
      dispatch(change(forms.APPLICATION, formFieldNames.ENROLLMENT_STARTED, moment(Date.now()).format("yyyy-MM-DDTHH:mm:ss.SSSSSSS")));
    }

    //new Cookies().remove([formFieldNames.USE_CASE]);
  }, [])

  const cancelUseCase = useSelector((state) => getFromAppStore(state, 'cancelUseCaseActive'))

  // props.dispatch(change(forms.APPLICATION, "useApplyWithoutAccount", true))

  return (
    <ContainerHOC fullPage>
      {cancelUseCase ? <CancelApplicationWorkflow /> : <CreateApplicationWorkflow />}
    </ContainerHOC>
  )
}
const mapStateToProps = state => {
  const editDraft = getFromAppStore(state, appStoreFields.editDraft);
  return {
    editDraft: editDraft
  };
};
export default connect(mapStateToProps)(NewDLCreateWrapper);
NewDLCreateWrapper.propTypes = {
  dispatch: PropTypes.any
};
