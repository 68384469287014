import {useSelector} from "react-redux";
import {getFormValues} from "@mb-react/mb-react-core";
import {formFieldsMeta, forms} from "../../../../../constants/form";
import {useMemo} from "react";
import {formFieldNames} from "@mb-react/svarog-ui";
import {isMandatory, isReadOnly} from "../../../../../util/businesslogicUtil";
import {useForm} from "react-final-form";
import {genericFormFieldNames} from "@mb-react/krg-getid-form-data-common";
import {projectDictionaries} from "../../../../../constants/dictionaries";

export const useProxyDataSettings = (props) => {
    const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION))
    const proxyDataFormValues = useSelector((state) => getFormValues(state, props.formName))
    const isProxyChecked = useSelector((state) => getFormValues(state, props.formName)[formFieldNames.PROXY])

    let excludeField = true;

    if(isProxyChecked){
        excludeField = false;
    }

    let years16Before = new Date();
    years16Before.setFullYear(years16Before.getFullYear() - 16);
    years16Before.setDate(years16Before.getDate() + 2)
    years16Before.setMonth(years16Before.getMonth() + 2)

    let years125Before = new Date();
    years125Before.setFullYear(years125Before.getFullYear() - 125);

    let form = useForm();

    const onProxyChange = () => {
        form.change(formFieldNames.PROXY_PERSONAL_NUMBER, '');
        form.change(formFieldNames.PROXY_NAME, '');
        form.change(formFieldNames.PROXY_SURNAME, '');
        form.change(formFieldNames.PROXY_GENDER, '');
        form.change(formFieldNames.PROXY_DATE_OF_BIRTH, '');
        form.change(genericFormFieldNames.PROXY_FATHER_NAME, '');
        form.change(genericFormFieldNames.PROXY_GRANDFATHER_NAME, '');
        form.change(genericFormFieldNames.PROXY_LETTER_NUMBER, '');
        form.change(genericFormFieldNames.PROXY_LETTER_DATE, '');
        form.change(genericFormFieldNames.PROXY_LETTER_AUTHORITY, '');
    };

    const proxyDataSettings = useMemo(() => {
        let proxyData = {
            [formFieldNames.PROXY]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(proxyDataFormValues, formFieldNames.PROXY, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                    onChange: onProxyChange
                },
                required: isMandatory(proxyDataFormValues, formFieldNames.PROXY, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return proxyData
    }, [])

    const proxyPersonalNumberSettings = useMemo(() => {
        let proxyData = {
            [formFieldNames.PROXY_PERSONAL_NUMBER]: {
                [formFieldNames.FIELD_PROPS]: {
                    keyfilter: "alphanum",
                    maxLength: 13,
                    readOnly: isReadOnly(proxyDataFormValues, formFieldNames.PROXY_PERSONAL_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                exclude: excludeField,
                required: isProxyChecked, //isMandatory(proxyDataFormValues, formFieldNames.PROXY_PERSONAL_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return proxyData
    }, [isProxyChecked])

    const proxyNameSettings = useMemo(() => {
        let proxyData = {
            [formFieldNames.PROXY_NAME]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(proxyDataFormValues, formFieldNames.PROXY_NAME, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                exclude: excludeField,
                required: isProxyChecked, //isMandatory(proxyDataFormValues, formFieldNames.PROXY_NAME, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return proxyData
    }, [isProxyChecked])

    const proxySurnameSettings = useMemo(() => {
        let proxyData = {
            [formFieldNames.PROXY_SURNAME]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(proxyDataFormValues, formFieldNames.PROXY_SURNAME, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                exclude: excludeField,
                required: isProxyChecked, //isMandatory(proxyDataFormValues, formFieldNames.PROXY_SURNAME, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return proxyData
    }, [isProxyChecked])

    const proxyGenderSettings = useMemo(() => {
        let proxyData = {
            [formFieldNames.PROXY_GENDER]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(proxyDataFormValues, formFieldNames.PROXY_GENDER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                    reference: projectDictionaries.GENDER_CODES,
                    showClear: true
                },
                exclude: excludeField,
                required: isProxyChecked, //isMandatory(proxyDataFormValues, formFieldNames.PROXY_GENDER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return proxyData
    }, [isProxyChecked])

    const proxyDateOfBirthSettings = useMemo(() => {
        let proxyData = {
            [formFieldNames.PROXY_DATE_OF_BIRTH]: {
                [formFieldNames.FIELD_PROPS]: {
                    viewDate: years16Before,
                    minDate: years125Before, maxDate: years16Before,
                    dateFormat: "dd/mm/yy",
                    readOnly: isReadOnly(proxyDataFormValues, formFieldNames.PROXY_DATE_OF_BIRTH, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                exclude: excludeField,
                required: isProxyChecked, //isMandatory(proxyDataFormValues, formFieldNames.PROXY_DATE_OF_BIRTH, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return proxyData
    }, [isProxyChecked])

    const proxyFatherNameSettings = useMemo(() => {
        let proxyData = {
            [genericFormFieldNames.PROXY_FATHER_NAME]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(proxyDataFormValues, genericFormFieldNames.PROXY_FATHER_NAME, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                exclude: excludeField,
                required: isProxyChecked, //isMandatory(proxyDataFormValues, genericFormFieldNames.PROXY_FATHER_NAME, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return proxyData
    }, [isProxyChecked])

    const proxyGrandfatherNameSettings = useMemo(() => {
        let proxyData = {
            [genericFormFieldNames.PROXY_GRANDFATHER_NAME]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(proxyDataFormValues, genericFormFieldNames.PROXY_GRANDFATHER_NAME, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                exclude: excludeField,
                required: isProxyChecked, //isMandatory(proxyDataFormValues, genericFormFieldNames.PROXY_GRANDFATHER_NAME, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return proxyData
    }, [isProxyChecked])

    const proxyLetterNumberSettings = useMemo(() => {
        let proxyData = {
            [genericFormFieldNames.PROXY_LETTER_NUMBER]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(proxyDataFormValues, genericFormFieldNames.PROXY_LETTER_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                exclude: excludeField,
                required: isMandatory(proxyDataFormValues, genericFormFieldNames.PROXY_LETTER_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return proxyData
    }, [isProxyChecked])

    const proxyLetterDateSettings = useMemo(() => {
        let proxyData = {
            [genericFormFieldNames.PROXY_LETTER_DATE]: {
                [formFieldNames.FIELD_PROPS]: {
                    minDate: years125Before, maxDate: new Date(),
                    dateFormat: "dd/mm/yy",
                    readOnly: isReadOnly(proxyDataFormValues, genericFormFieldNames.PROXY_LETTER_DATE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                exclude: excludeField,
                required: isMandatory(proxyDataFormValues, genericFormFieldNames.PROXY_LETTER_DATE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return proxyData
    }, [isProxyChecked])

    const proxyLetterAuthoritySettings = useMemo(() => {
        let proxyData = {
            [genericFormFieldNames.PROXY_LETTER_AUTHORITY]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(proxyDataFormValues, genericFormFieldNames.PROXY_LETTER_AUTHORITY, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                exclude: excludeField,
                required: isMandatory(proxyDataFormValues, genericFormFieldNames.PROXY_LETTER_AUTHORITY, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return proxyData
    }, [isProxyChecked])


    return {...proxyDataSettings, ...proxyPersonalNumberSettings, ...proxyNameSettings, ...proxySurnameSettings, ...proxyGenderSettings,
            ...proxyDateOfBirthSettings, ...proxyFatherNameSettings, ...proxyGrandfatherNameSettings, ...proxyLetterNumberSettings,
            ...proxyLetterDateSettings, ...proxyLetterAuthoritySettings}
}