/**
 * @author raicevicm on 13/12/2018.
 *
 * This file should contain all saga and reducer action names
 */
export const sagaActions = Object.freeze({
    TEST_ACTION:"TEST_ACTION"
    
});
export const reducerActions = Object.freeze({});
