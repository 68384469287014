import {
    addressDataFormFieldList, applicantGenericFieldsList,
    BirthData,
    FatherData,
    NameData,
    personalInformationFormFieldList,
    MaritalData, genericFormFieldNames
} from "@mb-react/krg-getid-form-data-common";
import {
    addMultipleInitialValuesToForm, addToAppStore, getFormErrors,
    getFormValues, getFromAppStore, hasValidationErrors
} from "@mb-react/mb-react-core";
import {
    EUseCases,
    extractApplicantDataToFormValues, extractFormValuesToSingleApplicantData,
    extractPersonDataToFormValues,
    formFieldNames,
    PagedRequest
} from "@mb-react/svarog-ui";
import React, {useEffect, useState} from "react";
import {useForm} from "react-final-form";
import {useDispatch, useSelector} from "react-redux";
import {Translate} from "react-redux-i18n";
import {forms} from "../../../../../constants/form";
import {dictionaryReference, projectDictionaries} from "../../../../../constants/general";
import {applicationStore, PERSONAL_DATA_STEPS} from "../../../../../constants/store";
import {useHandleCommonServices} from "../../../../../services/commonServiceHandler";
import {useHandleWorkflowSettings} from "../../../../entries/application/handleWorkflowSettings";
import {useBirthDataSettings} from "./personalDataSettings/birthDataSettings";
import {useFatherDataSettings} from "./personalDataSettings/fatherDataSettings";
import {useNameDataSettings} from "./personalDataSettings/nameDataSettings";
import {extractObjectBasedOnKeysList} from "../../../../../util/dataExtractorUtil";
import {
    isOperationModeNotProtected,
    isOperationModeProtected,
    isUseCaseReservePlateNumber
} from "../../../../../util/businesslogicUtil";
import {NATURAL_PERSON} from "../../../../../constants/application";
import {useMaritalDataSettings} from "./personalDataSettings/maritalDataSettings";

const PreSummaryTask = (props) => {
    const applicationsFromStore = useSelector(state => getFromAppStore(state, 'fetchedApplication'));
    const useCaseFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.USE_CASE]);
    const formValuesApplication = useSelector((state) => getFormValues(state, forms.APPLICATION));
    const selectedPersonForDL = useSelector((state) => getFromAppStore(state, "selectedPersonForDL"));
    const handleWorkflowSettings = useHandleWorkflowSettings();
    const handleCommonServices = useHandleCommonServices()
    const form = useForm();
    const dispatch = useDispatch()
    const errors = useSelector((state) => hasValidationErrors(state, props.formName));
    const formErrors = useSelector((state) => getFormErrors(state, props.formName));
    const [showMaritalDataSection, setShowMaritalDataSection] = useState(false)

    useEffect(() => {
        dispatch(addToAppStore('addIdentityToForm', false))
        if (applicationsFromStore) {
            if (applicationsFromStore["placeOfBirthProvince"]) {
                handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, applicationsFromStore["placeOfBirthProvince"], dictionaryReference.CITIES_PLACE_OF_BIRTH)
            }

            if (applicationsFromStore["placeOfBirthCity"]) {
                handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, applicationsFromStore["placeOfBirthCity"], dictionaryReference.VILLAGES_PLACE_OF_BIRTH)
            }

            if (applicationsFromStore["provinceOfRegistration"]) {
                handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, applicationsFromStore["provinceOfRegistration"], dictionaryReference.CITIES_OF_REGISTRATION)
            }

            if (applicationsFromStore["cityOfRegistration"]) {
                handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, applicationsFromStore["cityOfRegistration"], dictionaryReference.VILLAGES_OF_REGISTRATION)
            }
        }

        if ( props.useCase == "modifyIdentity" || props.useCase == "cancelIdentity" ||
          ((useCaseFromStore == "CANCEL_DOCUMENT" || useCaseFromStore == EUseCases.RESERVE_PLATE_NUMBER) && isOperationModeNotProtected()) ) {
            let pagedRequest = new PagedRequest();
            pagedRequest.requestObject = {}
            pagedRequest.page = 1
            pagedRequest.sizePerPage = 10

            handleCommonServices.handleCallGetMyIdentity(pagedRequest, getMyIdentityOnSuccess)
        }

        if(isOperationModeProtected() && selectedPersonForDL){

            let newObj = extractApplicantDataToFormValues(selectedPersonForDL.applicantData, {});

            if(newObj["placeOfBirthProvince"]){
                handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, newObj["placeOfBirthProvince"], dictionaryReference.CITIES_PLACE_OF_BIRTH)
            }

            if(newObj["placeOfBirthCity"]){
                handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, newObj["placeOfBirthCity"], dictionaryReference.VILLAGES_PLACE_OF_BIRTH)
            }

            let personalInformation = extractObjectBasedOnKeysList(newObj, personalInformationFormFieldList);
            form.initialize(personalInformation)


        }

    }, []);

    useEffect(() => {
        if(isOperationModeProtected() && errors && formErrors && (formErrors[genericFormFieldNames.SPOUSE_FATHER_NAME] || formErrors[genericFormFieldNames.SPOUSE_GRANDFATHER_NAME])){
            setShowMaritalDataSection(true)
            dispatch(addToAppStore(applicationStore.MISSING_SPOUSE_MARITAL_DATA, true))
        }
    }, [errors, formErrors]);

    let sectionsToRender = handleWorkflowSettings.personalDataStepsToRender;

    const getMyIdentityOnSuccess = (response) => {
        let newObj = extractPersonDataToFormValues(response.data, {});
        let personalInformation = extractObjectBasedOnKeysList(newObj, personalInformationFormFieldList);
        let addressData = extractObjectBasedOnKeysList(newObj, addressDataFormFieldList);

        if(newObj["placeOfBirthProvince"]){
            handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, newObj["placeOfBirthProvince"], dictionaryReference.CITIES_PLACE_OF_BIRTH)
        }

        if(newObj["placeOfBirthCity"]){
            handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, newObj["placeOfBirthCity"], dictionaryReference.VILLAGES_PLACE_OF_BIRTH)
        }

        if(!isOperationModeProtected() && isUseCaseReservePlateNumber(formValuesApplication)){
            let entityForApplication = extractFormValuesToSingleApplicantData({
                ...personalInformation,
                ...addressData,
                [formFieldNames.APPLICANT_TYPE]: NATURAL_PERSON,
            }, applicantGenericFieldsList)

            dispatch(addToAppStore("reservePlateNumberEntity", entityForApplication))
        }

        form.initialize(personalInformation)
        dispatch(addMultipleInitialValuesToForm(forms.ADDRESS_DATA, addressData))
        dispatch(addToAppStore('fetchedApplication', {...addressData, ...personalInformation}))

    }

    const maritalDataSet = useMaritalDataSettings();

    return (
        <div className="card">
            <h5><Translate value="label.personalInformation"/></h5>
            <hr className="sectionSeparator"/>
            <div className="grid">
                {sectionsToRender.includes(PERSONAL_DATA_STEPS.NAME_DATA) && <NameData {...useNameDataSettings()} />}
            </div>
            <hr className="sectionSeparator"/>
            <div className="grid">
                {sectionsToRender.includes(PERSONAL_DATA_STEPS.BIRTH_DATA) && <BirthData {...useBirthDataSettings()} />}
            </div>
            <h5><Translate value="label.familyData"/></h5>
            <hr className="sectionSeparator"/>
            <div className="grid">
                {sectionsToRender.includes(PERSONAL_DATA_STEPS.PARENTS_DATA) &&
                    <FatherData {...useFatherDataSettings()} />}
            </div>
            {showMaritalDataSection &&
            <>
                <h5><Translate value="label.maritalData"/></h5>
                <hr className="sectionSeparator"/>
                <div className="grid">
                    <MaritalData {...maritalDataSet} />
                </div>
            </>
            }
        </div>
    )
};
export default PreSummaryTask;