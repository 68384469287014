import {
    addressDataFormFieldList,
    driverLicenseFormFieldList,
    personalInformationFormFieldList
} from "@mb-react/krg-getid-form-data-common";
import {
    SimpleTable,
    addMultipleInitialValuesToForm,
    addToAppStore,
    getFromAppStore,
    getFormValues, Button
} from '@mb-react/mb-react-core';
import {
    ProcessStateController,
    PagedRequest,
    ServiceRequestData,
    extractApplicationDataToFormValues,
    useCreateServiceWrapper, ownerRoles
} from '@mb-react/svarog-ui';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {REDIRECT_TO_APPOINTMENT} from '../../../constants/environment';
import {formFieldsQuestionnaire, formFieldsVRQuestionnaire, forms} from "../../../constants/form";
import {getPath, i18nl} from '../../../constants/i18n';
import {applicationStore} from '../../../constants/store';
import {useHandleCommonServices} from "../../../services/commonServiceHandler";
import {
    prepareFetchedBloodGroupTypeForDDL,
    prepareFetchedDLCategoryForDropdown,
    setQuestionnaireBasedOnUseCase,
    setQuestionnaireBasedOnVRUseCase
} from "../../../util/dataPreparationUtil";
import {extractValueFromGenericField} from "../../../util/environment";
import MyApplicationsSearchForm from "./MyApplicationSearchForm";
import {extractObjectBasedOnKeysList} from "../../../util/dataExtractorUtil";
import {
    isLoggedAccountLegalEntity, mapVehicleToFormValues
} from "../../../util/businesslogicUtil";

const MyApplicationsTable = () => {
    const applicationsFromStore = useSelector(state => getFromAppStore(state, applicationStore.APPLICATIONS_DATA))
    const appFormValues = useSelector(state => getFormValues(state, forms.APPLICATION))
    const serviceWrapper = useCreateServiceWrapper();
    const [count, setCount] = useState(0);
    const handleCommonServices = useHandleCommonServices();
    const identity = useSelector(state => getFromAppStore(state, 'identity'))
    const searchApplicationFormValues = useSelector(state => getFormValues(state, forms.SEARCH_APPLICATION))

    let dispatch = useDispatch();

    const fetchApplication = (pageNumber, pageSize, params, identityData) => {
        let pagedRequest = new PagedRequest();
        if(params){
            pagedRequest = {...params}
        }
        pagedRequest.pageNumber = pageNumber
        pagedRequest.pageSize = pageSize

        let request = new ServiceRequestData();
        request.service = ProcessStateController.searchApplications
        request.requestData = pagedRequest
        request.onSuccessLocal = (result) => {
            let applications = []
            if (result) {
                applications = result.pagedResults;
                if (JSON.stringify(applications)) {
                    const tableData = [];
                    applications.map(app => {
                        let appObject = {
                            applicationID: app.applicationMetaDataDTO.applicationID,
                            applicationType: I18n.t('label.table.applicationType.' + app.applicationMetaDataDTO.applicationType),
                            useCase: I18n.t('label.table.useCase.' + app.applicationMetaDataDTO.useCase),
                            givenName: app.applicantsDTO.applicantDTOList[0].demographicDataDTO.personDataDTO.givenName,
                            surname: app.applicantsDTO.applicantDTOList[0].demographicDataDTO.personDataDTO.surname,
                            dateOfBirth: app.applicantsDTO.applicantDTOList[0].demographicDataDTO.personDataDTO.dateOfBirth,
                            applicationStatus: I18n.t('label.table.applicationStatus.' + app.applicationMetaDataDTO.applicationState),
                            appStatus: app.applicationMetaDataDTO.applicationState,
                            nameLe: app.applicantsDTO.applicantDTOList[0].demographicDataDTO.personDataDTO.nameLe
                        }
                        tableData.push(appObject)
                    })

                    let filteredData = filteredTableData(tableData, identityData);


                    dispatch(addToAppStore(applicationStore.APPLICATIONS_DATA, filteredData))
                    setCount(result.count);
                }
            }
        }
        serviceWrapper(request)
    }

    const filteredTableData = (tableData, identityData) => {
        let filteredData = [];

        let accountIdentity = identityData ? identityData : identity;

        if(tableData){
            tableData.map(data => {

                let listOfAppsWithId = listOfAppsWithSameId(tableData, data.applicationID)

                if(listOfAppsWithId && listOfAppsWithId.length > 1){
                    if(!checkIfApplicationIsAlreadyAdded(filteredData, data.applicationID)){
                        let record = getByNameAndSurname(listOfAppsWithId, accountIdentity)
                        if(record){
                            filteredData.push(record)
                        }else{
                            filteredData.push(data)
                        }
                    }
                }else{
                    filteredData.push(data)
                }
            })
        }
        return filteredData;
    }

    const getByNameAndSurname =(listOfApps, identityData) => {
        if(isLoggedAccountLegalEntity()){
            return listOfApps.find(x => x.nameLe === identityData.leName);
        }else{
            return listOfApps.find(x => x.givenName === identityData.givenName && x.surname === identityData.surname);
        }
    }

    const checkIfApplicationIsAlreadyAdded =(listOfApps, appId) => {
        return listOfApps.some(x => x.applicationID === appId);
    }

    const listOfAppsWithSameId = (listOfApps, appId) => {
        return listOfApps.filter(x => x.applicationID === appId);
    }

    const paginationFunc = (pageNumber, pageSize) => {
        fetchApplication(pageNumber + 1, pageSize, searchApplicationFormValues)
    }

    useEffect(() => {
        let pagedRequest = new PagedRequest();
        pagedRequest.requestObject = {}
        pagedRequest.page = 1
        pagedRequest.sizePerPage = 10

        if (isLoggedAccountLegalEntity()) {
            handleCommonServices.handleGetMyIdentityForLegalEntity(pagedRequest, getMyIdentityOnSuccess)
        } else {
            handleCommonServices.handleCallGetMyIdentity(pagedRequest, getMyIdentityOnSuccess)
        }

    }, [])


    const getMyIdentityOnSuccess = (response) => {
        dispatch(addToAppStore("identity", response.data))
        fetchApplication(null, null, null, response.data)
    }

    const onSuccessFetch = (result) => {
        if (result) {
            let application = extractApplicationDataToFormValues(result);
            if (application["driversLicenseData"]) {
                const preparedDLClasses = prepareFetchedDLCategoryForDropdown(application["driversLicenseData"]);
                application["driversLicenseData"] = [...preparedDLClasses]
            }

            if (application["oldLicenseClasses"]) {
                let listOfLicense = application["oldLicenseClasses"].split(",");

                let lst = [];
                listOfLicense.forEach(x => {
                    lst.push({
                        className: x,
                        restrictions: null,
                        validFromDate: null,
                        validUntilDate: null
                    })
                })

                const preparedDLClasses = prepareFetchedDLCategoryForDropdown(lst);
                application["oldLicenseClasses"] = [...preparedDLClasses]
            }

            if (application["useCase"]) {
                if (application.applicationType === "VR") {
                    application = {...application, ...setQuestionnaireBasedOnVRUseCase(application["useCase"], application["changeOwnershipSubUseCase"])}
                } else
                    application = {...application, ...setQuestionnaireBasedOnUseCase(application["useCase"])}
            }

            if (application["bloodGroup"]) {
                application["bloodGroup"] = prepareFetchedBloodGroupTypeForDDL(application["bloodGroup"])
            }

            let applicationMetaDataList = ["workflow", "documentType", "applicationType", "useCase", "enrolmentStarted", "applicationID", "transactionId"];

            let questionnaireInfo = application.applicationType === "VR" ? Object.values(formFieldsVRQuestionnaire) : Object.values(formFieldsQuestionnaire)

            let applicationMetaData = extractObjectBasedOnKeysList(application, applicationMetaDataList)
            let questionnaireData = extractObjectBasedOnKeysList(application, questionnaireInfo);
            let personalInformation = extractObjectBasedOnKeysList(application, personalInformationFormFieldList);
            let addressData = extractObjectBasedOnKeysList(application, addressDataFormFieldList);
            let dlData = extractObjectBasedOnKeysList(application, driverLicenseFormFieldList)

            dispatch(addMultipleInitialValuesToForm(forms.APPLICATION, applicationMetaData))
            if (application.applicationType === "VR") {
                dispatch(addMultipleInitialValuesToForm(forms.QUESTIONNAIRES_VEHICLE, questionnaireData))
            } else {
                dispatch(addMultipleInitialValuesToForm(forms.QUESTIONNAIRES, questionnaireData))
            }

            if (application.applicationType === "VR" && result.applicantsDTO && result.applicantsDTO.applicantDTOList) {

                let listOfApplicants = [];
                let listOfSellers = [];
                let listOfBuyers = [];
                let totalPercentageSellers = [];
                let totalPercentageBuyers = [];

                result.applicantsDTO.applicantDTOList.forEach((applicant, id) => {
                    let enrollmentStatus = extractValueFromGenericField(applicant.genericFieldsDTO.genericFieldDTOList, "enrollmentStatus");
                    let entityType = applicant.applicantType;
                    listOfApplicants.push({
                        sellerId: id,
                        buyerId: id,
                        enrollmentStatus: enrollmentStatus,
                        entityType: entityType,
                        applicantData: applicant
                    })
                })

                if (listOfApplicants.length > 0) {
                    listOfApplicants.forEach((applicant) => {
                        if (applicant.applicantData.ownershipDTO && applicant.applicantData.ownershipDTO.ownerRole === ownerRoles.BUYER) {
                            listOfBuyers.push(applicant)
                            totalPercentageBuyers.push({
                                id: applicant.buyerId,
                                lead: applicant.applicantData && applicant.applicantData.ownershipDTO ? applicant.applicantData.ownershipDTO.lead : null,
                                percentage: applicant.applicantData && applicant.applicantData.ownershipDTO ? applicant.applicantData.ownershipDTO.percent : null
                            })
                        } else {
                            listOfSellers.push(applicant)
                            totalPercentageSellers.push({
                                id: applicant.sellerId,
                                lead: applicant.applicantData && applicant.applicantData.ownershipDTO ? applicant.applicantData.ownershipDTO.lead : null,
                                percentage: applicant.applicantData && applicant.applicantData.ownershipDTO ? applicant.applicantData.ownershipDTO.percent : null
                            })
                        }
                    })
                }

                if (listOfSellers.length > 0) {
                    dispatch(addToAppStore('listOfSellers', listOfSellers))
                    dispatch(addToAppStore('totalPercentageSellers', totalPercentageSellers))
                }

                if (listOfBuyers.length > 0) {
                    dispatch(addToAppStore('listOfBuyers', listOfBuyers))
                    dispatch(addToAppStore('totalPercentageBuyers', totalPercentageBuyers))
                }

            }else if(application.applicationType === "IM"){
                dispatch(addMultipleInitialValuesToForm(forms.PERSONAL_INFORMATION, personalInformation))
                dispatch(addMultipleInitialValuesToForm(forms.ADDRESS_DATA + "Source", addressData))
                dispatch(addMultipleInitialValuesToForm(forms.DRIVER_LICENSE, dlData))
            } else {
                dispatch(addMultipleInitialValuesToForm(forms.PERSONAL_INFORMATION, personalInformation))
                dispatch(addMultipleInitialValuesToForm(forms.ADDRESS_DATA, addressData))
                dispatch(addMultipleInitialValuesToForm(forms.DRIVER_LICENSE, dlData))
            }

            if (application.applicationType === "VR" && application.transactionId) {
                handleCommonServices.handleGetVehicleByTransactionId({transactionId: application.transactionId}, getVehicleByTransactionIdOnSuccess);
            }

            dispatch(addToAppStore('fetchedApplication', application))
        }
    };

    const getVehicleByTransactionIdOnSuccess = (response) => {
        mapVehicleToFormValues(dispatch, handleCommonServices, response, appFormValues)
    }

    const editApplication = (row) => {
        serviceWrapper(new ServiceRequestData(
            ProcessStateController.getApplicationByApplicationId,
            row.applicationID,
            null,
            null,
            (result) => {
                if (result) {
                    onSuccessFetch(result)
                }
            },
            null
        ))
    };


    const startBookApp = (row) => {
        sessionStorage.setItem("selsectedApplicationId", row.applicationID);
        window.location = REDIRECT_TO_APPOINTMENT

    };

    const columns = [
        {
            field: 'applicationID',
            header: I18n.t(getPath(i18nl.APPLICATION_ID))
        },
        {
            field: 'applicationType',
            header: I18n.t(getPath(i18nl.APPLICATION_TYPE))
        },
        {
            field: 'useCase',
            header: I18n.t(getPath(i18nl.USE_CASE))
        },
        {
            field: 'givenName',
            header: I18n.t(getPath(i18nl.GIVEN_NAME))
        },
        {
            field: 'surname',
            header: I18n.t(getPath(i18nl.SURNAME))
        },
        {
            field: 'dateOfBirth',
            header: I18n.t(getPath(i18nl.DATE_OF_BIRTH))
        },
        {
            field: 'applicationStatus',
            header: I18n.t(getPath(i18nl.STATUS))
        },
        {
            field: 'appStatus',
            header: I18n.t(getPath(i18nl.STATUS)),
            hidden: true
        }
    ];

    const columnsLe = [
        {
            field: 'applicationID',
            header: I18n.t(getPath(i18nl.APPLICATION_ID))
        },
        {
            field: 'applicationType',
            header: I18n.t(getPath(i18nl.APPLICATION_TYPE))
        },
        {
            field: 'useCase',
            header: I18n.t(getPath(i18nl.USE_CASE))
        },
        {
            field: 'nameLe',
            header: I18n.t(getPath(i18nl.LE_NAME))
        },
        {
            field: 'applicationStatus',
            header: I18n.t(getPath(i18nl.STATUS))
        },
        {
            field: 'appStatus',
            header: I18n.t(getPath(i18nl.STATUS)),
            hidden: true
        }
    ];

    const customActionTemplate = (row) => {
        return (
            <div className="actionColumn" style={{ display: "flex" }}>
                {row && row.appStatus === "CREATED_INCOMPLETE" && <Button icon="pi pi-pencil" className="p-button-rounded p-button-success" onClick={() => editApplication(row)} />}
            </div>
        )

    }

    return (
        <div>
            <MyApplicationsSearchForm searchApplicationFunc={fetchApplication}/>
            <SimpleTable tableData={applicationsFromStore}
                         actionTitle={I18n.t("label.actionTitle")}
                         columns={isLoggedAccountLegalEntity() ? columnsLe : columns}
                         count={count}
                         customActionTemplate={customActionTemplate}
                         onViewIcon="pi pi-calendar-plus"
                         paginator={true}
                         paginationFunc={paginationFunc}
            />
        </div>
    )
}
export default MyApplicationsTable