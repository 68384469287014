import React, {useEffect, useMemo, useState} from 'react';
import PersonSearch from "./PersonSearch";
import LegalEntitySearch from "./LegalEntitySearch";
import {getFromAppStore, SelectButton} from "@mb-react/mb-react-core/dist";
import {I18n, Translate} from "react-redux-i18n";
import {useSelector} from "react-redux";
import {isSubUseCaseSaleAndBuy} from "../../../../../util/businesslogicUtil";
import {getFormValues} from "@mb-react/mb-react-core";
import {forms} from "../../../../../constants/form";
import {genericFormFieldNames} from "@mb-react/krg-getid-form-data-common";
import SearchLegalEntityTableResult from "./SearchLegalEntityTableResult";
import SearchPersonTableResult from "./SearchPersonTableResult";

function SearchApplicant(props) {
  const naturalPersonForm = 'naturalPersonForm';
  const legalEntityForm = 'legalEntityForm';
  const [showForm, setFormToShow] = useState(naturalPersonForm);
  const [selectOptions, setSelectOptions] = useState([naturalPersonForm, legalEntityForm]);
  const listOfBuyers = useSelector((state) => getFromAppStore(state, 'listOfBuyers'))
  const listOfSellers = useSelector((state) => getFromAppStore(state, 'listOfSellers'));
  const appFormValues = useSelector((state) => getFormValues(state, forms.APPLICATION))
  const isSellAndBuy = useMemo(() => isSubUseCaseSaleAndBuy(appFormValues), [appFormValues?.[genericFormFieldNames.CHANGE_OWNERSHIP_SUB_USE_CASE]])
  const itemTemp = (e) => <span><Translate value={"label." + e}/></span>;

  useEffect(() => {

    if (isSellAndBuy) {
      setFormToShow(naturalPersonForm);
      setSelectOptions([naturalPersonForm, legalEntityForm])
    } else if(listOfBuyers?.length > 0 || listOfSellers?.length > 0){
      setFormToShow(naturalPersonForm);
      setSelectOptions([naturalPersonForm, legalEntityForm])
    } else {
      setFormToShow(naturalPersonForm);
      setSelectOptions([naturalPersonForm, legalEntityForm])
    }
  }, [listOfBuyers?.length, listOfSellers?.length]);

  // check implementation for sell & buy, we shall have two lists of applicants - sellers and buyers.
  return (
    <>
      <SelectButton
        className="mb-4 mt-3 customSelectBtn" options={selectOptions}
        value={showForm}
        onChange={(e) => {
          setFormToShow(e.value)
        }}
        itemTemplate={itemTemp}
      />
      {showForm === naturalPersonForm && <><PersonSearch/><SearchPersonTableResult role={props.role}/></>}
      {showForm === legalEntityForm && <><LegalEntitySearch/><SearchLegalEntityTableResult role={props.role}/></>}
    </>
  );
}

export default SearchApplicant;