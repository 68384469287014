import {getFormValues, Menubar} from "@mb-react/mb-react-core";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useHandleAuthenticationServices } from "../../../../services/authentication/authenticationServiceHandler";
import {
  isLoggedIn
} from "../../../../util/businesslogicUtil";
import {getListOfMenuBarItems} from "./menuBarLists";
import {menuBarItems} from "./menuBarItems";


const MenubarComponent = () => {

  const handleAuthenticationServices = useHandleAuthenticationServices();
  useEffect(() => {
    if(isLoggedIn()) {
      handleAuthenticationServices.getUserRights()
    }
  }, []);

 let listOfMenuBarItems = getListOfMenuBarItems();

 const menuItems = menuBarItems();

  let items = listOfMenuBarItems ? listOfMenuBarItems.map(item => menuItems.menuItems()[item]) : null

  return (
    <div>
      {items &&
        <Menubar model={items} />
      }
    </div>
  );
};

MenubarComponent.propTypes = {
  changeComponent: PropTypes.func,
};
export default MenubarComponent;
