import { genericFormFieldNames } from "@mb-react/krg-getid-form-data-common"
import {getFormValues, getFromAppStore} from "@mb-react/mb-react-core"
import { formFieldNames } from "@mb-react/svarog-ui"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import {ACCOUNT_IDENTITY} from "../../../../../../../constants/application";
import {formFieldsMeta, forms} from "../../../../../../../constants/form";
import {isMandatory, isReadOnly} from "../../../../../../../util/businesslogicUtil";
import {formatDate} from "../../../../../../../util/dateUtil";

export const contractDetailsFieldsSettings = () => {
    const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION))
    const applicationDataformValues = useSelector((state) => getFormValues(state, forms.APPLICATION_DATA))
    const accountIdentity = useSelector(state => getFromAppStore(state, ACCOUNT_IDENTITY));

    let today = new Date();

    let years125Before = new Date();
    years125Before.setFullYear(years125Before.getFullYear() - 125);

    const handleContractNumberSettings = useMemo(() => {
        let contractDataProps = {
            [genericFormFieldNames.CONTRACT_NUMBER]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(applicationDataformValues, genericFormFieldNames.CONTRACT_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                required: isMandatory(applicationDataformValues, genericFormFieldNames.CONTRACT_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return contractDataProps
    }, [])

    const handleContractDateSettings = useMemo(() => {
        let contractDataProps = {
            [genericFormFieldNames.CONTRACT_DATE]: {
                [formFieldNames.FIELD_PROPS]: {
                    minDate: years125Before, maxDate: today,
                    dateFormat: formatDate(applicationDataformValues[genericFormFieldNames.CONTRACT_DATE]),
                    readOnly: isReadOnly(applicationDataformValues, genericFormFieldNames.CONTRACT_DATE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                required: isMandatory(applicationDataformValues, genericFormFieldNames.CONTRACT_DATE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return contractDataProps
    }, [])


    return { ...handleContractNumberSettings, ...handleContractDateSettings}
}