import {formFieldNames} from "@mb-react/svarog-ui";
import {plateCategories} from "../constants/application";
import {genericFormFieldNames} from "@mb-react/krg-getid-form-data-common";
import {extractGenericFields} from "./formUtils";

export function validateDesiredPlateNumberByPlateCategory(formValues) {
    console.log(formValues, "-> validateDesiredPlateNumberByPlateCategory")
    const desiredPlateNumber = formValues?.[genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN];
    const plateCategory = formValues?.[formFieldNames.PLATE_CATEGORY];
    if (!desiredPlateNumber || desiredPlateNumber?.length < 2 || !plateCategory) {
        return false;
    }

    const governmentRegex = /^[0-9]\d{2,2}$/;
    const letterRegex = /^[A-Z]$/;
    const digitRegex = /^\d$/;

    if (plateCategories.SPECIAL === plateCategory) {
        return desiredPlateNumber.match(governmentRegex)
    }

    const letter = desiredPlateNumber[0];
    const digits = desiredPlateNumber.substring(1);

    return !(!letterRegex.test(letter) || digits.length > 5 || !digits.split('').every((d) => digitRegex.test(d)));
}

export function formatPlateNumberLatin(desiredPlateNumberLatin, governorate) {
    if (desiredPlateNumberLatin?.length <= 6 && governorate) {
        return governorate + " " + desiredPlateNumberLatin.substring(0, 1) + " " + desiredPlateNumberLatin.substring(1);
    } else {
        return desiredPlateNumberLatin;
    }
}

export const formatPlateNumber = (plateNumber) => {
    plateNumber = plateNumber.substring(plateNumber.indexOf(" ") + 1)
    plateNumber = plateNumber.replace(" ", "")
    return plateNumber
}

export const reservePlateNumberRequestData = (formValuesPlateData, accountType, accountSubType) => {

    let plateRequestData = {
        [formFieldNames.PLATE_CATEGORY]: formValuesPlateData?.[formFieldNames.PLATE_CATEGORY],
        [formFieldNames.PLATE_USAGE]: formValuesPlateData?.[formFieldNames.PLATE_USAGE],
        [formFieldNames.PLATE_PROVINCE]: formValuesPlateData?.[formFieldNames.PLATE_PROVINCE],
        [formFieldNames.PLATE_COUNTRY_CODE]: formValuesPlateData?.[formFieldNames.PLATE_COUNTRY_CODE],
        [formFieldNames.DAYS_TO_EXTEND_PLATE_RESERVATION]: 10,
        genericFieldsDTO: extractGenericFields({
            ...formValuesPlateData,
            [formFieldNames.APPLICANT_TYPE]: accountType,
            [formFieldNames.APPLICANT_SUB_TYPE]: accountSubType
        }, [genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN, genericFormFieldNames.AVAILABLE_PLATE_NUMBER_LATIN, formFieldNames.APPLICANT_TYPE, formFieldNames.APPLICANT_SUB_TYPE])
    }

    return plateRequestData;
};