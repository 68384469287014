import { useSelector } from "react-redux";
import { getFormValues } from "@mb-react/mb-react-core";
import { formFieldsMeta, forms } from "../../../../../../constants/form";
import { useMemo } from "react";
import { formFieldNames } from "@mb-react/svarog-ui";
import { isMandatory, isReadOnly } from "../../../../../../util/businesslogicUtil";
import { genericFormFieldNames } from "@mb-react/krg-getid-form-data-common";

export const useAdditionalApplicationDataSettings = () => {
    const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION))
    const documentsDataFormValues = useSelector((state) => getFormValues(state, forms.DOCUMENTS_DATA))

    const requestMedicalExemSettings = useMemo(() => {
        let additionalData = {
            [genericFormFieldNames.REQUEST_MEDICAL_EXEM]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(documentsDataFormValues, genericFormFieldNames.REQUEST_MEDICAL_EXEM, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                exclude: true,
                required: isMandatory(documentsDataFormValues, genericFormFieldNames.REQUEST_MEDICAL_EXEM, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return additionalData
    }, [])

    const requestApprovalSettings = useMemo(() => {
        let additionalData = {
            [genericFormFieldNames.REQUEST_APPROVAL]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(documentsDataFormValues, genericFormFieldNames.REQUEST_APPROVAL, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                exclude: true,
                required: isMandatory(documentsDataFormValues, genericFormFieldNames.REQUEST_APPROVAL, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return additionalData
    }, [])

    const requestApprovalCommentSettings = useMemo(() => {
        let additionalData = {
            [genericFormFieldNames.REQUEST_APPROVAL_COMMENT]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(documentsDataFormValues, genericFormFieldNames.REQUEST_APPROVAL_COMMENT, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                exclude: true,
                required: isMandatory(documentsDataFormValues, genericFormFieldNames.REQUEST_APPROVAL_COMMENT, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return additionalData
    }, [])

    const commentSettings = useMemo(() => {
        let additionalData = {
            [formFieldNames.COMMENT]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(documentsDataFormValues, formFieldNames.COMMENT, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                exclude: true,
                required: isMandatory(documentsDataFormValues, formFieldNames.COMMENT, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return additionalData
    }, [])

    const newCardSettings = useMemo(() => {
        let additionalData = {
            [genericFormFieldNames.NEW_CARD]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(documentsDataFormValues, genericFormFieldNames.NEW_CARD, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                required: isMandatory(documentsDataFormValues, genericFormFieldNames.NEW_CARD, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return additionalData
    }, [])

    const newWindshieldStickerSettings = useMemo(() => {
        let additionalData = {
            [genericFormFieldNames.NEW_WINDSHIELD_STICKER]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(documentsDataFormValues, genericFormFieldNames.NEW_WINDSHIELD_STICKER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                exclude: true,
                required: isMandatory(documentsDataFormValues, genericFormFieldNames.NEW_WINDSHIELD_STICKER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return additionalData
    }, [])

    const newPlatesSettings = useMemo(() => {
        let additionalData = {
            [genericFormFieldNames.NEW_PLATES]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(documentsDataFormValues, genericFormFieldNames.NEW_PLATES, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW])
                },
                // exclude: newPlates,
                required: isMandatory(documentsDataFormValues, genericFormFieldNames.NEW_PLATES, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return additionalData
    }, [])

    const NumberOfRequestedPlatesSettings = useMemo(() => {
        let additionalData = {
            [genericFormFieldNames.NUMBER_OF_REQUESTED_PLATES]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(documentsDataFormValues, genericFormFieldNames.NUMBER_OF_REQUESTED_PLATES, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                exclude: true,
                required: isMandatory(documentsDataFormValues, genericFormFieldNames.NUMBER_OF_REQUESTED_PLATES, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return additionalData
    }, [])

    const previousCancellationSettings = useMemo(() => {
        let additionalData = {
            [genericFormFieldNames.PREVIOUS_CANCELLATION]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(documentsDataFormValues, genericFormFieldNames.PREVIOUS_CANCELLATION, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                exclude: true,
                required: isMandatory(documentsDataFormValues, genericFormFieldNames.PREVIOUS_CANCELLATION, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return additionalData
    }, [])

    const cancellationReasonSettings = useMemo(() => {
        let additionalData = {
            [genericFormFieldNames.CANCELLATION_REASON]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(documentsDataFormValues, genericFormFieldNames.CANCELLATION_REASON, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                exclude: true,
                required: isMandatory(documentsDataFormValues, genericFormFieldNames.CANCELLATION_REASON, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return additionalData
    }, [])

    const skipPersonalizationSettings = useMemo(() => {
        let additionalData = {
            [genericFormFieldNames.SKIP_PERSONALIZATION]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(documentsDataFormValues, genericFormFieldNames.SKIP_PERSONALIZATION, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                exclude: true,
                required: isMandatory(documentsDataFormValues, genericFormFieldNames.SKIP_PERSONALIZATION, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return additionalData
    }, [])

    return {
        ...requestMedicalExemSettings, ...requestApprovalSettings, ...requestApprovalCommentSettings, ...commentSettings,
        ...newCardSettings, ...newPlatesSettings, ...NumberOfRequestedPlatesSettings, ...newWindshieldStickerSettings,
        ...previousCancellationSettings, ...cancellationReasonSettings, ...skipPersonalizationSettings
    }
}