import { genericFormFieldNames } from "@mb-react/krg-getid-form-data-common";
import { addToAppStore, change, getFormValues } from "@mb-react/mb-react-core";
import {
  formFieldNames,
  GetAllDictionaryRequest,
  getCookie,
  InterfaceAdapter,
  ServiceRequestData,
  useCreateServiceWrapper,
  ProcessStateController
} from "@mb-react/svarog-ui";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formFieldsMeta, forms } from "../../../constants/form";
import { dictionaryReference, ENROLLMENT, projectDictionaries } from "../../../constants/general";
import { useHandleGetDictionarySuccessResult } from "../../../services/application/successGetDictionaryHandler";
import { useHandleCommonServices } from "../../../services/commonServiceHandler";
import {
  getAddressDataSectionsToRender,
  getApplicationTasksToRender,
  getPersonalDataSectionsToRender, getVehicleDataStepsToRender,
  getVisaDataDataSectionsToRender,
  isIRQ,
  isUseCaseReRegister,
} from "../../../util/businesslogicUtil";

export function useHandleWorkflowSettings() {
  const handleGetDictionarySuccessResult = useHandleGetDictionarySuccessResult()
  const callService = useCreateServiceWrapper();
  const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION))
  const dispatch = useDispatch();
  const handleCommonServices = useHandleCommonServices()
  const preselectInitialValues = () => {
    if (getCookie("username")) {
      dispatch(change(forms.APPLICATION, formFieldNames.OPERATOR_USERNAME, getCookie("username")))
    }

    if (configurationData && configurationData.stationId) {
      dispatch(change(forms.APPLICATION, formFieldNames.STATION_ID, configurationData.stationId))
    }

    if (isUseCaseReRegister(formValues)) {
      dispatch(change(forms.DRIVER_LICENSE, genericFormFieldNames.ISSUE_COUNTRY_DL, "IRQ"))
    }
  };

  const getAllDictionaries = () => {
    let arrayOfCodesRequest = new GetAllDictionaryRequest();
    arrayOfCodesRequest.arrayOfCodes = [
      projectDictionaries.COUNTRY_CODES,
      projectDictionaries.PROVINCES,
      projectDictionaries.MARITAL_CODES,
      projectDictionaries.GENDER_CODES,
      projectDictionaries.PROFESSION,
      projectDictionaries.NATIONALITIES,
      projectDictionaries.BLOOD_GROUP,
      projectDictionaries.BUILDING_CLASS,
      projectDictionaries.MEDICAL_RESTRICTIONS,
      projectDictionaries.VEHICLE_BRANDS,
      projectDictionaries.TRAILER_BRANDS,
      projectDictionaries.VEHICLE_MODELS,
      projectDictionaries.TRAILER_MODELS,
      projectDictionaries.VEHICLE_CATEGORIES,
      projectDictionaries.TRAILER_CATEGORIES,
      projectDictionaries.VEHICLE_SUBCATEGORIES,
      projectDictionaries.VEHICLE_USAGE_TYPES,
      projectDictionaries.VEHICLE_WHEEL_TYPES,
      projectDictionaries.VEHICLE_SUB_CATEGORY,
      projectDictionaries.VEHICLE_FUEL_TYPES,
      projectDictionaries.VEHICLE_CABIN_TYPES,
      projectDictionaries.VEHICLE_CABIN_SIZES,
      projectDictionaries.PLATE_TYPES,
      projectDictionaries.PLATE_CATEGORIES,
      projectDictionaries.PLATE_USAGES,
      projectDictionaries.STATE_HANDLING_REASONS,
      projectDictionaries.GOVERNORATE,
      projectDictionaries.LEGAL_ENTITY_TYPE,
      projectDictionaries.LEGAL_ENTITY_SUBTYPE,
      projectDictionaries.PLATE_CATEGORY,
      "plate_usage_standard",
      "plate_usage_special",
      "plate_usage_motorcycle",
      "plate_usage_other",
      "front_tint_percentage",
      "rear_tint_percentage"
    ];

    //TODO to be checked why values for plate_usage_xxx can't be used from projectDictionaries constant

    callService(new ServiceRequestData(
      InterfaceAdapter.getAllDictionaries,
      arrayOfCodesRequest,
      handleGetDictionarySuccessResult,
      null,
      null,
      null
    ));

    handleCommonServices.handleGetSingleDictionary(projectDictionaries.VEHICLE_COLORS, "preview", projectDictionaries.VEHICLE_COLORS)

    if (formValues) {
      if (formValues[formFieldNames.ADDRESS_PROVINCE]) {
        handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, formValues[formFieldNames.ADDRESS_PROVINCE], dictionaryReference.CITIES_ADDRESS)
        handleCommonServices.handleGetSingleDictionary(projectDictionaries.INFO, formValues[formFieldNames.ADDRESS_PROVINCE], projectDictionaries.INFO)
      }
      if (formValues[formFieldNames.ADDRESS_CITY]) {
        handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, formValues[formFieldNames.ADDRESS_CITY], dictionaryReference.VILLAGES_ADDRESS)
      }
      if (formValues[formFieldNames.PLACE_OF_BIRTH_COUNTRY] && isIRQ(formValues[formFieldNames.PLACE_OF_BIRTH_COUNTRY])) {
        if (formValues[formFieldNames.PLACE_OF_BIRTH_PROVINCE]) {
          handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, formValues[formFieldNames.PLACE_OF_BIRTH_PROVINCE], dictionaryReference.CITIES_PLACE_OF_BIRTH)
        }
        if (formValues[formFieldNames.PLACE_OF_BIRTH_CITY]) {
          handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, formValues[formFieldNames.PLACE_OF_BIRTH_CITY], dictionaryReference.VILLAGES_PLACE_OF_BIRTH)
        }
        if (formValues[genericFormFieldNames.PROVINCE_OF_REGISTRATION]) {
          handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, formValues[genericFormFieldNames.PROVINCE_OF_REGISTRATION], dictionaryReference.CITIES_OF_REGISTRATION)
        }
        if (formValues[genericFormFieldNames.CITY_OF_REGISTRATION]) {
          handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, formValues[genericFormFieldNames.CITY_OF_REGISTRATION], dictionaryReference.VILLAGES_OF_REGISTRATION)
        }
      }

    }
  };
  const generateApplicationID = () => {
    if (formValues && !formValues[formFieldNames.APPLICATION_ID]) {
      callService(new ServiceRequestData(
        ProcessStateController.createApplicationId,
        null,
        null,
        null,
        (result) => {
          if (result && result.applicationId) {
            dispatch(addToAppStore(formFieldNames.APPLICATION_ID, result.applicationId));
            dispatch(change(forms.APPLICATION, formFieldNames.APPLICATION_ID, result.applicationId));

          }
        },
        null
      ))
    }

  };

  const initWorkflowSettings = () => {
    getAllDictionaries();
    generateApplicationID();
    preselectInitialValues();
  };

  useEffect(() => {
    dispatch(change(forms.APPLICATION, formFieldsMeta.WORKFLOW, ENROLLMENT));
  }, []);

  return {
    initWorkflowSettings: initWorkflowSettings,
    tasksToRender: getApplicationTasksToRender(formValues),
    personalDataStepsToRender: getPersonalDataSectionsToRender(formValues),
    addressDataSectionsToRender: getAddressDataSectionsToRender(formValues),
    visaDataStepsToRender: getVisaDataDataSectionsToRender(formValues),
    vehicleDataStepsToRender: getVehicleDataStepsToRender(formValues)
  }
}