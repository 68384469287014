import {I18n} from "react-redux-i18n";
import {getPath, i18nl} from "../../../../constants/i18n";
import {
    REDIRECT_TO_ADMIN_HOME,
    REDIRECT_TO_APPOINTMENT,
    REDIRECT_TO_CANCEL_IDENTITY,
    REDIRECT_TO_CHANGE_PASSWORD,
    REDIRECT_TO_EDIT_ACCOUNT,
    REDIRECT_TO_FAQ,
    REDIRECT_TO_IDENTITY_MANAGEMENT,
    REDIRECT_TO_LOGIN,
    REDIRECT_TO_MODIFY_IDENTITY,
    REDIRECT_TO_MY_APPLICATIONS,
    REDIRECT_TO_MY_APPOINTMENTS,
    REDIRECT_TO_NEW_DRIVER_LICENSE,
    REDIRECT_TO_NEW_VEHICLE_REGISTRATION,
    REDIRECT_TO_TEMPLATE_DOCUMENTS,
    ROOT
} from "../../../../constants/environment";
import {b64ToStringEncoder} from "../../../../util/dataPreparationUtil";
import { formFieldNames, getCookie, setCookie, EUseCases } from "@mb-react/svarog-ui";
import {changeOwnershipSubUseCases} from "../../../../constants/application";
import {delete_cookie} from "../../../../util/util";
import {genericFormFieldNames} from "@mb-react/krg-getid-form-data-common";

export function menuBarItems() {

    let username = "Username";
    if (getCookie("username")) {
        username = b64ToStringEncoder(getCookie("username"))
    }

    function useCaseRedirect (documentType, useCase, subUseCase){
        delete_cookie([genericFormFieldNames.CHANGE_OWNERSHIP_SUB_USE_CASE])
        setCookie([formFieldNames.USE_CASE], useCase)
        if(subUseCase){
            setCookie([genericFormFieldNames.CHANGE_OWNERSHIP_SUB_USE_CASE], subUseCase)
        }
        let location = REDIRECT_TO_NEW_DRIVER_LICENSE
        if(documentType === "VR"){
            location = REDIRECT_TO_NEW_VEHICLE_REGISTRATION
        }
        window.location = location;
    }

    const menuItems = () => {
        return {
            ADMIN_HOME: {
                label: I18n.t(getPath(i18nl.ADMIN_HOME)),
                icon: "pi pi-fw pi-id-card",
                command: () => {
                    window.location = REDIRECT_TO_ADMIN_HOME;
                }
            },
            LOGOUT_SC: {
                label: username,
                icon: "pi pi-fw pi-user",
                items: [
                    {
                        label: I18n.t(getPath(i18nl.LOGOUT)),
                        icon: "pi pi-fw pi-user-plus",
                        command: () => {
                            window.location = ROOT + "/logoutPage";
                        },
                    }
                ],
            },
            HOME: {
                label: I18n.t(getPath(i18nl.HOME)),
                icon: "pi pi-home",
                command: () => {
                    window.location = ROOT + "/";
                }
            },
            LOGIN: {
                label: I18n.t(getPath(i18nl.LOGIN)),
                icon: "pi pi-fw pi-sign-in",
                command: () => {
                    window.location = REDIRECT_TO_LOGIN;
                }
            },
            MY_APPLICATIONS: {
                label: I18n.t(getPath(i18nl.MY_APPLICATIONS)),
                icon: "pi pi-book",
                command: () => {
                    window.location = REDIRECT_TO_MY_APPLICATIONS;
                },
            },
            IDENTITY_MANAGEMENT: {
                label: I18n.t(getPath(i18nl.IDENTITY_MANAGEMENT)),
                icon: "pi pi-id-card",
                items: [
                    {
                        label: I18n.t(getPath(i18nl.NEW_IDENTITY)),
                        command: () => {
                            window.location = REDIRECT_TO_IDENTITY_MANAGEMENT;
                        },
                    },
                    {
                        label: I18n.t(getPath(i18nl.MODIFY_IDENTITY)),
                        command: () => {
                            window.location = REDIRECT_TO_MODIFY_IDENTITY;
                        },
                    },
                    {
                        label: I18n.t(getPath(i18nl.CANCEL_IDENTITY)),
                        command: () => {
                            window.location = REDIRECT_TO_CANCEL_IDENTITY;
                        },
                    },
                ],
            },
            HOW_TO_APPLY: {
                label: I18n.t(getPath(i18nl.HOW_TO_APPLY)),
                icon: 'pi pi-book',
                items: [
                    {
                        label: I18n.t(getPath(i18nl.INSTRUCTIONS_AND_DIRECTIVES)),
                        icon: 'pi pi-book',
                        command: () => {
                            window.location = REDIRECT_TO_FAQ;
                        },
                    },
                    {
                        label: I18n.t(getPath(i18nl.LEGAL_FORMS)),
                        icon: 'pi pi-calendar-plus',
                        command: () => {
                            window.location = REDIRECT_TO_TEMPLATE_DOCUMENTS;
                        }
                    }
                ]
            },
            LOGOUT: {
                label: username,
                icon: "pi pi-fw pi-user",
                items: [
                    {
                        label: I18n.t(getPath(i18nl.LOGOUT)),
                        icon: "pi pi-fw pi-user-plus",
                        command: () => {
                            window.location = ROOT + "/logoutPage";
                        },
                    },
                    {
                        label: I18n.t(getPath(i18nl.CHANGE_PASSWORD)),
                        icon: "pi pi-fw pi-user-minus",
                        command: () => {
                            window.location = REDIRECT_TO_CHANGE_PASSWORD;
                        },
                    },
                    {
                        label: I18n.t(getPath(i18nl.EDIT_ACCOUNT)),
                        icon: "pi pi-fw pi-user-minus",
                        command: () => {
                            window.location = REDIRECT_TO_EDIT_ACCOUNT;
                        },
                    },
                ],
            },
            DRIVER_LICENSE: {
                label: I18n.t(getPath(i18nl.DRIVER_LICENSE)),
                icon: "pi pi-id-card",
                command: () => {
                    window.location = REDIRECT_TO_NEW_DRIVER_LICENSE;
                },
            },
            DRIVER_LICENSE_DDL: {
                label: I18n.t(getPath(i18nl.DRIVER_LICENSE)),
                icon: "pi pi-id-card",
                items: [
                    {
                        label: I18n.t("label." + EUseCases.NEW),
                        command: () => {useCaseRedirect("DL", EUseCases.NEW)}
                    },
                    {
                        label: I18n.t("label." + EUseCases.RE_REGISTER),
                        command: () => {useCaseRedirect("DL", EUseCases.RE_REGISTER)}

                    },
                    {
                        label: I18n.t("label." + EUseCases.EXTEND),
                        command: () => {useCaseRedirect("DL", EUseCases.EXTEND)}

                    },
                    {
                        label: I18n.t("label." + EUseCases.EXPIRED),
                        command: () => {useCaseRedirect("DL", EUseCases.EXPIRED)}
                    },
                    {
                        label: I18n.t("label." + EUseCases.FOREIGN),
                        command: () => {useCaseRedirect("DL", EUseCases.FOREIGN)}
                    },
                    {
                        label: I18n.t("label." + EUseCases.REPLACE),
                        command: () => {useCaseRedirect("DL", EUseCases.REPLACE)}
                    },
                    {
                        label: I18n.t("label." + EUseCases.PARTLY_DAMAGED),
                        command: () => {useCaseRedirect("DL", EUseCases.PARTLY_DAMAGED)}
                    },
                    {
                        label: I18n.t("label." + EUseCases.MODIFICATION),
                        command: () => {useCaseRedirect("DL", EUseCases.MODIFICATION)}

                    },
                    {
                        label: I18n.t("label." + EUseCases.CANCEL_DOCUMENT),
                        command: () => {useCaseRedirect("DL", EUseCases.CANCEL_DOCUMENT)}

                    },
                    {
                        label: I18n.t("label." + EUseCases.UNCANCEL_DOCUMENT),
                        command: () => {useCaseRedirect("DL", EUseCases.UNCANCEL_DOCUMENT)}

                    },
                ]
            },
            CHECK_APPLICATION_STATUS: {
                label: I18n.t(getPath(i18nl.CHECK_APPLICATION_STATUS)),
                icon: "pi pi-book",
                command: () => {
                    window.location = REDIRECT_TO_MY_APPLICATIONS;
                },
            },
            BOOK_APPOINTMENT: {
                label: I18n.t(getPath(i18nl.BOOK_APPOINTMENT)),
                icon: "pi pi-calendar-plus",
                command: () => {
                    window.location = REDIRECT_TO_APPOINTMENT;
                },
            },
            MY_APPOINTMENTS: {
                label: I18n.t(getPath(i18nl.MY_APPOINTMENTS)),
                icon: "pi pi-database",
                command: () => {
                    window.location = REDIRECT_TO_MY_APPOINTMENTS;
                },
            },
            VEHICLE_WORKFLOW: {
                label: I18n.t(getPath(i18nl.VEHICLE_WORKFLOW)),
                icon: "pi pi-id-card",
                command: () => {
                    window.location = REDIRECT_TO_NEW_VEHICLE_REGISTRATION;
                },
            },
            VEHICLE_WORKFLOW_DDL: {
                label: I18n.t(getPath(i18nl.VEHICLE_WORKFLOW)),
                icon: "pi pi-id-card",
                items: [
                    {
                        label: I18n.t("label." + EUseCases.NEW),
                        command: () => {useCaseRedirect("VR", EUseCases.NEW)}
                    },
                    {
                        label: I18n.t("label." + EUseCases.RE_REGISTER),
                        command: () => {useCaseRedirect("VR", EUseCases.RE_REGISTER)}
                    },
                    {
                        label: I18n.t("label." + EUseCases.REPLACE),
                        command: () => {useCaseRedirect("VR", EUseCases.REPLACE)}
                    },
                    {
                        label: I18n.t("label." + EUseCases.PARTLY_DAMAGED),
                        command: () => {useCaseRedirect("VR", EUseCases.PARTLY_DAMAGED)}
                    },
                    {
                        label: I18n.t("label." + EUseCases.RESERVE_PLATE_NUMBER),
                        command: () => {useCaseRedirect("VR", EUseCases.RESERVE_PLATE_NUMBER)}
                    },
                    {
                        label: I18n.t("label." + EUseCases.CHANGE_OWNERSHIP),
                        items: [
                            {
                                label: I18n.t("label." + changeOwnershipSubUseCases.ADD_OWNER),
                                command: () => {useCaseRedirect("VR", EUseCases.CHANGE_OWNERSHIP, changeOwnershipSubUseCases.ADD_OWNER)}
                            },
                            {
                                label: I18n.t("label." + changeOwnershipSubUseCases.BY_AUCTION),
                                command: () => {useCaseRedirect("VR", EUseCases.CHANGE_OWNERSHIP, changeOwnershipSubUseCases.BY_AUCTION)}
                            },
                            {
                                label: I18n.t("label." + changeOwnershipSubUseCases.BY_COURT),
                                command: () => {useCaseRedirect("VR", EUseCases.CHANGE_OWNERSHIP, changeOwnershipSubUseCases.BY_COURT)}
                            },
                            {
                                label: I18n.t("label." + changeOwnershipSubUseCases.CHANGE_LEAD),
                                command: () => {useCaseRedirect("VR", EUseCases.CHANGE_OWNERSHIP, changeOwnershipSubUseCases.CHANGE_LEAD)}
                            },
                            {
                                label: I18n.t("label." + changeOwnershipSubUseCases.CHANGE_SHARE),
                                command: () => {useCaseRedirect("VR", EUseCases.CHANGE_OWNERSHIP, changeOwnershipSubUseCases.CHANGE_SHARE)}
                            },
                            {
                                label: I18n.t("label." + changeOwnershipSubUseCases.DELETE_OWNER),
                                command: () => {useCaseRedirect("VR", EUseCases.CHANGE_OWNERSHIP, changeOwnershipSubUseCases.DELETE_OWNER)}
                            },
                            {
                                label: I18n.t("label." + changeOwnershipSubUseCases.SALE_AND_BUY),
                                command: () => {useCaseRedirect("VR", EUseCases.CHANGE_OWNERSHIP, changeOwnershipSubUseCases.SALE_AND_BUY)}
                            },
                        ]
                    },
                    {
                        label: I18n.t("label." + EUseCases.EXCHANGE_REGISTRATION),
                        command: () => {useCaseRedirect("VR", EUseCases.EXCHANGE_REGISTRATION)}
                    },
                    {
                        label: I18n.t("label." + EUseCases.MODIFY_VEHICLE_DATA),
                        command: () => {useCaseRedirect("VR", EUseCases.MODIFY_VEHICLE_DATA)}
                    },
                    {
                        label: I18n.t("label." + EUseCases.EXPIRED),
                        command: () => {useCaseRedirect("VR", EUseCases.EXPIRED)}
                    },
                    {
                        label: I18n.t("label." + EUseCases.CANCEL),
                        command: () => {useCaseRedirect("VR", EUseCases.CANCEL)}
                    },
                ],
            },
            PLACEHOLDER: {
                label: ""
            },
        }

    };
    return {
        menuItems: menuItems
    }
}

