import {EApplicationType, EUseCases, ownerRoles} from "@mb-react/svarog-ui";
import {I18n} from "react-redux-i18n";
import {LEGAL_ENTITY_TYPE, NATURAL_PERSON_TYPE} from "../util/businesslogicUtil";

export const LEGAL_ENTITY = "LEGAL_ENTITY";
export const NATURAL_PERSON = "NATURAL_PERSON";
export const IDENTITY_STATE = "IDENTITY_STATE";
export const ACCOUNT_IDENTITY = "ACCOUNT_IDENTITY";

export function getApplicationTypeOptions() {
  return [
    {
      label: I18n.t("label." + EApplicationType.DL),
      value: EApplicationType.DL,
    },
    {
      label: I18n.t("label." + EApplicationType.VR),
      value: EApplicationType.VR,
    },
    // {
    //   label: I18n.t("label." + EApplicationType.IDENTITY),
    //   value: EApplicationType.IDENTITY
    // }
  ];
}

export function getOwnerRoles() {
  return [
    {
      label: I18n.t("label." + ownerRoles.BUYER),
      value: ownerRoles.BUYER
    },
    {
      label: I18n.t("label." + ownerRoles.SELLER),
      value: ownerRoles.SELLER
    },
  ]
}

export function getIdentityOptions() {
  return [
    {
      label: I18n.t("label." + LEGAL_ENTITY),
      value: LEGAL_ENTITY,
    },
    {
      label: I18n.t("label." + NATURAL_PERSON),
      value: NATURAL_PERSON,
    }
  ];
}

export function getAccountTypeOptions (){
  return  [
    { label: I18n.t("label." + NATURAL_PERSON_TYPE), value: NATURAL_PERSON },
    { label: I18n.t("label." + LEGAL_ENTITY_TYPE), value: LEGAL_ENTITY }
  ]
};

export function getLegalEntityType (){
  return [
    { label: I18n.t("label." + accountSubType.COMPANY), value: accountSubType.COMPANY },
    { label: I18n.t("label." + accountSubType.EMBASSY), value: accountSubType.EMBASSY },
    { label: I18n.t("label." + accountSubType.GOVERNMENT), value: accountSubType.GOVERNMENT },
    { label: I18n.t("label." + accountSubType.INTERNATIONAL_ORGANIZATION), value: accountSubType.INTERNATIONAL_ORGANIZATION },
    { label: I18n.t("label." + accountSubType.NATIONAL_ORGANIZATION), value: accountSubType.NATIONAL_ORGANIZATION }
  ]
}

export function getDlUseCases() {
  return [
    {
      label: I18n.t("label." + EUseCases.NEW),
      value: EUseCases.NEW
    },
    {
      label: I18n.t("label." + EUseCases.RE_REGISTER),
      value: EUseCases.RE_REGISTER
    },
    {
      label: I18n.t("label." + EUseCases.EXTEND),
      value: EUseCases.EXTEND
    },
    {
      label: I18n.t("label." + EUseCases.EXPIRED),
      value: EUseCases.EXPIRED
    },
    {
      label: I18n.t("label." + EUseCases.FOREIGN),
      value: EUseCases.FOREIGN
    },
    {
      label: I18n.t("label." + EUseCases.REPLACE),
      value: EUseCases.REPLACE
    },
    {
      label: I18n.t("label." + EUseCases.PARTLY_DAMAGED),
      value: EUseCases.PARTLY_DAMAGED
    },
    {
      label: I18n.t("label." + EUseCases.MODIFICATION),
      value: EUseCases.MODIFICATION
    },
    {
      label: I18n.t("label." + EUseCases.CANCEL_DOCUMENT),
      value: EUseCases.CANCEL_DOCUMENT
    },
    {
      label: I18n.t("label." + EUseCases.UNCANCEL_DOCUMENT),
      value: EUseCases.UNCANCEL_DOCUMENT
    }
  ];
}

export function getVRUseCases() {
  return [
    {
      label: I18n.t("label." + EUseCases.NEW),
      value: EUseCases.NEW
    },
    {
      label: I18n.t("label." + EUseCases.RE_REGISTER),
      value: EUseCases.RE_REGISTER
    },
    {
      label: I18n.t("label." + EUseCases.REPLACE),
      value: EUseCases.REPLACE
    },
    {
      label: I18n.t("label." + EUseCases.PARTLY_DAMAGED),
      value: EUseCases.PARTLY_DAMAGED
    },
    {
      label: I18n.t("label." + EUseCases.RESERVE_PLATE_NUMBER),
      value: EUseCases.RESERVE_PLATE_NUMBER
    },
    {
      label: I18n.t("label." + EUseCases.CHANGE_OWNERSHIP),
      value: EUseCases.CHANGE_OWNERSHIP
    },
    {
      label: I18n.t("label." + EUseCases.EXCHANGE_REGISTRATION),
      value: EUseCases.EXCHANGE_REGISTRATION
    },
    {
      label: I18n.t("label." + EUseCases.MODIFY_VEHICLE_DATA),
      value: EUseCases.MODIFY_VEHICLE_DATA
    },
    {
      label: I18n.t("label." + EUseCases.EXPIRED),
      value: EUseCases.EXPIRED
    },
    {
      label: I18n.t("label." + EUseCases.CANCEL),
      value: EUseCases.CANCEL
    }

  ];
}

export function getIdentityUseCases() {
  return [
    {
      label: I18n.t("label." + EUseCases.NEW),
      value: EUseCases.NEW
    }, {
      label: I18n.t("label." + EUseCases.MODIFICATION),
      value: EUseCases.MODIFICATION
    }
  ];
}

export function getDLAvailableStatesOptions() {
  return [
    {
      label: I18n.t("label.stateHandlingOK"),
      value: "OK"
    },
    {
      label: I18n.t("label.stateHandlingDL"),
      value: "DL"
    },
    {
      label: I18n.t("label.stateHandlingP"),
      value: "P"
    },
    {
      label: I18n.t("label.stateHandlingR"),
      value: "R"
    },
    {
      label: I18n.t("label.stateHandlingTD"),
      value: "TD"
    }
  ]
}

export function getVRAvailableStatesOptions() {
  return [
    {
      label: I18n.t("label.stateHandlingNU"),
      value: "NU"
    },
    {
      label: I18n.t("label.stateHandlingAI"),
      value: "AI"
    },
    {
      label: I18n.t("label.stateHandlingCI"),
      value: "CI"
    },
    {
      label: I18n.t("label.stateHandlingCDI"),
      value: "CDI"
    },
  ]
}

export const extendDLCategories = [
  "BE", "C", "C1", "C1E", "D", "D1", "D1E", "DE"
];

export const workflowNames = {
  ENROLLMENT: "enrollment",
  APPROVAL: "approval"
};

export const createPersonUseCases = [EUseCases.NEW, EUseCases.RE_REGISTER, EUseCases.FOREIGN];
export const ownershipUseCases = [EUseCases.CHANGE_OWNERSHIP /* todo add rest of the use cases */];

export const plateClasses = {
  FOREIGN: "FOREIGN",
  OLD: "OLD",
  NEW: "NEW"
};

export const plateTypes = {
  STANDARD: "01",
  UN: "02",
  CDA: "03",
  RC: "04",
  AO: "05",
  IO: "06",
  CD: "07",
  CC: "08",
  MOTORCYCLE: "09",
};

export const specialPlateTypes = {
  UN: "02",
  CDA: "03",
  RC: "04",
  AO: "05",
  IO: "06",
  CD: "07",
  CC: "08",
};

// export const plateCategories = {
//   PRIVATE: "01",
//   COMPANY: "02",
//   CD: "03",
//   CDA: "04",
//   CC: "05",
//   NO: "06",
//   GOVERNMENT: "07",
//   UN: "08",
//   RC: "09",
//   AO: "10",
//   IO: "11",
// };

export const otherPlateCategories = {
  COMPANY: "02",
  GOVERNMENT: "07",
  NO: "06",
  // todo add TEMPORARY_IMPORT?
};

export const plateUsages = {
  PRIVATE: "01",
  TAXI: "02",
  TRANSPORTER: "03",
  SPECIAL: "04",
  AGRICULTURE: "05",
  BUILDING: "06"
};

export const changeOwnershipSubUseCases = {
  ADD_OWNER: "ADD_OWNER",
  BY_AUCTION: "BY_AUCTION",
  BY_COURT: "BY_COURT",
  CHANGE_LEAD: "CHANGE_LEAD",
  CHANGE_SHARE: "CHANGE_SHARE",
  DELETE_OWNER: "DELETE_OWNER",
  SALE_AND_BUY: "SALE_AND_BUY",
  REMOVE_OWNER: "REMOVE_OWNER"
};

export const vehicleTypes = {
  VEHICLE: "VEHICLE",
  TRAILER: "TRAILER",
};

export const vehicleState = {
  REGISTERED: "Registered",
  CANCELED: "Canceled",
  CREATED: "Created",
  UNREGISTERED: "Unregistered"
};

export const accountSubType = {
  EMBASSY: "EMBASSY",
  COMPANY: "COMPANY",
  GOVERNMENT: "GOVERNMENT",
  NATIONAL_ORGANIZATION: "NATIONAL_ORGANIZATION",
  INTERNATIONAL_ORGANIZATION: "INTERNATIONAL_ORGANIZATION"
}

export const GOVERNMENT = "government"
export const COMPANY = "company"
export const NO = "no"
export const IO = "io"
export const EMBASSY = "embassy"

export const plateCategories = Object.freeze({
  SPECIAL: "special",
  MOTORCYCLE: "motorcycle",
  OTHER: "other",
  STANDARD: "standard"
})

export function getChangeOwnershipSubUseCases() {
  return [
    {
      label: I18n.t("label." + changeOwnershipSubUseCases.ADD_OWNER),
      value: changeOwnershipSubUseCases.ADD_OWNER
    },
    {
      label: I18n.t("label." + changeOwnershipSubUseCases.BY_AUCTION),
      value: changeOwnershipSubUseCases.BY_AUCTION
    },
    {
      label: I18n.t("label." + changeOwnershipSubUseCases.BY_COURT),
      value: changeOwnershipSubUseCases.BY_COURT
    },
    {
      label: I18n.t("label." + changeOwnershipSubUseCases.CHANGE_LEAD),
      value: changeOwnershipSubUseCases.CHANGE_LEAD
    },
    {
      label: I18n.t("label." + changeOwnershipSubUseCases.CHANGE_SHARE),
      value: changeOwnershipSubUseCases.CHANGE_SHARE
    },
    {
      label: I18n.t("label." + changeOwnershipSubUseCases.DELETE_OWNER),
      value: changeOwnershipSubUseCases.DELETE_OWNER
    },
    {
      label: I18n.t("label." + changeOwnershipSubUseCases.SALE_AND_BUY),
      value: changeOwnershipSubUseCases.SALE_AND_BUY
    }
  ];
}

const EMBASSY_CODE = "embassy";
const COMPANY_CODE = "company";
const GOVERNMENT_CODE = "government";
const IO_CODE = "IO";
const NO_CODE = "NO";

export function getTypeOptions() {
  return [
    {
      label: I18n.t("label.EMBASSY_CODE"),
      value: EMBASSY_CODE,
    },
    {
      label: I18n.t("label.COMPANY_CODE"),
      value: COMPANY_CODE,
    },
    {
      label: I18n.t("label.GOVERNMENT_CODE"),
      value: GOVERNMENT_CODE,
    },
    {
      label: I18n.t("label.IO_CODE"),
      value: IO_CODE,
    },
    {
      label: I18n.t("label.NO_CODE"),
      value: NO_CODE,
    }
  ];
}

export const cylinderTypes = [
  {
    label: "1",
    value: "1"
  },
  {
    label: "2",
    value: "2"
  },
  {
    label: "3",
    value: "3"
  },
  {
    label: "4",
    value: "4"
  },
  {
    label: "5",
    value: "5"
  },
  {
    label: "6",
    value: "6"
  },
  {
    label: "8",
    value: "8"
  },
  {
    label: "10",
    value: "10"
  },
  {
    label: "12",
    value: "12"
  }
];

export const motorcycleCylinderTypes = [
  {
    label: "1",
    value: "1"
  },
  {
    label: "2",
    value: "2"
  },
  {
    label: "3",
    value: "3"
  },
  {
    label: "4",
    value: "4"
  }
];

export const sedanCylinderTypes = [
  {
    label: "3",
    value: "3"
  },
  {
    label: "4",
    value: "4"
  },
  {
    label: "5",
    value: "5"
  },
  {
    label: "6",
    value: "6"
  },
  {
    label: "8",
    value: "8"
  },
  {
    label: "12",
    value: "12"
  },
  {
    label: "16",
    value: "16"
  }
];

export const stationCylinderTypes = [
  {
    label: "3",
    value: "3"
  },
  {
    label: "4",
    value: "4"
  },
  {
    label: "6",
    value: "6"
  },
  {
    label: "8",
    value: "8"
  },
  {
    label: "12",
    value: "12"
  },
  {
    label: "16",
    value: "16"
  }
];