import React, {useState} from "react";
import {classNames, Menubar} from "@mb-react/mb-react-core";
import {I18n} from "react-redux-i18n";
import {getPath, i18nl} from "../../../../constants/i18n";
import {Link} from "react-router-dom";
import {routes} from "../../../../constants/router";

const DevMenu = () => {
    let items = [
        {
            label: 'Components',
            icon: "pi pi-fw pi-cog",
            items: [
                {
                    label: I18n.t(getPath(i18nl.ADMIN_HOME)),
                    items: [
                        {
                            label: I18n.t(getPath(i18nl.ADMIN_HOME)),
                            template: (item, options) => {
                                return (
                                    <div className={options.className}>
                                        <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                        <span className={options.labelClassName}><Link
                                            to={routes.ADMIN_HOME}>{item.label}</Link></span>
                                    </div>
                                );
                            }
                        },
                        {
                            label: I18n.t(getPath(i18nl.CONTENT_MANAGEMENT_HOME)),
                            template: (item, options) => {
                                return (
                                    <div className={options.className}>
                                        <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                        <span className={options.labelClassName}><Link
                                            to={routes.CONTENT_MANAGEMENT_HOME}>{item.label}</Link></span>
                                    </div>
                                );
                            }
                        },
                        {
                            label: I18n.t(getPath(i18nl.ADMIN_ACCOUNT_HOME)),
                            template: (item, options) => {
                                return (
                                    <div className={options.className}>
                                        <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                        <span className={options.labelClassName}><Link
                                            to={routes.ADMIN_ACCOUNT_HOME}>{item.label}</Link></span>
                                    </div>
                                );
                            }
                        },
                        {
                            label: I18n.t(getPath(i18nl.ADMIN_ACCOUNT_OVERVIEW)),
                            template: (item, options) => {
                                return (
                                    <div className={options.className}>
                                        <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                        <span className={options.labelClassName}><Link
                                            to={routes.ADMIN_ACCOUNT_OVERVIEW}>{item.label}</Link></span>
                                    </div>
                                );
                            }
                        },
                        {
                            label: I18n.t(getPath(i18nl.ADMIN_ACCOUNT_ADMINISTRATION)),
                            template: (item, options) => {
                                return (
                                    <div className={options.className}>
                                        <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                        <span className={options.labelClassName}><Link
                                            to={routes.ADMIN_ACCOUNT_ADMINISTRATION}>{item.label}</Link></span>
                                    </div>
                                );
                            }
                        }

                    ]
                },
                {
                    label: I18n.t(getPath("PLACE_HOLDER")),
                    template: (item, options) => {
                        return (
                            <div className={options.className}>
                                <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                <span className={options.labelClassName}><Link
                                    to={routes.REGISTER_ACCOUNT}>{item.label}</Link></span>
                            </div>
                        );
                    }
                },
                {
                    label: I18n.t(getPath(i18nl.PRINT_REPORTS)),
                    items: [
                        {
                            label: I18n.t(getPath(i18nl.PRINT_ACCOUNT_REPORT)),
                            template: (item, options) => {
                                return (
                                    <div className={options.className}>
                                        <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                        <span className={options.labelClassName}><Link
                                            to={routes.PRINT_ACCOUNT_REPORT}>{item.label}</Link></span>
                                    </div>
                                );
                            }
                        },
                        {
                            label: I18n.t(getPath(i18nl.PRINT_APPLICATION_REPORT)),
                            template: (item, options) => {
                                return (
                                    <div className={options.className}>
                                        <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                        <span className={options.labelClassName}><Link
                                            to={routes.PRINT_APPLICATION_REPORT}>{item.label}</Link></span>
                                    </div>
                                );
                            }
                        }
                    ]
                },
                {
                    label: I18n.t(getPath(i18nl.LOGIN)),
                    template: (item, options) => {
                        return (
                            <div className={options.className}>
                                <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                <span className={options.labelClassName}><Link
                                    to={routes.LOGIN}>{item.label}</Link></span>
                            </div>
                        );
                    }
                },
                {
                    label: I18n.t(getPath(i18nl.REGISTER_ACCOUNT)),
                    template: (item, options) => {
                        return (
                            <div className={options.className}>
                                <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                <span className={options.labelClassName}><Link
                                    to={routes.REGISTER_ACCOUNT}>{item.label}</Link></span>
                            </div>
                        );
                    }
                },
                {
                    label: I18n.t(getPath(i18nl.ACCOUNT_RECOVERY)),
                    template: (item, options) => {
                        return (
                            <div className={options.className}>
                                <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                <span className={options.labelClassName}><Link
                                    to={routes.ACCOUNT_RECOVERY}>{item.label}</Link></span>
                            </div>
                        );
                    }
                },
                {
                    label: I18n.t(getPath(i18nl.CHANGE_PASSWORD)),
                    template: (item, options) => {
                        return (
                            <div className={options.className}>
                                <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                <span className={options.labelClassName}><Link
                                    to={routes.CHANGE_PASSWORD}>{item.label}</Link></span>
                            </div>
                        );
                    }
                },
                {
                    label: I18n.t(getPath(i18nl.FORGOT_USERNAME_PASSWORD)),
                    template: (item, options) => {
                        return (
                            <div className={options.className}>
                                <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                <span className={options.labelClassName}><Link
                                    to={routes.FORGOT_PASSWORD}>{item.label}</Link></span>
                            </div>
                        );
                    }
                },
                {
                    label: I18n.t(getPath(i18nl.EDIT_ACCOUNT)),
                    template: (item, options) => {
                        return (
                            <div className={options.className}>
                                <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                <span className={options.labelClassName}><Link
                                    to={routes.EDIT_ACCOUNT}>{item.label}</Link></span>
                            </div>
                        );
                    }
                },
                {
                    label: I18n.t(getPath(i18nl.CREATE_DRIVER_LICENSE)),
                    template: (item, options) => {
                        return (
                            <div className={options.className}>
                                <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                <span className={options.labelClassName}><Link to={routes.CREATE_DL}>{item.label}</Link></span>
                            </div>
                        );
                    }
                },
                {
                    label: I18n.t(getPath(i18nl.MY_APPLICATIONS)),
                    template: (item, options) => {
                        return (
                            <div className={options.className}>
                                <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                <span className={options.labelClassName}><Link
                                    to={routes.MY_APPLICATION}>{item.label}</Link></span>
                            </div>
                        );
                    }
                },
                {
                    label: I18n.t(getPath(i18nl.BOOK_APPOINTMENT)),
                    template: (item, options) => {
                        return (
                            <div className={options.className}>
                                <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                <span className={options.labelClassName}><Link
                                    to={routes.BOOK_APPOINTMENT}>{item.label}</Link></span>
                            </div>
                        );
                    }
                },
                {
                    label: I18n.t(getPath(i18nl.MY_APPOINTMENTS)),
                    template: (item, options) => {
                        return (
                            <div className={options.className}>
                                <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                <span className={options.labelClassName}><Link
                                    to={routes.MY_APPOINTMENTS}>{item.label}</Link></span>
                            </div>
                        );
                    }
                },
                {
                    label: I18n.t(getPath(i18nl.VEHICLE_WORKFLOW)),
                    template: (item, options) => {
                        return (
                            <div className={options.className}>
                                <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                <span className={options.labelClassName}><Link
                                    to={routes.VEHICLE_WORKFLOW}>{item.label}</Link></span>
                            </div>
                        );
                    }
                },
                {
                    label: I18n.t(getPath(i18nl.HOW_TO_APPLY)),
                    items: [
                        {
                            label: I18n.t(getPath(i18nl.INSTRUCTIONS_AND_DIRECTIVES)),
                            template: (item, options) => {
                                return (
                                    <div className={options.className}>
                                        <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                        <span className={options.labelClassName}><Link
                                            to={routes.FAQ}>{item.label}</Link></span>
                                    </div>
                                );
                            }
                        },
                        {
                            label: I18n.t(getPath(i18nl.LEGAL_FORMS)),
                            template: (item, options) => {
                                return (
                                    <div className={options.className}>
                                        <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                        <span className={options.labelClassName}><Link
                                            to={routes.TEMPLATE_DOCUMENTS}>{item.label}</Link></span>
                                    </div>
                                );
                            }
                        }
                    ]
                },
                {
                    label: I18n.t(getPath(i18nl.IDENTITY_MANAGEMENT)),
                    items: [{
                        label: I18n.t(getPath(i18nl.NEW_IDENTITY)),
                        template: (item, options) => {
                            return (
                                <div className={options.className}>
                                    <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                    <span className={options.labelClassName}><Link
                                        to={routes.IDENTITY_MANAGEMENT}>{item.label}</Link></span>
                                </div>
                            );
                        }
                    },
                        {
                            label: I18n.t(getPath(i18nl.MODIFY_IDENTITY)),
                            template: (item, options) => {
                                return (
                                    <div className={options.className}>
                                        <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                        <span className={options.labelClassName}><Link
                                            to={routes.MODIFY_IDENTITY}>{item.label}</Link></span>
                                    </div>
                                );
                            }
                        },
                        {
                            label: I18n.t(getPath(i18nl.CANCEL_IDENTITY)),
                            template: (item, options) => {
                                return (
                                    <div className={options.className}>
                                        <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                        <span className={options.labelClassName}><Link
                                            to={routes.CANCEL_IDENTITY}>{item.label}</Link></span>
                                    </div>
                                );
                            }
                        }
                    ]
                },
                {
                    label: I18n.t(getPath(i18nl.ERROR)),
                    items: [
                        {
                            label: "403",
                            template: (item, options) => {
                                return (
                                    <div className={options.className}>
                                        <span className={classNames(options.iconClassName, 'pi pi-sign-in')}></span>
                                        <span className={options.labelClassName}><Link
                                            to={routes.ERROR_403}>{"403"}</Link></span>
                                    </div>
                                );
                            }
                        },
                    ]
                },
            ]
        }
    ];

    const start = <div>DEVELOPMENT MODE</div>

    return (
        <div>
            <div className="card">
                <Menubar model={items} start={start} className="devMenu"/>
            </div>
        </div>
    );
};

export default DevMenu;
