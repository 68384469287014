import { genericFormFieldNames } from "@mb-react/krg-getid-form-data-common"
import {
  getFormValues, getFromAppStore
} from "@mb-react/mb-react-core"
import { formFieldNames } from "@mb-react/svarog-ui"
import { useSelector } from "react-redux"
import { formFieldsMeta, forms } from "../../../../../../constants/form"
import { isMandatory, isReadOnly } from "../../../../../../util/businesslogicUtil"
import { formatDate } from "../../../../../../util/dateUtil"
import {useMemo} from "react";
import {ACCOUNT_IDENTITY} from "../../../../../../constants/application";

export const useGetResidenceDetailsFieldsSettings = (props) => {
  const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION))
  let addressDataFormValues = useSelector((state) => getFormValues(state, props.formName))
  const accountIdentity = useSelector(state => getFromAppStore(state, ACCOUNT_IDENTITY));

  let formSuffix = props.formNameSufix ? props.formNameSufix : ""

  let personalInformationFormValues = useSelector((state) => getFormValues(state, forms.PERSONAL_INFORMATION + formSuffix))

  addressDataFormValues = {...addressDataFormValues, ...personalInformationFormValues, ...formValues}

  let today = new Date();

  let years125Before = new Date();
  years125Before.setFullYear(years125Before.getFullYear() - 125);

  const handleResidenceCardNumberSettings = useMemo(() => {
    let addressDataProps = {
      [genericFormFieldNames.RESIDENCE_CARD_NUMBER]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(addressDataFormValues, genericFormFieldNames.RESIDENCE_CARD_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        },
        required: isMandatory(addressDataFormValues, genericFormFieldNames.RESIDENCE_CARD_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return addressDataProps
  }, [addressDataFormValues[genericFormFieldNames.ADDRESS_BUILDING_CLASS]])

  const handleResidenceCardDoISettings = useMemo(() => {
    let addressDataProps = {
      [genericFormFieldNames.RESIDENCE_CARD_DATE_OF_ISSUE]: {
        [formFieldNames.FIELD_PROPS]: {
          minDate: years125Before, maxDate: today,
          dateFormat: formValues && formValues[genericFormFieldNames.RESIDENCE_CARD_DATE_OF_ISSUE] && formatDate(formValues[genericFormFieldNames.RESIDENCE_CARD_DATE_OF_ISSUE]),
          readOnly: isReadOnly(addressDataFormValues, genericFormFieldNames.RESIDENCE_CARD_DATE_OF_ISSUE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        },
        required: isMandatory(addressDataFormValues, genericFormFieldNames.RESIDENCE_CARD_DATE_OF_ISSUE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return addressDataProps
  }, [addressDataFormValues[genericFormFieldNames.ADDRESS_BUILDING_CLASS]])

  const handleResidenceCertificateSettings = useMemo(() => {
    let addressDataProps = {
      [genericFormFieldNames.RESIDENCE_CERTIFICATE_NUMBER]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(addressDataFormValues, genericFormFieldNames.RESIDENCE_CERTIFICATE_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        },
        required: isMandatory(addressDataFormValues, genericFormFieldNames.RESIDENCE_CERTIFICATE_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return addressDataProps
  }, [addressDataFormValues[genericFormFieldNames.ADDRESS_BUILDING_CLASS]])

  const handleResidenceCertificateDoISettings = useMemo(() => {
    let addressDataProps = {
      [genericFormFieldNames.RESIDENCE_CERTIFICATE_DATE_OF_ISSUE]: {
        [formFieldNames.FIELD_PROPS]: {
          minDate: years125Before, maxDate: today,
          dateFormat: formValues && formValues[genericFormFieldNames.RESIDENCE_CERTIFICATE_DATE_OF_ISSUE] && formatDate(formValues[genericFormFieldNames.RESIDENCE_CERTIFICATE_DATE_OF_ISSUE]),
          readOnly: isReadOnly(addressDataFormValues, genericFormFieldNames.RESIDENCE_CERTIFICATE_DATE_OF_ISSUE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        },
        required: isMandatory(addressDataFormValues, genericFormFieldNames.RESIDENCE_CERTIFICATE_DATE_OF_ISSUE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return addressDataProps
  }, [addressDataFormValues[genericFormFieldNames.ADDRESS_BUILDING_CLASS]])

  const handleResidencePermitNumberSettings = useMemo(() => {
    let addressDataProps = {
      [genericFormFieldNames.RESIDENCE_PERMIT_NUMBER]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(addressDataFormValues, genericFormFieldNames.RESIDENCE_PERMIT_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        },
        required: isMandatory(addressDataFormValues, genericFormFieldNames.RESIDENCE_PERMIT_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return addressDataProps
  }, [addressDataFormValues[formFieldNames.NATIONALITY]])

  const handleResidencePermitDoISettings = useMemo(() => {
    let addressDataProps = {
      [genericFormFieldNames.RESIDENCE_PERMIT_ISSUE_DATE]: {
        [formFieldNames.FIELD_PROPS]: {
          minDate: years125Before, maxDate: today,
          dateFormat: formValues && formValues[genericFormFieldNames.RESIDENCE_PERMIT_ISSUE_DATE] && formatDate(formValues[genericFormFieldNames.RESIDENCE_PERMIT_ISSUE_DATE]),
          readOnly: isReadOnly(addressDataFormValues, genericFormFieldNames.RESIDENCE_PERMIT_ISSUE_DATE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        },
        required: isMandatory(addressDataFormValues, genericFormFieldNames.RESIDENCE_PERMIT_ISSUE_DATE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW], accountIdentity),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return addressDataProps
  }, [addressDataFormValues[formFieldNames.NATIONALITY]])


  return {...handleResidenceCardNumberSettings, ...handleResidenceCardDoISettings, ...handleResidenceCertificateSettings, ...handleResidenceCertificateDoISettings,
  ...handleResidencePermitNumberSettings, ...handleResidencePermitDoISettings}
}