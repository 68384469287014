import { getFromAppStore } from '@mb-react/mb-react-core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { TRG, getPath, i18nl } from '../../../constants/i18n';
import CreateApplicationWorkflow from '../../entries/application/CreateApplicationWorkflow';
import VehicleApplicationWorkflow from "../workflowTasks/vehicle/VehicleApplicationWorkflow";
import MyApplicationsTable from './MyApplicationsTable';
import CreateIdentityManagementWorkflow from "../../entries/application/CreateIdentityManagementWorkflow";
import CancelIdentityManagementWorkflow from "../../entries/application/CancelIdentityManagementWorkflow";
import ModifyIdentityManagementWorkflow from "../../entries/application/ModifyIdentityManagementWorkflow";

const MyApplications = () => {
    const applicationsFromStore = useSelector(state => getFromAppStore(state, 'fetchedApplication'))

    const startWorkflow = () => {
        if(applicationsFromStore){
            switch (applicationsFromStore.applicationType) {
                case "VR":
                    return <VehicleApplicationWorkflow />
                case "IM":
                    return checkIMUseCase(applicationsFromStore.useCase);
                default:
                    return <CreateApplicationWorkflow />
            }

        }
    }

    const checkIMUseCase = (useCase) => {
        switch (useCase){
            case "CANCEL":
                return <CancelIdentityManagementWorkflow formNameSufix={'Source'}/>
            case "MODIFY":
                return <ModifyIdentityManagementWorkflow formNameSufix={'Source'}/>
            default:
                return <CreateIdentityManagementWorkflow formNameSufix={'Source'}/>
        }
    }


    return (
        <div className='card navigation'>
            {applicationsFromStore ? startWorkflow()
                : <div className="card" style={{ padding: '1rem' }}>
                    <h5><Translate value={getPath(i18nl.MY_APPLICATIONS, TRG.LABEL)} /></h5>
                    <MyApplicationsTable />
                </div>}
        </div>
    )
}
export default MyApplications