import {genericFormFieldNames} from "@mb-react/krg-getid-form-data-common";
import {isRequired} from "@mb-react/svarog-ui";
import { getFormValues } from "@mb-react/mb-react-core";
import { useSelector } from "react-redux";
import {useGlobalValidation} from "../../../../../../hooks/useGlobalValidation";
import {forms} from "../../../../../../constants/form";

export const applicationDataValidation = () => {
  const globalValidation = useGlobalValidation()
  const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION_DATA));
  const errors = {};

  const validate = (values) => {
    if (!values) {
      errors.validation = 'Form values undefine!'
      return errors;
    }

    values = {...values, ...formValues}

    if (globalValidation.isMandatory(values, genericFormFieldNames.CONTRACT_NUMBER)) {
      isRequired(values, genericFormFieldNames.CONTRACT_NUMBER, errors);
    }
    if (globalValidation.isMandatory(values, genericFormFieldNames.CONTRACT_DATE)) {
      isRequired(values, genericFormFieldNames.CONTRACT_DATE, errors);
    }
    return errors;
  }
  return {validate};
};