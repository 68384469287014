import React, { Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import ServiceTask from './ServiceTask'
import LocationProviderTask from './LocationProviderTask'
import {completeStep, NavButtons, Toast} from '@mb-react/mb-react-core'
import { useDispatch, useSelector } from 'react-redux'
import { getFromAppStore, nextStep } from '@mb-react/mb-react-core'
import {APPLICATION_STEPS, appStoreFields, wizardSteps} from '../../../../constants/store'
import { I18n } from 'react-redux-i18n'
import NavButtonsLocal from "../../../reactCoreComponents/NavButtonsLocal";

const ServiceProviderTask = () => {
  let selectedProvider = useSelector((state) => getFromAppStore(state, appStoreFields.selectedProvider))
  let dispatch = useDispatch();
  const toast = useRef(null);
  const onNext = () => {
    if (selectedProvider) {
        dispatch(completeStep(wizardSteps.STEP1))
        dispatch(nextStep())
    } else {
      toast.current.show({ severity: 'error', summary: I18n.t("label.error"), detail: I18n.t("label.selectLocationAndProvider"), life: 3000 });
    }
  }
  return (
    <Fragment>
      <Toast ref={toast} position="top-right" />
      <div className="grid">
        <div className="col-12 xl:col-6">
          <ServiceTask />
        </div>
        <div className='col-12 xl:col-6'>
          <LocationProviderTask />
        </div>
      </div>
      <NavButtonsLocal firstStep customOnNext={() => onNext()} />
    </Fragment>
  )
}
ServiceProviderTask.propTypes = {
  dispatch: PropTypes.any,
  allServices: PropTypes.any,
  selectedService: PropTypes.any,
  selectedProvider: PropTypes.any
}
export default ServiceProviderTask
