import React, {useEffect, useRef} from "react";
import {getFormValues, Messages} from "@mb-react/mb-react-core";
import {useSelector} from "react-redux";
import {I18n, Translate} from "react-redux-i18n";
import {VEHICLE_DATA_STEP} from "../../../../../constants/store";
import {useHandleWorkflowSettings} from "../../../../entries/application/handleWorkflowSettings";
import {forms} from "../../../../../constants/form";
import {TemporaryPlateData, VehicleGeneralData} from "@mb-react/krg-getid-form-data-common";
import {vehicleTemporaryPlatesFieldSettings} from "./vehicleDataSettings/vehicleTemporaryPlatesFieldSettings";
import {useGetVehicleGeneralFieldsSettings} from "./vehicleDataSettings/vehicleGeneralFieldSettings";
import {formFieldNames} from "@mb-react/svarog-ui";
import {chassisNumberValidation} from "../../../../../util/util";
import {isUseCaseNEW} from "../../../../../util/businesslogicUtil";

const VehicleIdentificationTask = (props) => {
    const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION));
    const useCaseFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)["useCase"]);
    const docTypeFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)["documentType"]);
    const handleWorkflowSettings = useHandleWorkflowSettings();
    const guidanceMessage1 = useRef(null);
    const chassisMessage = useRef(null);

    const vehicleDataFormValues = useSelector((state) => getFormValues(state, props.formName))
    let chassisWarning = chassisNumberValidation(vehicleDataFormValues?.[formFieldNames.CHASSIS_NUMBER], vehicleDataFormValues?.[formFieldNames.CHASSIS_NUMBER]?.length)

    useEffect(() => {
        guidanceMessage1.current.show({
            severity: 'info', sticky: true, content: (
                <React.Fragment className="mb-1">
                    <i className="pi pi-info p-2"
                       style={{
                           borderRadius: '50%',
                           backgroundColor: 'var(--surface-b)',
                           color: 'var(--surface-d)'
                       }}></i>
                    <div className="ml-2">
                        <Translate value={"message.vehicleDataGuidanceMessage1"}/>
                    </div>
                </React.Fragment>
            )
        });
    }, []);

    useEffect(() => {
        chassisMessage.current.clear();
        if (chassisWarning) {
            chassisMessage.current.replace({
                severity: 'warn', sticky: true, content: (
                    <React.Fragment className="mb-1">
                        <i className="pi pi-info p-2"
                           style={{
                               borderRadius: '50%',
                               backgroundColor: 'var(--surface-b)',
                               color: 'var(--surface-d)'
                           }}></i>
                        <div className="ml-2">
                            <Translate value={chassisWarning}/>
                        </div>
                    </React.Fragment>
                )
            });
        }
    }, [chassisWarning]);


    let sectionsToRender = handleWorkflowSettings.vehicleDataStepsToRender

    return (
        <div className="card">
            <Messages ref={guidanceMessage1}/>
            <Messages ref={chassisMessage}/>
            {
                useCaseFromStore && docTypeFromStore &&
                <div className={"useCaseHeader"}><h5>{I18n.t("label.useCaseHeader", {
                    documentType: I18n.t("label." + docTypeFromStore),
                    useCase: I18n.t("label." + useCaseFromStore)
                })}</h5></div>
            }
            <h5><Translate value="label.vehicleData"/></h5>
            <hr className="sectionSeparator"/>
            <div className="grid">
                {sectionsToRender.includes(VEHICLE_DATA_STEP.VEHICLE_DATA) &&
                    <VehicleGeneralData {...useGetVehicleGeneralFieldsSettings(props)} />}
            </div>

            {isUseCaseNEW(formValues) &&
                <>
                    <h5><Translate value="label.temporaryPlateData"/></h5>
                    <hr className="sectionSeparator"/>
                    <div className="grid">
                        {sectionsToRender.includes(VEHICLE_DATA_STEP.VEHICLE_DATA) &&
                            <TemporaryPlateData {...vehicleTemporaryPlatesFieldSettings(props)} />}
                    </div>
                </>}
        </div>
    )
};
export default VehicleIdentificationTask;