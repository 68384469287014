import { changeOwnershipSubUseCasesList, genericFormFieldNames } from "@mb-react/krg-getid-form-data-common";
import { FormElement, addInitialValueToForm, fieldType, getFormValues } from "@mb-react/mb-react-core";
import React, {useEffect, useState} from "react";
import { useForm } from 'react-final-form';
import { useDispatch, useSelector } from "react-redux";
import { I18n, Translate } from "react-redux-i18n";
import { formFieldsVRQuestionnaire, forms } from "../../../../constants/form";
import { NO, YES, yesNoSelect } from "../../../../constants/general";
import {isLoggedAccountLegalEntity} from "../../../../util/businesslogicUtil";

export function useHandleVRQuestionariesComponent() {
    const dispatch = useDispatch()
    const formValues = useSelector((state) => getFormValues(state, forms.QUESTIONNAIRES_VEHICLE));
    const [isLostVRRoN, setIsLostVRRoN] = useState(false);
    const [isDamagedVRRoN, setIsDamagedVRRoN] = useState(false);
    const [isExpiredVRRoN, setIsExpiredVRRoN] = useState(false);
    const [isFirstTimeApplyVRRoN, setIsFirstTimeApplyVRRoN] = useState(false);
    const [isOldVRRoN, setIsOldVRRoN] = useState(false);
    const form = useForm()

    //reset the change ownership sub useCase
    useEffect(() => {
        if(!formValues?.[formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR] ||
          formValues[formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR] === NO) {
            dispatch(addInitialValueToForm(forms.APPLICATION, genericFormFieldNames.CHANGE_OWNERSHIP_SUB_USE_CASE))
        }
    }, [formValues])

    useEffect(() => {
        form.change(formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR, "");
    }, [formValues?.[formFieldsVRQuestionnaire.IS_NEW_VR],
        formValues?.[formFieldsVRQuestionnaire.IS_REPLACE_VR],
        formValues?.[formFieldsVRQuestionnaire.IS_MODIFY_VR],
        formValues?.[formFieldsVRQuestionnaire.IS_EXCHANGE_VR],
        formValues?.[formFieldsVRQuestionnaire.IS_RESERVE_PLATE_VR],
        formValues?.[formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR]])

    const isNewVR = () => {
        return formValues?.[formFieldsVRQuestionnaire.IS_NEW_VR] && formValues[formFieldsVRQuestionnaire.IS_NEW_VR] === YES
    };

    const noNewVR = () => {
        return formValues?.[formFieldsVRQuestionnaire.IS_NEW_VR] && formValues[formFieldsVRQuestionnaire.IS_NEW_VR] === NO
    };

    const isFirstTimeApplyVR = () => {
        return formValues?.[formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR] && formValues[formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR] === formFieldsVRQuestionnaire.FIRST_TIME_APPLY_VR;
    };

    const isOldDocumentVR = () => {
        return formValues?.[formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR] && formValues[formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR] === formFieldsVRQuestionnaire.OLD_VR;
    };

    const isForeignDocumentVR = () => {
        return formValues?.[formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR] && formValues[formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR] === formFieldsVRQuestionnaire.FOREIGN_VR;
    };

    const replaceVR = () => {
        return formValues?.[formFieldsVRQuestionnaire.IS_REPLACE_VR] && formValues[formFieldsVRQuestionnaire.IS_REPLACE_VR] === YES
    };

    const noReplaceVR = () => {
        return formValues?.[formFieldsVRQuestionnaire.IS_REPLACE_VR] && formValues[formFieldsVRQuestionnaire.IS_REPLACE_VR] === NO
    };

    const isLostDocumentVR = () => {
        return formValues?.[formFieldsVRQuestionnaire.LOST_VR] && formValues[formFieldsVRQuestionnaire.LOST_VR] === true;
    };

    const isDamagedDocument2VR = () => {
        return formValues?.[formFieldsVRQuestionnaire.DAMAGED_VR2] && formValues[formFieldsVRQuestionnaire.DAMAGED_VR2] === true;
    };

    const isExpiredDocumentVR = () => {
        return formValues?.[formFieldsVRQuestionnaire.EXPIRED_VR] && formValues[formFieldsVRQuestionnaire.EXPIRED_VR] === true;
    };

    const modifyDocumentVR = () => {
        return formValues?.[formFieldsVRQuestionnaire.IS_MODIFY_VR] && formValues[formFieldsVRQuestionnaire.IS_MODIFY_VR] === YES
    };

    const noModifyDocumentVR = () => {
        return formValues?.[formFieldsVRQuestionnaire.IS_MODIFY_VR] && formValues[formFieldsVRQuestionnaire.IS_MODIFY_VR] === NO
    };
    const isExpiredDocument3 = () => {
        return formValues?.[formFieldsVRQuestionnaire.EXPIRED_VR3] && formValues[formFieldsVRQuestionnaire.EXPIRED_VR3] === true;
    };
    const exchangeDocumentVR = () => {
        return formValues?.[formFieldsVRQuestionnaire.IS_EXCHANGE_VR] && formValues[formFieldsVRQuestionnaire.IS_EXCHANGE_VR] === YES
    };

    const noExchangeDocumentVR = () => {
        return formValues?.[formFieldsVRQuestionnaire.IS_EXCHANGE_VR] && formValues[formFieldsVRQuestionnaire.IS_EXCHANGE_VR] === NO
    };
    const reserveDocumentVR = () => {
        return formValues?.[formFieldsVRQuestionnaire.IS_RESERVE_PLATE_VR] && formValues[formFieldsVRQuestionnaire.IS_RESERVE_PLATE_VR] === YES
    };
    const noReserveDocumentVR = () => {
        return formValues?.[formFieldsVRQuestionnaire.IS_RESERVE_PLATE_VR] && formValues[formFieldsVRQuestionnaire.IS_RESERVE_PLATE_VR] === NO
    };
    const changeOwnershipDocumentVR = () => {
        return formValues?.[formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR] && formValues[formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR] === YES
    };
    const noChangeOwnershipDocumentVR = () => {
        return formValues?.[formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR] && formValues[formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR] === NO
    };
    const cancelDocumentVR = () => {
        return formValues?.[formFieldsVRQuestionnaire.IS_CANCEL_VR] && formValues[formFieldsVRQuestionnaire.IS_CANCEL_VR] === YES
    };
    const noCancelDocumentVR = () => {
        return formValues?.[formFieldsVRQuestionnaire.IS_CANCEL_VR] && formValues[formFieldsVRQuestionnaire.IS_CANCEL_VR] === NO
    };


    const onIsNewDocumentChange = (e) => {

        if (e.value === NO) {
            form.change(formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR, "");
        }

        form.change(formFieldsVRQuestionnaire.IS_REPLACE_VR, "");
        form.change(formFieldsVRQuestionnaire.DAMAGED_VR2, "");
        form.change(formFieldsVRQuestionnaire.DAMAGED_VR, "");
        form.change(formFieldsVRQuestionnaire.LOST_VR, "");
        form.change(formFieldsVRQuestionnaire.EXPIRED_VR, "");
        form.change(formFieldsVRQuestionnaire.IS_MODIFY_VR, "");
        form.change(formFieldsVRQuestionnaire.IS_EXCHANGE_VR, "");
        form.change(formFieldsVRQuestionnaire.EXPIRED_VR3, "");
        form.change(formFieldsVRQuestionnaire.IS_RESERVE_PLATE_VR, "");
        form.change(formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR, "");
        form.change(formFieldsVRQuestionnaire.IS_CANCEL_VR, "");

    };

    const isReplaceClearForm = () => {
        form.change(formFieldsVRQuestionnaire.LOST_VR, "");
        form.change(formFieldsVRQuestionnaire.DAMAGED_VR, "");
        form.change(formFieldsVRQuestionnaire.EXPIRED_VR, "");
        form.change(formFieldsVRQuestionnaire.IS_MODIFY_VR, "");
        form.change(formFieldsVRQuestionnaire.EXPIRED_VR3, "");
        form.change(formFieldsVRQuestionnaire.IS_EXCHANGE_VR, "");
        form.change(formFieldsVRQuestionnaire.IS_RESERVE_PLATE_VR, "");
        form.change(formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR, "");

    }
    const isModifyClearForm = () => {
        form.change(formFieldsVRQuestionnaire.EXPIRED_VR3, "");
        form.change(formFieldsVRQuestionnaire.IS_EXCHANGE_VR, "");
        form.change(formFieldsVRQuestionnaire.IS_RESERVE_PLATE_VR, "");
        form.change(formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR, "");
    }

    const isExchangeClearForm = () => {
        form.change(formFieldsVRQuestionnaire.IS_RESERVE_PLATE_VR, "");
        form.change(formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR, "");

    }
    const isReserveClearForm = () => {
        form.change(formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR, "");
        form.change(formFieldsVRQuestionnaire.IS_CANCEL_VR, "");
    }

    const isChangeClearForm = (e) => {
        if (e.value === NO) {
            form.change(formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR, "");
        }
        form.change(formFieldsVRQuestionnaire.IS_CANCEL_VR, "");
    }

    const readOnlyForModifyDocument = () => {
        if (formValues && formValues[formFieldsVRQuestionnaire.IS_MODIFY_VR] === YES) {
            return false
        } else {
            return true
        }
    };

    const readOnlyForExpiredDocument = () => {
        if (formValues?.[formFieldsVRQuestionnaire.IS_EXTEND_VR] === YES) {
            return false
        } else {
            return true
        }
    };
    const isChangeOwnershipOnChange = (e) => {
        if (e.value) {
            dispatch(addInitialValueToForm(forms.APPLICATION, genericFormFieldNames.CHANGE_OWNERSHIP_SUB_USE_CASE, e.value))
        }
    };




    const onChangeOnlyForSecondQuestion = (event, fieldName) => {
        if (event?.target) {
            let isChecked = event?.target?.checked
            switch (fieldName) {
                case formFieldsVRQuestionnaire.LOST_VR:
                    setIsDamagedVRRoN(isChecked)
                    setIsExpiredVRRoN(isChecked)
                    break;
                case formFieldsVRQuestionnaire.DAMAGED_VR2:
                    setIsLostVRRoN(isChecked)
                    setIsExpiredVRRoN(isChecked)
                    break;
                case formFieldsVRQuestionnaire.EXPIRED_VR:
                    setIsLostVRRoN(isChecked)
                    setIsDamagedVRRoN(isChecked)
                    break;
            }
        }
    };



    const isNewVRComponent =
        <FormElement
            label="label.isNewVR" fieldType={fieldType.RADIO_INLINE}
            fieldProps={{
                className: "radioQuest",
                name: formFieldsVRQuestionnaire.IS_NEW_VR,
                options: yesNoSelect,
                onChange: onIsNewDocumentChange
            }}
        />;

    const newVRYesComponent =
        <>
            <FormElement
                label={'label.' + formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR}
                fieldType={fieldType.RADIO_INLINE} additionalClass={"additionalQuestLabel"}
                fieldProps={{
                    className: "radioQuest",
                    name: formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR,
                    options: [
                        {
                            name: I18n.t('label.' + formFieldsVRQuestionnaire.FIRST_TIME_APPLY_VR), value: formFieldsVRQuestionnaire.FIRST_TIME_APPLY_VR,
                        },
                        {
                            name: I18n.t('label.' + formFieldsVRQuestionnaire.OLD_VR), value: formFieldsVRQuestionnaire.OLD_VR,
                        }
                    ]
                }}
            />
        </>;

    const replaceVRComponent =
        <>
            <FormElement
                label={'label.' + formFieldsVRQuestionnaire.IS_REPLACE_VR}
                fieldType={fieldType.RADIO_INLINE}
                fieldProps={{
                    className: "radioQuest",
                    name: formFieldsVRQuestionnaire.IS_REPLACE_VR,
                    options: yesNoSelect,
                    onChange: isReplaceClearForm
                }}
            />
        </>;

    const replaceVRComponentYes =
        <>
            <div className="additionalQuestionsWrapper">
                <p className="additionalQuestionLabel">
                    <Translate value={I18n.t('label.' + formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR)} />
                </p>
                <FormElement
                    label={'label.' + formFieldsVRQuestionnaire.LOST_VR}
                    fieldType={fieldType.CHECKBOX_CUSTOM} additionalClass={"checkBoxQuestLeft"}
                    fieldProps={{
                        readOnly: isLostVRRoN,
                        name: formFieldsVRQuestionnaire.LOST_VR,
                        onChange: (e) => onChangeOnlyForSecondQuestion(e, formFieldsVRQuestionnaire.LOST_VR)
                    }}
                />
                <FormElement
                    label={'label.' + formFieldsVRQuestionnaire.DAMAGED_VR2}
                    fieldType={fieldType.CHECKBOX_CUSTOM} additionalClass={"checkBoxQuestLeft"}
                    fieldProps={{
                        readOnly: isDamagedVRRoN,
                        name: formFieldsVRQuestionnaire.DAMAGED_VR2,
                        onChange: (e) => onChangeOnlyForSecondQuestion(e, formFieldsVRQuestionnaire.DAMAGED_VR2)

                    }}
                />
                <FormElement
                    label={'label.' + formFieldsVRQuestionnaire.EXPIRED_VR}
                    fieldType={fieldType.CHECKBOX_CUSTOM} additionalClass={"checkBoxQuest"}
                    fieldProps={{
                        readOnly: isExpiredVRRoN,
                        name: formFieldsVRQuestionnaire.EXPIRED_VR,
                        onChange: (e) => onChangeOnlyForSecondQuestion(e, formFieldsVRQuestionnaire.EXPIRED_VR)

                    }}
                />
            </div>
        </>;


    const isModifyDocumentComponentVR =
        <>
            <div className="radionInlineWrapForQuest">
                <FormElement
                    label={'label.' + formFieldsVRQuestionnaire.IS_MODIFY_VR}
                    fieldType={fieldType.RADIO_INLINE}
                    fieldProps={{
                        className: "radioQuest",
                        name: formFieldsVRQuestionnaire.IS_MODIFY_VR,
                        options: yesNoSelect,
                        onChange: isModifyClearForm
                    }}
                />
                <FormElement
                    label={'label.' + formFieldsVRQuestionnaire.EXPIRED_VR3}
                    fieldType={fieldType.CHECKBOX_CUSTOM} additionalClass={"checkBoxQuest"}
                    fieldProps={{
                        readOnly: readOnlyForModifyDocument(),
                        name: formFieldsVRQuestionnaire.EXPIRED_VR3
                    }}
                />
            </div>
        </>;

    const isExchangeDocumentComponent =
        <>
            <FormElement
                label={'label.' + formFieldsVRQuestionnaire.IS_EXCHANGE_VR}
                fieldType={fieldType.RADIO_INLINE}
                fieldProps={{
                    className: "radioQuest",
                    name: formFieldsVRQuestionnaire.IS_EXCHANGE_VR,
                    options: yesNoSelect,
                    onChange: isExchangeClearForm
                }}
            />
        </>;

    const isReserveDocumentComponent =
        <>
            <FormElement
                label={'label.' + formFieldsVRQuestionnaire.IS_RESERVE_PLATE_VR}
                fieldType={fieldType.RADIO_INLINE}
                fieldProps={{
                    className: "radioQuest",
                    name: formFieldsVRQuestionnaire.IS_RESERVE_PLATE_VR,
                    options: yesNoSelect,
                    onChange: isReserveClearForm
                }}
            />
        </>;



    const isChangeOwnershipDocumentComponent =
        <>
            <FormElement
                label={'label.' + formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR}
                fieldType={fieldType.RADIO_INLINE}
                fieldProps={{
                    className: "radioQuest",
                    name: formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR,
                    options: yesNoSelect,
                    onChange: isChangeClearForm
                }}
            />
        </>;

    const isChangeOwnershipDocumentYesComponent =
        <>
            <FormElement
                label={'label.' + formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR}
                fieldType={fieldType.RADIO_INLINE} additionalClass={"additionalQuestLabel"}
                fieldProps={{
                    className: "radioQuest chnageOwnershipRadioBtn",
                    name: formFieldsVRQuestionnaire.ADDITIONAL_QUESTION_VR,
                    onChange: isChangeOwnershipOnChange,
                    options: getChangeOwnershipUCBasedOnAccountType()
                }}
            />
        </>

    const cancelDocumentComponent =
        <>
            <FormElement
                label={'label.' + formFieldsVRQuestionnaire.IS_CANCEL_VR}
                fieldType={fieldType.RADIO_INLINE}
                fieldProps={{
                    className: "radioQuest",
                    name: formFieldsVRQuestionnaire.IS_CANCEL_VR,
                    options: yesNoSelect,
                }}
            />
        </>;


    function getChangeOwnershipUCBasedOnAccountType() {
        let options;
        if(isLoggedAccountLegalEntity()){
            options = [
                {
                    name: I18n.t('label.' + formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR_SELL_OR_BUY), value: changeOwnershipSubUseCasesList.SALE_AND_BUY
                },
                {
                    name: I18n.t('label.' + formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR_COURT_SELL_OR_BUY), value: changeOwnershipSubUseCasesList.BY_COURT
                },
                {
                    name: I18n.t('label.' + formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR_AUCTION_SELL_OR_BUY), value: changeOwnershipSubUseCasesList.BY_AUCTION
                }
            ]
        }else{
            options = [
                {
                    name: I18n.t('label.' + formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR_SELL_OR_BUY), value: changeOwnershipSubUseCasesList.SALE_AND_BUY
                },
                {
                    name: I18n.t('label.' + formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR_ADD_OWNER), value: changeOwnershipSubUseCasesList.ADD_OWNER

                },
                {
                    name: I18n.t('label.' + formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR_REMOVE_OWNER), value: changeOwnershipSubUseCasesList.DELETE_OWNER

                },
                {
                    name: I18n.t('label.' + formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR_CHANGE_OWNERSHIP_SHARES), value: changeOwnershipSubUseCasesList.CHANGE_SHARE

                },
                {
                    name: I18n.t('label.' + formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR_CHANGE_LEAD_OWNER), value: changeOwnershipSubUseCasesList.CHANGE_LEAD
                },
                {
                    name: I18n.t('label.' + formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR_COURT_SELL_OR_BUY), value: changeOwnershipSubUseCasesList.BY_COURT
                },
                {
                    name: I18n.t('label.' + formFieldsVRQuestionnaire.IS_CHANGE_OWNERSHIP_VR_AUCTION_SELL_OR_BUY), value: changeOwnershipSubUseCasesList.BY_AUCTION

                }
            ]
        }
        return options;
    }


    return {

        isNewVRComponent,
        newVRYesComponent,
        replaceVRComponent,
        replaceVRComponentYes,
        isModifyDocumentComponentVR,
        isExchangeDocumentComponent,
        isReserveDocumentComponent,
        isChangeOwnershipDocumentComponent,
        isChangeOwnershipDocumentYesComponent,
        cancelDocumentComponent,
        isNewVR: isNewVR(),
        noNewVR: noNewVR(),
        replaceVR: replaceVR(),
        noReplaceVR: noReplaceVR(),
        isFirstTimeApplyVR: isFirstTimeApplyVR(),
        isOldDocumentVR: isOldDocumentVR(),
        isForeignDocumentVR: isForeignDocumentVR(),
        isLostDocumentVR: isLostDocumentVR(),
        isDamagedDocument2VR: isDamagedDocument2VR(),
        isExpiredDocumentVR: isExpiredDocumentVR(),
        modifyDocumentVR: modifyDocumentVR(),
        noModifyDocumentVR: noModifyDocumentVR(),
        isExpiredDocument3: isExpiredDocument3(),
        exchangeDocumentVR: exchangeDocumentVR(),
        noExchangeDocumentVR: noExchangeDocumentVR(),
        reserveDocumentVR: reserveDocumentVR(),
        noReserveDocumentVR: noReserveDocumentVR(),
        changeOwnershipDocumentVR: changeOwnershipDocumentVR(),
        noChangeOwnershipDocumentVR: noChangeOwnershipDocumentVR(),
        cancelDocumentVR: cancelDocumentVR(),
        noCancelDocumentVR: noCancelDocumentVR()
    }
}

