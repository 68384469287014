import { MBForm, NavButtons, addMultipleErrorToStep, addToAppStore, completeStep, getFormValues, getFromAppStore, hasValidationErrors, nextStep, previousStep } from "@mb-react/mb-react-core";
import { formFieldNames } from "@mb-react/svarog-ui";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { forms } from "../../../../constants/form";
import {APPLICATION_STEPS, applicationStore} from "../../../../constants/store";
import ProxyDataTask from "./ProxyDataTask";
import {onCancel} from "../../../../util/componentUtil";
import {proxyDataValidation} from "./proxyDataValidation";
import {useSkip} from "../../../../util/businesslogicUtil";
import {ACCOUNT_IDENTITY, LEGAL_ENTITY} from "../../../../constants/application";
import NavButtonsLocal from "../../../reactCoreComponents/NavButtonsLocal";
import {isMobile} from "../../../../util/environment";

const ProxyDataForm = (props) => {

    const isProxyChecked = useSelector((state) => getFormValues(state, forms.PROXY_DATA)[formFieldNames.PROXY])
    const errors = useSelector((state) => hasValidationErrors(state, props.formName));
    const dispatch = useDispatch();
    const proxyValidation = proxyDataValidation();
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))
    const accountIdentity = useSelector(state => getFromAppStore(state, ACCOUNT_IDENTITY));

    const listOfBuyers = useSelector((state) => getFromAppStore(state, 'listOfBuyers'))
    const listOfSellers = useSelector((state) => getFromAppStore(state, 'listOfSellers'));
    let listOfApplicants = []

    if(listOfSellers){
        listOfApplicants = [...listOfSellers]
    }

    if(listOfApplicants && listOfBuyers){
        listOfApplicants.push(...listOfBuyers)
    }

    const onPrevious = () => {
        dispatch(previousStep(props.navigationName))
    }

    const isFormValid = () => {
        if (listOfApplicants && listOfApplicants.length > 0 &&
            (!listOfApplicants.some((e) => e.entityType === LEGAL_ENTITY) ||
                (isProxyChecked && !errors && listOfApplicants.some((e) => e.entityType === LEGAL_ENTITY)))) {
            return true
        }

        return false
    }

    const onNextStep = () => {
        if (isFormValid()) {
            dispatch(addToAppStore('proxyDataStepCompleted', true))
            dispatch(addMultipleErrorToStep(APPLICATION_STEPS.PROXY_DATA, {}, props.navigationName))
            dispatch(completeStep(props.navigationName, APPLICATION_STEPS.PROXY_DATA))
        } else {
            dispatch(addToAppStore('proxyDataStepCompleted', false))
            dispatch(addMultipleErrorToStep(APPLICATION_STEPS.PROXY_DATA, {"error": "testerror"}, props.navigationName))
        }
        dispatch(nextStep(props.navigationName))
    }

    const onSkip = () => {
        dispatch(nextStep(props.navigationName))
    }

    return (
        <MBForm formName={props.formName} navigationId={props.navigationName} stepId={APPLICATION_STEPS.PROXY_DATA}
                warningButtonLabel={isMobile() ? "  " : null}
                showErrorPanel
                submitFunction={() => {
            dispatch(completeStep(props.navigationName, APPLICATION_STEPS.PROXY_DATA))
            dispatch(nextStep(props.navigationName))
        }} validate={proxyValidation.validate}>
            <ProxyDataTask/>
            <NavButtonsLocal customOnPrevious={() => onPrevious()}
                        customOnNext={() => onNextStep()}
                        disableNextBtn={!isFormValid()}
                        customOnSkip={() => onSkip()}
                        allowSkip={useSkip(userRights, errors, accountIdentity)}
                        hideNext={useSkip(userRights, errors, accountIdentity)}
                        customOnCancel={() => onCancel(dispatch)}/>
        </MBForm>
    );
}

export default ProxyDataForm;