import {genericFormFieldNames} from '@mb-react/krg-getid-form-data-common';
import {formFieldNames, isRequired} from '@mb-react/svarog-ui';
import React from 'react';
import {I18n} from "react-redux-i18n";
import {DRIVER_LICENSE_PROPERTIES, formFields, formFieldsMeta} from "../../../../../constants/form";
import {isReRegisterOrForeignUseCase} from '../../../../../util/businesslogicUtil';
import {useGlobalValidation} from "../../../../../hooks/useGlobalValidation";
//
// export const useDriversLicenceValidation = (values, props) => {
//     const errors = {};
//     let emptyForm = false;
//     let dateRegex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
//     let today = new Date();
//
//     if (!values) {
//         emptyForm = true
//     }
//
//     if (emptyForm || !values[DRIVER_LICENSE_PROPERTIES.DL_CATEGORY]) {
//         errors[DRIVER_LICENSE_PROPERTIES.DL_CATEGORY] = I18n.t("err.categoryEmpty");
//     }
//
//     // for RE_REGISTER and FOREIGN use cases
//     if (isReRegisterOrForeignUseCase(props.appDataFormValues)) {
//         if (emptyForm || !values[DRIVER_LICENSE_PROPERTIES.DL_ISSUING_DATE]) {
//             errors[DRIVER_LICENSE_PROPERTIES.DL_ISSUING_DATE] = I18n.t("err.dateOfIssueEmpty");
//         }
//     }
//
//     return errors;
// };

export const useDriversLicenceEnrollmentFormValidation = (values) => {
    const globalValidation = useGlobalValidation()

    const validate = (values) => {
        const errors = {};

        if(!values){
            errors.validation = 'Form values undefine!'
        }else{
            if (!values[formFields.DRIVER_LICENSE_DATA] || values[formFields.DRIVER_LICENSE_DATA].length === 0) {
                errors[formFields.DRIVER_LICENSE_DATA] = I18n.t("err.driversLicenceDataEmpty");
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.NUMBER_DL)) {
                isRequired(values, genericFormFieldNames.NUMBER_DL, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.ISSUE_COUNTRY_DL)) {
                isRequired(values, genericFormFieldNames.ISSUE_COUNTRY_DL, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.ISSUE_DATE_DL)) {
                isRequired(values, genericFormFieldNames.ISSUE_DATE_DL, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.VALID_DATE_DL)) {
                isRequired(values, genericFormFieldNames.VALID_DATE_DL, errors);
            }
            if (globalValidation.isMandatory(values, genericFormFieldNames.CLASSES_DL)) {
                isRequired(values, genericFormFieldNames.CLASSES_DL, errors);
            }
        }
        return errors;
    }
    return {validate};
};


