import { getCookie } from "@mb-react/svarog-ui";
import axios from "axios";

const GATEWAY_URL = configurationData && configurationData.gatewayURL ? configurationData.gatewayURL : "http://localhost:10300";
const ACCESS_TOKEN_NAME = configurationData && configurationData.accessTokenName ? configurationData.accessTokenName : "jwt"
const ROOT_ENDPOINT = "/account-service/identity"
const LEGAL_ENTITY_ENDPOINT = "/account-service/legal-entities"
const CHECK_PERSON_STATE = ROOT_ENDPOINT + "/check-state"
const CHECK_LE_STATE = LEGAL_ENTITY_ENDPOINT + "/check-state"

export class IdentityManagementService {
    static saveIdentity(params) {
        return axios.post(GATEWAY_URL + ROOT_ENDPOINT, params, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    static getIdentity() {
        return axios.get(GATEWAY_URL + ROOT_ENDPOINT, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    static saveIdentityForLegalEntity(params) {
        return axios.post(GATEWAY_URL + LEGAL_ENTITY_ENDPOINT, params, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    static getIdentityForLegalEntity() {
        return axios.get(GATEWAY_URL + LEGAL_ENTITY_ENDPOINT, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    static checkPersonIdentityState() {
        return axios.get(GATEWAY_URL + CHECK_PERSON_STATE, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }

    static checkLegalEntityIdentityState() {
        return axios.get(GATEWAY_URL + CHECK_LE_STATE, {
            headers: {
                "Authorization": "Bearer " + getCookie(ACCESS_TOKEN_NAME)
            }
        })
    }
}