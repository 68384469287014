import React from 'react';
import { Button, FormElement, MBForm } from '@mb-react/mb-react-core';
import { getPath } from '../../../constants/i18n';
import { fieldType } from '@mb-react/mb-react-core';
import {I18n, Translate} from 'react-redux-i18n';
import { EStatusName } from '../../../constants/server';
import { formFieldNames } from '@mb-react/svarog-ui';
import {forms} from "../../../constants/form";

const MyAppointmentsSearch = (props) => {
  const { searchAppointmentsFunc } = props

  const getAppointmentStatusDropdown =()=> {
    let appStatus = [
        {label: I18n.t(getPath(EStatusName.SCHEDULED)), value: EStatusName.SCHEDULED},
        {label: I18n.t(getPath(EStatusName.CANCELED)), value: EStatusName.CANCELED},
        {label: I18n.t(getPath(EStatusName.ARCHIVED)), value: EStatusName.ARCHIVED}]
    return appStatus
}

  return (
    <MBForm formName={forms.APPOINTMENTS_SEARCH_FORM} submitFunction={(data) => searchAppointmentsFunc(data, 1, 10)}>
      <div className='grid appSearchForm'>
        <div className='col-12 xl:col-4 '>
          <FormElement label='label.appointmentId' fieldType={fieldType.INPUT_TEXT}
            fieldProps={{ name: formFieldNames.APPOINTMENT_ID}} />
        </div>
        <div className='col-12 xl:col-4 '>
          <FormElement label='label.startDate' fieldType={fieldType.INPUT_DATE_PICKER}
            fieldProps={{ name: formFieldNames.START_DATE }} />
        </div>
        <div className='col-12 xl:col-4 '>
          <FormElement label='label.endDate' fieldType={fieldType.INPUT_DATE_PICKER}
            fieldProps={{ name: formFieldNames.END_DATE }} />
        </div>
        <div className='col-12 xl:col-4'>
          <FormElement label='label.status' fieldType={fieldType.INPUT_DROPDOWN}
            fieldProps={{ name: formFieldNames.STATUS,
                          options: getAppointmentStatusDropdown(),
                          filter: true, showClear: true
                          }} />
        </div>
        <div className='col-12 xl:col-4'>
          <Button type="submit" label={<Translate value="action.search" />} className="p-button-primary" icon="pi pi-search" />
        </div>
      </div>

    </MBForm>
  )
}
export default MyAppointmentsSearch;