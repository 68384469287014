import React from "react";

export const myAppointmentsComponent = () => {

    return (
        <div className="docImageWrap">
            <svg version="1.1" id="myAppIcon" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 842.1 1018.6" space="preserve">
                <g id="Layer_2_00000054962683704746780950000015835263144564514229_">
                    <g id="Layer_3">
                        <path className="st0" d="M575.3,697.9c-10.9,0-21.7,0.1-32.6,0c-13.8-0.2-19.1-5.6-19.2-19c-0.3-30.4,16.8-47.7,47.1-48.7
			c20.4-0.6,39.2,1.6,51.2,21.2c6.7,10.9,7.4,22.8,5.6,34.6c-1.3,8.6-8.8,11.8-17.2,11.9C598.5,698,586.9,697.9,575.3,697.9z"/>
                        <path className="st0" d="M608.7,588c0,18.5-14.8,33.5-33,33.5s-33-15-33-33.6c0-18,15.4-33.7,33.1-33.7S608.7,569.9,608.7,588z" />
                        <path className="st1" d="M574.1,611c-12.9-0.7-22.8-11.8-22.1-24.7c0.8-12.5,12.2-22.3,25.3-21.6c12.2,0.6,22.8,12.1,22,23.7
			C598.3,602.4,588,611.7,574.1,611z"/>
                    </g>
                    <g id="Layer_2-2">
                        <path className="st2" d="M334.8,476.4h49.3c3.1,0,5.7,2.6,5.7,5.7v36.4c0,3.1-2.6,5.7-5.7,5.7h-49.3c-3.1,0-5.7-2.6-5.7-5.7v-36.4
			C329,478.9,331.6,476.4,334.8,476.4z"/>
                        <path className="st2" d="M384.1,580.3h-49.3c-3.1,0-5.7,2.5-5.7,5.7c0,0,0,0,0,0v36.5c0,3.1,2.6,5.7,5.7,5.7h49.3
			c3.1,0,5.7-2.6,5.7-5.7V586C389.8,582.8,387.2,580.3,384.1,580.3C384.1,580.3,384.1,580.3,384.1,580.3z"/>
                        <path className="st2" d="M212.3,674.3c-2.9,0-5.6-1.2-7.7-3.2c-2-2-3.2-4.8-3.2-7.7V433.3h409.6v22c8.2,1.6,16.3,3.8,24.2,6.5V320.7
			c0-19.2-15.6-34.7-34.8-34.7h-39.3c-6.6,0-11.9,5.3-11.9,11.9c0,6.6,5.3,11.9,11.9,11.9h39.3c2.9,0,5.6,1.1,7.7,3.2
			c2,2,3.2,4.8,3.2,7.7v88.8H201.7v-88.9c0-2.9,1.2-5.6,3.2-7.7c2-2,4.8-3.2,7.6-3.2h41.9c6.6,0,11.9-5.3,11.9-11.9
			c0-6.6-5.3-11.9-11.9-11.9h-41.9c-19.2,0-34.8,15.6-34.8,34.8v342.9c0,19.2,15.6,34.8,34.8,34.8c0,0,0,0,0,0h202.9
			c-3.4-7.8-6.2-15.9-8.4-24.1H212.3z"/>
                        <path className="st2" d="M502.8,353.2c12,0,21.5-8,21.5-17.7v-77.2c0-9.8-9.6-17.7-21.5-17.7s-21.5,8-21.5,17.7v77.2
			C481.3,345.3,490.8,353.2,502.8,353.2z"/>
                        <path className="st2" d="M309.5,353.2c11.9,0,21.5-8,21.5-17.7v-77.2c0-9.8-9.6-17.7-21.5-17.7s-21.5,8-21.5,17.7v77.2
			C288,345.3,297.6,353.2,309.5,353.2z"/>
                        <path className="st2" d="M365.4,309.8h80c6.6,0,11.9-5.3,11.9-11.9c0-6.6-5.3-11.9-11.9-11.9h-80c-6.6,0-11.9,5.3-11.9,11.9
			C353.5,304.4,358.8,309.7,365.4,309.8z"/>
                        <path className="st2" d="M482.4,477.9c-1.1-1-2.5-1.6-3.9-1.6h-49.3c-3.2,0-5.7,2.6-5.7,5.7v36.5c0,3.1,2.6,5.7,5.7,5.7h3.8
			C446.3,505.8,463.1,490,482.4,477.9z"/>
                        <path className="st2" d="M240.3,476.4h49.3c3.1,0,5.7,2.6,5.7,5.7v36.4c0,3.1-2.6,5.7-5.7,5.7h-49.3c-3.1,0-5.7-2.6-5.7-5.7v-36.4
			C234.6,478.9,237.2,476.4,240.3,476.4z"/>
                        <path className="st2" d="M628,499.7c-31.3-13-72.3-13.8-103.9-0.9c-50.9,20.9-85,72.2-85,127.2c0,75.5,61.2,136.6,136.7,136.6
			c75.5,0,136.6-61.2,136.6-136.7C712.4,570.7,679.1,520.9,628,499.7z M656.8,707c-44.8,44.8-117.5,44.7-162.2-0.2
			c-33.9-34-43.1-85.5-22.9-129.1c33.1-71.3,128.6-87.3,185.2-30.7c9,9,16.5,17.4,22.1,28.8C700.2,619.8,691.4,672.4,656.8,707z"/>
                        <path className="st2" d="M289.6,580.3h-49.3c-3.1,0-5.7,2.5-5.7,5.7c0,0,0,0,0,0v36.5c0,3.1,2.6,5.7,5.7,5.7h49.3
			c3.1,0,5.7-2.6,5.7-5.7V586C295.3,582.8,292.8,580.3,289.6,580.3C289.6,580.3,289.6,580.3,289.6,580.3z"/>
                        <path className="st2" d="M425.6,52.6v122.2l-8,0.2V52.7L425.6,52.6z" />
                        <path className="st2" d="M426.6,843.9v122h-8V844L426.6,843.9z" />
                        <path className="st3" d="M832.1,562.5c-1.7-131.9-0.8-263.8,0-395.6c0-21.1-5.3-32-27.3-38.8C682,90.7,559.9,51.7,437.7,12.7
			c-10.7-3.6-22.2-3.6-32.9,0C281.6,52,158.2,90.6,34.5,128.6c-18.4,6-24.5,14.9-24.5,34.2c1.1,73.9,0.4,147.9,0.5,221.7
			c0,67-0.5,134.1,0.1,201.2c0.5,59.4,17.3,117.5,48.5,168c80.7,134.3,208.6,204.5,351.6,253.5c10.8,3.5,19.8-0.2,29.6-3.3
			C516.4,980,588,943.6,652.1,896.1C763,812.9,834,707.8,832.1,562.5z"/>
                    </g>
                </g>
            </svg>
        </div>
    )
}