import {useSelector} from "react-redux";
import {getFormValues} from "@mb-react/mb-react-core";
import {forms} from "../../../../constants/form";
import {useGlobalValidation} from "../../../../hooks/useGlobalValidation";
import { formFieldNames, isRequired } from '@mb-react/svarog-ui';
import { genericFormFieldNames } from '@mb-react/krg-getid-form-data-common';

export const proxyDataValidation = (values) => {
    const globalValidation = useGlobalValidation()
    const formValuesApp = useSelector((state) => getFormValues(state, forms.APPLICATION))

    const validate = (values) => {
        const errors = {};

        if (!values) {
            errors.validation = 'Form values undefined!'
        } else {
            if (values[formFieldNames.PROXY]) {
                isRequired(values, formFieldNames.PROXY_PERSONAL_NUMBER, errors);
            }
            if (values[formFieldNames.PROXY]) {
                isRequired(values, formFieldNames.PROXY_NAME, errors);
            }
            if (values[formFieldNames.PROXY]) {
                isRequired(values, formFieldNames.PROXY_SURNAME, errors);
            }
            if (values[formFieldNames.PROXY]) {
                isRequired(values, formFieldNames.PROXY_GENDER, errors);
            }
            if (values[formFieldNames.PROXY]) {
                isRequired(values, formFieldNames.PROXY_DATE_OF_BIRTH, errors);
            }
            if (values[formFieldNames.PROXY]) {
                isRequired(values, genericFormFieldNames.PROXY_FATHER_NAME, errors);
            }
            if (values[formFieldNames.PROXY]) {
                isRequired(values, genericFormFieldNames.PROXY_GRANDFATHER_NAME, errors);
            }
        }
        return errors;
    }
    return {validate};
};