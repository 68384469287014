import {useSelector} from "react-redux";
import {getFormValues} from "@mb-react/mb-react-core";
import {formFieldsMeta, forms} from "../../../../../../constants/form";
import {useMemo} from "react";
import {formFieldNames} from "@mb-react/svarog-ui";
import {isMandatory, isReadOnly} from "../../../../../../util/businesslogicUtil";
import {useForm} from "react-final-form";
import {genericFormFieldNames} from "@mb-react/krg-getid-form-data-common";
import {I18n} from "react-redux-i18n";
import {getPath} from "../../../../../../constants/i18n";
import {ReferenceDocumentTypes} from "../../../../../../constants/general";

export const useReferenceDocumentDataSettings = () => {
    const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION))
    const documentsDataFormValues = useSelector((state) => getFormValues(state, forms.DOCUMENTS_DATA))

    let form = useForm();

    const onReferenceDocTypeChange = () => {
        form.change(genericFormFieldNames.REFERENCE_DOCUMENT_NUMBER, '');
    };

    const getReferenceDocumentTypeDropdown =()=> {
        let docType = [
            {label: I18n.t(getPath(ReferenceDocumentTypes.VEHICLE_REGISTRATION_CARD)), value: ReferenceDocumentTypes.VEHICLE_REGISTRATION_CARD},
            {label: I18n.t(getPath(ReferenceDocumentTypes.WIND_SHIELD_STICKER)), value: ReferenceDocumentTypes.WIND_SHIELD_STICKER},
            {label: I18n.t(getPath(ReferenceDocumentTypes.LICENSE_PLATE)), value: ReferenceDocumentTypes.LICENSE_PLATE}]
        return docType
    }

    const referenceDocumentTypeSettings = useMemo(() => {
        let referenceDocumentData = {
            [genericFormFieldNames.REFERENCE_DOCUMENT_TYPE]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(documentsDataFormValues, genericFormFieldNames.REFERENCE_DOCUMENT_TYPE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                    onChange: onReferenceDocTypeChange,
                    options: getReferenceDocumentTypeDropdown(),
                    showClear: true
                },
                required: isMandatory(documentsDataFormValues, genericFormFieldNames.REFERENCE_DOCUMENT_TYPE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return referenceDocumentData
    }, [])

    const referenceDocumentNumberSettings = useMemo(() => {
        let referenceDocumentData = {
            [genericFormFieldNames.REFERENCE_DOCUMENT_NUMBER]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(documentsDataFormValues, genericFormFieldNames.REFERENCE_DOCUMENT_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                },
                required: isMandatory(documentsDataFormValues, genericFormFieldNames.REFERENCE_DOCUMENT_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return referenceDocumentData
    }, [])


    return {...referenceDocumentTypeSettings, ...referenceDocumentNumberSettings}
}