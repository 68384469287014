/**
 * @author Filip Radulovic
 @author Jovanovic Predrag
 @author Iva Petkovic
 @author Aleksandar Radovanovic
 */
import React, {useEffect, useState} from 'react';
import MyApplications from '../tasks/myApplications/MyApplications';
import MainWrapper from './wrappers/MainWrapper';
import {useSelector} from "react-redux";
import {getFromAppStore} from "@mb-react/mb-react-core";
import {applicationStore} from "../../constants/store";
import PropTypes from "prop-types";
import {isNotRegisteredUserRole} from "../../util/businesslogicUtil";
import ApplicationStatus from "../tasks/myApplications/ApplicationStatus/ApplicationsStatus";

const MyApplicationsWrapper = (props) => {
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))
    const renderContent = () => {
        if(userRights && !isNotRegisteredUserRole(userRights)){
            return <MyApplications />
        }
        return <ApplicationStatus />
    }

    return (
        <MainWrapper>
            <div className='paddingBottom'>
                {renderContent()}
            </div>
        </MainWrapper>
    );
}
MyApplicationsWrapper.propTypes = {
    changeComponent: PropTypes.func,
};
export default MyApplicationsWrapper;

