import React, {useState} from 'react';
import {formFieldNames, setCookie, getCookie, EUseCases} from "@mb-react/svarog-ui";
import {
  Button,
  FormElement,
  getFormValues,
  MBForm,
  change,
  Dialog, Message
} from "@mb-react/mb-react-core";
import {I18n} from "react-redux-i18n";
import {
  REDIRECT_TO_NEW_DRIVER_LICENSE,
  REDIRECT_TO_NEW_VEHICLE_REGISTRATION
} from "../../../../constants/environment";
import {fieldType} from "@mb-react/mb-bootstrap-core";
import { RadioButton } from 'primereact/radiobutton';
import {getChangeOwnershipSubUseCases, getDlUseCases, getVRUseCases} from "../../../../constants/application";
import {useDispatch, useSelector} from "react-redux";
import {isUseCaseChangeOwnership} from "../../../../util/businesslogicUtil";
import {genericFormFieldNames} from "@mb-react/krg-getid-form-data-common";
import {forms} from "../../../../constants/form";
import {useHandleCommonServices} from "../../../../services/commonServiceHandler";
import {SELECTED_ACCOUNT_ID} from "../../../../constants/general";
import {delete_cookie} from "../../../../util/util";
import {getPath, i18nl, TRG} from "../../../../constants/i18n";

function ManageWorkflowForAccount(props) {
  const driverLicense = "DL";
  const vehicleRegistration = "VR";
  const [wfType, setWfType] = useState(driverLicense);
  const [showDialog, setShowDialog] = useState(false);
  const formValues = useSelector((state) => getFormValues(state, forms.START_CLIENT_MANAGE_WF))
  const dispatch = useDispatch();
  const handleServices = useHandleCommonServices();
  const applyWithoutAccount = getCookie("useApplyWithoutAccount");

  const onRadioChange = ({value}) => {
    dispatch(change(forms.START_CLIENT_MANAGE_WF, formFieldNames.USE_CASE));
    dispatch(change(forms.START_CLIENT_MANAGE_WF, genericFormFieldNames.CHANGE_OWNERSHIP_SUB_USE_CASE));
    setWfType(value);
  }

  const startWorkflow = () => {
    delete_cookie([genericFormFieldNames.CHANGE_OWNERSHIP_SUB_USE_CASE])
    setCookie([formFieldNames.USE_CASE], formValues?.[formFieldNames.USE_CASE])
    if(formValues?.[genericFormFieldNames.CHANGE_OWNERSHIP_SUB_USE_CASE]){
      setCookie([genericFormFieldNames.CHANGE_OWNERSHIP_SUB_USE_CASE], formValues?.[genericFormFieldNames.CHANGE_OWNERSHIP_SUB_USE_CASE])
    }
    let location = REDIRECT_TO_NEW_DRIVER_LICENSE
    if(wfType === vehicleRegistration){
      location = REDIRECT_TO_NEW_VEHICLE_REGISTRATION
    }
    window.location = location;
  }

  const checkConditions = () => {
    if(!applyWithoutAccount){
      let request = {
        applicationType: wfType,
        accountId: getCookie(SELECTED_ACCOUNT_ID),
        status: "READY_FOR_DISPATCHING"
      }
      handleServices.handleCountApplications(request, checkConditionsOnSuccess)
    }else{
      startWorkflow();
    }
  }

  const workflowConstraintsErrorMsg = () => {
    setShowDialog(true);
  }

  const checkConditionsOnSuccess = response => {
    if(wfType === vehicleRegistration){
      if(response >= configurationData.accountApplicationsVrTotal){
        workflowConstraintsErrorMsg();
        return;
      }
      if(response >= configurationData.accountApplicationsVrPerDay){
        workflowConstraintsErrorMsg();
        return;
      }
    }
    if(wfType === driverLicense && response > 0){
      workflowConstraintsErrorMsg();
      return;
    }
    startWorkflow();
  }

  let disabledCreateApplication = !formValues?.[formFieldNames.USE_CASE]

  if(formValues?.[formFieldNames.USE_CASE] === EUseCases.CHANGE_OWNERSHIP){
    disabledCreateApplication = !formValues?.[genericFormFieldNames.CHANGE_OWNERSHIP_SUB_USE_CASE]
  }

  const renderMaxNumbOfAppsReachedFooter = () => {
    return (
        <div>
          <Button label={I18n.t("action.confirm")} icon="pi pi-edit" onClick={() => {
            setShowDialog(false)
          }} className="p-button-text" />
        </div>
    );
  };

  return (
    <>
      <MBForm formName={forms.START_CLIENT_MANAGE_WF} submitFunction={() => {}}>
        <div className="flex flex-wrap gap-3 mb-5 mt-5">
          <div className="flex align-items-center">
            <RadioButton inputId="dl" name="wfType" value={driverLicense} onChange={onRadioChange} checked={wfType === driverLicense} />
            <label htmlFor="dl" className="ml-2">{I18n.t("label.driverLicense")}</label>
          </div>
          {!configurationData?.dlGoLive && <div className="flex align-items-center">
            <RadioButton inputId="vr" name="wfType" value={vehicleRegistration} onChange={onRadioChange} checked={wfType === vehicleRegistration} />
            <label htmlFor="vr" className="ml-2">{I18n.t("label.vehicleRegistration")}</label>
          </div>}
        </div>
        <div className="flex align-items-center">
          <div className='col-4'>
            <FormElement label={I18n.t("label.useCase")} fieldType={fieldType.INPUT_DROPDOWN}
                         fieldProps={{
                           name: formFieldNames.USE_CASE,
                           onChange: () => {dispatch(change(forms.START_CLIENT_MANAGE_WF, genericFormFieldNames.CHANGE_OWNERSHIP_SUB_USE_CASE));},
                           selectOptions: wfType === driverLicense ? getDlUseCases() : getVRUseCases(),
                           filter: true, showClear: true,
                         }}/>
          </div>
          <div className='col-4'>
            <FormElement label={I18n.t("label.subUseCase")} fieldType={fieldType.INPUT_DROPDOWN}
                         fieldProps={{
                           name: genericFormFieldNames.CHANGE_OWNERSHIP_SUB_USE_CASE,
                           selectOptions: getChangeOwnershipSubUseCases(),
                           readOnly: !isUseCaseChangeOwnership(formValues)
                         }}/>
          </div>
        </div>
        <Button
          label={I18n.t("action.createApplication")}
          onClick={checkConditions}
          className="p-button"
          disabled={disabledCreateApplication}
        />
        <Dialog header={I18n.t(getPath(i18nl.MAX_NUMBER_OF_APPLICATIONS_REACHED, TRG.LABEL))} visible={showDialog} style={{ width: '54vw' }}
                footer={renderMaxNumbOfAppsReachedFooter} onHide={() => setShowDialog(false)}>
          <Message severity="info" text={I18n.t(getPath(i18nl.MAX_NUMBER_OF_APPLICATIONS_REACHED_MESSAGE, TRG.MESSAGE))} />
        </Dialog>
      </MBForm>
    </>
  );
}

export default ManageWorkflowForAccount;