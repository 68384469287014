import { MBForm, addMultipleErrorToStep, addToAppStore, completeStep, getFormValues, getFromAppStore, hasValidationErrors, nextStep, previousStep } from "@mb-react/mb-react-core";
import { formFieldNames } from "@mb-react/svarog-ui";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {applicationStore} from "../../../../constants/store";
import {onCancel} from "../../../../util/componentUtil";
import {proxyDataValidation} from "./proxyDataValidation";
import {useSkip} from "../../../../util/businesslogicUtil";
import {ACCOUNT_IDENTITY, LEGAL_ENTITY} from "../../../../constants/application";
import NavButtonsLocal from "../../../reactCoreComponents/NavButtonsLocal";
import {isMobile} from "../../../../util/environment";
import ProxyDataBuyersTask from "./ProxyDataBuyersTask";

const ProxyDataBuyerForm = (props) => {

    const isProxyChecked = useSelector((state) => getFormValues(state, props.formName)[formFieldNames.PROXY])
    const errors = useSelector((state) => hasValidationErrors(state, props.formName));
    const dispatch = useDispatch();
    const proxyValidation = proxyDataValidation();
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))
    const accountIdentity = useSelector(state => getFromAppStore(state, ACCOUNT_IDENTITY));

    const listOfBuyers = useSelector((state) => getFromAppStore(state, 'listOfBuyers'))
    let listOfApplicants = []

    if(listOfApplicants && listOfBuyers){
        listOfApplicants.push(...listOfBuyers)
    }

    const onPrevious = () => {
        dispatch(previousStep(props.navigationName))
    }

    const isFormValid = () => {
        if (listOfApplicants && listOfApplicants.length > 0 &&
            (!listOfApplicants.some((e) => e.entityType === LEGAL_ENTITY) ||
                (isProxyChecked && !errors && listOfApplicants.some((e) => e.entityType === LEGAL_ENTITY)))) {
            return true
        }

        return false
    }

    const onNextStep = () => {
        if (isFormValid()) {
            dispatch(addToAppStore('proxyDataBuyerStepCompleted', true))
            dispatch(addMultipleErrorToStep(props.applicationStep, {}, props.navigationName))
            dispatch(completeStep(props.navigationName, props.applicationStep))
        } else {
            dispatch(addToAppStore('proxyDataBuyerStepCompleted', false))
            dispatch(addMultipleErrorToStep(props.applicationStep, {"error": "testerror"}, props.navigationName))
        }
        dispatch(nextStep(props.navigationName))
    }

    const onSkip = () => {
        dispatch(nextStep(props.navigationName))
    }

    return (
        <MBForm formName={props.formName} navigationId={props.navigationName} stepId={props.applicationStep}
                warningButtonLabel={isMobile() ? "  " : null}
                showErrorPanel
                submitFunction={() => {
            dispatch(completeStep(props.navigationName, props.applicationStep))
        }} validate={proxyValidation.validate}>
            <ProxyDataBuyersTask formName={props.formName}/>
            <NavButtonsLocal customOnPrevious={() => onPrevious()}
                        customOnNext={() => onNextStep()}
                        disableNextBtn={!isFormValid()}
                        customOnSkip={() => onSkip()}
                        allowSkip={useSkip(userRights, errors, accountIdentity)}
                        hideNext={useSkip(userRights, errors, accountIdentity)}
                        customOnCancel={() => onCancel(dispatch)}/>
        </MBForm>
    );
}

export default ProxyDataBuyerForm;