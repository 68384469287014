export const ADMIN = 'admin';
export const UNLOCK_ACCOUNT = 'unlockAccount';
export const DELETE_ACCOUNT = 'deleteAccount';
export const APPROVE_ACCOUNT = 'approveAccount';
export const SUSPEND_ACCOUNT = "suspendAccount";
export const ACTIVATE_ACCOUNT = "activateAccount";
export const ADD_DOCUMENT = "addDocument";
export const DELETE_DOCUMENT = "deleteDocument";
export const CREATE_ACCOUNT = "createAccount";
export const OVERVIEW_ACCOUNT = "overviewAccount";
export const MANAGE_ACCOUNT = "manageAccount";
export const CONTENT_MANAGEMENT = "contentManagement";
export const PRINT_ACCOUNT_REPORT = "printAccountReport";
export const PRINT_APPLICATION_REPORT = "printApplicationReport";
export const MODIFY_PROVINCE_SITE = "modifyProvinceSite";

export const MODIFY_PROVINCE_RIGHTS = [MODIFY_PROVINCE_SITE];
