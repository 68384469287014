import {
    addressDataFormFieldList,
    BirthData,
    EmploymentInformationData,
    FatherData,
    GrandfatherData,
    MaritalData,
    MotherData,
    NameData,
    OtherPersonalInformationData, personalInformationFormFieldList,
    VehicleOwnershipData
} from "@mb-react/krg-getid-form-data-common";
import {
    addMultipleInitialValuesToForm, addToAppStore,
    Button,
    getFormValues, getFromAppStore, Messages
} from "@mb-react/mb-react-core";
import {
    EDocumentType,
    extractPersonDataToFormValues,
    formFieldNames,
    PagedRequest
} from "@mb-react/svarog-ui";
import React, {useEffect, useRef} from "react";
import {useForm} from "react-final-form";
import {useDispatch, useSelector} from "react-redux";
import {I18n, Translate} from "react-redux-i18n";
import {forms} from "../../../../../constants/form";
import {dictionaryReference, projectDictionaries} from "../../../../../constants/general";
import {getPath, i18nl, TRG} from "../../../../../constants/i18n";
import {applicationStore, PERSONAL_DATA_STEPS} from "../../../../../constants/store";
import {useHandleCommonServices} from "../../../../../services/commonServiceHandler";
import {
    isNotRegisteredUserRole,
    isOperationModeProtected,
    isUseCaseReservePlateNumber
} from "../../../../../util/businesslogicUtil";
import {extractObjectBasedOnKeysList} from "../../../../../util/dataExtractorUtil";
import {useHandleWorkflowSettings} from "../../../../entries/application/handleWorkflowSettings";
import {useBirthDataSettings} from "./personalDataSettings/birthDataSettings";
import {useEmploymentInformationDataSettings} from "./personalDataSettings/employmentInformationDataSettings";
import {useFatherDataSettings} from "./personalDataSettings/fatherDataSettings";
import {useGrandfatherDataSettings} from "./personalDataSettings/grandfatherDataSettings";
import {useMaritalDataSettings} from "./personalDataSettings/maritalDataSettings";
import {useMotherDataSettings} from "./personalDataSettings/motherDataSettings";
import {useNameDataSettings} from "./personalDataSettings/nameDataSettings";
import {useOtherPersonalInformationDataDataSettings} from "./personalDataSettings/otherPersonalInformationDataSettings";
import {useOwnershipDataSettings} from "./personalDataSettings/ownershipDataSettings";
import {useTpidFieldSettings} from "./personalDataSettings/tpidFieldSettings";

const PersonalInformationTask = (props) => {
    const applicationsFromStore = useSelector(state => getFromAppStore(state, 'fetchedApplication'));
    const useCaseFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.USE_CASE]);
    const docTypeFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.DOCUMENT_TYPE]);
    const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION));
    const formValuesPI = useSelector((state) => getFormValues(state, forms.PERSONAL_INFORMATION));
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))
    const handleWorkflowSettings = useHandleWorkflowSettings();
    const brtDsSetting = useBirthDataSettings();
    const guidanceMessage1 = useRef(null);
    const handleCommonServices = useHandleCommonServices()
    const identity = useSelector(state => getFromAppStore(state, 'identity'))
    const form = useForm();
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addToAppStore('addIdentityToForm', false))
        if (applicationsFromStore) {
            if (applicationsFromStore["placeOfBirthProvince"]) {
                handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, applicationsFromStore["placeOfBirthProvince"], dictionaryReference.CITIES_PLACE_OF_BIRTH)
            }

            if (applicationsFromStore["placeOfBirthCity"]) {
                handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, applicationsFromStore["placeOfBirthCity"], dictionaryReference.VILLAGES_PLACE_OF_BIRTH)
            }

            if (applicationsFromStore["provinceOfRegistration"]) {
                handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, applicationsFromStore["provinceOfRegistration"], dictionaryReference.CITIES_OF_REGISTRATION)
            }

            if (applicationsFromStore["cityOfRegistration"]) {
                handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, applicationsFromStore["cityOfRegistration"], dictionaryReference.VILLAGES_OF_REGISTRATION)
            }
        }
        guidanceMessage1.current.show({
            severity: 'info', sticky: true, content: (
                <React.Fragment className="mb-1">
                    <i className="pi pi-info p-2" style={{
                        borderRadius: '50%',
                        backgroundColor: 'var(--surface-b)',
                        color: 'var(--surface-d)'
                    }}></i>
                    <div className="ml-2">
                        <Translate value="message.personalDataGuidanceMessage1"/>
                    </div>
                </React.Fragment>
            )
        });

        if (props.useCase == "modifyIdentity" || props.useCase == "cancelIdentity" || useCaseFromStore == "CANCEL_DOCUMENT") {
            let pagedRequest = new PagedRequest();
            pagedRequest.requestObject = {}
            pagedRequest.page = 1
            pagedRequest.sizePerPage = 10

            handleCommonServices.handleCallGetMyIdentity(pagedRequest, getMyIdentityOnSuccess)
        }
    }, []);

    let sectionsToRender = handleWorkflowSettings.personalDataStepsToRender;

    const getMyIdentityOnSuccess = (response) => {
        let newObj = extractPersonDataToFormValues(response.data, {});
        let personalInformation = extractObjectBasedOnKeysList(newObj, personalInformationFormFieldList);
        let addressData = extractObjectBasedOnKeysList(newObj, addressDataFormFieldList);

        if (personalInformation["placeOfBirthProvince"]) {
            handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, personalInformation["placeOfBirthProvince"], dictionaryReference.CITIES_PLACE_OF_BIRTH)
        }
        if (personalInformation["placeOfBirthCity"]) {
            handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, personalInformation["placeOfBirthCity"], dictionaryReference.VILLAGES_PLACE_OF_BIRTH)
        }

        form.initialize(personalInformation)
        dispatch(addMultipleInitialValuesToForm(forms.ADDRESS_DATA, addressData))
        dispatch(addToAppStore('fetchedApplication', {...addressData, ...personalInformation}))

    }

    const addIdentityToForm = () => {

        dispatch(addToAppStore('identityAdd', true))
        dispatch(addToAppStore('addIdentityToForm', true))

        let application = extractPersonDataToFormValues(identity, {})
        let personalInformation = extractObjectBasedOnKeysList(application, personalInformationFormFieldList);
        let addressData = extractObjectBasedOnKeysList(application, addressDataFormFieldList);

        if (personalInformation["placeOfBirthProvince"]) {
            handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, personalInformation["placeOfBirthProvince"], dictionaryReference.CITIES_PLACE_OF_BIRTH)
        }

        if (personalInformation["placeOfBirthCity"]) {
            handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, personalInformation["placeOfBirthCity"], dictionaryReference.VILLAGES_PLACE_OF_BIRTH)
        }

        if (personalInformation["provinceOfRegistration"]) {
            handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, personalInformation["provinceOfRegistration"], dictionaryReference.CITIES_OF_REGISTRATION)
        }

        if (personalInformation["cityOfRegistration"]) {
            handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, personalInformation["cityOfRegistration"], dictionaryReference.VILLAGES_OF_REGISTRATION)
        }

        form.initialize(personalInformation)
        dispatch(addMultipleInitialValuesToForm(forms.ADDRESS_DATA + props.formNameSufix, addressData));
    }

    return (
        <div className="card">
            <Messages ref={guidanceMessage1}/>
            {
                useCaseFromStore && docTypeFromStore && <div className={"useCaseHeader"}>
                    <h5>{I18n.t("label.useCaseHeader", {
                        documentType: I18n.t("label." + docTypeFromStore),
                        useCase: I18n.t("label." + useCaseFromStore)
                    })}</h5></div>
            }
            {!isNotRegisteredUserRole(userRights) && (docTypeFromStore == EDocumentType.VR) && identity && !isOperationModeProtected() && <div>
                <Button
                    label={I18n.t(getPath(i18nl.USE_IDENTITY, TRG.ACTION))}
                    icon="pi pi-file-pdf"
                    onClick={addIdentityToForm}
                    className="p-button"
                    autoFocus
                />
            </div>}
            <h5><Translate value="label.personalInformation"/></h5>
            <hr className="sectionSeparator"/>
            <div className="grid">
                {sectionsToRender.includes(PERSONAL_DATA_STEPS.NAME_DATA) &&
                <>
                    <OtherPersonalInformationData {...useTpidFieldSettings()} />
                    <NameData {...useNameDataSettings()} />
                </>
                }
            </div>
            <hr className="sectionSeparator"/>
            <div className="grid">
                {sectionsToRender.includes(PERSONAL_DATA_STEPS.BIRTH_DATA) && <BirthData {...brtDsSetting} />}
            </div>
            <hr className="sectionSeparator"/>
            <div className="grid">
                {sectionsToRender.includes(PERSONAL_DATA_STEPS.OTHER_PERSONAL_INFORMATION) &&
                <OtherPersonalInformationData {...useOtherPersonalInformationDataDataSettings()} />}
            </div>
            <hr className="sectionSeparator"/>
            <div className="grid">
                {sectionsToRender.includes(PERSONAL_DATA_STEPS.EMPLOYMENT_DATA) &&
                <EmploymentInformationData {...useEmploymentInformationDataSettings()} />}
            </div>
            <h5><Translate value="label.familyData"/></h5>
            <hr className="sectionSeparator"/>
            <div className="grid">
                {sectionsToRender.includes(PERSONAL_DATA_STEPS.PARENTS_DATA) &&
                <FatherData {...useFatherDataSettings()} />}
                {sectionsToRender.includes(PERSONAL_DATA_STEPS.PARENTS_DATA) &&
                <GrandfatherData {...useGrandfatherDataSettings()} />}
                {sectionsToRender.includes(PERSONAL_DATA_STEPS.PARENTS_DATA) &&
                <MotherData {...useMotherDataSettings()} />}
            </div>
            <h5><Translate value="label.maritalData"/></h5>
            <hr className="sectionSeparator"/>
            <div className="grid">
                {sectionsToRender.includes(PERSONAL_DATA_STEPS.MARITAL_DATA) &&
                <MaritalData {...useMaritalDataSettings()} />}
            </div>
            {(docTypeFromStore == EDocumentType.VR) && !isUseCaseReservePlateNumber(formValues) &&
            <div>
                <h5><Translate value="label.shareData"/></h5>
                <hr className="sectionSeparator"/>
                <div className="grid">
                    {sectionsToRender.includes(PERSONAL_DATA_STEPS.MARITAL_DATA) &&
                    <VehicleOwnershipData {...useOwnershipDataSettings(props.formNameSufix)} />}
                </div>
            </div>}
        </div>
    )
};
export default PersonalInformationTask;