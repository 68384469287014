import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  addToAppStore, completeStep,
  getFromAppStore,
  nextStep,
} from "@mb-react/mb-react-core";
import {
  Calendar,
  Message,
  NavButtons,
  SelectButton,
  Toast,
  Dropdown,
} from "@mb-react/mb-react-core";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";
import { getPath, i18nl } from "../../../../constants/i18n";
import {appStoreFields, wizardSteps} from "../../../../constants/store";
import {
  prepareDisableDatesForCalendar,
  prepareServerStringDate,
} from "../../../../sagas/generators/sagaUtil";
import { prepareTimeSlots } from "../../../../util/environment";
import { I18n } from "react-redux-i18n";
import {AppointmentService, useCreateServiceWrapper, ServiceRequestData, GetNonWorkingDaysAppointmentRequest, GetTimeSlotsAppointmentRequest } from '@mb-react/svarog-ui'
import NavButtonsLocal from "../../../reactCoreComponents/NavButtonsLocal";

var date = new Date();
const today = new Date(date.getFullYear(), date.getMonth(), date.getDay() - 1);

const DateAndTimeTask = () => {
  //service and provider data from redux store
  const selectedService = useSelector((state) =>
    getFromAppStore(state, appStoreFields.selectedService)
  );
  const selectedProvider = useSelector((state) =>
    getFromAppStore(state, appStoreFields.selectedProvider)
  );
  //original calendar data from redux store
  const originalDisabledDates = useSelector((state) =>
    getFromAppStore(state, appStoreFields.disabledDates)
  );
  const originalMaxDate = useSelector((state) =>
    getFromAppStore(state, appStoreFields.maxEndDateAP)
  );
  const originalSelectedDay = useSelector((state) =>
    getFromAppStore(state, appStoreFields.selectedDay)
  );
  //original time slot data from redux store
  const originalTimeSlots = useSelector((state) =>
    getFromAppStore(state, appStoreFields.availableTimeSlots)
  );
  const originalSelectedTimeSlot = useSelector((state) =>
    getFromAppStore(state, appStoreFields.selectedTimeSlot)
  );
  //local state data related to calendar and timeslot with initial original data if exists
  const [selectedDate, setSelectedDate] = useState(originalSelectedDay);
  const [disabledDates, setDisabledDates] = useState(
    originalDisabledDates
      ? prepareDisableDatesForCalendar(originalDisabledDates)
      : []
  );
  const [maxDate, setMaxDate] = useState(
    originalMaxDate ? moment(originalMaxDate).toDate() : null
  );
  const [timeSlotOptions, setTimeSlotOptions] = useState(
    originalTimeSlots ? prepareTimeSlots(originalTimeSlots) : null
  );
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(
    originalSelectedTimeSlot || null
  );

  const dispatch = useDispatch();
  const callService = useCreateServiceWrapper();

  const getNonWorkingDays = () => {
    let requestData = new GetNonWorkingDaysAppointmentRequest(
      prepareServerStringDate(moment(today), "YYYY-MM-DD"),
      selectedService.code,
      selectedProvider.value,
    )
    callService(new ServiceRequestData(
      AppointmentService.getNonWorkingDays,
      requestData,
      null,
      null,
      (response) => {
        let data = response.data;
        if (data && data.localDates) {
          dispatch(
            addToAppStore(appStoreFields.disabledDates, data.localDates)
          );
          setDisabledDates(prepareDisableDatesForCalendar(data.localDates));
        }
        if (data && data.maxEndDate) {
          dispatch(addToAppStore(appStoreFields.maxEndDateAP, data.maxEndDate));
          setMaxDate(moment(data.maxEndDate).toDate());
        }
      },
      null
    ))
  }
  const getTimeSlotsForDate = () => {
    setTimeSlotOptions(null)
    setSelectedTimeSlot(null)
    let requestData = new GetTimeSlotsAppointmentRequest(
      prepareServerStringDate(moment(selectedDate), "YYYY-MM-DD"),
      selectedService.code,
      selectedProvider.value,
    )
    callService(new ServiceRequestData(
      AppointmentService.getTimeSlots,
      requestData,
      null,
      null,
      (response) => {
        let data = response.data;
        dispatch(addToAppStore(appStoreFields.availableTimeSlots, data));
        setTimeSlotOptions(prepareTimeSlots(data));
      },
      null
    ))
  }
  // first render of component
  useEffect(() => {
    if (!originalDisabledDates) {
      getNonWorkingDays()
    }
  }, []);

  //execute effect bellow on every change selected date in calendar
  useEffect(() => {
    dispatch(addToAppStore(appStoreFields.selectedDay, selectedDate));
    if (selectedDate) {
      getTimeSlotsForDate()
    }
  }, [selectedDate]);

  //execute effect bellow on every change selected time slot
  useEffect(() => {
    dispatch(addToAppStore(appStoreFields.selectedTimeSlot, selectedTimeSlot));
  }, [selectedTimeSlot]);

  const toast = useRef(null);
  const onNext = () => {
    if (selectedTimeSlot) {
      dispatch(completeStep(wizardSteps.STEP3))
      dispatch(nextStep());
    } else {
      toast.current.show({
        severity: "error",
        summary: I18n.t("label.error"),
        detail: I18n.t("label.selectDateAndTime"),
        life: 3000,
      });
    }
  };

  const monthNavigatorTemplate = (e) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  };

  const yearNavigatorTemplate = (e) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  };

  return (
    <div>
      <Fragment>
        <Toast ref={toast} position="top-right" />
        <div className="grid">
          <div className="col-12 xl:col-6 text-center">
            <div
              className="card Date"
              style={{ maxHeight: "450px", overflow: "overlay" }}
            >
              <h5>
                <Translate value={getPath(i18nl.SELECT_DATE_AND_TIME)} />
              </h5>
              {maxDate && (
                <Calendar
                  id="navigatorstemplate"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.value)}
                  inline
                  disabledDates={disabledDates}
                  minDate={date}
                  maxDate={maxDate}
                  monthNavigator
                  yearNavigator
                  yearRange="2022:2023"
                  monthNavigatorTemplate={monthNavigatorTemplate}
                  yearNavigatorTemplate={yearNavigatorTemplate}
                />
              )}
            </div>
          </div>
          <div className="col-12 xl:col-6 text-center">
            <div
              className="card Time"
              style={{ maxHeight: "450px", overflow: "overlay" }}
            >
              <h5>
                <Translate value={getPath(i18nl.AVAILABLE_TIME)} />
              </h5>
              {timeSlotOptions ? (
                <SelectButton
                  value={selectedTimeSlot}
                  style={{ margin: "1rem" }}
                  options={timeSlotOptions}
                  onChange={(e) => setSelectedTimeSlot(e.value)}
                  optionLabel="name"
                  itemTemplate={(data) => <div>{data.name}</div>}
                />
              ) : (
                <Message
                  severity="info"
                  text="Please choose date to see available time slot"
                />
              )}
            </div>
          </div>
        </div>
        <NavButtonsLocal customOnNext={() => onNext()} />
      </Fragment>
    </div>
  );
};
export default DateAndTimeTask;
