import React, { useEffect, useRef } from "react";
import { fieldType, FormElement, Messages } from "@mb-react/mb-react-core";
import { useSelector } from "react-redux";
import { I18n, Translate } from "react-redux-i18n";
import { AdditionalApplicationData, genericFormFieldNames, ReferenceDocumentData } from "@mb-react/krg-getid-form-data-common";
import { getFormValues } from "@mb-react/mb-react-core";
import { forms } from "../../../../../constants/form";
import { useReferenceDocumentDataSettings } from "./requestedDocumentDataSettings/referenceDocumentDataSettings";
import { useAdditionalApplicationDataSettings } from "./requestedDocumentDataSettings/additionalApplicationDataSettings";
import { isUseCasePartlyDamagedVR, isUseCaseReplaceVR } from "../../../../../util/businesslogicUtil";

const RequestedDocumentDataTask = () => {
    const useCaseFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)["useCase"]);
    const docTypeFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)["documentType"]);
    const guidanceMessage1 = useRef(null);
    const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION));

    const isReplaceOrPartlyDamaged = isUseCaseReplaceVR(formValues) || isUseCasePartlyDamagedVR(formValues)

    useEffect(() => {
        guidanceMessage1.current.show({
            severity: 'info', sticky: true, content: (
                <React.Fragment className="mb-1">
                    <i className="pi pi-info p-2" style={{
                        borderRadius: '50%',
                        backgroundColor: 'var(--surface-b)',
                        color: 'var(--surface-d)'
                    }}></i>
                    <div className="ml-2">
                        <Translate value="message.documentDataGuidanceMessage1" />
                    </div>
                </React.Fragment>
            )
        });
    }, []);

    return (
        <div className="card">
            <Messages ref={guidanceMessage1} />
            {
                useCaseFromStore && docTypeFromStore && <div className={"useCaseHeader"}>
                    <h5>{I18n.t("label.useCaseHeader", {
                        documentType: I18n.t("label." + docTypeFromStore),
                        useCase: I18n.t("label." + useCaseFromStore)
                    })}</h5></div>
            }
            <>
                <h5><Translate value="label.requestedDocuments" /></h5>
                <hr className="sectionSeparator" />
                <div className="grid">
                    <AdditionalApplicationData {...useAdditionalApplicationDataSettings()} />
                    {isReplaceOrPartlyDamaged &&
                     <FormElement
                        additionalClass="col-12 md:col-4 xl:col-4"
                        label={"label." + genericFormFieldNames.NEW_PLATES}
                        fieldType={fieldType.CHECKBOX_CUSTOM}
                        fieldProps={{
                            name: genericFormFieldNames.NEW_PLATES
                        }}
                    />}
                </div>
                <h5><Translate value="label.referenceDocument" /></h5>
                <hr className="sectionSeparator" />
                <div className="grid">
                    <ReferenceDocumentData {...useReferenceDocumentDataSettings()} />
                </div>
            </>
        </div>
    )
};
export default RequestedDocumentDataTask;