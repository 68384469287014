import {SelectButton, getFromAppStore, removeFromAppStore} from "@mb-react/mb-react-core";
import { formFieldNames, getCookie } from "@mb-react/svarog-ui";
import React, { useEffect, useRef, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { I18n } from "react-redux-i18n";
import { ADMIN, CREATE_ACCOUNT, OVERVIEW_ACCOUNT } from "../../../../constants/admin/rights";
import {SELECTED_ACCOUNT_EMAIL, SELECTED_ACCOUNT_ID, SELECTED_EXISTING_ACCOUNT} from "../../../../constants/general";
import { applicationStore } from "../../../../constants/store";
import { checkUserRights, delete_cookie } from "../../../../util/util";
import ContainerHOC from "../../../ContainerHOC";
import RegisterAccount from "../../../workflows/login/RegisterAccount";
import AccountsTable from "./AccountsTable";
import ManageWorkflowForAccount from "./ManageWorkflowForAccount";
import SearchAccountsForm from "./SearchAccountsForm";

const AdminAccountOverview = () => {
  const searchAccount = "searchAccount";
  const createAccount = "createAccount";
  const [accountWorkflowOptions, setAccountWorkflowOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(searchAccount);
  const itemTemp = (e) => <span>{I18n.t('label.' + e)}</span>;
  const userRights = useSelector((state) => getFromAppStore(state, applicationStore.USER_RIGHTS));
  const applyWithoutAccount = getCookie("useApplyWithoutAccount");
  const manageWorkflowRef = useRef(null);
  const isApplicantReady = useSelector(state => getFromAppStore(state, applicationStore.REGISTERED_ACCOUNT_ID))
  const createAccountRights = [ADMIN, CREATE_ACCOUNT];
  const overviewAccountRights = [ADMIN, OVERVIEW_ACCOUNT];
  const dispatch = useDispatch();
  const handleAccountSelection = () => {
    // Scroll to the target section
    manageWorkflowRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const onOptionChange = (value) => {
      if (value === createAccount) {
        delete_cookie(SELECTED_EXISTING_ACCOUNT)
      }
      delete_cookie(SELECTED_ACCOUNT_ID)
      delete_cookie(SELECTED_ACCOUNT_EMAIL)
      dispatch(removeFromAppStore(applicationStore.REGISTERED_ACCOUNT_ID));
      dispatch(removeFromAppStore(applicationStore.REGISTERED_ACCOUNT_EMAIL));
      setSelectedOption(value);
  }

  useEffect(() => {
    let options = [];
    if (checkUserRights(overviewAccountRights, userRights)) {
      options.push(searchAccount)
    }
    if (checkUserRights(createAccountRights, userRights)) {
      options.push(createAccount)
    }
    setAccountWorkflowOptions(options);

    // If the currently selected option is not in the new options, update it
    if (!options.includes(selectedOption)) {
      setSelectedOption(options[0] || null);
    }
  }, [userRights, selectedOption]);

  useEffect(() => {
    delete_cookie(SELECTED_ACCOUNT_ID)
    delete_cookie(SELECTED_ACCOUNT_EMAIL)
    delete_cookie(formFieldNames.USE_CASE)
    delete_cookie(SELECTED_EXISTING_ACCOUNT)
  }, [])

  return (
    <ContainerHOC fullPage>
      <div className='card navigation'>
        <div className="card" style={{ padding: '1rem' }}>
          {!applyWithoutAccount && (
            <div>
              <div className="flex justify-content-center mb-5">
                <SelectButton
                  value={selectedOption}
                  options={accountWorkflowOptions}
                  onChange={(e) => onOptionChange(e.value)}
                  itemTemplate={itemTemp}
                />
              </div>
              {selectedOption === searchAccount && (
                <>
                  <SearchAccountsForm />
                  <AccountsTable onAccountSelect={handleAccountSelection} />
                </>
              )}
              {selectedOption === createAccount && (
                <>
                  <RegisterAccount />
                </>
              )}
            </div>
          )}
          <div ref={manageWorkflowRef}>
            {(isApplicantReady || applyWithoutAccount) && <ManageWorkflowForAccount />}
          </div>
        </div>
      </div>
    </ContainerHOC>
  );
};

export default AdminAccountOverview;
