import {
    addToAppStore,
    completeStep, getFromAppStore,
    hasValidationErrors,
    MBForm,
    NavButtons,
    nextStep
} from "@mb-react/mb-react-core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {APPLICATION_STEPS, applicationStore} from "../../../../../constants/store";
import LegalEntityTask from "./LegalEntityTask";
import {legalEntityFormValidation} from "./legalEntityFormValidation";
import {onCancel} from "../../../../../util/componentUtil";
import {useSkip} from "../../../../../util/businesslogicUtil";
import {ACCOUNT_IDENTITY} from "../../../../../constants/application";
import NavButtonsLocal from "../../../../reactCoreComponents/NavButtonsLocal";
import {isMobile} from "../../../../../util/environment";

const LegalEntityForm = (props) => {
    const errors = useSelector((state) => hasValidationErrors(state, props.formName));
    const dispatch = useDispatch();
    const legalEntityValidation = legalEntityFormValidation()
    const identityAdd = useSelector(state => getFromAppStore(state, 'identityAdd'))
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))
    const accountIdentity = useSelector(state => getFromAppStore(state, ACCOUNT_IDENTITY));

    const onSkip = () => {
        dispatch(nextStep(props.navigationName))
    }

    return (
        <MBForm formName={props.formName} stepId={APPLICATION_STEPS.LEGAL_ENTITY} warningButtonLabel={isMobile() ? "  " : null} showErrorPanel submitFunction={() => {
            if(!identityAdd){
                dispatch(completeStep(APPLICATION_STEPS.LEGAL_ENTITY))
                dispatch(nextStep(props.navigationName))
            }
            dispatch(addToAppStore('identityAdd', false))
        }} validate={legalEntityValidation.validate}>
            <LegalEntityTask formName={props.formName} useCase={props.useCase} formNameSufix={props.formNameSufix}/>
            <NavButtonsLocal firstStep={true} customOnSkip={() => onSkip()} allowSkip={useSkip(userRights, errors, accountIdentity)}
                        hideNext={useSkip(userRights, errors, accountIdentity)} customOnCancel={() => onCancel(dispatch)}/>
        </MBForm>
    );
}

export default LegalEntityForm;