import {
    completeStep,
    getFromAppStore,
    hasValidationErrors,
    MBForm,
    nextStep,
    previousStep
} from "@mb-react/mb-react-core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { forms } from "../../../../../constants/form";
import {APPLICATION_STEPS, applicationStore} from "../../../../../constants/store";
import {useDriversLicenceEnrollmentFormValidation} from "./DriversLicenseDataValidation";
import DriverLicenseTask from "./DriverLicenseTask";
import DriverLicenseCancelTask from "./DriverLicenseCancelTask";
import {onCancel} from "../../../../../util/componentUtil";
import {disableNextButton, useSkip} from "../../../../../util/businesslogicUtil";
import {ACCOUNT_IDENTITY} from "../../../../../constants/application";
import NavButtonsLocal from "../../../../reactCoreComponents/NavButtonsLocal";

const DriverLicenseForm = (props) => {

    const errors = useSelector((state) => hasValidationErrors(state, forms.DRIVER_LICENSE));
    const dispatch = useDispatch();
    const driversLicenceEnrollmentFormValidation = useDriversLicenceEnrollmentFormValidation();
    const cancelUseCase = useSelector((state) => getFromAppStore(state, 'cancelUseCaseActive'))
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))
    const accountIdentity = useSelector(state => getFromAppStore(state, ACCOUNT_IDENTITY));

    const onNext = () => {
        dispatch(nextStep(props.navigationName))
    }

    const onPrevious = () => {
        dispatch(previousStep(props.navigationName))
    }

    const isFormValid = () => {
        return !errors
    }

    const onSkip = () => {
        dispatch(nextStep(props.navigationName))
    }

    return (
        <MBForm formName={forms.DRIVER_LICENSE} stepId={APPLICATION_STEPS.DL_TASK} showErrorPanel submitFunction={() => {
            dispatch(completeStep(APPLICATION_STEPS.DL_TASK))
            dispatch(nextStep(props.navigationName))
        }} validate={driversLicenceEnrollmentFormValidation.validate}>
            {cancelUseCase ? <DriverLicenseCancelTask /> : <DriverLicenseTask />}
            {cancelUseCase ? <NavButtonsLocal firstStep={true} allowSkip={errors} hideNext={errors} /> :
                <NavButtonsLocal customOnNext={() => onNext()}
                            customOnPrevious={() => onPrevious()}
                            customOnSkip={() => onSkip()}
                            allowSkip={useSkip(userRights, errors, accountIdentity)}
                            hideNext={useSkip(userRights, errors, accountIdentity)}
                            disableNextBtn={disableNextButton(isFormValid(), userRights)}
                            customOnCancel={() => onCancel(dispatch)}/>}
        </MBForm>
    );
}

export default DriverLicenseForm;