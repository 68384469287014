import React from "react";
import HomePic from "../../../../public/media/krg-lage.png";
import { Button, addInitialValueToForm } from "@mb-react/mb-react-core";
import {
  REDIRECT_TO_LOGIN
} from "../../../constants/environment";
import { useDispatch, useSelector } from "react-redux";
import { AuthenticationService, ServiceRequestData, useCreateServiceWrapper } from "@mb-react/svarog-ui";
import { forms } from "../../../constants/form";
import { I18n, Translate } from "react-redux-i18n";
import krLogo1 from "../../../../public/media/KRG_logo.svg";


const HomeLogin = () => {
  const dispatch = useDispatch();
  useSelector(state => state.i18n.locale)

  const serviceWrapper = useCreateServiceWrapper();

  //todo check expected behavior in case free access token method throws error
  const applyWithoutAccount = () => {
    serviceWrapper(new ServiceRequestData(
      AuthenticationService.getFreeAccessToken,
      null,
      null,
      null,
      () => {dispatch(addInitialValueToForm(forms.APPLICATION, "useApplyWithoutAccount", true))},
      () => {dispatch(addInitialValueToForm(forms.APPLICATION, "useApplyWithoutAccount", true))},
      null,
      null,
        null,
        null,
        () => {dispatch(addInitialValueToForm(forms.APPLICATION, "useApplyWithoutAccount", true))}
    ))
  };


  return (
    <div className="home-page grid grid-nogutter surface-0 text-800">
      <div className="md:col-12 p-6 flex text-center align-items-center flex-center">
        <div className="sectionDiv">
          <div className="logoWithTagline">
            <img src={krLogo1} alt="Logo" width={"120px"}/>
            <div className="tagline"><Translate value="label.headerLogo"/></div>
            <div className="tagline"><Translate value="label.headerLogo1"/></div>
            <div className="tagline"><Translate value="label.headerLogo2"/></div>
          </div>
          <div className="text-6xl text-primary font-bold mb-3"><Translate value="label.applyDocOnline"/></div>
          <p className="mt-0 mb-4 text-700 line-height-3"><Translate value="label.bookAppInfoMessage"/></p>
          <Button label={<Translate value="label.login" />} type="button" className="mr-3 p-button-raised loginBtn" onClick={() => window.location = REDIRECT_TO_LOGIN} />
          <Button label={<Translate value="label.applyWithoutAccount" />} type="button" className="p-button-outlined" onClick={() => applyWithoutAccount()} />
        </div>
      </div>
    </div>
  );
}
export default HomeLogin
