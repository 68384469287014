import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { ScaleLoader } from 'react-spinners';


const SplashScreen = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {

  }, []);
    return (
      <div className='splash-screen'>
        <ScaleLoader color={"rgb(85, 73, 148)"} loading={true} size={200}/>
      </div>
    )

  };
  const mapStateToProps = (state) => {
    return {
      locale: state.i18n.locale,
    };
  };
  export default connect(mapStateToProps)(SplashScreen);
