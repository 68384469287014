export const PRODUCTION = 'production'
export const DEVELOPMENT = 'development'
export const ROOT = configurationData && configurationData.operationMode === "PROTECTED" ? "/start-client-server" : "";
export const REDIRECT_TO_HOME_PAGE = ROOT + '/home'
export const REDIRECT_TO_EPASSPORT = ROOT + '/createEPassport'
export const REDIRECT_TO_DRIVER_LICENSE = ROOT + '/driverLicense'
export const REDIRECT_TO_NEW_DRIVER_LICENSE = ROOT + '/newDriverLicense'
export const REDIRECT_TO_NEW_VEHICLE_REGISTRATION = ROOT + '/newVehicleRegistration'
export const REDIRECT_TO_EVISA = ROOT + '/createEVisa'
export const REDIRECT_TO_ID_CARD = ROOT + '/createIDCard'
export const REDIRECT_TO_LOGIN = ROOT + '/login'
export const LOG_OUT = ROOT + '/logout'
export const REDIRECT_TO_CREATE_ACCOUNT = ROOT + '/registerAccount'
export const REDIRECT_TO_ADMIN_CREATE_ACCOUNT = ROOT + '/admin/register-account'
export const REDIRECT_TO_ADMIN_ACCOUNT_OVERVIEW = ROOT + '/account-overview'
export const REDIRECT_TO_ADMIN_ACCOUNT_ADMINISTRATION = ROOT + '/account-administration'
export const REDIRECT_TO_APPOINTMENT = ROOT + '/bookAppointment'
export const REDIRECT_TO_MY_APPOINTMENTS = ROOT + '/myAppointments'
export const REDIRECT_TO_MY_APPLICATIONS = ROOT + '/myApplications'
export const REDIRECT_TO_VEHICLE_WORKFLOW = ROOT + '/vehicleWorkflow'
export const REDIRECT_TO_IDENTITY_MANAGEMENT = ROOT + '/identityManagement'
export const REDIRECT_TO_MODIFY_IDENTITY = ROOT + '/modifyIdentity'
export const REDIRECT_TO_CANCEL_IDENTITY = ROOT + '/cancelIdentity'
export const REDIRECT_TO_CHANGE_PASSWORD = ROOT + '/changePassword'
export const REDIRECT_TO_EDIT_ACCOUNT = ROOT + '/editAccount'
export const REDIRECT_TO_ADMIN_HOME = ROOT + '/admin'
export const REDIRECT_TO_CONTENT_MANAGEMENT_HOME = ROOT + '/content-management'
export const REDIRECT_TO_FAQ = ROOT + '/faq'
export const REDIRECT_TO_TEMPLATE_DOCUMENTS = ROOT + '/templateDocuments'
export const REDIRECT_TO_PRINT_ACCOUNT_REPORT = ROOT + '/print-account-report'
export const REDIRECT_TO_PRINT_APPLICATION_REPORT = ROOT + '/print-application-report'


export const modalIDs = Object.freeze({
  SPINNER: 'SpinnerModal',
  ERROR: 'ErrorModal',
  ERROR_BAD_USERNAME_PASSWORD: 'BadUsernamePassword',
  INFO: 'InfoModal',
  WARNING: 'WARNING',
  ERROR_SAME_FILE: 'ErrorModalSameFile',
  SUPPORTIVE_DOCUMENTS: 'ErrorModalSupportiveDocuments',
  SUBMIT_APPLICATION: 'SubmitApplicationModal',
  MESSAGE_MODAL: 'MessageModal',
  DELETE_APPLICATION_MODAL: 'DeleteApplicationModal',
  SHOW_APPLICATIONS_MODAL: 'ShowApplicaitonsModal',
  SHOW_APPOINTMENT_PREVIEW: 'ShowAppointmentPreview',
  CANCEL_APPOINTMENT_MODAL: 'CancelAppointment',
  RESCHEDULE_APPOINTMENT_MODAL: 'RescheduleAppointmentsModal',
  CONFIRM_MODAL: 'ConfirmModal'
})

