export const REST_MESSAGE_SUCCESS = "SUCCESS"


export const CONTENT_TYPE_ENUM = Object.freeze({
    BLOB: 'BLOB',
    OTHER: 'OTHER'
})

export const EDayOfWeek = Object.freeze({
    MONDAY: 'MONDAY',
    TUESDAY: 'TUESDAY',
    WEDNESDAY: 'WEDNESDAY',
    THURSDAY: 'THURSDAY',
    FRIDAY: 'FRIDAY',
    SATURDAY: 'SATURDAY',
    SUNDAY: 'SUNDAY'
})

export const EWorkingDay = Object.freeze({
    WORKING: 'Working Day',
    NON_WORKING: 'Non-working Day'
})

export const EEmailTemplateType = Object.freeze({
    RescheduleAppointment: 1,
    DeleteAppointment: 2

})

export const EStatusName = Object.freeze({
    SCHEDULED: 'SCHEDULED',
    CANCELED: 'CANCELED',
    ARCHIVED: 'ARCHIVED'
})


