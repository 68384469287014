import {Button, getFormValues, getFromAppStore} from "@mb-react/mb-react-core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";
import {
  REDIRECT_TO_FAQ, REDIRECT_TO_IDENTITY_MANAGEMENT,
  REDIRECT_TO_NEW_DRIVER_LICENSE
} from "../../../constants/environment";
import { applicationStore } from "../../../constants/store";
import { isIdentityActivated, isNotRegisteredUserRole, isUser } from "../../../util/businesslogicUtil";
import krLogo1 from "../../../../public/media/KRG_logo.svg";
import { setCookie } from "@mb-react/svarog-ui";
import { ACCOUNT_IDENTITY } from "../../../constants/application";
import {forms} from "../../../constants/form";

const HomeApplyWithoutAccount = () => {

  const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS));
  const [showLoggedInMessage, setShowLoggedInMessage] = useState(isNotRegisteredUserRole(userRights) && !isUser(userRights));
  const useApplyWithoutAccount = useSelector((state) => getFormValues(state, forms.APPLICATION)?.["useApplyWithoutAccount"])
  let isIdentityActive = isIdentityActivated()
  useSelector(state => state.i18n.locale)

  useEffect(() => {
    setShowLoggedInMessage(isNotRegisteredUserRole(userRights) && !isUser(userRights));
  }, [userRights]);

  function onCreateIdentity() {
    setCookie(ACCOUNT_IDENTITY, true)
    window.location = REDIRECT_TO_IDENTITY_MANAGEMENT
  }

  return (
    <div className="home-page grid grid-nogutter surface-0 text-800">
      <div className="md:col-12 p-6 flex text-center align-items-center flex-center">
        {(isIdentityActive || isNotRegisteredUserRole(userRights) || useApplyWithoutAccount) ?
          <div className="sectionDiv">
            <div className="logoWithTagline">
              <img src={krLogo1} alt="Logo" width={"120px"}/>
              <div className="tagline"><Translate value="label.headerLogo"/></div>
              <div className="tagline"><Translate value="label.headerLogo1"/></div>
              <div className="tagline"><Translate value="label.headerLogo2"/></div>
            </div>
            <div className="text-6xl text-primary font-bold mb-3"><Translate value="label.applyDocOnline"/></div>
            {!configurationData.dlGoLive &&
            <p className="mt-0 mb-4 text-700 line-height-3"><Translate value="label.bookAppInfoMessage"/></p>}
            <div className="buttonsHomeWrap flex" >
              {!configurationData.dlGoLive && !configurationData.useDDL && <Button label={<Translate value="label.applyForDocument" />} type="button" className="p-button-raised" onClick={() => window.location = REDIRECT_TO_NEW_DRIVER_LICENSE} />}
              <Button label={<Translate value="label.howToApply2" />} type="button" className="p-button-outlined" onClick={() => window.location = REDIRECT_TO_FAQ} />
            </div>
            <br />
            {showLoggedInMessage &&
              <p className="mt-0 mb-4 text-700 line-height-3"><Translate value="label.notLoggedInMessage" /></p>
            }
          </div> :
          <div className="sectionDiv">
            <div className="logoWithTagline">
              <img src={krLogo1} alt="Logo" width={"120px"}/>
              <div className="tagline"><Translate value="label.headerLogo"/></div>
              <div className="tagline"><Translate value="label.headerLogo1"/></div>
              <div className="tagline"><Translate value="label.headerLogo2"/></div>
            </div>
            <div className="text-6xl text-primary font-bold mb-3"><Translate value="label.createIdentityInfoMessage"/>
            </div>
            <div className="buttonsHomeWrap">
              <Button label={<Translate value="label.createIdentity" />} type="button" className="p-button-raised" onClick={() => onCreateIdentity()} />
            </div>
          </div>}
      </div>

    </div>
  );
}
export default HomeApplyWithoutAccount
