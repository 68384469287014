import { genericFormFieldNames } from "@mb-react/krg-getid-form-data-common";
import {
    getFormValues, getFromAppStore, removeFromDictionaryStore
} from "@mb-react/mb-react-core";
import { formFieldNames } from "@mb-react/svarog-ui";
import { useMemo } from "react";
import { useForm } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { formFieldsMeta, forms } from "../../../../../../constants/form";
import { dictionaryReference, projectDictionaries } from "../../../../../../constants/general";
import { useHandleCommonServices } from "../../../../../../services/commonServiceHandler";
import {
    isMandatory,
    isOperationModeProtected,
    isReadOnly,
    isUseCaseReservePlateNumber
} from "../../../../../../util/businesslogicUtil";
import {ACCOUNT_IDENTITY} from "../../../../../../constants/application";

export const useBirthDataSettings = () => {
    const form = useForm();
    const useCase = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.USE_CASE])
    const documentType = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.DOCUMENT_TYPE])
    const workflow = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldsMeta.WORKFLOW])
    const preSummaryAdded = useSelector((state) => getFromAppStore(state, 'preSummaryAdded'))
    const formValuesApp = useSelector((state) => getFormValues(state, forms.APPLICATION))

    let reservePlateNumberReadOnly = !isOperationModeProtected() && isUseCaseReservePlateNumber(formValuesApp);

    let formValues = form?.getState()?.values ?? {}

    const dispatch = useDispatch();
    const handleCommonServices = useHandleCommonServices()

    const accountIdentity = useSelector(state => getFromAppStore(state, ACCOUNT_IDENTITY));

    let years16Before = new Date();
    years16Before.setFullYear(years16Before.getFullYear() - 16);
    years16Before.setDate(years16Before.getDate() + 2)
    years16Before.setMonth(years16Before.getMonth() + 2)

    let years125Before = new Date();
    years125Before.setFullYear(years125Before.getFullYear() - 125);

    const onCountryChange = (event) => {
        form.change(formFieldNames.PLACE_OF_BIRTH_PROVINCE, '')
        form.change(formFieldNames.PLACE_OF_BIRTH_CITY, '')
        form.change(genericFormFieldNames.PLACE_OF_BIRTH_VILLAGE, '')
        dispatch(removeFromDictionaryStore(projectDictionaries.VILLAGES, event.target.value, dictionaryReference.VILLAGES_PLACE_OF_BIRTH))
        dispatch(removeFromDictionaryStore(projectDictionaries.CITIES, event.target.value, dictionaryReference.CITIES_PLACE_OF_BIRTH))
    };

    const onProvinceChange = (event) => {
        form.change(formFieldNames.PLACE_OF_BIRTH_CITY, '')
        form.change(genericFormFieldNames.PLACE_OF_BIRTH_VILLAGE, '')
        dispatch(removeFromDictionaryStore(dictionaryReference.VILLAGES_PLACE_OF_BIRTH))
        handleCommonServices.handleGetSingleDictionary(projectDictionaries.CITIES, event.target.value, dictionaryReference.CITIES_PLACE_OF_BIRTH)
    };

    const onCityChange = (event) => {
        form.change(genericFormFieldNames.ADDRESS_VILLAGE, '')
        handleCommonServices.handleGetSingleDictionary(projectDictionaries.VILLAGES, event.target.value, dictionaryReference.VILLAGES_PLACE_OF_BIRTH)
    };

    const handleDateOfBirthSettings = useMemo(() => {
        let birthDataProps = {
            [formFieldNames.DATE_OF_BIRTH]: {
                [formFieldNames.FIELD_PROPS]: {
                    viewDate: years16Before,
                    minDate: years125Before, maxDate: years16Before,
                    dateFormat: "dd/mm/yy",
                    readOnly: reservePlateNumberReadOnly || preSummaryAdded || isReadOnly(formValues, formFieldNames.DATE_OF_BIRTH, useCase, documentType, workflow)
                },
                required: isMandatory(formValues, formFieldNames.DATE_OF_BIRTH, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            },
        }
        return birthDataProps
    }, [formValues[formFieldNames.TPID]])

    const handlePlaceOfBirthCountrySettings = useMemo(() => {
        let birthDataProps = {
            [formFieldNames.PLACE_OF_BIRTH_COUNTRY]: {
                [formFieldNames.FIELD_PROPS]: {
                    filter: true,
                    showClear: true,
                    filterBy: "label",
                    readOnly: reservePlateNumberReadOnly || preSummaryAdded || isReadOnly(formValues, formFieldNames.PLACE_OF_BIRTH_COUNTRY, useCase, documentType, workflow),
                    reference: projectDictionaries.COUNTRY_CODES,
                    [formFieldNames.ON_CHANGE]: onCountryChange
                },
                required: isMandatory(formValues, formFieldNames.PLACE_OF_BIRTH_COUNTRY, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return birthDataProps
    }, [formValues[formFieldNames.TPID]])

    const handlePlaceOfBirthProvinceSettings = useMemo(() => {
        let birthDataProps = {
            [formFieldNames.PLACE_OF_BIRTH_PROVINCE]: {
                [formFieldNames.FIELD_PROPS]: {
                    filter: true,
                    showClear: true,
                    filterBy: "label",
                    readOnly: reservePlateNumberReadOnly || preSummaryAdded || isReadOnly(formValues, formFieldNames.PLACE_OF_BIRTH_PROVINCE, useCase, documentType, workflow),
                    reference: projectDictionaries.PROVINCES,
                    [formFieldNames.ON_CHANGE]: onProvinceChange
                },
                required: isMandatory(formValues, formFieldNames.PLACE_OF_BIRTH_PROVINCE, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return birthDataProps
    }, [formValues.placeOfBirthCountry, formValues[formFieldNames.TPID]])


    const handlePlaceOfBirthCitySettings = useMemo(() => {
        let birthDataProps = {
            [formFieldNames.PLACE_OF_BIRTH_CITY]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || preSummaryAdded || isReadOnly(formValues, formFieldNames.PLACE_OF_BIRTH_CITY, useCase, documentType, workflow),
                    reference: dictionaryReference.CITIES_PLACE_OF_BIRTH,
                    [formFieldNames.ON_CHANGE]: onCityChange
                },
                required: isMandatory(formValues, formFieldNames.PLACE_OF_BIRTH_CITY, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return birthDataProps
    }, [formValues.placeOfBirthProvince, formValues.placeOfBirthCountry, formValues[formFieldNames.TPID]])

    const handlePlaceOfBirthVillageSettings = useMemo(() => {
        let birthDataProps = {
            [genericFormFieldNames.PLACE_OF_BIRTH_VILLAGE]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || preSummaryAdded || isReadOnly(formValues, genericFormFieldNames.PLACE_OF_BIRTH_VILLAGE, useCase, documentType, workflow),
                    reference: dictionaryReference.VILLAGES_PLACE_OF_BIRTH,
                },
                required: isMandatory(formValues, genericFormFieldNames.PLACE_OF_BIRTH_VILLAGE, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return birthDataProps
    }, [formValues.placeOfBirthCity, formValues.placeOfBirthProvince, formValues.placeOfBirthCountry, formValues[formFieldNames.TPID]])

    return { ...handleDateOfBirthSettings, ...handlePlaceOfBirthCountrySettings, ...handlePlaceOfBirthProvinceSettings,
        ...handlePlaceOfBirthCitySettings, ...handlePlaceOfBirthVillageSettings }
};