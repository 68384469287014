import {getFormValues, getFromAppStore} from "@mb-react/mb-react-core";
import { EDocumentType, EUseCases, formFieldNames } from "@mb-react/svarog-ui";
import { useSelector } from "react-redux";
import { mandatoryFieldListCANCEL_IM, mandatoryFieldListMODIFY_IM, mandatoryFieldListNEW_IM, readOnlyFieldListIM } from "../constants/fieldState/fieldStateIM";
import {
  mandatoryFieldListCANCEL_DL,
  mandatoryFieldListEXPIRED_DL,
  mandatoryFieldListEXTEND_DL,
  mandatoryFieldListFOREIGN_DL,
  mandatoryFieldListNEW_DL,
  mandatoryFieldListPARTLY_DAMAGED_DL,
  mandatoryFieldListREPLACE_DL,
  mandatoryFieldListREREGISTER_DL,
  mandatoryTpIdList,
  readOnlyFieldListEXPIRED,
  readOnlyFieldListEXTEND_DL,
  readOnlyFieldListFOREIGN_DL,
  readOnlyFieldListNEW_DL,
  readOnlyFieldListPARTLY_DAMAGED_DL,
  readOnlyFieldListREPLACE_DL,
  readOnlyFieldListREREGISTER_DL
} from "../constants/fieldState/fieldsStateDl";
import {
  mandatoryFieldListCHANGE_OWNERSHIP_SALE_AND_BUY,
  mandatoryFieldListEXCHANGE_REGISTRATION,
  mandatoryFieldListNEW_VR, mandatoryFieldListPARTLY_DAMAGED_VR,
  mandatoryFieldListREPLACE_VR,
  mandatoryFieldListREREGISTER_VR, mandatoryFieldListRESERVE_PLATE_NUMBER, readOnlyFieldListCHANGE_OWNERSHIP,
  readOnlyFieldListNEW_VR,
  readOnlyFieldListPARTLY_DAMAGED_VR,
  readOnlyFieldListREPLACE_VR,
  readOnlyFieldListREREGISTER_VEHICLE
} from "../constants/fieldState/fieldsStateVR";
import {
  specificMandatoryBehaviorFieldList,
  specificReadOnlyBehaviorFieldList
} from "../constants/fieldState/specificFieldsList";
import { formFieldsMeta, forms } from "../constants/form";
import { ENROLLMENT } from "../constants/general";
import { specificMandatoryBehavior, specificReadOnlyBehavior } from "../util/specificBusinessLogicBehaviorUtil";
import {ACCOUNT_IDENTITY} from "../constants/application";

export const useGlobalValidation = () => {
  const documentType = useSelector((state) => getFormValues(state, forms.APPLICATION))[formFieldNames.DOCUMENT_TYPE]
  const useCase = useSelector((state) => getFormValues(state, forms.APPLICATION))[formFieldNames.USE_CASE]
  const workflow = useSelector((state) => getFormValues(state, forms.APPLICATION))[formFieldsMeta.WORKFLOW]
  const tpid = useSelector((state) => getFormValues(state, forms.PERSONAL_INFORMATION)[formFieldNames.TPID])
  const tpidVR = useSelector((state) => getFormValues(state, forms.PERSONAL_INFORMATION + "Source")[formFieldNames.TPID])
  const accountIdentity = useSelector(state => getFromAppStore(state, ACCOUNT_IDENTITY));

  const isMandatory = (formValues, fieldName) => {
    if (isReadOnly(formValues, fieldName)) {
      return false
    } else {
      let defaultList = [];
      if (!accountIdentity && (tpid && tpid != undefined || tpidVR && tpidVR != undefined)) {
        defaultList = mandatoryTpIdList
      } else if (documentType && documentType == EDocumentType.DL) {
        if (workflow && workflow == ENROLLMENT) {
          if (useCase && useCase == EUseCases.NEW) {
            defaultList = mandatoryFieldListNEW_DL
          } else if (useCase && useCase == EUseCases.RE_REGISTER) {
            defaultList = mandatoryFieldListREREGISTER_DL
          } else if (useCase && useCase == EUseCases.REPLACE) {
            defaultList = mandatoryFieldListREPLACE_DL
          } else if (useCase && useCase == EUseCases.EXTEND) {
            defaultList = mandatoryFieldListEXTEND_DL
          } else if (useCase && useCase == EUseCases.EXPIRED) {
            defaultList = mandatoryFieldListEXPIRED_DL
          } else if (useCase && useCase == EUseCases.FOREIGN) {
            defaultList = mandatoryFieldListFOREIGN_DL
          } else if (useCase && useCase == EUseCases.PARTLY_DAMAGED) {
            defaultList = mandatoryFieldListPARTLY_DAMAGED_DL
          } else if (useCase && useCase == EUseCases.CANCEL_DOCUMENT) {
            defaultList = mandatoryFieldListCANCEL_DL
          }
        }
      } else if (documentType && documentType == EDocumentType.VR){
        if(workflow && workflow == ENROLLMENT){
          if (useCase && useCase == EUseCases.NEW) {
            defaultList = mandatoryFieldListNEW_VR
          } else if (useCase && useCase == EUseCases.RE_REGISTER) {
            defaultList = mandatoryFieldListREREGISTER_VR
          } else if (useCase && useCase == EUseCases.REPLACE) {
            defaultList = mandatoryFieldListREPLACE_VR
          } else if (useCase && useCase == EUseCases.PARTLY_DAMAGED) {
            defaultList = mandatoryFieldListPARTLY_DAMAGED_VR
          } else if (useCase && useCase == EUseCases.RESERVE_PLATE_NUMBER) {
            defaultList = mandatoryFieldListRESERVE_PLATE_NUMBER
          } else if (useCase && useCase == EUseCases.EXCHANGE_REGISTRATION) {
            defaultList = mandatoryFieldListEXCHANGE_REGISTRATION
          } else if (useCase && useCase == EUseCases.CHANGE_OWNERSHIP) {
            defaultList = mandatoryFieldListCHANGE_OWNERSHIP_SALE_AND_BUY
          }else{
            defaultList = mandatoryFieldListNEW_VR
          }
        }
      } else if (documentType && documentType == "IDENTITY") {
        if (workflow && workflow == ENROLLMENT) {
          if (useCase && useCase == "NEW") {
            defaultList = mandatoryFieldListNEW_IM
          } else if (useCase && useCase == "MODIFICATION") {
            defaultList = mandatoryFieldListMODIFY_IM
          } else if (useCase && useCase == "CANCEL_PERSON") {
            defaultList = mandatoryFieldListCANCEL_IM
          }
        }
      }
      if (specificMandatoryBehaviorFieldList.includes(fieldName)) {
        return specificMandatoryBehavior(formValues, fieldName, useCase, documentType, workflow, defaultList.includes(fieldName))
      } else {
        return defaultList.includes(fieldName)
      }
    }
  }
  const isReadOnly = (formValues, fieldName) => {
    let defaultList = [];
    if (documentType && documentType == EDocumentType.DL) {
      if (workflow && workflow == ENROLLMENT) {
        if (useCase && useCase == EUseCases.NEW) {
          defaultList = readOnlyFieldListNEW_DL
        } else if (useCase && useCase == EUseCases.RE_REGISTER) {
          defaultList = readOnlyFieldListREREGISTER_DL
        } else if (useCase && useCase == EUseCases.REPLACE) {
          defaultList = readOnlyFieldListREPLACE_DL
        } else if (useCase && useCase == EUseCases.EXTEND) {
          defaultList = readOnlyFieldListEXTEND_DL
        } else if (useCase && useCase == EUseCases.EXPIRED) {
          defaultList = readOnlyFieldListEXPIRED
        } else if (useCase && useCase == EUseCases.FOREIGN) {
          defaultList = readOnlyFieldListFOREIGN_DL
        } else if (useCase && useCase == EUseCases.PARTLY_DAMAGED) {
          defaultList = readOnlyFieldListPARTLY_DAMAGED_DL
        }
      }
    }else if (documentType && documentType == EDocumentType.VR){
      if(workflow && workflow == ENROLLMENT){
        if (useCase && useCase == EUseCases.NEW) {
          defaultList = readOnlyFieldListNEW_VR
        } else if (useCase && useCase == EUseCases.RE_REGISTER) {
          defaultList = readOnlyFieldListREREGISTER_VEHICLE
        } else if (useCase && useCase == EUseCases.REPLACE) {
          defaultList = readOnlyFieldListREPLACE_VR
        } else if (useCase && useCase == EUseCases.PARTLY_DAMAGED) {
          defaultList = readOnlyFieldListPARTLY_DAMAGED_VR
        } else if (useCase && useCase == EUseCases.RESERVE_PLATE_NUMBER) {
          defaultList = readOnlyFieldListNEW_VR
        } else if (useCase && useCase == EUseCases.EXCHANGE_REGISTRATION) {
          defaultList = readOnlyFieldListNEW_VR
        } else if (useCase && useCase == EUseCases.CHANGE_OWNERSHIP) {
          defaultList = readOnlyFieldListCHANGE_OWNERSHIP
        }else{
          defaultList = readOnlyFieldListNEW_VR
        }
      }
    } else if (documentType && documentType == "IDENTITY"){
        if(useCase && useCase == "CANCEL_PERSON"){
          defaultList = readOnlyFieldListIM;
        }
    }
    if (specificReadOnlyBehaviorFieldList.includes(fieldName)) {
      return specificReadOnlyBehavior(formValues, fieldName, useCase, documentType, workflow, defaultList.includes(fieldName));
    } else {
      return defaultList.includes(fieldName);
    }
  }
  return {
    isMandatory,
    isReadOnly
  }
}