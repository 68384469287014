import {addToAppStore, openModal, showToast, toastIDs} from '@mb-react/mb-react-core';
import {change} from '@mb-react/mb-react-core';
import { formFieldNames } from '@mb-react/svarog-ui';
import {useDispatch} from 'react-redux';
import {modalIDs} from '../../constants/environment';
import {restErrors} from '../../constants/general';
import {i18nl} from '../../constants/i18n';
import {applicationStore} from '../../constants/store';
import {I18n} from "react-redux-i18n";

export function useHandleRegisterAccountErrorResult() {
  const dispatch = useDispatch();
  const handleResponse = (response) => {
    let responseMessage = "err.";
    if (response.message) {
      responseMessage += response.message;
    } else {
      responseMessage += restErrors.GENERAL_SERVER_ERROR;
    }
    dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {
      messageId: responseMessage
    }));
  }
  return handleResponse;
}

export function useHandleAccountRecoveryChangePasswordErrorResult() {
  const dispatch = useDispatch();
  const handleResponse = (response) => {
    let error = {message: null};
    let responseMessage;

    if (response.message) {
      responseMessage = response.message;
    } else if (response.data) {
      responseMessage = response.data;
    } else {
      responseMessage = restErrors.GENERAL_SERVER_ERROR;
    }

    switch (responseMessage) {
      case restErrors.ERROR_WRONG_CAPTCHA:
        dispatch(addToAppStore(applicationStore.ERROR_MESSAGE, 'err.' + i18nl.REFRESH_CAPTCHA));
        break;
      case restErrors.ERROR_NO_ACCOUNT_WITH_GIVEN_USERNAME:
        dispatch(addToAppStore(applicationStore.ERROR_MESSAGE, 'err.' + i18nl.ERROR_NO_ACCOUNT_WITH_GIVEN_USERNAME));
        break;
      case restErrors.ERROR_ACCOUNT_NOT_LOCKED:
        dispatch(addToAppStore(applicationStore.ERROR_MESSAGE, 'err.' + i18nl.ERROR_ACCOUNT_NOT_LOCKED));
        break;
      default:
        error.message = restErrors.GENERAL_SERVER_ERROR;
        dispatch(addToAppStore(applicationStore.ERROR_MESSAGE, 'err.' + error.message));
    }
    dispatch(openModal(modalIDs.ERROR));
  };
  return handleResponse
}

export function useChangePasswordErrorResult() {
  const dispatch = useDispatch();
  const handleResponse = (response) => {
    let error = {message: null};
    let responseMessage;

    if (response.message) {
      responseMessage = response.message;
    } else if (response.data) {
      responseMessage = response.data;
    } else {
      responseMessage = restErrors.GENERAL_SERVER_ERROR;
    }

    switch (responseMessage) {
      case restErrors.ERROR_WRONG_CAPTCHA:
        dispatch(addToAppStore(applicationStore.ERROR_MESSAGE, 'err.' + i18nl.REFRESH_CAPTCHA));
        break;
      case restErrors.ERROR_NO_ACCOUNT_WITH_GIVEN_USERNAME:
        dispatch(addToAppStore(applicationStore.ERROR_MESSAGE, 'err.' + i18nl.ERROR_NO_ACCOUNT_WITH_GIVEN_USERNAME));
        break;
      case restErrors.ERROR_BAD_PASSWORD:
        dispatch(addToAppStore(applicationStore.ERROR_MESSAGE, 'err.' + i18nl.BAD_PASSWORD));
        break;
      default:
        error.message = restErrors.GENERAL_SERVER_ERROR;
        dispatch(addToAppStore(applicationStore.ERROR_MESSAGE, 'err.' + error.message));
    }

    // yield call(getCaptcha);
    dispatch(openModal(modalIDs.ERROR));
  };
  return handleResponse
}

export function useHandleCaptchaErrorResult() {
  const dispatch = useDispatch();
  const handleResponse = (response) => {
    let error = {message: null};
    switch (response.data) {
      case restErrors.ERROR_WRONG_CAPTCHA:
        dispatch(addToAppStore(applicationStore.ERROR_MESSAGE, 'err.' + i18nl.REFRESH_CAPTCHA));
        break;
      case restErrors.ERROR_USERNAME_NOT_UNIQUE:
        dispatch(addToAppStore(applicationStore.ERROR_MESSAGE, 'err.' + i18nl.USERNAME_NOT_UNIQUE));
        break;
      case restErrors.ERROR_EMAIL_NOT_UNIQUE:
        dispatch(addToAppStore(applicationStore.ERROR_MESSAGE, 'err.' + i18nl.EMAIL_NOT_UNIQUE));
        break;
      case restErrors.ERROR_REPEAT_PASS_NOT_SAME:
        dispatch(addToAppStore(applicationStore.ERROR_MESSAGE, 'err.' + i18nl.REPEAT_PASS_NOT_SAME));
        break;
      default:
        error.message = restErrors.GENERAL_SERVER_ERROR;
    }
    dispatch(openModal(modalIDs.ERROR));
  };
  return handleResponse
}

export function useEditAccountErrorResult() {
  const dispatch = useDispatch();
  const handleResponse = (response) => {
    let error = {message: null};
    switch (response.data) {
      case restErrors.ERROR_WRONG_CAPTCHA:
        dispatch(addToAppStore(applicationStore.ERROR_MESSAGE, 'err.' + i18nl.REFRESH_CAPTCHA));
        break;
      default:
        error.message = restErrors.GENERAL_SERVER_ERROR;
    }
    dispatch(openModal(modalIDs.ERROR));
  };
  return handleResponse
}

export function useChangeAccountStateErrorResult() {
  const dispatch = useDispatch();
  const handleResponse = (response) => {
    let error = {message: restErrors.GENERAL_SERVER_ERROR};

    dispatch(openModal(modalIDs.ERROR));
  };
  return handleResponse
}

export function useHandleGetAccountDataSuccessResult() {
  const dispatch = useDispatch();
  const handleResponse = (response) => {
    if (response.data) {
      let accountData = response.data;
      if (accountData[formFieldNames.USER_PHONE]) {
        dispatch(change(formFieldNames.USER_PHONE, accountData[formFieldNames.USER_PHONE]))
      }
      if (accountData[formFieldNames.USER_EMAIL]) {
        dispatch(change(formFieldNames.USER_EMAIL, accountData[formFieldNames.USER_EMAIL]))
      }
      if (accountData[formFieldNames.USERNAME]) {
        dispatch(change(formFieldNames.USERNAME, accountData[formFieldNames.USERNAME]))
      }
    }
  };
  return handleResponse
}
export function useCheckCaptchaErrorResult() {
  const dispatch = useDispatch();
  const handleResponse = (response) => {
    let error = {message: null};
    switch (response.message) {
      case restErrors.ERROR_WRONG_CAPTCHA:
        dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {messageId: I18n.t("err." + response)}))
        break;
      default:
        error.message = restErrors.GENERAL_SERVER_ERROR;
    }
  };
  return handleResponse
}
