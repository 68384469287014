import {Button, FormElement, MBForm, fieldType, showToast, toastIDs} from "@mb-react/mb-react-core";
import {
    formFieldNames,
} from "@mb-react/svarog-ui";
import React from "react";
import { I18n } from "react-redux-i18n";
import { forms } from "../../../../constants/form";
import {useDispatch} from "react-redux";

const ApplicationStatusSearchForm = (props) => {
  const { searchApplicationFunc } = props;
  const dispatch = useDispatch();

  const searchApplication = (data) => {
    if(searchApplicationFunc && data && (data.applicationId && data.applicationId != "") || (data.email && data.email != "")){
        searchApplicationFunc(1, 10, data)
    }else{
      dispatch(showToast(toastIDs.MESSAGE_TOAST, 'warn', {messageId: I18n.t("message.searchApplicationsParamMissing")}))
    }

  };

  return (
    <div>
      <MBForm
        formName={forms.ADDRESS_DATA}
        submitFunction={(data) => {searchApplication(data)}}
      >
        <div className="grid appSearchForm">
          <div className="col-12 xl:col-4">
            <FormElement
              label="label.applicationId"
              fieldType={fieldType.INPUT_TEXT}
              fieldProps={{ name: "applicationId" }}
            />
          </div>
          <div className="col-12 xl:col-4 ">
            <FormElement
                label="label.email"
                fieldType={fieldType.INPUT_TEXT}
                fieldProps={{ name: formFieldNames.EMAIL }}
            />
          </div>
          <div className="col-12 xl:col-4">
            <Button
              type="submit"
              label={I18n.t("action.search")}
              className="p-button-primary"
              icon="pi pi-search"
            />
          </div>
        </div>
      </MBForm>
    </div>
  );
};
export default ApplicationStatusSearchForm;
