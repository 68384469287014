import React from "react";
import ContainerHOC from "../../../ContainerHOC";
import AccountsTable from "./AccountsTable";
import SearchAccountsForm from "./SearchAccountsForm";

const PrintAccountReport = () => {
  const handleAccountSelection = () => {
    console.log("do something...")
  };

  return (
    <ContainerHOC fullPage>
      <div className='card navigation'>
        <div className="card" style={{ padding: '1rem' }}>
            <div>
                <>
                  <SearchAccountsForm />
                  <AccountsTable onAccountSelect={handleAccountSelection} printAccountReport={true} />
                </>
            </div>
        </div>
      </div>
    </ContainerHOC>
  );
};

export default PrintAccountReport;
