import { addInitialValueToForm, fieldType } from "@mb-react/mb-react-core";
import React from "react";

import { Button, FormElement, MBForm } from "@mb-react/mb-react-core";
import {
  AuthenticationService,
  ServiceRequestData,
  formFieldNames,
  isRequired,
  useCreateServiceWrapper
} from '@mb-react/svarog-ui';
import { useDispatch } from "react-redux";
import { I18n, Translate } from "react-redux-i18n";
import { forms } from "../../../constants/form";
import {
  useHandleGetFreeAccessTokenSuccessResult,
  useHandleLoginErrorResult,
  useHandleLoginSuccessResult
} from "../../../services/authentication/handleAuthServiceResponse";
import krLogo1 from "../../../../public/media/KRG_logo.svg";

const LoginProtected = () => {
  const serviceWrapper = useCreateServiceWrapper();

  const handleSuccessResponse = useHandleLoginSuccessResult();
  const handleErrorResponse = useHandleLoginErrorResult();
  const handleGetFreeAccessTokenSuccessResponse = useHandleGetFreeAccessTokenSuccessResult();
  const dispatch = useDispatch();

  const submitLogin = (data) => {
    if (data != null) {
      const loginParams = {
        username: data.username,
        password: data.password
      };
      serviceWrapper(new ServiceRequestData(
        AuthenticationService.loginUM,
        loginParams,
        handleSuccessResponse,
        handleErrorResponse,
        null
      ))
    }
  };

  const applyWithoutAccount = () => {
    dispatch(addInitialValueToForm(forms.APPLICATION, "useApplyWithoutAccount", true))

    serviceWrapper(new ServiceRequestData(
      AuthenticationService.getFreeAccessToken,
      null,
      handleGetFreeAccessTokenSuccessResponse,
      null,
      null,
      null,
      null,
      null
    ))
  };

  const validationFunc = (values) => {
    let errors = {}

    isRequired(values, formFieldNames.USERNAME, errors)
    isRequired(values, formFieldNames.PASSWORD, errors)

    return errors
  }

  return (
    <MBForm formName={forms.REGISTER_ACCOUNT}
      validationMode="Restrict"
      submitFunction={(data) => submitLogin(data)}
      validate={validationFunc}>
      <div className="flex align-items-center justify-content-center mt-2">
        <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div className="text-center mb-5">
            <div className="logoWithTagline">
              <img src={krLogo1} alt="Logo" width={"120px"}/>
              <div className="tagline"><Translate value="label.headerLogo"/></div>
              <div className="tagline"><Translate value="label.headerLogo1"/></div>
              <div className="tagline"><Translate value="label.headerLogo2"/></div>
            </div>
            <div className="text-900 text-5xl font-bold mb-3"><Translate
                value={"message.walcomeBack"}/></div>
          </div>

          <div>
            <FormElement
                label='label.username'
                fieldType={fieldType.INPUT_TEXT}
                required
                fieldProps={{name: formFieldNames.USERNAME}}
            />
            <FormElement
                label='label.password'
                fieldType={fieldType.PASSWORD}
                required
              fieldProps={{
                name: formFieldNames.PASSWORD,
                feedback: false,
                toggleMask: true,
              }}
            />
            <Button label={<Translate value="action.login" />} type="submit"
              icon="pi pi-user" className="w-full" />
          </div>
          <br />
        </div>
      </div>
    </MBForm>
  );
};
export default LoginProtected;
