import { Button, Captcha, FormElement, MBForm, Message, fieldType, showToast, toastIDs, } from "@mb-react/mb-react-core";
import { formFieldNames, isRequired } from "@mb-react/svarog-ui";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { I18n, Translate } from "react-redux-i18n";
import { forms } from "../../../constants/form";
import { restErrors } from "../../../constants/general";
import { useHandleAccountServices } from "../../../services/account/accountServiceHandler";
import krLogo1 from "../../../../public/media/KRG_logo.svg";

const AccountRecoveryChangePassword = (props) => {
  const handleAccountServices = useHandleAccountServices();
  const dispatch = useDispatch();

  const [captchaData, setCaptchaData] = useState();
  const [captchaUrl, setCaptchaUrl] = useState(null);
  const [accountRecoveryChangePasswordSuccessfullyPerformed, setAccountRecoveryChangePasswordSuccessfullyPerformed] = useState(false);

  const submitAccountRecoveryChangePassword = (data, form) => {
    if (data != null && captchaData != null) {
      const accountRecoveryChangePasswordParams = {
        username: data.username,
        captcha: data.captcha,
        recoveryReason: props.recoveryReason,
        captchaId: captchaData.id,
      };
      handleAccountServices.handleRecoveryChangePass(accountRecoveryChangePasswordParams, () => {
        setAccountRecoveryChangePasswordSuccessfullyPerformed(true)
      }, (response) => {
        let responseMessage = "err.";
        if (response.message) {
          responseMessage += response.message;
        } else {
          responseMessage += restErrors.GENERAL_SERVER_ERROR;
        }
        dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {
          messageId: responseMessage
        }));

        getCaptcha();
        form.change(formFieldNames.CAPTCHA, '')
      })

    }
  };

  const prepareCaptchaImage = (captchaData) => {
    if (captchaData != null) {
      return "data:image/jpg;base64," + captchaData.base64;
    }
    return null;
  };

  const prepareCaptcha = (data) => {
    if (data) {
      setCaptchaData(data);
      setCaptchaUrl(prepareCaptchaImage(data));
    }
  };

  const getCaptcha = () => {
    handleAccountServices.handleGetCaptcha(prepareCaptcha)
  };

  useEffect(() => {
    getCaptcha();
  }, []);

  const validateAccountRecovery = (values) => {
    let errors = {}
    isRequired(values, formFieldNames.USERNAME, errors)
    isRequired(values, formFieldNames.CAPTCHA, errors)

    return errors;
  }

  return (
    <Fragment>
      {accountRecoveryChangePasswordSuccessfullyPerformed ?
        <div className="flex align-items-center justify-content-center mt-2">
          <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
            <div className="text-center mb-5">
              <div className="logoWithTagline">
                <img src={krLogo1} alt="Logo" width={"120px"}/>
                <div className="tagline"><Translate value="label.headerLogo"/></div>
                <div className="tagline"><Translate value="label.headerLogo1"/></div>
                <div className="tagline"><Translate value="label.headerLogo2"/></div>
              </div>
              <div className="text-900 text-5xl font-bold mb-3">
                <Message
                    severity="success"
                    text={props.recoveryReason !== 'FORGOT_PASSWORD'
                        ? <Translate value="label.accountRecoverySuccess"/>
                        : <Translate value="label.changePasswordSuccess" />
                  } />
              </div>
            </div>

            <div>
              <Button label={I18n.t("action.login")} onClick={() => (window.location = "/login")}
                icon="pi pi-user" className="w-full" />
            </div>
          </div>
        </div>
        :
        <MBForm formName={forms.ACCOUNT_RECOVERY}
          submitFunction={(data, form) => submitAccountRecoveryChangePassword(data, form)}
          validate={validateAccountRecovery}
        >
          <div className="flex align-items-center justify-content-center mt-2">
            <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
              <div className="text-center mb-5">
                <div className="logoWithTagline">
                  <img src={krLogo1} alt="Logo" width={"120px"}/>
                  <div className="tagline"><Translate value="label.headerLogo"/></div>
                  <div className="tagline"><Translate value="label.headerLogo1"/></div>
                  <div className="tagline"><Translate value="label.headerLogo2"/></div>
                </div>
                <div className="text-900 text-5xl font-bold mb-3">
                  {props.recoveryReason === 'FORGOT_PASSWORD' ?
                      <Translate value="label.changePassword"/>
                      :
                      <Translate value="label.accountRecovery"/>
                  }</div>
              </div>

              <div>
                <FormElement
                  label={"label.username"}
                  fieldType={fieldType.INPUT_TEXT} required
                  fieldProps={{ name: formFieldNames.USERNAME }} />
                <Captcha
                  captchaURL={captchaUrl}
                  fieldName={formFieldNames.CAPTCHA}
                  onRefreshCaptcha={() => getCaptcha()}
                  captchaPlaceholder={false}
                />
                <Button label={<Translate value="action.recovery" />} type="submit"
                  icon="pi pi-user" className="w-full" />
              </div>
            </div>
          </div>
        </MBForm>}
    </Fragment>
  )
};

export default AccountRecoveryChangePassword;
