import {addressDataFormFieldList, personalInformationFormFieldList} from "@mb-react/krg-getid-form-data-common";
import {
    MBForm,
    Messages,
    NavButtons,
    addInitialValueToForm,
    addMultipleInitialValuesToForm,
    addToAppStore,
    change,
    completeStep,
    getFormValues,
    nextStep,
    showToast,
    toastIDs
} from "@mb-react/mb-react-core";
import {EUseCases, PagedRequest, extractPersonDataToFormValues, formFieldNames} from "@mb-react/svarog-ui";
import React, {useEffect, useRef} from "react";
import {useForm} from "react-final-form";
import {useDispatch, useSelector} from "react-redux";
import {I18n, Translate} from "react-redux-i18n";
import {formFieldsQuestionnaire, forms} from "../../../../../constants/form";
import {APPLICATION_STEPS} from "../../../../../constants/store";
import {useHandleCommonServices} from "../../../../../services/commonServiceHandler";
import {extractObjectBasedOnKeysList} from "../../../../../util/dataExtractorUtil";
import {isMobile} from "../../../../../util/environment";
import {useHandleQuestionnairesComponent} from "./QuestionnarieComponents";
import {onCancel} from "../../../../../util/componentUtil";

const QuestionnaireData = () => {
    const dispatch = useDispatch();
    const formValues = useSelector((state) => getFormValues(state, forms.QUESTIONNAIRES));
    const form = useForm();
    useSelector(state => state.i18n.locale)

    const questionnaireMessage = useRef(null);
    let handleQuestionnairesComponent = useHandleQuestionnairesComponent();
    const handleCommonServices = useHandleCommonServices()

    useEffect(() => {
        let pagedRequest = new PagedRequest();
        pagedRequest.requestObject = {}
        pagedRequest.page = 1
        pagedRequest.sizePerPage = 10

        handleCommonServices.handleCallGetMyIdentity(pagedRequest, getMyIdentityOnSuccess)
    }, [])

    const getMyIdentityOnSuccess = (response) => {
        let newObj = extractPersonDataToFormValues(response.data, {});
        let personalInformation = extractObjectBasedOnKeysList(newObj, personalInformationFormFieldList);
        let addressData = extractObjectBasedOnKeysList(newObj, addressDataFormFieldList);

        dispatch(addMultipleInitialValuesToForm(forms.ADDRESS_DATA, addressData))
        dispatch(addMultipleInitialValuesToForm(forms.PERSONAL_INFORMATION, personalInformation))
        dispatch(addToAppStore('fetchedApplication', {...addressData, ...personalInformation}))
    }

    useEffect(() => {
        questionnaireMessage.current.show({
            severity: 'info', sticky: true, content: (
                <React.Fragment className="mb-1">
                    <i className="pi pi-info p-2"
                       style={{
                           borderRadius: '50%',
                           backgroundColor: 'var(--surface-b)',
                           color: 'var(--surface-d)'
                       }}></i>
                    <div className="ml-2">
                        <Translate value="message.QuestioonnarieMessage1"/>
                    </div>
                </React.Fragment>
            )
        });

        //TODO update useHandleQuestionnairesComponent
        if (formValues) {
            Object.keys(formValues).forEach(key => {
                    dispatch(change(forms.QUESTIONNAIRES, key, formValues[key]))
                }
            )
        }
    }, []);

    const onNextStep = () => {
        console.log("mrs")
    }

    //TODO fix for dl issue_country_dl

    return (
        <>
            {isMobile ?
                <>
                    <div className="card mobileQuestWrap">
                        <Messages ref={questionnaireMessage}/>
                        {handleQuestionnairesComponent.isNewDocumentComponent}
                        {handleQuestionnairesComponent.isNewDocument && handleQuestionnairesComponent.newDocumentYesComponent}
                        {handleQuestionnairesComponent.noNewDocument && handleQuestionnairesComponent.replaceDocumentComponent}
                        {handleQuestionnairesComponent.replaceDocument && handleQuestionnairesComponent.replaceDocumentComponentYes}
                        {handleQuestionnairesComponent.noReplaceDocument && handleQuestionnairesComponent.isModifyDocumentComponent}
                        {handleQuestionnairesComponent.noModifyDocument && handleQuestionnairesComponent.isExtendDocumentComponent}
                        {handleQuestionnairesComponent.noExtendDocument && handleQuestionnairesComponent.isCancelDocumentComponent}
                    </div>
                    <div className="navButtonsStyle">
                        <NavButtons firstStep={true} customOnNext={() => onNextStep()}
                                    customOnCancel={() => onCancel(dispatch)}/>
                    </div>
                </>
                :
                <>
                    <div className="card desktopQuestWrap">
                        <Messages ref={questionnaireMessage}/>
                        {handleQuestionnairesComponent.isNewDocumentComponent}
                        {handleQuestionnairesComponent.isNewDocument && handleQuestionnairesComponent.newDocumentYesComponent}
                        {handleQuestionnairesComponent.noNewDocument && handleQuestionnairesComponent.replaceDocumentComponent}
                        {handleQuestionnairesComponent.replaceDocument && handleQuestionnairesComponent.replaceDocumentComponentYes}
                        {handleQuestionnairesComponent.noReplaceDocument && handleQuestionnairesComponent.isModifyDocumentComponent}
                        {handleQuestionnairesComponent.noModifyDocument && handleQuestionnairesComponent.isExtendDocumentComponent}
                        {handleQuestionnairesComponent.noExtendDocument && handleQuestionnairesComponent.isCancelDocumentComponent}
                    </div>
                    <div className="navButtonsStyle">
                        <NavButtons firstStep={true} customOnNext={() => onNextStep()}
                                    customOnCancel={() => onCancel(dispatch)}/>
                    </div>
                </>
            }
        </>
    )
};
const QuestionnaireDataForm = () => {

    const dispatch = useDispatch();


    const validationFunc = () => {
        let errors = {}

        return errors
    }

    const toMoveOn = (formValues) => {
        if ((formValues?.[formFieldsQuestionnaire.ADDITIONAL_QUESTION] && formValues[formFieldsQuestionnaire.ADDITIONAL_QUESTION] === formFieldsQuestionnaire.FIRST_TIME_APPLY) ||
            (formValues?.[formFieldsQuestionnaire.ADDITIONAL_QUESTION] && formValues[formFieldsQuestionnaire.ADDITIONAL_QUESTION] === formFieldsQuestionnaire.OLD_DOCUMENT) ||
            (formValues?.[formFieldsQuestionnaire.ADDITIONAL_QUESTION] && formValues[formFieldsQuestionnaire.ADDITIONAL_QUESTION] === formFieldsQuestionnaire.FOREIGN_DOCUMENT) ||
            (formValues?.[formFieldsQuestionnaire.LOST_DOCUMENT] && formValues[formFieldsQuestionnaire.LOST_DOCUMENT] === true) ||
            (formValues?.[formFieldsQuestionnaire.DAMAGED_DOCUMENT2] && formValues[formFieldsQuestionnaire.DAMAGED_DOCUMENT2] === true) ||
            (formValues?.[formFieldsQuestionnaire.EXPIRED_DOCUMENT] && formValues[formFieldsQuestionnaire.EXPIRED_DOCUMENT] === true) ||
            (formValues?.[formFieldsQuestionnaire.EXPIRED_DOCUMENT2] && formValues[formFieldsQuestionnaire.EXPIRED_DOCUMENT2] === true) ||
            (formValues?.[formFieldsQuestionnaire.EXPIRED_DOCUMENT3] && formValues[formFieldsQuestionnaire.EXPIRED_DOCUMENT3] === true) ||
            (formValues?.[formFieldsQuestionnaire.IS_MODIFY_DOCUMENT] && formValues[formFieldsQuestionnaire.IS_MODIFY_DOCUMENT] === "YES") ||
            (formValues?.[formFieldsQuestionnaire.IS_EXTEND_DOCUMENT] && formValues[formFieldsQuestionnaire.IS_EXTEND_DOCUMENT] === "YES") ||
            (formValues?.[formFieldsQuestionnaire.IS_CANCEL_DOCUMENT] && formValues[formFieldsQuestionnaire.IS_CANCEL_DOCUMENT] === "YES")
        ) {
            dispatch(addToAppStore('questionnariesCompletedDL', true))
        } else {
            dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {messageId: I18n.t("message.QuestioonnarieMessage1")}))
        }
    };

    const mapQuestionsToIsNewDocumentUseCase = (formValues) => {
        if (formValues?.[formFieldsQuestionnaire.IS_NEW_DOCUMENT]) {
            if (formValues?.[formFieldsQuestionnaire.ADDITIONAL_QUESTION] && formValues[formFieldsQuestionnaire.ADDITIONAL_QUESTION] === formFieldsQuestionnaire.FIRST_TIME_APPLY) {
                dispatch(addInitialValueToForm(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.NEW));
            } else if (formValues?.[formFieldsQuestionnaire.ADDITIONAL_QUESTION] && formValues[formFieldsQuestionnaire.ADDITIONAL_QUESTION] === formFieldsQuestionnaire.OLD_DOCUMENT) {
                dispatch(addInitialValueToForm(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.RE_REGISTER));
            } else if (formValues?.[formFieldsQuestionnaire.ADDITIONAL_QUESTION] && formValues[formFieldsQuestionnaire.ADDITIONAL_QUESTION] === formFieldsQuestionnaire.FOREIGN_DOCUMENT) {
                dispatch(addInitialValueToForm(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.FOREIGN));
            }
        }
    };

    const mapQuestionsToIsReplaceDocumentUseCase = (formValues) => {
        if (formValues?.[formFieldsQuestionnaire.IS_REPLACE_DOCUMENT]) {
            if (formValues?.[formFieldsQuestionnaire.LOST_DOCUMENT] && formValues[formFieldsQuestionnaire.LOST_DOCUMENT] === true) {
                dispatch(addInitialValueToForm(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.REPLACE));
            } else if (formValues?.[formFieldsQuestionnaire.DAMAGED_DOCUMENT2] && formValues[formFieldsQuestionnaire.DAMAGED_DOCUMENT2] === true) {
                dispatch(addInitialValueToForm(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.PARTLY_DAMAGED));
            }
        }
    };

    const mapQuestionsToIsExpiredDocumentUseCase = (formValues) => {
        if ((formValues?.[formFieldsQuestionnaire.LOST_DOCUMENT] && formValues[formFieldsQuestionnaire.LOST_DOCUMENT] === true) &&
            (formValues?.[formFieldsQuestionnaire.EXPIRED_DOCUMENT] && formValues[formFieldsQuestionnaire.EXPIRED_DOCUMENT] === true) ||
            (formValues?.[formFieldsQuestionnaire.LOST_DOCUMENT] && formValues[formFieldsQuestionnaire.LOST_DOCUMENT] === true)) {
            dispatch(addInitialValueToForm(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.REPLACE));
        } else if ((formValues?.[formFieldsQuestionnaire.DAMAGED_DOCUMENT2] && formValues[formFieldsQuestionnaire.DAMAGED_DOCUMENT2] === true) &&
            (formValues?.[formFieldsQuestionnaire.EXPIRED_DOCUMENT] && formValues[formFieldsQuestionnaire.EXPIRED_DOCUMENT] === true) ||
            (formValues?.[formFieldsQuestionnaire.DAMAGED_DOCUMENT2] && formValues[formFieldsQuestionnaire.DAMAGED_DOCUMENT2] === true)) {
            dispatch(addInitialValueToForm(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.PARTLY_DAMAGED));
        } else if (formValues?.[formFieldsQuestionnaire.EXPIRED_DOCUMENT] && formValues[formFieldsQuestionnaire.EXPIRED_DOCUMENT] === true) {
            dispatch(addInitialValueToForm(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.EXPIRED));
        }
    };

    const mapQuestionsToIsModifyDocumentUseCase = (formValues) => {
        if ((formValues?.[formFieldsQuestionnaire.IS_MODIFY_DOCUMENT] && formValues[formFieldsQuestionnaire.IS_MODIFY_DOCUMENT] === "YES") ||
            (formValues?.[formFieldsQuestionnaire.IS_MODIFY_DOCUMENT] && formValues[formFieldsQuestionnaire.IS_MODIFY_DOCUMENT] === "YES") &&
            (formValues?.[formFieldsQuestionnaire.EXPIRED_DOCUMENT3] && formValues[formFieldsQuestionnaire.EXPIRED_DOCUMENT3] === true)) {
            dispatch(addInitialValueToForm(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.MODIFICATION))
        }
    };

    const mapQuestionsToIsExtendDocumentUseCase = (formValues) => {
        if (formValues?.[formFieldsQuestionnaire.IS_EXTEND_DOCUMENT] ||
            formValues?.[formFieldsQuestionnaire.IS_EXTEND_DOCUMENT] &&
            (formValues?.[formFieldsQuestionnaire.EXPIRED_DOCUMENT2] && formValues[formFieldsQuestionnaire.EXPIRED_DOCUMENT2] === true))
            dispatch(addInitialValueToForm(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.EXTEND))
    };

    const mapQuestionsToIsCancelDocumentUseCase = (formValues) => {
        if (formValues?.[formFieldsQuestionnaire.IS_CANCEL_DOCUMENT] && formValues[formFieldsQuestionnaire.IS_CANCEL_DOCUMENT] === "YES") {
            dispatch(addInitialValueToForm(forms.APPLICATION, formFieldNames.USE_CASE, EUseCases.CANCEL_DOCUMENT));
            dispatch(addToAppStore('cancelUseCaseActive', true));
        } else {
            dispatch(addToAppStore('cancelUseCaseActive', false));
        }
    };

    const onNextStep = (values) => {
        mapQuestionsToIsNewDocumentUseCase(values);
        mapQuestionsToIsReplaceDocumentUseCase(values);
        mapQuestionsToIsModifyDocumentUseCase(values);
        mapQuestionsToIsExtendDocumentUseCase(values);
        mapQuestionsToIsCancelDocumentUseCase(values);
        mapQuestionsToIsExpiredDocumentUseCase(values);
        toMoveOn(values);
    };
    return (
        <MBForm formName={forms.QUESTIONNAIRES} stepId={APPLICATION_STEPS.QUESTIONNAIRE_DATA}
                submitFunction={(values) => {
                    onNextStep(values)
                }} validate={validationFunc}>
            <QuestionnaireData/>
        </MBForm>
    )
}
export default QuestionnaireDataForm;
