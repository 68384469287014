import { genericFormFieldNames } from "@mb-react/krg-getid-form-data-common";
import {
    getFormValues, getFromAppStore
} from "@mb-react/mb-react-core";
import { formFieldNames } from "@mb-react/svarog-ui";
import { useMemo } from "react";
import { useForm } from "react-final-form";
import { useSelector } from "react-redux";
import { formFieldsMeta, forms } from "../../../../../../constants/form";
import { isMandatory, isReadOnly } from "../../../../../../util/businesslogicUtil";
import { useFieldValuesHandler } from "../../../../../../util/translateUtil";
import {ACCOUNT_IDENTITY} from "../../../../../../constants/application";

export const useGrandfatherDataSettings = () => {
    const form = useForm();
    const useCase = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.USE_CASE])
    const documentType = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.DOCUMENT_TYPE])
    const workflow = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldsMeta.WORKFLOW])
    const accountIdentity = useSelector(state => getFromAppStore(state, ACCOUNT_IDENTITY));

    let formValues = form?.getState()?.values ?? {}

    const fieldValuesHandler = useFieldValuesHandler();

    const onGrandfatherNameChange = (e) => {
        fieldValuesHandler.changeArabicValueToLatin(e.target.value, genericFormFieldNames.GRANDFATHER_NAME_LATIN)
    }

    const handleGrandfathersNameSettings = useMemo(() => {
        let grandfatherDataProps = {
            [genericFormFieldNames.GRANDFATHER_NAME]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(formValues, genericFormFieldNames.GRANDFATHER_NAME, useCase, documentType, workflow),
                    [formFieldNames.ON_CHANGE]: onGrandfatherNameChange
                },
                required: isMandatory(formValues, genericFormFieldNames.GRANDFATHER_NAME, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return grandfatherDataProps
    }, [formValues[formFieldNames.TPID]])

    const handleGrandfathersNameLatinSettings = useMemo(() => {
        let grandfatherDataProps = {
            [genericFormFieldNames.GRANDFATHER_NAME_LATIN]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: isReadOnly(formValues, genericFormFieldNames.GRANDFATHER_NAME_LATIN, useCase, documentType, workflow),
                },
                required: isMandatory(formValues, genericFormFieldNames.GRANDFATHER_NAME_LATIN, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return grandfatherDataProps
    }, [formValues.grandfatherName, formValues[formFieldNames.TPID]])

    return { ...handleGrandfathersNameSettings, ...handleGrandfathersNameLatinSettings }
};