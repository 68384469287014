import {
    completeStep,
    getFromAppStore,
    hasValidationErrors,
    MBForm,
    nextStep,
    previousStep
} from "@mb-react/mb-react-core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {APPLICATION_STEPS, applicationStore} from "../../../../../constants/store";
import AddressDataTask from "./AddressDataTask";
import {useAddressEnrollmentFormDataValidation} from "./AddressDataValidation";
import {onCancel} from "../../../../../util/componentUtil";
import {disableNextButton, useSkip} from "../../../../../util/businesslogicUtil";
import {ACCOUNT_IDENTITY} from "../../../../../constants/application";
import NavButtonsLocal from "../../../../reactCoreComponents/NavButtonsLocal";
import {isMobile} from "../../../../../util/environment";

const AddressDataForm = (props) => {
    const errors = useSelector((state) => hasValidationErrors(state, props.formName));
    const dispatch = useDispatch();
    const addressEnrollmentFormDataValidation = useAddressEnrollmentFormDataValidation(props.formNameSufix);
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))
    const accountIdentity = useSelector(state => getFromAppStore(state, ACCOUNT_IDENTITY));

    const onSkip = () => {
        dispatch(nextStep(props.navigationName))
    }

    const onPrevious = () => {
        dispatch(previousStep(props.navigationName))
    }

    const isFormValid = () => {
        return !errors
    }

    return (
        <MBForm formName={props.formName} stepId={APPLICATION_STEPS.ADDRESS_DATA} warningButtonLabel={isMobile() ? "  " : null} showErrorPanel submitFunction={() => {
            dispatch(completeStep(APPLICATION_STEPS.ADDRESS_DATA))
            dispatch(nextStep(props.navigationName))
        }} validate={addressEnrollmentFormDataValidation.validate}>
            <AddressDataTask formName={props.formName} formNameSufix={props.formNameSufix}/>
            <NavButtonsLocal customOnPrevious={() => onPrevious()}
                        customOnSkip={() => onSkip()}
                        allowSkip={useSkip(userRights, errors, accountIdentity)}
                        hideNext={useSkip(userRights, errors, accountIdentity)}
                        disableNextBtn={disableNextButton(isFormValid(), userRights, accountIdentity)}
                        customOnCancel={() => onCancel(dispatch)}/>
        </MBForm>
    );
}

export default AddressDataForm;