import { call, put } from 'redux-saga/effects';
import { applicationStore, appStoreFields } from '../../constants/store';
import { modalIDs } from '../../constants/environment';
import moment from 'moment';
import { addToAppStore, closeModal, openModal as coreOpenModal, removeFromAppStore } from '@mb-react/mb-react-core';
import { formFieldNames } from '@mb-react/svarog-ui';

/**
 * @author Minja Raicevic on 07/02/2018.
 * This is default Generator for all saga actions
 */
export function* performSagaAction(callbackFunc, action /*, generatorName */) {
  yield put(coreOpenModal(modalIDs.SPINNER));
  try {
    yield call(callbackFunc, action);
    yield put(closeModal(modalIDs.SPINNER));
  } catch (e) {
    yield put(closeModal(modalIDs.SPINNER));
    yield put(addToAppStore(applicationStore.ERROR_MESSAGE, 'err.' + e.message));
    yield put(coreOpenModal(modalIDs.ERROR));
  }
}

export const prepareSuportiveDocumentForServer = (supportingDocuments, appData) => {
  let customMap = new Map();
  for (let i = 0; i < supportingDocuments.length; i++) {
    let data = {
      base64img: supportingDocuments[i].data.split(',')[1],
      extension: supportingDocuments[i].name.split('.')[1]
    };
    let files = [];
    if (customMap.has(supportingDocuments[i].type)) {
      let oldAndNewFiles = customMap.get(supportingDocuments[i].type);
      oldAndNewFiles.push(data);
      customMap.set(supportingDocuments[i].type, oldAndNewFiles);
    } else {
      files.push(data);
      customMap.set(supportingDocuments[i].type, files);
    }
  }
  let arrayAllDocs = [];
  customMap.forEach((value, key) => {
    let supDocObject = {
      documentName: key,
      documentDateOfIssue: (appData && appData[key + formFieldNames.SUFFIX_DOC_DATE_OF_ISSUE]) ? prepareServerStringDate(appData[key + formFieldNames.SUFFIX_DOC_DATE_OF_ISSUE]) : null,
      files: value
    };
    arrayAllDocs.push(supDocObject);
  });
  return arrayAllDocs;
}

/*
function mapMapToArray(value, key) {
  let supDocObject = {
    documentType: key,
    files: value
  }
  arrayAllDocs.push(supDocObject)
}
*/
export function prepareServerStringDate(momentValue, dateFormat) {
  if (momentValue) {
    if(dateFormat){
      return moment(momentValue).format(dateFormat)
    }
    return moment(momentValue).format('DD-MM-YYYY')
  }
  return momentValue;
};



export const convertDateFromServerToMoment = serverDate => {
  if (serverDate && moment(serverDate, 'YYYY-MM-DD').isValid()) {
    return moment(serverDate, 'YYYY-MM-DD');
  }
};
export const prepareDisableDatesForCalendar = data => {
  let dates = []
  if (data && data.length > 0) {
    data.forEach(element => {
      if (element && moment(element, 'YYYY-MM-DD').isValid()) {
        dates.push(moment(element).toDate())
      }
    });
  }

  return dates
};



/**
 * Add new property to the Application Store and remove it from the store
 * after given delay.
 * @param {string} [name] - of the new App store property @default(true)
 * @param {} [value = true] - of the new App store property
 * @param {number} [delay = 10000] - in milliseconds
 * @author Minja Raicevic on 18/01/2019.
 */
export function* addRemoveAppStorePropWithDelay(name, value = true, delay = 10000) {
  yield put(addToAppStore(name, value));
  yield call(pause, delay);
  yield put(removeFromAppStore(name));
}

/**
 * Timing offset function
 * @param {number} [delay = 10000] - in milliseconds
 * @author Minja Raicevic on 06/11/2018.
 */
export const pause = delay => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, delay);
  });
};
//function bellow wa function generator "function*"
export function loadFile(pdfData, fileName) {
  var file = b64toBlob(pdfData, 'application/pdf');
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(file, fileName);
  } else {
    let fileURL = window.URL.createObjectURL(file);
    let link = document.createElement('a');
    link.href = fileURL;
    link.download = fileName;
    // FF requires the link in actual DOM
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export function b64toBlob(b64Data, contentType) {
  var byteCharacters = atob(b64Data);

  var byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    var slice = byteCharacters.slice(offset, offset + 512),
      byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType })
}

export function convertDateFromServerToDate(serverDate) {
  if (serverDate) {
    return new Date(serverDate)
  }
  return null
}

export function convertDisabledDaysToMoment(disabledDays) {
  let convertedDays = []
  if (disabledDays) {
    disabledDays.forEach(day => convertedDays.push(convertDateFromServerToDate(day)))
  }
  return convertedDays
}

// This function should be declared obsolete and not used anymore
export function* openModal(callbackFunc, action, generatorName) {
  yield put(
    coreOpenModal('loadingmodal')
  )
  try {
    yield call(callbackFunc, action)

    yield put(
      closeModal('loadingmodal')
    )
  } catch (e) {
    yield put(addToAppStore(appStoreFields.errorMessage, e.message))
    yield put(coreOpenModal('errormodal'))
  }
}
