export const routes = Object.freeze({
  HOME: '/',
  ADMIN_HOME: '/admin',
  ADMIN_ACCOUNT_HOME: '/account',
  ADMIN_ACCOUNT_OVERVIEW: '/account-overview',
  ADMIN_ACCOUNT_ADMINISTRATION: '/account-administration',
  CONTACT: '/contact',
  CREATE_E_PASS: '/create_e_pass',
  CREATE_ID: '/create_id',
  CREATE_DL: '/create_dl',
  CREATE_VISA: '/create_visa',
  MY_APPLICATION: '/my_application',
  BOOK_APPOINTMENT: '/book_appointment',
  MY_APPOINTMENTS: '/my_appointments',
  VEHICLE_WORKFLOW: '/vehicleWorkflow',
  IDENTITY_MANAGEMENT: '/identityManagement',
  MODIFY_IDENTITY: '/modifyIdentity',
  CANCEL_IDENTITY: '/cancelIdentity',
  REGISTER_ACCOUNT: '/registerAccount',
  LOGIN: '/login',
  ACCOUNT_RECOVERY: '/account_recovery',
  CHANGE_PASSWORD: '/change_password',
  EDIT_ACCOUNT: '/edit_account',
  FORGOT_PASSWORD: '/forgot_password',
  CONTENT_MANAGEMENT_HOME: '/content-management',
  FAQ: '/faq',
  TEMPLATE_DOCUMENTS: '/templateDocuments',
  PRINT_ACCOUNT_REPORT: '/print-account-report',
  PRINT_APPLICATION_REPORT: '/print-application-report',

  ERROR_403:'error_403'
})
