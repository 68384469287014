import {completeStep, hasValidationErrors, MBForm, NavButtons, nextStep, previousStep} from "@mb-react/mb-react-core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { APPLICATION_STEPS } from "../../../../../constants/store";
import RequestedDocumentDataTask from "./RequestedDocumentDataTask";
import {plateDataValidation} from "../../vehicle/plateData/plateDataValidation";
import {onCancel} from "../../../../../util/componentUtil";
import NavButtonsLocal from "../../../../reactCoreComponents/NavButtonsLocal";

const RequestedDocumentDataForm = (props) => {

    const errors = useSelector((state) => hasValidationErrors(state, props.formName));
    const dispatch = useDispatch();
    const plateDataFormValidation = plateDataValidation();

    const onPrevious = () => {
        dispatch(previousStep(props.navigationName))
    }

    const onSkip = () => {
        dispatch(nextStep(props.navigationName))
    }

    return (
        <MBForm formName={props.formName} navigationId={props.navigationName} stepId={APPLICATION_STEPS.DOCUMENTS_DATA} showErrorPanel submitFunction={() => {
            dispatch(completeStep(props.navigationName, APPLICATION_STEPS.DOCUMENTS_DATA))
            dispatch(nextStep(props.navigationName))
        }} validate={plateDataFormValidation.validate}>
            <RequestedDocumentDataTask />
            <NavButtonsLocal customOnPrevious={() => onPrevious()} customOnSkip={() => onSkip()} allowSkip={errors} hideNext={errors} customOnCancel={() => onCancel(dispatch)}/>
        </MBForm>
    );
}

export default RequestedDocumentDataForm;