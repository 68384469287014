import { formFieldNames, isRequired } from "@mb-react/svarog-ui";
import { useGlobalValidation } from "../../../../../../hooks/useGlobalValidation";
import { genericFormFieldNames } from "@mb-react/krg-getid-form-data-common";
import { useSelector } from "react-redux";
import {getFormValues, getFromAppStore} from "@mb-react/mb-react-core";
import { forms } from "../../../../../../constants/form";
import { isVehicleType } from "../../../../../../util/businesslogicUtil";
import {applicationStore} from "../../../../../../constants/store";

export const vehicleIdentificaitonDataValidation = (values) => {
  const globalValidation = useGlobalValidation()
  const vehicleDataFormValues = useSelector((state) => getFormValues(state, forms.VEHICLE_IDENTIFICATION))
  let validationDigit = useSelector(state => getFromAppStore(state, applicationStore.TEMPORARY_PLATE_NUMBER_VALIDATION_DIGIT));

  if(!validationDigit){
    let year = new Date();
    year = year.getFullYear();
    validationDigit = year % 10;
  }

  const validate = (values) => {
    const errors = {};

    //Temporary solution for validating fields until digitsOnly prop is fixed

    const fieldsToValidate = [
      formFieldNames.PRODUCTION_YEAR
    ]

    let regexNumbersOnly = /^[0-9]*$/;
    for (let fieldName of fieldsToValidate) {
      let fieldValidation = values[fieldName];
      if (fieldValidation != undefined) {
        if (fieldValidation && !fieldValidation.toString().match(regexNumbersOnly)) {
          errors[fieldName] = "onlyNumbersAllowed";
        }
      }
    }

    let string = "^(2[1-4])-(1[8-9]|[1][8-9]|2[0-" + validationDigit + "])\\s(\\d{1,5})$";

    let regexTemporaryPlate = new RegExp(string)
      let plateValidation = values[genericFormFieldNames.TEMPORARY_PLATE_NUMBER]
        if (plateValidation != undefined) {
          if (plateValidation && !plateValidation.toString().match(regexTemporaryPlate)) {
            errors[genericFormFieldNames.TEMPORARY_PLATE_NUMBER] = "invalidPlateNumber"
          }
        }


      let chassisValidation = values[formFieldNames.CHASSIS_NUMBER]
      let chassisLength = values[formFieldNames.CHASSIS_NUMBER]?.length
      if (chassisValidation != undefined) {
        if (chassisLength < 6) {
          errors[formFieldNames.CHASSIS_NUMBER] = "invalidChassisNumber"
        }
      }


    if (!values) {
      errors.validation = 'Form values undefined!'
    } else {
      // if (globalValidation.isMandatory(values, formFieldNames.VEHICLE_ID)) {
      //   isRequired(values, formFieldNames.VEHICLE_ID, errors);
      // }
      if (globalValidation.isMandatory(values, genericFormFieldNames.VEHICLE_TYPE)) {
        isRequired(values, genericFormFieldNames.VEHICLE_TYPE, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.VEHICLE_CATEGORY)) {
        isRequired(values, formFieldNames.VEHICLE_CATEGORY, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.VEHICLE_SUBCATEGORY)) {
        isRequired(values, formFieldNames.VEHICLE_SUBCATEGORY, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.PRODUCTION_YEAR)) {
        isRequired(values, formFieldNames.PRODUCTION_YEAR, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.PRODUCTION_COUNTRY)) {
        isRequired(values, formFieldNames.PRODUCTION_COUNTRY, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.VEHICLE_USAGE_TYPE)) {
        isRequired(values, formFieldNames.VEHICLE_USAGE_TYPE, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.CHASSIS_NUMBER)) {
        isRequired(values, formFieldNames.CHASSIS_NUMBER, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.CABIN_TYPE) && isVehicleType(vehicleDataFormValues)) {
        isRequired(values, formFieldNames.CABIN_TYPE, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.CABIN_NUMBER) && isVehicleType(vehicleDataFormValues)) {
        isRequired(values, formFieldNames.CABIN_NUMBER, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.VEHICLE_BRAND)) {
        isRequired(values, formFieldNames.VEHICLE_BRAND, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.VEHICLE_MODEL)) {
        isRequired(values, formFieldNames.VEHICLE_MODEL, errors);
      }
      if (globalValidation.isMandatory(values, formFieldNames.VEHICLE_COLOR)) {
        isRequired(values, formFieldNames.VEHICLE_COLOR, errors);
      }
    }
    return errors;
  }
  return {validate};
};