import { useMemo } from 'react'
import {
    getFormValues, getFromAppStore,
} from "@mb-react/mb-react-core";
import { formFieldNames } from "@mb-react/svarog-ui";
import { useSelector } from "react-redux";
import { formFieldsMeta, forms } from "../../../../../../constants/form";
import {
    isMandatory,
    isOperationModeProtected,
    isReadOnly,
    isUseCaseReservePlateNumber
} from "../../../../../../util/businesslogicUtil";
import { useFieldValuesHandler } from "../../../../../../util/translateUtil";
import { useForm } from 'react-final-form';
import {ACCOUNT_IDENTITY} from "../../../../../../constants/application";

export const useNameDataSettings = () => {
    const form = useForm();
    const formValuesApp = useSelector((state) => getFormValues(state, forms.APPLICATION))
    const useCase = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.USE_CASE])
    const documentType = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldNames.DOCUMENT_TYPE])
    const workflow = useSelector((state) => getFormValues(state, forms.APPLICATION)[formFieldsMeta.WORKFLOW])
    const preSummaryAdded = useSelector((state) => getFromAppStore(state, 'preSummaryAdded'))

    let formValues = form?.getState()?.values ?? {}
    const fieldValuesHandler = useFieldValuesHandler();

    let reservePlateNumberReadOnly = !isOperationModeProtected() && isUseCaseReservePlateNumber(formValuesApp);
    const accountIdentity = useSelector(state => getFromAppStore(state, ACCOUNT_IDENTITY));

    const onGivenNameChange = (e) => {
        fieldValuesHandler.changeArabicValueToLatin(e.target.value, formFieldNames.GIVEN_NAME_LATIN)
    };

    const onSurnameChange = (e) => {
        fieldValuesHandler.changeArabicValueToLatin(e.target.value, formFieldNames.SURNAME_LATIN)
    };

    const handleGivenNameSettings = useMemo(() => {
        let nameDataProps = {
            [formFieldNames.GIVEN_NAME]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || preSummaryAdded || isReadOnly(formValues, formFieldNames.GIVEN_NAME, useCase, documentType, workflow),
                    [formFieldNames.ON_CHANGE]: onGivenNameChange
                },
                required: isMandatory(formValues, formFieldNames.GIVEN_NAME, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            },
        }
        return nameDataProps
    }, [formValues[formFieldNames.TPID]])

    const handleGivenNameLatinSettings = useMemo(() => {
        let nameDataProps = {
            [formFieldNames.GIVEN_NAME_LATIN]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || preSummaryAdded || isReadOnly(formValues, formFieldNames.GIVEN_NAME_LATIN, useCase, documentType, workflow),
                },
                required: isMandatory(formValues, formFieldNames.GIVEN_NAME_LATIN, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return nameDataProps
    }, [formValues.givenName, formValues[formFieldNames.TPID]])

    const handleSurnameSettings = useMemo(() => {
        let nameDataProps = {
            [formFieldNames.SURNAME]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || preSummaryAdded || isReadOnly(formValues, formFieldNames.SURNAME, useCase, documentType, workflow),
                    [formFieldNames.ON_CHANGE]: onSurnameChange
                },
                required: isMandatory(formValues, formFieldNames.SURNAME, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }
        return nameDataProps
    }, [formValues[formFieldNames.TPID]])

    const handleSurnameLatinSettings = useMemo(() => {
        let nameDataProps = {
            [formFieldNames.SURNAME_LATIN]: {
                [formFieldNames.FIELD_PROPS]: {
                    readOnly: reservePlateNumberReadOnly || preSummaryAdded || isReadOnly(formValues, formFieldNames.SURNAME_LATIN, useCase, documentType, workflow),
                },
                required: isMandatory(formValues, formFieldNames.SURNAME_LATIN, useCase, documentType, workflow, accountIdentity),
                additionalClass: "col-12 md:col-4 xl:col-4"
            }
        }

        return nameDataProps
    }, [formValues.surname, formValues[formFieldNames.TPID]])

    // const settings = useMemo(handleNameDataSettings, [givenName, givenNameLatin, surname, surnameLatin])
    return { ...handleGivenNameSettings, ...handleGivenNameLatinSettings, ...handleSurnameSettings, ...handleSurnameLatinSettings }
};