/**
 * @author Minja Raicevic on 13/12/2018.
 *
 * This file should contain all store properties
 */
export const applicationStore = Object.freeze({
  VISA_CATEGORIES: 'VISA_CATEGORIES',
  FAQUESTIONS: 'FAQ_QUESTIONS',
  ERROR_MESSAGE: 'ERROR_MESSAGE',
  SPINNER_MESSAGE: 'SPINNER_MESSAGE',
  MESSAGE_MODAL_MESSAGE: 'MESSAGE_MODAL_MESSAGE',
  PROJECT_VERSION: 'PROJECT_VERSION',
  CAPTCHA_DATA: "CAPTCHA_DATA",
  APP_CREATE_SUCCESS: 'appCreateSuccess',
  APP_CREATE_SUCCESS_FORM_NAME: 'appCreateSuccessFormName',
  APPLICATION_DATA_FOR_PERSON_COUNT: 'applicationDataForPersonCount',
  APPLICATION_FOR_DELETE_ID: 'APPLICATION_FOR_DELETE_ID',
  APPOINTMENT_FOR_CANCEL_ID: 'APPOINTMENT_FOR_CANCEL_ID',
  WORKFLOW_TYPE: 'WORKFLOW_TYPE',
  READ_ONLY_FIELDS: 'READ_ONLY_FIELDS',
  ACCOUNT_DATA: 'ACCOUNT_DATA',
  APPLICATIONS_DATA: 'APPLICATIONS_DATA',
  USER_RIGHTS: "userRights",
  REGISTERED_ACCOUNT_ID: "REGISTERED_ACCOUNT_ID",
  REGISTERED_ACCOUNT_EMAIL: "REGISTERED_ACCOUNT_EMAIL",
  PERSONS_DATA: "PERSONS_DATA",
  SELECTED_PERSON: "SELECTED_PERSON",
  ADDED_SOURCE_OWNER: "ADDED_SOURCE_OWNER",
  ASSIGNED_PERSON_TPID: "assignedPersonTpId",
  LEGAL_ENTITY_DATA: "LEGAL_ENTITY_DATA",
  FETCHED_DL_DATA: "FETCHED_DL_DATA",
  VEHICLES_DATA: "VEHICLES_DATA",
  VEHICLES_DATA_COUNT: "VEHICLES_DATA_COUNT",
  MAIN_WRAP_REF: "MAIN_WRAP_REF",
  SELECTED_VEHICLE: "SELECTED_VEHICLE",
  VEHICLE_FROM_SYSTEM: "VEHICLE_FROM_SYSTEM",
  ADDITIONAL_VEHICLES: "ADDITIONAL_VEHICLES",
  SELECTED_ADDITIONAL_VEHICLE: "SELECTED_ADDITIONAL_VEHICLE",
  ADD_SELECTED_VEHICLE: "ADD_SELECTED_VEHICLE",
  SELECTED_VEHICLE_SEARCH_TABLE: "SELECTED_VEHICLE_SEARCH_TABLE",
  SHOW_ADDITIONAL_ADD_VEHICLE_FORM: "SHOW_ADDITIONAL_ADD_VEHICLE_FORM",
  UPDATE_VEHICLE: "UPDATE_VEHICLE",
  ADDED_VEHICLES: "ADDED_VEHICLES",
  ADDED_SDMS_VEHICLES: "ADDED_SDMS_VEHICLES",
  ADDED_VEHICLES_FROM_SDMS: "ADDED_VEHICLES_FROM_SDMS",
  THEME: "THEME",
  INITIAL_MODIFY_VALUES: "INITIAL_MODIFY_VALUES",
  MODIFIED_FIELDS: "MODIFIED_FIELDS",
  PLATE_DATA_HELPER: "PLATE_DATA_HELPER",
  TEMPORARY_PLATE_NUMBER_VALIDATION_DIGIT: "TEMPORARY_PLATE_NUMBER_VALIDATION_DIGIT",
  CONTRACT_DETAILS: "CONTRACT_DETAILS",
  UPDATE_ADDITIONAL_VEHICLE: 'UPDATE_ADDITIONAL_VEHICLE',
  SHOW_CANCEL_MODAL: 'SHOW_CANCEL_MODAL',
  MISSING_SPOUSE_MARITAL_DATA: "MISSING_SPOUSE_MARITAL_DATA"
});
export const wizardSteps = Object.freeze({
  STEP1: 'Step 1',
  STEP2: 'Step 2',
  STEP3: 'Step 3',
  STEP4: 'Step 4',
  STEP5: 'Step 5',
  STEP6: 'Step 6',
  STEP7: 'Step 7',
  STEP8: 'Step 8',
  STEP9: 'Step 9',
  STEP10: 'Step 10',
  SUBSTEP1: 'Sub Step 1',
  SUBSTEP2: 'Sub Step 2',
  SUBSTEP3: 'Sub Step 3',
  SUBSTEP4: 'Sub Step 4',
  SUBSTEP5: 'Sub Step 5',
  SUBSTEP6: 'Sub Step 6',

});

export const APPLICATION_STEPS = Object.freeze({
  NEW_ID_CARD_INTRO: 'NEW_ID_CARD_INTRO',
  PERSONAL_DATA: 'PERSONAL_DATA',
  PERSONAL_DATA_RO: 'PERSONAL_DATA_RO',
  TARGET_OWNER: 'TARGET_OWNER',
  BIRTH_DATA: 'BIRTH_DATA',
  PERSONAL_INFORMATION: 'PERSONAL_INFORMATION',
  ADDRESS_DATA: 'ADDRESS_DATA',
  PARENTS_DATA: 'PARENTS_DATA',
  SPOUSE_DATA: 'SPOUSE_DATA',
  ADDITIONAL_DATA: 'ADDITIONAL_DATA',
  IDENTIFICATION_DATA: 'IDENTIFICATION_DATA',
  REPRESENTATIVE_DATA: 'REPRESENTATIVE_DATA',
  SUPPORTING_DOCUMENTS: 'SUPPORTING_DOCUMENTS',
  CONFIRMATION_TASK: 'CONFIRMATION_TASK',
  CONFIRMATION_TASK_TX: 'CONFIRMATION_TASK_TX',
  PORTAL_FEEDBACK: 'PORTAL_FEEDBACK',
  BIOMETRIC_TASK: 'BIOMETRIC_TASK',
  DL_TASK: 'DL_TASK',
  VISA_DATA: 'VISA_DATA',
  QUESTIONNAIRE_DATA: 'QUESTIONNAIRE_DATA',
  QUESTIONNAIRE_VR_DATA: 'QUESTIONNAIRE_VR_DATA',
  VEHICLE_DATA: 'VEHICLE_DATA',
  TARGET_VEHICLE: 'TARGET_VEHICLE',
  PLATE_DATA: 'PLATE_DATA',
  VEHICLE_IDENTIFICATION: 'VEHICLE_IDENTIFICATION',
  TECHNICAL_DESCRIPTION: 'TECHNICAL_DESCRIPTION',
  PLATE_HANDLING: "PLATE_HANDLING",
  PROXY_DATA: "PROXY_DATA",
  PROXY_DATA_BUYER: "PROXY_DATA_BUYER",
  DOCUMENTS_DATA: "DOCUMENTS_DATA",
  LEGAL_ENTITY: "LEGAL_ENTITY",
  SEARCH_PERSON: "SEARCH_PERSON",
  SEARCH_APPLICATION_STEP: "SEARCH_APPLICATION_STEP"
});

export const MENU_BAR_ITEMS = Object.freeze({
  ADMIN_HOME: 'ADMIN_HOME',
  LOGOUT_SC: 'LOGOUT_SC',
  LOGIN: 'LOGIN',
  MY_APPLICATIONS: 'MY_APPLICATIONS',
  IDENTITY_MANAGEMENT: 'IDENTITY_MANAGEMENT',
  HOW_TO_APPLY: 'HOW_TO_APPLY',
  LOGOUT: 'LOGOUT',
  DRIVER_LICENSE: 'DRIVER_LICENSE',
  CHECK_APPLICATION_STATUS: 'CHECK_APPLICATION_STATUS',
  BOOK_APPOINTMENT: 'BOOK_APPOINTMENT',
  MY_APPOINTMENTS: 'MY_APPOINTMENTS',
  VEHICLE_WORKFLOW: 'VEHICLE_WORKFLOW',
  PLACEHOLDER: 'PLACEHOLDER',
  VEHICLE_WORKFLOW_DDL: 'VEHICLE_WORKFLOW_DDL',
  DRIVER_LICENSE_DDL: 'DRIVER_LICENSE_DDL',
  HOME: 'HOME'
});

export const PERSONAL_DATA_STEPS = Object.freeze({
  NAME_DATA: 'NAME_DATA',
  BIRTH_DATA: 'BIRTH_DATA',
  OTHER_PERSONAL_INFORMATION: 'OTHER_PERSONAL_INFORMATION',
  EMPLOYMENT_DATA: 'EMPLOYMENT_DATA',
  ADDRESS_DATA: 'ADDRESS_DATA',
  PARENTS_DATA: 'PARENTS_DATA',
  MARITAL_DATA: 'MARITAL_DATA',
  CONTACT_DATA: 'CONTACT_DATA',
  IDENTIFICATION_DATA: 'IDENTIFICATION_DATA',
  REPRESENTATIVE_DATA: 'REPRESENTATIVE_DATA',
});
export const ADDRESS_DATA_STEP = Object.freeze({
  ADDRESS_DATA: 'ADDRESS_DATA',
  RESIDENCE_DATA: 'RESIDENCE_DATA',
});
export const VISA_DATA_STEPS = Object.freeze({
  VISA_DATA: 'VISA_DATA',
  PASSPORT_DATA: 'PASSPORT_DATA',
});

export const VEHICLE_DATA_STEP = Object.freeze({
  VEHICLE_DATA: 'VEHICLE_DATA',
  PLATE_DATA: 'PLATE_DATA',
});

export const PASSPORT_STEPS = Object.freeze({
  NEW_PASSPORT_INTRO: 'NEW_PASSPORT_INTRO',
  PERSONAL_DATA: 'PERSONAL_DATA',
  BIRTH_DATA: 'BIRTH_DATA',
  PERSONAL_INFORMATION: 'PERSONAL_INFORMATION',
  ADDRESS_DATA: 'ADDRESS_DATA',
  PARENTS_DATA: 'PARENTS_DATA',
  SPOUSE_DATA: 'SPOUSE_DATA',
  ADDITIONAL_DATA: 'ADDITIONAL_DATA',
  IDENTIFICATION_DATA: 'IDENTIFICATION_DATA',
  REPRESENTATIVE_DATA: 'REPRESENTATIVE_DATA',
  SUPPORTING_DOCUMENTS: 'SUPPORTING_DOCUMENTS',
  CONFIRMATION_TASK: 'CONFIRMATION_TASK',
  PORTAL_FEEDBACK: 'PORTAL_FEEDBACK'
})

export const VISAS_STEPS = Object.freeze({
  VISA_DATA: 'VISA DATA',
  BIOMETRICS: 'BIOMETRICS',
  DEMOGRAPHIC_DATA: 'DEMOGRAPHIC DATA',
  PERSONAL_DATA: 'PERSONAL_DATA',
  BIRTH_DATA: 'BIRTH_DATA',
  PERSONAL_INFORMATION: 'PERSONAL_INFORMATION',
  ADDRESS_DATA: 'ADDRESS_DATA',
  PARENTS_DATA: 'PARENTS_DATA',
  SPOUSE_DATA: 'SPOUSE_DATA',
  ADDITIONAL_DATA: 'ADDITIONAL_DATA',
  IDENTIFICATION_DATA: 'IDENTIFICATION_DATA',
  REPRESENTATIVE_DATA: 'REPRESENTATIVE_DATA',
  SUPPORTING_DOCUMENTS: 'SUPPORTING_DOCUMENTS',
  CONFIRMATION_TASK: 'CONFIRMATION_TASK',
  PORTAL_FEEDBACK: 'PORTAL_FEEDBACK'
})

export const DL_STEPS = Object.freeze({
  NEW_DL_INTRO: 'NEW_DL_INTRO',
  PERSONAL_DATA: 'PERSONAL_DATA',
  CONFIRMATION_TASK: 'CONFIRMATION_TASK'
})

export const globalStore = Object.freeze({
});
export const appStoreFields = Object.freeze({
  editDraft: "editDraft",
  supportingDocuments: 'supportingDocuments',
  myApplications: 'myApplications',
  overallApplications: 'overallApplications',
  applicationsTableSizePerPage: 'applicationsTableSizePerPage',
  applicationsTableCurrentPage: 'applicationsTableCurrentPage',
  myAppointments: 'myAppointments',
  appointmentsTableSizePerPage: 'sizePerPage',
  appointmentsTableCurrentPage: 'currentPage',
  myApplicationsReportBase64: 'myApplicationsReportBase64',
  userMessageSent: 'userMessageSent',
  supportingDocumentList: 'supportingDocumentList',
  LOGIN_PROPERTY: 'loginProperty',
  EMAIL_SENT: 'emailSent',
  REGISTERED_ACCOUNT: 'registeredAccounts',
  EDITED_ACCOUNT: 'editedAccount',
  PASSWORD_CHANGED: 'passwordChanged',
  PROVIDERS_FOR_LOCATION_SERVICE: 'Providers For Location and Service',
  ALL_LOCATIONS: 'AllLocations',
  ALL_APPOINTMENTS: 'AllAppointments',
  ALL_SERVICES: 'AllServices',
  LIST_TREE_SELECTED_ELEMENT: 'ListTreeSelectedElement',
  availableTimeSlots: 'availableTimeSlots',
  selectedDay: 'selectedDay',
  createdAppointment: 'createdAppointment',
  createdAppointmentId: 'createdAppointmentId',
  createdAppointmentReport: 'createdAppointmentReport',
  sagaCreateSuccess: 'sagaCreateSuccess',
  selectedLocation: 'selectedLocation',
  selectedService: 'selectedService',
  preferredChannels: 'preferredChannels',
  selectedProvider: 'selectedProvider',
  selectedProviderName: 'selectedProviderName',
  errorMessage: 'errorMessage',
  disabledDates: 'disabledDates',
  maxEndDateAP: 'maxEndDateAP',
  filteredAppointments: 'filteredAppointments',
  successSagaDelete: 'successSagaDelete',
  appointment: 'appointment',
  selectedTimeSlot: 'selectedTimeSlot',
  completeSteps: 'completeSteps',
  appointmentId: 'appointmentId',
  displaySplashScreen: 'displaySplashScreen',
});

export const globalProps = Object.freeze({
  DICTIONARY: 'dictionary',
  CONFIG: 'config',
  sysProps: 'sysProps'
});

export const accountStore = Object.freeze({
  ACCOUNTS_DATA:  'ACCOUNTS_DATA',
  ONE_PERSON_DATA: 'onePersonData',
  ACCOUNT_DATA: 'accountData'
});

export const plateReservationUses = Object.freeze({
  NOT_USED: "Not used",
  NORMAL_USE: "Normal use",
  RESERVED_FOR_RE_REGISTER: "Reserved for reregister",
  RESERVED_FOR_AUCTION: "Reserved for auction",
});
