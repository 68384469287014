import { genericFormFieldNames } from "@mb-react/krg-getid-form-data-common";
import { formFieldNames } from "@mb-react/svarog-ui";


export const commonMandatoryFieldListDL = [
  formFieldNames.GIVEN_NAME,
  formFieldNames.GIVEN_NAME_LATIN,
  formFieldNames.SURNAME,
  formFieldNames.SURNAME_LATIN,
  formFieldNames.GENDER,
  formFieldNames.NATIONALITY,
  formFieldNames.BLOOD_GROUP,
  formFieldNames.DATE_OF_BIRTH,
  formFieldNames.PLACE_OF_BIRTH_COUNTRY,
  formFieldNames.POSITION,
  formFieldNames.MARITAL_STATUS,
  formFieldNames.FATHERS_NAME,
  formFieldNames.FATHERS_NAME_LATIN,
  formFieldNames.MOTHERS_NAME,
  formFieldNames.MOTHERS_NAME_LATIN,
  genericFormFieldNames.MOTHERS_FATHER_NAME,
  genericFormFieldNames.GRANDFATHER_NAME,
  genericFormFieldNames.GRANDFATHER_NAME_LATIN,
  formFieldNames.ADDRESS_PROVINCE,
  formFieldNames.ADDRESS_CITY,
  genericFormFieldNames.ADDRESS_VILLAGE,
  genericFormFieldNames.ADDRESS_BUILDING_CLASS,
  genericFormFieldNames.ADDRESS_INFORMATION_CENTER,
  genericFormFieldNames.PROVINCE_OF_REGISTRATION,
  genericFormFieldNames.CITY_OF_REGISTRATION,
  genericFormFieldNames.VILLAGE_OF_REGISTRATION,
  genericFormFieldNames.REGISTRATION_BOOK_NUMBER,
  genericFormFieldNames.REGISTRATION_PAGE_NUMBER
];

export const mandatoryFieldListNEW_DL = [
  ...commonMandatoryFieldListDL
];

export const mandatoryFieldListREREGISTER_DL = [
  ...commonMandatoryFieldListDL,
  genericFormFieldNames.NUMBER_DL,
  genericFormFieldNames.ISSUE_DATE_DL,
  genericFormFieldNames.VALID_DATE_DL,
  genericFormFieldNames.ISSUE_COUNTRY_DL,
  genericFormFieldNames.CLASSES_DL
];
export const mandatoryFieldListREPLACE_DL = [
  ...commonMandatoryFieldListDL

];
export const mandatoryFieldListPARTLY_DAMAGED_DL = [
  ...commonMandatoryFieldListDL

];
export const mandatoryFieldListEXTEND_DL = [
  ...commonMandatoryFieldListDL

];
export const mandatoryFieldListEXPIRED_DL = [
  ...commonMandatoryFieldListDL

];
export const mandatoryFieldListFOREIGN_DL = [
  ...commonMandatoryFieldListDL,
  genericFormFieldNames.NUMBER_DL,
  genericFormFieldNames.ISSUE_DATE_DL,
  genericFormFieldNames.VALID_DATE_DL,
  genericFormFieldNames.ISSUE_COUNTRY_DL,
  genericFormFieldNames.CLASSES_DL
];

export const mandatoryFieldListCANCEL_DL = [
  ...commonMandatoryFieldListDL
]

export const mandatoryTpIdList = [
  formFieldNames.GIVEN_NAME,
  formFieldNames.GIVEN_NAME_LATIN,
  formFieldNames.SURNAME,
  formFieldNames.SURNAME_LATIN,
  formFieldNames.FATHERS_NAME,
  formFieldNames.FATHERS_NAME_LATIN,
  genericFormFieldNames.GRANDFATHER_NAME,
  genericFormFieldNames.GRANDFATHER_NAME_LATIN,
  formFieldNames.DATE_OF_BIRTH,
  formFieldNames.GENDER,
  formFieldNames.PHONE,
]

export const readOnlyFieldListNEW_DL = [
  genericFormFieldNames.NUMBER_DL,
  genericFormFieldNames.ISSUE_DATE_DL,
  genericFormFieldNames.VALID_DATE_DL,
  genericFormFieldNames.ISSUE_COUNTRY_DL,
  genericFormFieldNames.CLASSES_DL,
  genericFormFieldNames.REQUEST_MEDICAL_EXEM
];

export const readOnlyFieldListREREGISTER_DL = [
  genericFormFieldNames.REQUEST_MEDICAL_EXEM,
  genericFormFieldNames.ISSUE_COUNTRY_DL
];
export const readOnlyFieldListREPLACE_DL = [
  genericFormFieldNames.NUMBER_DL,
  genericFormFieldNames.ISSUE_DATE_DL,
  genericFormFieldNames.VALID_DATE_DL,
  genericFormFieldNames.ISSUE_COUNTRY_DL,
  genericFormFieldNames.CLASSES_DL,
  genericFormFieldNames.REQUEST_MEDICAL_EXEM

];
export const readOnlyFieldListPARTLY_DAMAGED_DL = [
  genericFormFieldNames.NUMBER_DL,
  genericFormFieldNames.ISSUE_DATE_DL,
  genericFormFieldNames.VALID_DATE_DL,
  genericFormFieldNames.ISSUE_COUNTRY_DL,
  genericFormFieldNames.CLASSES_DL
];
export const readOnlyFieldListEXTEND_DL = [
  genericFormFieldNames.NUMBER_DL,
  genericFormFieldNames.ISSUE_DATE_DL,
  genericFormFieldNames.VALID_DATE_DL,
  genericFormFieldNames.ISSUE_COUNTRY_DL,
  genericFormFieldNames.CLASSES_DL,
  genericFormFieldNames.REQUEST_MEDICAL_EXEM

];
export const readOnlyFieldListEXPIRED = [
  genericFormFieldNames.NUMBER_DL,
  genericFormFieldNames.ISSUE_DATE_DL,
  genericFormFieldNames.VALID_DATE_DL,
  genericFormFieldNames.ISSUE_COUNTRY_DL,
  genericFormFieldNames.CLASSES_DL,
  genericFormFieldNames.REQUEST_MEDICAL_EXEM

];
export const readOnlyFieldListFOREIGN_DL = [
  genericFormFieldNames.REQUEST_MEDICAL_EXEM,
];

export const readOnlyFieldListApproval_DL = [
  formFieldNames.GIVEN_NAME,
  formFieldNames.GIVEN_NAME_LATIN,
  formFieldNames.SURNAME,
  formFieldNames.SURNAME_LATIN,
  formFieldNames.GENDER,
  formFieldNames.PERSONAL_NUMBER,
  formFieldNames.NATIONALITY,
  formFieldNames.BLOOD_GROUP,
  formFieldNames.TAX_NUMBER,
  formFieldNames.DATE_OF_BIRTH,
  formFieldNames.PLACE_OF_BIRTH_COUNTRY,
  formFieldNames.PLACE_OF_BIRTH_PROVINCE,
  formFieldNames.PLACE_OF_BIRTH_CITY,
  genericFormFieldNames.PLACE_OF_BIRTH_VILLAGE,
  formFieldNames.POSITION,
  formFieldNames.MARITAL_STATUS,
  formFieldNames.FATHERS_NAME,
  formFieldNames.FATHERS_NAME_LATIN,
  formFieldNames.MOTHERS_NAME,
  formFieldNames.MOTHERS_NAME_LATIN,
  genericFormFieldNames.MOTHERS_FATHER_NAME,
  genericFormFieldNames.GRANDFATHER_NAME,
  genericFormFieldNames.GRANDFATHER_NAME_LATIN,
  formFieldNames.ADDRESS_PROVINCE,
  formFieldNames.ADDRESS_CITY,
  genericFormFieldNames.ADDRESS_VILLAGE,
  genericFormFieldNames.ADDRESS_BUILDING_CLASS,
  genericFormFieldNames.ADDRESS_INFORMATION_CENTER,
  formFieldNames.TPID,
  genericFormFieldNames.NUMBER_DL,
  genericFormFieldNames.ISSUE_DATE_DL,
  genericFormFieldNames.VALID_DATE_DL,
  genericFormFieldNames.ISSUE_COUNTRY_DL,
  genericFormFieldNames.CLASSES_DL,
  formFieldNames.PHONE,
  formFieldNames.EMAIL,
  genericFormFieldNames.PROVINCE_OF_REGISTRATION,
  genericFormFieldNames.CITY_OF_REGISTRATION,
  genericFormFieldNames.VILLAGE_OF_REGISTRATION,
  genericFormFieldNames.REGISTRATION_BOOK_NUMBER,
  genericFormFieldNames.REGISTRATION_PAGE_NUMBER,
  genericFormFieldNames.FOOD_CENTER_INDICATOR,
  formFieldNames.PROXY,
  formFieldNames.PROXY_NAME,
  formFieldNames.PROXY_SURNAME,
  formFieldNames.PROXY_PERSONAL_NUMBER,
  formFieldNames.PROXY_GENDER,
  formFieldNames.PROXY_DATE_OF_BIRTH,
  genericFormFieldNames.PROXY_FATHER_NAME,
  genericFormFieldNames.PROXY_GRANDFATHER_NAME,
  genericFormFieldNames.REQUEST_MEDICAL_EXEM,
  genericFormFieldNames.REQUEST_APPROVAL,
  genericFormFieldNames.REQUEST_APPROVAL_COMMENT,
  formFieldNames.COMMENT,
];
export const readOnlyFieldListIssuanceDL = [
  formFieldNames.GIVEN_NAME,
  formFieldNames.GIVEN_NAME_LATIN,
  formFieldNames.SURNAME,
  formFieldNames.SURNAME_LATIN,
  formFieldNames.GENDER,
  formFieldNames.PERSONAL_NUMBER,
  formFieldNames.NATIONALITY,
  formFieldNames.BLOOD_GROUP,
  formFieldNames.TAX_NUMBER,
  formFieldNames.DATE_OF_BIRTH,
  formFieldNames.PLACE_OF_BIRTH_COUNTRY,
  formFieldNames.PLACE_OF_BIRTH_PROVINCE,
  formFieldNames.PLACE_OF_BIRTH_CITY,
  genericFormFieldNames.PLACE_OF_BIRTH_VILLAGE,
  formFieldNames.POSITION,
  formFieldNames.MARITAL_STATUS,
  formFieldNames.FATHERS_NAME,
  formFieldNames.FATHERS_NAME_LATIN,
  formFieldNames.MOTHERS_NAME,
  formFieldNames.MOTHERS_NAME_LATIN,
  genericFormFieldNames.MOTHERS_FATHER_NAME,
  genericFormFieldNames.GRANDFATHER_NAME,
  genericFormFieldNames.GRANDFATHER_NAME_LATIN,
  formFieldNames.ADDRESS_PROVINCE,
  formFieldNames.ADDRESS_CITY,
  genericFormFieldNames.ADDRESS_VILLAGE,
  genericFormFieldNames.ADDRESS_BUILDING_CLASS,
  genericFormFieldNames.ADDRESS_INFORMATION_CENTER,
  formFieldNames.TPID,
  genericFormFieldNames.NUMBER_DL,
  genericFormFieldNames.ISSUE_DATE_DL,
  genericFormFieldNames.VALID_DATE_DL,
  genericFormFieldNames.ISSUE_COUNTRY_DL,
  genericFormFieldNames.CLASSES_DL,
  formFieldNames.PHONE,
  formFieldNames.EMAIL,
  genericFormFieldNames.PROVINCE_OF_REGISTRATION,
  genericFormFieldNames.CITY_OF_REGISTRATION,
  genericFormFieldNames.VILLAGE_OF_REGISTRATION,
  genericFormFieldNames.REGISTRATION_BOOK_NUMBER,
  genericFormFieldNames.REGISTRATION_PAGE_NUMBER,
  genericFormFieldNames.FOOD_CENTER_INDICATOR,
  formFieldNames.PROXY,
  formFieldNames.PROXY_NAME,
  formFieldNames.PROXY_SURNAME,
  formFieldNames.PROXY_PERSONAL_NUMBER,
  formFieldNames.PROXY_GENDER,
  formFieldNames.PROXY_DATE_OF_BIRTH,
  genericFormFieldNames.PROXY_FATHER_NAME,
  genericFormFieldNames.PROXY_GRANDFATHER_NAME,
  genericFormFieldNames.REQUEST_MEDICAL_EXEM,
  genericFormFieldNames.REQUEST_APPROVAL,
  genericFormFieldNames.REQUEST_APPROVAL_COMMENT,
  formFieldNames.COMMENT,
];
export const readOnlyFieldListPayment_DL = [
  formFieldNames.AMOUNT,
  formFieldNames.PAYMENT_DATE
]
export const mandatoryFieldListPayment_DL = [
  formFieldNames.PAYMENT_METHOD,
  formFieldNames.PAYMENT_REFERENCE_NUMBER
]

export const readOnlyFieldListStateHandling = [
  formFieldNames.GIVEN_NAME,
  formFieldNames.GIVEN_NAME_LATIN,
  formFieldNames.SURNAME,
  formFieldNames.SURNAME_LATIN,
  formFieldNames.GENDER,
  formFieldNames.PERSONAL_NUMBER,
  formFieldNames.NATIONALITY,
  formFieldNames.BLOOD_GROUP,
  formFieldNames.TAX_NUMBER,
  formFieldNames.DATE_OF_BIRTH,
  formFieldNames.PLACE_OF_BIRTH_COUNTRY,
  formFieldNames.PLACE_OF_BIRTH_PROVINCE,
  formFieldNames.PLACE_OF_BIRTH_CITY,
  genericFormFieldNames.PLACE_OF_BIRTH_VILLAGE,
  formFieldNames.POSITION,
  formFieldNames.MARITAL_STATUS,
  formFieldNames.FATHERS_NAME,
  formFieldNames.FATHERS_NAME_LATIN,
  formFieldNames.MOTHERS_NAME,
  formFieldNames.MOTHERS_NAME_LATIN,
  genericFormFieldNames.MOTHERS_FATHER_NAME,
  genericFormFieldNames.GRANDFATHER_NAME,
  genericFormFieldNames.GRANDFATHER_NAME_LATIN,
  formFieldNames.ADDRESS_PROVINCE,
  formFieldNames.ADDRESS_CITY,
  genericFormFieldNames.ADDRESS_VILLAGE,
  genericFormFieldNames.ADDRESS_BUILDING_CLASS,
  genericFormFieldNames.ADDRESS_INFORMATION_CENTER,
  formFieldNames.TPID,
  genericFormFieldNames.NUMBER_DL,
  genericFormFieldNames.ISSUE_DATE_DL,
  genericFormFieldNames.VALID_DATE_DL,
  genericFormFieldNames.ISSUE_COUNTRY_DL,
  genericFormFieldNames.CLASSES_DL,
  formFieldNames.PHONE,
  formFieldNames.EMAIL,
  genericFormFieldNames.PROVINCE_OF_REGISTRATION,
  genericFormFieldNames.CITY_OF_REGISTRATION,
  genericFormFieldNames.VILLAGE_OF_REGISTRATION,
  genericFormFieldNames.REGISTRATION_BOOK_NUMBER,
  genericFormFieldNames.REGISTRATION_PAGE_NUMBER,
  genericFormFieldNames.FOOD_CENTER_INDICATOR,
  formFieldNames.PROXY,
  formFieldNames.PROXY_NAME,
  formFieldNames.PROXY_SURNAME,
  formFieldNames.PROXY_PERSONAL_NUMBER,
  formFieldNames.PROXY_GENDER,
  formFieldNames.PROXY_DATE_OF_BIRTH,
  genericFormFieldNames.PROXY_FATHER_NAME,
  genericFormFieldNames.PROXY_GRANDFATHER_NAME,
  genericFormFieldNames.REQUEST_MEDICAL_EXEM,
  genericFormFieldNames.REQUEST_APPROVAL,
  genericFormFieldNames.REQUEST_APPROVAL_COMMENT,
  formFieldNames.COMMENT,
];