export const customMiddleware = storeAPI => next => action => {
  // Do something in here, when each action is dispatched
  // Todo: Move this to the react core?
  if(action?.type === "NAV_WIZARD_NEXT_STEP" || action?.type === "NAV_WIZARD_PREVIOUS"){
    if(document.body.scrollHeight > window.innerHeight){
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }
  return next(action)
}