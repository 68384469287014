import {formFieldNames} from "@mb-react/svarog-ui";
import {useMemo} from "react";
import {useSelector} from "react-redux";
import {formFieldsMeta, forms} from "../../../../../../constants/form";
import {isMandatory, isReadOnly} from "../../../../../../util/businesslogicUtil";
import {getFormValues} from "@mb-react/mb-react-core";
import {genericFormFieldNames} from "@mb-react/krg-getid-form-data-common";

export const vehicleTemporaryPlatesFieldSettings = (formName) => {

  const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION))
  const vehicleDataFormValues = useSelector((state) => getFormValues(state, formName.formName))


  const handleTemporaryPlateNumberSettings = useMemo(() => {
    let props = {
      [genericFormFieldNames.TEMPORARY_PLATE_NUMBER]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, genericFormFieldNames.TEMPORARY_PLATE_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          maxLength: 11,
          placeHolder: "##-## #####"
        },
        required: isMandatory(vehicleDataFormValues, genericFormFieldNames.TEMPORARY_PLATE_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return props
  }, [])

  const handleNumberOfTemporaryPlatesSettings = useMemo(() => {
    let props = {
      [genericFormFieldNames.NUMBER_OF_TEMPORARY_PLATES]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: isReadOnly(vehicleDataFormValues, genericFormFieldNames.NUMBER_OF_TEMPORARY_PLATES, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        },
        required: false,//isMandatory(vehicleDataFormValues, genericFormFieldNames.NUMBER_OF_TEMPORARY_PLATES, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4",
        exclude: true
      }
    }

    return props
  }, [])

  return {...handleTemporaryPlateNumberSettings, ...handleNumberOfTemporaryPlatesSettings}

};