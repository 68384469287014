import React, { Component } from 'react';
import { connect } from 'react-redux';
import RegisterAccount from './RegisterAccount';
import ContainerHOC from "../../ContainerHOC";

class RegisterAccountContainer extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { } = this.props
        return (
          <ContainerHOC>
            <RegisterAccount />
          </ContainerHOC>
        );
    }
}



const mapStateToProps = state => {
    return {
    };
};
export default connect(mapStateToProps)(RegisterAccountContainer);
RegisterAccountContainer.propTypes = {
};
