import {GenericFields, GenericFieldsList, formFieldNames} from "@mb-react/svarog-ui";
import {prepareServerStringDate} from "../sagas/generators/sagaUtil";
import moment from "moment";

export const validateTintedWindows = (value) => {
    const intValue = parseInt(value, 10);
    return intValue >= 0 && intValue <= 10;
};

export const validatePercentage = (value) => {
    const intValue = parseInt(value, 10);
    return intValue >= 0 && intValue <= 100;
};

export const extractGenericFields = (formValues, listOfGenericFields) => {
    let genericFields = new GenericFields();
    let genericFieldsList = [];
    if (listOfGenericFields && listOfGenericFields.length > 0) {
        listOfGenericFields.forEach(element => {
            if (formValues[element] !== undefined && formValues[element]) {
                let singleGenericField = new GenericFieldsList();
                singleGenericField.key = element;
                if (Array.isArray(formValues[element])) {
                    singleGenericField.value = formValues[element].toString();
                } else if (typeof formValues[element] == "object" && moment(formValues[element], 'YYYY-MM-DD').isValid()) {
                    singleGenericField.value = prepareServerStringDate(formValues[element])
                } else {
                    singleGenericField.value = formValues[element]
                }
                singleGenericField.genericFieldDataType = typeof formValues[element] == 'boolean' ? 'BOOLEAN' : 'STRING';
                genericFieldsList.push(singleGenericField)
            }
        });
    }
    genericFields.genericFieldDTOList = genericFieldsList;
    return genericFields;
};

export const vehicleFormValuesObject = (formValues) => {

    formValues = {...formValues, "dateOfBirth": prepareServerStringDate(formValues.dateOfBirth, "DD/MM/yyyy")}

    return {
        [formFieldNames.VEHICLE_USAGE_TYPE]: formValues?.[formFieldNames.VEHICLE_USAGE_TYPE],
        [formFieldNames.VEHICLE_BRAND]: formValues?.[formFieldNames.VEHICLE_BRAND],
        [formFieldNames.VEHICLE_MODEL]: formValues?.[formFieldNames.VEHICLE_MODEL],
        [formFieldNames.VEHICLE_CATEGORY]: formValues?.[formFieldNames.VEHICLE_CATEGORY],
        [formFieldNames.VEHICLE_SUBCATEGORY]: formValues?.[formFieldNames.VEHICLE_SUBCATEGORY],
        [formFieldNames.PRODUCTION_YEAR]: formValues?.[formFieldNames.PRODUCTION_YEAR],
        [formFieldNames.CHASSIS_NUMBER]: formValues?.[formFieldNames.CHASSIS_NUMBER],
        [formFieldNames.VEHICLE_ID]: formValues?.[formFieldNames.VEHICLE_ID],
        genericFieldsDTO: {
            genericFieldDTOList: preparePersonFormValues(cleanObject(searchPersonFormValues(formValues)))
        },
        plateDTOList: [
          cleanObject(plateFormValuesObject(formValues))
        ]
    }
};

export const cleanObject = (obj) => {
    Object.keys(obj).forEach((key) => (obj[key] == null || obj[key] == "") && delete obj[key]);
    return obj;
};

const preparePersonFormValues = values => {
    let array = []
    Object.keys(values).forEach(k => {
        array.push({
            key: k,
            value: values[k],
            genericFieldDataType: "STRING"
        })
    })
    return array;
}

export const plateFormValuesObject = (formValues) => {
    return {
        [formFieldNames.PLATE_NUMBER]: formValues?.[formFieldNames.PLATE_NUMBER],
        [formFieldNames.PLATE_PROVINCE]: formValues?.[formFieldNames.PLATE_PROVINCE],
        [formFieldNames.PLATE_SERIAL_NUMBER]: formValues?.[formFieldNames.PLATE_SERIAL_NUMBER],
        [formFieldNames.PLATE_COUNTRY_CODE]: formValues?.[formFieldNames.PLATE_COUNTRY_CODE]
    }
};

export const searchPersonFormValues = (formValues) => {
    return {
        [formFieldNames.GIVEN_NAME]: formValues?.[formFieldNames.GIVEN_NAME],
        [formFieldNames.SURNAME]: formValues?.[formFieldNames.SURNAME],
        [formFieldNames.TPID]: formValues?.[formFieldNames.TPID],
        [formFieldNames.DATE_OF_BIRTH]: formValues?.[formFieldNames.DATE_OF_BIRTH]
    }
};

export const personFormValuesObject = (formValues) => {
    return {
        [formFieldNames.GIVEN_NAME]: formValues?.[formFieldNames.GIVEN_NAME],
        [formFieldNames.SURNAME]: formValues?.[formFieldNames.SURNAME],
        [formFieldNames.DATE_OF_BIRTH]: formValues?.[formFieldNames.DATE_OF_BIRTH]
    }
};

export const searchVehicleFormValues = (formValues) => {
    return {
        [formFieldNames.PLATE_NUMBER]: formValues?.[formFieldNames.PLATE_NUMBER],
        [formFieldNames.CHASSIS_NUMBER]: formValues?.[formFieldNames.CHASSIS_NUMBER],
        [formFieldNames.VEHICLE_USAGE_TYPE]: formValues?.[formFieldNames.VEHICLE_USAGE_TYPE],
        [formFieldNames.VEHICLE_BRAND]: formValues?.[formFieldNames.VEHICLE_BRAND],
        [formFieldNames.VEHICLE_MODEL]: formValues?.[formFieldNames.VEHICLE_MODEL],
        [formFieldNames.VEHICLE_CATEGORY]: formValues?.[formFieldNames.VEHICLE_CATEGORY],
        [formFieldNames.PRODUCTION_YEAR]: formValues?.[formFieldNames.PRODUCTION_YEAR]
    }
};