import {PRODUCTION} from './constants/environment'
import {reducers} from './reducers/index'
import {sagas} from './sagas/index'

import thunk from 'redux-thunk'
import freeze from 'redux-freeze'
import createSagaMiddleware from 'redux-saga'
import {syncTranslationWithStore} from 'react-redux-i18n'
import {applyMiddleware, compose, createStore} from 'redux'
import {customMiddleware} from "./middleware";

let middlewares = []

// redux-thunk is an implicit dependency for i18n
middlewares.push(thunk)
middlewares.push(customMiddleware)

// add the saga middleware
const sagaMiddleware = createSagaMiddleware()
middlewares.push(sagaMiddleware)

// Redux middleware that prevents state from being mutated anywhere in the app.
// When mutation occurs, an error will be thrown by the runtime.
// This is useful during development mode to ensure that no part of the app accidentally mutates the state.
if (process.env.NODE_ENV !== PRODUCTION) {
  middlewares.push(freeze)
}

// Redux DevTools for debugging application's state changes
const composeEnhancers =
   (process.env.NODE_ENV !== PRODUCTION && window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
 //compose;

// compose enhancers
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

// create the store
const store = createStore(reducers,enhancer)

sagaMiddleware.run(sagas)

// used in i18n
syncTranslationWithStore(store)

// export
export { store }
