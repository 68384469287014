import {getFormValues, getFromAppStore} from "@mb-react/mb-react-core";
import { useSelector } from "react-redux";
import {applicationStore, MENU_BAR_ITEMS} from "../../../../constants/store";
import {PROTECTED} from "../../../../constants/general";
import {
    isIdentityActivated,
    isLoggedAccountLegalEntity,
    isLoggedIn,
    isNotRegisteredUserRole
} from "../../../../util/businesslogicUtil";
import {forms} from "../../../../constants/form";

// Start client

// user logged out
const scLogout = [
    MENU_BAR_ITEMS.LOGIN
];

// user logged in
const scLogin = [
    MENU_BAR_ITEMS.ADMIN_HOME,
    MENU_BAR_ITEMS.LOGOUT_SC
]

/////////////////////////////////////////////////////////////////////

// DL phase
const dlActiveIdentityUserLogged = [
    MENU_BAR_ITEMS.HOME,
    MENU_BAR_ITEMS.MY_APPLICATIONS,
    MENU_BAR_ITEMS.DRIVER_LICENSE,
    MENU_BAR_ITEMS.IDENTITY_MANAGEMENT,
    MENU_BAR_ITEMS.HOW_TO_APPLY,
    MENU_BAR_ITEMS.LOGOUT
]

// use DDL
const dlActiveIdentityUserLoggedDDL = [
    MENU_BAR_ITEMS.HOME,
    MENU_BAR_ITEMS.MY_APPLICATIONS,
    MENU_BAR_ITEMS.DRIVER_LICENSE_DDL,
    MENU_BAR_ITEMS.IDENTITY_MANAGEMENT,
    MENU_BAR_ITEMS.HOW_TO_APPLY,
    MENU_BAR_ITEMS.LOGOUT
]

const dlApplyWoAccount = [
    MENU_BAR_ITEMS.HOME,
    MENU_BAR_ITEMS.DRIVER_LICENSE,
    MENU_BAR_ITEMS.IDENTITY_MANAGEMENT,
    MENU_BAR_ITEMS.CHECK_APPLICATION_STATUS,
    MENU_BAR_ITEMS.HOW_TO_APPLY,
    MENU_BAR_ITEMS.LOGIN
]

//use DDL
const dlApplyWoAccountDDL = [
    MENU_BAR_ITEMS.HOME,
    MENU_BAR_ITEMS.DRIVER_LICENSE_DDL,
    MENU_BAR_ITEMS.IDENTITY_MANAGEMENT,
    MENU_BAR_ITEMS.CHECK_APPLICATION_STATUS,
    MENU_BAR_ITEMS.HOW_TO_APPLY,
    MENU_BAR_ITEMS.LOGIN
]

/////////////////////////////////////////////////////////////

//VR phase
//Natural person
const npActiveIdentityUserLogged = [
    MENU_BAR_ITEMS.HOME,
    MENU_BAR_ITEMS.BOOK_APPOINTMENT,
    MENU_BAR_ITEMS.MY_APPOINTMENTS,
    MENU_BAR_ITEMS.MY_APPLICATIONS,
    MENU_BAR_ITEMS.DRIVER_LICENSE,
    MENU_BAR_ITEMS.VEHICLE_WORKFLOW,
    MENU_BAR_ITEMS.IDENTITY_MANAGEMENT,
    MENU_BAR_ITEMS.HOW_TO_APPLY,
    MENU_BAR_ITEMS.LOGOUT
]

//Natural person -DDL
const npActiveIdentityUserLoggedDDL = [
    MENU_BAR_ITEMS.HOME,
    MENU_BAR_ITEMS.BOOK_APPOINTMENT,
    MENU_BAR_ITEMS.MY_APPOINTMENTS,
    MENU_BAR_ITEMS.MY_APPLICATIONS,
    MENU_BAR_ITEMS.DRIVER_LICENSE_DDL,
    MENU_BAR_ITEMS.VEHICLE_WORKFLOW_DDL,
    MENU_BAR_ITEMS.IDENTITY_MANAGEMENT,
    MENU_BAR_ITEMS.HOW_TO_APPLY,
    MENU_BAR_ITEMS.LOGOUT
]

//Legal entity
const leActiveIdentityUserLogged = [
    MENU_BAR_ITEMS.HOME,
    MENU_BAR_ITEMS.BOOK_APPOINTMENT,
    MENU_BAR_ITEMS.MY_APPOINTMENTS,
    MENU_BAR_ITEMS.MY_APPLICATIONS,
    MENU_BAR_ITEMS.VEHICLE_WORKFLOW,
    MENU_BAR_ITEMS.IDENTITY_MANAGEMENT,
    MENU_BAR_ITEMS.HOW_TO_APPLY,
    MENU_BAR_ITEMS.LOGOUT
]

//Legal entity DLL
const leActiveIdentityUserLoggedDDL = [
    MENU_BAR_ITEMS.HOME,
    MENU_BAR_ITEMS.BOOK_APPOINTMENT,
    MENU_BAR_ITEMS.MY_APPOINTMENTS,
    MENU_BAR_ITEMS.MY_APPLICATIONS,
    MENU_BAR_ITEMS.VEHICLE_WORKFLOW_DDL,
    MENU_BAR_ITEMS.IDENTITY_MANAGEMENT,
    MENU_BAR_ITEMS.HOW_TO_APPLY,
    MENU_BAR_ITEMS.LOGOUT
]


// apply without account
const applyWoAccount = [
    MENU_BAR_ITEMS.HOME,
    MENU_BAR_ITEMS.BOOK_APPOINTMENT,
    MENU_BAR_ITEMS.DRIVER_LICENSE,
    MENU_BAR_ITEMS.VEHICLE_WORKFLOW,
    MENU_BAR_ITEMS.IDENTITY_MANAGEMENT,
    MENU_BAR_ITEMS.CHECK_APPLICATION_STATUS,
    MENU_BAR_ITEMS.HOW_TO_APPLY,
    MENU_BAR_ITEMS.LOGIN
]

// apply without account DDL
const applyWoAccountDDL = [
    MENU_BAR_ITEMS.HOME,
    MENU_BAR_ITEMS.BOOK_APPOINTMENT,
    MENU_BAR_ITEMS.DRIVER_LICENSE_DDL,
    MENU_BAR_ITEMS.VEHICLE_WORKFLOW_DDL,
    MENU_BAR_ITEMS.IDENTITY_MANAGEMENT,
    MENU_BAR_ITEMS.CHECK_APPLICATION_STATUS,
    MENU_BAR_ITEMS.HOW_TO_APPLY,
    MENU_BAR_ITEMS.LOGIN
]

////////////////////////////////////////////////////////////////

//common list
const inactiveIdentityUserLogged = [
    MENU_BAR_ITEMS.HOME,
    MENU_BAR_ITEMS.HOW_TO_APPLY,
    MENU_BAR_ITEMS.LOGOUT
]

//empty placeholder
const emptyList = [
    MENU_BAR_ITEMS.PLACEHOLDER
]

export function getListOfMenuBarItems() {
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))
    const useApplyWithoutAccount = useSelector((state) => getFormValues(state, forms.APPLICATION)?.["useApplyWithoutAccount"])

    let itemsList= emptyList;

    if (configurationData.operationMode === PROTECTED) {
        if(isLoggedIn()){
            itemsList = scLogin
        }else{
            itemsList = scLogout
        }
    }else{
        //check if DL goLive phase is active
        if(configurationData.dlGoLive){
            if(isLoggedIn()){
                if(isNotRegisteredUserRole(userRights)){
                    itemsList = configurationData.useDDL ? dlApplyWoAccountDDL : dlApplyWoAccount
                }else{
                    if(isIdentityActivated()){
                        itemsList = configurationData.useDDL ? dlActiveIdentityUserLoggedDDL : dlActiveIdentityUserLogged
                    }else{
                        if(useApplyWithoutAccount) {
                            itemsList = configurationData.useDDL ? dlApplyWoAccountDDL : dlApplyWoAccount
                        }else{
                            itemsList = inactiveIdentityUserLogged
                        }
                    }
                }
            }else{
                if(useApplyWithoutAccount) {
                    itemsList = configurationData.useDDL ? dlApplyWoAccountDDL : dlApplyWoAccount
                }else{
                    itemsList = emptyList
                }
            }
        }else {
            if(isLoggedIn()){
                if(isNotRegisteredUserRole(userRights)){
                    itemsList = configurationData.useDDL ? applyWoAccountDDL : applyWoAccount
                }else{
                    if(isIdentityActivated()){
                        if(isLoggedAccountLegalEntity()){
                            itemsList = configurationData.useDDL ? leActiveIdentityUserLoggedDDL : leActiveIdentityUserLogged
                        }else{
                            itemsList = configurationData.useDDL ? npActiveIdentityUserLoggedDDL : npActiveIdentityUserLogged
                        }

                    }else {
                        if(useApplyWithoutAccount) {
                            itemsList = configurationData.useDDL ? applyWoAccountDDL : applyWoAccount
                        }else{
                            itemsList = inactiveIdentityUserLogged
                        }
                    }
                }
            }else {
                if(useApplyWithoutAccount) {
                    itemsList = configurationData.useDDL ? applyWoAccountDDL : applyWoAccount
                }else{
                    itemsList = emptyList
                }
            }
        }
    }

    if(!configurationData.enableAppointmentsWorkflow){
        itemsList = itemsList.filter((item) => {return item !== MENU_BAR_ITEMS.BOOK_APPOINTMENT})
        itemsList = itemsList.filter((item) => {return item !== MENU_BAR_ITEMS.MY_APPOINTMENTS})
    }

    if(!configurationData.enableIdentityManagementEnrollment){
        itemsList = itemsList.filter((item) => {return item !== MENU_BAR_ITEMS.IDENTITY_MANAGEMENT})
    }

    return itemsList;
}