import { change, getFromAppStore } from '@mb-react/mb-react-core';
import { formFieldNames } from '@mb-react/svarog-ui';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { forms } from '../../constants/form';
import { appStoreFields } from '../../constants/store';
import ContainerHOC from '../ContainerHOC';
import ModifyIdentityManagementWorkflow from './application/ModifyIdentityManagementWorkflow';

const ModifyIMCreateWrapper = (props) => {
  useEffect(() => {
    const { dispatch, editDraft } = props;
    if (!editDraft) {
      dispatch(change(forms.APPLICATION, formFieldNames.DOCUMENT_TYPE, "IDENTITY"));
      //TODO set proper application type based UC
      dispatch(change(forms.APPLICATION, "applicationType", "IM"));
      dispatch(change(forms.APPLICATION, formFieldNames.USE_CASE, "MODIFICATION"));
      dispatch(change(forms.APPLICATION, formFieldNames.ENROLLMENT_STARTED, moment(Date.now()).format("yyyy-MM-DDTHH:mm:ss.SSSSSSS")));
    }
  }, [])


  return (
    <ContainerHOC fullPage>
        <ModifyIdentityManagementWorkflow formNameSufix={'Source'}/>
    </ContainerHOC>
  )
}
const mapStateToProps = state => {
  const editDraft = getFromAppStore(state, appStoreFields.editDraft);
  return {
    editDraft: editDraft
  };
};
export default connect(mapStateToProps)(ModifyIMCreateWrapper);
ModifyIMCreateWrapper.propTypes = {
  dispatch: PropTypes.any
};
