import moment, {now} from 'moment-mini';
import {errorCodes} from '../constants/form'
import {addToAppStore} from "@mb-react/mb-react-core";
import {applicationStore} from "../constants/store";

export const mainClassName = 'col-6 col-md-4'

export const headerLabels = Object.freeze(
    {
        ADDRESS: 'address',
        CONTACT: 'contact'
    }
)

/**
 * Concatenates given field value with 'label.' prefix
 * @author Minja Raicevic
 */
export function createLabel(fieldName){
    if(fieldName){
        return `label.${fieldName}`
    }
}

/**
 * Checks if value exists with provided fieldName on the provided sectionPath.
 * If value exists, performs provided callback function.
 * Provided callback function MUST RETURN boolean value:
 *       TRUE - if error exists
 *       FALSE - error don't exists
 *
 * @author Minja Raicevic
 */
export const validateFieldValue = (values, sectionPath, fieldName, callback) => {
    const keys = sectionPath.split('.');

    const reducerValue = (accumulatorValue, key) => {
        return accumulatorValue[key]
    }
    try {
        const lastObj = keys.reduce(reducerValue, values);
        return callback(lastObj[fieldName])
    } catch (e) {
       // console.error('ERROR -> FORM-DATA-COMMON -> addErrorIfExist function :: ' + e.name + ': ' + e.message)
        return true
    }
}

/**
 * Checks if a obj value exists with provided fieldName on the provided sectionPath.
 * If value exists, performs provided callback function.
 * Provided callback function MUST RETURN boolean value:
 *       if value id TRUE, error will be added to the provided err object
 *
 * @author Minja Raicevic
 */
export const addErrorIfExist = (obj, err, sectionPath, fieldName, val, callback) => {

    const errorOccured = validateFieldValue(obj, sectionPath, fieldName, callback)
    if(errorOccured){
        const reducerError = (accumulator, key) => {
            return accumulator[key] = accumulator[key] || {};
        }
        const keys = sectionPath.split('.');
        const lastObjError = keys.reduce(reducerError, err);
        lastObjError[fieldName] = val;
    }
    return errorOccured
};



/**
 * Checks if a value exists. If not, error is added to the given errors array.
 * @author Minja Raicevic
 */
export const isRequired = (values, fieldName, errors, sectionPrefix) => {
    if(sectionPrefix && values){
        return addErrorIfExist(values, errors, sectionPrefix, fieldName, errorCodes.REQUIRED, (value)=> !value)
    } else if (!values || (values && !values[fieldName])) {
        errors[fieldName] = errorCodes.REQUIRED;
        return true;
    }
    return false;
};

/**
 * Checks if a field value is date before current date. If not, error is added to the given errors array.
 * As field is required by default, if field value doesn't exist 'required' error is also added to the errors array.
 * If date is not required set 'required' flag to false.
 */
export const isBeforeCurrentDate = (values, fieldName, errors, required, sectionPrefix) => {
    isBeforeDate(values, fieldName, now(), errors, required, errorCodes.DATE_IN_THE_FUTURE, sectionPrefix);
};

/**
 * Checks if a field value is date before given date. If not, error is added to the given errors array.
 * As field is required by default, if field value doesn't exist 'required' error is also added to the errors array.
 * If date is not required set 'required' flag to false.
 * @author Minja Raicevic
 */
export const isBeforeDate = (values, fieldName, compareToDate, errors, required, customErrorMessage, sectionPrefix) => {
    if(sectionPrefix){
        if(!isRequired(values, fieldName, errors, sectionPrefix)){
            addErrorIfExist(values, errors, sectionPrefix, fieldName, customErrorMessage || errorCodes.DATE_INVALID, (value)=> isMomentBeforeGivenValue(compareToDate, value))
        }
    }else{
        const fieldValue = values[fieldName];
        if (required && !fieldValue) {
            errors[fieldName] = errorCodes.REQUIRED;
            return false;
        } else {
            if (isMomentBeforeGivenValue(compareToDate, fieldValue)) {
                errors[fieldName] = customErrorMessage || errorCodes.DATE_INVALID;
                return false;
            }
        }
    }
    return true;
};

const isMomentBeforeGivenValue = (dateBefore, dateAfter) =>{
    return moment(dateBefore).isBefore(moment(dateAfter))
}

export const onCancel = (dispatch) => {
    dispatch(addToAppStore(applicationStore.SHOW_CANCEL_MODAL, true))
}

/**
 * Checks if a dateFrom field value isBeforeCurrentDate and dateTo field value isAfterDate dateFrom. If not, error is added to the given errors array.
 * As fields are required by default, if field values doesn't exist 'required' errors are also added to the errors array.
 * If interval datea are not required, set 'notRequired' flag to true.
 * @author Minja Raicevic
 */
// TODO TEST
/*export const validateIntervalDates = (values, dateFrom, dateTo, errors, notRequired, sectionPrefix) => {
    if (isBeforeCurrentDate(values, dateFrom, errors, notRequired, sectionPrefix)) {
        isAfterDate(values, dateTo, values[dateFrom], errors, notRequired, sectionPrefix);
    }
};*/

/**
 * Checks if a field value is date after given date. If not, error is added to the given errors array.
 * As field is required by default, if field value doesn't exist 'required' error is also added to the errors array.
 * If date is not required set 'notRequired' flag to true.
 * @author Minja Raicevic
 */
// TODO TEST
/*export const isAfterDate = (values, fieldName, compateToDate, errors, notRequired, customErrorMessage, sectionPrefix) => {
    const fieldValue = values[fieldName];
    if (!notRequired && !fieldValue) {
        errors[fieldName] = 'required';
        return false;
    } else {
        if (!moment(compateToDate).isBefore(moment(fieldValue))) {
            errors[fieldName] = customErrorMessage || 'dateInvalid';
            return false;
        }
    }
    return true;
};*/

/**
 * Checks if a field value is date after current date. If not, error is added to the given errors array.
 * As field is required by default, if field value doesn't exist 'required' error is also added to the errors array.
 * If date is not required set 'notRequired' flag to true.
 * @author Minja Raicevic
 */
// TODO TEST
/*export const isAfterCurrentDate = (values, fieldName, errors, notRequired) => {
    isAfterDate(values, fieldName, moment(), errors, notRequired, 'dateCurrentOrInThePast');
};*/

// TODO TEST
/*export const isOlderThanNumberOfYears = (values, fieldName, numberOfYears, errors, notRequired, customErrorMessage) => {
    const fieldValue = values[fieldName];
    if (!notRequired && !fieldValue) {
        errors[fieldName] = 'required';
        return false;
    } else {
        const years = moment().diff(moment(fieldValue), 'years')
        if (years < numberOfYears) {
            errors[fieldName] = customErrorMessage || 'dateInvalid';
            return false;
        }
    }
    return true;
};*/
