import {setCookie} from "@mb-react/mb-bootstrap-core";
import {
    Button, Captcha, FormElement, MBForm,
    addToAppStore,
    fieldType,
    getFormValues,
    showToast,
    toastIDs
} from "@mb-react/mb-react-core";
import {formFieldNames} from "@mb-react/svarog-ui";
import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {I18n, Translate} from "react-redux-i18n";
import {
    LEGAL_ENTITY,
    NATURAL_PERSON,
    accountSubType,
    getLegalEntityType,
    getAccountTypeOptions
} from "../../../constants/application";
import {dictionaryReference} from "../../../constants/dictionaries";
import {forms} from "../../../constants/form";
import {SELECTED_ACCOUNT_EMAIL, SELECTED_ACCOUNT_ID, projectDictionaries} from "../../../constants/general";
import {applicationStore} from "../../../constants/store";
import {useHandleAccountServices} from "../../../services/account/accountServiceHandler";
import {useHandleCommonServices} from "../../../services/commonServiceHandler";
import {
    REGISTER_ACCOUNT_GENERIC_FIELDS,
    isOperationModeNotProtected, isOperationModeProtected,
    prepareGenericsForSerialization,
} from "../../../util/businesslogicUtil";
import {prepareSpecificDictionaryElementForDropdown} from "../../../util/dataPreparationUtil";
import {generatePassword} from "../../../util/util";
import {useRegisterAccountValidation} from "./registerAccountValidation";
import krLogo1 from "../../../../public/media/KRG_logo.svg";

function RegisterAccount() {
    const formValues = useSelector((state) => getFormValues(state, forms.REGISTER_ACCOUNT))
    const accountType = formValues?.[formFieldNames.ACCOUNT_TYPE]
    const leType = formValues?.[formFieldNames.LE_TYPE]
    const subtypeOptions = useSelector((state) => prepareSpecificDictionaryElementForDropdown(state, dictionaryReference.LEGAL_ENTITY_SUBTYPE));
    const handleCommonServices = useHandleCommonServices()
    const [captchaData, setCaptchaData] = useState();
    const [captchaUrl, setCaptchaUrl] = useState(null);
    const [createdUsername, setCreatedUsername] = useState(null);
    const [createdPassword, setCreatedPassword] = useState(null);
    const [createdEmail, setCreatedEmail] = useState(null);
    const [showPrintButton, setShowPrintButton] = useState(false);
    const handleAccountServices = useHandleAccountServices();
    const isNotProtectedMode = isOperationModeNotProtected();
    const isProtectedMode = isOperationModeProtected();
    const [report, setReport] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        isNotProtectedMode && getCaptcha();
    }, []);
    const onRegisterSuccess = (response, request) => {
        if (isNotProtectedMode) {
            window.location = "/registrationSuccessfulPage";
            return;
        }

        if (response?.data) {
            setShowPrintButton(true);
            dispatch(addToAppStore(applicationStore.REGISTERED_ACCOUNT_ID, response?.data))
            setCookie(SELECTED_ACCOUNT_ID, response?.data)
            setCookie(SELECTED_ACCOUNT_EMAIL, btoa(request?.[formFieldNames.EMAIL]))
        }
        dispatch(showToast(toastIDs.MESSAGE_TOAST, 'success', {messageId: I18n.t("label.createApplicationAfterRegister")}))
    };
    const onRegisterError = () => {
        setCreatedUsername(null);
        setCreatedPassword(null);
        setCreatedEmail(null);
        isNotProtectedMode && getCaptcha()
    };

    const submitRegister = (data, form) => {
        if (isNotProtectedMode && captchaData == null)
            return;

        let generatedPassword;
        if (isProtectedMode) generatedPassword = generatePassword();

        //set entered credentials
        if (isProtectedMode) {
            setCreatedUsername(data[formFieldNames.USERNAME]);
            setCreatedPassword(generatedPassword);
            setCreatedEmail(data[formFieldNames.EMAIL])
        }

        if (data != null) {
            const registerParams = {
                accountType: configurationData.dlGoLive ? NATURAL_PERSON : data[formFieldNames.ACCOUNT_TYPE],
                username: data[formFieldNames.USERNAME],
                password: isNotProtectedMode ? data[formFieldNames.PASSWORD] : generatedPassword,
                repeatPassword: isNotProtectedMode ? data[formFieldNames.PASSWORD_REPEATED] : generatedPassword,
                email: data[formFieldNames.EMAIL],
                captcha: isNotProtectedMode ? data[formFieldNames.CAPTCHA] : null,
                captchaId: isNotProtectedMode ? captchaData.id : null,
                legalEntityType: data[formFieldNames.LE_TYPE],
                genericFieldsDTO: {
                    genericFieldDTOList: prepareGenericsForSerialization(
                        data,
                        REGISTER_ACCOUNT_GENERIC_FIELDS
                    ),
                },
                accountRole: isNotProtectedMode ? "SUPERVISOR" : "REGULAR"
            };
            if (isNotProtectedMode) {
                handleAccountServices.handleRegisterAccount(registerParams, onRegisterSuccess, onRegisterError)
            } else {
                handleAccountServices.handleRegisterAccountProtected(registerParams, data => {
                    form.initialize({});
                    onRegisterSuccess(data);
                }, onRegisterError)
            }
        }
    };

    const prepareCaptchaImage = (data) => {
        if (data != null) {
            return "data:image/jpg;base64," + data.base64;
        }
        return null;
    };

    const prepareCaptcha = (data) => {
        if (data) {
            setCaptchaData(data);
            setCaptchaUrl(prepareCaptchaImage(data));
        }
    };

    const getCaptcha = () => {
        handleAccountServices.handleGetCaptcha(prepareCaptcha)
    };

    const onTypeChange = (event) => {
        if ([accountSubType.EMBASSY, accountSubType.INTERNATIONAL_ORGANIZATION].includes(event.target.value)) {
            let param = "io"
            if (event.target.value === accountSubType.EMBASSY) {
                param = "embassy"
            }
            handleCommonServices.handleGetSingleDictionary(projectDictionaries.LEGAL_ENTITY_SUBTYPE, param, dictionaryReference.LEGAL_ENTITY_SUBTYPE)
        }
    };

    const printCredentialsFunction = () => {
        handleCommonServices.handleCreateAccountReport({username: createdUsername, password: createdPassword},
            onPrintCredentialsFunction
        );
    }

    const onPrintCredentialsFunction = (response) => {
        if (response?.data) {
            setReport(response.data)
        }
    }

    useEffect(() => {
        if (report) {
            let pdf_newTab = window.open("");
            pdf_newTab.document.write(
                "<html><head><title>PRINT CREDENTIALS</title></head><body><iframe title='PRINT CREDENTIALS'  width='100%' height='100%' src='data:application/pdf;base64, " + report + "'></iframe></body></html>"
            );
        }
    }, [report]);

    const footer = (
        <>
            <br/>
            <p className="mt-2"><Translate value="label.passwordFormat.headline"/></p>
            <ul className="pl-2 ml-2 mt-0 line-height-3">
                <li><Translate value="label.passwordFormat.upperCase"/></li>
                <li><Translate value="label.passwordFormat.lowerCase"/></li>
                <li><Translate value="label.passwordFormat.digit"/></li>
                <li><Translate value="label.passwordFormat.specialCharacter"/></li>
                <li><Translate value="label.passwordFormat.minLength"/></li>
            </ul>
        </>
    );

    return (
        <>
            <MBForm
                formName={forms.REGISTER_ACCOUNT}
                validate={useRegisterAccountValidation}
                submitFunction={(data, form) => submitRegister(data, form)}
            >
                <div className="flex align-items-center justify-content-center mt-2">
                    <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
                        <div className="text-center accRegLabelWrap">
                            <div className="logoWithTagline">
                                <img src={krLogo1} alt="Logo" width={"120px"}/>
                                <div className="tagline"><Translate value="label.headerLogo"/></div>
                                <div className="tagline"><Translate value="label.headerLogo1"/></div>
                                <div className="tagline"><Translate value="label.headerLogo2"/></div>
                            </div>
                            <div className="text-900 text-5xl font-bold mb-3"><Translate
                                value={"message.accountRegistration"}/></div>
                        </div>
                        {isNotProtectedMode &&
                            <div className="flex align-items-center justify-content-between alreadyHaveAccount">
                                <div className="flex align-items-center">
                                </div>
                                <a href={"/login"}
                                   className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">
                                    <Translate value="message.alreadyHaveAccount"/>
                                </a>
                            </div>}
                        <div>
                            <FormElement
                                label={"label.username"}
                                fieldType={fieldType.INPUT_TEXT}
                                required
                                fieldProps={{
                                    name: formFieldNames.USERNAME,
                                    keyfilter: "alphanum",
                                    maxLength: 16
                                }}
                            />
                            {isNotProtectedMode && <>
                                <FormElement
                                    label={"label.password"}
                                    fieldType={fieldType.PASSWORD}
                                    required
                                    fieldProps={{name: formFieldNames.PASSWORD,
                                        promptLabel: I18n.t("label.passwordLocale.enterPassword"),
                                        weakLabel: I18n.t("label.passwordLocale.weak"),
                                        mediumLabel: I18n.t("label.passwordLocale.medium"),
                                        strongLabel: I18n.t("label.passwordLocale.strong"),
                                        toggleMask: true,
                                        maxLength: 12}}
                                    footer={footer}
                                />
                                <FormElement
                                    label={"label.repeatPassword"}
                                    fieldType={fieldType.PASSWORD}
                                    required
                                    fieldProps={{
                                        name: formFieldNames.PASSWORD_REPEATED,
                                        toggleMask: true,
                                        feedback: false,
                                        maxLength: 12
                                    }}
                                />
                            </>}
                            <FormElement
                                label={"label.email"}
                                fieldType={fieldType.INPUT_TEXT}
                                required={isNotProtectedMode}
                                fieldProps={{name: formFieldNames.EMAIL}}
                            />
                            {!configurationData.dlGoLive &&
                                <FormElement
                                    label={"label.accountType"}
                                    required
                                    fieldType={fieldType.INPUT_DROPDOWN}
                                    fieldProps={{
                                        name: formFieldNames.ACCOUNT_TYPE,
                                        selectOptions: getAccountTypeOptions(),
                                    }}
                                />}
                            {accountType === LEGAL_ENTITY &&
                                <div>
                                    <FormElement
                                        label={"label.entityType"}
                                        required
                                        fieldType={fieldType.INPUT_DROPDOWN}
                                        fieldProps={{
                                            name: formFieldNames.LE_TYPE,
                                            selectOptions: getLegalEntityType(),
                                            [formFieldNames.ON_CHANGE]: onTypeChange
                                        }}
                                    />
                                    {[accountSubType.EMBASSY, accountSubType.INTERNATIONAL_ORGANIZATION].includes(leType) ?
                                        <FormElement
                                            label={"label.entitySubType"}
                                            required
                                            fieldType={fieldType.INPUT_DROPDOWN}
                                            fieldProps={{
                                                name: formFieldNames.LE_SUBTYPE,
                                                options: subtypeOptions,
                                            }}
                                        />
                                        :
                                        <FormElement
                                            label={"label.entitySubType"}
                                            fieldType={fieldType.INPUT_TEXT}
                                            required
                                            fieldProps={{name: formFieldNames.LE_SUBTYPE}}
                                        />
                                    }
                                </div>}
                            {isNotProtectedMode && <Captcha
                                label={"label.captcha"}
                                captchaURL={captchaUrl}
                                fieldName={formFieldNames.CAPTCHA}
                                onRefreshCaptcha={() => getCaptcha()}
                                captchaPlaceholder={false}
                            />}

                            <Button
                                label={<Translate value="action.register"/>}
                                type="submit"
                                icon="pi pi-user"
                                className="w-full"
                            />
                        </div>
                    </div>
                </div>
            </MBForm>
            {showPrintButton &&
                <Button
                    label={I18n.t("action.print")}
                    type="button"
                    icon="pi pi-print"
                    onClick={printCredentialsFunction}
                />}
        </>
    )
}

const mapStateToProps = state => {
    return {};
};

export default connect(mapStateToProps)(RegisterAccount);
