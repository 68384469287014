import React, {useMemo, useState} from "react";
import {
    Button,
    fieldType,
    FormElement,
    getFormValues,
    getFromAppStore,
    showSpinner,
    addToAppStore,
    Dialog, Message, showToast, toastIDs, hideSpinner
} from "@mb-react/mb-react-core";
import {I18n} from "react-redux-i18n";
import {genericFormFieldNames, PlateNumberData} from "@mb-react/krg-getid-form-data-common";
import {useDispatch, useSelector} from "react-redux";
import {forms} from "../../../../../constants/form";
import {
    validateDesiredPlateNumberByPlateCategory,
    formatPlateNumberLatin
} from "../../../../../util/vrUtil";
import {formFieldNames, getCookie} from "@mb-react/svarog-ui";
import {extractGenericFields} from "../../../../../util/formUtils";
import {LEGAL_ENTITY, NATURAL_PERSON} from "../../../../../constants/application";
import {plateReservationUses} from "../../../../../constants/store";
import {useHandleCommonServices} from "../../../../../services/commonServiceHandler";
import {useForm} from "react-final-form";
import {isOperationModeProtected} from "../../../../../util/businesslogicUtil";
import {usePlateNumberDataSettings} from "./plateDataSettings/plateNumberDataSettings";
import {getPath, i18nl, TRG} from "../../../../../constants/i18n";

const EXISTING_RESERVATION_SUCCESSFULLY_PERFORMED = "existingReservationSuccess";
const EXISTING_RESERVATION_REFERENCE = "existingReservationReference";

const UseExistingPlateTask = (formName) => {
    const dispatch = useDispatch();
    const commonServiceHandler = useHandleCommonServices();
    const formValuesApplication = useSelector((state) => getFormValues(state, forms.APPLICATION));
    const formValuesPlateData = useSelector((state) => getFormValues(state, forms.PLATE_DATA));
    let formValues = {...formValuesApplication, ...formValuesPlateData}
    const addedApplicants = useSelector((state) => getFromAppStore(state, 'listOfSellers'))
    const isLegalEntity = useMemo(() => addedApplicants.some(a => a?.entityType === LEGAL_ENTITY), [addedApplicants?.length]);
    const leType = useMemo(() => addedApplicants.find(a => a?.entityType === LEGAL_ENTITY)?.applicantData?.legalEntityDTO?.[formFieldNames.LE_TYPE], [addedApplicants?.length]);
    const form = useForm();
    const [useExistingPlateDialog, setUseExistingPlateDialog] = useState(false);
    const [ownerNameDialog, setOwnerNameDialog] = useState();
    const [chassisNumberDialog, setChassisNumberDialog] = useState();
    const existingReservationReference = useSelector((state) => getFromAppStore(state, 'existingReservationReference'))
    const plateNumberDataSettings = usePlateNumberDataSettings(formName);
    const reservePlateNumberButton = useSelector((state) => getFromAppStore(state, "reservePlateNumberButton"));

    const preparePlateGenerics = () => {
        let generics = extractGenericFields(formValues, [genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN]);
        generics?.genericFieldDTOList.push(
            {
                key: "applicantType",
                value: isLegalEntity ? LEGAL_ENTITY : NATURAL_PERSON
            },
            {
                key: "applicantSubType",
                value: leType ?? null
            });

        return generics;
    };

    const plateRequestData = {
        [formFieldNames.PLATE_CATEGORY]: formValues?.[formFieldNames.PLATE_CATEGORY],
        [formFieldNames.PLATE_USAGE]: formValues?.[formFieldNames.PLATE_USAGE],
        [formFieldNames.PLATE_PROVINCE]: formValues?.[formFieldNames.PLATE_PROVINCE],
        [formFieldNames.PLATE_COUNTRY_CODE]: formValues?.[formFieldNames.PLATE_COUNTRY_CODE],
        genericFieldsDTO: preparePlateGenerics()
    };

    const getPlateReservationUseAndReference = (e) => {
        e.preventDefault();
        dispatch(showSpinner(I18n.t('message.getPlateReservationUseAndReference')))
        commonServiceHandler.handleGetPlateReservationUseAndReference(plateRequestData, handleGetPlateReservationUseAndReferenceSuccess, handleGetPlateReservationUseAndReferenceError);
    }

    const handleGetPlateReservationUseAndReferenceError = () => {
        dispatch(hideSpinner())
    }

    const handleGetPlateReservationUseAndReferenceSuccess = (response) => {
        dispatch(hideSpinner())
        if (response) {
            const existingReservationUse = response?.plateReservationUse;
            dispatch(addToAppStore("existingReservationReference", response?.plateReservationReference));
            const existingReservationReferenceValue = response?.plateReservationReference;
            dispatch(addToAppStore("existingReservationReference", existingReservationReferenceValue));
            const plateUsedForReRegister = existingReservationUse === plateReservationUses.RESERVED_FOR_RE_REGISTER;
            const plateNotUsed = existingReservationUse === plateReservationUses.NOT_USED;
            const correctPlateReference = existingReservationReference === formValues?.[EXISTING_RESERVATION_REFERENCE];
            const selectedForReRegister = formValues?.[genericFormFieldNames.RESERVED_FOR_RE_REGISTER];
            const plateReservedForReRegister = plateUsedForReRegister && selectedForReRegister;

            // this value is sent to backend based on checkbox value and plate reservation use response value
            form.change(genericFormFieldNames.RESERVED_FOR_RE_REGISTER, plateReservedForReRegister);

            if (plateReservedForReRegister) {
                let ownerName = existingReservationReferenceValue?.split(";")?.[0];
                let chassisNumber = existingReservationReferenceValue?.split(";")?.[1];

                setChassisNumberDialog(chassisNumber)
                setOwnerNameDialog(ownerName)

                setUseExistingPlateDialog(true)
            } else if (!selectedForReRegister) {
                handleNotSelectedForRegister(plateNotUsed, correctPlateReference);
            } else {
                handleSelectedForReRegister(existingReservationUse);
            }
        } else {
            dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {messageId: I18n.t("err.plateReservationExpected")}))
        }
    }

    const handleSelectedForReRegister = (plateReservationUse) => {
        if (plateReservationUse) {
            dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {messageId: I18n.t("err.notFoundUseOfPlateData")}))
        } else {
            dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {messageId: I18n.t("err.plateValidationFailed")}))
        }
    };

    const handleNotSelectedForRegister = (plateNotUsed, correctApplicationReference) => {
        if (plateNotUsed && correctApplicationReference) {
            plateReservationSuccess();
        } else if (!correctApplicationReference) {
            dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {messageId: I18n.t("err.notAppropriatePlateReference")}))
        } else {
            dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {messageId: I18n.t("err.notAppropriatePlateUse")}))
        }
    };

    const useExistingPlateConfirmation = () => {
        dispatch(addToAppStore(genericFormFieldNames.RESERVED_FOR_RE_REGISTER, formValues?.[genericFormFieldNames.RESERVED_FOR_RE_REGISTER]));
        plateReservationSuccess();
    }

    const plateReservationSuccess = () => {
        // todo logic for serial number needs to be implemented as well
        // todo LICENSE_NUMBER and LICENSE_NUMBER_LATIN set read only (inside specific behaviour util)
        form.change(formFieldNames.PLATE_NUMBER, formatPlateNumberLatin(formValues?.[genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN], formValues?.[formFieldNames.PLATE_PROVINCE]));
        form.change(formFieldNames.PLATE_NUMBER_LATIN, formatPlateNumberLatin(formValues?.[genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN], formValues?.[formFieldNames.PLATE_PROVINCE]));
        form.change(genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN, null);
        form.change(genericFormFieldNames.PLATE_REFERENCE_NUMBER, existingReservationReference);
        form.change(EXISTING_RESERVATION_SUCCESSFULLY_PERFORMED, true);
        form.change(genericFormFieldNames.PLATE_RESERVATION_COMPLETED, true);
        setUseExistingPlateDialog(false)
        dispatch(addToAppStore('reservePlateNumberButton', true))
    }

    const renderUseExistingPlateFooter = () => {
        return (
            <div>
                <Button label={I18n.t("action.yes")} icon="pi pi-edit" onClick={() => useExistingPlateConfirmation()}
                        className="p-button-text" />
                <Button label={I18n.t("action.no")} icon="pi pi-times" onClick={() => {setUseExistingPlateDialog(false)}}
                        className="p-button-text" />
            </div>
        );
    };

    const reservePlateNumber = () => {
        if(!existingReservationReference && formValuesPlateData && !formValuesPlateData[genericFormFieldNames.PLATE_REFERENCE_NUMBER]){
            form.change(genericFormFieldNames.PLATE_REFERENCE_NUMBER, formValues[formFieldNames.APPLICATION_ID])
        }

        dispatch(addToAppStore('reservePlateNumberButton', false))
        dispatch(addToAppStore('checkAvailablePlateNumberButton', true))
        dispatch(addToAppStore('desiredPlateNumberReserved', true))
        dispatch(showToast(toastIDs.MESSAGE_TOAST, 'success', {messageId: I18n.t("message.plateSuccessfullyReserved")}))
        dispatch(addToAppStore('plateReserved', true))
    }

    let desiredPlateCss = isOperationModeProtected() ? "flex justify-content-center protectedWrap" : "publicWrap"

    const reservedForReRegisterUseExisting = I18n.t("message.reservedForReRegisterUseExisting", {
        ownerName: ownerNameDialog,
        chassisNumber: chassisNumberDialog,
        [formFieldNames.PLATE_NUMBER]: formValues?.[genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN]
    })

    let messages = reservedForReRegisterUseExisting.split("\n")

    return (
        <div className="grid">
            <div className="col-6">
                <div className="col-6">
                    <FormElement
                        label={"label." + genericFormFieldNames.RESERVED_FOR_RE_REGISTER}
                        fieldType={fieldType.CHECKBOX_CUSTOM}
                        fieldProps={{
                            name: genericFormFieldNames.RESERVED_FOR_RE_REGISTER,
                            readOnly: formValues?.[EXISTING_RESERVATION_SUCCESSFULLY_PERFORMED]
                        }}
                    />
                </div>

                <FormElement
                    label={"label." + EXISTING_RESERVATION_REFERENCE}
                    additionalClass={'col-6'}
                    fieldType={fieldType.INPUT_TEXT}
                    fieldProps={{
                        name: EXISTING_RESERVATION_REFERENCE,
                        readOnly: formValues?.[genericFormFieldNames.RESERVED_FOR_RE_REGISTER] || formValues?.[EXISTING_RESERVATION_SUCCESSFULLY_PERFORMED]
                    }}
                />
                <FormElement
                    label={I18n.t("label.desiredPlateNumber")}
                    additionalClass={'col-6'}
                    fieldType={fieldType.INPUT_TEXT}
                    fieldProps={{
                        name: genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN,
                        maxLength: 6,
                        readOnly: formValues?.[EXISTING_RESERVATION_SUCCESSFULLY_PERFORMED]
                    }}
                />
                <div className={"col-12 flex justify-content-center"}>
                    <div className="col-6 checkPlateBtn">
                        <Button
                            label={I18n.t("action.validatePlate")}
                            className="p-button-info ml-1"
                            icon="pi pi-check"
                            disabled={formValues?.[EXISTING_RESERVATION_SUCCESSFULLY_PERFORMED] || !validateDesiredPlateNumberByPlateCategory(formValues)}
                            onClick={getPlateReservationUseAndReference}
                        />
                    </div>
                    <div className="col-6 checkPlateNum">
                        <Button label={I18n.t("action.reservePlateNumber")}
                                disabled={!reservePlateNumberButton}
                                className="p-button-secondary"
                                type="button"
                                onClick={() => reservePlateNumber()}/>
                    </div>
                </div>
            </div>
            <div className={"col-6"}>
                <div className={desiredPlateCss}>
                    <PlateNumberData {...plateNumberDataSettings} />
                </div>
            </div>
            <Dialog header={I18n.t("label.useExistingPlateReservationDataSection")} visible={useExistingPlateDialog}
                    style={{width: '32vw'}}
                    footer={renderUseExistingPlateFooter} onHide={() => setUseExistingPlateDialog(false)}>
                <p style={{marginLeft: '40px'}}>
                    {messages && messages[0] ? messages[0] : ""}
                    <br/>
                    <ul>
                        <li>
                            {messages && messages[1] ? messages[1] : ""}
                        </li>
                        <li>
                            {messages && messages[2] ? messages[2] : ""}
                        </li>
                        <li>
                            {messages && messages[3] ? messages[3] : ""}
                        </li>
                    </ul>
                    {messages && messages[4] ? messages[4] : ""}
                </p>
            </Dialog>
        </div>
    );
};
export default UseExistingPlateTask;