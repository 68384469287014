/**
 * @author miladinovica
 */
import React, {Fragment} from 'react';
import {fieldType, FormElement} from '@mb-react/mb-react-core';

const ModifyProvinceSiteData = (props) => {
   const {
      modifyProvinceSite

   } = {
      modifyProvinceSite : {},
      ...props
   };

   return (
       <Fragment>
           {!modifyProvinceSite.exclude &&
           <FormElement
               label="label.modifyProvinceSite"
               additionalClass='col-6 col-md-4'
               fieldType={fieldType.CHECKBOX_CUSTOM}
               required
               {...modifyProvinceSite}
               fieldProps={{
                   ...modifyProvinceSite.fieldProps,
                   name: "modifyProvinceSite"
               }}
           />
           }
       </Fragment>
   )
};

export default ModifyProvinceSiteData;