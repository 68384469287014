import {formFieldNames, isRequired} from "@mb-react/svarog-ui";
import {I18n} from "react-redux-i18n"
import {EMAIL_REGEX, PHONE_REGEX} from "../../../constants/form"

export const useEditAccountValidation = (values) => {
  let errors = {};

  isRequired(values, formFieldNames.EMAIL, errors)

  if(values[formFieldNames.EMAIL] && !values[formFieldNames.EMAIL].match(EMAIL_REGEX)){
    errors[formFieldNames.EMAIL] = I18n.t('label.wrongEmailFormat')
  }

  isRequired(values, formFieldNames.PHONE, errors)

  if(values[formFieldNames.PHONE] && !values[formFieldNames.PHONE].match(PHONE_REGEX)){
    errors[formFieldNames.PHONE] = I18n.t('label.wrongPhoneFormat')
  }
  isRequired(values, formFieldNames.CAPTCHA, errors)

  return errors
};