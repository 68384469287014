export const ids = Object.freeze({
  CANCELLATION: 'cancellationModal',
  LOADING: 'loadingmodal',
  ERROR: 'errormodal',
  ERROR_MODAL_LOCATION: 'errorModalLocation',
  PROCESS_HISTORY: "processHistory",
})

export const headerStyles = Object.freeze({
  PRIMARY: 'modal-primary',
  SECONDARY: 'modal-secondary',
  DANGER: 'modal-danger',
  WARNING: 'modal-warning'
})
