import {genericFormFieldNames} from "@mb-react/krg-getid-form-data-common";
import {formFieldNames} from "@mb-react/svarog-ui";

// MANDATORY
export const commonMandatoryFieldListVRApplicant = [
  formFieldNames.GIVEN_NAME,
  formFieldNames.GIVEN_NAME_LATIN,
  formFieldNames.SURNAME,
  formFieldNames.SURNAME_LATIN,
  formFieldNames.GENDER,
  formFieldNames.NATIONALITY,
  formFieldNames.BLOOD_GROUP,
  formFieldNames.DATE_OF_BIRTH,
  formFieldNames.PLACE_OF_BIRTH_COUNTRY,
  formFieldNames.POSITION,
  formFieldNames.MARITAL_STATUS,
  formFieldNames.FATHERS_NAME,
  formFieldNames.FATHERS_NAME_LATIN,
  formFieldNames.MOTHERS_NAME,
  formFieldNames.MOTHERS_NAME_LATIN,
  formFieldNames.OWNER_ROLE,
  genericFormFieldNames.MOTHERS_FATHER_NAME,
  genericFormFieldNames.GRANDFATHER_NAME,
  genericFormFieldNames.GRANDFATHER_NAME_LATIN,
  formFieldNames.ADDRESS_PROVINCE,
  formFieldNames.ADDRESS_CITY,
  genericFormFieldNames.ADDRESS_VILLAGE,
  genericFormFieldNames.ADDRESS_BUILDING_CLASS,
  genericFormFieldNames.ADDRESS_INFORMATION_CENTER,
  genericFormFieldNames.PROVINCE_OF_REGISTRATION,
  genericFormFieldNames.CITY_OF_REGISTRATION,
  genericFormFieldNames.VILLAGE_OF_REGISTRATION,
  genericFormFieldNames.REGISTRATION_BOOK_NUMBER,
  genericFormFieldNames.REGISTRATION_PAGE_NUMBER,
  formFieldNames.PHONE
];

export const commonMandatoryFieldListVRVehicle = [
  formFieldNames.VEHICLE_ID,
  formFieldNames.VEHICLE_BRAND,
  formFieldNames.VEHICLE_CATEGORY,
  formFieldNames.PRODUCTION_YEAR,
  formFieldNames.CHASSIS_NUMBER,
  formFieldNames.VEHICLE_COLOR,
  formFieldNames.PASSENGERS,
  formFieldNames.VEHICLE_MODEL,
  formFieldNames.FUEL,
  formFieldNames.ENGINE_NUMBER,
  formFieldNames.CABIN_TYPE,
  formFieldNames.DRIVE_TRAIN,
  formFieldNames.WHEEL_SIZE,
];

export const trailerMandatoryFieldListVRVehicle = [
  formFieldNames.VEHICLE_ID,
  genericFormFieldNames.VEHICLE_TYPE,
  formFieldNames.VEHICLE_BRAND,
  formFieldNames.VEHICLE_CATEGORY,
  formFieldNames.PRODUCTION_YEAR,
  formFieldNames.PRODUCTION_COUNTRY,
  formFieldNames.CHASSIS_NUMBER,
  // formFieldNames.VEHICLE_USAGE_TYPE,
  formFieldNames.VEHICLE_COLOR,
  formFieldNames.TOTAL_WEIGHT,
  formFieldNames.PASSENGERS,
  formFieldNames.VEHICLE_MODEL,
  formFieldNames.VEHICLE_SUBCATEGORY,
  formFieldNames.LOAD_WEIGHT,
  formFieldNames.DRIVE_TRAIN,

  formFieldNames.PLATE_NUMBER,
  formFieldNames.PLATE_NUMBER_LATIN,
  formFieldNames.PLATE_SERIAL_NUMBER,
  formFieldNames.PLATE_SERIAL_NUMBER_LATIN,
 ];

export const mandatoryFieldListNEW_VR_applicant = [
  ...commonMandatoryFieldListVRApplicant
];

export const mandatoryFieldListREREGISTER_VR_applicant = [
  ...commonMandatoryFieldListVRApplicant
];

export const mandatoryFieldListPARTLY_DAMAGED_VR_applicant = [
  ...commonMandatoryFieldListVRApplicant
];

export const mandatoryFieldListRESERVE_PLATE_NUMBER_VR_applicant = [
  ...commonMandatoryFieldListVRApplicant,
  formFieldNames.DAYS_TO_EXTEND_PLATE_RESERVATION
];

export const mandatoryFieldListREPLACE_VR_applicant = [
  ...commonMandatoryFieldListVRApplicant,
  formFieldNames.TPID,
  genericFormFieldNames.CATERING_CENTER_PROVINCE,
  genericFormFieldNames.CATERING_CENTER_NUMBER,
  genericFormFieldNames.FOOD_RATION_CARD_NUMBER,
  genericFormFieldNames.FOOD_RATION_CARD_ISSUE_DATE,
  genericFormFieldNames.ADDITIONAL_PLATES
];

export const mandatoryFieldListEXCHANGE_REGISTRATION_VR_applicant = [
  ...commonMandatoryFieldListVRVehicle,
  formFieldNames.OWNERSHIP_PERCENTAGE,
];

export const mandatoryFieldListCHANGE_OWNERSHIP_SALE_AND_BUY_VR_applicant = [
  ...commonMandatoryFieldListVRApplicant,
  formFieldNames.OWNERSHIP_PERCENTAGE,
  formFieldNames.OWNER_ROLE
];

export const mandatoryFieldListNEW_VR_vehicle = [
  ...commonMandatoryFieldListVRVehicle
];

export const mandatoryFieldListREREGISTER_VR_vehicle = [
  ...commonMandatoryFieldListVRVehicle
];

export const mandatoryFieldListREPLACE_VR_vehicle = [
  ...commonMandatoryFieldListVRVehicle
];

export const mandatoryFieldListPARTLY_DAMAGED_VR_vehicle = [
  ...commonMandatoryFieldListVRVehicle
];

export const mandatoryFieldListRESERVE_PLATE_NUMBER_VR_vehicle = [
  ...commonMandatoryFieldListVRVehicle
];

export const mandatoryFieldListEXCHANGE_REGISTRATION_VR_vehicle = [
  ...commonMandatoryFieldListVRVehicle
];

export const mandatoryFieldListCHANGE_OWNERSHIP_SALE_AND_BUY_VR_vehicle = [
  ...commonMandatoryFieldListVRVehicle
];

export const mandatoryFieldListNEW_VR = [
  ...mandatoryFieldListNEW_VR_applicant,
  ...mandatoryFieldListNEW_VR_vehicle
];

export const mandatoryFieldListREREGISTER_VR = [
  ...mandatoryFieldListREREGISTER_VR_applicant,
  ...mandatoryFieldListREREGISTER_VR_vehicle
];

export const mandatoryFieldListREPLACE_VR = [
  ...mandatoryFieldListREPLACE_VR_applicant,
  ...mandatoryFieldListREPLACE_VR_vehicle
];

export const mandatoryFieldListPARTLY_DAMAGED_VR = [
  ...mandatoryFieldListPARTLY_DAMAGED_VR_applicant,
  ...mandatoryFieldListPARTLY_DAMAGED_VR_vehicle
];

export const mandatoryFieldListRESERVE_PLATE_NUMBER = [
  ...mandatoryFieldListRESERVE_PLATE_NUMBER_VR_applicant,
  ...mandatoryFieldListRESERVE_PLATE_NUMBER_VR_vehicle
];

export const mandatoryFieldListEXCHANGE_REGISTRATION = [
  ...mandatoryFieldListEXCHANGE_REGISTRATION_VR_applicant,
  ...mandatoryFieldListEXCHANGE_REGISTRATION_VR_vehicle
];

export const mandatoryFieldListCHANGE_OWNERSHIP_SALE_AND_BUY = [
  ...mandatoryFieldListCHANGE_OWNERSHIP_SALE_AND_BUY_VR_applicant,
  ...mandatoryFieldListCHANGE_OWNERSHIP_SALE_AND_BUY_VR_vehicle
];

export const mandatoryFieldListApproval_VR = [
  ...commonMandatoryFieldListVRApplicant,
  ...commonMandatoryFieldListVRVehicle
];

export const mandatoryFieldListApprovalIdentification_VR = [
  ...commonMandatoryFieldListVRApplicant,
  ...commonMandatoryFieldListVRVehicle
];

export const mandatoryFieldListPayment_VR = [
  formFieldNames.PAYMENT_METHOD,
  formFieldNames.TRANSACTION_NUMBER
];

// READ ONLY
export const commonReadOnlyFieldListEnrollmentVR = [
  formFieldNames.VEHICLE_ID,
  formFieldNames.VEHICLE_SUBCATEGORY,
  formFieldNames.PLATE_NUMBER,
  formFieldNames.PLATE_NUMBER_LATIN,
  formFieldNames.PLATE_SERIAL_NUMBER,
  formFieldNames.PLATE_SERIAL_NUMBER_LATIN,
  //genericFormFieldNames.FOOD_CENTER_INDICATOR,
  genericFormFieldNames.EXISTING_PLATE_INPUT,
];

export const readOnlyFieldListNEW_VR = [
  ...commonReadOnlyFieldListEnrollmentVR,
  //formFieldNames.TPID,
  genericFormFieldNames.NUMBER_DL,
  genericFormFieldNames.ISSUE_DATE_DL,
  genericFormFieldNames.VALID_DATE_DL,
  genericFormFieldNames.ISSUE_COUNTRY_DL,
  genericFormFieldNames.CLASSES_DL,
  genericFormFieldNames.REQUEST_MEDICAL_EXEM
];

export const readOnlyFieldListCHANGE_OWNERSHIP = [
  ...commonReadOnlyFieldListEnrollmentVR,
];

export const readOnlyFieldListREREGISTER_VEHICLE = [
  ...commonReadOnlyFieldListEnrollmentVR,
  formFieldNames.TPID,
  genericFormFieldNames.REQUEST_MEDICAL_EXEM,
  genericFormFieldNames.ISSUE_COUNTRY_DL
];

export const readOnlyFieldListREPLACE_VR = [
  ...commonReadOnlyFieldListEnrollmentVR,
  formFieldNames.TPID,
  genericFormFieldNames.NUMBER_DL,
  genericFormFieldNames.ISSUE_DATE_DL,
  genericFormFieldNames.VALID_DATE_DL,
  genericFormFieldNames.ISSUE_COUNTRY_DL,
  genericFormFieldNames.CLASSES_DL,
  genericFormFieldNames.REQUEST_MEDICAL_EXEM
];

export const readOnlyFieldListPARTLY_DAMAGED_VR = [
  ...commonReadOnlyFieldListEnrollmentVR,
  formFieldNames.TPID,
  genericFormFieldNames.NUMBER_DL,
  genericFormFieldNames.ISSUE_DATE_DL,
  genericFormFieldNames.VALID_DATE_DL,
  genericFormFieldNames.ISSUE_COUNTRY_DL,
  genericFormFieldNames.CLASSES_DL
];

export const readOnlyFieldListEXPIRED_VR = [
  ...commonReadOnlyFieldListEnrollmentVR,
  formFieldNames.TPID,
  genericFormFieldNames.NUMBER_DL,
  genericFormFieldNames.ISSUE_DATE_DL,
  genericFormFieldNames.VALID_DATE_DL,
  genericFormFieldNames.ISSUE_COUNTRY_DL,
  genericFormFieldNames.CLASSES_DL,
  genericFormFieldNames.REQUEST_MEDICAL_EXEM
];

export const readOnlyFieldListMODIFY_VEHICLE_DATA = [
  ...commonReadOnlyFieldListEnrollmentVR,
  formFieldNames.TPID,
  genericFormFieldNames.NUMBER_DL,
  genericFormFieldNames.ISSUE_DATE_DL,
  genericFormFieldNames.VALID_DATE_DL,
  genericFormFieldNames.ISSUE_COUNTRY_DL,
  genericFormFieldNames.CLASSES_DL,
  genericFormFieldNames.REQUEST_MEDICAL_EXEM,
  genericFormFieldNames.REQUEST_APPROVAL,
  genericFormFieldNames.REQUEST_APPROVAL_COMMENT
];

export const readOnlyFieldListApprovalVR_applicant = [
  formFieldNames.GIVEN_NAME,
  formFieldNames.GIVEN_NAME_LATIN,
  formFieldNames.SURNAME,
  formFieldNames.SURNAME_LATIN,
  formFieldNames.GENDER,
  formFieldNames.PERSONAL_NUMBER,
  formFieldNames.NATIONALITY,
  formFieldNames.BLOOD_GROUP,
  formFieldNames.TAX_NUMBER,
  formFieldNames.DATE_OF_BIRTH,
  formFieldNames.PLACE_OF_BIRTH_COUNTRY,
  formFieldNames.PLACE_OF_BIRTH_PROVINCE,
  formFieldNames.PLACE_OF_BIRTH_CITY,
  genericFormFieldNames.PLACE_OF_BIRTH_VILLAGE,
  formFieldNames.POSITION,
  formFieldNames.MARITAL_STATUS,
  formFieldNames.FATHERS_NAME,
  formFieldNames.FATHERS_NAME_LATIN,
  formFieldNames.MOTHERS_NAME,
  formFieldNames.MOTHERS_NAME_LATIN,
  genericFormFieldNames.MOTHERS_FATHER_NAME,
  genericFormFieldNames.GRANDFATHER_NAME,
  genericFormFieldNames.GRANDFATHER_NAME_LATIN,
  formFieldNames.ADDRESS_PROVINCE,
  formFieldNames.ADDRESS_CITY,
  genericFormFieldNames.ADDRESS_VILLAGE,
  genericFormFieldNames.ADDRESS_BUILDING_CLASS,
  genericFormFieldNames.ADDRESS_INFORMATION_CENTER,
  formFieldNames.TPID,
  genericFormFieldNames.NUMBER_DL,
  genericFormFieldNames.ISSUE_DATE_DL,
  genericFormFieldNames.VALID_DATE_DL,
  genericFormFieldNames.ISSUE_COUNTRY_DL,
  genericFormFieldNames.CLASSES_DL,
  formFieldNames.PHONE,
  formFieldNames.EMAIL,
  genericFormFieldNames.PROVINCE_OF_REGISTRATION,
  genericFormFieldNames.CITY_OF_REGISTRATION,
  genericFormFieldNames.VILLAGE_OF_REGISTRATION,
  genericFormFieldNames.REGISTRATION_BOOK_NUMBER,
  genericFormFieldNames.REGISTRATION_PAGE_NUMBER,
  genericFormFieldNames.FOOD_CENTER_INDICATOR,
  formFieldNames.PROXY,
  formFieldNames.PROXY_NAME,
  formFieldNames.PROXY_SURNAME,
  formFieldNames.PROXY_PERSONAL_NUMBER,
  formFieldNames.PROXY_GENDER,
  formFieldNames.PROXY_DATE_OF_BIRTH,
  genericFormFieldNames.PROXY_FATHER_NAME,
  genericFormFieldNames.PROXY_GRANDFATHER_NAME,
  genericFormFieldNames.REQUEST_MEDICAL_EXEM,
  genericFormFieldNames.REQUEST_APPROVAL,
  genericFormFieldNames.REQUEST_APPROVAL_COMMENT,
  formFieldNames.COMMENT,
];

export const readOnlyFieldListApprovalVR_vehicle = [
  formFieldNames.VEHICLE_ID,
  formFieldNames.VEHICLE_BRAND,
  formFieldNames.VEHICLE_CATEGORY,
  formFieldNames.PRODUCTION_YEAR,
  formFieldNames.PRODUCTION_COUNTRY,
  formFieldNames.CHASSIS_NUMBER,
  formFieldNames.VEHICLE_USAGE_TYPE,
  formFieldNames.VEHICLE_COLOR,
  formFieldNames.VERTICAL_LOAD,
  formFieldNames.SEATS,
  formFieldNames.WHEEL_SIZE,
  formFieldNames.AXIS,
  formFieldNames.TOTAL_WEIGHT,
  formFieldNames.VEHICLE_LENGTH,
  formFieldNames.VEHICLE_HEIGHT,
  formFieldNames.VEHICLE_WIDTH,
  formFieldNames.VEHICLE_RESTRICTIONS,
  formFieldNames.PASSENGERS,
  formFieldNames.VEHICLE_MODEL,
  formFieldNames.VEHICLE_SUBCATEGORY,
  formFieldNames.FUEL,
  formFieldNames.CYLINDERS,
  formFieldNames.ENGINE_CAPACITY,
  formFieldNames.ENGINE_POWER,
  formFieldNames.MAXIMUM_SPEED,
  formFieldNames.LOAD_WEIGHT,
  formFieldNames.CABIN_NUMBER,
  formFieldNames.CABIN_TYPE,
  formFieldNames.CABIN_SIZE,
  formFieldNames.DRIVE_TRAIN,
  formFieldNames.MAXIMUM_LOAD_TRAILER_WITH_BRAKES,
  formFieldNames.MAXIMUM_LOAD_TRAILER_WITHOUT_BRAKES,
  formFieldNames.VEHICLE_APPROVAL_TYPE,
  formFieldNames.FRONT_TINT_PERCENTAGE,
  formFieldNames.REAR_TINT_PERCENTAGE,

  formFieldNames.PLATE_NUMBER,
  formFieldNames.PLATE_NUMBER_LATIN,
  formFieldNames.PLATE_TYPE,
  formFieldNames.PLATE_CATEGORY,
  formFieldNames.PLATE_PROVINCE,
  formFieldNames.PLATE_USAGE,
  formFieldNames.PLATE_SERIAL_NUMBER,
  formFieldNames.PLATE_SERIAL_NUMBER_LATIN,
  formFieldNames.PLATE_COUNTRY_CODE,
  formFieldNames.DAYS_TO_EXTEND_PLATE_RESERVATION
];

export const readOnlyFieldListPayment_VR = [
  formFieldNames.PAYMENT_AMOUNT,
  formFieldNames.TRANSACTION_DATE
];

export const readOnlyFieldListStateHandlingVehicle = [
  ...readOnlyFieldListApprovalVR_vehicle,
  ...commonReadOnlyFieldListEnrollmentVR
];